import React from 'react';
import { DataGridPremium, useGridApiRef } from '@mui/x-data-grid-premium';

import { IMeeting } from 'src/api/models/Meeting';
import { getMeetingTableColumns } from '../tableColumns';
const MeetingTableColumns = getMeetingTableColumns(false);

interface ISelectableMeetingTableProps {
    meetings: IMeeting[];
    isLoading?: boolean;
    onMeetingClicked: (meeting: IMeeting) => void;
    selectedMeetingId: string | null;
    caption?: string;
}

export const SelectableMeetingTable: React.FC<ISelectableMeetingTableProps> = ({ meetings, caption, selectedMeetingId, isLoading = false, onMeetingClicked }) => {
    const apiRef = useGridApiRef();

    const formatted = React.useMemo(
        () =>
            meetings.map((d) => {
                return {
                    ...d,
                    meeting_type_name: d.meeting_type ? d.meeting_type.name : '-',
                };
            }),
        [meetings],
    );

    return (
        <div className="selectable-meeting-table-container">
            <DataGridPremium
                onSelectionModelChange={(newSelectionModel) => {
                    const target: IMeeting | undefined = meetings.find((m) => m.id === newSelectionModel[0]);
                    if (target) {
                        onMeetingClicked(target);
                    }
                }}
                selectionModel={selectedMeetingId ? [selectedMeetingId] : []}
                apiRef={apiRef}
                columns={MeetingTableColumns}
                disableMultipleSelection
                rows={formatted}
                pagination
                autoPageSize
            />
        </div>
    );
};
