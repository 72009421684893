import React from 'react';
import { Header } from '../../../components/Header';

export const Finance: React.FC = () => {
    return (
        <div>
            <Header title="Finance" breadcrumbs={['My First Job', 'Finance']} />
        </div>
    );
};
