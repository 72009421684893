import React from 'react';
import { InputGroup, Label, Button, Colors } from '@blueprintjs/core';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useAuth } from 'src/hooks/useAuth';
import { setPassword, getMe } from 'src/api/user';

const validationSchema = Yup.object().shape({
    password: Yup.string().required().min(6, 'Password must have at least 6 characters').label('Password'),
    confirmPassword: Yup.string().required().min(1, 'Password must have at least 6 characters').label('Password'),
});

interface ILoginFormValues {
    confirmPassword: string;
    password: string;
}

const initialValues: ILoginFormValues = {
    confirmPassword: '',
    password: '',
};

export const ResetPassword: React.FC = () => {
    const { setUser } = useAuth();
    const [loginError, setLoginError] = React.useState<string | null>(null);
    const formik = useFormik({
        initialValues,
        validationSchema,
        validateOnMount: true,
        onSubmit: async (values) => {
            if (values.password !== values.confirmPassword) {
                return setLoginError('Passwords must match');
            }
            setLoginError(null);
            try {
                await setPassword(values.password);
                const user = await getMe();
                const fake = {
                    ...user,
                    status: 'confirmed',
                };
                setUser(fake);
            } catch (error) {
                setLoginError('Invalid email or password');
            }
        },
    });

    const handleKeyDown = (e: any) => {
        if (e.key === 'Enter') {
            formik.handleSubmit();
        }
    };

    return (
        <>
            <div className="bp3-dialog-container" style={{ marginTop: 200 }}>
                <div className="bp3-dialog">
                    <div className={`bp3-dialog-body ${loginError ? 'm-b-0' : ''}`}>
                        <h2 className="m-0 m-t-15 text-center">Update Password</h2>
                        <p className="m-t-15 m-b-30 text-center">Update your password to continue</p>
                        <Label>
                            New Password
                            <InputGroup
                                placeholder="Enter password..."
                                autoFocus
                                type="password"
                                onChange={(e) => formik.setFieldValue('password', e.target.value)}
                                value={formik.values.password}
                                autoComplete="off"
                                className="m-b-15"
                            />
                        </Label>
                        <Label>
                            Confirm Password
                            <InputGroup
                                type="password"
                                onChange={(e) => formik.setFieldValue('confirmPassword', e.target.value)}
                                value={formik.values.confirmPassword}
                                placeholder="Confirm password..."
                                autoComplete="off"
                                onKeyDown={handleKeyDown}
                            />
                        </Label>
                    </div>
                    <div className="bp3-dialog-footer">
                        {loginError && <p style={{ color: Colors.RED2, textAlign: 'center' }}>{loginError}</p>}
                        <div className="bp3-dialog-footer-actions">
                            <Button
                                text="Update Password"
                                type="submit"
                                fill
                                intent="primary"
                                loading={formik.isSubmitting}
                                disabled={!formik.isValid}
                                className="m-0"
                                onClick={() => formik.handleSubmit()}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
