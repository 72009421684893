import AppApi from './index';
import { getAuthOptions } from './helpers';
import { ISubmittal } from './models/Submittal';

const api = AppApi.getApi();

export const loadSubmittalById = async (jobId: number, id: string): Promise<ISubmittal> => {
    const url = `/jobs/${jobId}/submittals/${id}`;
    const result = await api.get(url, await getAuthOptions());
    return result.data;
};

export const loadJobSubmittals = async (jobId: number): Promise<ISubmittal[]> => {
    const url = `/jobs/${jobId}/submittals`;
    const result = await api.get(url, await getAuthOptions());
    return result.data;
};

export const createSubmittal = async (jobId: number, data: ISubmittal): Promise<ISubmittal> => {
    const url = `/jobs/${jobId}/submittals`;
    const result = await api.post(url, data, await getAuthOptions());
    return result.data;
};

export const updateSubmittal = async (jobId: number, data: ISubmittal): Promise<ISubmittal> => {
    const url = `/jobs/${jobId}/submittals/${data.id}`;
    const result = await api.put(url, data, await getAuthOptions());
    return result.data;
};
