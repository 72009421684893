import React from 'react';
import { useHistory } from 'react-router-dom';
import { useJobs } from 'src/hooks/useJobs';
import { Button } from '@blueprintjs/core';
import { listTimesheetDrafts, listTimesheets } from '../../../api/timesheets';
import { TimesheetDraft, Timesheet } from '../../../api/models/timesheet';
import { DraftsTable } from './components/DraftsTable';
import { Header2 } from '../../../components/Header2';

export const Timesheets: React.FC = () => {
    const { selectedJob } = useJobs();
    const history = useHistory();
    const [error, setError] = React.useState<string | null>(null);
    const [loading, setLoading] = React.useState(false);
    const [drafts, setDrafts] = React.useState<TimesheetDraft[]>([]);
    const [timesheets, setTimesheets] = React.useState<Timesheet[]>([]);
    React.useEffect(() => {
        loadDrafts();
    }, []);

    const loadDrafts = async () => {
        if (!selectedJob) return;
        setLoading(true);
        setError(null);
        try {
            const data = await listTimesheetDrafts(selectedJob.id);
            setDrafts(data);
            const ts = await listTimesheets(selectedJob.id);
            setTimesheets(ts);
        } catch (err) {
            setError(err.toLocaleString());
        }
        setLoading(false);
    };

    return (
        <>
            <Header2
                breadcrumbs={[
                    { title: 'Jobs', link: '/jobs' },
                    { title: `${selectedJob?.name} - ${selectedJob?.job_number}`, link: `/jobs/${selectedJob?.id}/summary` },
                    { title: 'Timesheets', link: '' },
                ]}
                actionComponent={
                    <Button icon="add" intent="primary" onClick={() => history.push(`/jobs/${selectedJob?.id}/timesheets/create`)}>
                        Create Timesheets
                    </Button>
                }
            />
            {error && <p className="text-center">{error}</p>}
            <DraftsTable drafts={drafts} loading={loading} timesheets={timesheets} />
        </>
    );
};
