import React from 'react';
import { IconName } from '@blueprintjs/core';
import { SubMenu } from '../SubMenu';

interface INavigationItem {
    id: string;
    title: string;
    path: string;
    icon: IconName;
    subs?: INavigationItem[];
}

const MENU_ITEMS: INavigationItem[] = [
    {
        id: 'operations',
        title: 'Operations',
        path: '/operations',
        icon: 'satellite',
        subs: [
            {
                id: 'summary',
                title: 'Summary',
                path: '/operations/summary',
                icon: 'info-sign',
            },
            {
                id: 'dailys',
                title: 'Dailys',
                path: '/operations/dailys',
                icon: 'array-date',
            },
            {
                id: 'installs',
                title: 'Installs',
                path: '/operations/installs',
                icon: 'timeline-events',
            },
            {
                id: 'timesheets',
                title: 'Timesheets',
                path: '/operations/timesheets',
                icon: 'time',
            },
            {
                id: 'plans',
                title: 'Adjusted vs Current',
                path: '/operations/plans',
                icon: 'comparison',
            },
            {
                id: 'analysis',
                title: 'Analysis',
                path: '/operations/analysis',
                icon: 'grouped-bar-chart',
            },
            {
                id: 'pieces',
                title: 'Pieces',
                path: '/operations/pieces',
                icon: 'wrench',
            },
        ],
    },
];

const buttonCssFn = (props: any) => {
    return {
        color: props.isSelected ? '#004677' : '#42536e',
        backgroundColor: props.isSelected ? '#ebecf0' : '#fff',
    };
};

export const Operations: React.FC = () => {
    return <SubMenu items={MENU_ITEMS} buttonCssFn={buttonCssFn} goBackText="Back" goBackPath="/dashboard" computePath={(item) => item.path} />;
};
