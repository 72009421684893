import React from 'react';
import { Col, Row } from 'react-grid-system';
import { Card, FormGroup, InputGroup, TextArea, Button } from '@blueprintjs/core';
import { DatePicker } from 'src/components/DatePicker';
import { useJobs } from 'src/hooks/useJobs';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { useAuth } from 'src/hooks/useAuth';
import { JobUserSelect } from 'src/components/Selectors/JobUserSelect/single';
import { Attachments } from 'src/components/Dialogs/ChecklistItemDialog/components/Attachments';

import { IChangeOrderFormValues } from 'src/api/models/ChangeOrder';
import { createChangeOrder, updateChangeOrder } from 'src/api/changes';

import { AppToast } from 'src/components/Toasts/AppToast';
import { useHistory } from 'react-router-dom';
import { FeedFooter } from '../FeedFooter';

const validationSchema = Yup.object().shape({
    date: Yup.date().required().min(1, 'Date Required').label('Date'),
    assignee_user_id: Yup.number().required(),
});

interface IChangeOrderFormProps {
    initialValues: IChangeOrderFormValues;
    changeId?: string;
}

export const ChangeForm: React.FC<IChangeOrderFormProps> = ({ initialValues, changeId }) => {
    const { selectedJob } = useJobs();
    const { user } = useAuth();
    const history = useHistory();

    const formik = useFormik({
        initialValues,
        validationSchema,
        validateOnMount: true,
        onSubmit: async (values, { setSubmitting }) => {
            if (!selectedJob) return;
            setSubmitting(true);
            if (!changeId) {
                const result = await createChangeOrder(selectedJob.id, values);
                AppToast.show({ message: `Change Request created!`, intent: 'success', icon: 'tick' });
                history.push(`/jobs/${selectedJob.id}/changes/view/${result.id}`);
            } else {
                await updateChangeOrder(selectedJob.id, changeId, values);
                AppToast.show({ message: `Change Request updated!`, intent: 'success', icon: 'tick' });
            }
            setSubmitting(false);
        },
    });

    const { setFieldValue, values, isSubmitting } = formik;

    if (!selectedJob || !user) return null;
    return (
        <Row className="m-b-5">
            <Col>
                <Card>
                    <Row>
                        <Col xs={12}>
                            <FormGroup label="Change Summary" labelFor="desc" className="text-bold">
                                <TextArea
                                    onChange={(e) => setFieldValue('description', e.target.value)}
                                    value={values.description}
                                    id="desc"
                                    style={{ height: '100%', maxWidth: '100%' }}
                                    name="description"
                                    growVertically={true}
                                    fill
                                    rows={3}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </Card>
                <Card className="m-t-20">
                    <Row>
                        <Col xs={10}></Col>
                        <Col className="text-right">
                            <div>
                                <div className="bp3-html-select bp3-fill">
                                    <select value={values.status} onChange={(e) => setFieldValue('status', e.target.value)}>
                                        <option value="Working">Working</option>
                                        <option value="Review">Review</option>
                                        <option value="Complete">Complete</option>
                                        <option value="Draft">Draft</option>
                                    </select>
                                    <span className="bp3-icon bp3-icon-double-caret-vertical" />
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup label="Assignee" labelInfo="*">
                                <JobUserSelect
                                    hideLabel
                                    small
                                    hideRole
                                    selectedUserId={formik.values.assignee_user_id}
                                    onChange={(value) => {
                                        if (value) {
                                            formik.setFieldValue('assignee_user_id', value.user_id);
                                        } else {
                                            formik.setFieldValue('assignee_user_id', null);
                                        }
                                    }}
                                />
                            </FormGroup>
                        </Col>
                        <Col>
                            <DatePicker label="Need Date" onChange={(val) => setFieldValue('date', val)} value={values.date} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup label="Impact Notes" labelFor="impact">
                                <TextArea
                                    onChange={(e) => setFieldValue('impact_notes', e.target.value)}
                                    value={values.impact_notes}
                                    id="impact"
                                    style={{ height: '100%', maxWidth: '100%' }}
                                    name="description"
                                    growVertically={true}
                                    fill
                                    rows={4}
                                />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup label="Cost" labelFor="cost">
                                <InputGroup id="cost" autoComplete="off" name="cost" value={values.cost} onChange={(e) => setFieldValue('cost', e.target.value)} />
                            </FormGroup>
                            <FormGroup label="Schedule" labelFor="schedule">
                                <InputGroup id="schedule" autoComplete="off" name="schedule" value={values.schedule} onChange={(e) => setFieldValue('schedule', e.target.value)} />
                            </FormGroup>
                        </Col>
                    </Row>
                </Card>
                <Card className="m-t-20">
                    <Row>
                        <Col>
                            <Attachments
                                onFilesAdded={(files) => formik.setFieldValue('files_to_add', files)}
                                onFilesRemoved={(files) => formik.setFieldValue('files_to_remove', files)}
                                existingFiles={values.attachments}
                                itemType="change_request"
                            />
                        </Col>
                    </Row>
                </Card>
                <FeedFooter changeId={changeId} jobId={selectedJob.id} />
                <Card className="m-t-20">
                    <Row>
                        <Col className="text-center">
                            <Button>Cancel</Button>{' '}
                            <Button intent="primary" loading={isSubmitting} onClick={() => formik.handleSubmit()} disabled={!formik.isValid}>
                                {changeId ? 'Update Change Request' : 'Submit Change Request'}
                            </Button>
                        </Col>
                    </Row>
                </Card>
            </Col>
        </Row>
    );
};
