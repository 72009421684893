import React from 'react';
import { Row, Col } from 'react-grid-system';
import { Card, Spinner } from '@blueprintjs/core';
import { WBSTree } from './components/WBSTree';
import { useJobs } from '../../../hooks/useJobs';
import { IWBSResponse } from 'src/api/models/WBS';
import { loadJobWBS } from 'src/api/wbs';
import { Header2 } from '../../../components/Header2';

export const WBS: React.FC = () => {
    const { selectedJob } = useJobs();
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState<string | null>(null);
    const [wbs, setWbs] = React.useState<IWBSResponse[]>([]);

    React.useEffect(() => {
        loadWBS();
    }, []);

    const loadWBS = async () => {
        if (!selectedJob) return;
        setLoading(true);
        setError(null);
        try {
            const data = await loadJobWBS(selectedJob.id);
            setWbs(data);
        } catch (err) {
            setError(err.toLocaleString());
        }

        setLoading(false);
    };
    return (
        <>
            <Header2
                breadcrumbs={[
                    { title: 'Jobs', link: '/jobs' },
                    { title: `${selectedJob?.name} - ${selectedJob?.job_number}`, link: `/jobs/${selectedJob?.id}/summary` },
                    { title: 'WBS', link: '' },
                ]}
            />
            <Row>
                <Col sm={12}>
                    <Card>
                        {error && <p>{error}</p>}
                        {loading ? (
                            <div className="p-t-50 text-center">
                                <Spinner />
                            </div>
                        ) : (
                            <WBSTree wbs={wbs} />
                        )}
                    </Card>
                </Col>
            </Row>
        </>
    );
};
