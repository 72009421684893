import React from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col } from 'react-grid-system';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { TimesheetDraft, Timesheet } from 'src/api/models/timesheet';
import { DraftCard } from './DraftCard';

interface IDraftsTableProps {
    drafts: TimesheetDraft[];
    timesheets: Timesheet[];
    loading?: boolean;
}

const columns: GridColDef[] = [
    {
        field: 'status',
        headerName: 'Status',
        renderCell: (params: GridValueGetterParams) => {
            return <span style={{ textTransform: 'capitalize' }}>{params.row.status}</span>;
        },
    },
    {
        field: 'employee',
        headerName: 'Employee',
        minWidth: 250,
        renderCell: (params: GridValueGetterParams) => {
            return params.row.employee.full_name;
        },
    },
    {
        field: 'created_by',
        headerName: 'Created By',
        minWidth: 250,
        renderCell: (params: GridValueGetterParams) => {
            return params.row.created_by.full_name;
        },
    },
];

export const DraftsTable: React.FC<IDraftsTableProps> = ({ drafts, loading = true, timesheets }) => {
    const history = useHistory();
    const renderDrafts = () => {
        return drafts.map((draft) => {
            return (
                <Col key={draft.id} xs={12} md={12} lg={6} xl={4}>
                    <DraftCard draft={draft} />
                </Col>
            );
        });
    };
    return (
        <>
            <h4>In Progress</h4>
            {drafts.length === 0 && <p>No in progress timesheets</p>}
            <Row>{renderDrafts()}</Row>
            <h4>Completed</h4>
            <div style={{ height: 600, width: '100%', backgroundColor: '#fff', marginBottom: 20 }}>
                <DataGrid
                    sx={{
                        '& .MuiDataGrid-cell': {
                            cursor: 'pointer',
                        },
                    }}
                    disableSelectionOnClick
                    disableColumnSelector
                    onCellClick={async (params) => {
                        history.push(`/jobs/${params.row.job_id}/timesheets/view/${params.row.id}`);
                    }}
                    loading={loading}
                    rows={timesheets}
                    columns={columns}
                    pageSize={20}
                    rowsPerPageOptions={[20]}
                />
            </div>
        </>
    );
};
