import React from 'react';
import groupBy from 'lodash/groupBy';
import { Card } from '@blueprintjs/core';
import { RateSheetSection } from './RateSheetSection';
import { IRateResponse } from 'src/api/models/Rate';

interface IRateSheetCardProps {
    jobId: number;
    rates: IRateResponse[];
    onRatesChanged: () => void;
    interactable?: boolean;
}

interface IGroupedRates {
    labor: IRateResponse[];
    equipment: IRateResponse[];
    material: IRateResponse[];
}
export const RateSheetCard: React.FC<IRateSheetCardProps> = ({ jobId, rates, onRatesChanged, interactable = false }) => {
    const [groupedRates, setGroupedRates] = React.useState<IGroupedRates>({ labor: [], equipment: [], material: [] });
    React.useEffect(() => {
        const grouped = groupBy(rates, 'category');
        const obj = {
            labor: grouped.labor || [],
            equipment: grouped.equipment || [],
            material: grouped.material || [],
        };
        setGroupedRates(obj);
    }, [rates]);

    return (
        <Card>
            <h3 className="m-b-10 m-t-0">Company Rate Sheet</h3>
            <RateSheetSection title="Labor Type" interactable={interactable} rates={groupedRates.labor} category="labor" jobId={jobId} measurement="hr" onRateAdded={onRatesChanged} />
            <hr className="m-t-15" />
            <RateSheetSection title="Equipment" interactable={interactable} rates={groupedRates.equipment} category="equipment" jobId={jobId} measurement="hr" onRateAdded={onRatesChanged} />
            <hr className="m-t-15" />
            <RateSheetSection title="Material" interactable={interactable} rates={groupedRates.material} category="material" jobId={jobId} measurement="cy" onRateAdded={onRatesChanged} />
        </Card>
    );
};
