import React from 'react';
import Select, { Props } from 'react-select';
import { listItems } from 'src/api/checklists';

interface IChecklistItem {
    id: string;
    title: string;
}

interface IChecklistItemSelectorProps extends Props {
    value: IChecklistItem | null;
    jobId?: number;
}

export const ChecklistItemSelector: React.FC<IChecklistItemSelectorProps> = ({ value, onChange, isDisabled, jobId }) => {
    const [options, setOptions] = React.useState<IChecklistItem[]>([]);
    React.useEffect(() => {
        loadItems();
    }, []);
    const loadItems = async () => {
        try {
            if (jobId) {
                const data = await listItems(jobId, null);
                setOptions(data);
            }
        } catch (err) {
            console.log('error: ', err.toLocaleString());
        }
    };
    return (
        <>
            <Select
                isClearable
                isDisabled={isDisabled}
                className="basic-single"
                placeholder="Select an item..."
                classNamePrefix="react-select"
                getOptionLabel={(o: IChecklistItem) => o.title}
                getOptionValue={(o: IChecklistItem) => o.id}
                value={value}
                options={options}
                onChange={onChange}
            />
        </>
    );
};
