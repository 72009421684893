import { getAuthOptions } from './helpers';
import AppApi from './index';
import { IFolderPayload, IDocument, IDocumentUpdatePayload } from './models/Document';

const api = AppApi.getApi();

export const uploadDocument = async (jobId: number, parentId: string | null, file: File): Promise<IDocument> => {
    const formData = new FormData();
    formData.append('file', file);
    const url = parentId ? `/jobs/${jobId}/documents/upload/${parentId}` : `/jobs/${jobId}/documents/upload`;
    const authOptions = await getAuthOptions();
    const options = {
        ...authOptions,
        headers: {
            ...authOptions.headers,
            'Content-Type': 'multipart/form-data',
        },
    };
    const result = await api.post(url, formData, options);
    return result.data;
};

export const uploadDocumentVersion = async (jobId: number, documentId: string, file: File): Promise<IDocument> => {
    const formData = new FormData();
    formData.append('file', file);
    const url = `/jobs/${jobId}/versions/documents/${documentId}`;
    const authOptions = await getAuthOptions();
    const options = {
        ...authOptions,
        headers: {
            ...authOptions.headers,
            'Content-Type': 'multipart/form-data',
        },
    };
    const result = await api.post(url, formData, options);
    return result.data;
};

export const downloadDocument = async (jobId: number, documentId: string): Promise<IDocument> => {
    const url = `/jobs/${jobId}/documents/download/${documentId}`;
    const result = await api.get(url, await getAuthOptions());
    const link = document.createElement('a');
    link.href = result.data.url;
    link.download = result.data.name;
    link.click();
    return result.data;
};

export const getDocumentById = async (jobId: number, documentId: string): Promise<IDocument> => {
    const url = `/jobs/${jobId}/documents/${documentId}`;
    const result = await api.get(url, await getAuthOptions());
    return result.data;
};

export const downloadVersionDocument = async (jobId: number, documentId: string): Promise<IDocument> => {
    const url = `/jobs/${jobId}/documents/download/version/${documentId}`;
    const result = await api.get(url, await getAuthOptions());
    const link = document.createElement('a');
    link.href = result.data.url;
    link.download = result.data.name;
    link.click();
    return result.data;
};

export const listDocuments = async (jobId: number, parentId: string | null): Promise<IDocument[]> => {
    const url = parentId ? `/jobs/${jobId}/documents/folders/${parentId}` : `/jobs/${jobId}/documents`;
    const { data } = await api.get(url, await getAuthOptions());
    return data;
};

export const listFolders = async (jobId: number, parentId: string | null): Promise<IDocument[]> => {
    const url = parentId ? `/jobs/${jobId}/folders/${parentId}` : `/jobs/${jobId}/folders`;
    const { data } = await api.get(url, await getAuthOptions());
    return data;
};

export const createFolder = async (jobId: number, payload: IFolderPayload): Promise<IDocument> => {
    const url = `/jobs/${jobId}/documents/folders`;
    const { data } = await api.post(url, payload, await getAuthOptions());
    return data;
};

export const updateFolder = async (jobId: number, folderId: string, payload: IFolderPayload): Promise<IDocument> => {
    const url = `/jobs/${jobId}/documents/folders/${folderId}`;
    const { data } = await api.put(url, payload, await getAuthOptions());
    return data;
};

export const updateDocument = async (jobId: number, documentId: string, payload: IDocumentUpdatePayload): Promise<IDocument> => {
    const url = `/jobs/${jobId}/documents/${documentId}`;
    const { data } = await api.put(url, payload, await getAuthOptions());
    return data;
};

export const removeFolder = async (jobId: number, folderId: string): Promise<IDocument> => {
    const url = `/jobs/${jobId}/documents/folders/${folderId}`;
    const { data } = await api.delete(url, await getAuthOptions());
    return data;
};

export const removeDocument = async (jobId: number, documentId: string): Promise<IDocument> => {
    const url = `/jobs/${jobId}/documents/${documentId}`;
    const { data } = await api.delete(url, await getAuthOptions());
    return data;
};
