import React from 'react';
import { Card } from '@blueprintjs/core';
import { useOpenWeather } from 'react-open-weather';
import { IJob } from 'src/api/models/Job';
interface IWeatherCardProps {
    job: IJob;
    onWeatherLoaded: (data: any) => void;
    initialWeather: any;
}

export const WeatherCard: React.FC<IWeatherCardProps> = ({ job, onWeatherLoaded, initialWeather }) => {
    const { data } = useOpenWeather({
        key: '4eec3031198d60147b84d98f281f8354',
        lat: job.location.geo?.lat,
        lon: job.location.geo?.lng,
        lang: 'en',
        unit: 'imperial', // values are (metric, standard, imperial)
    });
    React.useEffect(() => {
        if (!data || initialWeather) return;
        onWeatherLoaded(data);
    }, [data]);

    if (!data) return null;
    if (initialWeather) {
        return (
            <>
                <Card>
                    <div className="m-0 text-center">
                        {initialWeather.current.temperature.current} Degrees - <span style={{ textTransform: 'capitalize' }}>{initialWeather.current.description}</span> - {initialWeather.current.wind}{' '}
                        mph
                    </div>
                </Card>
            </>
        );
    }
    return (
        <>
            <Card>
                <div className="m-0 text-center">
                    {data.current.temperature.current} Degrees - <span style={{ textTransform: 'capitalize' }}>{data.current.description}</span> - {data.current.wind} mph
                </div>
            </Card>
        </>
    );
};
