import React from 'react';
import { Label } from '@blueprintjs/core';
import { Autocomplete, createFilterOptions } from '@mui/material';
import TextField from '@mui/material/TextField';
import { IUser } from 'src/api/models/User';
import { loadJobUsers } from 'src/api/user';

interface IJobUserSelectProps {
    jobId: number;
    onChange: (value: IUser[]) => void;
    onCustomChange?: (value: IUser[]) => void;
    value: IUser[];
    customValues?: IUser[];
    hideLabel?: boolean;
    placeholder?: string;
    disabled?: boolean;
}
function getRandomInt(max: number) {
    return Math.floor(Math.random() * max);
}

const filter = createFilterOptions<IUser>();
export const JobUserSelect2: React.FC<IJobUserSelectProps> = ({ onChange, jobId, onCustomChange, customValues = [], disabled = false, value, hideLabel = false, placeholder = 'Select users...' }) => {
    const [options, setOptions] = React.useState<IUser[]>([]);

    React.useEffect(() => {
        loadUserOptions();
    }, [jobId]);

    const loadUserOptions = async () => {
        try {
            const users = await loadJobUsers(jobId);
            setOptions(users);
        } catch (err) {
            console.log('error: ', err.toLocaleString());
        }
    };
    return (
        <div style={{ flexGrow: 1 }}>
            {!hideLabel && <Label className="text-bold">User</Label>}
            <Autocomplete
                multiple
                disabled={disabled}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                size="small"
                id="plexus-user-select"
                noOptionsText="No users found..."
                options={options}
                getOptionLabel={(option) => option.full_name}
                isOptionEqualToValue={(option, value) => {
                    return option.id === value.id;
                }}
                renderOption={(props, option) => {
                    return (
                        <li {...props} key={option.id}>
                            {option.id > 0 ? option.full_name : `Add "${option.full_name}"`}
                        </li>
                    );
                }}
                freeSolo
                autoHighlight
                value={[...value, ...customValues]}
                defaultValue={[...value, ...customValues]}
                onChange={(event, newValue: any) => {
                    const users = newValue.filter((u: any) => u.id > 0);
                    const custom = newValue.filter((u: any) => u.id < 0);
                    onChange(users.length > 0 ? users : []);
                    if (onCustomChange) {
                        onCustomChange(custom);
                    }
                }}
                filterOptions={(options, params) => {
                    const filtered = filter(options, params);

                    if (params.inputValue !== '') {
                        filtered.push({
                            id: getRandomInt(1000) * -1,
                            avatar: '',
                            email: '',
                            company_name: '',
                            first_name: '',
                            last_name: '',
                            status: '',
                            apps: [],
                            roles: [],
                            offices: [],
                            full_name: params.inputValue,
                            display: params.inputValue,
                        });
                    }

                    return filtered;
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="outlined"
                        placeholder={placeholder}
                        // value={query}
                        // onChange={(e) => setQuery(e.target.value)}
                        InputProps={{
                            ...params.InputProps,
                            size: 'small',
                            // onBlur: () => setQuery(''),
                            endAdornment: (
                                <React.Fragment>
                                    {/*{loading ? <CircularProgress color="inherit" size={20} /> : null}*/}
                                    {params.InputProps.endAdornment}
                                </React.Fragment>
                            ),
                        }}
                    />
                )}
            />
        </div>
    );
};
