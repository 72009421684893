import React from 'react';

interface TabPanelProps {
    tab: string;
    value: string;
    className?: string;
}

export const TabPanel: React.FC<TabPanelProps> = ({ children, tab, value, className = 'p-20' }) => {
    return (
        <div role="tabpanel" className={className} style={{ height: '100%' }} hidden={value !== tab}>
            {value === tab && <>{children}</>}
        </div>
    );
};
