import React from 'react';
import { useHistory } from 'react-router-dom';
import { useJobs } from '../../../hooks/useJobs';
import { Row, Col } from 'react-grid-system';
import { Button, Spinner } from '@blueprintjs/core';
import { listDailys } from 'src/api/dailys';
import { IDailyResponse } from '../../../api/models/Daily';
import { DailyTable } from './DailyTable';
import { Header2 } from '../../../components/Header2';

export const Dailys: React.FC = () => {
    const { selectedJob } = useJobs();
    const history = useHistory();
    const [dailys, setDailys] = React.useState<IDailyResponse[]>([]);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState<string | null>(null);
    React.useEffect(() => {
        loadDailys();
    }, []);

    const loadDailys = async () => {
        if (!selectedJob) return;
        setLoading(true);
        setError(null);
        try {
            const data = await listDailys(selectedJob.id);
            setDailys(data);
        } catch (err) {
            setError(err.toLocaleString());
        }
        setLoading(false);
    };

    if (!selectedJob) return null;
    return (
        <>
            <Header2
                breadcrumbs={[
                    { title: 'Jobs', link: '/jobs' },
                    { title: `${selectedJob?.name} - ${selectedJob?.job_number}`, link: `/jobs/${selectedJob?.id}/summary` },
                    { title: 'Dailys', link: '' },
                ]}
                actionComponent={
                    <Button icon="add" intent="primary" onClick={() => history.push(`/jobs/${selectedJob?.id}/dailys/submit`)}>
                        Create Daily
                    </Button>
                }
            />
            {error && <p className="text-center">{error}</p>}
            {loading ? (
                <div className="text-center m-t-100">
                    <Spinner />
                </div>
            ) : (
                <Row>
                    <Col>
                        <DailyTable dailys={dailys} loading={loading} />
                    </Col>
                </Row>
            )}
        </>
    );
};
