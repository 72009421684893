import React from 'react';
import { MenuItem, TagProps } from '@blueprintjs/core';
import { ItemRenderer, MultiSelect, ItemPredicate } from '@blueprintjs/select';
import { IOrganizationRole } from 'src/api/models/Organization';
import { useOrganization } from 'src/hooks/useOrganization';

const RoleSelect = MultiSelect.ofType<IOrganizationRole>();

interface IRoleSelectorProps {
    onChange: (role: IOrganizationRole[]) => void;
    initialValue?: IOrganizationRole[];
    placeholder?: string;
    // value?: IOrganizationRole[];
}

export const RoleSelector: React.FC<IRoleSelectorProps> = ({ onChange, initialValue = [], placeholder = 'Select user roles...' }) => {
    const { roles } = useOrganization();
    const [selected, setSelected] = React.useState<IOrganizationRole[]>(initialValue);

    React.useEffect(() => {
        onChange(selected);
    }, [selected]);

    const onValueChange = (v: IOrganizationRole) => {
        const isSelected = isValueSelected(v);
        if (!isSelected) {
            setSelected((prev) => [...prev, v]);
        } else {
            deselectValue(v);
        }
    };

    const deselectValue = (v: IOrganizationRole) => {
        setSelected((prev) => prev.filter((p) => p.id !== v.id));
    };

    const getTagProps = (_value: React.ReactNode): TagProps => ({
        intent: 'none',
        minimal: true,
    });

    const isValueSelected = (v: IOrganizationRole) => {
        return !!selected.find((s) => s.id === v.id);
    };

    const filterData: ItemPredicate<IOrganizationRole> = (query, role, _index, exactMatch) => {
        const normalizedTitle = role.name.toLowerCase();
        const normalizedQuery = query.toLowerCase();
        if (exactMatch) {
            return normalizedTitle === normalizedQuery;
        } else {
            return `${role.name}. ${normalizedTitle}`.indexOf(normalizedQuery) >= 0;
        }
    };

    const renderTag = (role: IOrganizationRole) => role.name;

    const renderItem: ItemRenderer<IOrganizationRole> = (role, { modifiers, handleClick }) => {
        if (!modifiers.matchesPredicate) {
            return null;
        }
        return <MenuItem icon={isValueSelected(role) ? 'tick' : 'blank'} key={role.id} onClick={handleClick} text={role.name} />;
    };

    return (
        <RoleSelect
            placeholder={placeholder}
            selectedItems={selected}
            itemPredicate={filterData}
            //itemListRenderer={itemListRenderer}
            fill
            tagRenderer={renderTag}
            tagInputProps={{
                tagProps: getTagProps,
            }}
            itemRenderer={renderItem}
            items={roles}
            noResults={<MenuItem disabled={true} text="No results." />}
            onItemSelect={onValueChange}
            onRemove={deselectValue}
        />
    );
};
