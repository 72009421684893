import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CircularProgress from '@mui/material/CircularProgress';

interface ConfirmDeleteDialogProps {
    open: boolean;
    onCancel: () => void;
    onConfirm: () => void;
    deleting: boolean;
}
export const ConfirmDeleteDialog: React.FC<ConfirmDeleteDialogProps> = ({ open, onConfirm, onCancel, deleting }) => {
    return (
        <Dialog open={open} onClose={onCancel} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">{'Are you sure?'}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">Are you sure you want to perminantly delete this post?</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onCancel}>Cancel</Button>
                <Button color="error" variant="outlined" onClick={onConfirm} autoFocus>
                    {deleting ? <CircularProgress color="primary" size={18} sx={{ marginRight: '0px' }} /> : <>Delete Post</>}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
