import AppApi from './index';
import { IJob, ITeamUser, JobSummary } from './models/Job';
import { getAuthOptions } from './helpers';

const api = AppApi.getApi();

export const loadJobSummary = async (id: number): Promise<JobSummary> => {
    const url = `/jobs/${id}/summary`;
    const { data } = await api.get(url, await getAuthOptions());
    return data;
};

export const loadJobById = async (id: string): Promise<IJob> => {
    const url = `/jobs/${id}`;
    const { data } = await api.get(url, await getAuthOptions());
    return data;
};

export const loadJobs = async (): Promise<IJob[]> => {
    const url = `/jobs`;
    const { data } = await api.get(url, await getAuthOptions());
    return data;
};
//name, description, id: -1, client_id: client.id, organization_id: client.organization_id
export const createJob = async (job: Pick<IJob, 'name' | 'description' | 'id' | 'client_id' | 'organization_id' | 'job_number' | 'location' | 'division'>): Promise<IJob> => {
    const url = `/jobs`;
    const { data } = await api.post(url, job, await getAuthOptions());
    return data;
};

export const updateJob = async (jobId: number, job: Pick<IJob, 'name' | 'description' | 'client' | 'client_id' | 'job_number' | 'location' | 'division'>): Promise<IJob> => {
    const url = `/jobs/${jobId}`;
    const { data } = await api.put(url, job, await getAuthOptions());
    return data;
};

export const loadJobTeam = async (id: number): Promise<ITeamUser[]> => {
    const url = `/jobs/${id}/team`;
    const { data } = await api.get(url, await getAuthOptions());
    return data;
};

export const addMemberToJobTeam = async (jobId: number, userId: number): Promise<ITeamUser> => {
    const url = `/jobs/${jobId}/team`;
    const { data } = await api.post(url, { userId }, await getAuthOptions());
    return data;
};

export const removeMemberFromTeam = async (jobId: number, userId: number): Promise<ITeamUser> => {
    const url = `/jobs/${jobId}/team/${userId}`;
    const { data } = await api.delete(url, await getAuthOptions());
    return data;
};

export const updateJobWorkflow = async (id: number, workflowSettings: any): Promise<IJob> => {
    const url = `/jobs/${id}/workflowSettings`;
    const { data } = await api.post(url, workflowSettings, await getAuthOptions());
    return data;
};

export const loadTeamByRoles = async (id: number, roles: number[]): Promise<ITeamUser[]> => {
    const url = `/jobs/${id}/teamByRoles`;
    const { data } = await api.post(url, { roles }, await getAuthOptions());
    return data;
};
