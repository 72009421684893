import React from 'react';
import { useHistory } from 'react-router-dom';
import { useJobs } from '../../../hooks/useJobs';
import { Row, Col } from 'react-grid-system';
import { Button } from '@blueprintjs/core';
import { IWorkTicketResponse } from 'src/api/models/WorkTicket';
import { listWorkTickets } from '../../../api/tickets';
import { TicketsTable } from './TicketsTable';
import { Header2 } from '../../../components/Header2';
export const Tickets: React.FC = () => {
    const { selectedJob } = useJobs();
    const history = useHistory();
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState<string | null>(null);
    const [tickets, setTickets] = React.useState<IWorkTicketResponse[]>([]);

    React.useEffect(() => {
        loadTickets();
    }, []);

    const loadTickets = async () => {
        if (!selectedJob) return;
        setLoading(true);
        setError(null);

        try {
            const data = await listWorkTickets(selectedJob.id);
            setTickets(data);
        } catch (err) {
            setError(err.toLocaleString());
        }
        setLoading(false);
    };
    return (
        <>
            <Header2
                breadcrumbs={[
                    { title: 'Jobs', link: '/jobs' },
                    { title: `${selectedJob?.name} - ${selectedJob?.job_number}`, link: `/jobs/${selectedJob?.id}/summary` },
                    { title: 'Work Tickets', link: '' },
                ]}
                actionComponent={
                    <Button icon="add" intent="primary" onClick={() => history.push(`/jobs/${selectedJob?.id}/tickets/create`)}>
                        Create Work Ticket
                    </Button>
                }
            />
            {selectedJob && (
                <Row>
                    <Col>
                        {error && <p>{error}</p>}
                        <TicketsTable tickets={tickets} loading={loading} />
                    </Col>
                </Row>
            )}
        </>
    );
};
