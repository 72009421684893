import React from 'react';
import { Classes, Button, FormGroup, InputGroup, Intent, Label, Colors, TextArea } from '@blueprintjs/core';
import { Row, Col } from 'react-grid-system';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { ScopePicker } from 'src/components/ScopePicker';
import { createOrganizationRole, updateOrganizationRole } from 'src/api/organization';
import { IOrganizationRole } from 'src/api/models/Organization';
import { useOrganization } from 'src/hooks/useOrganization';
import { BottomToast } from '../../Toasts';

const validationSchema = Yup.object().shape({
    name: Yup.string().required().min(1, 'Name Required').label('Name'),
    description: Yup.string().required().min(1, 'Description Required'),
    scopes: Yup.array().required().min(1, 'At least one scope required'),
});

const initialValues: IOrganizationRole = {
    id: -1,
    name: '',
    scopes: [],
    description: '',
    users: [],
};

interface ICreateRoleForm {
    selectedRole?: IOrganizationRole;
    setCreateRoleDialogOpen: (isOpen: boolean, role?: IOrganizationRole) => void;
}

export const CreateRoleForm: React.FC<ICreateRoleForm> = ({ selectedRole, setCreateRoleDialogOpen }) => {
    const { onRoleCreated, onRoleUpdated } = useOrganization();
    const [error, setError] = React.useState<string | null>(null);
    const closeDialog = () => {
        setCreateRoleDialogOpen(false);
        formik.resetForm();
    };
    const formik = useFormik({
        initialValues: selectedRole ? selectedRole : initialValues,
        validationSchema,
        validateOnMount: true,
        onSubmit: async (values) => {
            setError(null);
            try {
                if (selectedRole) {
                    const result = await updateOrganizationRole(values);
                    onRoleUpdated(result);
                    setCreateRoleDialogOpen(false);
                    BottomToast.show({ message: 'Role successfully updated!', intent: 'success', icon: 'updated' });
                } else {
                    const result = await createOrganizationRole(values);
                    onRoleCreated(result);
                    setCreateRoleDialogOpen(false);
                    BottomToast.show({ message: 'Role successfully created!', intent: 'success', icon: 'tick-circle' });
                }
            } catch (err) {
                setError(err.message);
                BottomToast.show({ message: err.message, intent: 'danger', icon: 'warning-sign' });
            }
        },
    });

    const onScopeChange = React.useMemo(
        () => (selectedScopes: string[]) => {
            return formik.setFieldValue('scopes', selectedScopes);
        },

        [formik.setFieldValue],
    );
    return (
        <div className={Classes.DRAWER_BODY} style={{ backgroundColor: '#ebf1f5' }}>
            <div className={Classes.DIALOG_BODY}>
                <Row>
                    <Col>
                        <FormGroup label="Role Name" labelFor="role-name-input" labelInfo="*">
                            <InputGroup
                                autoFocus
                                autoComplete="off"
                                id="role-name-input"
                                placeholder="What do you want to call this role?"
                                value={formik.values.name}
                                onChange={(e) => formik.setFieldValue('name', e.target.value)}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup label="Description" labelInfo="*">
                            <TextArea
                                placeholder="What is this role used for?"
                                fill
                                growVertically={true}
                                value={formik.values.description}
                                onChange={(e) => formik.setFieldValue('description', e.target.value)}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Label className="m-0">Select Scopes</Label>
                        <ScopePicker hideLabel onChange={onScopeChange} initialSelectedScopes={formik.values.scopes} />
                    </Col>
                </Row>
                {error && (
                    <p className="m-b-0" style={{ color: Colors.RED5 }}>
                        {error}
                    </p>
                )}
            </div>
            <div className="m-b-20">
                <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Button onClick={closeDialog}>Cancel</Button>
                        <Button onClick={() => formik.handleSubmit()} intent={Intent.PRIMARY} loading={formik.isSubmitting} disabled={!formik.isValid || !formik.dirty}>
                            {selectedRole ? 'Save Changes' : 'Create Role'}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};
