import AppApi from './index';
import { getAuthOptions } from './helpers';
import { IDrawing } from './models/Drawing';

const api = AppApi.getApi();

export const loadDrawingById = async (jobId: number, id: string): Promise<IDrawing> => {
    const url = `/jobs/${jobId}/drawings/${id}`;
    const result = await api.get(url, await getAuthOptions());
    return result.data;
};

export const loadJobDrawings = async (jobId: number): Promise<IDrawing[]> => {
    const url = `/jobs/${jobId}/drawings`;
    const result = await api.get(url, await getAuthOptions());
    return result.data;
};

export const createDrawing = async (jobId: number, data: IDrawing): Promise<IDrawing> => {
    const url = `/jobs/${jobId}/drawings`;
    const result = await api.post(url, data, await getAuthOptions());
    return result.data;
};

export const updateDrawing = async (jobId: number, data: IDrawing): Promise<IDrawing> => {
    const url = `/jobs/${jobId}/drawings/${data.id}`;
    const result = await api.put(url, data, await getAuthOptions());
    return result.data;
};
