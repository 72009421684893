import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { IDrawing } from 'src/api/models/Drawing';
import { Card, FormGroup, InputGroup, Button } from '@blueprintjs/core';
import { Col, Row } from 'react-grid-system';
import { IJob } from 'src/api/models/Job';
import { useAuth } from 'src/hooks/useAuth';
import { JobUserSelect } from 'src/components/Selectors/JobUserSelect/single';
import { FileDrop } from 'src/components/FileDrop';
import { WorkflowButtons } from '../../../../components/Workflow/WorkflowButtons';
import { createDrawing, updateDrawing } from 'src/api/drawings';
import { ChecklistItemSelector } from 'src/components/Selectors/ChecklistItemSelector';
import { AppToast } from '../../../../components/Toasts/AppToast';

const validationSchema = Yup.object().shape({
    title: Yup.string().required().min(1, 'Title Required').label('Title'),
});

interface IDrawingFormProps {
    initialValues: IDrawing;
    selectedJob: IJob;
    onSaved?: (drawing: IDrawing) => void;
    onCreated?: (drawing: IDrawing) => void;
}

export const DrawingForm: React.FC<IDrawingFormProps> = ({ onSaved, onCreated, initialValues, selectedJob }) => {
    const { user, isAdmin } = useAuth();
    const [error, setError] = React.useState<string | null>(null);
    const formik = useFormik({
        initialValues,
        validationSchema,
        validateOnMount: true,
        onSubmit: async () => {
            console.log('submit');
        },
    });
    const setValue = ({ target }: any) => {
        setFieldValue(target.name, target.value);
    };

    const onVoidClick = async () => {
        if (!selectedJob) return;
        setError(null);
        try {
            const updatedDrawing = {
                ...values,
                status: 'Voided',
            };
            const result = await createDrawing(selectedJob.id, updatedDrawing);
            const updated = {
                ...updatedDrawing,
                ...result,
                files_to_remove: [],
                files_to_add: [],
            };
            await formik.setValues(updated);
            if (onSaved) {
                onSaved(updated);
            }
            AppToast.show({ message: `${result.drawing_number} Voided!`, intent: 'success', icon: 'tick' });
            //await formik.setValues(result);
            //onSaved(result);
        } catch (err) {
            setError(err.toLocaleString());
        }
    };

    const onSavedOnlyClicked = async () => {
        if (!selectedJob) return;
        setError(null);
        try {
            const updatedDrawing = {
                ...values,
                save_only: true,
            };
            const result = await updateDrawing(selectedJob.id, updatedDrawing);
            const updated = {
                ...values,
                ...result,
                files_to_remove: [],
                files_to_add: [],
            };
            await formik.setValues(updated);
            if (onSaved) {
                onSaved(updated);
            }
            AppToast.show({ message: `${result.drawing_number} Updated!`, intent: 'success', icon: 'tick' });
        } catch (err) {
            setError(err.toLocaleString());
        }
    };

    const saveForInfoOnly = async () => {
        if (values.workflow) {
            await onSubmitClick(values.workflow.steps[values.workflow.steps.length - 1].label, values.step_index, true);
        }
    };

    const onReject = async () => {
        await onSubmitClick('Rejected', -1);
    };

    const onSubmitClick = async (status: string, step_index: number, saveOnly = false) => {
        if (!selectedJob) return;
        setError(null);
        try {
            const updatedDrawing = {
                ...values,
                step_index,
                status,
                save_only: saveOnly,
            };
            if (values.id === '') {
                const result = await createDrawing(selectedJob.id, updatedDrawing);
                const final = {
                    ...updatedDrawing,
                    ...result,
                    files_to_remove: [],
                    files_to_add: [],
                };
                await formik.setValues(final);
                if (onCreated) {
                    onCreated(final);
                }
                AppToast.show({ message: `${result.drawing_number} Created!`, intent: 'success', icon: 'tick' });
            } else {
                const result = await updateDrawing(selectedJob.id, updatedDrawing);
                const final = {
                    ...updatedDrawing,
                    ...result,
                    files_to_remove: [],
                    files_to_add: [],
                };
                if (values.status !== result.status) {
                    AppToast.show({ message: `${result.drawing_number} ${result.status}`, intent: 'success', icon: 'tick' });
                } else {
                    AppToast.show({ message: `${result.drawing_number} Updated!`, intent: 'success', icon: 'tick' });
                }
                await formik.setValues(final);
                if (onSaved) {
                    onSaved(final);
                }
            }
        } catch (err) {
            setError(err.toLocaleString());
        }
    };

    const { values, setFieldValue } = formik;
    const isDisabled = (values.id !== '' || values.status === 'Voided') && !isAdmin;
    const disableSubmit = values.files_to_add.length === 0 && values.attachments.length === 0;
    const reporter = values.reporter ? values.reporter.full_name : `${user?.first_name} ${user?.last_name}`;
    return (
        <div className="p-b-20">
            <Card>
                <h3 className="m-t-0">Details</h3>
                <Row>
                    <Col>
                        <FormGroup label="Job" labelFor="job">
                            <InputGroup id="job" value={selectedJob.name} disabled fill />
                        </FormGroup>
                        <FormGroup label="Client" labelFor="client">
                            <InputGroup id="client" value={`${selectedJob.client?.name}`} disabled />
                        </FormGroup>
                        <FormGroup label="Reporter" labelFor="reporter">
                            <InputGroup id="reporter" value={reporter} disabled />
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup label="Number" labelFor="number">
                            <InputGroup id="number" name="drawing_number" value={values.drawing_number} disabled placeholder="Auto Generated" />
                        </FormGroup>
                        <FormGroup label="Drawing # (External)" labelFor="external_drawing_number">
                            <InputGroup
                                id="external_drawing_number"
                                name="external_drawing_number"
                                disabled={isDisabled}
                                value={isDisabled && values.external_drawing_number === '' ? 'N/A' : values.external_drawing_number}
                                onChange={setValue}
                                placeholder="ex: DRAWING-XXXX"
                            />
                        </FormGroup>
                        <FormGroup label="Status" labelFor="status">
                            <InputGroup id="status" name="status" value={values.status} disabled placeholder="N/A" />
                        </FormGroup>
                    </Col>
                </Row>
                {isAdmin && (
                    <FormGroup label="Assigned Workflow" labelFor="workflow" labelInfo="(Admin Only)">
                        <InputGroup id="workflow" name="workflow" value={values.workflow?.name} disabled />
                    </FormGroup>
                )}
            </Card>
            <Card className="m-t-20 m-b-20">
                <h3 className="m-t-0">Drawing</h3>
                <Row>
                    <Col>
                        <FormGroup label="Title" labelFor="title">
                            <InputGroup
                                id="title"
                                name="title"
                                disabled={isDisabled}
                                value={isDisabled && values.title === '' ? 'N/A' : values.title}
                                onChange={setValue}
                                placeholder="Enter title..."
                            />
                        </FormGroup>
                        <FormGroup label="Assignee" labelInfo="*">
                            <JobUserSelect
                                hideLabel
                                small
                                hideRole
                                selectedUserId={formik.values.assignee_user_id}
                                onChange={(value) => {
                                    if (value) {
                                        formik.setFieldValue('assignee_user_id', value.user_id);
                                        formik.setFieldValue('company', value.user.company_name);
                                    } else {
                                        formik.setFieldValue('assignee_user_id', null);
                                        formik.setFieldValue('company', '');
                                    }
                                }}
                                disabled={isDisabled}
                            />
                        </FormGroup>
                        <FormGroup label="Linked Checklist Item">
                            <ChecklistItemSelector value={values.checklist_item} onChange={(item) => formik.setFieldValue('checklist_item', item)} jobId={selectedJob.id} isDisabled={isDisabled} />
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup label="Intent of Review" labelFor="intent">
                            <div className="bp3-html-select bp3-fill">
                                <select
                                    value={values.intent_of_review}
                                    onChange={(e) => {
                                        setFieldValue('intent_of_review', e.target.value);
                                    }}
                                >
                                    {/*<option selected>Choose a type...</option>*/}
                                    <option value="review">Issued for Review and Acceptance</option>
                                    <option value="info_only">Issued for Information Only</option>
                                </select>
                                <span className="bp3-icon bp3-icon-double-caret-vertical" />
                            </div>
                        </FormGroup>
                        <FormGroup label="Company Name" labelFor="company" labelInfo="*">
                            <InputGroup
                                id="company"
                                disabled={isDisabled}
                                name="company"
                                value={isDisabled && values.company === '' ? 'N/A' : values.company}
                                onChange={setValue}
                                placeholder="What's the company name?"
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <hr className="m-b-20" />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup label="Document" labelInfo="*">
                            <FileDrop
                                maxFiles={1}
                                filesToRemove={values.files_to_remove}
                                showRevisions
                                revisions={values.revisions}
                                onFilesAdded={(files) => setFieldValue('files_to_add', files)}
                                onFilesRemoved={(files) => setFieldValue('files_to_remove', files)}
                                existingFiles={values.attachments}
                            />
                        </FormGroup>
                    </Col>
                </Row>
            </Card>
            {values.intent_of_review === 'info_only' ? (
                <Card className="text-center">
                    {values.id === '' || values.status === 'Rejected' ? (
                        <Button disabled={disableSubmit} onClick={() => saveForInfoOnly()}>
                            Save and Close
                        </Button>
                    ) : (
                        <>
                            <Button disabled={disableSubmit} intent="danger" className="m-r-10" onClick={() => onReject()}>
                                Reject
                            </Button>
                            <Button disabled={disableSubmit} onClick={() => saveForInfoOnly()}>
                                Save Updates
                            </Button>
                        </>
                    )}
                </Card>
            ) : (
                <>
                    {values.status !== 'Voided' && (
                        <WorkflowButtons
                            disableSubmit={disableSubmit}
                            hideAssignToSelector
                            onAssignedToChange={(value) => {
                                if (value) {
                                    formik.setFieldValue('assignee_user_id', value.user_id);
                                    formik.setFieldValue('company', value.user.company_name);
                                } else {
                                    formik.setFieldValue('assignee_user_id', null);
                                    formik.setFieldValue('company', '');
                                }
                            }}
                            assignedTo={values.assignee_user_id}
                            // singleAssignee
                            // onlyAllow={values.assignee_user_id}
                            error={error}
                            onExternalEmailChange={(email) => setFieldValue('external_email', email)}
                            onSubmitClick={onSubmitClick}
                            workflow={values.workflow}
                            onSaveOnlyClick={onSavedOnlyClicked}
                            onVoidClick={onVoidClick}
                            currentStatus={values.status}
                            stepIndex={values.step_index}
                        />
                    )}
                </>
            )}
        </div>
    );
};
