import AppApi from './index';
import { getAuthOptions } from './helpers';
import { IAttachment } from './models/Attachment';
const api = AppApi.getApi();

interface IAttachmentURLResponse {
    url: string;
}

export const upload = async (file: File): Promise<IAttachment> => {
    const formData = new FormData();
    formData.append('file', file);
    const url = `/attachments/upload/tmp`;
    const authOptions = await getAuthOptions();
    const options = {
        ...authOptions,
        headers: {
            ...authOptions.headers,
            'Content-Type': 'multipart/form-data',
        },
    };
    const result = await api.post(url, formData, options);
    return result.data;
};

export const getImageUrl = async (attachmentId: string): Promise<string> => {
    const url = `/attachments/download/${attachmentId}`;
    const result = await api.get(url, await getAuthOptions());
    return result.data.url;
};

export const download = async (attachmentId: string): Promise<IAttachmentURLResponse> => {
    const url = `/ctc/attachments/download/${attachmentId}`;
    const result = await api.get(url, await getAuthOptions());
    const link = document.createElement('a');
    link.href = result.data.url;
    link.download = result.data.name;
    link.click();
    return result.data;
};

export const uploadTmpFile = async (file: File, itemType: string): Promise<IAttachment> => {
    const formData = new FormData();
    formData.append('file', file);
    const url = `/attachments/upload/tmp/${itemType}`;
    const authOptions = await getAuthOptions();
    const options = {
        ...authOptions,
        headers: {
            ...authOptions.headers,
            'Content-Type': 'multipart/form-data',
        },
    };
    const result = await api.post(url, formData, options);
    return result.data;
};

export const uploadCTCFile = async (files: File[]): Promise<IAttachment> => {
    let url = `/attachments/upload/ctc?`;
    const formData = new FormData();
    files.forEach((file, idx) => {
        formData.append('files', file);
        url = `${url}${file.name}=${file.size}${idx !== files.length - 1 ? '&' : ''}`;
    });

    // formData.append('files', files[0]);

    const authOptions = await getAuthOptions();
    const options = {
        ...authOptions,
        headers: {
            ...authOptions.headers,
            'Content-Type': 'multipart/form-data',
        },
    };
    const result = await api.post(url, formData, options);
    return result.data;
};
