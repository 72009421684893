import React from 'react';
import moment from 'moment';
import { DateInput, TimePrecision } from '@blueprintjs/datetime';
import { FormGroup, ControlGroup, Button } from '@blueprintjs/core';

interface IDatePickerProps {
    label?: string;
    onChange: (value: Date) => void;
    value?: Date;
    enableTime?: boolean;
    inputClassname?: string;
    showNow?: boolean;
}

export const DatePicker: React.FC<IDatePickerProps> = ({ label, showNow = false, inputClassname = '', onChange, enableTime, value }) => {
    const val = value ? value : undefined;

    return (
        <FormGroup label={label} labelFor="desc">
            <ControlGroup>
                <DateInput
                    inputProps={{
                        className: inputClassname,
                    }}
                    timePickerProps={enableTime ? { useAmPm: true, showArrowButtons: true } : undefined}
                    formatDate={enableTime ? (date) => moment(date).format('MM/DD/YYYY h:mm a') : (date) => date.toLocaleDateString()}
                    fill
                    timePrecision={enableTime ? TimePrecision.MINUTE : undefined}
                    shortcuts
                    onChange={(val) => onChange(val)}
                    parseDate={(str) => new Date(str)}
                    value={typeof val === 'string' ? new Date(val) : val}
                    placeholder={enableTime ? 'MM/DD/YYYY h:mm a' : 'MM/DD/YYYY'}
                />
                {showNow && <Button onClick={() => onChange(new Date())}>Now</Button>}
            </ControlGroup>
        </FormGroup>
    );
};
