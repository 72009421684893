import { getAuthOptions } from './helpers';
import AppApi from './index';
import { CostCodeFormValues, CostCodeResponse, WBSLevel1Children } from './models/WBS';
import { IWBSResponse } from './models/WBS';

const api = AppApi.getApi();

export const getCostCodeById = async (codeId: string): Promise<CostCodeResponse> => {
    const url = `/wbs/cost_codes/${codeId}`;
    const { data } = await api.get(url, await getAuthOptions());
    return data;
};

export const listCostCodes = async (): Promise<CostCodeResponse[]> => {
    const url = `/wbs/cost_codes`;
    const { data } = await api.get(url, await getAuthOptions());
    return data;
};

export const createCostCode = async (code: CostCodeFormValues): Promise<CostCodeResponse> => {
    const url = `/wbs/cost_codes`;
    const { data } = await api.post(url, code, await getAuthOptions());
    return data;
};

export const updateCostCode = async (codeId: string, code: CostCodeFormValues): Promise<CostCodeResponse> => {
    const url = `/wbs/cost_codes/${codeId}`;
    const { data } = await api.put(url, code, await getAuthOptions());
    return data;
};

export const removeCostCode = async (codeId: string): Promise<CostCodeResponse> => {
    const url = `/wbs/cost_codes/${codeId}`;
    const { data } = await api.delete(url, await getAuthOptions());
    return data;
};

export const loadJobWBS = async (jobId: number): Promise<IWBSResponse[]> => {
    const url = `/jobs/${jobId}/wbs`;
    const result = await api.get(url, await getAuthOptions());
    return result.data;
};

export const createLevel1Wbs = async (jobId: number, data: IWBSResponse | WBSLevel1Children): Promise<IWBSResponse> => {
    const url = `/jobs/${jobId}/wbs`;
    const result = await api.post(url, data, await getAuthOptions());
    return result.data;
};

export const createLevel2Wbs = async (jobId: number, level1Id: string, data: IWBSResponse | WBSLevel1Children): Promise<WBSLevel1Children> => {
    const url = `/jobs/${jobId}/wbs/${level1Id}`;
    const result = await api.post(url, data, await getAuthOptions());
    return result.data;
};

export const assignedLevel3 = async (jobId: number, level2Id: string, data: CostCodeResponse): Promise<CostCodeResponse> => {
    const url = `/jobs/${jobId}/wbs/${level2Id}/assign/${data.id}`;
    const result = await api.post(url, data, await getAuthOptions());
    return result.data;
};

export const listJobCostCodes = async (): Promise<CostCodeResponse[]> => {
    const url = `/wbs/cost_codes`;
    const { data } = await api.get(url, await getAuthOptions());
    return data;
};
