import * as React from 'react';
import { IconButton, Chip, ListItemIcon, Tooltip } from '@mui/material';
import Check from '@mui/icons-material/Check';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useJobs } from 'src/hooks/useJobs';
import { IUser } from 'src/api/models/User';

interface PersonPickerProps {
    value: IUser | null;
    onChange: (value: IUser | null) => void;
}
export const PersonPicker: React.FC<PersonPickerProps> = ({ value, onChange }) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const { users } = useJobs();
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const renderOptions = () => {
        if (users.length === 0) {
            return <MenuItem disabled> No Users Assigned to Job</MenuItem>;
        }
        return users.map((user) => {
            const isSelected = value && value.id === user.id;
            return (
                <MenuItem
                    key={user.id}
                    onClick={() => {
                        onChange(user);
                        handleClose();
                    }}
                >
                    {isSelected && (
                        <ListItemIcon>
                            <Check />
                        </ListItemIcon>
                    )}
                    {user.full_name}
                </MenuItem>
            );
        });
    };

    return (
        <>
            {value ? (
                <Tooltip title="Assigned To">
                    <Chip label={value.full_name} color="primary" onDelete={() => onChange(null)} className="m-r-5" />
                </Tooltip>
            ) : (
                <Tooltip title="Assigned To">
                    <IconButton
                        className="m-r-5 p-t-4"
                        id="basic-button"
                        aria-controls={open ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}
                    >
                        <AccountCircleIcon />
                    </IconButton>
                </Tooltip>
            )}

            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {renderOptions()}
            </Menu>
        </>
    );
};
