import React from 'react';
import { Dialog } from '@blueprintjs/core';
import { useDialogs } from 'src/hooks/useDialogs';
import { CreateOfficeForm } from './CreateOfficeForm';

export const CreateOfficeDialog: React.FC = () => {
    const { createOfficeDialogOpen, setCreateOfficeDialogOpen, selectedOffice } = useDialogs();
    const closeDialog = () => {
        setCreateOfficeDialogOpen(false);
    };
    return (
        <Dialog style={{ width: 600 }} canOutsideClickClose={false} isOpen={createOfficeDialogOpen} onClose={closeDialog} title={selectedOffice ? 'Update Office' : 'Create New Office'} icon="office">
            <CreateOfficeForm selectedOffice={selectedOffice} setCreateOfficeDialogOpen={setCreateOfficeDialogOpen} />
        </Dialog>
    );
};
