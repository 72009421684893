import React, { useState, createContext } from 'react';
import { IUser } from 'src/api/models/User';
import { loadUsers } from 'src/api/user';
import { AppToast } from '../components/Toasts/AppToast';

interface IUserContext {
    users: IUser[];

    loading: boolean;
}
export const UserContext = createContext<IUserContext>({
    users: [],
    loading: true,
});

export const UsersProvider: React.FC = ({ children }) => {
    const [users, setUsers] = useState<IUser[]>([]);
    const [loading, setLoading] = useState(true);
    React.useEffect(() => {
        fetchUsers();
    }, []);

    const fetchUsers = async () => {
        try {
            const data = await loadUsers();
            setUsers(data);
        } catch (err) {
            AppToast.show({ message: `Error Loading Users: ${err.message}`, intent: 'danger', icon: 'warning-sign' });
        }
        setLoading(true);

        setLoading(false);
    };
    return <UserContext.Provider value={{ users, loading }}>{children}</UserContext.Provider>;
};

export const useUsers: () => IUserContext = () => {
    const context = React.useContext(UserContext);
    return { ...context };
};
