import React from 'react';

import { IMeeting } from 'src/api/models/Meeting';
import { loadMeetings } from 'src/api/meetings';
import { AppToast } from 'src/components/Toasts/AppToast';
import { Spinner } from '@blueprintjs/core';
import { SelectableMeetingTable } from '../../../components/MeetingsTable/SelectableMeetingTable';
interface SelectMeetingTableProps {
    jobId: number;
    selectedMeetingId: string | null;
    onMeetingClicked: (meeting: IMeeting) => void;
}

export const SelectMeetingTable: React.FC<SelectMeetingTableProps> = ({ jobId, selectedMeetingId, onMeetingClicked }) => {
    const [loading, setLoading] = React.useState(false);
    const [meetings, setMeetings] = React.useState<IMeeting[]>([]);

    React.useEffect(() => {
        loadMeetingData();
    }, []);
    const loadMeetingData = async () => {
        setLoading(true);
        try {
            const data = await loadMeetings(jobId);
            setMeetings(data);
        } catch (err) {
            AppToast.show({ message: `Error: ${err.toLocaleString()}`, intent: 'danger', icon: 'tick' });
        }
        setLoading(false);
    };

    if (loading) {
        return (
            <div className="text-center p-t-50">
                <Spinner />
            </div>
        );
    }

    return <SelectableMeetingTable meetings={meetings} isLoading={loading} onMeetingClicked={onMeetingClicked} selectedMeetingId={selectedMeetingId} />;
};
