import React from 'react';
import { Button } from '@blueprintjs/core';
import { Row, Col } from 'react-grid-system';
import { WorkTicketForm } from '../WorkTicketForm';
import { useJobs } from '../../../../hooks/useJobs';
import { IWorkTicketFormValues } from 'src/api/models/WorkTicket';
import { useAuth } from '../../../../hooks/useAuth';
import { useHistory } from 'react-router-dom';
import { Header2 } from '../../../../components/Header2';

export const CreateTicket: React.FC = () => {
    const { selectedJob } = useJobs();
    const { user } = useAuth();
    const history = useHistory();
    if (!selectedJob || !user) return null;
    const initialValues: IWorkTicketFormValues = {
        created_by_id: user.id,
        created_by: user,
        daily_id: null,
        use_signature_pad: false,
        date: new Date(),
        summary: '',
        signature_string: '',
        requester: '',
        signature: [],
        requested_by_id: null,
        status: 'Created',
        work_performed_date: null,
        recipient_id: null,
        items: [],
        job_id: selectedJob.id,
        images: [],
        files_to_add: [],
        files_to_remove: [],
    };
    return (
        <>
            <Header2
                breadcrumbs={[
                    { title: 'Jobs', link: '/jobs' },
                    { title: `${selectedJob?.name} - ${selectedJob?.job_number}`, link: `/jobs/${selectedJob?.id}/summary` },
                    { title: 'Work Tickets', link: `/jobs/${selectedJob.id}/tickets` },
                    { title: 'Create', link: '' },
                ]}
            />
            <Row>
                <Col>
                    <WorkTicketForm
                        jobId={selectedJob.id}
                        initialValues={initialValues}
                        sideCancelButton={
                            <Button fill className="m-b-15" onClick={() => history.push(`/jobs/${selectedJob.id}/tickets`)}>
                                Cancel
                            </Button>
                        }
                        cancelButton={
                            <Button className="m-r-10" onClick={() => history.push(`/jobs/${selectedJob.id}/tickets`)}>
                                Cancel
                            </Button>
                        }
                    />
                </Col>
            </Row>
        </>
    );
};
