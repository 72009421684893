import React from 'react';

import Tree, { TreeNode } from 'rc-tree';
import { IDefaultFolder } from 'src/api/models/DefaultFolder';

interface IFolderTreeProps {
    selectedFolder: IDefaultFolder | null;
    folders: IDefaultFolder[];
    onFolderSelected: (docId: string, path: number[]) => void;
}

export const FolderTree: React.FC<IFolderTreeProps> = ({ selectedFolder, folders, onFolderSelected }) => {
    const onSelect = (keys: any[], data: any) => {
        const path = data.node.pos.split('-').slice(2);
        onFolderSelected(keys[0], path);
    };
    const renderChildren = (children: IDefaultFolder[]) => {
        return children.map((child) => {
            return (
                <TreeNode title={<span className="m-l-5">{child.name}</span>} key={child.id} selectable={true} icon={<span className="rc-tree-iconEle rc-tree-icon__open m-l-5" />}>
                    {renderChildren(child.children)}
                </TreeNode>
            );
        });
    };
    return (
        <Tree defaultExpandAll autoExpandParent showLine onSelect={onSelect} selectedKeys={selectedFolder ? [selectedFolder.id] : ['documents']}>
            <TreeNode title={`Documents`} key="documents" selectable={true} icon={<span className="rc-tree-iconEle rc-tree-icon__open m-l-5" />}>
                {renderChildren(folders)}
            </TreeNode>
        </Tree>
    );
};
