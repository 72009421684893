import React from 'react';
import { Dialog } from '@mui/material';
import { PostForm } from '../PostCard/components/PostForm';
import { PostResponse } from 'src/api/models/Post';

interface EditPostProps {
    onPostSaved: () => void;
    handleClose: () => void;
    post: PostResponse | null;
}

export const EditPost: React.FC<EditPostProps> = ({ onPostSaved, handleClose, post }) => {
    return (
        <>
            <Dialog open={post !== null} onClose={handleClose} fullWidth aria-labelledby="draggable-dialog-title">
                <PostForm handleClose={handleClose} onPostCreated={onPostSaved} initialValues={post} />
            </Dialog>
        </>
    );
};
