import React from 'react';
import { Button, Icon } from '@blueprintjs/core';
import { Row, Col } from 'react-grid-system';
import { OfficeCard } from 'src/components/OfficeCard';
import { Header } from 'src/components/Header';
import { useOrganization } from 'src/hooks/useOrganization';
import { useDialogs } from 'src/hooks/useDialogs';
import './index.scss';
import { Classes as TTClasses, Popover2 } from '@blueprintjs/popover2';

export const Offices: React.FC = () => {
    const { offices, onOfficeDeleted } = useOrganization();
    const { setCreateOfficeDialogOpen } = useDialogs();
    return (
        <>
            <Header
                title="Offices"
                breadcrumbs={['Settings', 'Offices']}
                actionComponent={<Button intent="primary" icon="add" text="Create Office" onClick={() => setCreateOfficeDialogOpen(true)} />}
            />
            <Row>
                <Col>
                    <h2 className="m-t-0">
                        Offices{' '}
                        <div className="title-help-icon">
                            <Popover2
                                interactionKind="click"
                                popoverClassName={TTClasses.POPOVER2_CONTENT_SIZING}
                                enforceFocus={false}
                                position="right"
                                content={
                                    <>
                                        <h4 className="m-0 m-b-5">Offices</h4>
                                        <p>Users can be assigned to one or many offices to limit visibility. Users can only see data belonging to organizations the user has been assigned.</p>
                                    </>
                                }
                            >
                                <Icon icon="help" color="#5c7080" size={15} className="m-l-10 m-b-3" />
                            </Popover2>
                        </div>
                    </h2>
                </Col>
            </Row>
            <Row>
                {offices.map((office) => {
                    return (
                        <Col sm={6} md={6} lg={6} xl={3} key={office.id} className="m-b-15 m-t-15">
                            <OfficeCard office={office} onClick={(role) => setCreateOfficeDialogOpen(true, office)} onRoleDeleted={(role) => onOfficeDeleted(role)} />
                        </Col>
                    );
                })}
            </Row>

            {/*<ScopePicker scopeList={scopes} />*/}
        </>
    );
};
