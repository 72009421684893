import React from 'react';
import { Dialog } from '@blueprintjs/core';
import { useDialogs } from 'src/hooks/useDialogs';
import { CreateClientForm } from './CreateClientForm';

export const CreateClientDialog: React.FC = () => {
    const { createClientOpen, setCreateClientDialogOpen, selectedClient } = useDialogs();
    const closeDialog = () => {
        setCreateClientDialogOpen(false);
    };
    return (
        <Dialog isOpen={createClientOpen} onClose={closeDialog} title={selectedClient ? 'Update Client' : 'Create New Client'} icon="inherited-group">
            <CreateClientForm selectedClient={selectedClient} setCreateClientDialogOpen={setCreateClientDialogOpen} />
        </Dialog>
    );
};
