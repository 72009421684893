import React from 'react';
import { Row, Col } from 'react-grid-system';
import { Button, InputGroup } from '@blueprintjs/core';
import { IRateFormValues, IRateResponse } from 'src/api/models/Rate';
import { createJobRate, removeJobRate } from 'src/api/rates';
import { DraggableItem } from './DraggableItem';
import { StaticItem } from './StaticItem';
interface IRateSheetSectionProps {
    category: string;
    measurement: string;
    title: string;
    onRateAdded: (rate: IRateResponse) => void;
    jobId: number;
    rates: IRateResponse[];
    interactable: boolean;
}
const initialValues: IRateFormValues = { label: '', rate: '', rate_measurement: '', category: '', quantity: '' };

export const RateSheetSection: React.FC<IRateSheetSectionProps> = ({ rates, interactable, title, onRateAdded, category, measurement, jobId }) => {
    const [submitting, setSubmitting] = React.useState(false);
    const [error, setError] = React.useState<string | null>(null);
    const [showForm, setShowForm] = React.useState(false);
    const [values, setValues] = React.useState<IRateFormValues>(initialValues);
    const setFieldValue = (field: 'label' | 'rate', value: string) => {
        setValues((prev) => {
            return {
                ...prev,
                [field]: value,
            };
        });
    };

    const resetForm = () => {
        setValues(initialValues);
        setShowForm(false);
    };

    const enterCreateMode = () => {
        setValues({
            category,
            rate_measurement: measurement,
            label: '',
            rate: '',
            quantity: '',
        });
        setShowForm(true);
    };

    const submitRate = async () => {
        setSubmitting(true);
        setError(null);
        try {
            const result = await createJobRate(jobId, values);
            onRateAdded(result);
            resetForm();
        } catch (err) {
            setError(err.toLocaleString());
        }
        setSubmitting(false);
    };

    const remove = async (rate: IRateResponse) => {
        await removeJobRate(jobId, rate.id);
        onRateAdded(rate);
    };

    const renderLines = () => {
        if (rates.length === 0) {
            return (
                <div className="bp3-callout .modifier">
                    <h6 className="bp3-heading">No {title} Rates</h6>
                </div>
            );
        }
        return rates.map((rate) => {
            if (interactable) {
                return <DraggableItem key={rate.id} rate={rate} onRemove={() => remove(rate)} />;
            }
            return <StaticItem key={rate.id} rate={rate} onRemove={() => remove(rate)} />;
        });
    };
    return (
        <>
            <Row className="m-b-15">
                <Col xs={6}>
                    <h4 className="m-b-0">{title}</h4>
                </Col>
                <Col xs={3}>{!interactable && <h4 className="m-b-0 p-r-15">Rate</h4>}</Col>
                <Col xs={3} />
            </Row>
            <Row className="m-b-15">
                <Col>{renderLines()}</Col>
            </Row>

            {!interactable && (
                <>
                    {showForm ? (
                        <>
                            {error && <p>{error}</p>}
                            <Row>
                                <Col xs={5} className="p-r-0">
                                    <InputGroup value={values.label} onChange={(e) => setFieldValue('label', e.target.value)} placeholder={title} />
                                </Col>
                                <Col xs={3} className="p-l-5 p-r-0">
                                    <InputGroup
                                        value={values.rate}
                                        onChange={(e) => {
                                            if (isNaN(Number(e.target.value)) && e.target.value !== '') return;
                                            setFieldValue('rate', e.target.value);
                                        }}
                                        placeholder="Rate"
                                        rightElement={<Button style={{ color: '#000' }} minimal disabled text={`/${measurement}`} intent="primary" onClick={() => setShowForm(false)} />}
                                    />
                                </Col>
                                <Col className="text-right p-l-0">
                                    <Button minimal loading={submitting} text="Add" intent="primary" onClick={submitRate} />
                                    <Button minimal icon="cross" intent="danger" onClick={resetForm} />
                                </Col>
                            </Row>
                        </>
                    ) : (
                        <Button minimal fill intent="primary" onClick={enterCreateMode}>
                            Add {title}
                        </Button>
                    )}
                </>
            )}
        </>
    );
};
