import React from 'react';
import { Classes, Drawer, Tag } from '@blueprintjs/core';
import { useDialogs } from 'src/hooks/useDialogs';
import { AllScopes } from 'src/components/AllScopes';

export const ScopeInfoDialog: React.FC = () => {
    const { scopeInfoOpen, setScopeInfoOpen } = useDialogs();

    const closeDrawer = () => {
        setScopeInfoOpen(false);
    };

    return (
        <Drawer icon="info-sign" onClose={closeDrawer} title="Understanding Scopes" isOpen={scopeInfoOpen}>
            <div className={Classes.DRAWER_BODY}>
                <div className={Classes.DIALOG_BODY}>
                    <h4>Overview</h4>
                    <p>
                        Scopes control what resources a user with a role can read, write, and/or manage. Each type of data (referred to as resources) within the system (ie Documents, tasks, materials,
                        timesheets, etc) has a series of corresponding scopes used to control user access.
                    </p>
                    <h4>Scope Format</h4>
                    <p>
                        A scope is composed of three parts, the <b>resource</b>, the <b>permission</b>, and the <b>access level</b> represented by a colon delimited list (
                        <Tag minimal intent="primary" className="text-bold">
                            Resource:permission:access level
                        </Tag>
                        ).
                    </p>
                    <p>
                        <b>Resource:</b> Type of data the permissions apply to. Documents, Dailys, Timesheets, Tasks, and Finance are all examples of resources.
                    </p>
                    <p>
                        <b>Permission:</b> What the user is allowed to do with specified resource.
                        <ul className="bp3-list">
                            <li>
                                <b>Read</b>: Allows the user to ONLY view the data.
                            </li>
                            <li>
                                <b>Write</b>: Allows the user to view, update, or delete the specified resource regardless of who created it. Write permissions is synonymous with "admin" privileges
                                for the resource.
                            </li>
                            <li>
                                <b>Restricted</b> Allows the user to view, update, or delete the specified resource that was originally created by the user.
                            </li>
                        </ul>
                    </p>
                    <p>
                        <b>Access Level:</b> Each resource can either be accessed system wide, or only within jobs the user is granted access to.
                    </p>
                    <ul className="bp3-list">
                        <li>
                            <b>Global Access:</b> The access level "all" is used to give access to all instances of the resource within the system regardless of whether the user has explicit access to
                            the linked Job. For example,{' '}
                            <Tag minimal intent="primary" className="text-bold">
                                Timesheets:read:global
                            </Tag>{' '}
                            allows the user to view all timesheets in the system, even if the user cannot see the associate Job.
                        </li>
                        <li>
                            <b>Job Specific Access:</b> Alternatively, scopes can be used to allow access to ONLY the resources linked to Jobs the user has access to. For example,{' '}
                            <Tag minimal intent="primary" className="text-bold">
                                Timesheets:write:jobs
                            </Tag>{' '}
                            allows the user to create, read, update, and delete timesheets ONLY if they are associate to Jobs that the user has been explicitly granted access to.
                        </li>
                    </ul>
                    <h4>Scenarios</h4>
                    <p>
                        <b>Scenario 1 - Materials Manager:</b>
                    </p>
                    <p>
                        Your organization has recently hired a Foreman whose job it is to manage all materials and pieces across all jobs on top of his/her normal Foreman responsibilities. By default,
                        the foreman role gives access to most things within jobs the user has been granted access to. A system administrator would then create another role called "Materials Manager"
                        and select the scopes{' '}
                        <Tag minimal intent="primary" className="text-bold">
                            Materials:write:global
                        </Tag>{' '}
                        and{' '}
                        <Tag minimal intent="primary" className="text-bold">
                            Pieces:write:global
                        </Tag>
                        . This will give the user access to create, view, update, or delete all materials and pieces regardless of the Job.
                    </p>
                    <p>
                        <b>Scenario 2 - Contract Designer:</b>
                    </p>
                    <p>
                        Your organization has recently contracted a designer to provide designs for a single project. The designer is expected to upload and manage their design documents within jobs
                        the user is assigned to. A system administrator would create a new role called "Designer" and assign the scope{' '}
                        <Tag minimal intent="primary" className="text-bold">
                            Documents:restricted:jobs
                        </Tag>{' '}
                        . If no other roles are assigned to the user, they will only have access to upload documents to Jobs they have been added to as well update and delete those documents.
                    </p>
                    <h4 className="m-b-0">Available Scopes</h4>
                    <AllScopes hideLabel />
                </div>
            </div>
        </Drawer>
    );
};
