import React from 'react';
import moment from 'moment';
import { Button } from '@mui/material';
import { sortBy, sumBy, uniqBy, findIndex } from 'lodash';
import { Card, CardContent, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { CostCodeAdjustmentLineItem, CTCJobDetails, CTCJobSummary, CTCMaterial } from '../../../../../api/models/CTC';
import { HistoryDialog } from './HistoryDialog';
import { CostCodeLine } from './CostCodeLine';
import HistoryIcon from '@mui/icons-material/History';
import { Row, Col } from 'react-grid-system';
import { createCostCodeAdjustment, loadAdjustments } from 'src/api/ctc';
import { nanoid } from 'nanoid';
import { AppToast } from '../../../../../components/Toasts/AppToast';
const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0,
});

const CODE_LOOKUP: any = {
    '10': 'Labor',
    '20': 'Materials',
    '30': 'Equip Rental',
    '70': 'Permits Rental',
    '40': 'Subcontractors',
    '50': 'Other',
};
const TOTAL_BG = '#ffb5b5';
const KEYS = [
    {
        category: 'Labor',
        code: '10',
        codeList: [],
    },
    {
        category: 'Materials',
        code: '20',
        codeList: [],
    },
    {
        category: 'Equip Rental',
        code: '30',
        codeList: [],
    },
    {
        category: 'Permits Rental',
        code: '70',
        codeList: [],
    },
    {
        category: 'Subcontractors',
        code: '40',
        codeList: [],
    },
    {
        category: 'Other',
        code: '50',
        codeList: [],
    },
];

function createCostCodeDetail(costCodeSummary: CTCJobSummary) {
    return {
        Cost_Code: costCodeSummary.Cost_Code,
        Cost_Code_Description: costCodeSummary.Cost_Code_Description,
        DistDesc: '',
        DOCNUMBR: '',
        EST_Hours: 0,
        CTC_Hours: 0,
        EstimatedRate: 0,
        JTD_Rate: 0,
        JTD_Cost: 0,
        CTC: 0,
        JTD_hours: 0,
        MTD_hours: 0,
        OriginalEstimateCost: 0,
        RevisedEstimateCost: 0,
        WS_Job_Number: '',
        created_at: null,
        id: '',
        updated_at: null,
    };
}

interface GroupedDetails {
    [category: string]: CTCJobDetails[];
}
interface GroupedSummary {
    [category: string]: CTCJobSummary[];
}
interface Column {
    id: string;
    label: string;
    format?: (value: number) => string;
}
interface EstimatorProps {
    jobId: string;
    summaryList: CTCJobSummary[];
    details: GroupedDetails;
    summary: GroupedSummary;
    materials: Record<string, CTCMaterial[]>;
    adjustedRevenue?: number;
    onCtcChange: (value: number) => void;
    onAdjustedRevChange: (value: number) => void;
}

const columns: Column[] = [
    { id: 'description', label: 'Description' },
    {
        id: 'ctc_hours',
        label: 'CTC Hours',
        format: (value: number) => value.toLocaleString('en-US'),
    },
    {
        id: 'jtd_rate',
        label: 'JTD Rate',
        format: (value: number) => value.toLocaleString('en-US'),
    },
    {
        id: 'CTC',
        label: 'CTC',
        format: (value: number) => value.toLocaleString('en-US'),
    },
    {
        id: 'cost_at_completion',
        label: 'C@C',
        format: (value: number) => value.toLocaleString('en-US'),
    },
    {
        id: 'currentDiff',
        label: '+ / -',
        format: (value: number) => value.toLocaleString('en-US'),
    },
    // {
    //     id: 'history',
    //     label: '',
    //     format: (value: number) => value.toLocaleString('en-US'),
    // },
];
export const Estimator: React.FC<EstimatorProps> = ({ summaryList, onCtcChange, onAdjustedRevChange, details, jobId, adjustedRevenue, summary, materials }) => {
    const [adjustments, setAdjustments] = React.useState<CostCodeAdjustmentLineItem[]>([]);
    const [loading, setLoading] = React.useState(true);
    const [enableSave, setEnableSave] = React.useState(false);
    const [historyOpen, setHistoryOpen] = React.useState(false);
    const ctcRef = React.useRef(0);
    const [rev, setRev] = React.useState(0);

    React.useEffect(() => {
        onCtcChange(ctcRef.current);
    }, [ctcRef.current]);

    React.useEffect(() => {
        if (!loading) {
            onAdjustedRevChange(rev);
        }
    }, [rev]);
    React.useEffect(() => {
        loadAdjustmentData();
    }, []);

    const saveAdjustments = async () => {
        try {
            await createCostCodeAdjustment(jobId, adjustments, rev);
            setAdjustments((prevState) => {
                return prevState.map((adj) => {
                    delete adj.action;
                    return adj;
                });
            });
            AppToast.show({ message: `Adjustments Saved`, intent: 'success', icon: 'tick' });
            loadAdjustmentData();
            setEnableSave(false);
        } catch (err) {
            AppToast.show({ message: `Failed to save adjustment: ${err.toLocaleString()}`, intent: 'danger', icon: 'tick' });
        }
    };

    const loadAdjustmentData = async () => {
        setLoading(true);
        const data = await loadAdjustments(jobId);
        if (data) {
            setAdjustments(
                data.adjustments.map((adj) => {
                    delete adj.action;
                    return adj;
                }),
            );
            setRev(Number(data.adjusted_rev) || 0);
        }
        setLoading(false);
    };
    const onAdjustmentChange = (adj: CostCodeAdjustmentLineItem) => {
        const location = findIndex(adjustments, (item) => item.cost_code_id === adj.cost_code_id);
        if (location > -1) {
            setAdjustments((prevState) => {
                return prevState.map((item) => {
                    if (item.cost_code_id === adj.cost_code_id) {
                        return {
                            ...adj,
                            action: adj.action === 'create' ? 'create' : 'update',
                        };
                    }
                    return item;
                });
            });
        } else {
            setAdjustments((prevState) => {
                return [
                    ...prevState,
                    {
                        ...adj,
                        action: 'create',
                    },
                ];
            });
        }
        setEnableSave(true);
    };

    const renderRows = () => {
        const updatedKeys = KEYS.map((key) => {
            return {
                ...key,
                codeList: summaryList
                    .filter((summaryItem) => {
                        return summaryItem.Cost_Code.slice(0, 2) === key.code;
                    })
                    .map((s) => {
                        return { cc: s.Cost_Code, description: s.Cost_Code_Description };
                    }),
            };
        });
        const today = moment();
        return updatedKeys.map((settings) => {
            const { codeList } = settings;
            const totals = {
                OriginalEstimateCost: 0,
                RevisedEstimateCost: 0,
                RemainingPoBalanceTotal: 0,
                MTD_Cost: 0,
                JTD_Cost: 0,
                EST_Hours: 0,
                JTD_Hours: 0,
                MTD_Hours: 0,
                Remain: 0,
                CTC: 0,
                Cost: 0,
                Diff: 0,
            };
            return (
                <>
                    {sortBy(codeList, [
                        function (o) {
                            return o.cc;
                        },
                    ]).map((obj, idx) => {
                        const costCodeDetailItems = (details[obj.cc] || []).map((r) => {
                            return {
                                ...r,
                                NumberAmount: Number(r.Amount),
                            };
                        });
                        const costCodeSummaryItems = summary[obj.cc] || [];
                        const costCodeSummary = costCodeSummaryItems[0];
                        if (!costCodeSummary) return null;
                        const costCodeDetails = costCodeDetailItems[0] || createCostCodeDetail(costCodeSummary);
                        const allMaterials = materials[costCodeDetails.CostCode] || [];
                        const materialNumbers = uniqBy(allMaterials, 'PONUMBER').map((m) => {
                            return {
                                ...m,
                                PO_Amount: Number(m.PO_Amount),
                                Remaining_PO_Balance: Number(m.Remaining_PO_Balance),
                            };
                        });
                        const currentMonthDetails = costCodeDetailItems.filter((d) => moment(new Date(d.DOCDATE)).isSame(today, 'month'));
                        const currentMonthAmountTotal = sumBy(currentMonthDetails, 'NumberAmount') | 0;
                        const remainingPoBalanceTotal = sumBy(materialNumbers, 'Remaining_PO_Balance');
                        //const cost = Number(costCodeSummary.CTC) + Number(costCodeSummary.JTD_Cost);
                        // const diff = costCodeSummary.RevisedEstimateCost - cost;
                        const adjustment = adjustments.find((adj) => adj.cost_code_id === obj.cc);
                        totals.OriginalEstimateCost = totals.OriginalEstimateCost + Number(costCodeSummary.OriginalEstimateCost);
                        totals.RevisedEstimateCost = totals.RevisedEstimateCost + Number(costCodeSummary.RevisedEstimateCost);
                        totals.RemainingPoBalanceTotal = totals.RemainingPoBalanceTotal + remainingPoBalanceTotal;
                        totals.MTD_Cost = totals.MTD_Cost + currentMonthAmountTotal;
                        totals.JTD_Cost = totals.JTD_Cost + Number(costCodeSummary.JTD_Cost);
                        totals.EST_Hours += Number(costCodeSummary.EST_Hours);
                        totals.JTD_Hours += Number(costCodeSummary.JTD_hours);
                        totals.MTD_Hours += Number(costCodeSummary.MTD_hours);
                        totals.Remain += Math.round(Number(costCodeSummary.EST_Hours) - Number(costCodeSummary.JTD_hours));
                        // totals.CTC += Number(costCodeSummary.CTC);
                        // totals.Cost += cost;
                        // totals.Diff += diff;
                        if (adjustment) {
                            const tmpCtc = adjustment.jtd_rate * adjustment.ctc_hours;
                            const tmpCost = tmpCtc + Number(costCodeSummary.JTD_Cost);
                            totals.CTC += tmpCtc;
                            totals.Cost += tmpCost;
                            totals.Diff += tmpCost - Number(costCodeSummary.RevisedEstimateCost);
                        } else {
                            const tmpRate = Number(costCodeSummary.JTD_Rate) > 0 ? Number(costCodeSummary.JTD_Rate) : Number(costCodeSummary.EstimatedRate);
                            const tmpCtc = Number(costCodeSummary.CTC_Hours) * tmpRate;
                            const tmpCost = tmpCtc + Number(costCodeSummary.JTD_Cost);
                            totals.Cost += tmpCost;
                            totals.CTC += tmpCtc;
                            totals.Diff += tmpCost - Number(costCodeSummary.RevisedEstimateCost);
                        }

                        return (
                            <CostCodeLine
                                key={obj.cc + obj.description + idx}
                                label={obj.description}
                                jdtCost={Number(costCodeSummary.JTD_Cost)}
                                revisedEstimate={Number(costCodeSummary.RevisedEstimateCost)}
                                onAdjustmentChange={onAdjustmentChange}
                                costCode={obj.cc}
                                jtdRate={adjustment ? Number(adjustment.jtd_rate) : Number(costCodeSummary.JTD_Rate) > 0 ? Number(costCodeSummary.JTD_Rate) : Number(costCodeSummary.EstimatedRate)}
                                ctcHours={adjustment ? Number(adjustment.ctc_hours) : Number(costCodeSummary.CTC_Hours)}
                                jobId={jobId}
                            />
                        );
                    })}
                    {codeList.length > 0 && (
                        <TableBody key={nanoid()}>
                            <TableRow>
                                <TableCell style={{ borderBottom: '1px solid #000', backgroundColor: '#f3f3f3' }} align="right" colSpan={1}>
                                    -
                                </TableCell>
                                <TableCell style={{ borderBottom: '1px solid #000', backgroundColor: '#f3f3f3' }} align="right" colSpan={1}>
                                    -
                                </TableCell>
                                <TableCell style={{ borderBottom: '1px solid #000', backgroundColor: '#f3f3f3' }} align="right" colSpan={1}>
                                    -
                                </TableCell>
                                <TableCell style={{ borderBottom: '1px solid #000', backgroundColor: '#f3f3f3' }} align="right" colSpan={1}>
                                    <b>{formatter.format(totals.CTC)}</b>
                                </TableCell>
                                <TableCell style={{ borderBottom: '1px solid #000', backgroundColor: '#f3f3f3' }} align="right" colSpan={1}>
                                    <b>{totals.Cost < 0 ? <span className="text-danger">({formatter.format(totals.Cost * -1)})</span> : formatter.format(totals.Cost)}</b>
                                </TableCell>
                                <TableCell style={{ borderBottom: '1px solid #000', backgroundColor: '#f3f3f3' }} align="right" colSpan={1}>
                                    <b>{totals.Diff < 0 ? <span className="text-danger">({formatter.format(totals.Diff * -1)})</span> : formatter.format(totals.Diff)}</b>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    )}
                </>
            );
        });
    };
    const renderFooter = () => {
        const today = moment();
        const totals = {
            OriginalEstimateCost: 0,
            RevisedEstimateCost: 0,
            RemainingPoBalanceTotal: 0,
            MTD_Cost: 0,
            JTD_Cost: 0,
            EST_Hours: 0,
            JTD_Hours: 0,
            MTD_Hours: 0,
            Remain: 0,
            CTC: 0,
            Cost: 0,
            Diff: 0,
        };

        summaryList.forEach((costCodeSummary) => {
            const allMaterials = materials[costCodeSummary.Cost_Code] || [];
            const materialNumbers = uniqBy(allMaterials, 'PONUMBER').map((m) => {
                return {
                    ...m,
                    PO_Amount: Number(m.PO_Amount),
                    Remaining_PO_Balance: Number(m.Remaining_PO_Balance),
                };
            });

            const costCodeDetailItems = (details[costCodeSummary.Cost_Code] || []).map((r) => {
                return {
                    ...r,
                    NumberAmount: Number(r.Amount),
                };
            });

            const currentMonthDetails = costCodeDetailItems.filter((d) => moment(new Date(d.DOCDATE)).isSame(today, 'month'));
            const currentMonthAmountTotal = sumBy(currentMonthDetails, 'NumberAmount') | 0;
            const remainingPoBalanceTotal = sumBy(materialNumbers, 'Remaining_PO_Balance');
            // const cost = Number(costCodeSummary.CTC) + Number(costCodeSummary.JTD_Cost);
            // const diff = costCodeSummary.RevisedEstimateCost - cost;

            const category = CODE_LOOKUP[costCodeSummary.Cost_Code.slice(0, 2)];
            if (!category) return;
            const adjustment = adjustments.find((adj) => adj.cost_code_id === costCodeSummary.Cost_Code);
            const isLabor = category === 'Labor';
            totals.OriginalEstimateCost += Number(costCodeSummary.OriginalEstimateCost);
            totals.RevisedEstimateCost += Number(costCodeSummary.RevisedEstimateCost);
            totals.JTD_Cost += Number(costCodeSummary.JTD_Cost);
            //totals.CTC += Number(costCodeSummary.CTC);
            totals.MTD_Cost += currentMonthAmountTotal;
            // totals.Cost += cost;
            // totals.Diff += diff;

            // if (adjustment) {
            //     totals.CTC += adjustment.jtd_rate * adjustment.ctc_hours;
            // } else {
            //     const tmpRate = Number(costCodeSummary.JTD_Rate) > 0 ? Number(costCodeSummary.JTD_Rate) : Number(costCodeSummary.EstimatedRate);
            //     totals.CTC += Number(costCodeSummary.CTC_Hours) * tmpRate;
            // }

            if (adjustment) {
                const tmpCtc = adjustment.jtd_rate * adjustment.ctc_hours;
                const tmpCost = tmpCtc + Number(costCodeSummary.JTD_Cost);
                totals.CTC += tmpCtc;
                totals.Cost += tmpCost;
                totals.Diff += tmpCost - Number(costCodeSummary.RevisedEstimateCost);
            } else {
                const tmpRate = Number(costCodeSummary.JTD_Rate) > 0 ? Number(costCodeSummary.JTD_Rate) : Number(costCodeSummary.EstimatedRate);
                const tmpCtc = Number(costCodeSummary.CTC_Hours) * tmpRate;
                const tmpCost = tmpCtc + Number(costCodeSummary.JTD_Cost);
                totals.Cost += tmpCost;
                totals.CTC += tmpCtc;
                totals.Diff += tmpCost - Number(costCodeSummary.RevisedEstimateCost);
            }

            if (!isLabor) {
                totals.RemainingPoBalanceTotal += remainingPoBalanceTotal;
            }

            if (isLabor) {
                totals.EST_Hours += Number(costCodeSummary.EST_Hours);
                totals.JTD_Hours += Number(costCodeSummary.JTD_hours);
                totals.MTD_Hours += Number(costCodeSummary.MTD_hours);
                totals.Remain += Math.round(Number(costCodeSummary.EST_Hours) - Number(costCodeSummary.JTD_hours));
            }
        });
        ctcRef.current = totals.CTC;
        return (
            <TableBody>
                <TableRow>
                    <TableCell style={{ borderBottom: '1px solid #000', backgroundColor: TOTAL_BG }} align="right" colSpan={1}>
                        -
                    </TableCell>
                    <TableCell style={{ borderBottom: '1px solid #000', backgroundColor: TOTAL_BG }} align="right" colSpan={1}>
                        -
                    </TableCell>
                    <TableCell style={{ borderBottom: '1px solid #000', backgroundColor: TOTAL_BG }} align="right" colSpan={1}>
                        -
                    </TableCell>
                    <TableCell style={{ borderBottom: '1px solid #000', backgroundColor: TOTAL_BG }} align="right" colSpan={1}>
                        <b>{formatter.format(totals.CTC)}</b>
                    </TableCell>
                    <TableCell style={{ borderBottom: '1px solid #000', backgroundColor: TOTAL_BG }} align="right" colSpan={1}>
                        <b>{totals.Cost < 0 ? <span className="text-danger">({formatter.format(totals.Cost * -1)})</span> : formatter.format(totals.Cost)}</b>
                    </TableCell>
                    <TableCell style={{ borderBottom: '1px solid #000', backgroundColor: TOTAL_BG }} align="right" colSpan={1}>
                        <b>{totals.Diff < 0 ? <span className="text-danger">({formatter.format(totals.Diff * -1)})</span> : formatter.format(totals.Diff)}</b>
                    </TableCell>
                </TableRow>
            </TableBody>
        );
    };

    const toggleHistory = () => {
        setHistoryOpen((prev) => !prev);
    };
    return (
        <Card className="p-0">
            <HistoryDialog jobId={jobId} open={historyOpen} onClose={toggleHistory} />
            {/*<AdjustmentDialog onSaveComplete={onAdjustmentSaved} jobNumber={job.ws_job_number} adjustment={adjustment} handleClose={() => setAdjustment(null)} />*/}
            <CardContent>
                <Row style={{ backgroundColor: '#9ed6fc', height: 50, paddingTop: 10, margin: 0 }}>
                    <Col className="p-t-5">Current Month Adjustments</Col>
                    <Col className="text-right">
                        {enableSave && (
                            <Button size="small" variant="contained" onClick={saveAdjustments}>
                                Save Adjustments
                            </Button>
                        )}
                        <Button size="small" variant="outlined" className="m-l-10" onClick={toggleHistory}>
                            <HistoryIcon />
                        </Button>
                    </Col>
                </Row>
                <Row style={{ backgroundColor: '#e9e9e9', height: 50, paddingTop: 10, margin: 0 }}>
                    <Col className="p-t-5">Revenue Adjusted</Col>
                    <Col className="text-right">
                        <input
                            value={rev}
                            style={{ maxWidth: 100 }}
                            type="number"
                            onChange={(e) => {
                                setRev(Number(e.target.value));
                                setEnableSave(true);
                            }}
                        />
                    </Col>
                </Row>
                <TableContainer sx={{ maxHeight: 600, overflow: 'auto' }}>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell key={column.id} align="center" width={20} style={{ top: 57, backgroundColor: '#e9e9e9' }} colSpan={column.id === 'currentDiff' ? 1 : 1}>
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        {loading ? (
                            <p>Loading...</p>
                        ) : (
                            <>
                                {renderRows()}
                                {renderFooter()}
                            </>
                        )}
                    </Table>
                </TableContainer>
            </CardContent>
        </Card>
    );
};
