import React from 'react';
import ReactWeather, { useOpenWeather } from 'react-open-weather';
import { IJob } from 'src/api/models/Job';
interface IWeatherCardProps {
    job: IJob;
}

const customStyles = {
    fontFamily: '-apple-system, "BlinkMacSystemFont", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Open Sans", "Helvetica Neue", "Icons16", sans-serif',
    gradientStart: '#116faa',
    gradientMid: '#1484c9',
    gradientEnd: '#55b3ee',
    locationFontColor: '#FFF',
    todayTempFontColor: '#FFF',
    todayDateFontColor: '#B5DEF4',
    todayRangeFontColor: '#B5DEF4',
    todayDescFontColor: '#B5DEF4',
    todayInfoFontColor: '#B5DEF4',
    todayIconColor: '#FFF',
    forecastBackgroundColor: '#FFF',
    forecastSeparatorColor: '#DDD',
    forecastDateColor: '#777',
    forecastDescColor: '#777',
    forecastRangeColor: '#777',
    forecastIconColor: '#137cbd',
};

export const WeatherCard: React.FC<IWeatherCardProps> = ({ job }) => {
    const { data, errorMessage } = useOpenWeather({
        key: '4eec3031198d60147b84d98f281f8354',
        lat: job.location.geo?.lat,
        lon: job.location.geo?.lng,
        lang: 'en',
        unit: 'imperial', // values are (metric, standard, imperial)
    });
    return (
        <>
            <ReactWeather
                theme={customStyles}
                isLoading={false}
                errorMessage={errorMessage}
                data={data}
                lang="en"
                locationLabel={job.location.address}
                unitsLabels={{ temperature: 'F', windSpeed: 'mph' }}
                showForecast
            />
        </>
    );
};
