import React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';

import { Button, Callout, Classes, FormGroup, InputGroup, Intent } from '@blueprintjs/core';
import { IDefaultFolder } from '../../../../api/models/DefaultFolder';
import { createDefaultFolder, updateDefaultFolder } from '../../../../api/folders';
import { AppToast } from '../../../../components/Toasts/AppToast';

interface ICreateFolderDialogProps {
    selectedFolder: IDefaultFolder | null;
    breadcrumbs: IDefaultFolder[];
    onClose: () => void;
    edit?: boolean;
    onSaveComplete: () => void;
}

export const CreateFolderDialog: React.FC<ICreateFolderDialogProps> = ({ selectedFolder, breadcrumbs, onSaveComplete, onClose, edit }) => {
    const [isSaving, setIsSaving] = React.useState(false);
    const [error, setError] = React.useState<string | null>(null);
    const [name, setName] = React.useState(selectedFolder && edit ? selectedFolder.name : '');

    const handleKeyDown = (e: any) => {
        if (e.key === 'Enter') {
            saveFolder();
        }
    };

    const saveFolder = async () => {
        setIsSaving(true);
        setError(null);
        try {
            if (edit) {
                if (!selectedFolder) return;
                await updateDefaultFolder(selectedFolder.id, {
                    parent_id: selectedFolder.parent_id,
                    master_id: selectedFolder.master_id,
                    name,
                });
                AppToast.show({ message: `"${name}" updated!`, intent: 'success', icon: 'tick-circle' });
                onSaveComplete();
                onClose();
            } else {
                await createDefaultFolder({
                    parent_id: selectedFolder ? selectedFolder.id : null,
                    master_id: selectedFolder ? (selectedFolder.master_id ? selectedFolder.master_id : selectedFolder.id) : null,
                    name,
                });
                AppToast.show({ message: `"${name}" created!`, intent: 'success', icon: 'tick-circle' });
                onSaveComplete();
                onClose();
            }
        } catch (err) {
            setError(err.toLocaleString());
            setIsSaving(false);
        }
    };

    const renderBreadcrumbs = () => {
        return breadcrumbs.map((crumb) => {
            return (
                <span key={crumb.id} className="bp3-button-text bp3-minimal fs-16 " style={{ color: '#182026' }}>
                    {crumb.name}
                </span>
            );
        });
    };

    return (
        <div className={`${Classes.DIALOG_BODY} m-b-0`}>
            <label className="text-bold m-b-10">Location</label>
            <Breadcrumbs className={'m-b-15 m-t-10'} maxItems={4}>
                <span className="bp3-button-text bp3-minimal fs-16 " style={{ color: '#182026' }}>
                    Documents
                </span>
                {renderBreadcrumbs()}
            </Breadcrumbs>
            <FormGroup label="Name" className="text-bold">
                <InputGroup autoFocus onKeyDown={handleKeyDown} autoComplete="off" placeholder="Folder name..." value={name} onChange={(e) => setName(e.target.value)} />
            </FormGroup>
            <Callout intent="primary" className="m-b-15">
                Folder settings and permissions will be set on the job level
            </Callout>
            {error && <p>{error}</p>}
            <div className={Classes.DIALOG_FOOTER}>
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                    <Button onClick={onClose} minimal>
                        Close
                    </Button>
                    <Button onClick={saveFolder} intent={Intent.PRIMARY} minimal loading={isSaving} disabled={name.length < 2}>
                        {edit ? 'Save Changes' : 'Create Folder'}
                    </Button>
                </div>
            </div>
        </div>
    );
};
