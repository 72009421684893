import { getAuthOptions } from './helpers';
import { PostItemOption, PostPayload, PostResponse, PaginatedPostResponse, PostCommentResponse } from './models/Post';
import { IDocument } from './models/Document';
import AppApi from './index';

const api = AppApi.getApi();

export interface FilterState {
    visibility: string;
    changeId?: string;
    resolved: string;
    assignedToMe: boolean;
    createdByMe: boolean;
    constraint: boolean;
    critical: boolean;
    cost: boolean;
    assigned: boolean;
}

export const loadPosts = async (jobId: number, sort: string, filters: FilterState, postType: string, query: string, page: number): Promise<PaginatedPostResponse> => {
    const url = `/jobs/${jobId}/posts/search?sortBy=${sort}&postType=${postType}&query=${query}&page=${page}`;
    const { data } = await api.post(url, { filters }, await getAuthOptions());
    return data;
};

export const loadChangePosts = async (jobId: number, sort: string, filters: FilterState, postType: string, query: string, page: number): Promise<PaginatedPostResponse> => {
    const url = `/jobs/${jobId}/posts/search?sortBy=${sort}&postType=${postType}&query=${query}&page=${page}`;
    const { data } = await api.post(url, { filters }, await getAuthOptions());
    return data;
};

export const resolvePost = async (jobId: number, postId: string, resolved: boolean): Promise<PaginatedPostResponse> => {
    const url = `/jobs/${jobId}/posts/${postId}/resolve`;
    const { data } = await api.post(url, { resolved }, await getAuthOptions());
    return data;
};

export const loadPostById = async (jobId: number, postId: string): Promise<PostResponse> => {
    const url = `/jobs/${jobId}/posts/${postId}`;
    const { data } = await api.get(url, await getAuthOptions());
    return data;
};

export const createPost = async (jobId: number, payload: PostPayload): Promise<PostResponse> => {
    const url = `/jobs/${jobId}/posts`;
    const { data } = await api.post(url, payload, await getAuthOptions());
    return data;
};

export const deletePost = async (jobId: number, postId: string): Promise<PostResponse> => {
    const url = `/jobs/${jobId}/posts/${postId}`;
    const { data } = await api.delete(url, await getAuthOptions());
    return data;
};

export const updatePost = async (jobId: number, postId: string, payload: PostPayload): Promise<PostResponse> => {
    const url = `/jobs/${jobId}/posts/${postId}`;
    const { data } = await api.put(url, payload, await getAuthOptions());
    return data;
};

export const loadLinkItems = async (jobId: number, query: string): Promise<PostItemOption[]> => {
    const url = `/jobs/${jobId}/posts/link_options`;
    const { data } = await api.post(url, { query }, await getAuthOptions());
    return data;
};

export const loadDocumentItems = async (jobId: number, query: string): Promise<IDocument[]> => {
    const url = `/jobs/${jobId}/posts/document_options`;
    const { data } = await api.post(url, { query }, await getAuthOptions());
    return data;
};

export const loadPostComments = async (jobId: number, postId: string): Promise<PostCommentResponse[]> => {
    const url = `/jobs/${jobId}/posts/${postId}/comments`;
    const { data } = await api.get(url, await getAuthOptions());
    return data;
};

export const createPostComment = async (jobId: number, postId: string, comment: string): Promise<PostCommentResponse> => {
    const url = `/jobs/${jobId}/posts/${postId}/comments`;
    const { data } = await api.post(url, { comment }, await getAuthOptions());
    return data;
};

export const updatePostComment = async (jobId: number, postId: string, commentId: string, comment: string): Promise<PostCommentResponse> => {
    const url = `/jobs/${jobId}/posts/${postId}/comments/${commentId}`;
    const { data } = await api.put(url, { comment }, await getAuthOptions());
    return data;
};

export const deletePostComment = async (jobId: number, postId: string, commentId: string): Promise<PostCommentResponse> => {
    const url = `/jobs/${jobId}/posts/${postId}/comments/${commentId}`;
    const { data } = await api.delete(url, await getAuthOptions());
    return data;
};
