import React from 'react';
import { useJobs } from 'src/hooks/useJobs';
import { useHistory, useLocation, Link } from 'react-router-dom';
import { Callout, Spinner, Tag } from '@blueprintjs/core';
import { loadJobSubmittals } from 'src/api/submittals';
import { AuthorizedButton } from 'src/components/AuthorizedButton';
import { FiretrolTable } from 'src/components/FiretrolTable';
import { TableColumn } from 'react-data-table-component';
import { ISubmittal } from '../../../api/models/Submittal';
import { Header2 } from '../../../components/Header2';

const SUBMITTAL_COLUMNS: TableColumn<any>[] = [
    {
        name: 'Submittal Number',
        selector: (s: ISubmittal) => s.id,
        format: (s: ISubmittal) => <Link to={`/jobs/${s.job_id}/submittals/${s.id}`}>{s.submittal_number} </Link>,
        sortable: true,
        width: `200px`,
    },
    {
        name: 'Status',
        selector: (s: ISubmittal) => s.status,
        format: (s: ISubmittal) => <Tag minimal>{s.status} </Tag>,
        sortable: true,
        // width: `200px`,
    },
    {
        name: 'Intent',
        selector: (s: ISubmittal) => s.intent_of_review,
        format: (s: ISubmittal) => (s.intent_of_review === 'info_only' ? 'Information Only' : 'Review and Acceptance'),
        sortable: true,
        //width: `200px`,
    },
    {
        name: 'Assigned To',
        selector: (s: ISubmittal) => s.assigned_to?.full_name || '',
        format: (s: ISubmittal) => s.assigned_to?.full_name,
        sortable: true,
        //width: `200px`,
    },
    {
        name: 'Reporter',
        selector: (s: ISubmittal) => s.reporter?.full_name || '',
        format: (s: ISubmittal) => s.reporter?.full_name,
        sortable: true,
        //width: `200px`,
    },
];

interface IPermissionState {
    allowedRoles: number[];
    allowedUsers: number[];
    blockedUsers: number[];
}

export const Submittals: React.FC = () => {
    const { selectedJob, rehydrateJob } = useJobs();
    const [error, setError] = React.useState<string | null>(null);
    const [loading, setLoading] = React.useState(false);
    const [submittals, setSubmittals] = React.useState<ISubmittal[]>([]);
    const [permissions, setPermissions] = React.useState<IPermissionState>({
        allowedRoles: [],
        allowedUsers: [],
        blockedUsers: [],
    });
    const history = useHistory();
    const location = useLocation();

    React.useEffect(() => {
        rehydrateJob();
        fetchSubmittals();
    }, []);

    const fetchSubmittals = async () => {
        if (!selectedJob) return;
        setLoading(true);
        setError(null);
        try {
            const data = await loadJobSubmittals(selectedJob.id);
            setSubmittals(data);
        } catch (err) {
            setError(err.toLocaleString());
        }
        setLoading(false);
    };

    React.useEffect(() => {
        if (!selectedJob || (selectedJob && !selectedJob.submittal_workflow)) {
            return setPermissions({
                allowedRoles: [],
                allowedUsers: [],
                blockedUsers: [],
            });
        }
        const allowedRoles = selectedJob.submittal_workflow.authorized_roles.map((r) => (r.organization_role_id ? r.organization_role_id : -1));
        const allowedUsers = selectedJob.submittal_workflow.authorized_users.map((r) => r.user_id);
        const blockedUsers = selectedJob.submittal_workflow.excluded_users.map((r) => r.user_id);
        setPermissions({ allowedRoles, allowedUsers, blockedUsers });
    }, [selectedJob?.submittal_workflow]);

    if (!selectedJob) return null;

    const createButton = () => {
        if (!selectedJob || !selectedJob.submittal_workflow) return null;
        return (
            <AuthorizedButton
                allowedRoles={permissions.allowedRoles}
                allowedUsers={permissions.allowedUsers}
                blockedUsers={permissions.blockedUsers}
                text={selectedJob.submittal_workflow.create_button_text}
                onClick={() => history.push(`${location.pathname}/submit/submittal`)}
                intent={selectedJob.submittal_workflow.create_button_color}
                icon="add"
            />
        );
    };
    return (
        <div>
            <Header2
                breadcrumbs={[
                    { title: 'Jobs', link: '/jobs' },
                    { title: `${selectedJob?.name} - ${selectedJob?.job_number}`, link: `/jobs/${selectedJob?.id}/summary` },
                    { title: 'Submittals', link: '' },
                ]}
                actionComponent={<>{createButton()}</>}
            />
            {!selectedJob.submittal_workflow_id && (
                <Callout className="m-b-20" title="Missing Submittal Workflow" intent="danger">
                    Default workflow does not exist. Please contact an administrator.
                </Callout>
            )}
            {error && <p>{error}</p>}
            {loading ? <Spinner className="m-t-50" /> : <FiretrolTable paginationPerPage={10} columns={SUBMITTAL_COLUMNS} data={submittals} />}
        </div>
    );
};
