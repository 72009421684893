import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { AppToast } from 'src/components/Toasts/AppToast';
import { deletePost } from 'src/api/posts';
import { ConfirmDeleteDialog } from '../ConfirmDeleteDialog';

interface PostMenuProps {
    postId: string;
    jobId: number;
    onPostDeleted: () => void;
    onEditClicked: () => void;
}

export const PostMenu: React.FC<PostMenuProps> = ({ postId, onPostDeleted, jobId, onEditClicked }) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [confirmDeleteOpen, setConfirmDeleteOpen] = React.useState(false);
    const [deleting, setDeleting] = React.useState(false);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleDelete = async () => {
        setDeleting(true);
        try {
            await deletePost(jobId, postId);
            AppToast.show({ message: `Post Successfully Deleted`, intent: 'success', icon: 'tick' });
            handleClose();
            onPostDeleted();
        } catch (err) {
            AppToast.show({ message: `Error: ${err.toLocaleString()}`, intent: 'danger', icon: 'tick' });
        }
        setDeleting(false);
    };
    const handleEditClick = () => {
        handleClose();
        onEditClicked();
    };
    return (
        <>
            <ConfirmDeleteDialog open={confirmDeleteOpen} onCancel={() => setConfirmDeleteOpen(false)} onConfirm={handleDelete} deleting={deleting} />
            <IconButton id="basic-button" aria-controls={open ? 'basic-menu' : undefined} aria-haspopup="true" aria-expanded={open ? 'true' : undefined} onClick={handleClick}>
                <MoreVertIcon />
            </IconButton>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={handleEditClick}>
                    <ModeEditOutlinedIcon sx={{ marginRight: '15px' }} /> Edit Post
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        setConfirmDeleteOpen(true);
                        handleClose();
                    }}
                >
                    <DeleteOutlineOutlinedIcon sx={{ marginRight: '15px' }} /> Delete Post
                </MenuItem>
            </Menu>
        </>
    );
};
