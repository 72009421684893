import React from 'react';
import { Row, Col } from 'react-grid-system';
import { Spinner } from '@blueprintjs/core';
import { useJobs } from '../../../hooks/useJobs';
import { RateSheetCard } from 'src/components/RateSheetCard';
import { listJobRates } from '../../../api/rates';
import { IRateResponse } from 'src/api/models/Rate';
import { Header2 } from '../../../components/Header2';

export const RateSheet: React.FC = () => {
    const { selectedJob } = useJobs();
    const [rates, setRates] = React.useState<IRateResponse[]>([]);
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        loadJobRates();
    }, []);

    const loadJobRates = async (load = true) => {
        if (!selectedJob) return;
        setLoading(load);
        const data = await listJobRates(selectedJob.id);
        setRates(data);
        setLoading(false);
    };
    if (!selectedJob) return null;
    return (
        <>
            <Header2
                breadcrumbs={[
                    { title: 'Jobs', link: '/jobs' },
                    { title: `${selectedJob?.name} - ${selectedJob?.job_number}`, link: `/jobs/${selectedJob?.id}/summary` },
                    { title: 'Rate Sheet', link: '' },
                ]}
            />
            {loading ? (
                <div className="text-center p-t-50">
                    <Spinner />
                </div>
            ) : (
                <Row>
                    <Col xs={12} lg={4} xl={4}>
                        <RateSheetCard rates={rates} jobId={selectedJob.id} onRatesChanged={() => loadJobRates(false)} />
                    </Col>
                </Row>
            )}
        </>
    );
};
