import React from 'react';
import { MenuItem, Button } from '@blueprintjs/core';
import { ItemRenderer, Select, ItemPredicate } from '@blueprintjs/select';
import { CostCodeResponse } from 'src/api/models/WBS';
import { listJobCostCodes } from '../../../api/wbs';

const CostCodeSelect = Select.ofType<CostCodeResponse>();

interface ICostCodeSelectorProps {
    onChange: (code: CostCodeResponse | undefined) => void;
    initialValue?: CostCodeResponse | undefined;
}

export const CostCodeSelector: React.FC<ICostCodeSelectorProps> = ({ onChange, initialValue = undefined }) => {
    const [codes, setCodes] = React.useState<CostCodeResponse[]>([]);
    const [selected, setSelected] = React.useState<CostCodeResponse | undefined>(initialValue);
    React.useEffect(() => {
        loadCostCodes();
    }, []);

    const loadCostCodes = async (load = true) => {
        // setLoading(load);
        const data = await listJobCostCodes();
        setCodes(data);
        //setLoading(false);
    };

    React.useEffect(() => {
        onChange(selected);
    }, [selected]);

    const onValueChange = (value: CostCodeResponse) => {
        setSelected(value);
    };

    const filterData: ItemPredicate<CostCodeResponse> = (query, role, _index, exactMatch) => {
        const normalizedTitle = role.code.toLowerCase();
        const normalizedQuery = query.toLowerCase();
        if (exactMatch) {
            return normalizedTitle === normalizedQuery;
        } else {
            return `${role.code}. ${normalizedTitle}`.indexOf(normalizedQuery) >= 0;
        }
    };

    const renderItem: ItemRenderer<CostCodeResponse> = (costCode, { modifiers, handleClick }) => {
        if (!modifiers.matchesPredicate) {
            return null;
        }
        return <MenuItem key={costCode.id} onClick={handleClick} text={`${costCode.code} - ${costCode.description}`} active={selected ? costCode.id === selected.id : false} />;
    };

    return (
        <CostCodeSelect itemPredicate={filterData} itemRenderer={renderItem} items={codes} noResults={<MenuItem disabled={true} text="No results." />} onItemSelect={onValueChange}>
            <Button text={selected ? `${selected.code} - ${selected.description}` : 'Select cost code...'} rightIcon="caret-down" icon="inherited-group" />
        </CostCodeSelect>
    );
};
