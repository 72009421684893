import React from 'react';
import { Button, Classes, Callout, Dialog, InputGroup, Intent, RadioGroup, FormGroup, Radio } from '@blueprintjs/core';
import { createFolder, updateFolder } from 'src/api/documents';
import { useJobs } from '../../../../../hooks/useJobs';
import { AppToast } from '../../../../../components/Toasts/AppToast';
import { IDocument } from 'src/api/models/Document';
import { JobUserSelect } from '../../../../../components/Selectors/JobUserSelect';
import { ITeamUser } from '../../../../../api/models/Job';
interface IFolderDialogProps {
    isOpen: boolean;
    isLocked: boolean;
    currentFolderId: string | null;
    onClose: () => void;
    onFolderCreated: (folder: IDocument) => void;
    onFolderUpdated?: (folder: IDocument) => void;
    parentFolder: IDocument | null;
    masterId: string;
    edit?: boolean;
}

export const FolderDialog: React.FC<IFolderDialogProps> = ({ isOpen, isLocked, masterId, onFolderUpdated, edit, parentFolder, onClose, currentFolderId, onFolderCreated }) => {
    const { selectedJob } = useJobs();
    const [isSaving, setIsSaving] = React.useState(false);
    const [error, setError] = React.useState<string | null>(null);
    const [name, setName] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [sharedWith, setSharedWith] = React.useState<ITeamUser[]>([]);
    const [access, setAccess] = React.useState('all');
    const [folderType, setFolderType] = React.useState('standard');

    const isNew = !edit;
    const isRootFolder = currentFolderId === 'documents' || (!isNew && parentFolder && parentFolder.parent_id === null);
    const isSubFolder = currentFolderId !== 'documents';
    const enableOptions = isRootFolder || (!isNew && !isSubFolder);

    React.useEffect(() => {
        if (!isOpen) {
            resetForm();
        } else if (isOpen && parentFolder !== null) {
            setEmail(parentFolder.email);
            setFolderType(parentFolder.folder_type);
            setAccess(parentFolder.access);
            setSharedWith(parentFolder.shared_with ? parentFolder.shared_with : []);
            if (edit) {
                setName(parentFolder.name);
            }
        }
    }, [isOpen]);

    const resetForm = () => {
        setName('');
        setEmail('');
        setSharedWith([]);
        setAccess('all');
        setFolderType('standard');
    };
    const saveFolder = async () => {
        if (!selectedJob) return;
        setIsSaving(true);
        setError(null);
        try {
            if (edit) {
                if (!parentFolder) return;
                const result = await updateFolder(selectedJob.id, parentFolder.id, {
                    parent_id: parentFolder.parent_id,
                    master_id: masterId === 'documents' ? null : masterId,
                    name,
                    email,
                    folder_type: folderType,
                    access,
                    shared_with: sharedWith,
                });
                AppToast.show({ message: `"${name}" updated!`, intent: 'success', icon: 'tick-circle' });
                if (onFolderUpdated) {
                    onFolderUpdated(result);
                }
                onClose();
            } else {
                const result = await createFolder(selectedJob.id, {
                    parent_id: currentFolderId === 'documents' ? null : currentFolderId,
                    master_id: masterId === 'documents' ? null : masterId,
                    name,
                    email,
                    folder_type: folderType,
                    access,
                    shared_with: sharedWith,
                });
                AppToast.show({ message: `"${name}" created!`, intent: 'success', icon: 'tick-circle' });
                onFolderCreated(result);
                onClose();
            }
        } catch (err) {
            setError(err.toLocaleString());
        }
        setIsSaving(false);
    };
    const handleKeyDown = (e: any) => {
        if (e.key === 'Enter') {
            saveFolder();
        }
    };

    return (
        <Dialog isOpen={isOpen} autoFocus icon="folder-new" title={edit ? 'Edit Folder' : 'New Folder'} onClose={onClose}>
            {isLocked ? (
                <div className={`${Classes.DIALOG_BODY} m-b-0`}>
                    <Callout intent="primary" className="m-b-15">
                        This folder is system generated and cannot be modified
                    </Callout>
                    <FormGroup label="Name" className="text-bold">
                        <InputGroup autoFocus autoComplete="off" disabled placeholder="Folder name..." value={name} />
                    </FormGroup>
                </div>
            ) : (
                <div className={`${Classes.DIALOG_BODY} m-b-0`}>
                    {!isRootFolder && (
                        <Callout intent="primary" className="m-b-15">
                            Subfolders share the same settings as their parent
                        </Callout>
                    )}
                    <FormGroup label="Name" className="text-bold">
                        <InputGroup autoFocus onKeyDown={handleKeyDown} autoComplete="off" placeholder="Folder name..." value={name} onChange={(e) => setName(e.target.value)} />
                    </FormGroup>
                    <label className="text-bold">Folder Type</label>
                    <RadioGroup disabled={!enableOptions} inline className="m-t-10 m-b-5" onChange={({ target }: any) => setFolderType(target.value)} selectedValue={folderType}>
                        <Radio label="Standard" value="standard" />
                        <Radio label="Email Inbox" value="emails" />
                    </RadioGroup>
                    {folderType === 'emails' && (
                        <InputGroup
                            disabled={!enableOptions}
                            className="m-b-15"
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                            placeholder="Enter email..."
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    )}
                    <label className="text-bold">Who can access</label>
                    <RadioGroup disabled={!enableOptions} className="m-t-10 m-b-25" onChange={({ target }: any) => setAccess(target.value)} selectedValue={access}>
                        <Radio label="Anyone with access to this job" value="all" />
                        <Radio label="Only you" value="only_you" />
                        <Radio label="Specific people" value="people" />
                    </RadioGroup>
                    {access === 'people' && (
                        <>
                            <label className="text-bold m-b-10">Select People</label>
                            <div className="m-t-10">
                                <JobUserSelect
                                    disabled={!enableOptions}
                                    hideLabel
                                    value={sharedWith}
                                    onChange={(users) => {
                                        if (users) {
                                            setSharedWith(users);
                                        } else {
                                            setSharedWith([]);
                                        }
                                    }}
                                />
                            </div>
                        </>
                    )}
                </div>
            )}

            {error && <p>{error}</p>}
            <div className={Classes.DIALOG_FOOTER}>
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                    <Button onClick={onClose} minimal>
                        Close
                    </Button>
                    {!isLocked && (
                        <Button onClick={saveFolder} intent={Intent.PRIMARY} minimal loading={isSaving} disabled={name.length < 3}>
                            {edit ? 'Save Changes' : 'Create Folder'}
                        </Button>
                    )}
                </div>
            </div>
        </Dialog>
    );
};
