import React from 'react';
import { Row, Col } from 'react-grid-system';
import { Button, Classes, Dialog, DialogProps, FormGroup, TextArea, InputGroup } from '@blueprintjs/core';
import { createPhase } from 'src/api/checklists';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { BottomToast } from '../../Toasts';

const validationSchema = Yup.object().shape({
    title: Yup.string().required().min(1, 'Title Required').label('Title'),
});

interface IPhaseDialogProps extends DialogProps {
    onCreateComplete: () => void;
    onClose: () => void;
}

export const PhaseDialog: React.FC<IPhaseDialogProps> = ({ isOpen, onClose, onCreateComplete }) => {
    const [error, setError] = React.useState<string | null>(null);
    const formik = useFormik({
        initialValues: { title: '', description: '' },
        validationSchema,
        validateOnMount: false,
        onSubmit: async (values) => {
            setError(null);
            try {
                // do it here
                const result = await createPhase(values);
                console.log('result: ', result);
                BottomToast.show({ message: 'Phase successfully created!', intent: 'success', icon: 'tick-circle' });
                onCreateComplete();
                closeAndReset();
            } catch (err) {
                setError(err.message);
                BottomToast.show({ message: err.message, intent: 'danger', icon: 'warning-sign' });
            }
        },
    });
    const closeAndReset = () => {
        formik.resetForm();
        setError(null);
        onClose();
    };
    return (
        <Dialog usePortal={false} isOpen={isOpen} onClose={onClose} title="New Phase" icon="inheritance" className="task-dialog">
            <div className={`${Classes.DIALOG_BODY} m-0`}>
                <Row>
                    <Col className="m-15">
                        <Row>
                            <Col>
                                <FormGroup label="Title" labelFor="title" className="text-bold">
                                    <InputGroup id="title" name="title" value={formik.values.title} onChange={(e) => formik.setFieldValue('title', e.target.value)} fill />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup label="Description" labelFor="description" className="text-bold">
                                    <TextArea
                                        id="description"
                                        name="description"
                                        value={formik.values.description}
                                        onChange={(e) => formik.setFieldValue('description', e.target.value)}
                                        growVertically={true}
                                        fill
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
            <div className={Classes.DIALOG_FOOTER}>
                {error && <p>{error}</p>}
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                    <Button onClick={closeAndReset}>Close</Button>
                    <Button intent="primary" loading={formik.isSubmitting} onClick={() => formik.handleSubmit()}>
                        Save
                    </Button>
                </div>
            </div>
        </Dialog>
    );
};
