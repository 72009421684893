import { Toaster, Position } from '@blueprintjs/core';

export const BottomToast = Toaster.create({
    position: Position.BOTTOM_RIGHT,
    usePortal: true,
});

export const TopToast = Toaster.create({
    position: Position.TOP_RIGHT,
    usePortal: true,
    // container: document.body,
});
