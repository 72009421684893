import React from 'react';
import { useQuery } from 'react-query';
import { Row, Col } from 'react-grid-system';
import moment from 'moment';
import { loadAllChanges } from 'src/api/rfis';
import { Header2 } from 'src/components/Header2';
import { Card, Typography } from '@mui/material';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { Link } from 'react-router-dom';
import { IJobWithChanges } from '../../api/models/Job';
import './index.scss';

const columns: GridColDef[] = [
    {
        field: 'name',
        headerName: 'Job Name',
        flex: 2,
        renderCell: (params: GridValueGetterParams) => {
            return (
                <Link to={`/jobs/${params.row.job_number}/changes`}>
                    {params.row.job_number} - {params.row.name}
                </Link>
            );
        },
    },
    {
        field: 'open_count',
        headerName: 'Open',
        flex: 1,
        valueGetter: (params: GridValueGetterParams) => {
            return params.row.open_count;
        },
        renderCell: (params: GridValueGetterParams) => {
            return <a>{params.row.open_count}</a>;
        },
    },
    {
        field: 'all_count',
        headerName: 'All',
        flex: 1,
        valueGetter: (params: GridValueGetterParams) => {
            return params.row.all_count;
        },
        renderCell: (params: GridValueGetterParams) => {
            return <a>{params.row.all_count}</a>;
        },
    },
    {
        field: 'past_due',
        headerName: 'Past Due',
        flex: 1,
        valueGetter: (params: GridValueGetterParams) => {
            return params.row.past_due_count;
        },
        renderCell: (params: GridValueGetterParams) => {
            return <a>{params.row.past_due_count}</a>;
        },
    },
];

const rfiColumns: GridColDef[] = [
    {
        field: 'name',
        headerName: 'Change #',
        flex: 1,
        renderCell: (params: GridValueGetterParams) => {
            return <Link to={`/jobs/${params.row.job_id}/changes/view/${params.row.id}`}>{params.row.change_number}</Link>;
        },
    },
    {
        field: 'need_date',
        headerName: 'Need Date',
        flex: 1,
        valueGetter: (params: GridValueGetterParams) => {
            return moment(params.row.date).format('MM/DD/YYYY');
        },
    },
    {
        field: 'status',
        headerName: 'Status',
        flex: 1,
        valueGetter: (params: GridValueGetterParams) => {
            return params.row.status;
        },
    },
];

interface JobWithCounts extends IJobWithChanges {
    open_count: number;
    all_count: number;
    past_due_count: number;
    working_count: number;
    complete_count: number;
    review_count: number;
    draft_count: number;
}

interface SelectedJob {
    job: JobWithCounts;
    type: 'open_count' | 'all_count' | 'past_due';
}

export const Changes: React.FC = () => {
    const [rfis, setRfis] = React.useState<JobWithCounts[]>([]);
    const { data: jobs, isLoading, error } = useQuery<IJobWithChanges[]>(['loadAllChanges'], () => loadAllChanges());
    const [selectedJob, setSelectedJob] = React.useState<SelectedJob | null>(null);

    React.useEffect(() => {
        if (!jobs) return;
        const today = moment();
        const enhanced: JobWithCounts[] = jobs.map((job) => {
            let open_count = 0;
            let past_due_count = 0;
            let review_count = 0;
            let working_count = 0;
            let draft_count = 0;
            let complete_count = 0;
            job.changes.forEach((change) => {
                const need = moment(change.date);
                if (change.status !== 'Complete') {
                    open_count += 1;
                    const isPastDue = today.isAfter(need);
                    if (isPastDue) {
                        past_due_count += 1;
                    }
                }
                if (change.status === 'Review') {
                    review_count += 1;
                }
                if (change.status === 'Working') {
                    working_count += 1;
                }
                if (change.status === 'Draft') {
                    draft_count += 1;
                }
                if (change.status === 'Complete') {
                    complete_count += 1;
                }
            });
            return {
                ...job,
                open_count,
                review_count,
                working_count,
                complete_count,
                draft_count,
                all_count: job.changes.length,
                past_due_count,
            };
        });
        setRfis(enhanced);
    }, [jobs]);

    const getChangeRows = () => {
        if (!selectedJob) return [];
        if (selectedJob.type === 'all_count') {
            return selectedJob.job.changes;
        }
        if (selectedJob.type === 'past_due') {
            const today = moment();
            return selectedJob.job.changes.filter((change) => {
                return change.status !== 'Complete' && today.isAfter(moment(change.date));
            });
        }
        if (selectedJob.type === 'open_count') {
            return selectedJob.job.changes.filter((change) => {
                return change.status !== 'Complete';
            });
        }
        return [];
    };
    return (
        <>
            <Header2 breadcrumbs={[{ title: 'Change Orders', link: '' }]} />
            {error && <p className="text-center text-danger">Error Loading Changes</p>}
            <Row>
                <Col>
                    <div className="rfi-job-table-container">
                        <DataGrid
                            onCellClick={(cell) => {
                                if (cell.field === 'all_count' || cell.field === 'open_count' || cell.field === 'past_due') {
                                    setSelectedJob({
                                        job: cell.row,
                                        type: cell.field,
                                    });
                                }
                            }}
                            // disableSelectionOnClick
                            loading={isLoading}
                            getRowClassName={(row) => {
                                if (!selectedJob) return '';
                                if (row.row.id === selectedJob.job.id) return 'selected-job-row';
                                return '';
                            }}
                            disableSelectionOnClick
                            disableColumnSelector
                            rows={rfis}
                            columns={columns}
                            pageSize={20}
                            rowsPerPageOptions={[20]}
                        />
                    </div>
                </Col>
                {selectedJob && (
                    <Col xs={5} className="p-0">
                        <Card square>
                            <Typography className="m-20 m-b-10">
                                {selectedJob.job.name} - {selectedJob.type === 'open_count' && 'Open Change Orders'} {selectedJob.type === 'all_count' && 'All Change Orders'}{' '}
                                {selectedJob.type === 'past_due' && 'Past Due'}
                            </Typography>
                        </Card>
                        <div className="rfi-job-details-container">
                            <DataGrid disableSelectionOnClick disableColumnSelector rows={getChangeRows()} columns={rfiColumns} pageSize={20} rowsPerPageOptions={[20]} />
                        </div>
                    </Col>
                )}
            </Row>
        </>
    );
};
