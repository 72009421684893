import React from 'react';
import { sumBy, orderBy, sortBy } from 'lodash';
import moment from 'moment';
import { Dialog, DialogTitle, Box, Typography, Grid, ButtonGroup, Button } from '@mui/material';
import { TreeView, TreeItem } from '@mui/lab';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { loadCostCodeBreakdown, loadJobCostCodeMaterials } from 'src/api/ctc';
import { CostCodeBreakdown, CTCMaterial } from 'src/api/models/CTC';
import { POTab } from './POTab';

const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0,
});

interface BreakdownDialogProps {
    handleClose: () => void;
    jobNumber: string;
    costCode: string | null;
    isLabor: boolean;
    description: string | null;
    isHours?: boolean;
    isMtd?: boolean;
    totalValue?: number;
}

const today = moment();

export const BreakdownDialog: React.FC<BreakdownDialogProps> = ({ handleClose, costCode, isLabor, jobNumber, totalValue, isHours = false, description, isMtd }) => {
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState<string | null>(null);
    const [breakdown, setBreakdown] = React.useState<CostCodeBreakdown>({});
    const [material, setMaterial] = React.useState<Record<string, CTCMaterial[]>>({});
    const [tab, setTab] = React.useState<'po' | 'nonpo' | 'all'>('nonpo');

    React.useEffect(() => {
        if (costCode) {
            setTab('nonpo');
            loadBreakdown();
        }
    }, [costCode]);

    const loadBreakdown = async () => {
        if (!costCode) return;
        setLoading(true);
        setError(null);
        try {
            const data = await loadCostCodeBreakdown(jobNumber, costCode);
            const result = !isMtd
                ? data
                : Object.keys(data).reduce((acc, key) => {
                      const items = data[key];

                      const filtered = items.filter((v) => moment(v.DOCDATE).isSame(today, 'month'));
                      if (filtered.length === 0) return acc;
                      return {
                          ...acc,
                          [key]: filtered,
                      };
                  }, {});
            setBreakdown(result);

            if (!isLabor) {
                const materialData = await loadJobCostCodeMaterials(jobNumber, costCode);
                const filteredMaterials = !isMtd
                    ? materialData
                    : Object.keys(materialData).reduce((acc, key) => {
                          const items = materialData[key];

                          const filtered = items.filter((v) => moment(v.DOCDATE).isSame(today, 'month'));
                          if (filtered.length === 0) return acc;
                          return {
                              ...acc,
                              [key]: filtered,
                          };
                      }, {});
                setMaterial(filteredMaterials);
            }
        } catch (err) {
            setError(err.toLocaleString());
        }
        setLoading(false);
    };

    const renderLineItems = (filterPO = false) => {
        let grandTotal = 0;
        let grandTotalHours = 0;
        return (
            <>
                <TreeItem
                    nodeId={'header'}
                    className={'total-block'}
                    label={
                        <Grid container spacing={2} sx={{ p: 1, pr: 0 }}>
                            <Grid item xs={5}>
                                <Typography variant="body2" sx={{ fontWeight: 'inherit', flexGrow: 1 }}>
                                    <b>Description</b>
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                {isLabor && (
                                    <Typography variant="body2" color="inherit" className="text-right">
                                        <b>Hours</b>
                                    </Typography>
                                )}
                            </Grid>
                            <Grid item xs={2}>
                                <Typography variant="body2" color="inherit" className="text-right">
                                    <b>Amount</b>
                                </Typography>
                            </Grid>
                        </Grid>
                    }
                />
                {sortBy(Object.keys(breakdown), [
                    function (o) {
                        return o;
                    },
                ]).map((key) => {
                    const values = breakdown[key];
                    //uniqBy(values, 'DOCDATE')
                    const numberValues = values.map((val) => {
                        return {
                            ...val,
                            Hours_Number: Number(val.Hours),
                            Committed_Cost_Number: Number(val.Amount),
                        };
                    });
                    const tmpItems = orderBy(numberValues, (v) => new Date(v.DOCDATE), 'desc');
                    const orderedValues = filterPO ? tmpItems.filter((v) => v.PONUMBER === '') : tmpItems;
                    const total = sumBy(numberValues, 'Committed_Cost_Number');
                    const totalHours = sumBy(numberValues, 'Hours_Number');
                    grandTotal = grandTotal + total;
                    grandTotalHours = grandTotalHours + totalHours;
                    return (
                        <>
                            <TreeItem
                                key={key}
                                className="basic-block"
                                nodeId={key}
                                label={
                                    <Grid container className="basic-block-root" sx={{ p: 0.5, pr: 0 }}>
                                        <Grid item xs={5}>
                                            <Typography variant="body2" sx={{ fontWeight: 'inherit', flexGrow: 1, textTransform: 'capitalize' }}>
                                                <b>{key.length === 0 ? 'No Description' : key.toLowerCase()}</b>
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={5}>
                                            {isLabor && (
                                                <Typography variant="body2" color="inherit" className="text-right">
                                                    <b>{totalHours.toLocaleString()}</b>
                                                </Typography>
                                            )}
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Typography variant="body2" color="inherit" className="text-right">
                                                <b>{formatter.format(total)}</b>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                }
                            >
                                {orderedValues.map((val) => {
                                    return (
                                        <TreeItem
                                            key={val.id}
                                            nodeId={val.id}
                                            label={
                                                <Grid container spacing={2} sx={{ p: 1, pr: 0 }}>
                                                    <Grid item xs={5}>
                                                        <Typography variant="body2" sx={{ fontWeight: 'inherit', flexGrow: 1, textTransform: 'capitalize' }}>
                                                            {val.DOCNUMBR.length === 0 ? '' : val.DOCNUMBR} ({val.DOCDATE})
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={5}>
                                                        {isLabor && (
                                                            <Typography variant="body2" color="inherit" className="text-right p-r-3">
                                                                {val.Hours}
                                                            </Typography>
                                                        )}
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        <Typography variant="body2" color="inherit" className="text-right">
                                                            {formatter.format(val.Amount)}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            }
                                        />
                                    );
                                })}
                            </TreeItem>
                        </>
                    );
                })}
                <TreeItem
                    nodeId={'grandTotal'}
                    className={'total-block'}
                    label={
                        <Grid container spacing={2} sx={{ p: 1, pr: 0 }}>
                            <Grid item xs={5}>
                                <Typography variant="body2" sx={{ fontWeight: 'inherit', flexGrow: 1, textTransform: 'capitalize' }}>
                                    <b>Total</b>
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                {isLabor && (
                                    <Typography variant="body2" color="inherit" className="text-right">
                                        <b>{grandTotalHours.toLocaleString()}</b>
                                    </Typography>
                                )}
                            </Grid>
                            <Grid item xs={2}>
                                <Typography variant="body2" color="inherit" className="text-right">
                                    <b>{formatter.format(grandTotal)}</b>
                                </Typography>
                            </Grid>
                        </Grid>
                    }
                />
            </>
        );
    };
    const lineItems = React.useMemo(() => renderLineItems(true), [breakdown]);
    const renderNonPo = () => {
        return Object.keys(breakdown).length > 0 ? (
            <TreeView
                //defaultExpanded={expanded}
                aria-label="file system navigator"
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
                sx={{ height: 400, flexGrow: 1, maxWidth: '100%', overflowY: 'auto' }}
            >
                {lineItems}
            </TreeView>
        ) : (
            <div style={{ height: 300 }}>
                <p className="text-center">No Items</p>
            </div>
        );
    };

    const allLineItems = React.useMemo(renderLineItems, [breakdown]);
    const renderAllItems = () => {
        return Object.keys(breakdown).length > 0 ? (
            <TreeView
                //defaultExpanded={expanded}
                aria-label="file system navigator"
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
                sx={{ height: 400, flexGrow: 1, maxWidth: '100%', overflowY: 'auto' }}
            >
                {allLineItems}
            </TreeView>
        ) : (
            <div style={{ height: 300 }}>
                <p className="text-center">No Items</p>
            </div>
        );
    };
    return (
        <Dialog onClose={handleClose} open={costCode !== null} fullWidth maxWidth="md">
            <DialogTitle>
                {description} - {costCode}
            </DialogTitle>
            <Box sx={{ p: 2, pt: 0 }}>
                {loading && <p className="p-l-10 m-b-100">Loading...</p>}
                {error && <p>{error}</p>}
                {!loading && (
                    <>
                        {isLabor && <>{renderNonPo()}</>}
                        {!isLabor && (
                            <div className="p-l-10">
                                <div>
                                    <ButtonGroup className="m-b-25">
                                        <Button variant={tab === 'nonpo' ? 'contained' : 'outlined'} onClick={() => setTab('nonpo')}>
                                            NO PO
                                        </Button>
                                        <Button variant={tab === 'po' ? 'contained' : 'outlined'} onClick={() => setTab('po')}>
                                            PO
                                        </Button>
                                        <Button variant={tab === 'all' ? 'contained' : 'outlined'} onClick={() => setTab('all')}>
                                            INVOICED
                                        </Button>
                                    </ButtonGroup>
                                </div>
                                {tab === 'all' && <>{renderAllItems()}</>}
                                {tab === 'nonpo' && <>{renderNonPo()}</>}
                                {tab === 'po' && <POTab material={material} />}
                            </div>
                        )}
                    </>
                )}
            </Box>
        </Dialog>
    );
};
