import React from 'react';
import { Button, Card, FormGroup, InputGroup } from '@blueprintjs/core';
import { Row, Col } from 'react-grid-system';
import { IWorkflowResponse, IWorkflowStep } from 'src/api/models/Workflow';
import { useAuth } from 'src/hooks/useAuth';
import { AuthorizedButton } from 'src/components/AuthorizedButton';
import { JobUserSelect } from '../../Selectors/JobUserSelect/single';
import { ITeamUser } from '../../../api/models/Job';
interface IWorkflowButtonsProps {
    error?: string | null;
    stepIndex: number;
    currentStatus: string;
    onSubmitClick: (nextStatus: string, nextStepIndex: number) => void;
    onSaveOnlyClick: () => void;
    onVoidClick: () => void;
    workflow: IWorkflowResponse | null;
    onExternalEmailChange: (email: string) => void;
    onAssignedToChange?: (user: ITeamUser | null) => void;
    assignedTo?: number | null;
    hideAssignToSelector?: boolean;
    disableSubmit?: boolean;
}

export const WorkflowButtons: React.FC<IWorkflowButtonsProps> = ({
    error,
    onAssignedToChange,
    assignedTo,
    onExternalEmailChange,
    onVoidClick,
    workflow,
    stepIndex,
    onSubmitClick,
    onSaveOnlyClick,
    hideAssignToSelector = false,
    disableSubmit = false,
}) => {
    const { isAdmin, user } = useAuth();
    const [nextStep, setNextStep] = React.useState<IWorkflowStep | null>(null);
    const [currentStep, setCurrentStep] = React.useState<IWorkflowStep | null>(null);
    const [loading, setLoading] = React.useState('');
    const [canSubmitRoleIds, setCanSubmitRoleIds] = React.useState<number[]>([]);
    const [canSubmitUserIds, setCanSubmitUserIds] = React.useState<number[]>([]);
    const [excludedUserIds, setExcludedUserIds] = React.useState<number[]>([]);
    const [externalEmail, setExternalEmail] = React.useState('');
    const isCreating = React.useMemo(() => {
        return currentStep === null || stepIndex === -1;
    }, [currentStep, stepIndex]);
    React.useEffect(() => {
        if (workflow) {
            const n = workflow.steps[stepIndex + 1];
            setNextStep(n);

            if (stepIndex >= 0) {
                const c = workflow.steps[stepIndex];
                const canSubmitRoles = c.assigned_roles.map((r) => (r.organization_role_id ? r.organization_role_id : -1));
                const canSubmitUsers = c.assigned_users.map((u) => u.user.user_id);
                const excludedUsers = c.excluded_assigned_users.map((u) => u.user.user_id);

                setCurrentStep(c);
                setCanSubmitRoleIds(canSubmitRoles);
                setCanSubmitUserIds(canSubmitUsers);
                setExcludedUserIds(excludedUsers);
            } else {
                const canSubmitRoles = workflow.authorized_roles.map((r) => (r.organization_role_id ? r.organization_role_id : -1));
                const canSubmitUsers = workflow.authorized_users.map((u) => u.user.user_id);
                const excludedUsers = workflow.excluded_users.map((u) => u.user.user_id);
                setCanSubmitRoleIds(canSubmitRoles);
                setCanSubmitUserIds(canSubmitUsers);
                setExcludedUserIds(excludedUsers);
            }
        }
    }, [stepIndex]);

    React.useEffect(() => {
        onExternalEmailChange(externalEmail);
    }, [externalEmail]);
    if (!workflow) {
        return (
            <Card>
                <p>Something went wrong loading workflow.</p>
            </Card>
        );
    }

    const doSubmit = async (nextStatus: string, nextId: number) => {
        setLoading(nextStatus);
        try {
            await onSubmitClick(nextStatus, nextId);
        } catch (err) {
            console.log('err: ', err);
        }
        setLoading('');
    };

    const doSaveOnly = async (nextStatus: string) => {
        setLoading(nextStatus);
        try {
            if (nextStatus === 'Voided') {
                await onVoidClick();
            } else {
                await onSaveOnlyClick();
            }
        } catch (err) {
            console.log('err: ', err);
        }
        setLoading('');
    };

    console.log('next: ', nextStep);
    console.log('current: ', currentStep);
    console.log('assigned: ', assignedTo);
    const disableSubmitForAssignee = nextStep?.is_dynamic_user_assignment && !assignedTo;
    const DraftButton =
        isCreating && workflow?.allow_draft ? (
            <AuthorizedButton
                allowedRoles={canSubmitRoleIds}
                allowedUsers={canSubmitUserIds}
                blockedUsers={excludedUserIds}
                className="m-l-15"
                loading={loading === 'Draft'}
                onClick={() => doSubmit('Draft', -1)}
            >
                Save Draft
            </AuthorizedButton>
        ) : null;
    const SubmitButton = nextStep ? (
        <AuthorizedButton
            allowedRoles={canSubmitRoleIds}
            allowedUsers={canSubmitUserIds}
            blockedUsers={excludedUserIds}
            className="m-l-15"
            disabled={(nextStep?.is_external_step && externalEmail.length < 5) || disableSubmitForAssignee || disableSubmit}
            intent={nextStep.button_color}
            loading={loading === nextStep.label}
            allowAll={currentStep?.is_external_step}
            onClick={nextStep ? () => doSubmit(nextStep.label, stepIndex + 1) : undefined}
        >
            {nextStep.button_text}
        </AuthorizedButton>
    ) : null;
    const VoidButton =
        !isCreating && currentStep && currentStep.allow_void ? (
            <AuthorizedButton
                allowedRoles={canSubmitRoleIds}
                allowedUsers={canSubmitUserIds}
                blockedUsers={excludedUserIds}
                className="m-l-15"
                intent="none"
                loading={loading === 'Voided'}
                onClick={() => doSaveOnly('Voided')}
            >
                Void
            </AuthorizedButton>
        ) : null;
    const RejectButton =
        !isCreating && nextStep && nextStep.allow_reject ? (
            <AuthorizedButton
                allowedRoles={canSubmitRoleIds}
                allowedUsers={canSubmitUserIds}
                blockedUsers={excludedUserIds}
                className="m-l-15"
                intent="danger"
                loading={loading === 'Rejected'}
                onClick={() => doSubmit('Rejected', stepIndex - 1)}
            >
                Reject
            </AuthorizedButton>
        ) : null;
    const UpdateButton =
        !isCreating && isAdmin ? (
            <Button className="m-l-15" loading={loading === 'Saving'} onClick={() => doSaveOnly('Saving')}>
                Save Updates
            </Button>
        ) : null;

    const hide = !UpdateButton && !DraftButton && !SubmitButton && !RejectButton && !VoidButton && !error && !currentStep?.is_external_step;
    if (hide) return null;
    return (
        <Card>
            {error && <p>{error}</p>}
            <Row>
                {nextStep?.is_dynamic_user_assignment && !hideAssignToSelector && (
                    <Col>
                        <JobUserSelect
                            hideLabel
                            small
                            placeholder="Select assignee..."
                            hideRole
                            selectedUserId={assignedTo}
                            onChange={(value) => {
                                if (onAssignedToChange) {
                                    onAssignedToChange(value);
                                }
                            }}
                        />
                    </Col>
                )}
                {nextStep?.is_external_step && user && (
                    <Col>
                        <FormGroup label="Enter Customer Email" labelFor="external_email">
                            <InputGroup id="external_email" name="external_email" value={externalEmail} onChange={(e) => setExternalEmail(e.target.value)} placeholder="Enter email..." />
                        </FormGroup>
                    </Col>
                )}
                <Col className={`text-center ${nextStep?.is_external_step && 'm-t-25 text-left'}`}>
                    {UpdateButton}
                    {DraftButton}
                    {RejectButton}
                    {VoidButton}
                    {SubmitButton}
                </Col>
            </Row>
        </Card>
    );
};
