import React from 'react';
import { DataGridPremium, useGridApiRef, useKeepGroupedColumnsHidden } from '@mui/x-data-grid-premium';
import { IMeeting } from 'src/api/models/Meeting';
import { getMeetingTableColumns } from './tableColumns';

const MeetingTableColumns = getMeetingTableColumns();

interface IMeetingTableProps {
    meetings: IMeeting[];
    isLoading?: boolean;
    onMeetingClicked: (meeting: IMeeting) => void;
    selectedMeetingId: string | null;
    caption?: string;
}

export const MeetingTable: React.FC<IMeetingTableProps> = ({ meetings, caption, selectedMeetingId, isLoading = false, onMeetingClicked }) => {
    const apiRef = useGridApiRef();
    const initialState = useKeepGroupedColumnsHidden({
        apiRef,
        initialState: {
            rowGrouping: {
                model: ['meeting_type_name'],
            },
            columns: {
                columnVisibilityModel: { meeting_type_name: false },
            },
        },
    });

    const formatted = React.useMemo(
        () =>
            meetings.map((d) => {
                return {
                    ...d,
                    meeting_type_name: d.meeting_type ? d.meeting_type.name : '-',
                };
            }),
        [meetings],
    );

    return (
        <div className="meeting-table-container">
            <DataGridPremium
                apiRef={apiRef}
                defaultGroupingExpansionDepth={-1}
                columns={MeetingTableColumns}
                disableSelectionOnClick
                rows={formatted}
                pagination
                autoPageSize
                rowGroupingColumnMode="single"
                initialState={initialState}
            />
        </div>
    );
};
