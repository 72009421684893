import React from 'react';
import { useDrop } from 'react-dnd';
import { DragTypes } from 'src/components/RateSheetCard/DragTypes';
import { IRateResponse } from 'src/api/models/Rate';
import { ListItem } from './ListItem';
import { Col, Row } from 'react-grid-system';

interface IItemsContainerProps {
    items: IRateResponse[];
    onItemsChanged: (items: IRateResponse[]) => void;
    showTotal?: boolean;
}
export const ItemsContainer: React.FC<IItemsContainerProps> = ({ items, onItemsChanged, showTotal = false }) => {
    const [rates, setRates] = React.useState<IRateResponse[]>(items);
    React.useEffect(() => {
        onItemsChanged(rates);
    }, [rates]);

    const [{ isOver }, drop] = useDrop({
        accept: [DragTypes.RATE],
        drop: (item: any) => {
            onDrop(item.data);
        },
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
    });

    const onDrop = (rate: IRateResponse) => {
        const isThere = rates.find((r) => r.id === rate.id);
        if (!isThere) {
            setRates((prevRates) => {
                return [...prevRates, rate];
            });
        }
    };
    const onRemove = (rate: IRateResponse) => {
        setRates((prevRates) => {
            return prevRates.filter((r) => r.id !== rate.id);
        });
    };

    const onQuanityChange = (rate: IRateResponse, quantity: string) => {
        setRates((prevState) => {
            return prevState.map((r) => {
                if (r.id === rate.id) {
                    return {
                        ...r,
                        quantity,
                    };
                }
                return r;
            });
        });
    };
    const renderRates = (includeTotal = false) => {
        return rates.map((r) => {
            return <ListItem key={r.id} rate={r} onRemove={() => onRemove(r)} onQuanityChange={(val) => onQuanityChange(r, val)} includeTotal={includeTotal} />;
        });
    };

    if (showTotal) {
        const total = rates.reduce((acc, rate) => {
            return acc + Number(rate.quantity) * Number(rate.rate);
        }, 0);
        return (
            <div ref={drop} role={DragTypes.CONTAINER} className={`item-container ${isOver && 'is-over'}`}>
                {rates.length > 0 && (
                    <div style={{ backgroundColor: '#fff', width: '100%' }}>
                        <Row style={{ backgroundColor: '#fff' }}>
                            <Col xs={3}>
                                <h4 className="">Item</h4>
                            </Col>
                            <Col xs={3}>
                                <h4>Qty</h4>
                            </Col>
                            <Col xs={3}>
                                <h4>Rate</h4>
                            </Col>
                            <Col>
                                <h4 className="text-right">Total</h4>
                            </Col>
                            <Col />
                        </Row>
                    </div>
                )}

                {renderRates(true)}
                {rates.length > 0 && (
                    <>
                        <hr />
                        <Row>
                            <Col xs={3} />
                            <Col xs={3} />
                            <Col xs={3} />
                            <Col className="text-right">${total.toLocaleString()}</Col>
                            <Col />
                        </Row>
                    </>
                )}

                {rates.length === 0 && <div className="text-center p-t-50">Drag & Drop Rates Here</div>}
            </div>
        );
    }
    return (
        <>
            <div ref={drop} role={DragTypes.CONTAINER} className={`item-container ${isOver && 'is-over'}`}>
                {rates.length > 0 && (
                    <div style={{ backgroundColor: '#fff', width: '100%' }}>
                        <Row style={{ backgroundColor: '#fff' }}>
                            <Col xs={5}>
                                <h4 className="">Item</h4>
                            </Col>
                            <Col xs={5}>
                                <h4>Qty</h4>
                            </Col>
                        </Row>
                    </div>
                )}

                {renderRates()}
                {rates.length === 0 && <div className="text-center p-t-50">Drag & Drop Rates Here</div>}
            </div>
        </>
    );
};
