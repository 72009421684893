import React from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { IChangeOrderResponse } from 'src/api/models/ChangeOrder';

interface IChangesTableProps {
    changes: IChangeOrderResponse[];
    loading?: boolean;
}

const columns: GridColDef[] = [
    {
        field: 'change_number',
        headerName: 'Change',
        width: 200,
    },
    {
        field: 'date',
        headerName: 'Date',
        width: 150,
        renderCell: (params: GridValueGetterParams) => {
            return moment(params.row.date).format('MM/DD/YYYY');
        },
    },
    {
        field: 'description',
        headerName: 'Summary',
        renderCell: (params: GridValueGetterParams) => {
            return params.row.description;
        },
    },
    {
        field: 'cost',
        headerName: 'Cost',
        width: 150,
        renderCell: (params: GridValueGetterParams) => {
            return params.row.cost;
        },
    },
    {
        field: 'schedule',
        headerName: 'Schedule',
        width: 150,
        renderCell: (params: GridValueGetterParams) => {
            return params.row.schedule;
        },
    },
    {
        field: 'created_by',
        headerName: 'Created By',
        width: 150,
        renderCell: (params: GridValueGetterParams) => {
            return params.row.created_by.full_name;
        },
    },
    {
        field: 'assignee',
        headerName: 'Assignee',
        width: 150,
        renderCell: (params: GridValueGetterParams) => {
            return params.row.assignee.full_name;
        },
    },
    {
        field: 'status',
        headerName: 'Status',
        width: 150,
        renderCell: (params: GridValueGetterParams) => {
            return params.row.status;
        },
    },
    // {
    //     field: 'description',
    //     headerName: 'description',
    //     width: 150,
    // },
];

export const ChangesTable: React.FC<IChangesTableProps> = ({ changes, loading = true }) => {
    const history = useHistory();
    return (
        <div style={{ height: 600, width: '100%', backgroundColor: '#fff' }}>
            <DataGrid
                sx={{
                    '& .MuiDataGrid-cell': {
                        cursor: 'pointer',
                    },
                }}
                disableSelectionOnClick
                disableColumnSelector
                onCellClick={async (params) => {
                    history.push(`/jobs/${params.row.job_id}/changes/view/${params.row.id}`);
                }}
                loading={loading}
                rows={changes}
                columns={columns}
                pageSize={20}
                rowsPerPageOptions={[20]}
            />
        </div>
    );
};
