import React from 'react';
import { Card, Button, Icon, Checkbox, InputGroup, RadioGroup, Radio } from '@blueprintjs/core';
import { Row, Col } from 'react-grid-system';
import InlineEdit from '@atlaskit/inline-edit';
import { FormGroupWithHelp } from 'src/components/FormGroupWithHelp';
import { RoleUserSelector } from '../RoleUserSelector';
import { ColorSelect } from 'src/components/Selectors/ColorSelect';
import { JobUserSelect } from 'src/components/Selectors/JobUserSelect';
import { IWorkflowSettings } from 'src/api/models/Workflow';

interface IWorkflowSettingsProps {
    settings: IWorkflowSettings;
    onChange: (key: string, value: any) => void;
    isTemplateEditor: boolean;
}

export const WorkflowSettings: React.FC<IWorkflowSettingsProps> = ({ settings, onChange, isTemplateEditor }) => {
    const [isOpen, setIsOpen] = React.useState(false);
    return (
        <>
            <Card className="m-b-25 p-15 p-t-10">
                <Row>
                    <Col>
                        <FormGroupWithHelp className="m-b-0 m-t-10" labelBottomMargin={10} label="Workflow Name" helpText="What do you want to call this workflow?" />
                        <InlineEdit
                            defaultValue={settings.name}
                            editView={({ errorMessage, ...fieldProps }, ref) => <InputGroup inputRef={ref} {...fieldProps} />}
                            readView={() => <div className="read-only-inline-edit">{settings.name || 'Click to enter a value'}</div>}
                            onConfirm={(val: any) => onChange('name', val)}
                            keepEditViewOpenOnBlur={false}
                            readViewFitContainerWidth
                        />
                    </Col>
                </Row>
                {isTemplateEditor && (
                    <>
                        <Row>
                            <Col>
                                <FormGroupWithHelp className="m-b-0 m-t-10" labelBottomMargin={15} label="Active" helpText="Allow your organization to use this workflow?" />
                                <RadioGroup
                                    inline
                                    onChange={(e: any) => {
                                        onChange('is_active', e.target.value === 'true');
                                    }}
                                    selectedValue={settings.is_active ? 'true' : 'false'}
                                >
                                    <Radio label="Yes" value="true" />
                                    <Radio label="No" value="false" />
                                </RadioGroup>
                            </Col>
                            <Col>
                                <FormGroupWithHelp className="m-b-0 m-t-10" labelBottomMargin={15} label="Default" helpText="Use this workflow as the default for new jobs?" />
                                <RadioGroup
                                    inline
                                    onChange={(e: any) => {
                                        onChange('is_default', e.target.value === 'true');
                                    }}
                                    selectedValue={settings.is_default ? 'true' : 'false'}
                                >
                                    <Radio label="Yes" value="true" />
                                    <Radio label="No" value="false" />
                                </RadioGroup>
                            </Col>
                        </Row>
                    </>
                )}
            </Card>
            <Card className="m-b-0 p-15 p-t-10">
                <Row>
                    <Col xs={1} className="p-t-11" style={{ maxWidth: 15 }}>
                        <Icon icon="cog" size={14} color="#626a70" />
                    </Col>
                    <Col>
                        <div className="read-only-inline-edit m-t-7 no-hover" style={{ height: 30 }}>
                            Workflow Settings
                        </div>
                    </Col>
                    <Col className="text-right">
                        <Button className="m-t-5" icon={isOpen ? 'chevron-up' : 'chevron-down'} minimal onClick={() => setIsOpen((prevState) => !prevState)} />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {isOpen && (
                            <>
                                <Row>
                                    <Col>
                                        <FormGroupWithHelp className="m-b-0 m-t-10" labelBottomMargin={10} label="Create Button Text" helpText="Text the user will see to initiate workflow " />

                                        <InlineEdit
                                            defaultValue={settings.create_button_text}
                                            // label="Status"
                                            editView={({ errorMessage, ...fieldProps }, ref) => <InputGroup inputRef={ref} {...fieldProps} />}
                                            readView={() => <div className="read-only-inline-edit">{settings.create_button_text || 'Click to enter a value'}</div>}
                                            onConfirm={(val: any) => onChange('create_button_text', val)}
                                            keepEditViewOpenOnBlur={false}
                                            readViewFitContainerWidth
                                        />
                                    </Col>
                                    <Col>
                                        <FormGroupWithHelp className="m-b-0 m-t-10" labelBottomMargin={10} label="Button Color" helpText="Color of the create button" />
                                        <ColorSelect onChange={(value) => onChange('create_button_color', value)} value={settings.create_button_color} hideLabel />
                                    </Col>
                                </Row>
                                <Row className="m-t-20 m-b-15">
                                    <Col>
                                        <Checkbox checked={settings.allow_draft} label="Allow Drafts" onChange={(e: any) => onChange('allow_draft', e.target.checked)} />
                                    </Col>
                                </Row>
                                {/*<h3 className="m-t-5">Authorization</h3>*/}

                                <Row>
                                    <Col>
                                        <FormGroupWithHelp label="Authorized Roles" helpText={'Users with selected roles will be allowed to initiate this workflow'}>
                                            <RoleUserSelector
                                                placeholder="Select authorized roles..."
                                                onFieldChange={onChange}
                                                initialUsers={settings.excluded_users}
                                                onUserSelect={(users) => onChange('excluded_users', users)}
                                                initialSelectedRoles={settings.authorized_roles}
                                                selectDescription="Select users to EXCLUDE from authorized roles!"
                                                selectedRoles={settings.authorized_roles}
                                                field={'authorized_roles'}
                                            />
                                        </FormGroupWithHelp>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <FormGroupWithHelp
                                            hidden={isTemplateEditor}
                                            label="Authorized Users"
                                            helpText="Selected users will also be allowed to transition to this status regardless of roles"
                                        >
                                            <JobUserSelect onChange={(value) => onChange('authorized_users', value)} value={settings.authorized_users} hideLabel />
                                        </FormGroupWithHelp>
                                    </Col>
                                </Row>
                            </>
                        )}
                    </Col>
                </Row>
            </Card>
        </>
    );
};
