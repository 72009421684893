import React from 'react';
import { Card, Button, InputGroup, Icon } from '@blueprintjs/core';
import { Row, Col } from 'react-grid-system';
import InlineEdit from '@atlaskit/inline-edit';
import { IWorkflowStep } from 'src/api/models/Workflow';
import { EditDrawer } from '../EditDrawer';

interface IWorkflowStepProps {
    step: IWorkflowStep;
    onValueChange: any;
    onStepRemoved: (step: IWorkflowStep) => void;
    isTemplateEditor: boolean;
}

export const WorkflowStep: React.FC<IWorkflowStepProps> = ({ step, onValueChange, onStepRemoved, isTemplateEditor }) => {
    const [isOpen, setIsOpen] = React.useState(false);

    const setNameValue = (val: any) => {
        const updated = {
            ...step,
            label: val,
        };
        onValueChange(updated);
    };

    return (
        <Card className="m-b-0 p-15 p-t-10">
            <Row>
                {step.is_locked && (
                    <Col xs={1} className="p-t-11" style={{ maxWidth: 15 }}>
                        <Icon icon="lock" size={14} color="#626a70" />
                    </Col>
                )}
                <Col>
                    <InlineEdit
                        defaultValue={step.label}
                        editView={({ errorMessage, ...fieldProps }, ref) => <InputGroup inputRef={ref} {...fieldProps} />}
                        readView={() => <div className="read-only-inline-edit">{step.label || 'Click to enter a value'}</div>}
                        onConfirm={setNameValue}
                        keepEditViewOpenOnBlur={false}
                        readViewFitContainerWidth
                    />
                </Col>
                <Col className="text-right">
                    <Button className="m-t-5" icon={isOpen ? 'chevron-up' : 'chevron-down'} minimal onClick={() => setIsOpen((prevState) => !prevState)} />
                </Col>
            </Row>
            <Row>
                <Col>{isOpen && <EditDrawer onValueChange={onValueChange} step={step} onStepRemoved={onStepRemoved} isTemplateEditor={isTemplateEditor} />}</Col>
            </Row>
        </Card>
    );
};
