import React from 'react';
import { Button, Card, Checkbox } from '@blueprintjs/core';
import { Col, Row } from 'react-grid-system';
import { getDailySettings, updateDailySettings } from '../../../api/dailys';
import { IDailySettings } from '../../../api/models/Daily';
import { TopToast } from '../../../components/Toasts';

export const SettingsForm: React.FC = () => {
    const [saving, setSaving] = React.useState(false);
    const [formValues, setFormValues] = React.useState<IDailySettings>({
        questions: true,
        tickets: true,
        timesheets: true,
        notes: true,
        progress: true,
        equipment: true,
        contractors: true,
        concerns: true,
        media: true,
    });

    React.useEffect(() => {
        load();
    }, []);

    const update = (field: string, value: boolean) => {
        console.log(field, value);
        setFormValues((prevState) => {
            return {
                ...prevState,
                [field]: value,
            };
        });
    };

    const load = async () => {
        const data = await getDailySettings();
        console.log('data: ', data);
        setFormValues(data);
    };

    const saveSettings = async () => {
        setSaving(true);
        await updateDailySettings(formValues);
        setSaving(false);
        TopToast.show({ message: 'Daily settings updated!', intent: 'success', icon: 'tick-circle' });
    };
    return (
        <Card>
            <h4 className="m-t-0">Include Modules</h4>
            {/*<Checkbox checked={formValues.questions} onChange={(e: any) => update('questions', e.target.checked)} label="Questions" />*/}
            <Checkbox checked={formValues.tickets} label="Work Tickets" onChange={(e: any) => update('tickets', e.target.checked)} />
            <Checkbox checked={formValues.timesheets} label="Timesheets" onChange={(e: any) => update('timesheets', e.target.checked)} />
            <Checkbox checked={formValues.notes} label="Notes" onChange={(e: any) => update('notes', e.target.checked)} />
            <Checkbox checked={formValues.progress} label="Progress" onChange={(e: any) => update('progress', e.target.checked)} />
            <Checkbox checked={formValues.equipment} label="Equipment" onChange={(e: any) => update('equipment', e.target.checked)} />
            <Checkbox checked={formValues.contractors} label="Contractors" onChange={(e: any) => update('contractors', e.target.checked)} />
            <Checkbox checked={formValues.concerns} label="Concerns" onChange={(e: any) => update('concerns', e.target.checked)} />
            <Checkbox checked={formValues.media} label="Media" onChange={(e: any) => update('media', e.target.checked)} />
            <div style={{ minHeight: 75 }} />
            <Row>
                <Col />
                <Col xs={5} className="text-right">
                    <Button intent="primary" loading={saving} onClick={saveSettings}>
                        Save Settings
                    </Button>
                </Col>
            </Row>
        </Card>
    );
};
