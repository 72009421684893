import React from 'react';
import { AiOutlineFileExcel, AiOutlineFilePdf, AiOutlineFileUnknown, AiOutlineFileImage, AiOutlineFileWord } from 'react-icons/ai';
import { GrDocumentCsv } from 'react-icons/gr';
import moment from 'moment';
import { useDropzone } from 'react-dropzone';
import { IAttachment } from 'src/api/models/Attachment';
import { Button, Spinner, Tab, Tabs, TabId } from '@blueprintjs/core';
import { uploadTmpFile } from 'src/api/rfis';
import { download } from 'src/api/attachments';
import './index.scss';
import { useAuth } from '../../hooks/useAuth';

interface IFileDropProps {
    onFilesAdded: (attachments: IAttachment[]) => void;
    onFilesRemoved: (attachment: IAttachment[]) => void;
    filesToRemove?: IAttachment[];
    existingFiles: IAttachment[];
    revisions?: IAttachment[];
    showRevisions?: boolean;
    disabled?: boolean;
    maxFiles?: number;
}

const IconLookup: any = {
    csv: <GrDocumentCsv size={16} />,
    pdf: <AiOutlineFilePdf size={18} />,
    xlsx: <AiOutlineFileExcel size={18} />,
    cfb: <AiOutlineFileExcel size={18} />,
    xls: <AiOutlineFileExcel size={18} />,
    png: <AiOutlineFileImage size={18} />,
    jpg: <AiOutlineFileImage size={18} />,
    jpeg: <AiOutlineFileImage size={18} />,
    docx: <AiOutlineFileWord size={18} />,
};

export const FileDrop: React.FC<IFileDropProps> = ({ onFilesAdded, filesToRemove = [], revisions = [], showRevisions = false, onFilesRemoved, maxFiles = 100, existingFiles, disabled }) => {
    const [removed, setRemoved] = React.useState<IAttachment[]>([]);
    const [attachments, setAttachments] = React.useState<IAttachment[]>(existingFiles);
    const [loading, setLoading] = React.useState(false);
    const [tab, setTab] = React.useState<TabId>('attachments');
    const [error, setError] = React.useState<string | null>(null);
    const { user } = useAuth();
    const onDrop = React.useCallback(async (acceptedFiles) => {
        setLoading(true);
        setError(null);
        try {
            const result = await uploadTmpFile(acceptedFiles[0], user === null);
            setAttachments((prevState) => [...prevState, result]);
        } catch (err) {
            setError(err.toString());
        }

        setLoading(false);
    }, []);

    React.useEffect(() => {
        if (attachments !== existingFiles) {
            onFilesAdded(attachments);
        }
    }, [attachments]);

    React.useEffect(() => {
        onFilesRemoved(removed);
    }, [removed]);

    const { getRootProps, getInputProps, isDragActive, open } = useDropzone({ onDrop, noClick: true, noKeyboard: true, maxFiles: 1 });

    const downloadFile = async (id: string) => {
        await download(id);
    };

    const removeAttachment = (attachment: IAttachment) => {
        setRemoved((prevState) => [...prevState, attachment]);
        setAttachments((prevState) => prevState.filter((a) => a.id !== attachment.id));
    };

    const renderFiles = () => {
        return attachments.map((file) => {
            return (
                <tr key={file.id}>
                    <td>{IconLookup[file.type] || <AiOutlineFileUnknown size={18} />}</td>
                    <td>
                        <a onClick={() => downloadFile(file.id)}>{file.name}</a>
                    </td>
                    <td>{Math.round(file.size / 1000)} KB</td>
                    <td>{moment(file.created_at).format('DD MMM YYYY')}</td>
                    <td className="p-t-8 p-b-0">{user && <Button icon="trash" minimal onClick={() => removeAttachment(file)} small />}</td>
                </tr>
            );
        });
    };
    const renderRevisions = () => {
        return [...revisions, ...filesToRemove.filter((f) => !f.is_temporary)].map((file) => {
            return (
                <tr key={file.id}>
                    <td>{IconLookup[file.type] || <AiOutlineFileUnknown size={18} />}</td>
                    <td>{file.version}</td>
                    <td>
                        <a onClick={() => downloadFile(file.id)}>{file.name}</a>
                    </td>
                    <td>{Math.round(file.size / 1000)} KB</td>
                    <td>{moment(file.created_at).format('DD MMM YYYY')}</td>
                    <td className="p-t-8 p-b-0">{user && <Button icon="trash" minimal onClick={() => removeAttachment(file)} small />}</td>
                </tr>
            );
        });
    };

    const AttachmentTable = (
        <>
            {disabled || (attachments.length === 0 && <p className="m-t-15">No Attachments</p>)}
            {attachments.length > 0 && (
                <table className={`bp3-html-table bp3-html-table-striped ${!disabled && attachments.length < maxFiles && 'm-t-20'}`} style={{ width: '100%' }}>
                    <thead>
                        <tr>
                            <th style={{ width: 18 }} />
                            <th>Name</th>
                            <th>Size</th>
                            <th>Date Added</th>
                            <th />
                        </tr>
                    </thead>
                    <tbody>{renderFiles()}</tbody>
                </table>
            )}
        </>
    );
    const RevisionsTable = (
        <>
            {revisions && revisions.length === 0 && <p className="m-t-20">No Revisions</p>}
            {revisions && revisions.length > 0 && (
                <table className={`bp3-html-table bp3-html-table-striped ${!disabled && attachments.length < maxFiles && 'm-t-20'}`} style={{ width: '100%' }}>
                    <thead>
                        <tr>
                            <th style={{ width: 18 }} />
                            <th>Version</th>
                            <th>Name</th>
                            <th>Size</th>
                            <th>Date Added</th>
                            <th />
                        </tr>
                    </thead>
                    <tbody>{renderRevisions()}</tbody>
                </table>
            )}
        </>
    );
    return (
        <>
            {!disabled && attachments.length < maxFiles && (
                <div {...getRootProps()} className={`bp3-non-ideal-state file-drop ${isDragActive && 'dragging'}`}>
                    <input {...getInputProps()} />
                    <div className={`bp3-non-ideal-state-visual m-b-0 `}>
                        {/*<span className="bp3-icon bp3-icon-plus" />*/}
                        {loading ? (
                            <Spinner className="m-b-15 m-t-10" size={35} />
                        ) : (
                            <Button className="bp3-button bp3-intent-primary" onClick={open}>
                                Choose File
                            </Button>
                        )}
                    </div>
                    {!loading && <div>or drag files here</div>}
                    {error && <div>{error}</div>}
                </div>
            )}
            {!showRevisions ? (
                <>{AttachmentTable}</>
            ) : (
                <>
                    <Tabs onChange={(tabId) => setTab(tabId)} selectedTabId={tab} className="m-b-0">
                        <Tab id="attachments" title="Current" className="m-t-0" panel={<>{AttachmentTable}</>} />
                        <Tab id="revisions" title={`Versions (${revisions && revisions.length ? revisions.length : '0'})`} className="m-t-0" panel={<>{RevisionsTable}</>} />
                        <Tabs.Expander />
                    </Tabs>
                </>
            )}
        </>
    );
};
