import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import { useAuth } from 'src/hooks/useAuth';
import { Avatar, Paper, IconButton, Divider, InputBase, CircularProgress } from '@mui/material';
import { PostCommentResponse } from 'src/api/models/Post';
import { createPostComment } from 'src/api/posts';
import { AppToast } from 'src/components/Toasts/AppToast';
import { useJobs } from 'src/hooks/useJobs';
import './index.scss';

interface NewCommentProps {
    onCommentAdded: (comment: PostCommentResponse) => void;
    postId: string;
}

export const NewComment: React.FC<NewCommentProps> = ({ onCommentAdded, postId }) => {
    const { user } = useAuth();
    const { selectedJob } = useJobs();
    const [value, setValue] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const confirmComment = async () => {
        if (!user || !selectedJob) return;
        setLoading(true);
        try {
            const result = await createPostComment(selectedJob.id, postId, value);
            onCommentAdded(result);
            setValue('');
        } catch (err) {
            AppToast.show({ message: `Error: ${err.toLocaleString()}`, intent: 'danger', icon: 'tick' });
        }
        setLoading(false);
    };

    const handleKeyDown = (e: any) => {
        if (e.key === 'Enter') {
            confirmComment();
            e.preventDefault();
        }
    };

    if (!user) return null;
    return (
        <Paper sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: '100%' }}>
            <Avatar sx={{ width: 24, height: 24, bgcolor: '#2968a0', fontSize: 14, marginLeft: '10px' }}>{user.first_name.slice(0, 1)}</Avatar>
            <InputBase onKeyDown={handleKeyDown} sx={{ ml: 1, flex: 1 }} value={value} placeholder="Add a comment..." onChange={(e) => setValue(e.target.value)} />
            {value.length > 0 ? (
                <>
                    <IconButton sx={{ p: '10px' }} onClick={confirmComment}>
                        {loading ? <CircularProgress /> : <CheckIcon />}
                    </IconButton>
                    <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                    <IconButton color="primary" sx={{ p: '10px' }} aria-label="directions" onClick={() => setValue('')}>
                        <CloseIcon />
                    </IconButton>
                </>
            ) : (
                <div style={{ height: 42 }} />
            )}
        </Paper>
    );
};
