import React from 'react';
import { Avatar, CircularProgress } from '@mui/material';
import BackupIcon from '@mui/icons-material/Backup';
import { useDropzone } from 'react-dropzone';
import { uploadTmpFile } from 'src/api/attachments';
import { IAttachment } from 'src/api/models/Attachment';

interface PostFilesProps {
    onFileUploaded: (file: IAttachment) => void;
}

export const PostFileUploader: React.FC<PostFilesProps> = ({ onFileUploaded }) => {
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState<string | null>(null);

    const onDrop = React.useCallback(async (acceptedFiles) => {
        setLoading(true);
        setError(null);
        try {
            const result = await uploadTmpFile(acceptedFiles[0], 'post');
            onFileUploaded(result);
        } catch (err) {
            setError(err.toString());
        }
        setLoading(false);
    }, []);

    const { getRootProps, getInputProps, isDragActive, open } = useDropzone({ onDrop, noClick: true, noKeyboard: true, maxFiles: 1 });
    return (
        <div {...getRootProps()}>
            <div style={{ height: 215, padding: 15 }}>
                <input {...getInputProps()} />
                <div className={`post-file-uploader ${isDragActive && 'dragging'}`} onClick={open}>
                    <div className="uploader-info">
                        <Avatar sx={{ bgcolor: '#e3e6ea', color: '#000', marginLeft: '80px' }}>{loading ? <CircularProgress /> : <BackupIcon />}</Avatar>
                        {loading ? (
                            <p className="upload-title">Uploading...</p>
                        ) : (
                            <>
                                <p className="upload-title">Add Files</p>
                                <p className="upload-subtitle bp3-text-muted">Or Drag and Drop</p>
                            </>
                        )}
                        {error && <p className="upload-title text-danger">{error}</p>}
                    </div>
                </div>
            </div>
        </div>
    );
};
