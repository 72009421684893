import React from 'react';
import { Col, Row } from 'react-grid-system';
import { Button } from '@blueprintjs/core';
import { useDrag } from 'react-dnd';
import { IRateResponse } from 'src/api/models/Rate';
import { DragTypes } from './DragTypes';
interface IDraggableItemProps {
    rate: IRateResponse;
    onRemove: () => void;
}

export const DraggableItem: React.FC<IDraggableItemProps> = ({ rate, onRemove }) => {
    const [{ opacity, cursor }, drag] = useDrag({
        type: DragTypes.RATE,
        item: {
            data: {
                ...rate,
            },
        },
        collect: (monitor) => ({
            opacity: monitor.isDragging() ? 0.5 : 1,
            cursor: monitor.isDragging() ? 'grabbing' : 'grab',
        }),
    });
    return (
        <div role={DragTypes.RATE} ref={drag} style={{ opacity, cursor, border: '1px dashed #ced9e0', paddingLeft: 10, paddingRight: 10, marginBottom: 5 }}>
            <Row>
                <Col xs={6}>
                    <div className="p-t-10 p-b-10">{rate.label}</div>
                </Col>
                {/*<Col xs={4}>*/}
                {/*    <div className="p-t-10 p-b-10">*/}
                {/*        ${rate.rate} /{rate.rate_measurement}*/}
                {/*    </div>*/}
                {/*</Col>*/}
                <Col className="text-right">
                    <Button style={{ position: 'absolute', right: 10, top: 4 }} onClick={onRemove} intent="danger" minimal icon="trash" />
                </Col>
            </Row>
        </div>
    );
};
