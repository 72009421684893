import React from 'react';
import moment from 'moment';
import { Button } from '@blueprintjs/core';
import Comment, { CommentAction, CommentAuthor, CommentTime } from '@atlaskit/comment';
import { Avatar } from '@mui/material';
import Textarea from '@atlaskit/textarea';
import { updatePostComment, deletePostComment } from 'src/api/posts';
import { AppToast } from 'src/components/Toasts/AppToast';
import { useAuth } from 'src/hooks/useAuth';
import { ConfirmPopover } from 'src/components/ConfirmPopover';
import { PostCommentResponse } from 'src/api/models/Post';

interface CommentValueProps {
    comment: PostCommentResponse;
    onCommentUpdated: (comment: PostCommentResponse) => void;
    onCommentDeleted: (comment: PostCommentResponse) => void;
}

export const CommentValue: React.FC<CommentValueProps> = ({ comment, onCommentUpdated, onCommentDeleted }) => {
    const [editing, setEditing] = React.useState(false);
    const [deleting, setDeleting] = React.useState(false);
    const [saving, setSaving] = React.useState(false);
    const [value, setValue] = React.useState(comment.comment);
    const { user } = useAuth();

    const saveComment = async () => {
        if (value.length === 0) return;
        setSaving(true);
        try {
            const result = await updatePostComment(comment.job_id, comment.post_id, comment.id, value);
            onCommentUpdated(result);
            setEditing(false);
        } catch (err) {
            AppToast.show({ message: `Error: ${err.toLocaleString()}`, intent: 'danger', icon: 'tick' });
        }
        setSaving(false);
    };

    const removeComment = async () => {
        setDeleting(true);
        try {
            await deletePostComment(comment.job_id, comment.post_id, comment.id);
            onCommentDeleted(comment);
        } catch (err) {
            AppToast.show({ message: `Error: ${err.toLocaleString()}`, intent: 'danger', icon: 'tick' });
        }
        setDeleting(false);
    };

    const onFocus = (e: any) => {
        e.target.select();
    };

    const handleKeyDown = (e: any) => {
        if (e.key === 'Enter') {
            saveComment();
        }
    };

    const cancelEdit = () => {
        setValue(comment.comment);
        setEditing(false);
    };

    if (!user) return null;

    const isMyComment = user.id === comment.author_id;
    const deleteAction = (
        <ConfirmPopover
            contentTitle="Are you sure you want to remove this comment?"
            contentBody="This action cannot be reversed."
            confirmButtonText="Delete Comment"
            confirmButtonIntent="danger"
            onConfirmClick={() => removeComment()}
            interactionKind="click"
            loading={deleting}
        >
            <CommentAction>{deleting ? 'Deleting' : 'Delete'}</CommentAction>
        </ConfirmPopover>
    );
    return (
        <div className="m-10">
            <Comment
                avatar={<Avatar sx={{ width: 24, height: 24, fontSize: '14px' }}>{comment.author.first_name.slice(0, 1)}</Avatar>}
                author={<CommentAuthor>{comment.author.full_name}</CommentAuthor>}
                // type="author"
                time={<CommentTime>{moment(comment.created_at).format('MMM Do YYYY [at] h:mm a')}</CommentTime>}
                content={
                    editing ? (
                        <>
                            <Textarea autoFocus onKeyDown={handleKeyDown} onFocus={onFocus} onChange={(e: any) => setValue(e.target.value)} value={value} autoComplete="off" />
                            <div className="text-right m-t-5">
                                <Button intent="primary" disabled={value.length === 0} small minimal onClick={saveComment} loading={saving}>
                                    Save
                                </Button>
                                <Button small minimal onClick={cancelEdit}>
                                    Cancel
                                </Button>
                            </div>
                        </>
                    ) : (
                        <p>{comment.comment}</p>
                    )
                }
                actions={isMyComment ? [<CommentAction onClick={() => setEditing(true)}>Edit</CommentAction>, deleteAction] : []}
            />
        </div>
    );
};
