import React from 'react';
import { useLocation } from 'react-router-dom';
import { IconName } from '@blueprintjs/core';
import { SubMenu } from '../SubMenu';

interface INavigationItem {
    id: string;
    title: string;
    path: string;
    icon: IconName;
    subs?: INavigationItem[];
}

const MENU_ITEMS: INavigationItem[] = [
    {
        id: 'job_info',
        title: 'Job Info',
        path: '/info',
        icon: 'info-sign',
        subs: [
            {
                id: 'summary',
                title: 'Summary',
                path: '/summary',
                icon: 'info-sign',
            },
            // {
            //     id: 'finance',
            //     title: 'Finance',
            //     path: '/finance',
            //     icon: 'dollar',
            // },
            // {
            //     id: 'analysis',
            //     title: 'Analysis',
            //     path: '/analysis',
            //     icon: 'grouped-bar-chart',
            // },
            {
                id: 'feed',
                title: 'Activity Feed',
                path: '/activity',
                icon: 'feed',
            },
            {
                id: 'checklists',
                title: 'Checklists',
                path: '/checklists',
                icon: 'automatic-updates',
            },
            {
                id: 'meetings',
                title: 'Meeting Minutes',
                path: '/meetings',
                icon: 'time',
            },
        ],
    },
    {
        id: 'operations',
        title: 'Operations',
        path: '/operations',
        icon: 'satellite',
        subs: [
            {
                id: 'dailys',
                title: 'Dailys',
                path: '/dailys',
                icon: 'array-date',
            },
            {
                id: 'tix',
                title: 'Work Tickets',
                path: '/tickets',
                icon: 'label',
            },
            {
                id: 'changes',
                title: 'Changes',
                path: '/changes',
                icon: 'changes',
            },
            {
                id: 'installs',
                title: 'Installs',
                path: '/installs',
                icon: 'timeline-events',
            },
            {
                id: 'timesheets',
                title: 'Timesheets',
                path: '/timesheets',
                icon: 'time',
            },
            {
                id: 'plans',
                title: 'Adjusted vs Current',
                path: '/plans',
                icon: 'comparison',
            },
            // {
            //     id: 'pieces',
            //     title: 'Pieces',
            //     path: '/pieces',
            //     icon: 'wrench',
            // },
            {
                id: 'rfis',
                title: 'RFIs',
                path: '/rfis',
                icon: 'exchange',
            },
        ],
    },
    {
        id: 'documents',
        title: 'Documents',
        path: '/documents',
        icon: 'folder-close',
        subs: [
            {
                id: 'all_documents',
                title: 'Documents',
                path: '/documents',
                icon: 'folder-close',
            },
            {
                id: 'drawings',
                title: 'Drawings',
                path: '/drawings',
                icon: 'draw',
            },
            {
                id: 'submittals',
                title: 'Submittals',
                path: '/submittals',
                icon: 'projects',
            },
            // {
            //     id: 'misc',
            //     title: 'Misc Files',
            //     path: '/misc',
            //     icon: 'document',
            // },
        ],
    },
    {
        id: 'materials',
        title: 'Materials',
        path: '/materials',
        icon: 'cube',
        subs: [
            // {
            //     id: 'byType',
            //     title: 'Materials By Type',
            //     path: '/materials',
            //     icon: 'property',
            // },
            {
                id: 'requests',
                title: 'Requests',
                path: '/requests',
                icon: 'inheritance',
            },
            {
                id: 'purchase',
                title: 'Purchase Orders',
                path: '/purchase_orders',
                icon: 'shopping-cart',
            },
            {
                id: 'vendors',
                title: 'Vendor Invoices',
                path: '/vendor_invoices',
                icon: 'shop',
            },
        ],
    },
    {
        id: 'job_settings',
        title: 'Job Settings',
        path: '/settings',
        icon: 'cog',
        subs: [
            {
                id: 'team',
                title: 'Project Team',
                path: '/team',
                icon: 'people',
            },
            {
                id: 'workflows',
                title: 'Workflows',
                path: '/workflows',
                icon: 'data-lineage',
            },
            {
                id: 'rate_sheet',
                title: 'Rate Sheet',
                path: '/rates',
                icon: 'th-list',
            },
            {
                id: 'questions',
                title: 'Daily Questions',
                path: '/questions',
                icon: 'help',
            },
            {
                id: 'wbs',
                title: 'WBS Structure',
                path: '/wbs',
                icon: 'diagram-tree',
            },
            {
                id: 'weather',
                title: 'Weather',
                path: '/weather',
                icon: 'rain',
            },
        ],
    },
];

const buttonCssFn = (props: any) => {
    return {
        color: props.isSelected ? '#004677' : '#42536e',
        backgroundColor: props.isSelected ? '#ebecf0' : '#fff',
    };
};

export const Jobs: React.FC = () => {
    const location = useLocation();
    const basePath = location.pathname.split('/');
    return <SubMenu items={MENU_ITEMS} buttonCssFn={buttonCssFn} goBackText="Back to Jobs" goBackPath="/jobs" computePath={(item) => `/jobs/${basePath[2]}${item.path}`} />;
};
