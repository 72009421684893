import React from 'react';
import moment from 'moment';
import { Row, Col } from 'react-grid-system';
import { ITopicIssue } from 'src/api/models/Meeting';
import { loadIssues } from 'src/api/meetings';
import { AppToast } from 'src/components/Toasts/AppToast';
import { Chip } from '@mui/material';
import { Icon, Card } from '@blueprintjs/core';
import { DataGridPremium, useGridApiRef, useKeepGroupedColumnsHidden } from '@mui/x-data-grid-premium';
import { Link } from 'react-router-dom';

interface IssuesTableProps {
    jobId: number;
}

const PRIORITY_COLORS: any = {
    Low: undefined,
    Medium: 'warning',
    High: 'error',
};

export const IssuesTable: React.FC<IssuesTableProps> = ({ jobId }) => {
    const [issues, setIssues] = React.useState<ITopicIssue[]>([]);
    const [stats, setStats] = React.useState({ my_items: 0, past_due: 0, priority: 0, open: 0 });
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        loadIssueData();
    }, []);

    const loadIssueData = async () => {
        setLoading(true);
        try {
            const data = await loadIssues(jobId);
            const { issues: fetchedIssues, ...restData } = data;
            setStats(restData);
            setIssues(fetchedIssues);
        } catch (err) {
            AppToast.show({ message: `Error: ${err.toLocaleString()}`, intent: 'danger', icon: 'tick' });
        }
        setLoading(false);
    };

    const apiRef = useGridApiRef();
    const initialState = useKeepGroupedColumnsHidden({
        apiRef,
        initialState: {
            rowGrouping: {
                model: ['meeting_type'],
            },
            columns: {
                columnVisibilityModel: { meeting_type_name: false },
            },
        },
    });
    const formatted = React.useMemo(
        () =>
            issues.map((d) => {
                const topics = d.topics;
                const meetingTitle = topics && topics.length > 0 ? topics[0].topic.meeting.title : 'Untitled';
                const topicTitle = topics && topics.length > 0 ? (topics[0].topic.title.length > 0 ? topics[0].topic.title : 'Untitled') : 'Untitled';
                const meetingType = topics && topics.length > 0 ? (topics[0].topic.meeting.meeting_type ? topics[0].topic.meeting.meeting_type.name : 'N/A') : 'Untitled';
                return {
                    ...d,
                    meeting: `${meetingTitle.length === 0 ? 'Untitled' : meetingTitle}`,
                    description: { complete: d.complete, title: d.title, priority: d.priority },
                    meeting_title: meetingTitle.length === 0 ? 'Untitled' : meetingTitle,
                    meeting_type: meetingType,
                    topic_title: topicTitle,
                    topic_name: meetingTitle,
                };
            }),
        [issues],
    );

    const renderStat = (label: string, stat: any) => {
        return (
            <Card>
                <h3 className="text-center m-t-0">{label}</h3>
                <h2 className="text-center m-b-0 bp3-text-muted">{stat}</h2>
            </Card>
        );
    };

    return (
        <>
            <Row className="m-b-20">
                <Col xs={3}>{renderStat('Past Due', stats.past_due)}</Col>
                <Col xs={3}>{renderStat('High Priority', stats.priority)}</Col>
                <Col xs={3}>
                    <Card>
                        <h3 className="text-center m-t-0">All Open</h3>
                        <h2 className="text-center m-b-0 bp3-text-muted">{stats.open}</h2>
                    </Card>
                </Col>
                <Col xs={3}>
                    <Card>
                        <h3 className="text-center m-t-0">My Items</h3>
                        <h2 className="text-center m-b-0 bp3-text-muted">{stats.my_items}</h2>
                    </Card>
                </Col>
            </Row>
            <div className="meeting-table-container">
                <DataGridPremium
                    sx={{
                        '& .MuiDataGrid-cell': {
                            cursor: 'pointer',
                        },
                    }}
                    loading={loading}
                    apiRef={apiRef}
                    defaultGroupingExpansionDepth={-1}
                    groupingColDef={{
                        hideDescendantCount: true,
                    }}
                    columns={[
                        {
                            field: 'meeting_type',
                            headerName: 'Meeting Type',
                            width: 200,
                        },
                        {
                            field: 'meeting',
                            headerName: 'Meeting',
                            width: 250,
                            renderCell: (params) => {
                                if (!params.row.topics) return '--';
                                return <Link to={`/jobs/${params.row.topics[0].topic.job_id}/meetings/edit/${params.row.topics[0].topic.meeting.id}`}>{params.row.meeting}</Link>;
                            },
                        },
                        {
                            field: 'title',
                            headerName: 'Issue',
                            renderCell: (params) => {
                                if (Object.keys(params.row).length === 0) return '';
                                return (
                                    <>
                                        {params.row.complete ? (
                                            <Icon icon="tick-circle" intent="success" size={18} className="m-r-5" />
                                        ) : (
                                            <Icon icon="circle" intent="success" size={18} className="m-r-5" />
                                        )}{' '}
                                        {params.row.title}
                                    </>
                                );
                            },
                            width: 400,
                        },
                        {
                            field: 'topic_title',
                            headerName: 'Topic',
                            width: 200,
                        },
                        {
                            field: 'priority',
                            headerName: 'Priority',
                            renderCell: (params) => {
                                console.log('params: ', params.row);
                                if (!params.row.priority) return '';
                                return <Chip className="m-l--5" color={PRIORITY_COLORS[params.row.priority]} label={`${params.row.priority}`} size="small" />;
                            },
                            width: 200,
                        },
                        {
                            field: 'assignee',
                            headerName: 'Assigned To',
                            renderCell: (params) => {
                                if (Object.keys(params.row).length === 0) return '';
                                return (
                                    <>
                                        {params.row.assignee ? (
                                            <Chip className="m-l--5" label={params.row.assignee.full_name} size="small" />
                                        ) : (
                                            <Chip className="m-l--5" label={'Unassigned'} size="small" />
                                        )}
                                    </>
                                );
                            },
                            width: 200,
                        },
                        {
                            field: 'due_date',
                            headerName: 'Due Date',
                            renderCell: (params) => {
                                if (Object.keys(params.row).length === 0) return '';
                                return (
                                    <Chip
                                        className="m-l--5"
                                        color={params.row.due_date && moment(params.row.due_date).isBefore(moment()) && !params.row.due_date ? 'error' : undefined}
                                        label={params.row.due_date ? `${moment(params.row.due_date).format('MM/DD/YY h:mma')}` : 'No Due Date'}
                                        size="small"
                                    />
                                );
                            },
                            width: 200,
                        },
                        // {
                        //     field: 'actions',
                        //     headerName: '',
                        //     width: 200,
                        //     renderCell: (params) => {
                        //         if (Object.keys(params.row).length === 0) return '';
                        //         if (params.row.topics === undefined || params.row.topics.length === 0 || !params.row.topics[0]) return '';
                        //         const topic: any = params.row.topics[0];
                        //         if (!topic) return '';
                        //         return <Button onClick={() => history.push(`/jobs/${topic.topic.job_id}/meetings/edit/${topic[0].topic.meeting.id}`)}>View Meeting</Button>;
                        //     },
                        // },
                    ]}
                    // onRowClick={(params) => (params.row.topics ? history.push(`/jobs/${params.row.topics[0].topic.job_id}/meetings/edit/${params.row.topics[0].topic.meeting.id}`) : undefined)}
                    disableSelectionOnClick
                    rows={formatted}
                    pagination
                    autoPageSize
                    rowGroupingColumnMode="single"
                    initialState={initialState}
                />
            </div>
        </>
    );
};
