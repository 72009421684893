import React from 'react';
import { Checkbox, Tag, Card } from '@blueprintjs/core';
import { Col, Row } from 'react-grid-system';
import { useChecklist } from 'react-checklist';
import { RoleSelector2 } from 'src/components/Selectors/RoleSelector2';
import { IOrganizationRole } from 'src/api/models/Organization';
import { ITeamUser } from 'src/api/models/Job';
import { loadTeamByRoles } from 'src/api/jobs';
import { useJobs } from 'src/hooks/useJobs';
import './index.scss';

interface IRoleUserSelectorProps {
    field: string;
    onFieldChange: (field: string, value: any) => void;
    onUserSelect?: (users: ITeamUser[]) => void;
    initialSelectedRoles?: IOrganizationRole[];
    selectedRoles: IOrganizationRole[];
    initialUsers?: ITeamUser[];
    selectDescription: string;
    placeholder?: string;
}
export const RoleUserSelector: React.FC<IRoleUserSelectorProps> = ({
    onFieldChange,
    placeholder = '',
    onUserSelect,
    selectedRoles,
    selectDescription,
    field,
    initialSelectedRoles = [],
    initialUsers = [],
}) => {
    const { selectedJob, team } = useJobs();
    const [users, setUsers] = React.useState<ITeamUser[]>(initialUsers);
    const { handleCheck, isCheckedAll, checkedItems, setCheckedItems } = useChecklist(team, {
        key: 'user_id',
        keyType: 'number',
    });

    React.useEffect(() => {
        if (onUserSelect) {
            const selected = team.filter((teamUser) => checkedItems.has(teamUser.user.id));
            onUserSelect(selected);
        }
    }, [checkedItems]);

    React.useEffect(() => {
        const set = new Set<number>();
        initialUsers.forEach((val) => set.add(val.user.id));
        setCheckedItems(set);
    }, []);
    React.useEffect(() => {
        getTeamByRoles();
    }, [selectedRoles]);

    const getTeamByRoles = async () => {
        if (!selectedJob) return;
        const roleIds = selectedRoles.map((r: IOrganizationRole) => r.id);
        const data = await loadTeamByRoles(selectedJob.id, roleIds);
        await setUsers(data);
        await checkedItems.clear();
    };

    return (
        <>
            <RoleSelector2 placeholder={placeholder} onChange={(value) => onFieldChange(field, value)} value={selectedRoles} initialValue={initialSelectedRoles} />
            {users.length > 0 && (
                <Card className="p-0">
                    <div className="checkbox-container">
                        <p>{selectDescription}</p>
                        <Checkbox checked={isCheckedAll} label="Exclude All" onChange={handleCheck} />
                        {users.map((v) => (
                            <Row key={v.id}>
                                <Col>
                                    <Checkbox checked={checkedItems.has(v.user.id)} label={`${v.user.first_name} ${v.user.last_name}`} onChange={handleCheck} data-key={v.user.id} />
                                </Col>
                                <Col>
                                    {v.user.roles.map((r) => {
                                        return (
                                            <Tag key={r.id} minimal className="m-r-5">
                                                {r.name}
                                            </Tag>
                                        );
                                    })}
                                </Col>
                            </Row>
                        ))}
                    </div>
                </Card>
            )}
        </>
    );
};
