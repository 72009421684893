import React from 'react';
import moment from 'moment';
import { css } from '@emotion/core';
import TextArea from '@atlaskit/textarea';
import AvatarGroup from '@atlaskit/avatar-group';
import { fontSize as getFontSize, gridSize as getGridSize } from '@atlaskit/theme/constants';
import InlineEdit from '@atlaskit/inline-edit';
import { Row, Col } from 'react-grid-system';
import { IDocument } from '../../../../../api/models/Document';
import { downloadDocument, updateDocument, uploadDocumentVersion, removeDocument } from 'src/api/documents';
import IconButton from '@mui/material/IconButton';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { IconLookup24, humanFileSize } from '../../utils';
import Textfield from '@atlaskit/textfield';
import { Alignment, Button, Intent, Navbar, ProgressBar } from '@blueprintjs/core';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { AppToast } from '../../../../../components/Toasts/AppToast';
import { useAuth } from '../../../../../hooks/useAuth';
import { useDropzone } from 'react-dropzone';
import { VersionTable } from './VersionTable';
import { ConfirmPopover } from '../../../../../components/ConfirmPopover';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
            {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
        </div>
    );
}
const fontSize = getFontSize();
const gridSize = getGridSize();
const minRows = 2;
const textAreaLineHeightFactor = 2.5;

const readViewContainerStyles = css({
    minHeight: `${gridSize * textAreaLineHeightFactor * minRows}px`,
    color: '#fff!important',
    padding: `${gridSize - 2}px ${gridSize - 2}px`,
    lineHeight: `${(gridSize * textAreaLineHeightFactor) / fontSize}`,
    wordBreak: 'break-word',
});

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

interface IDocumentDetailsProps {
    isOpen: boolean;
    selectedDocument: IDocument | null;
    onUpdateComplete: () => void;
    onUploadComplete: (doc: IDocument) => void;
    refreshSelectedDocument: () => void;
    onRemoveComplete: () => void;
    drawerWidth: number;
    onClose: () => void;
    jobId: number;
}

export const DocumentDetails: React.FC<IDocumentDetailsProps> = ({ onRemoveComplete, refreshSelectedDocument, onUploadComplete, onUpdateComplete, jobId, isOpen, selectedDocument, onClose }) => {
    const [tab, setTab] = React.useState(0);
    const [editValue, setEditValue] = React.useState(selectedDocument ? selectedDocument.description : '');
    const [nameValue, setNameValue] = React.useState(selectedDocument ? selectedDocument.name : '');
    const [uploading, setUploading] = React.useState(false);
    const [error, setError] = React.useState<string | null>(null);
    const { user } = useAuth();

    const onDrop = React.useCallback(
        async (acceptedFiles) => {
            if (!selectedDocument) return;
            try {
                setUploading(true);
                const result = await uploadDocumentVersion(jobId, selectedDocument.id, acceptedFiles[0]);
                onUploadComplete(result);
                AppToast.show({ message: `Version uploaded!!`, intent: 'success', icon: 'tick-circle' });
                setUploading(false);
            } catch (err) {
                console.log(err.toString());
            }
        },
        [selectedDocument, jobId],
    );
    const { getRootProps, getInputProps, open } = useDropzone({
        onDrop,
        maxFiles: 1,
        accept: selectedDocument ? [`.${selectedDocument.subtype}`, `.${selectedDocument.type}`] : [''],
        noClick: true,
        noKeyboard: true,
    });

    React.useEffect(() => {
        if (selectedDocument) {
            setNameValue(selectedDocument.name);
            setEditValue(selectedDocument.description);
        } else {
            setNameValue('');
            setEditValue('');
        }
    }, [selectedDocument, isOpen]);

    const updateDoc = async (field: string, value: string) => {
        if (!selectedDocument) return;
        try {
            const payload = {
                [field]: value,
            };
            await updateDocument(jobId, selectedDocument.id, payload);
            AppToast.show({ message: `Document updated!`, intent: 'success', icon: 'tick-circle' });
            onUpdateComplete();
            refreshSelectedDocument();
        } catch (err) {
            setError(err.toLocaleString());
        }
        //setUpdating(false);
    };

    const renderShared = () => {
        if (!selectedDocument || !user) return null;
        if (!selectedDocument.parent || selectedDocument.parent.access === 'all') {
            return (
                <>
                    <Button disabled icon="unlock" className="m-r-10 m-t--5" />
                    <Typography variant="subtitle1" className="bp3-text-muted" gutterBottom component="span">
                        Anyone who has access to this job
                    </Typography>
                </>
            );
        }
        if (selectedDocument.parent.access === 'only_you' || (selectedDocument.parent.access === 'people' && selectedDocument.parent.shared_with.length === 0)) {
            return (
                <>
                    <Button disabled icon="lock" className="m-r-10 m-t--5" />
                    <Typography variant="subtitle1" className="bp3-text-muted" gutterBottom component="span">
                        Only you
                    </Typography>
                </>
            );
        }
        if (selectedDocument.parent.access === 'people') {
            const data = selectedDocument.parent.shared_with.map((d) => ({
                email: d.user.email,
                key: d.user.email,
                name: user.id === d.user.id ? 'You' : d.user.full_name,
            }));
            return (
                <>
                    <div style={{ width: 230, display: 'inline-block' }}>
                        <AvatarGroup
                            size="small"
                            appearance="stack"
                            maxCount={5}
                            data={[
                                {
                                    email: selectedDocument.parent.uploaded_by.email,
                                    key: selectedDocument.parent.uploaded_by.email,
                                    name: user.id === selectedDocument.parent.uploaded_by_id ? 'You' : selectedDocument.parent.uploaded_by.full_name,
                                },
                                ...data,
                            ]}
                        />
                    </div>
                </>
            );
        }
    };

    const renderVersions = () => {
        if (!selectedDocument) return null;
        return <VersionTable jobId={jobId} documents={selectedDocument.versions} selectedDocumentId={selectedDocument.id} />;
    };

    const deleteDocument = async () => {
        if (!selectedDocument) return;
        await removeDocument(selectedDocument.job_id, selectedDocument.id);
        AppToast.show({ message: `Document removed!`, intent: 'success', icon: 'tick-circle' });
        onRemoveComplete();
    };

    return (
        <div>
            <Navbar className="p-l-0">
                <Navbar.Group align={Alignment.LEFT}>
                    <Navbar.Divider className="m-l-0" />
                    <IconButton onClick={onClose}>
                        <ChevronRightIcon />
                    </IconButton>
                </Navbar.Group>
            </Navbar>
            {uploading && <ProgressBar stripes intent={Intent.PRIMARY} value={1} />}
            {selectedDocument && (
                <div className="m-b-5">
                    <div {...getRootProps()}>
                        <input {...getInputProps()} />
                    </div>
                    <Button small icon="download" text="Download" className="m-t-5 m-l-10" minimal onClick={() => downloadDocument(jobId, selectedDocument.id)} />
                    <Button small icon="history" text="New Version" className="m-t-5 m-l-5" minimal onClick={open} />
                    {!selectedDocument.is_locked ? (
                        <ConfirmPopover
                            contentTitle="Are you sure?"
                            confirmButtonIntent="danger"
                            contentBody={<p>Are you sure you want to remove this document?</p>}
                            confirmButtonText="Delete"
                            onConfirmClick={deleteDocument}
                        >
                            <Button small text="Delete" className="m-t-5 m-l-5" minimal intent="danger" />
                        </ConfirmPopover>
                    ) : (
                        <Button small text="Delete" className="m-t-5 m-l-5" minimal intent="danger" disabled />
                    )}
                </div>
            )}

            <div className="p-20 document-pane">
                {selectedDocument && (
                    <>
                        <div className="m-b-20">
                            {IconLookup24[selectedDocument.type] || IconLookup24['unknown']}{' '}
                            <div style={{ display: 'inline-block', width: 260, verticalAlign: 'bottom' }}>
                                <InlineEdit
                                    defaultValue={nameValue}
                                    editView={({ errorMessage, ...fieldProps }, ref) => (
                                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                        // @ts-ignore - textarea does not pass through ref as a prop
                                        <Textfield {...fieldProps} ref={ref} />
                                    )}
                                    readView={() => (
                                        <div
                                            className="fs-22 m-l-5 p-3 p-t-0 text-400"
                                            style={{ display: 'inline-block', width: 255, textOverflow: 'ellipsis', verticalAlign: 'bottom', whiteSpace: 'nowrap', overflow: 'hidden' }}
                                        >
                                            {nameValue || 'Click to enter text'}
                                        </div>
                                    )}
                                    onConfirm={(value) => {
                                        setNameValue(value);
                                        updateDoc('name', value);
                                    }}
                                    keepEditViewOpenOnBlur
                                    readViewFitContainerWidth
                                />
                            </div>
                        </div>
                        <Box sx={{ width: '100%' }} component="div">
                            <Box sx={{ borderBottom: 1, borderColor: 'divider', marginBottom: 2 }} component="div">
                                <Tabs variant="fullWidth" value={tab} onChange={(event: React.SyntheticEvent, newValue: number) => setTab(newValue)}>
                                    <Tab label="Details" {...a11yProps(0)} />
                                    <Tab label="Activity" {...a11yProps(1)} />
                                </Tabs>
                            </Box>
                            {error && <p>{error}</p>}
                            <TabPanel value={tab} index={0}>
                                <Typography variant="subtitle1" gutterBottom component="div">
                                    Who has access
                                </Typography>
                                {renderShared()}
                                <Typography variant="subtitle1" className="m-t-20" gutterBottom component="div">
                                    Document Properties
                                </Typography>
                                <Row>
                                    <Col className="fs-14" xs={4}>
                                        <p className="bp3-text-muted">Type</p>
                                        <p className="bp3-text-muted">Size</p>
                                        <p className="bp3-text-muted">Owner</p>
                                        <p className="bp3-text-muted">Created</p>
                                        <p className="bp3-text-muted">Modified</p>
                                    </Col>
                                    <Col className="fs-14">
                                        <p className="bp3-text-muted" style={{ textTransform: selectedDocument.is_folder ? 'capitalize' : 'uppercase' }}>
                                            {selectedDocument.type}
                                        </p>
                                        <p className="bp3-text-muted">{selectedDocument.is_folder ? '0 KB' : humanFileSize(selectedDocument.size)}</p>
                                        <p className="bp3-text-muted">{selectedDocument.uploaded_by.full_name}</p>
                                        <p className="bp3-text-muted">{moment(selectedDocument.created_at).format('MM/DD/YYYY')}</p>
                                        <p className="bp3-text-muted">{moment(selectedDocument.updated_at).format('MM/DD/YYYY')}</p>
                                    </Col>
                                </Row>
                                <Typography variant="subtitle1" className="m-t-20" gutterBottom component="div">
                                    Description
                                </Typography>
                                <InlineEdit
                                    defaultValue={editValue}
                                    editView={({ errorMessage, ...fieldProps }, ref) => (
                                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                        // @ts-ignore - textarea does not pass through ref as a prop
                                        <TextArea {...fieldProps} ref={ref} />
                                    )}
                                    readView={() => (
                                        <div css={readViewContainerStyles} style={{ padding: 5, color: '#5c7080' }}>
                                            {editValue || 'Add a description'}
                                        </div>
                                    )}
                                    onConfirm={(value) => {
                                        setEditValue(value);
                                        updateDoc('description', value);
                                    }}
                                    keepEditViewOpenOnBlur
                                    readViewFitContainerWidth
                                />
                                <Typography variant="subtitle1" className="m-t-20" gutterBottom component="div">
                                    Versions
                                </Typography>
                                {renderVersions()}
                            </TabPanel>
                            <TabPanel value={tab} index={1}>
                                {selectedDocument && user && (
                                    <>
                                        {selectedDocument.activity.map((activity) => {
                                            return (
                                                <p key={activity.id}>
                                                    {moment(activity.created_at).format('MM/DD/YYYY')} at {moment(activity.created_at).format('h:mma')}:{' '}
                                                    {activity.user_id === user.id ? 'You' : activity.user.full_name} {activity.action}
                                                </p>
                                            );
                                        })}
                                    </>
                                )}
                            </TabPanel>
                        </Box>
                    </>
                )}
            </div>
        </div>
    );
};
