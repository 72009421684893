import React from 'react';
import moment from 'moment';
import { Button, InputGroup, Spinner, FormGroup, Card } from '@blueprintjs/core';
import { useJobs } from '../../../../hooks/useJobs';
import { useHistory, useParams } from 'react-router-dom';
import { TimesheetCode, TimesheetDraft, Timesheet } from '../../../../api/models/timesheet';
import { getTimesheetDraftById, submitDraftForReview, updateTimesheetDraft } from '../../../../api/timesheets';
import { TimesheetPanel } from './components/TimesheetPanel';
import { Col, Row } from 'react-grid-system';
import { AppToast } from '../../../../components/Toasts/AppToast';
import { Header2 } from '../../../../components/Header2';

function formatChanges(timesheets: Timesheet[]) {
    const result: any = {};
    timesheets.forEach((ts) => {
        result[ts.id] = ts.rows;
    });
    return result;
}
export const EditTimesheetDraft: React.FC = () => {
    const { selectedJob } = useJobs();
    const history = useHistory();
    const params: any = useParams();
    const { draftId } = params;
    const [saving, setSaving] = React.useState(false);
    const [submitting, setSubmitting] = React.useState(false);
    const [error, setError] = React.useState<string | null>(null);
    const [loading, setLoading] = React.useState(false);
    const [draft, setDraft] = React.useState<TimesheetDraft | null>(null);
    const [changes, setChanges] = React.useState({});

    React.useEffect(() => {
        loadDraft(draftId);
    }, [draftId]);

    const loadDraft = async (draftId: string) => {
        if (!selectedJob) return;
        setLoading(true);
        setError(null);
        try {
            const data = await getTimesheetDraftById(selectedJob.id, draftId);
            setDraft(data);

            setChanges(formatChanges(data.timesheets));
        } catch (err) {
            setError(err.toLocaleString());
        }
        setLoading(false);
    };

    const onChange = (timesheetId: string, rows: TimesheetCode[]) => {
        setChanges((prevState) => {
            return {
                ...prevState,
                [timesheetId]: rows,
            };
        });
    };

    const saveAllChanges = async () => {
        if (!selectedJob) return;
        setSaving(true);
        setError(null);
        try {
            await updateTimesheetDraft(selectedJob.id, draftId, changes);
            AppToast.show({ message: `Timesheets updated!`, intent: 'success', icon: 'tick' });
        } catch (err) {
            setError(err.toLocaleString());
        }
        setSaving(false);
    };

    const submitForReview = async () => {
        if (!selectedJob) return;
        setSubmitting(true);
        setError(null);
        try {
            await submitDraftForReview(selectedJob.id, draftId);
            AppToast.show({ message: `Timesheets submitted for review!`, intent: 'success', icon: 'tick' });
            history.push(`/jobs/${selectedJob.id}/timesheets`);
        } catch (err) {
            setError(err.toLocaleString());
        }
        setSubmitting(false);
    };
    if (!selectedJob) return null;
    if (loading) {
        return (
            <div className="m-t-50 text-center">
                <Spinner />
            </div>
        );
    }

    const renderTimesheets = () => {
        if (!draft || !draft.timesheets || draft.timesheets.length === 0) return <p>No In Progress Timesheets</p>;
        return draft.timesheets.map((ts) => {
            return <TimesheetPanel key={ts.id} draftId={draft.id} timesheetId={ts.id} costCodes={ts.rows} startDate={draft.start_date} person={ts.employee} onChange={onChange} />;
        });
    };

    return (
        <>
            <Header2
                breadcrumbs={[
                    { title: 'Jobs', link: '/jobs' },
                    { title: `${selectedJob?.name} - ${selectedJob?.job_number}`, link: `/jobs/${selectedJob?.id}/summary` },
                    { title: 'Timesheets', link: `/jobs/${selectedJob.id}/timesheets` },
                    { title: draft ? `${moment(draft.start_date).format('MM/DD/YYYY')} - ${moment(draft.start_date).add(6, 'days').format('MM/DD/YYYY')}` : '', link: `` },
                ]}
                actionComponent={
                    <>
                        <Button onClick={() => history.push(`/jobs/${selectedJob?.id}/timesheets`)}>Cancel</Button>{' '}
                        <Button onClick={submitForReview} loading={submitting} intent="primary">
                            Submit For Review
                        </Button>
                    </>
                }
            />
            {error && <p className="text-center">{error}</p>}
            {draft && (
                <>
                    <Card className="m-b-15">
                        <Row>
                            <Col xs={6}>
                                <FormGroup label="Created By">
                                    <InputGroup value={`${draft.created_by.full_name} on ${moment(draft.created_at).format('MM/DD/YYYY')}`} disabled />
                                </FormGroup>
                            </Col>
                            <Col xs={6}>
                                <FormGroup label="Status">
                                    <InputGroup value="Draft" disabled />
                                </FormGroup>
                            </Col>
                        </Row>
                    </Card>
                    {renderTimesheets()}
                    <Card>
                        <Row className="m-t-10 m-b-15">
                            <Col className="text-center">
                                <Button onClick={() => history.push(`/jobs/${selectedJob?.id}/timesheets`)}>Cancel</Button>{' '}
                                <Button intent="primary" onClick={saveAllChanges} loading={saving}>
                                    Save All Changes
                                </Button>
                            </Col>
                        </Row>
                    </Card>
                </>
            )}
        </>
    );
};
