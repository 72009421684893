import React from 'react';
import { Dialog, Classes, Button, Tag } from '@blueprintjs/core';
import { IWorkflowStep, IWorkflowSettings } from 'src/api/models/Workflow';
import { IOrganizationRole } from 'src/api/models/Organization';
import { ITeamUser } from 'src/api/models/Job';

interface IWorkflowPreviewProps {
    isOpen: boolean;
    steps: IWorkflowStep[];
    settings: IWorkflowSettings;
    setOpen: (isOpen: boolean) => void;
}

export const WorkflowPreview: React.FC<IWorkflowPreviewProps> = ({ isOpen, settings, steps, setOpen }) => {
    const renderTeamUserNames = (users: ITeamUser[]) => {
        if (users.length === 0) {
            return <Tag>None</Tag>;
        }
        return users.map((user) => (
            <Tag key={user.id}>
                <span style={{ textDecoration: 'line-through' }}>
                    {user.user.first_name} {user.user.last_name}
                </span>
            </Tag>
        ));
    };

    const renderRoles = (roles: IOrganizationRole[]) => {
        if (roles.length === 0) {
            return <Tag>None</Tag>;
        }
        return roles.map((role) => <Tag key={role.id}>{role.name}</Tag>);
    };
    const renderSettings = () => {
        return (
            <>
                <div className="m-b-30">
                    <b>Allow Drafts:</b> <Tag minimal>{settings.allow_draft ? 'TRUE' : 'FALSE'}</Tag>
                </div>
            </>
        );
    };

    const renderFirstStep = () => {
        return (
            <>
                <p className="text-bold">Initiate Workflow</p>
                <div className="m-b-10">
                    Button Text:{' '}
                    <Button intent="primary" small>
                        {settings.create_button_text}
                    </Button>
                </div>
                <p>Allowed Roles: {renderRoles(settings.authorized_roles)}</p>
                <p>Allowed Users: {renderTeamUserNames(settings.authorized_users)}</p>
                <p className="m-b-30">Excluded Users: {renderTeamUserNames(settings.excluded_users)}</p>
            </>
        );
    };
    const renderSteps = () => {
        return steps.map((step, idx) => {
            return (
                <span key={step.step_id}>
                    <p className="text-bold">
                        {idx + 1}. {step.label}
                    </p>
                    <div className="m-b-10">
                        Button Text:{' '}
                        <Button intent="primary" small>
                            {step.button_text}
                        </Button>
                    </div>
                    {step.is_last_step && <p className="m-b-30 text-bold">Items with this status are considered complete</p>}
                    {!step.is_last_step && (
                        <>
                            {step.is_external_step && (
                                <>
                                    <p className="text-bold">Assigned to External Customer</p>
                                    <p className="m-b-30">
                                        Can reject from this status: <Tag minimal>{step.allow_reject ? 'TRUE' : 'FALSE'}</Tag>
                                    </p>
                                </>
                            )}
                            {!step.is_external_step && (
                                <>
                                    <p>Assigned to Roles: {renderRoles(step.assigned_roles)}</p>
                                    <p>Assigned to Users: {renderTeamUserNames(step.assigned_users)}</p>
                                    <p>Excluded Assigned Users: {renderTeamUserNames(step.excluded_assigned_users)}</p>
                                    <p>
                                        Can reject from this status: <Tag minimal>{step.allow_reject ? 'TRUE' : 'FALSE'}</Tag>
                                    </p>
                                    <p className="m-b-30">
                                        Can void from this status: <Tag minimal>{step.allow_void ? 'TRUE' : 'FALSE'}</Tag>
                                    </p>
                                </>
                            )}
                        </>
                    )}
                </span>
            );
        });
    };
    return (
        <Dialog isOpen={isOpen} onClose={() => setOpen(false)} title="Preview RFI Workflow">
            <div className={Classes.DIALOG_BODY}>
                {/*<h2>Steps</h2>*/}
                {renderSettings()}
                {renderFirstStep()}
                {renderSteps()}
            </div>
            <div className={Classes.DIALOG_FOOTER}>
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                    <Button onClick={() => setOpen(false)}>Close</Button>
                </div>
            </div>
        </Dialog>
    );
};
