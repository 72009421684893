import React from 'react';
import { ILinkItem } from 'src/api/models/Checklist';
import { loadItemLinks } from 'src/api/checklists';
import { Link } from 'react-router-dom';
import { Label } from '@blueprintjs/core';
import { Col, Row } from 'react-grid-system';

interface ILinksProps {
    jobId: number;
    itemId: string;
}

export const Links: React.FC<ILinksProps> = ({ jobId, itemId }) => {
    const [links, setLinks] = React.useState<ILinkItem[]>([]);
    React.useEffect(() => {
        loadData();
    }, []);
    const loadData = async () => {
        const submittals = await loadItemLinks(jobId, itemId);
        setLinks(submittals);
    };
    const renderLinks = () => {
        return links.map((s) => {
            return (
                <tr key={s.id}>
                    <td>
                        <Link to={`/jobs/${s.job_id}/${s.resource}/${s.id}`}>{s.item_number}</Link>
                    </td>
                    <td>{s.status}</td>
                </tr>
            );
        });
    };
    if (links.length === 0) return null;
    return (
        <div className="m-b-20">
            <Row>
                <Col>
                    <Label className="m-b-0 text-bold">Links ({links.length})</Label>
                </Col>
            </Row>
            <hr className="m-t-3" />
            <table className={`bp3-html-table bp3-html-table-striped bp3-html-table-condensed`} style={{ width: '100%' }}>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>{renderLinks()}</tbody>
            </table>
        </div>
    );
};
