import { useState, useEffect } from 'react';

export const useIntersection = (element: any, rootMargin: any) => {
    const [isVisible, setState] = useState(false);
    useEffect(() => {
        if (!element.current) return;
        const observer = new IntersectionObserver(
            ([entry]) => {
                setState(entry.isIntersecting);
            },
            { rootMargin },
        );

        element.current && observer.observe(element.current);

        return () => {
            if (observer && element.current) {
                return observer.unobserve(element.current);
            }
        };
    }, [element]);

    return isVisible;
};
