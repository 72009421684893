import React from 'react';
import { Header2 } from 'src/components/Header2';

export const Summary: React.FC = () => {
    return (
        <>
            <Header2 breadcrumbs={[{ title: 'Summary', link: '' }]} />
        </>
    );
};
