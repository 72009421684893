import AppApi from './index';
import { IProject } from './models/Project';
import { getAuthOptions } from './helpers';

const api = AppApi.getApi();

export const loadProjects = async (): Promise<IProject[]> => {
    const url = `/projects`;
    const { data } = await api.get(url, await getAuthOptions());
    return data;
};

export const createProject = async (project: IProject): Promise<IProject> => {
    const url = `/projects`;
    const { data } = await api.post(url, project, await getAuthOptions());
    return data;
};
