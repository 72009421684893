import React from 'react';
import { Header2 } from 'src/components/Header2';
import { useQuery } from 'react-query';
import { IJobWithTimesheets } from '../../api/models/Job';
import { loadAllTimesheets } from '../../api/rfis';
import { Col, Row } from 'react-grid-system';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { Card, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import moment from 'moment';

interface JobWithCounts extends IJobWithTimesheets {
    draft_count: number;
    all_count: number;
    submitted_count: number;
}

interface SelectedJob {
    job: JobWithCounts;
    type: 'draft_count' | 'submitted_count' | 'all_count';
}

const columns: GridColDef[] = [
    {
        field: 'name',
        headerName: 'Job Name',
        flex: 2,
        renderCell: (params: GridValueGetterParams) => {
            return (
                <Link to={`/jobs/${params.row.job_number}/timesheets`}>
                    {params.row.job_number} - {params.row.name}
                </Link>
            );
        },
    },
    {
        field: 'draft_count',
        headerName: 'Draft',
        flex: 1,
        valueGetter: (params: GridValueGetterParams) => {
            return params.row.draft_count;
        },
        renderCell: (params: GridValueGetterParams) => {
            return <a>{params.row.draft_count}</a>;
        },
    },
    {
        field: 'submitted_count',
        headerName: 'Submitted',
        flex: 1,
        valueGetter: (params: GridValueGetterParams) => {
            return params.row.submitted_count;
        },
        renderCell: (params: GridValueGetterParams) => {
            return <a>{params.row.submitted_count}</a>;
        },
    },
    {
        field: 'all_count',
        headerName: 'All',
        flex: 1,
        valueGetter: (params: GridValueGetterParams) => {
            return params.row.all_count;
        },
        renderCell: (params: GridValueGetterParams) => {
            return <a>{params.row.all_count}</a>;
        },
    },
];
const timesheetColumns: GridColDef[] = [
    {
        field: 'employee',
        headerName: 'Employee',
        flex: 1,
        renderCell: (params: GridValueGetterParams) => {
            console.log('status: ', params.row.status);
            if (params.row.status === 'draft') {
                return <Link to={`/jobs/${params.row.job_id}/timesheets/drafts/${params.row.timesheet_draft_id}`}>{params.row.employee.full_name}</Link>;
            }
            return <Link to={`/jobs/${params.row.job_id}/timesheets/view/${params.row.id}`}>{params.row.employee.full_name}</Link>;
        },
    },
    {
        field: 'pay_period',
        headerName: 'Period',
        flex: 1,
        valueGetter: (params: GridValueGetterParams) => {
            return `${moment(params.row.rows[0].start_date).format('MM/DD')} - ${moment(params.row.rows[0].start_date).add(6, 'days').format('MM/DD')}`;
        },
    },
    {
        field: 'status',
        headerName: 'Status',
        flex: 1,
        valueGetter: (params: GridValueGetterParams) => {
            return params.row.status.toUpperCase();
        },
    },
];
export const Timesheets: React.FC = () => {
    const [timesheets, setTimesheets] = React.useState<JobWithCounts[]>([]);
    const { data: jobs, isLoading, error } = useQuery<IJobWithTimesheets[]>(['loadAllTimesheets'], () => loadAllTimesheets());
    const [selectedJob, setSelectedJob] = React.useState<SelectedJob | null>(null);

    React.useEffect(() => {
        if (!jobs) return;
        const enhanced: JobWithCounts[] = jobs.map((job) => {
            let draft_count = 0;
            let submitted_count = 0;
            job.timesheets.forEach((timesheet) => {
                if (timesheet.status === 'submitted') {
                    submitted_count += 1;
                }
                if (timesheet.status === 'draft') {
                    draft_count += 1;
                }
            });
            return {
                ...job,
                draft_count,
                submitted_count,
                all_count: job.timesheets.length,
            };
        });
        setTimesheets(enhanced);
    }, [jobs]);

    const getTimesheetRows = () => {
        if (!selectedJob) return [];
        if (selectedJob.type === 'all_count') {
            return selectedJob.job.timesheets;
        }
        if (selectedJob.type === 'submitted_count') {
            return selectedJob.job.timesheets.filter((timesheet) => {
                return timesheet.status === 'submitted';
            });
        }
        if (selectedJob.type === 'draft_count') {
            return selectedJob.job.timesheets.filter((timesheet) => {
                return timesheet.status === 'draft';
            });
        }
        return [];
    };

    return (
        <>
            <Header2 breadcrumbs={[{ title: 'Timesheets', link: '' }]} />
            {error && <p className="text-center text-danger">Error Loading RFIS</p>}
            <Row>
                <Col>
                    <div className="rfi-job-table-container">
                        <DataGrid
                            onCellClick={(cell) => {
                                if (cell.field === 'all_count' || cell.field === 'draft_count' || cell.field === 'submitted_count') {
                                    setSelectedJob({
                                        job: cell.row,
                                        type: cell.field,
                                    });
                                }
                            }}
                            // disableSelectionOnClick
                            loading={isLoading}
                            getRowClassName={(row) => {
                                if (!selectedJob) return '';
                                if (row.row.id === selectedJob.job.id) return 'selected-job-row';
                                return '';
                            }}
                            disableSelectionOnClick
                            disableColumnSelector
                            rows={timesheets}
                            columns={columns}
                            pageSize={20}
                            rowsPerPageOptions={[20]}
                        />
                    </div>
                </Col>
                {selectedJob && (
                    <Col xs={5} className="p-0">
                        <Card square>
                            <Typography className="m-20 m-b-10">
                                {selectedJob.job.name} - {selectedJob.type === 'draft_count' && 'Draft Timesheets'} {selectedJob.type === 'submitted_count' && 'Submitted Timesheets'}{' '}
                                {selectedJob.type === 'all_count' && 'All Timesheets'}
                            </Typography>
                        </Card>
                        <div className="rfi-job-details-container">
                            <DataGrid disableSelectionOnClick disableColumnSelector rows={getTimesheetRows()} columns={timesheetColumns} pageSize={20} rowsPerPageOptions={[20]} />
                        </div>
                    </Col>
                )}
            </Row>
        </>
    );
};
