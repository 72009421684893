import React from 'react';
import { CircularProgress, Dialog } from '@mui/material';
import { PostItem } from '../PostItem';
import { loadPostById } from 'src/api/posts';
import { markPostNotificationRead } from 'src/api/user';
import { PostResponse } from 'src/api/models/Post';

interface PostViewDialogProps {
    postId: string | null;
    jobId: number;
    open: boolean;
    onClose: () => void;
    openEditDialog: (item: PostResponse) => void;
}

export const PostViewDialog: React.FC<PostViewDialogProps> = ({ postId, jobId, open, onClose, openEditDialog }) => {
    const [post, setPost] = React.useState<PostResponse | null>(null);
    const [loading, setLoading] = React.useState(true);
    const [error, setError] = React.useState<string | null>(null);
    React.useEffect(() => {
        if (postId) {
            fetchPostById();
        } else {
            setPost(null);
        }
    }, [jobId, postId]);

    const fetchPostById = async (load = true) => {
        if (!postId) return;
        setLoading(load);
        setError(null);
        try {
            const data = await loadPostById(jobId, postId);
            setPost(data);
            await markPostNotificationRead(postId);
        } catch (err) {
            setError(err.toLocaleString());
        }
        setLoading(false);
    };

    return (
        <>
            <Dialog open={open} onClose={onClose} fullWidth style={{ padding: 0 }}>
                {loading || !post ? (
                    <div className="p-50 text-center">{error ? <p className="text-center">{error}</p> : <CircularProgress />}</div>
                ) : (
                    <PostItem item={post} displayOnly refreshPosts={console.log} openEditDialog={openEditDialog} />
                )}
            </Dialog>
        </>
    );
};
