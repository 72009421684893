import React from 'react';
import { Icon } from '@blueprintjs/core';
import { ScopeList } from 'src/components/ScopeList';
import './index.scss';

interface IRouteScopeInfoProps {
    scopes: string[];
}

export const RouteScopeInfo: React.FC<IRouteScopeInfoProps> = ({ scopes }) => {
    return (
        <div className="route-role-icon">
            <ScopeList
                scopes={scopes}
                count={0}
                hideLabel
                forceView
                title="Required Scopes"
                description=""
                renderTarget={() => {
                    return (
                        <div>
                            <Icon icon="shield" color="#5c7080" size={20} />
                        </div>
                    );
                }}
            />
        </div>
    );
};
