import React from 'react';
import { Button, Card } from '@blueprintjs/core';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Row, Col } from 'react-grid-system';
import { WorkTicketForm } from '../../../Tickets/WorkTicketForm';
import { IWorkTicketFormValues, IWorkTicketResponse } from 'src/api/models/WorkTicket';
import { useJobs } from 'src/hooks/useJobs';
import { useAuth } from 'src/hooks/useAuth';

interface ITicketsTabProps {
    initialTickets: IWorkTicketResponse[];
    onChange: (tickets: IWorkTicketResponse[]) => void;
    dailyId?: string;
}

export const TicketsTab: React.FC<ITicketsTabProps> = ({ initialTickets, onChange, dailyId }) => {
    const [tickets, setTickets] = React.useState<IWorkTicketResponse[]>(initialTickets);
    const [showForm, setShowForm] = React.useState(false);
    const { selectedJob } = useJobs();
    const { user } = useAuth();
    React.useEffect(() => {
        onChange(tickets);
    }, [tickets]);
    if (!selectedJob || !user) return null;
    console.log('tickets: ', tickets);
    const renderTickets = () => {
        return tickets.map((t) => {
            return (
                <Row key={t.id}>
                    <Col xs={4}>
                        <Card>
                            <p>
                                <span className="text-bold">Requested By:</span> {t.requester.length > 0 ? t.requester : 'N/A'}
                            </p>
                            <p>
                                <span className="text-bold">Date Requested:</span> {moment(t.date).format('MM/DD/YYYY')}
                            </p>
                            <p>
                                <span className="text-bold">Status:</span> {t.status}
                            </p>
                            <Link to={`/jobs/${t.job_id}/tickets/view/${t.id}`} target="_blank" rel="noopener noreferrer">
                                Open Work Ticket
                            </Link>
                        </Card>
                    </Col>
                </Row>
            );
        });
    };
    const initialValues: IWorkTicketFormValues = {
        created_by_id: user.id,
        created_by: user,
        daily_id: dailyId ? dailyId : null,
        use_signature_pad: false,
        signature_string: '',
        requester: '',
        signature: [],
        status: 'Created',
        date: new Date(),
        summary: '',
        requested_by_id: null,
        // requested_by: null,
        work_performed_date: null,
        job_id: selectedJob.id,
        recipient_id: null,
        // recipient: null,
        items: [],
        images: [],
        files_to_add: [],
        files_to_remove: [],
    };
    return (
        <>
            {showForm ? (
                <>
                    <WorkTicketForm
                        jobId={selectedJob.id}
                        initialValues={initialValues}
                        cancelButton={
                            <Button className="m-r-10" onClick={() => setShowForm(false)}>
                                Cancel
                            </Button>
                        }
                        sideCancelButton={
                            <Button fill className="m-b-15" onClick={() => setShowForm(false)}>
                                Cancel
                            </Button>
                        }
                        onTicketCreated={(ticket) => {
                            setTickets((prevState) => [...prevState, ticket]);
                            setShowForm(false);
                        }}
                    />
                </>
            ) : (
                <>
                    <div className="text-right">
                        <Button onClick={() => setShowForm(true)}>Create Ticket</Button>
                    </div>
                    {renderTickets()}
                </>
            )}
        </>
    );
};
