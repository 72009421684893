import React from 'react';
import { Col, Row } from 'react-grid-system';
import { Button, InputGroup } from '@blueprintjs/core';
import { IRateResponse } from 'src/api/models/Rate';

interface IListItemProps {
    rate: IRateResponse;
    onRemove: () => void;
    includeTotal?: boolean;
    onQuanityChange: (value: string) => void;
}

export const ListItem: React.FC<IListItemProps> = ({ rate, onRemove, onQuanityChange, includeTotal = false }) => {
    const [q, setQ] = React.useState(rate.quantity);
    React.useEffect(() => {
        onQuanityChange(q);
    }, [q]);
    if (includeTotal) {
        return (
            <Row>
                <Col xs={3}>
                    <div className="p-t-10 p-b-10">{rate.label}</div>
                </Col>
                <Col xs={3} className="p-t-5">
                    <InputGroup
                        placeholder="qty"
                        fill
                        value={q}
                        onChange={(e) => {
                            if (isNaN(Number(e.target.value)) && e.target.value !== '') return;
                            setQ(e.target.value);
                        }}
                        rightElement={<Button style={{ color: '#000' }} minimal disabled text={`${rate.rate_measurement}s`} intent="primary" />}
                    />
                </Col>
                <Col xs={3} className="m-t-10">
                    ${rate.rate} / {rate.rate_measurement}
                </Col>
                <Col className="text-right m-t-10">${(Number(rate.quantity) * Number(rate.rate)).toLocaleString()}</Col>
                <Col className="text-right">
                    <Button style={{ position: 'absolute', right: 10, top: 4 }} onClick={onRemove} intent="danger" minimal icon="trash" />
                </Col>
            </Row>
        );
    }
    return (
        <Row>
            <Col xs={5}>
                <div className="p-t-10 p-b-10">{rate.label}</div>
            </Col>
            <Col xs={5} className="p-t-5">
                <InputGroup
                    placeholder="qty"
                    fill
                    value={q}
                    onChange={(e) => {
                        if (isNaN(Number(e.target.value)) && e.target.value !== '') return;
                        setQ(e.target.value);
                    }}
                    rightElement={<Button style={{ color: '#000' }} minimal disabled text={`${rate.rate_measurement}s`} intent="primary" />}
                />
            </Col>
            {includeTotal && <Col className="text-right m-t-10">${(Number(rate.quantity) * Number(rate.rate)).toLocaleString()}</Col>}
            <Col className="text-right">
                <Button style={{ position: 'absolute', right: 10, top: 4 }} onClick={onRemove} intent="danger" minimal icon="trash" />
            </Col>
        </Row>
    );
};
