import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import InfoIcon from '@mui/icons-material/Info';
import SyncAlt from '@mui/icons-material/SyncAlt';
import { CTCJob } from 'src/api/models/CTC';
import { loadCTCJobsWithFilters, loadLinkedJobs } from 'src/api/ctc';
import { DivisionSelector } from './components/DivisionSelector';
import { Stack, LinearProgress } from '@mui/material';
import { Cell, Column, Table2, TableLoadingOption, ColumnHeaderCell } from '@blueprintjs/table';
import { Popover2 } from '@blueprintjs/popover2';
import { Col, Row } from 'react-grid-system';
import { Breadcrumbs } from '@blueprintjs/core';
import './index.scss';

const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0,
});

export const CTC: React.FC = () => {
    const [jobs, setJobs] = React.useState<CTCJob[]>([]);
    const [linkedJobs, setLinkedJobs] = React.useState<string[]>([]);
    const [divisions, setDivisions] = React.useState<string[]>([]);
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {
        loadJobs();
    }, [divisions]);

    const loadJobs = async () => {
        setLoading(true);
        try {
            const filters = { divisions };
            const codes = await loadCTCJobsWithFilters(filters);
            const mainJobs = await loadLinkedJobs();
            setLinkedJobs(mainJobs);
            setJobs(codes.jobs);
        } catch (err) {
            console.log('error loadings codes: ', err.toLocaleString());
        }
        setLoading(false);
    };

    const jobNumberRenderer = (rowIndex: number) => {
        return (
            <Cell>
                <Link to={`/ctc/view/${jobs[rowIndex].ws_job_number}`}>
                    {jobs[rowIndex].ws_job_number} - {jobs[rowIndex].ws_job_name}
                </Link>
            </Cell>
        );
    };

    const linkedRenderer = (rowIndex: number) => {
        const j = jobs[rowIndex];
        const jn = j.ws_job_number;
        const isLinked = linkedJobs.find((l) => l === jn);
        return <Cell>{isLinked && <SyncAlt fontSize="small" sx={{ color: '#058100' }} />}</Cell>;
    };

    const infoRenderer = (rowIndex: number) => {
        const j = jobs[rowIndex];
        return (
            <Cell>
                <Popover2
                    minimal
                    interactionKind="hover"
                    popoverClassName="width-500 p-15 bg-white"
                    enforceFocus={false}
                    position="right"
                    content={
                        <div style={{ width: 500 }}>
                            <p className="text-bold">
                                {jobs[rowIndex].ws_job_number} - {jobs[rowIndex].ws_job_name}
                            </p>
                            <p>Customer: {j.customer}</p>
                            <p>Division: {j.division}</p>
                            <p>Salesperson: {j.salesperson}</p>
                            <p>Certified Payroll: {j.certified_payroll}</p>
                            <p>OCIP: {j.ocip}</p>
                            <p>Liquidated Damages: {j.lds}</p>
                        </div>
                    }
                >
                    <InfoIcon fontSize="small" sx={{ color: '#B9B9B9FF' }} />
                </Popover2>
            </Cell>
        );
    };

    const columnHeaderCellRenderer = React.useCallback((title: string) => {
        return <ColumnHeaderCell name={title} style={{ backgroundColor: '#a3def8' }} />;
    }, []);

    const columnHeaderCellRendererGreen = React.useCallback((title: string) => {
        return <ColumnHeaderCell name={title} style={{ backgroundColor: '#64ffc0' }} />;
    }, []);

    const revEarned = (j: CTCJob) => {
        return j.contract_amount * (j.percent_complete / 100);
    };
    return (
        <>
            <Row className="m-b-5 m-t-20">
                <Col xs={2}>
                    <Breadcrumbs items={[{ text: 'CTC' }]} />
                </Col>
                <Col className="text-right">
                    <h4 className="m-0">
                        {jobs.length > 0 ? `Last Import Update: ${jobs[0] && jobs[0].created_at ? moment(jobs[0].created_at).format('MM/DD/YYYY h:mm a') : moment().format('MM/DD/YYYY')}` : ''}{' '}
                    </h4>
                </Col>
            </Row>
            <Row className="m-b-10">
                <Col>
                    <DivisionSelector onChange={setDivisions} initialValue={[]} />
                </Col>
            </Row>
            <Stack direction="row" className="m-b-10">
                <div>
                    <Stack direction="row">
                        <div className="table-key" style={{ backgroundColor: '#64ffc0' }} />
                        <span>Overall</span>
                    </Stack>
                </div>
                <div className="m-l-15">
                    <Stack direction="row">
                        <div className="table-key" style={{ backgroundColor: '#a3def8' }} />
                        <span>Current Month</span>
                    </Stack>
                </div>
            </Stack>
            <div className="ctc-job-table-container">
                {loading && <LinearProgress />}
                <Table2 numRows={jobs.length} loadingOptions={loading ? [TableLoadingOption.CELLS] : []} columnWidths={[40, 40, 325, 100, 100, 100, 100, 75, 75, 100, 100, 100, 100]}>
                    <Column className="text-center" name="Info" columnHeaderCellRenderer={() => columnHeaderCellRendererGreen('')} cellRenderer={linkedRenderer} />

                    <Column className="text-center" name="Info" columnHeaderCellRenderer={() => columnHeaderCellRendererGreen('')} cellRenderer={infoRenderer} />

                    <Column name="Job Number - Job Name" columnHeaderCellRenderer={() => columnHeaderCellRendererGreen('Job Number - Job Name')} cellRenderer={jobNumberRenderer} />
                    <Column
                        name="Contract Amt"
                        className="text-right cell-width"
                        columnHeaderCellRenderer={() => columnHeaderCellRendererGreen('Contract Amt')}
                        cellRenderer={(rowIndex: number) => (
                            <Cell>
                                <span className={jobs[rowIndex].contract_amount < 0 ? 'text-danger' : ''}>{formatter.format(jobs[rowIndex].contract_amount)}</span>
                            </Cell>
                        )}
                    />
                    <Column
                        name="Est Costs"
                        className="text-right"
                        columnHeaderCellRenderer={() => columnHeaderCellRendererGreen('Est Costs')}
                        cellRenderer={(rowIndex: number) => (
                            <Cell>
                                <span className={jobs[rowIndex].estimated_costs < 0 ? 'text-danger' : ''}>{formatter.format(jobs[rowIndex].estimated_costs)}</span>
                            </Cell>
                        )}
                    />
                    <Column
                        name="JTD Costs"
                        className="text-right"
                        columnHeaderCellRenderer={() => columnHeaderCellRendererGreen('JTD Costs')}
                        cellRenderer={(rowIndex: number) => (
                            <Cell>
                                <span className={jobs[rowIndex].cost_to_date < 0 ? 'text-danger' : ''}>{formatter.format(jobs[rowIndex].cost_to_date)}</span>
                            </Cell>
                        )}
                    />
                    <Column
                        name="Cost at Comp"
                        className="text-right"
                        columnHeaderCellRenderer={() => columnHeaderCellRendererGreen('C @ C')}
                        cellRenderer={(rowIndex: number) => {
                            const j = jobs[rowIndex];
                            const value = Number(j.cost_at_completion) + Number(j.adjustments_total);
                            return (
                                <Cell>
                                    <span className={value < 0 ? 'text-danger' : ''}>{formatter.format(value)}</span>
                                </Cell>
                            );
                        }}
                    />
                    <Column
                        name="% Comp"
                        className="text-right"
                        columnHeaderCellRenderer={() => columnHeaderCellRendererGreen('% Comp')}
                        cellRenderer={(rowIndex: number) => {
                            if (jobs[rowIndex].percent_complete > 1000) {
                                return (
                                    <Cell>
                                        <span>?</span>
                                    </Cell>
                                );
                            }
                            return (
                                <Cell>
                                    <span className={jobs[rowIndex].percent_complete < 0 ? 'text-danger' : ''}>{Math.round(jobs[rowIndex].percent_complete)}%</span>
                                </Cell>
                            );
                        }}
                    />
                    <Column
                        name="GM %"
                        className="text-right"
                        columnHeaderCellRenderer={() => columnHeaderCellRendererGreen('GM %')}
                        cellRenderer={(rowIndex: number) => {
                            const j = jobs[rowIndex];
                            //const top = j.cost_at_completion - j.contract_amount;
                            const top = j.contract_amount - j.cost_at_completion;
                            const value = top === 0 || j.contract_amount === 0 ? 0 : (top / j.contract_amount) * 100;
                            return (
                                <Cell>
                                    <span className={value < 0 ? 'text-danger' : ''}>{Math.round(value)}%</span>
                                </Cell>
                            );
                        }}
                    />
                    <Column
                        name="Rev Earned"
                        className="text-right"
                        columnHeaderCellRenderer={() => columnHeaderCellRendererGreen('Rev Earned')}
                        cellRenderer={(rowIndex: number) => {
                            const j = jobs[rowIndex];
                            const value = revEarned(j);
                            return (
                                <Cell>
                                    <span className={value < 0 ? 'text-danger' : ''}>{formatter.format(value)}</span>
                                </Cell>
                            );
                        }}
                    />
                    <Column
                        name="Rev Earned"
                        className="text-right"
                        columnHeaderCellRenderer={() => columnHeaderCellRenderer('Rev Earned')}
                        cellRenderer={(rowIndex) => {
                            const j = jobs[rowIndex];
                            const value = revEarned(j);
                            return (
                                <Cell>
                                    <span>{formatter.format(value - j.PriorEarnedTotal)}</span>
                                </Cell>
                            );
                        }}
                    />
                    <Column
                        name="Cost"
                        className="text-right"
                        columnHeaderCellRenderer={() => columnHeaderCellRenderer('Cost')}
                        cellRenderer={(rowIndex: number) => {
                            const j = jobs[rowIndex];
                            const value = j.mtd_cost;
                            return (
                                <Cell>
                                    <span className={value < 0 ? 'text-danger' : ''}>{formatter.format(value)}</span>
                                </Cell>
                            );
                        }}
                    />
                    <Column
                        name="Margin"
                        className="text-right"
                        columnHeaderCellRenderer={() => columnHeaderCellRenderer('Margin')}
                        cellRenderer={(rowIndex: number) => {
                            const j = jobs[rowIndex];
                            const cost = j.mtd_cost;
                            const rev = revEarned(j) - j.PriorEarnedTotal;
                            return (
                                <Cell>
                                    <span>{formatter.format(cost - rev)}</span>
                                </Cell>
                            );
                        }}
                    />
                </Table2>
            </div>
        </>
    );
};
