import React from 'react';
import moment from 'moment';
import { Col, Row } from 'react-grid-system';
import { Button, InputGroup, Spinner, FormGroup, Card } from '@blueprintjs/core';
import { useJobs } from '../../../../hooks/useJobs';
import { useHistory, useParams } from 'react-router-dom';
import { TimesheetCode, Timesheet } from '../../../../api/models/timesheet';
import { getTimesheetById, updateTimesheet } from '../../../../api/timesheets';
import { TimesheetPanel } from '../EditTimesheetDraft/components/TimesheetPanel';

import { AppToast } from '../../../../components/Toasts/AppToast';
import { Header2 } from '../../../../components/Header2';

export const ViewTimesheet: React.FC = () => {
    const { selectedJob } = useJobs();
    const history = useHistory();
    const params: any = useParams();
    const { timesheetId } = params;
    const [saving, setSaving] = React.useState(false);
    // const [submitting, setSubmitting] = React.useState(false);
    const [error, setError] = React.useState<string | null>(null);
    const [loading, setLoading] = React.useState(false);
    const [timesheet, setTimesheet] = React.useState<Timesheet | null>(null);
    const [changes, setChanges] = React.useState({});

    React.useEffect(() => {
        loadTimesheet(timesheetId);
    }, [timesheetId]);

    const loadTimesheet = async (timesheetId: string) => {
        if (!selectedJob) return;
        setLoading(true);
        setError(null);
        try {
            const data = await getTimesheetById(selectedJob.id, timesheetId);
            const ts = {
                ...data,
                rows: data.rows.filter((row) => row.total !== '' && row.total !== '0'),
            };
            setTimesheet(ts);
            setChanges({ [timesheetId]: ts.rows });
        } catch (err) {
            setError(err.toLocaleString());
        }
        setLoading(false);
    };

    const onChange = (timesheetId: string, rows: TimesheetCode[]) => {
        setChanges((prevState) => {
            return {
                ...prevState,
                [timesheetId]: rows,
            };
        });
    };

    const saveAllChanges = async () => {
        if (!selectedJob) return;
        setSaving(true);
        setError(null);
        try {
            await updateTimesheet(selectedJob.id, timesheetId, changes);
            AppToast.show({ message: `Timesheets updated!`, intent: 'success', icon: 'tick' });
        } catch (err) {
            setError(err.toLocaleString());
        }
        setSaving(false);
    };

    // const submitForReview = async () => {
    //     if (!selectedJob) return;
    //     setSubmitting(true);
    //     setError(null);
    //     try {
    //         await submitDraftForReview(selectedJob.id, draftId);
    //         AppToast.show({ message: `Timesheets submitted for review!`, intent: 'success', icon: 'tick' });
    //         history.push(`/jobs/${selectedJob.id}/timesheets`);
    //     } catch (err) {
    //         setError(err.toLocaleString());
    //     }
    //     setSubmitting(false);
    // };
    if (!selectedJob) return null;
    if (loading) {
        return (
            <div className="m-t-50 text-center">
                <Spinner />
            </div>
        );
    }

    return (
        <>
            <Header2
                breadcrumbs={[
                    { title: 'Jobs', link: '/jobs' },
                    { title: `${selectedJob?.name} - ${selectedJob?.job_number}`, link: `/jobs/${selectedJob?.id}/summary` },
                    { title: 'Timesheets', link: `/jobs/${selectedJob.id}/timesheets` },
                    { title: timesheet ? `${moment(timesheet.start).format('MM/DD/YYYY')} - ${moment(timesheet.start).add(6, 'days').format('MM/DD/YYYY')}` : '', link: '' },
                ]}
                actionComponent={
                    <>
                        <Button onClick={() => history.push(`/jobs/${selectedJob?.id}/timesheets`)}>Cancel</Button>
                    </>
                }
            />
            {error && <p className="text-center">{error}</p>}
            {timesheet && (
                <>
                    <Card className="m-b-15">
                        <Row>
                            <Col xs={6}>
                                <FormGroup label="Created By">
                                    <InputGroup value={`${timesheet.created_by.full_name} on ${moment(timesheet.created_at).format('MM/DD/YYYY')}`} disabled />
                                </FormGroup>
                            </Col>
                            <Col xs={6}>
                                <FormGroup label="Status">
                                    <InputGroup style={{ textTransform: 'capitalize' }} value={timesheet.status} disabled />
                                </FormGroup>
                            </Col>
                        </Row>
                    </Card>
                    <TimesheetPanel
                        disabled
                        draftId={timesheet.timesheet_draft_id}
                        timesheetId={timesheet.id}
                        costCodes={timesheet.rows}
                        startDate={timesheet.start}
                        person={timesheet.employee}
                        onChange={onChange}
                    />

                    <Card>
                        <Row className="m-t-10 m-b-15">
                            <Col className="text-center">
                                <Button onClick={() => history.push(`/jobs/${selectedJob?.id}/timesheets`)}>Cancel</Button>{' '}
                                <Button intent="primary" onClick={saveAllChanges} loading={saving}>
                                    Approve Timesheet
                                </Button>
                            </Col>
                        </Row>
                    </Card>
                </>
            )}
        </>
    );
};
