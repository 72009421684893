import React from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Filter1Icon from '@mui/icons-material/Filter1';
import ListItemText from '@mui/material/ListItemText';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/Delete';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import { AddLevelButton } from './AddLevelButton';
import { Level2Item } from './Level2Item';
import { WBSLevel1Children } from 'src/api/models/WBS';

interface Level1Props {
    label: string;
    id: string;
    subs: WBSLevel1Children[];
}

export const Level1Item: React.FC<Level1Props> = ({ label, subs, id }) => {
    const [open, setOpen] = React.useState(false);
    const [data, setData] = React.useState<WBSLevel1Children[]>(subs);

    const onLevel2Add = (data: WBSLevel1Children) => {
        setData((prevState) => {
            return [...prevState, data];
        });
    };
    const handleClick = () => {
        setOpen(!open);
    };
    const renderLevel2 = () => {
        return data.map((level2) => {
            return <Level2Item key={level2.id} label={level2.name} subs={level2.subs} id={level2.id} />;
        });
    };
    return (
        <>
            <ListItem>
                <ListItemIcon>
                    <Filter1Icon />
                </ListItemIcon>
                <ListItemText primary={label} />
                <IconButton onClick={handleClick} edge="start" aria-label="delete" style={{ marginRight: 20 }}>
                    <DeleteIcon />
                </IconButton>
                <IconButton onClick={handleClick} edge="end" aria-label="delete">
                    {open ? <ExpandLess /> : <ExpandMore />}
                </IconButton>
            </ListItem>
            <Divider />
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {renderLevel2()}

                    <AddLevelButton level="2" onLevel2Add={onLevel2Add} parentId={id} />
                </List>
            </Collapse>
        </>
    );
};
