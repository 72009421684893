import React from 'react';

import { Header } from 'src/components/Header';
import { useJobs } from 'src/hooks/useJobs';
import { JobTable } from './components/JobTable';
export const Jobs: React.FC = () => {
    const { jobs, fetchJobs, loading } = useJobs();

    React.useEffect(() => {
        fetchJobs();
    }, []);

    return (
        <>
            <Header breadcrumbs={['Jobs']} title="Jobs" />
            {/*{loading ? <Spinner /> : <>{renderJobs()}</>}*/}
            <JobTable jobs={jobs} loading={loading} />
        </>
    );
};
