import React from 'react';

import { useDropzone } from 'react-dropzone';
import { IAttachment } from 'src/api/models/Attachment';
import { Icon, Spinner } from '@blueprintjs/core';
import { download } from 'src/api/attachments';
import './index.scss';
import { Image } from './Image';
import { uploadTmpImage } from '../../api/tickets';

interface IImageUploadProps {
    onFilesAdded: (attachments: IAttachment[]) => void;
    onFilesRemoved: (attachment: IAttachment[]) => void;
    filesToRemove?: IAttachment[];
    existingFiles: IAttachment[];
    revisions?: IAttachment[];
    showRevisions?: boolean;
    disabled?: boolean;
    maxFiles?: number;
}

export const ImageUpload: React.FC<IImageUploadProps> = ({ onFilesAdded, filesToRemove = [], revisions = [], showRevisions = false, onFilesRemoved, maxFiles = 100, existingFiles, disabled }) => {
    const [removed, setRemoved] = React.useState<IAttachment[]>([]);
    const [attachments, setAttachments] = React.useState<IAttachment[]>(existingFiles);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState<string | null>(null);

    const onDrop = React.useCallback(async (acceptedFiles) => {
        setLoading(true);
        setError(null);
        try {
            // console.log('accepted: ', acceptedFiles);
            const result = await uploadTmpImage(acceptedFiles[0]);
            // console.log('result: ', result);
            setAttachments((prevState) => [...prevState, result]);
        } catch (err) {
            setError(err.toString());
        }

        setLoading(false);
    }, []);

    React.useEffect(() => {
        if (attachments !== existingFiles) {
            onFilesAdded(attachments);
        }
    }, [attachments]);

    React.useEffect(() => {
        onFilesRemoved(removed);
    }, [removed]);

    const { getRootProps, getInputProps, isDragActive, open } = useDropzone({ onDrop, noClick: true, noKeyboard: true, maxFiles: 1, accept: ['image/jpeg', 'image/png'] });

    const downloadFile = async (id: string) => {
        await download(id);
    };

    const removeAttachment = (attachment: IAttachment) => {
        setRemoved((prevState) => [...prevState, attachment]);
        setAttachments((prevState) => prevState.filter((a) => a.id !== attachment.id));
    };

    const renderImages = () => {
        return attachments.map((att) => {
            return <Image key={att.id} id={att.id} onRemoveClick={() => removeAttachment(att)} onImageClick={() => downloadFile(att.id)} />;
        });
    };

    return (
        <>
            {renderImages()}
            <div className={`file-drop-image ${isDragActive && 'dragging'}`} onClick={open}>
                {!disabled && attachments.length < maxFiles && (
                    <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <div className={`file-add-image text-center m-b-0 `}>
                            {loading ? (
                                <Spinner className="m-b-15 m-t-5" size={38} />
                            ) : (
                                <div>
                                    <Icon icon="plus" size={32} className="m-t-5 m-l-5" />
                                </div>
                            )}
                        </div>
                        {error && <div>{error}</div>}
                    </div>
                )}
            </div>
        </>
    );
};
