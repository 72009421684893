import React from 'react';
import { AiOutlineFileExcel, AiOutlineFilePdf, AiOutlineFileUnknown, AiOutlineFileImage, AiOutlineFileWord } from 'react-icons/ai';
import { GrDocumentCsv } from 'react-icons/gr';
import { useDropzone } from 'react-dropzone';
import { Row, Col } from 'react-grid-system';
import { Button, Label } from '@blueprintjs/core';
import { uploadCTCFile } from 'src/api/attachments';
import { useAuth } from 'src/hooks/useAuth';
import './index.scss';

interface IAttachmentsProps {
    onFilesAdded: () => void;
}

const IconLookup: any = {
    'text/csv': <GrDocumentCsv size={16} />,
    pdf: <AiOutlineFilePdf size={18} />,
    xlsx: <AiOutlineFileExcel size={18} />,
    cfb: <AiOutlineFileExcel size={18} />,
    xls: <AiOutlineFileExcel size={18} />,
    png: <AiOutlineFileImage size={18} />,
    jpg: <AiOutlineFileImage size={18} />,
    jpeg: <AiOutlineFileImage size={18} />,
    docx: <AiOutlineFileWord size={18} />,
};

interface CTCFile {
    JobCostCostCodeSummary: File | null;
    JobCostDashPOReport: File | null;
    JobCostDetails: File | null;
}
export const CTCAttachments: React.FC<IAttachmentsProps> = ({ onFilesAdded }) => {
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState<string | null>(null);
    const { user } = useAuth();
    const [tmpFiles, setTmpFiles] = React.useState<CTCFile>({
        JobCostCostCodeSummary: null,
        JobCostDashPOReport: null,
        JobCostDetails: null,
    });

    const onDrop = React.useCallback(async (acceptedFiles: File[]) => {
        setLoading(true);
        setError(null);
        try {
            //const result = await uploadCTCFile(acceptedFiles);
            const JobCostCostCodeSummary = acceptedFiles.find((file) => file.name === 'JobCostCostCodeSummary.csv') || null;
            const JobCostDashPOReport = acceptedFiles.find((file) => file.name === 'JobCostDashPOReport.csv') || null;
            const JobCostDetails = acceptedFiles.find((file) => file.name === 'JobCostDetails.csv') || null;

            const result: any = {};
            if (JobCostCostCodeSummary) {
                result.JobCostCostCodeSummary = JobCostCostCodeSummary;
            }
            if (JobCostDashPOReport) {
                result.JobCostDashPOReport = JobCostDashPOReport;
            }
            if (JobCostDetails) {
                result.JobCostDetails = JobCostDetails;
            }
            setTmpFiles((prevState) => {
                return {
                    ...prevState,
                    ...result,
                };
            });
            //setAttachments((prevState) => [...prevState, result]);
        } catch (err) {
            setError(err.toString());
        }

        setLoading(false);
    }, []);

    const { getRootProps, getInputProps, open } = useDropzone({ onDrop, noClick: true, noKeyboard: true, maxFiles: 3, accept: ['text/csv'] });

    const uploadFiles = async () => {
        const files: File[] = [];
        Object.keys(tmpFiles).forEach((key) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const f: File = tmpFiles[key];
            if (f) {
                files.push(f);
            }
        });
        try {
            setLoading(true);
            await uploadCTCFile(files);
            onFilesAdded();
            setTmpFiles({
                JobCostCostCodeSummary: null,
                JobCostDashPOReport: null,
                JobCostDetails: null,
            });
            setLoading(false);
        } catch (err) {
            setError(err.toLocaleString());
            setLoading(false);
        }
    };

    const removeAttachment = (fileName: 'JobCostCostCodeSummary' | 'JobCostDashPOReport' | 'JobCostDetails') => {
        setTmpFiles((prevState) => {
            return {
                ...prevState,
                [fileName]: null,
            };
        });
    };

    const AttachmentTable = (
        <>
            {(tmpFiles.JobCostCostCodeSummary || tmpFiles.JobCostDetails || tmpFiles.JobCostDashPOReport) && (
                <table className={`bp3-html-table bp3-html-table-striped`} style={{ width: '100%' }}>
                    <thead>
                        <tr>
                            <th style={{ width: 18 }} />
                            <th>Name</th>
                            <th>Size</th>
                            <th />
                        </tr>
                    </thead>
                    <tbody>
                        {tmpFiles.JobCostCostCodeSummary && (
                            <tr>
                                <td>{IconLookup[tmpFiles.JobCostCostCodeSummary.type] || <AiOutlineFileUnknown size={18} />}</td>
                                <td>{tmpFiles.JobCostCostCodeSummary.name}</td>
                                <td>{Math.round(tmpFiles.JobCostCostCodeSummary.size / 1000)} KB</td>
                                <td className="p-t-8 p-b-0">{user && <Button icon="trash" minimal small onClick={() => removeAttachment('JobCostCostCodeSummary')} />}</td>
                            </tr>
                        )}
                        {tmpFiles.JobCostDetails && (
                            <tr>
                                <td>{IconLookup[tmpFiles.JobCostDetails.type] || <AiOutlineFileUnknown size={18} />}</td>
                                <td>{tmpFiles.JobCostDetails.name}</td>
                                <td>{Math.round(tmpFiles.JobCostDetails.size / 1000)} KB</td>
                                <td className="p-t-8 p-b-0">{user && <Button icon="trash" minimal small onClick={() => removeAttachment('JobCostDetails')} />}</td>
                            </tr>
                        )}
                        {tmpFiles.JobCostDashPOReport && (
                            <tr>
                                <td>{IconLookup[tmpFiles.JobCostDashPOReport.type] || <AiOutlineFileUnknown size={18} />}</td>
                                <td>{tmpFiles.JobCostDashPOReport.name}</td>
                                <td>{Math.round(tmpFiles.JobCostDashPOReport.size / 1000)} KB</td>
                                <td className="p-t-8 p-b-0">{user && <Button icon="trash" minimal small onClick={() => removeAttachment('JobCostDashPOReport')} />}</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            )}
        </>
    );
    return (
        <div>
            <Row>
                <Col>
                    <Label className="m-b-0 text-bold">Upload CTC Files</Label>
                </Col>
                <Col className="text-right">
                    <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <Button icon="plus" small minimal onClick={open} loading={loading} />
                    </div>
                </Col>
            </Row>
            <hr className="m-t-3" />
            <Row>
                <Col>{AttachmentTable}</Col>
            </Row>
            {tmpFiles.JobCostCostCodeSummary && tmpFiles.JobCostDetails && tmpFiles.JobCostDashPOReport ? (
                <Row>
                    <Col>{loading && <p className="m-t-15">Upload in progress. This may take a couple minutes. </p>}</Col>
                    <Col className="text-right" xs={4}>
                        <Button className="m-t-10" onClick={uploadFiles} loading={loading} intent="primary">
                            Upload Files
                        </Button>
                    </Col>
                </Row>
            ) : (
                <Row className="m-t-10">
                    <Col>
                        <p>
                            <b>Select the following CTC files to continue:</b>
                        </p>
                        <p>
                            <span style={{ fontStyle: 'italic' }}>JobCostCostCodeSummary.csv, JobCostDetails.csv, JobCostDashPOReport.csv</span>
                        </p>
                    </Col>
                </Row>
            )}

            {error && <p>{error}</p>}
        </div>
    );
};
