import * as React from 'react';
import { Icon } from '@blueprintjs/core';
import { IDocument } from '../../../../../api/models/Document';

import Tree, { TreeNode } from 'rc-tree';
interface IDocumentTreeProps {
    selectedFolderId: any;
    folders: IDocument[];
    onFolderSelected: (docId: string, path: number[]) => void;
}
export const DocumentTree: React.FC<IDocumentTreeProps> = ({ folders, selectedFolderId, onFolderSelected }) => {
    const onSelect = (keys: any[], data: any) => {
        const path = data.node.pos.split('-').slice(2);
        onFolderSelected(keys[0], path);
    };
    const renderChildren = (children: IDocument[]) => {
        return children.map((child) => {
            const isPrivateAndEmail = (child.access === 'people' || child.access === 'only_you') && child.folder_type === 'emails';
            return (
                <TreeNode
                    title={
                        <>
                            {isPrivateAndEmail ? (
                                <span className="m-l--15 m-t-4">
                                    <Icon icon="envelope" iconSize={8} color="grey" />
                                    <Icon icon="lock" iconSize={8} color="grey" />
                                </span>
                            ) : (
                                <>
                                    {child.is_locked ? (
                                        <Icon icon="lightning" iconSize={8} color="grey" className={`m-l--10 m-t-2`} />
                                    ) : (
                                        <>
                                            {(child.access === 'people' || child.access === 'only_you') && <Icon icon="lock" iconSize={8} color="grey" className={`m-l--10 m-t-2`} />}
                                            {child.folder_type === 'emails' && <Icon icon="envelope" iconSize={8} color="grey" className="m-l--10 m-t-2" />}
                                        </>
                                    )}
                                </>
                            )}

                            <span className="m-l-5">
                                {child.name} {child.documents.length !== 0 ? `(${child.documents.length})` : ''}
                            </span>
                        </>
                    }
                    key={child.id}
                    selectable={true}
                    icon={<span className="rc-tree-iconEle rc-tree-icon__open m-l-5" />}
                >
                    {renderChildren(child.children)}
                </TreeNode>
            );
        });
    };
    return (
        <>
            <Tree defaultExpandAll autoExpandParent showLine onSelect={onSelect} selectedKeys={[selectedFolderId]}>
                <TreeNode title={`Documents`} key="documents" selectable={true} icon={<span className="rc-tree-iconEle rc-tree-icon__open m-l-5" />}>
                    {renderChildren(folders)}
                </TreeNode>
            </Tree>
        </>
    );
};
