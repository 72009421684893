import React from 'react';
import { IJob } from 'src/api/models/Job';
import { MenuItem, Button } from '@blueprintjs/core';
import { Select, ItemRenderer, ItemPredicate } from '@blueprintjs/select';
import { useHistory } from 'react-router-dom';
import { useJobs } from 'src/hooks/useJobs';

const JobSelect = Select.ofType<IJob>();

interface IJobSelectorProps {
    onChange?: (job: IJob) => void;
}

export const JobSelector: React.FC<IJobSelectorProps> = ({ onChange }) => {
    const history = useHistory();
    const { selectedJob, jobs, fetchJobs } = useJobs();
    React.useEffect(() => {
        fetchJobs();
    }, []);
    const onValueChange = (value: IJob) => {
        if (onChange) {
            onChange(value);
        }
        history.push(`/jobs/${value.job_number}/summary`);
    };
    const filterProjects: ItemPredicate<IJob> = (query, project, _index, exactMatch) => {
        const normalizedTitle = project.name.toLowerCase();
        const normalizedQuery = query.toLowerCase();
        if (exactMatch) {
            return normalizedTitle === normalizedQuery;
        } else {
            return `${project.name}. ${normalizedTitle} ${project.description}`.indexOf(normalizedQuery) >= 0;
        }
    };

    const renderItem: ItemRenderer<IJob> = (project, { handleClick }) => {
        return <MenuItem text={project.name} key={project.id} onClick={handleClick} />;
    };
    return (
        <JobSelect itemPredicate={filterProjects} fill itemRenderer={renderItem} items={jobs} noResults={<MenuItem disabled={true} text="No results." />} onItemSelect={onValueChange}>
            <Button text={selectedJob ? selectedJob.name : 'Quick Navigation'} rightIcon="caret-down" />
        </JobSelect>
    );
};
