import React from 'react';
import { useQuery } from 'react-query';
import { Row, Col } from 'react-grid-system';
import { loadAllRfis } from 'src/api/rfis';
import { Header2 } from 'src/components/Header2';
import { Card, Typography } from '@mui/material';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { Link } from 'react-router-dom';
import { IJobWithRFIs } from '../../api/models/Job';
import './index.scss';
import moment from 'moment';

const columns: GridColDef[] = [
    {
        field: 'name',
        headerName: 'Job Name',
        flex: 2,
        renderCell: (params: GridValueGetterParams) => {
            return (
                <Link to={`/jobs/${params.row.job_number}/rfis`}>
                    {params.row.job_number} - {params.row.name}
                </Link>
            );
        },
    },
    {
        field: 'open_count',
        headerName: 'Open',
        flex: 1,
        valueGetter: (params: GridValueGetterParams) => {
            return params.row.open_count;
        },
        renderCell: (params: GridValueGetterParams) => {
            return <a>{params.row.open_count}</a>;
        },
    },
    {
        field: 'all_count',
        headerName: 'All',
        flex: 1,
        valueGetter: (params: GridValueGetterParams) => {
            return params.row.all_count;
        },
        renderCell: (params: GridValueGetterParams) => {
            return <a>{params.row.all_count}</a>;
        },
    },
    {
        field: 'past_due',
        headerName: 'Past Due',
        flex: 1,
        valueGetter: (params: GridValueGetterParams) => {
            return params.row.past_due_count;
        },
        renderCell: (params: GridValueGetterParams) => {
            return <a>{params.row.past_due_count}</a>;
        },
    },
];

const rfiColumns: GridColDef[] = [
    {
        field: 'name',
        headerName: 'RFI #',
        flex: 1,
        renderCell: (params: GridValueGetterParams) => {
            return <Link to={`/jobs/${params.row.job_id}/rfis/${params.row.id}`}>{params.row.rfi_number}</Link>;
        },
    },
    {
        field: 'need_date',
        headerName: 'Need Date',
        flex: 1,
        valueGetter: (params: GridValueGetterParams) => {
            return moment(params.row.need_date).format('MM/DD/YYYY');
        },
    },
    {
        field: 'status',
        headerName: 'Status',
        flex: 1,
        valueGetter: (params: GridValueGetterParams) => {
            return params.row.status;
        },
    },
];

interface JobWithCounts extends IJobWithRFIs {
    open_count: number;
    all_count: number;
    past_due_count: number;
}

interface SelectedJob {
    job: JobWithCounts;
    type: 'open_count' | 'all_count' | 'past_due';
}
export const RFIs: React.FC = () => {
    const [rfis, setRfis] = React.useState<JobWithCounts[]>([]);
    const { data: jobs, isLoading, error } = useQuery<IJobWithRFIs[]>(['loadAllRFIS'], () => loadAllRfis());
    const [selectedJob, setSelectedJob] = React.useState<SelectedJob | null>(null);

    React.useEffect(() => {
        if (!jobs) return;
        const enhanced: JobWithCounts[] = jobs.map((job) => {
            let open_count = 0;
            let past_due_count = 0;
            const today = moment();
            job.rfis.forEach((rfi) => {
                console.log('rfis: ', rfi);
                if (rfi.workflow && rfi.workflow.statuses) {
                    const is_open = rfi.step_index !== rfi.workflow.statuses.length - 1;
                    if (is_open) {
                        console.log('isOpen: ', is_open);
                        open_count += 1;
                        if (today.isAfter(moment(rfi.need_date))) {
                            past_due_count += 1;
                        }
                    }
                }
            });
            return {
                ...job,
                open_count,
                all_count: job.rfis.length,
                past_due_count,
            };
        });
        setRfis(enhanced);
    }, [jobs]);

    const getRfiRows = () => {
        if (!selectedJob) return [];
        if (selectedJob.type === 'all_count') {
            return selectedJob.job.rfis;
        }
        if (selectedJob.type === 'past_due') {
            const today = moment();
            return selectedJob.job.rfis.filter((rfi) => {
                let isComplete = false;
                if (rfi.workflow && rfi.workflow.statuses) {
                    isComplete = rfi.step_index === rfi.workflow.statuses.length - 1;
                }
                return !isComplete && today.isAfter(moment(rfi.need_date));
            });
        }
        if (selectedJob.type === 'open_count') {
            return selectedJob.job.rfis.filter((rfi) => {
                if (rfi.workflow && rfi.workflow.statuses) {
                    return rfi.step_index !== rfi.workflow.statuses.length - 1;
                }
                return false;
            });
        }
        return [];
    };
    return (
        <>
            <Header2 breadcrumbs={[{ title: 'RFIs', link: '' }]} />
            {error && <p className="text-center text-danger">Error Loading RFIS</p>}
            <Row>
                <Col>
                    <div className="rfi-job-table-container">
                        <DataGrid
                            onCellClick={(cell) => {
                                if (cell.field === 'all_count' || cell.field === 'open_count' || cell.field === 'past_due') {
                                    setSelectedJob({
                                        job: cell.row,
                                        type: cell.field,
                                    });
                                }
                            }}
                            // disableSelectionOnClick
                            loading={isLoading}
                            getRowClassName={(row) => {
                                if (!selectedJob) return '';
                                if (row.row.id === selectedJob.job.id) return 'selected-job-row';
                                return '';
                            }}
                            disableSelectionOnClick
                            disableColumnSelector
                            rows={rfis}
                            columns={columns}
                            pageSize={20}
                            rowsPerPageOptions={[20]}
                        />
                    </div>
                </Col>
                {selectedJob && (
                    <Col xs={5} className="p-0">
                        <Card square>
                            <Typography className="m-20 m-b-10">
                                {selectedJob.job.name} - {selectedJob.type === 'open_count' && 'Open RFIs'} {selectedJob.type === 'all_count' && 'All RFIs'}{' '}
                                {selectedJob.type === 'past_due' && 'Past Due RFIs'}
                            </Typography>
                        </Card>
                        <div className="rfi-job-details-container">
                            <DataGrid disableSelectionOnClick disableColumnSelector rows={getRfiRows()} columns={rfiColumns} pageSize={20} rowsPerPageOptions={[20]} />
                        </div>
                    </Col>
                )}
            </Row>
        </>
    );
};
