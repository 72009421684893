import React from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ClosedCaptionOffIcon from '@mui/icons-material/ClosedCaptionOff';
import ListItemText from '@mui/material/ListItemText';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';

interface Level3Props {
    label: string;
}

export const Level3Item: React.FC<Level3Props> = ({ label }) => {
    return (
        <>
            <ListItem sx={{ ml: 20 }}>
                <ListItemIcon>
                    <ClosedCaptionOffIcon />
                </ListItemIcon>
                <ListItemText primary={label} />
                <IconButton onClick={console.log} edge="start" aria-label="delete" style={{ marginRight: 209 }}>
                    <DeleteIcon />
                </IconButton>
            </ListItem>
            <Divider sx={{ ml: 20 }} />
        </>
    );
};
