import React from 'react';
import { useJobs } from 'src/hooks/useJobs';
import { useHistory, useLocation, Link } from 'react-router-dom';
import { Callout, Spinner, Tag } from '@blueprintjs/core';
import { loadJobRfis } from 'src/api/rfis';
import { IRFI } from 'src/api/models/RFI';
import { AuthorizedButton } from 'src/components/AuthorizedButton';
import { FiretrolTable } from 'src/components/FiretrolTable';
import { TableColumn } from 'react-data-table-component';
import { Header2 } from '../../../components/Header2';

const RFI_COLUMNS: TableColumn<any>[] = [
    {
        name: 'RFI Number',
        selector: (rfi: IRFI) => rfi.rfi_number,
        format: (rfi: IRFI) => <Link to={`/jobs/${rfi.job_id}/rfis/${rfi.id}`}>{rfi.rfi_number} </Link>,
        sortable: true,
        width: `200px`,
    },
    {
        name: 'Status',
        selector: (rfi: IRFI) => rfi.status,
        format: (rfi: IRFI) => <Tag>{rfi.status} </Tag>,
        sortable: true,
        width: `200px`,
    },
    {
        name: 'Impact',
        selector: (rfi: IRFI) => rfi.impact,
        format: (rfi: IRFI) => rfi.impact.toUpperCase(),
        sortable: true,
        width: `200px`,
    },
    {
        name: 'From',
        selector: (rfi: IRFI) => rfi.from_user?.full_name || '',
        format: (rfi: IRFI) => rfi.from_user?.full_name,
        sortable: true,
        width: `200px`,
    },
    {
        name: 'Reporter',
        selector: (rfi: IRFI) => rfi.reporter?.full_name || '',
        format: (rfi: IRFI) => rfi.reporter?.full_name,
        sortable: true,
        width: `200px`,
    },
];

interface IPermissionState {
    allowedRoles: number[];
    allowedUsers: number[];
    blockedUsers: number[];
}
export const RFIs: React.FC = () => {
    const { selectedJob, rehydrateJob } = useJobs();
    const [error, setError] = React.useState<string | null>(null);
    const [loading, setLoading] = React.useState(false);
    const [rfis, setRfis] = React.useState<IRFI[]>([]);
    const [permissions, setPermissions] = React.useState<IPermissionState>({
        allowedRoles: [],
        allowedUsers: [],
        blockedUsers: [],
    });
    const history = useHistory();
    const location = useLocation();

    React.useEffect(() => {
        rehydrateJob();
        fetchRfis();
    }, []);

    const fetchRfis = async () => {
        if (!selectedJob) return;
        setLoading(true);
        setError(null);
        try {
            const data = await loadJobRfis(selectedJob.id);
            setRfis(data);
        } catch (err) {
            setError(err.toLocaleString());
        }
        setLoading(false);
    };

    React.useEffect(() => {
        if (!selectedJob || (selectedJob && !selectedJob.rfi_workflow)) {
            return setPermissions({
                allowedRoles: [],
                allowedUsers: [],
                blockedUsers: [],
            });
        }
        const allowedRoles = selectedJob.rfi_workflow.authorized_roles.map((r) => (r.organization_role_id ? r.organization_role_id : -1));
        const allowedUsers = selectedJob.rfi_workflow.authorized_users.map((r) => r.user_id);
        const blockedUsers = selectedJob.rfi_workflow.excluded_users.map((r) => r.user_id);
        setPermissions({ allowedRoles, allowedUsers, blockedUsers });
    }, [selectedJob?.rfi_workflow]);

    if (!selectedJob) return null;

    const createButton = () => {
        if (!selectedJob || !selectedJob.rfi_workflow) return null;
        return (
            <AuthorizedButton
                allowedRoles={permissions.allowedRoles}
                allowedUsers={permissions.allowedUsers}
                blockedUsers={permissions.blockedUsers}
                text={selectedJob.rfi_workflow.create_button_text}
                onClick={() => history.push(`${location.pathname}/submit/rfi`)}
                intent={selectedJob.rfi_workflow.create_button_color}
                icon="add"
            />
        );
    };
    return (
        <div>
            <Header2
                breadcrumbs={[
                    { title: 'Jobs', link: '/jobs' },
                    { title: `${selectedJob?.name} - ${selectedJob?.job_number}`, link: `/jobs/${selectedJob?.id}/summary` },
                    { title: 'RFIs', link: '' },
                ]}
                actionComponent={<>{createButton()}</>}
            />
            {!selectedJob.rfi_workflow_id && (
                <Callout className="m-b-20" title="Missing RFI Workflow" intent="danger">
                    Default workflow does not exist. Please contact an administrator.
                </Callout>
            )}
            {error && <p>{error}</p>}
            {loading ? <Spinner className="m-t-50" /> : <FiretrolTable paginationPerPage={10} columns={RFI_COLUMNS} data={rfis} />}
        </div>
    );
};
