import AppApi from './index';
import { IRFI } from './models/RFI';
const api = AppApi.getApi();

export const loadExternalRFI = async (id: string): Promise<IRFI> => {
    const url = `/external/rfis/${id}`;
    const result = await api.get(url);
    return result.data;
};

export const saveExternalRfi = async (data: IRFI): Promise<IRFI> => {
    const url = `/external/rfis/${data.id}`;
    const result = await api.post(url, data);
    return result.data;
};
