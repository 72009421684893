import React from 'react';
import moment from 'moment';

import PublicIcon from '@mui/icons-material/Public';
import SecurityIcon from '@mui/icons-material/Security';
import { Card, CardContent, Avatar, Box, Typography, CardHeader, Tabs, Stack, Tooltip, Tab, ImageList, Badge, Chip, IconButton } from '@mui/material';
import { PostResponse } from 'src/api/models/Post';
import RestartAltIcon from '@mui/icons-material/RestartAlt';

import { MentionInput } from '../PostCard/components/MentionInput';
import { PostLinks } from '../PostCard/components/PostLinks';
import { Image } from '../PostCard/components/PostFiles/Image';
import { download } from '../../../../../api/attachments';
import { resolvePost } from 'src/api/posts';
import { humanFileSize, IconLookup24 } from '../../../Documents/utils';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { IDocument } from '../../../../../api/models/Document';
import { downloadDocument } from '../../../../../api/documents';
import { PostComments } from './components/PostComments';
import { PostMenu } from './components/PostMenu';
import { useIntersection } from 'src/hooks/useIntersection';
import { useAuth } from '../../../../../hooks/useAuth';
import { forceNotificationRead } from 'src/api/user';
import { TbBarrierBlock } from 'react-icons/tb';
import NearbyErrorIcon from '@mui/icons-material/NearbyError';
import MoreTimeIcon from '@mui/icons-material/MoreTime';
import { AppToast } from '../../../../../components/Toasts/AppToast';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

// const bull = (
//     <Box component="span" sx={{ display: 'inline-block', mx: '5px', transform: 'scale(0.8)' }}>
//         •
//     </Box>
// );

interface PostItemProps {
    item: PostResponse;
    refreshPosts: () => void;
    openEditDialog: (item: PostResponse) => void;
    displayOnly?: boolean;
}

const acceptedImageTypes = ['gif', 'jpeg', 'png', 'jpg'];

export const PostItem: React.FC<PostItemProps> = ({ item, refreshPosts, openEditDialog, displayOnly = false }) => {
    const [tab, setTab] = React.useState(0);
    const [resolved, setResolved] = React.useState(item.resolved);
    const [read, setRead] = React.useState(false);
    const [shouldPoll, setShouldPoll] = React.useState(false);
    const ref = React.useRef<any>(null);
    const isVisible = useIntersection(ref, '50px');
    const { user } = useAuth();

    React.useEffect(() => {
        setShouldPoll(isVisible);
        if (isVisible && !read) {
            markRead();
        }
    }, [isVisible]);

    const markRead = async () => {
        await forceNotificationRead(item.id);
        setRead(true);
    };
    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTab(newValue);
    };
    const downloadFile = async (id: string) => {
        await download(id);
    };
    const downloadDoc = async (doc: IDocument) => {
        await downloadDocument(doc.job_id, doc.id);
    };

    const images = React.useMemo(() => {
        return item.files.filter((f) => acceptedImageTypes.indexOf(f.type) >= 0);
    }, [item.files]);

    const nonImages = React.useMemo(() => {
        return item.files.filter((f) => acceptedImageTypes.indexOf(f.type) < 0);
    }, [item.files]);

    const renderImages = () => {
        return images.map((att) => {
            return <Image key={att.id} id={att.id} disabled title={att.name} onRemoveClick={console.log} onImageClick={() => downloadFile(att.id)} />;
        });
    };

    const renderDocuments = () => {
        return item.documents.map((file) => {
            return (
                <Card variant="outlined" className="file-item m-b-10" key={file.id}>
                    <Box sx={{ display: 'flex', alignItems: 'center', p: 1, mr: 2 }}>
                        <div>{IconLookup24[file.type]}</div>
                        <div className="m-l-20" style={{ width: '65%' }}>
                            <a onClick={() => downloadDoc(file)}>
                                {file.name}.{file.type}
                            </a>
                        </div>
                        <div className="m-l-20 text-right bp3-text-muted p-r-30" style={{ width: '35%' }}>
                            {humanFileSize(file.size)}
                        </div>
                    </Box>
                </Card>
            );
        });
    };

    const renderFiles = () => {
        return nonImages.map((file) => {
            return (
                <Card variant="outlined" className="file-item m-b-10" key={file.id}>
                    <Box sx={{ display: 'flex', alignItems: 'center', p: 1, mr: 2 }}>
                        <div>{IconLookup24[file.type]}</div>
                        <div className="m-l-20" style={{ width: '65%' }}>
                            <a onClick={() => downloadFile(file.id)}>{file.name}</a>
                        </div>
                        <div className="m-l-20 text-right bp3-text-muted p-r-30" style={{ width: '35%' }}>
                            {humanFileSize(file.size)}
                        </div>
                    </Box>
                </Card>
            );
        });
    };

    // const UserAvatar = (
    //     <Avatar style={{ marginTop: 0 }} sx={{ bgcolor: '#015794', width: 42, height: 42 }}>
    //         {item.created_by.first_name.slice(0, 1)}
    //         {item.created_by.last_name.slice(0, 1)}
    //     </Avatar>
    // );
    const links = React.useMemo(() => {
        return [...item.meetings, ...item.dailys, ...item.rfis, ...item.changes];
    }, [item]);

    const handleEditClick = () => {
        openEditDialog(item);
    };

    const toggleResolved = async () => {
        const updatedResolved = !resolved;
        setResolved(updatedResolved);
        await resolvePost(item.job_id, item.id, updatedResolved);
        if (updatedResolved) {
            AppToast.show({ message: `Post Resolved!`, intent: 'success', icon: 'tick' });
        } else {
            AppToast.show({ message: `Post Unresolved!`, intent: 'success', icon: 'tick' });
        }
    };

    const createdDate = moment(item.created_at);
    const timeago = moment().diff(createdDate, 'days') <= 1;
    return (
        <Card className={displayOnly ? 'm-b-0' : 'm-b-15'} ref={ref}>
            <CardHeader
                // style={{ marginLeft: -10, marginRight: -10 }}
                // avatar={<>{UserAvatar}</>}
                title={
                    <>
                        {item.created_by.full_name}

                        {item.visibility === 'public' ? (
                            <Tooltip title="Public">
                                <PublicIcon sx={{ height: 20, width: 20, marginLeft: '15px', marginBottom: '-3px' }} />
                            </Tooltip>
                        ) : (
                            <Tooltip title="Private">
                                <SecurityIcon sx={{ height: 20, width: 20, marginLeft: '15px', marginBottom: '-3px' }} />
                            </Tooltip>
                        )}
                    </>
                }
                titleTypographyProps={{ variant: 'h6', component: 'div', sx: { fontSize: 18, fontWeight: 'bold', paddingLeft: '5px', gutterBottom: false } }}
                subheader={
                    <Stack direction="row" spacing={1} className="m-l-5 m-t-10">
                        {item.constraint && (
                            <Tooltip title="Constraint">
                                <Avatar sx={{ bgcolor: '#1776d1', width: 28, height: 28 }}>
                                    <TbBarrierBlock />
                                </Avatar>
                            </Tooltip>
                        )}
                        {item.critical && (
                            <Tooltip title="Critical">
                                <Avatar sx={{ bgcolor: '#1776d1', width: 28, height: 28 }}>
                                    <NearbyErrorIcon sx={{ fontSize: 18 }} />
                                </Avatar>
                            </Tooltip>
                        )}
                        {item.cost && (
                            <Avatar sx={{ bgcolor: '#1776d1', width: 28, height: 28 }}>
                                <Tooltip title="Cost and Schedule">
                                    <MoreTimeIcon sx={{ fontSize: 18 }} />
                                </Tooltip>
                            </Avatar>
                        )}
                        {item.assigned_to && (
                            <Tooltip title="Assigned To">
                                <Chip size="small" label={item.assigned_to.full_name} color="primary" className="m-t-3" />
                            </Tooltip>
                        )}
                        {item.date && (
                            <Tooltip title="Need Date">
                                <Chip size="small" label={moment(item.date).format('MM/DD/YYYY')} color="primary" className="m-t-3" />
                            </Tooltip>
                        )}
                    </Stack>
                    // <div className="p-l-5">
                    //     In {item.job.name}
                    //     {bull}
                    //     {moment(item.created_at).fromNow()}
                    // </div>
                }
                action={
                    <Stack direction="row">
                        <div style={{ fontWeight: 'normal', fontSize: 14, marginTop: 10, marginRight: user?.id === item.created_by_id ? 10 : 50 }}>
                            {timeago ? createdDate.fromNow() : createdDate.format('MM/DD/YYYY [at] h:mm a')}
                        </div>
                        {resolved ? (
                            <Tooltip title="Click to unresolve">
                                <IconButton onClick={toggleResolved} color="success">
                                    <CheckCircleOutlineIcon />
                                </IconButton>
                            </Tooltip>
                        ) : (
                            <Tooltip title="Click to resolve">
                                <IconButton onClick={toggleResolved}>
                                    <RestartAltIcon />
                                </IconButton>
                            </Tooltip>
                        )}

                        {user?.id === item.created_by_id && <PostMenu postId={item.id} jobId={item.job_id} onPostDeleted={refreshPosts} onEditClicked={handleEditClick} />}
                    </Stack>
                }
            />
            <CardContent className="p-t-0">
                <MentionInput peopleOptions={[]} value={item.content} disabled onChange={console.log} id={item.id} />
                {/*<PostPeople people={item.mentions} groups={item.groups} />*/}
                {images.length > 0 && (
                    <ImageList sx={{ width: '100%', height: 250 }} cols={3}>
                        {renderImages()}
                    </ImageList>
                )}
            </CardContent>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={tab} onChange={handleTabChange} aria-label="basic tabs example" variant="fullWidth">
                    <Tab
                        label={
                            <Badge badgeContent={item.comments.length} color="primary">
                                <div style={{ width: 120 }}>Comments</div>
                            </Badge>
                        }
                        {...a11yProps(0)}
                    />
                    <Tab
                        label={
                            <Badge badgeContent={links.length} color="primary" style={{ marginLeft: 5 }}>
                                <div style={{ width: 65 }}>Links</div>
                            </Badge>
                        }
                        {...a11yProps(1)}
                    />
                    <Tab
                        label={
                            <Badge badgeContent={nonImages.length} color="primary">
                                <div style={{ width: 65 }}>Files</div>
                            </Badge>
                        }
                        {...a11yProps(2)}
                    />
                    <Tab
                        label={
                            <Badge badgeContent={item.documents.length} color="primary">
                                <div style={{ width: 120 }}>Documents</div>
                            </Badge>
                        }
                        {...a11yProps(3)}
                    />
                </Tabs>
            </Box>
            <TabPanel value={tab} index={0}>
                <PostComments comments={item.comments} postId={item.id} shouldPoll={shouldPoll} />
            </TabPanel>
            <TabPanel value={tab} index={1}>
                <PostLinks links={links} onLinkRemoved={console.log} disabled />
            </TabPanel>
            <TabPanel value={tab} index={2}>
                {renderFiles()}
            </TabPanel>
            <TabPanel value={tab} index={3}>
                {renderDocuments()}
            </TabPanel>
        </Card>
    );
};
