import React from 'react';
import { logout } from 'src/api/user';
import { useAuth } from 'src/hooks/useAuth';
import { useHistory } from 'react-router-dom';
export const Logout: React.FC = () => {
    const { setUser } = useAuth();
    const history = useHistory();
    const logUserOut = React.useCallback(async () => {
        await logout();
        await localStorage.removeItem('@Firetrol:user');
        await localStorage.removeItem('@Firetrol:jwt');
        setUser(null);
        history.push('/login');
    }, [setUser]);

    React.useEffect(() => {
        logUserOut();
    }, [logUserOut]);

    return null;
};
