import React, { useState, createContext } from 'react';
import { useQuery } from 'react-query';
import { loadProjects } from 'src/api/projects';
import { IProject } from 'src/api/models/Project';

interface IProjectsContext {
    projects: IProject[];
    activeProject: IProject | null;
    setActiveProject: (project: IProject) => void;
    onNewProjectCreated: (project: IProject) => void;
    loading: boolean;
}

export const ProjectContext = createContext<IProjectsContext>({
    projects: [],
    activeProject: null,
    setActiveProject: () => null,
    onNewProjectCreated: () => null,
    loading: true,
});

export const ProjectsProvider: React.FC = ({ children }) => {
    const [projects, setProjects] = useState<IProject[]>([]);
    const [activeProject, setActiveProject] = useState<IProject | null>(null);
    const { data, isLoading: loading } = useQuery<IProject[]>('projects', loadProjects);

    React.useEffect(() => {
        if (!activeProject && data && data.length > 0) {
            setActiveProject(data[0]);
        }
        if (projects.length === 0) {
            setProjects(data || []);
        }
    }, [data, projects.length, activeProject]);

    const onNewProjectCreated = (project: IProject) => {
        setProjects([project, ...projects]);
        setActiveProject(project);
    };

    return <ProjectContext.Provider value={{ projects, onNewProjectCreated, loading, activeProject, setActiveProject }}>{children}</ProjectContext.Provider>;
};

export const useProjects: () => IProjectsContext = () => {
    const context = React.useContext(ProjectContext);
    return { ...context };
};
