import React from 'react';
import { sumBy, sortBy } from 'lodash';
import { Row, Col } from 'react-grid-system';
import { Box, Typography } from '@mui/material';
import { TreeView, TreeItem } from '@mui/lab';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { CTCMaterial } from 'src/api/models/CTC';
import groupBy from 'lodash/groupBy';

const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0,
});

interface POTabProps {
    material: Record<string, CTCMaterial[]>;
}

export const POTab: React.FC<POTabProps> = ({ material }) => {
    const renderLineItems = () => {
        let totalRem = 0;
        let totalRemFinal = 0;
        let totalCost = 0;
        return (
            <>
                <TreeItem
                    nodeId={'header'}
                    className={'total-block'}
                    label={
                        <Box sx={{ alignItems: 'center', p: 0.5, pr: 0 }}>
                            {/*<Box component={LabelIcon} color="inherit" sx={{ mr: 1 }} />*/}
                            <Row>
                                <Col xs={6}>
                                    <Typography variant="body2" sx={{ fontWeight: 'inherit' }}>
                                        <b>Description</b>
                                    </Typography>
                                </Col>

                                <Col className="text-right">
                                    <Typography variant="body2" color="inherit">
                                        <b>PO Amount</b>
                                    </Typography>
                                </Col>
                                <Col className="text-right">
                                    <Typography variant="body2" color="inherit">
                                        <b>Remaining</b>
                                    </Typography>
                                </Col>
                            </Row>
                        </Box>
                    }
                />
                {sortBy(Object.keys(material), [
                    function (o) {
                        return o;
                    },
                ]).map((key) => {
                    const values = material[key];
                    const numberValues = values.map((val) => {
                        return {
                            ...val,
                            UNITCOST_Number: Number(val.UNITCOST),
                            Remaining_Number: Number(val.Remaining_PO_Balance),
                        };
                    });
                    // const total = sumBy(numberValues, 'UNITCOST_Number');
                    const remainingTotal = numberValues[0] ? numberValues[0].Remaining_PO_Balance : 0;
                    totalRem = totalRem + Number(remainingTotal);
                    const poGroup = groupBy(values, 'PONUMBER');
                    let remainTotal = 0;
                    Object.keys(poGroup).forEach((groupKey) => {
                        const vals = poGroup[groupKey];
                        const tmp = vals[0] ? Number(vals[0].Remaining_PO_Balance) : 0;
                        remainTotal = remainTotal + tmp;
                    });
                    return (
                        <TreeItem
                            className="basic-block"
                            nodeId={key}
                            label={
                                <Box sx={{ alignItems: 'center', p: 0.5, pr: 0 }}>
                                    {/*<Box component={LabelIcon} color="inherit" sx={{ mr: 1 }} />*/}
                                    <Row>
                                        <Col xs={6}>
                                            <Typography variant="body2" sx={{ fontWeight: 'inherit', textTransform: 'capitalize' }}>
                                                <b>{key.length === 0 ? 'No Description' : key}</b>
                                            </Typography>
                                        </Col>
                                        <Col className="text-right">
                                            <Typography variant="body2" color="inherit">
                                                {/*<b>{formatter.format(total)}</b>*/}
                                            </Typography>
                                        </Col>
                                        <Col className="text-right">
                                            <Typography variant="body2" color="inherit">
                                                {/*<b>{formatter.format(remainTotal)}</b>*/}
                                            </Typography>
                                        </Col>
                                    </Row>
                                </Box>
                            }
                        >
                            {Object.keys(poGroup).map((k) => {
                                const vals = poGroup[k].map((i) => {
                                    return {
                                        ...i,
                                        Remaining_PO_Balance: Number(i.Remaining_PO_Balance),
                                        PO_Amount: Number(i.PO_Amount),
                                        UNITCOST: Number(i.UNITCOST),
                                        EXTDCOST: Number(i.EXTDCOST),
                                    };
                                });

                                const poTotal = sumBy(vals, 'EXTDCOST');
                                //const rTotal = sumBy(vals, 'Remaining_PO_Balance');
                                const rTotal = vals[0].Remaining_PO_Balance;
                                totalCost = totalCost + poTotal;
                                totalRemFinal = totalRemFinal + rTotal;
                                return (
                                    <TreeItem
                                        key={k}
                                        nodeId={k}
                                        label={
                                            <Box sx={{ alignItems: 'center', p: 0.5, pr: '5px' }} className="basic-block-root">
                                                <Row>
                                                    <Col xs={6}>
                                                        <Typography variant="body2" sx={{ fontWeight: 'inherit' }}>
                                                            <b>{k}</b>
                                                        </Typography>
                                                    </Col>
                                                    <Col xs={3} className="text-right">
                                                        <Typography variant="body2" color="inherit">
                                                            <b>{formatter.format(poTotal)}</b>
                                                        </Typography>
                                                    </Col>
                                                    <Col xs={3} className="text-right p-r-10">
                                                        <Typography variant="body2" color="inherit">
                                                            <b>{formatter.format(rTotal)}</b>
                                                        </Typography>
                                                    </Col>
                                                </Row>
                                            </Box>
                                        }
                                    >
                                        {vals.map((val) => {
                                            //if (Math.round(val.Remaining_PO_Balance) === 0) return null;
                                            //if (val.PO_Line_Status === 'Closed') return null;
                                            return (
                                                <TreeItem
                                                    key={val.id}
                                                    nodeId={val.id}
                                                    label={
                                                        <Box sx={{ alignItems: 'center', p: 0.5, pr: '10px' }}>
                                                            {/*<Box component={LabelIcon} color="inherit" sx={{ mr: 1 }} />*/}
                                                            <Row>
                                                                <Col xs={6}>
                                                                    <Typography variant="body2" sx={{ fontWeight: 'inherit' }}>
                                                                        {val.ITEMDESC.length > 0 ? val.ITEMDESC : '-'} {val.PO_Line_Status.length > 0 ? `(${val.PO_Line_Status})` : ''}
                                                                    </Typography>
                                                                </Col>
                                                                <Col xs={3} className="text-right">
                                                                    <Typography variant="body2" color="inherit">
                                                                        {formatter.format(val.EXTDCOST)}
                                                                    </Typography>
                                                                </Col>
                                                                {val.PO_Line_Status !== 'Closed' ? (
                                                                    <Col xs={3} className="text-right p-r-5">
                                                                        <Typography variant="body2" color="inherit">
                                                                            {formatter.format(val.Remaining_PO_Balance)}
                                                                        </Typography>
                                                                    </Col>
                                                                ) : (
                                                                    <Col xs={3} className="text-right p-r-5">
                                                                        <Typography variant="body2" color="inherit">
                                                                            {/*{formatter.format(0)}*/}
                                                                        </Typography>
                                                                    </Col>
                                                                )}

                                                                {/*<Col className="text-right p-r-5">*/}
                                                                {/*    <Typography variant="body2" color="inherit">*/}
                                                                {/*        {formatter.format(val.Remaining_PO_Balance)}*/}
                                                                {/*    </Typography>*/}
                                                                {/*</Col>*/}
                                                            </Row>
                                                        </Box>
                                                    }
                                                />
                                            );
                                        })}
                                    </TreeItem>
                                );
                            })}
                        </TreeItem>
                    );
                })}
                {totalCost && (
                    <TreeItem
                        nodeId={'total value'}
                        className={'total-block'}
                        label={
                            <Box sx={{ alignItems: 'center', p: 0.5, pr: 0 }}>
                                <Row>
                                    <Col xs={6}>
                                        <Typography variant="body2" sx={{ fontWeight: 'inherit' }}>
                                            <b>Total</b>
                                        </Typography>
                                    </Col>
                                    <Col className="text-right">
                                        <Typography variant="body2" color="inherit">
                                            <b>{formatter.format(totalCost)}</b>
                                        </Typography>
                                    </Col>
                                    <Col className="text-right">
                                        <Typography variant="body2" color="inherit">
                                            <b>{formatter.format(totalRemFinal)}</b>
                                        </Typography>
                                    </Col>
                                </Row>
                            </Box>
                        }
                    />
                )}
            </>
        );
    };
    const lineItems = React.useMemo(renderLineItems, [material]);
    return (
        <>
            {Object.keys(material).length > 0 ? (
                <TreeView defaultCollapseIcon={<ExpandMoreIcon />} defaultExpandIcon={<ChevronRightIcon />} sx={{ height: 400, flexGrow: 1, maxWidth: '100%' }}>
                    {lineItems}
                </TreeView>
            ) : (
                <div style={{ height: 300 }}>
                    <p>No items...</p>
                </div>
            )}
        </>
    );
};
