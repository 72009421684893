import React from 'react';
import { Link } from 'react-router-dom';
import { IconButton, Card, Box } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { PostItemOption } from 'src/api/models/Post';

interface PostLinksProps {
    onLinkRemoved: (link: PostItemOption) => void;
    links: PostItemOption[];
    disabled?: boolean;
}

const getRouterlink = (link: PostItemOption) => {
    switch (link.resource) {
        case 'Meetings': {
            return `/jobs/${link.job_id}/meetings/edit/${link.id}`;
        }
        case 'RFIs': {
            return `/jobs/${link.job_id}/rfis/${link.id}`;
        }
        case 'Changes': {
            return `/jobs/${link.job_id}/changes/view/${link.id}`;
        }
        case 'Dailys': {
            return `/jobs/${link.job_id}/dailys/view/${link.id}`;
        }
        default: {
            return '';
        }
    }
};
export const PostLinks: React.FC<PostLinksProps> = ({ links, onLinkRemoved, disabled = false }) => {
    const removeLink = (link: PostItemOption) => {
        onLinkRemoved(link);
    };
    const renderLinks = () => {
        return links.map((link) => {
            return (
                <Card variant="outlined" className="link-item m-b-10" key={link.id}>
                    <Box sx={{ display: 'flex', alignItems: 'center', p: 1, mr: 2 }}>
                        {/*<div>{IconLookup24[file.type]}</div>*/}
                        <div>{link.resource.slice(0, link.resource.length - 1)} - </div>
                        <div className="m-l-5" style={{ width: '50%' }}>
                            <Link to={getRouterlink(link)} target="_blank" rel="noopener noreferrer">
                                {link.label}
                            </Link>
                        </div>
                    </Box>
                    {!disabled && (
                        <div className="link-item-delete">
                            <IconButton onClick={() => removeLink(link)} size="small">
                                <ClearIcon />
                            </IconButton>
                        </div>
                    )}
                </Card>
            );
        });
    };

    return <>{renderLinks()}</>;
};
