import React from 'react';
import { useLocation } from 'react-router-dom';
import { Main } from './Main';
import { Jobs } from './Jobs';
import { Account } from './Account';
import { Operations } from './Operations';
import { Settings } from './Settings';
import { Documents } from './Documents';
import './index.scss';

export const Sidebar: React.FC = () => {
    const location = useLocation();

    if (location.pathname.includes('/account')) {
        return <Account />;
    }
    if (location.pathname.includes('/settings')) {
        return <Settings />;
    }
    if (location.pathname.includes('/operations')) {
        return <Operations />;
    }
    if (location.pathname.includes('/documents') && !location.pathname.includes('jobs')) {
        return <Documents />;
    }
    if (location.pathname.includes('/jobs/') && location.pathname.length > 6) {
        return <Jobs />;
    }
    if (location.pathname === '/account') {
        return null;
    }
    return <Main />;
};
