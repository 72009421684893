import React from 'react';
import { useParams } from 'react-router-dom';
import { Spinner } from '@blueprintjs/core';
import { useJobs } from 'src/hooks/useJobs';
import { Row, Col } from 'react-grid-system';
import { DailyForm } from '../DailyForm';
import { getDailyById } from 'src/api/dailys';
import { IDailyResponse } from 'src/api/models/Daily';
import { Header2 } from '../../../../components/Header2';

export const ViewDaily: React.FC = () => {
    const { selectedJob } = useJobs();
    const params: any = useParams();
    const [values, setValues] = React.useState<IDailyResponse | null>();
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState<string | null>(null);

    React.useEffect(() => {
        loadDaily();
    }, []);

    const loadDaily = async (load = true) => {
        if (!selectedJob) return;
        setLoading(load);
        setError(null);
        try {
            const daily = await getDailyById(selectedJob.id, params.dailyId);
            setValues(daily);
        } catch (err) {
            setError(err.toLocaleString());
        }
        setLoading(false);
    };
    return (
        <>
            <Header2
                breadcrumbs={[
                    { title: 'Jobs', link: '/jobs' },
                    { title: `${selectedJob?.name} - ${selectedJob?.job_number}`, link: `/jobs/${selectedJob?.id}/summary` },
                    { title: 'Dailys', link: `/jobs/${selectedJob?.id}/dailys` },
                    { title: values?.daily_number || '', link: '' },
                ]}
            />
            <Row>
                <Col>
                    {error && <p className="text-center">{error}</p>}
                    {loading || !values ? (
                        <div className="text-center">
                            <Spinner />
                        </div>
                    ) : (
                        <DailyForm initialValues={values} dailyId={params.dailyId} />
                    )}
                </Col>
            </Row>
        </>
    );
};
