import React from 'react';
import Select from 'react-select';
import { IWorkflowResponse } from 'src/api/models/Workflow';

interface IUserSelectProps {
    resourceId: string;
    onChange: (value: IWorkflowResponse) => void;
    value: IWorkflowResponse | null;
    options: IWorkflowResponse[];
}

export const WorkflowSelector: React.FC<IUserSelectProps> = ({ onChange, value, resourceId, options }) => {
    const filteredOptions = React.useMemo(() => {
        return options.filter((o) => o.resource_id === resourceId);
    }, [options, resourceId]);
    return (
        <div>
            <Select
                className="basic-single"
                placeholder="Select the default workflow..."
                classNamePrefix="react-select"
                formatOptionLabel={(o) => {
                    return (
                        <div>
                            {o.name}
                            {o.is_template && <span className="pull-right">Template</span>}
                            {o.job_id && <span className="pull-right">Custom</span>}
                        </div>
                    );
                }}
                getOptionLabel={(o) => o.name}
                getOptionValue={(o) => String(o.id)}
                value={value}
                options={filteredOptions}
                onChange={(value: any) => {
                    onChange(value);
                }}
            />
        </div>
    );
};
