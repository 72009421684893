import React from 'react';
import { Row, Col } from 'react-grid-system';
import { Header } from 'src/components/Header';
import { CTCAttachments } from './CTCAttachments';
import { loadCTCFiles, processCTCFiles } from 'src/api/ctc';
import { CTCFile } from '../../../api/models/CTC';
import { AiOutlineFileExcel, AiOutlineFileImage, AiOutlineFilePdf, AiOutlineFileUnknown, AiOutlineFileWord } from 'react-icons/ai';
import { Button } from '@blueprintjs/core';
import { GrDocumentCsv } from 'react-icons/gr';
import moment from 'moment';
import { download } from '../../../api/attachments';

const IconLookup: any = {
    'text/csv': <GrDocumentCsv size={16} />,
    csv: <GrDocumentCsv size={16} />,
    pdf: <AiOutlineFilePdf size={18} />,
    xlsx: <AiOutlineFileExcel size={18} />,
    cfb: <AiOutlineFileExcel size={18} />,
    xls: <AiOutlineFileExcel size={18} />,
    png: <AiOutlineFileImage size={18} />,
    jpg: <AiOutlineFileImage size={18} />,
    jpeg: <AiOutlineFileImage size={18} />,
    docx: <AiOutlineFileWord size={18} />,
};
export const CTC: React.FC = () => {
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState<string | null>(null);
    const [upload, setUpload] = React.useState<CTCFile | null>(null);
    const [processing, setProcessing] = React.useState(false);
    const [pError, setPError] = React.useState<string | null>(null);
    React.useEffect(() => {
        loadUploads();
    }, []);

    const processFiles = async () => {
        setProcessing(true);
        setPError(null);
        try {
            const file = await processCTCFiles();
            setUpload(file);
        } catch (err) {
            console.log('err: ', err);
            setPError(err.toLocaleString());
        }
        setProcessing(false);
    };
    const loadUploads = async () => {
        setLoading(true);
        try {
            const files = await loadCTCFiles();
            setUpload(files[0] || null);
        } catch (err) {
            setError(err.toLocaleString());
        }

        setLoading(false);
    };
    const onFileUploaded = () => {
        loadUploads();
    };
    const downloadFile = async (id: string) => {
        await download(id);
    };
    const renderFiles = () => {
        if (!upload) {
            return <p>No Uploads</p>;
        }
        return (
            <>
                <p className="m-b-5">
                    Last uploaded by <b>{upload.uploaded_by.full_name}</b> on {moment(upload.created_at).format('MM/DD/YYYY h:mm a')}
                </p>
                <table className={`bp3-html-table bp3-html-table-striped`} style={{ width: '100%' }}>
                    <thead>
                        <tr>
                            <th style={{ width: 18 }} />
                            <th>Name</th>
                            <th>Size</th>
                            <th />
                        </tr>
                    </thead>
                    <tbody>
                        {upload.files.map((file) => {
                            return (
                                <tr>
                                    <td>{IconLookup[file.type] || <AiOutlineFileUnknown size={18} />}</td>
                                    <td>
                                        {' '}
                                        <a onClick={() => downloadFile(file.attachmentId)}>{file.name}</a>
                                    </td>
                                    <td>{Math.round(file.size / 1000)} KB</td>
                                    <td />
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
                {pError && (
                    <Row className="m-t-10">
                        <Col>
                            <p className="m-t-5 text-danger">Error Processing files. Invalid CSVs.</p>
                        </Col>
                    </Row>
                )}
                {!upload.processed && (
                    <Row className="m-t-10">
                        <Col>
                            <p className="m-t-5">CTC Files have only been uploaded but not processed.</p>
                        </Col>
                        <Col className="text-right" xs={4}>
                            <Button loading={processing} onClick={processFiles} intent="primary">
                                {' '}
                                Process Files
                            </Button>
                        </Col>
                    </Row>
                )}

                {upload.processed && (
                    <Row className="m-t-10">
                        <Col>
                            <p className="m-t-5">
                                CTC Files were last processed by {upload.processed_by?.full_name} on {moment(upload.processed_at).format('MM/DD/YYYY h:mm a')}
                            </p>
                        </Col>
                    </Row>
                )}
            </>
        );
    };
    return (
        <>
            <Header title="CTC" breadcrumbs={['Settings', 'CTC']} />
            <Row className="m-b-40">
                <Col xs={12} md={12} lg={8}>
                    {error && <p>{error}</p>}
                    {loading ? <p>Loading...</p> : <>{renderFiles()}</>}
                </Col>
            </Row>
            <Row>
                <Col xs={12} md={12} lg={8}>
                    <CTCAttachments onFilesAdded={onFileUploaded} />
                </Col>
            </Row>
        </>
    );
};
