import React from 'react';
import { Link } from 'react-router-dom';
import { Card } from '@blueprintjs/core';
import { TimesheetDraft } from 'src/api/models/timesheet';
import AvatarGroup from '@atlaskit/avatar-group';
import moment from 'moment';

interface IDraftCardProps {
    draft: TimesheetDraft;
}
export const DraftCard: React.FC<IDraftCardProps> = ({ draft }) => {
    const avatarList = React.useMemo(() => {
        return draft.timesheets.map((ts) => {
            return {
                email: ts.employee.email,
                key: ts.employee.id,
                name: ts.employee.full_name,
                href: '#',
            };
        });
    }, [draft]);
    return (
        <Card className="m-b-15">
            <h3 className="m-t-0 m-b-10">
                <Link to={`/jobs/${draft.job_id}/timesheets/drafts/${draft.id}`}>
                    {' '}
                    {moment(draft.start_date).format('MM/DD/YYYY')} - {moment(draft.start_date).add(6, 'days').format('MM/DD/YYYY')}
                </Link>
            </h3>
            <p>
                <b>Created By:</b> {draft.created_by.full_name}
            </p>
            <p>
                <b>Employees</b>
            </p>
            <AvatarGroup data={avatarList} maxCount={5} size="small" />
        </Card>
    );
};
