import React from 'react';
import { Moment } from 'moment';
import { DialogTitle, DialogContent, DialogActions, CardHeader, Tooltip, Button, IconButton, IconButtonProps } from '@mui/material';
import NearbyErrorIcon from '@mui/icons-material/NearbyError';
import { TbBarrierBlock } from 'react-icons/tb';
import MoreTimeIcon from '@mui/icons-material/MoreTime';
import { MentionInput } from '../MentionInput';
import { PostActions } from '../PostActions';
import { PostFiles } from '../PostFiles';
import { PostLinks } from '../PostLinks';
import { DatePicker } from '../DatePicker';
import { PersonPicker } from '../PersonPicker';
import { IAttachment } from 'src/api/models/Attachment';
import { IUser } from 'src/api/models/User';
import { useJobs } from 'src/hooks/useJobs';
import { PostItemOption, PostResponse } from 'src/api/models/Post';
import { IOrganizationRole } from 'src/api/models/Organization';
import { useAuth } from 'src/hooks/useAuth';
import { IDocument } from 'src/api/models/Document';
import { createPost, updatePost } from 'src/api/posts';
import { styled } from '@mui/material/styles';

interface PostFormProps {
    handleClose: () => void;
    onPostCreated: () => void;
    initialValues?: PostResponse | null;
}

const ContainedIconButtonButton = styled(IconButton)<IconButtonProps>(({ theme }) => ({
    color: theme.palette.getContrastText(theme.palette.primary.main),
    backgroundColor: theme.palette.primary.main,
    border: 0,
    padding: 5,
    height: 35,
    width: 35,
    marginTop: 0,
    marginLeft: 3,
    marginRight: 7,
    fontSize: 21,
    '&:hover': {
        backgroundColor: theme.palette.primary.main,
    },
}));

export const PostForm: React.FC<PostFormProps> = ({ handleClose, onPostCreated, initialValues }) => {
    const { user } = useAuth();
    const [files, setFiles] = React.useState<IAttachment[]>(initialValues ? initialValues.files : []);
    const [filesToRemove, setFilesToRemove] = React.useState<string[]>([]);
    const [links, setLinks] = React.useState<PostItemOption[]>(initialValues ? [...initialValues.meetings, ...initialValues.dailys, ...initialValues.rfis, ...initialValues.changes] : []);
    const [documents, setDocuments] = React.useState<IDocument[]>(initialValues ? initialValues.documents : []);
    const [people, setPeople] = React.useState<IUser[]>(initialValues ? initialValues.mentions : []);
    const [groups, setGroups] = React.useState<IOrganizationRole[]>(initialValues ? initialValues.groups : []);
    const [content, setContent] = React.useState(initialValues ? initialValues.content : '');
    const [visibility, setVisibility] = React.useState(initialValues ? initialValues.visibility : 'public');
    const [critical, setCritical] = React.useState(initialValues ? initialValues.critical : false);
    const [constraint, setConstraint] = React.useState(initialValues ? initialValues.constraint : false);
    const [cost, setCost] = React.useState(initialValues ? initialValues.cost : false);
    const [date, setDate] = React.useState<Moment | Date | null>(initialValues ? initialValues.date : null);
    const [assigned, setAssigned] = React.useState<IUser | null>(initialValues ? initialValues.assigned_to : null);
    const [plainTextContent, setPlainTextContent] = React.useState(initialValues ? initialValues.plainTextContent : '');

    const { selectedJob, users } = useJobs();

    // const UserAvatar = user ? (
    //     <Avatar style={{ marginTop: 0 }} sx={{ bgcolor: '#015794', width: 52, height: 52 }}>
    //         {user.first_name.slice(0, 1)}
    //         {user.last_name.slice(0, 1)}
    //     </Avatar>
    // ) : null;

    const onFileUploaded = (file: IAttachment) => {
        setFiles((prevState) => [...prevState, file]);
    };

    const onFileRemoved = (file: IAttachment) => {
        setFiles((prevState) => prevState.filter((a) => a.id !== file.id));
        setFilesToRemove((prevState) => [...prevState, file.id]);
    };

    const onDocumentRemoved = (doc: IDocument) => {
        setDocuments((prevState) => prevState.filter((a) => a.id !== doc.id));
    };

    const onLinkSelected = (link: PostItemOption) => {
        setLinks((prevState) => [...prevState, link]);
    };

    const onDocumentSelected = (doc: IDocument) => {
        setDocuments((prevState) => [...prevState, doc]);
    };

    const onLinkRemoved = (link: PostItemOption) => {
        setLinks((prevState) => prevState.filter((a) => a.id !== link.id));
    };

    const onContentChange = (value: string, plainTextValue: string, peeps: IUser[], roles: IOrganizationRole[]) => {
        setContent(value);
        setPeople(peeps);
        setGroups(roles);
        setPlainTextContent(plainTextValue);
    };

    const submitPostForm = async () => {
        if (!selectedJob) return;
        const payload = {
            visibility,
            resolved: false,
            critical,
            cost,
            constraint,
            assigned_to: assigned,
            date,
            files,
            documents,
            links,
            mentions: people,
            groups,
            content,
            filesToRemove,
            plainTextContent,
        };
        if (initialValues) {
            await updatePost(initialValues.job_id, initialValues.id, payload);
        } else {
            await createPost(selectedJob.id, payload);
        }
        onPostCreated();
        handleClose();
    };
    if (!selectedJob || !user) return null;
    return (
        <>
            <DialogTitle style={{ height: 60, textAlign: 'center', borderBottom: '1px solid silver' }}>Create Post</DialogTitle>
            <DialogTitle style={{ height: 100 }}>
                <CardHeader
                    style={{ marginLeft: -10, marginRight: -10, paddingTop: 0 }}
                    //avatar={<>{UserAvatar}</>}
                    title={`${user.full_name} in ${selectedJob.name}`}
                    titleTypographyProps={{ variant: 'h5', component: 'div', sx: { fontSize: 20, fontWeight: 'bold', paddingTop: 0, paddingLeft: '5px', gutterBottom: false } }}
                    subheader={
                        <div className="m-t-10">
                            <Tooltip title="Constraint">
                                {constraint ? (
                                    <ContainedIconButtonButton onClick={() => setConstraint((prevState) => !prevState)}>
                                        <TbBarrierBlock />
                                    </ContainedIconButtonButton>
                                ) : (
                                    <IconButton onClick={() => setConstraint((prevState) => !prevState)} className="m-r-5 p-t-4">
                                        <TbBarrierBlock />
                                    </IconButton>
                                )}
                            </Tooltip>
                            <Tooltip title="Critical">
                                {critical ? (
                                    <ContainedIconButtonButton onClick={() => setCritical((prevState) => !prevState)}>
                                        <NearbyErrorIcon />
                                    </ContainedIconButtonButton>
                                ) : (
                                    <IconButton onClick={() => setCritical((prevState) => !prevState)} className="m-r-5 p-t-4">
                                        <NearbyErrorIcon />
                                    </IconButton>
                                )}
                            </Tooltip>
                            <Tooltip title="Cost and Schedule">
                                {cost ? (
                                    <ContainedIconButtonButton onClick={() => setCost((prevState) => !prevState)}>
                                        <MoreTimeIcon />
                                    </ContainedIconButtonButton>
                                ) : (
                                    <IconButton onClick={() => setCost((prevState) => !prevState)} className="m-r-5 p-t-4">
                                        <MoreTimeIcon />
                                    </IconButton>
                                )}
                            </Tooltip>

                            <PersonPicker onChange={(value) => setAssigned(value)} value={assigned} />
                            <DatePicker value={date} onChange={(value) => setDate(value)} />
                        </div>
                    }
                    action={
                        <div className="m-t-0">
                            <div className="bp3-html-select bp3-minimal m-r-15">
                                <select className="bp3-small " onChange={(e) => setVisibility(e.target.value)}>
                                    <option selected={visibility === 'public'} value="public">
                                        Public
                                    </option>
                                    <option selected={visibility === 'private'} value="private">
                                        Private
                                    </option>
                                </select>
                                <span className="bp3-icon bp3-icon-double-caret-vertical fs-14 m-t-5" />
                            </div>
                        </div>
                    }
                />
            </DialogTitle>
            <DialogContent style={{ minHeight: 100, overflow: 'auto', paddingBottom: 0 }}>
                <div style={{ minHeight: 100 }}>
                    <MentionInput value={content} peopleOptions={users} onChange={onContentChange} id="mention-input" />
                </div>
                <PostLinks onLinkRemoved={onLinkRemoved} links={links} />
                <PostFiles onFileRemoved={onFileRemoved} onDocumentRemoved={onDocumentRemoved} files={files} documents={documents} />
            </DialogContent>
            <DialogActions style={{ flex: 'none', display: 'block', padding: '20px 25px 20px 25px' }}>
                <PostActions onFileUploaded={onFileUploaded} onLinkSelected={onLinkSelected} onDocumentSelected={onDocumentSelected} jobId={selectedJob.id} />
                <Button fullWidth variant="contained" onClick={submitPostForm} disabled={content.length === 0}>
                    {initialValues && 'Save '}Post
                </Button>
                {visibility === 'public' ? (
                    <p className="text-center m-t-20 m-b-0">Public posts are visible to all people with access to this job.</p>
                ) : (
                    <p className="text-center m-t-20 m-b-0">Private posts are only visible to people and roles you've tagged</p>
                )}
            </DialogActions>
        </>
    );
};
