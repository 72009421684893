import React from 'react';
import { Row, Col } from 'react-grid-system';
import { pdf } from '@react-pdf/renderer';
import { Card, FormGroup, Button, RadioGroup, Radio, Spinner, Dialog, Classes, Collapse } from '@blueprintjs/core';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { IMeeting, IMeetingTopic } from 'src/api/models/Meeting';

import './index.scss';
import { AppToast } from 'src/components/Toasts/AppToast';
import { DatePicker } from 'src/components/DatePicker';
import { JobUserSelect2 as MultiJobUserSelect2 } from 'src/components/Selectors/JobUserSelect2';
import { MeetingTypeSelect } from 'src/components/Selectors/MeetingTypeSelect';
import { Topic } from './components/Topic';
import { createTopic, updateMeeting, publishMeeting } from 'src/api/meetings';
import Textfield from '@atlaskit/textfield';
import InlineEdit from '@atlaskit/inline-edit';
import { gridSize as getGridSize } from '@atlaskit/theme/constants';
import { css } from '@emotion/core';
import { PDFDocument } from '../components/PDFDocument';
import './pdf-template.scss';
import { getDocumentById } from '../../../../api/documents';
import { VersionTable } from '../../Documents/components/DocumentDetails/VersionTable';
import { IDocument } from '../../../../api/models/Document';

const gridSize = getGridSize();
const minRows = 2;
const textAreaLineHeightFactor = 2.5;

const fs = 24;
const readViewTitleContainerStyles = css({
    minHeight: `${gridSize * textAreaLineHeightFactor * minRows}px`,
    color: '#fff!important',
    fontSize: fs,
    padding: `${gridSize - 2}px ${gridSize - 2}px`,
    lineHeight: `${(gridSize * textAreaLineHeightFactor) / fs}`,
    wordBreak: 'break-word',
});

const validationSchema = Yup.object().shape({
    start_date: Yup.date().required().min(1, 'Start Date Required').label('Date'),
    end_date: Yup.date().required().min(1, 'Start Date Required').label('Date'),
});

const labelColumnCount = 2;

interface IMeetingFormProps {
    initialValues: IMeeting;
    onMeetingTypeUpdated: (type: string) => void;
    jobId: number;
}

export const MeetingForm: React.FC<IMeetingFormProps> = ({ initialValues, jobId, onMeetingTypeUpdated }) => {
    const history = useHistory();
    const [loading, setLoading] = React.useState(false);
    const [formDirty, setFormDirty] = React.useState(false);
    const [loaded, setLoaded] = React.useState(false);
    const [showLoader, setShowLoader] = React.useState(false);
    const [confirmPublish, setConfirmPublish] = React.useState(false);
    const [status, setStatus] = React.useState(initialValues.status);
    const [isPublishing, setIsPublishing] = React.useState(false);
    const [versions, setVersions] = React.useState<IDocument[]>([]);
    const [versionsCollapsed, setVersionsCollapsed] = React.useState(true);
    React.useEffect(() => {
        loadMeetingVersions();
    }, []);

    React.useEffect(() => {
        setShowLoader(true);
        if (!loading) {
            const handler = setTimeout(() => {
                setShowLoader(false);
            }, 1000);
            return () => {
                clearTimeout(handler);
            };
        }
    }, [loading]);

    const formik = useFormik({
        initialValues,
        validationSchema,
        validateOnMount: true,
        onSubmit: async (values, { setSubmitting }) => {
            setSubmitting(true);
            try {
                AppToast.show({ message: `Meeting minute created!`, intent: 'success', icon: 'tick' });
                history.push(`/jobs/${jobId}/meetings`);
            } catch (err) {
                AppToast.show({ message: `Error: ${err.toLocaleString()}`, intent: 'danger', icon: 'tick' });
            }
            setSubmitting(false);
        },
    });

    const { values, setFieldValue, dirty } = formik;

    React.useEffect(() => {
        if (!dirty || !loaded) return;
        setFormDirty(true);
    }, [
        values.title,
        values.start_date,
        values.end_date,
        values.admins,
        values.leads,
        values.attendees,
        values.invitees,
        values.custom_admins,
        values.custom_leads,
        values.custom_attendees,
        values.custom_invitees,
        values.meeting_type,
    ]);

    React.useEffect(() => {
        setLoaded(true);
        if (!formDirty) return;
        saveMeetingUpdates();
    }, [formDirty]);

    React.useEffect(() => {
        if (status === 'Draft') {
            saveMeetingUpdates();
        }
    }, [status]);

    const saveMeetingUpdates = async () => {
        setLoading(true);
        try {
            await updateMeeting(jobId, { ...values, status });
            setFormDirty(false);
        } catch (err) {
            AppToast.show({ message: `Error: ${err.toLocaleString()}`, intent: 'danger', icon: 'tick' });
        }
        setLoading(false);
    };

    const addTopic = async () => {
        setLoading(true);
        try {
            const result = await createTopic(jobId, values.id);
            setFieldValue('topics', [...values.topics, result]);
        } catch (err) {
            AppToast.show({ message: `Error: ${err.toLocaleString()}`, intent: 'danger', icon: 'tick' });
        }
        setLoading(false);
    };

    const onTopicChange = (topic: IMeetingTopic) => {
        const updatedTopics = values.topics.map((t) => {
            if (t.id === topic.id) return topic;
            return t;
        });
        setFieldValue('topics', updatedTopics);
    };

    const onTopicDeleted = (topic: IMeetingTopic) => {
        const updatedTopics = values.topics.filter((t) => t.id !== topic.id);
        setFieldValue('topics', updatedTopics);
    };

    const publishMeetingMinutes = async () => {
        setConfirmPublish(false);
        setIsPublishing(true);
        const blob = await pdf(
            <PDFDocument
                values={{
                    ...values,
                    status: status === 'Draft' ? 'Published' : 'Draft',
                }}
            />,
        ).toBlob();
        const result = await publishMeeting(values.job_id, values.id, blob);
        await setStatus(status === 'Draft' ? 'Published' : 'Draft');
        setIsPublishing(false);
        AppToast.show({ message: `Meeting Minutes Published`, intent: 'success', icon: 'tick' });
        setVersions([result, ...result.versions]);
    };

    const loadMeetingVersions = async () => {
        if (!values.export_id) return;
        const result = await getDocumentById(values.job_id, values.export_id);
        setVersions([result, ...result.versions]);
    };
    return (
        <>
            <Card className="m-b-15 p-relative">
                {showLoader && (
                    <div style={{ position: 'fixed', bottom: 15, left: 265, zIndex: 9999, backgroundColor: '#fff', padding: 10, width: 150, textAlign: 'center', borderRadius: 5 }}>
                        <div style={{ display: 'inline-block', marginRight: 15 }}>
                            <Spinner size={16} />
                        </div>
                        <div className="bp3-text-muted fs-18" style={{ display: 'inline-block' }}>
                            Saving...
                        </div>
                    </div>
                )}
                <Row className="m-b-25 m-l--20 m-r--20">
                    <Col>
                        <InlineEdit
                            defaultValue={values.title}
                            editView={({ errorMessage, ...fieldProps }, ref) => (
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                // @ts-ignore - textarea does not pass through ref as a prop
                                <Textfield {...fieldProps} ref={ref} autoComplete="off" />
                            )}
                            readView={() => (
                                <div css={readViewTitleContainerStyles} style={{ padding: 5, fontSize: 24, color: '#000' }}>
                                    {values.title || 'Click to add meeting title'}
                                </div>
                            )}
                            onConfirm={(val) => {
                                setFieldValue('title', val);
                                onMeetingTypeUpdated(val);
                            }}
                            readViewFitContainerWidth
                        />
                    </Col>
                </Row>
                <Row className="m-b-25 m-t-15">
                    <Col xs={labelColumnCount}>
                        <FormGroup label="Meeting Type" inline />
                    </Col>
                    <Col>
                        <MeetingTypeSelect
                            hideLabel
                            jobId={jobId}
                            onChange={(value) => {
                                setFieldValue('meeting_type', value);
                            }}
                            value={values.meeting_type}
                        />
                    </Col>
                </Row>

                <Row className="m-b-15">
                    <Col xs={labelColumnCount}>
                        <FormGroup label="Start Date" inline />
                    </Col>
                    <Col>
                        <DatePicker showNow inputClassname="bp3-large" onChange={(value) => setFieldValue('start_date', value)} value={values.start_date ? values.start_date : undefined} enableTime />
                    </Col>
                </Row>
                <Row className="m-b-15">
                    <Col xs={labelColumnCount}>
                        <FormGroup label="End Date" inline />
                    </Col>
                    <Col>
                        <DatePicker
                            showNow
                            label=""
                            inputClassname="bp3-large"
                            onChange={(value) => setFieldValue('end_date', value)}
                            value={values.end_date ? values.end_date : undefined}
                            enableTime
                        />
                    </Col>
                </Row>
                <Row className="m-b-15">
                    <Col xs={labelColumnCount}>
                        <FormGroup label="Administrators" inline />
                    </Col>
                    <Col>
                        <MultiJobUserSelect2
                            placeholder="Select admins..."
                            jobId={jobId}
                            hideLabel
                            value={values.admins}
                            customValues={values.custom_admins}
                            onCustomChange={(users) => {
                                if (users) {
                                    setFieldValue('custom_admins', users);
                                } else {
                                    setFieldValue('custom_admins', []);
                                }
                            }}
                            onChange={(value) => {
                                if (value) {
                                    setFieldValue('admins', value);
                                } else {
                                    setFieldValue('admins', []);
                                }
                            }}
                        />
                    </Col>
                </Row>
                <Row className="m-b-15">
                    <Col xs={labelColumnCount}>
                        <FormGroup label="Leads" inline />
                    </Col>
                    <Col>
                        <MultiJobUserSelect2
                            placeholder="Select leads..."
                            hideLabel
                            jobId={jobId}
                            value={values.leads}
                            customValues={values.custom_leads}
                            onCustomChange={(users) => {
                                if (users) {
                                    setFieldValue('custom_leads', users);
                                } else {
                                    setFieldValue('custom_leads', []);
                                }
                            }}
                            onChange={(value) => {
                                if (value) {
                                    setFieldValue('leads', value);
                                } else {
                                    setFieldValue('leads', []);
                                }
                            }}
                        />
                    </Col>
                </Row>
                <Row className="m-b-15">
                    <Col xs={labelColumnCount}>
                        <FormGroup label="Invitees" inline />
                    </Col>
                    <Col>
                        <MultiJobUserSelect2
                            placeholder="Select invitees..."
                            hideLabel
                            jobId={jobId}
                            value={values.invitees}
                            customValues={values.custom_invitees}
                            onCustomChange={(users) => {
                                if (users) {
                                    setFieldValue('custom_invitees', users);
                                } else {
                                    setFieldValue('custom_invitees', []);
                                }
                            }}
                            onChange={(users) => {
                                if (users) {
                                    setFieldValue('invitees', users);
                                } else {
                                    setFieldValue('invitees', []);
                                }
                            }}
                        />
                    </Col>
                </Row>
                <Row className="m-b-20">
                    <Col xs={labelColumnCount}>
                        <FormGroup label="Attendees" inline />
                    </Col>
                    <Col>
                        <MultiJobUserSelect2
                            placeholder="Select attendees..."
                            hideLabel
                            jobId={jobId}
                            value={values.attendees}
                            customValues={values.custom_attendees}
                            onCustomChange={(users) => {
                                if (users) {
                                    setFieldValue('custom_attendees', users);
                                } else {
                                    setFieldValue('custom_attendees', []);
                                }
                            }}
                            onChange={(users) => {
                                if (users) {
                                    setFieldValue('attendees', users);
                                } else {
                                    setFieldValue('attendees', []);
                                }
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={labelColumnCount}>
                        <FormGroup label="Status" inline />
                    </Col>
                    <Col>
                        <RadioGroup inline className="m-t-5 " disabled onChange={() => (status === 'Draft' ? setConfirmPublish(true) : setStatus('Draft'))} selectedValue={status}>
                            <Radio className="bp3-large" label="Draft" value="Draft" />
                            <Radio className="bp3-large" label="Published" value="Published" />
                        </RadioGroup>
                    </Col>
                </Row>
                <Row>
                    <Col className="text-center">
                        {status === 'Draft' ? (
                            <Button onClick={() => setConfirmPublish(true)} loading={isPublishing} intent="primary">
                                Publish Meeting Minutes
                            </Button>
                        ) : (
                            <Button onClick={() => setStatus('Draft')}>Move to Draft</Button>
                        )}
                    </Col>
                </Row>
            </Card>
            <Card className="m-b-20 p-b-0">
                <Row>
                    <Col>
                        <h3 className="m-t-0" style={{ display: 'inline-block' }}>
                            Published Versions
                        </h3>
                        <Button minimal className="m-l-5 m-t--3" icon={versionsCollapsed ? 'chevron-up' : 'chevron-down'} onClick={() => setVersionsCollapsed((prevState) => !prevState)} />
                        <Collapse isOpen={!versionsCollapsed}>
                            <Row>
                                <Col>
                                    <VersionTable emptyText="Meeting has not been published" documents={versions} jobId={values.job_id} selectedDocumentId={null} hasLatest />
                                </Col>
                            </Row>
                        </Collapse>
                    </Col>
                </Row>
            </Card>
            <Dialog usePortal={true} isOpen={confirmPublish} onClose={() => setConfirmPublish(false)} title="Publish Meeting Minutes">
                <div className={`${Classes.DIALOG_BODY}`}>
                    <h4 className="m-0">Are you sure? Once published, the meeting minutes will be versioned and a copy will be stored in documents.</h4>
                </div>
                <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Button onClick={() => setConfirmPublish(false)}>Close</Button>
                        <Button onClick={publishMeetingMinutes} intent="primary">
                            Publish
                        </Button>
                    </div>
                </div>
            </Dialog>
            <Row className="m-b-15">
                <Col>
                    {values.topics.map((topic) => {
                        return <Topic key={topic.id} topic={topic} onTopicChange={onTopicChange} onTopicDeleted={onTopicDeleted} />;
                    })}
                    <Button onClick={addTopic} fill large icon="add" intent="primary" minimal>
                        Add Topic
                    </Button>
                </Col>
            </Row>
        </>
    );
};
