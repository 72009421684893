import * as React from 'react';
import { cloneDeep } from 'lodash';
import { Row, Col } from 'react-grid-system';
import { IWBSResponse } from 'src/api/models/WBS';

import { useJobs } from '../../../../../hooks/useJobs';
import { loadJobWBS } from '../../../../../api/wbs';
import { TimesheetCode, NodePath } from 'src/api/models/timesheet';
import { Spinner, Tree, Button, TreeNodeInfo, Card } from '@blueprintjs/core';
import { Classes as Popover2Classes, ContextMenu2 } from '@blueprintjs/popover2';

function forEachNode(nodes: TreeNodeInfo[] | undefined, callback: (node: TreeNodeInfo) => void) {
    if (nodes === undefined) {
        return;
    }

    for (const node of nodes) {
        callback(node);
        forEachNode(node.childNodes, callback);
    }
}

function forNodeAtPath(nodes: TreeNodeInfo[], path: NodePath, callback: (node: TreeNodeInfo) => void) {
    callback(Tree.nodeFromPath(path, nodes));
}

type TreeAction =
    | { type: 'SET_NODES'; payload: { nodes: TreeNodeInfo[] } }
    | { type: 'SET_IS_EXPANDED'; payload: { path: NodePath; isExpanded: boolean } }
    | { type: 'DESELECT_ALL' }
    | { type: 'SET_IS_SELECTED'; payload: { path: NodePath; isSelected: boolean } };

function treeExampleReducer(state: TreeNodeInfo[], action: TreeAction) {
    switch (action.type) {
        case 'SET_NODES': {
            return action.payload.nodes;
        }
        case 'DESELECT_ALL': {
            const newState1 = cloneDeep(state);
            forEachNode(newState1, (node) => (node.isSelected = false));
            return newState1;
        }
        case 'SET_IS_EXPANDED': {
            const newState2 = cloneDeep(state);
            forNodeAtPath(newState2, action.payload.path, (node) => (node.isExpanded = action.payload.isExpanded));
            return newState2;
        }
        case 'SET_IS_SELECTED': {
            const newState3 = cloneDeep(state);
            forNodeAtPath(newState3, action.payload.path, (node) => (node.isSelected = action.payload.isSelected));
            return newState3;
        }
        default: {
            return state;
        }
    }
}

const contentSizing = { popoverProps: { popoverClassName: Popover2Classes.POPOVER2_CONTENT_SIZING } };

interface IChargeCodeSelectorProps {
    onChange: (codes: TimesheetCode[]) => void;
    initialValues: TimesheetCode[];
}

export const ChargeCodeSelector: React.FC<IChargeCodeSelectorProps> = ({ onChange, initialValues }) => {
    const { selectedJob } = useJobs();
    const [nodes, dispatch] = React.useReducer(treeExampleReducer, []);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState<string | null>(null);
    const [wbs, setWbs] = React.useState<IWBSResponse[]>([]);
    const [selected, setSelected] = React.useState<TimesheetCode[]>(initialValues);
    React.useEffect(() => {
        loadWBS();
    }, []);

    React.useEffect(() => {
        onChange(selected);
    }, [selected]);

    const loadWBS = async () => {
        if (!selectedJob) return;
        setLoading(true);
        setError(null);
        try {
            const data = await loadJobWBS(selectedJob.id);

            const info: TreeNodeInfo[] = data.map((level1) => {
                return {
                    id: level1.id,
                    hasCaret: true,
                    icon: 'folder-close',
                    isExpanded: true,
                    label: (
                        <ContextMenu2 {...contentSizing} content={<div>Hello there!</div>}>
                            {level1.name}
                        </ContextMenu2>
                    ),
                    childNodes: level1.subs.map((level2) => {
                        return {
                            id: level2.id,
                            hasCaret: true,
                            isExpanded: true,
                            icon: 'folder-close',
                            label: (
                                <ContextMenu2 {...contentSizing} content={<div>Hello there!</div>}>
                                    {level2.name}
                                </ContextMenu2>
                            ),
                            childNodes: level2.subs.map((level3) => {
                                return {
                                    id: level3.id,
                                    icon: 'caret-right',
                                    label: (
                                        <span>
                                            {level3.code} - {level3.description}
                                        </span>
                                    ),
                                };
                            }),
                        };
                    }),
                };
            });
            dispatch({
                payload: { nodes: info },
                type: 'SET_NODES',
            });
            setWbs(data);
        } catch (err) {
            setError(err.toLocaleString());
        }

        setLoading(false);
    };

    const handleNodeClick = React.useCallback(
        (node: TreeNodeInfo, nodePath: NodePath, e: React.MouseEvent<HTMLElement>) => {
            if (!e.shiftKey) {
                dispatch({ type: 'DESELECT_ALL' });
            }
            setSelected((prevState) => {
                const level1 = wbs[nodePath[0]];
                const level2 = level1.subs[nodePath[1]];
                const tmp = level2.subs[nodePath[2]];
                const newCode: TimesheetCode = {
                    pay_type: 'ST',
                    total: '',
                    cost_code_id: tmp.id,
                    level_1_id: level1.id,
                    level_2_id: level2.id,
                    path: nodePath,
                    label: `${wbs[nodePath[0]].name} / ${wbs[nodePath[0]].subs[nodePath[1]].name} / ${tmp.code} - ${tmp.description}`,
                    costCode: tmp,
                };
                return [...prevState, newCode];
            });
        },
        [wbs],
    );

    const handleNodeCollapse = React.useCallback((_node: TreeNodeInfo, nodePath: NodePath) => {
        dispatch({
            payload: { path: nodePath, isExpanded: false },
            type: 'SET_IS_EXPANDED',
        });
    }, []);

    const handleNodeExpand = React.useCallback((_node: TreeNodeInfo, nodePath: NodePath) => {
        dispatch({
            payload: { path: nodePath, isExpanded: true },
            type: 'SET_IS_EXPANDED',
        });
    }, []);

    const removeSelected = (item: TimesheetCode) => {
        setSelected((prevState) => {
            return prevState.filter((p) => p.cost_code_id !== item.cost_code_id);
        });
    };
    const renderSelected = () => {
        return selected.map((item) => {
            return (
                <Card key={item.cost_code_id} className="p-10 m-b-5">
                    <Row>
                        <Col xs={10}>
                            <div className="m-t-5">{item.label}</div>
                        </Col>
                        <Col className="text-right">
                            <Button icon="trash" onClick={() => removeSelected(item)} />
                        </Col>
                    </Row>
                </Card>
            );
        });
    };
    return (
        <>
            <h3 className="m-t-0">Select Cost Codes</h3>

            {error && <p className="text-center">{error}</p>}
            {loading ? (
                <div className="text-center p-t-50">
                    <Spinner />{' '}
                </div>
            ) : (
                <Row>
                    <Col>
                        <div className="m-b-10 bp3-text-small bp3-text-muted">Double click cost codes to add to timesheet</div>
                        <Tree contents={nodes} onNodeDoubleClick={handleNodeClick} onNodeCollapse={handleNodeCollapse} onNodeExpand={handleNodeExpand} />
                    </Col>
                    <Col xs={8}>
                        <div className="m-b-10 bp3-text-small bp3-text-muted">Selected Cost Codes</div>
                        {renderSelected()}
                    </Col>
                </Row>
            )}
        </>
    );
};
