import React from 'react';
import { Header } from '../../../components/Header';
import { Row, Col } from 'react-grid-system';
import { Button, Card } from '@blueprintjs/core';
import { PhaseDialog } from 'src/components/Dialogs/PhaseDialog';
import { IPhase, IChecklistItem } from 'src/api/models/Checklist';
import { listItems, listPhases, removePhase } from 'src/api/checklists';
import { BottomToast } from '../../../components/Toasts';
import { ChecklistItemDialog } from '../../../components/Dialogs/ChecklistItemDialog';
import { ChecklistItemTable } from 'src/components/Tables/ChecklistItemTable';
import { ConfirmPopover } from '../../../components/ConfirmPopover';

export const Checklists: React.FC = () => {
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [itemDialogOpen, setItemDialogOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState<string | null>(null);
    const [phases, setPhases] = React.useState<IPhase[]>([]);
    const [items, setItems] = React.useState<IChecklistItem[]>([]);
    const [selectedItem, setSelectedItem] = React.useState<IChecklistItem | null>(null);
    const [selectedPhaseId, setSelectedPhaseId] = React.useState<string | null>(null);

    React.useEffect(() => {
        loadPhases();
    }, [selectedPhaseId]);

    const loadPhases = async (load = true) => {
        setError(null);
        setLoading(load);
        try {
            const data = await listPhases();
            setPhases(data);
            const items = await listItems('default', { default: true, phase: selectedPhaseId });
            setItems(items);
        } catch (err) {
            setError(err.toLocaleString());
        }
        setLoading(false);
    };

    const remove = async (id: string) => {
        await removePhase(id);
        BottomToast.show({ message: 'Phase removed!', intent: 'success', icon: 'tick-circle' });
        await loadPhases(false);
    };

    const renderPhases = () => {
        return phases.map((phase) => {
            const isSelected = selectedPhaseId === phase.id;
            return (
                <Col xs={12} md={3} key={phase.id} className="m-b-15">
                    <Card
                        interactive
                        style={{ backgroundColor: isSelected ? '#e9e9e9' : '#fff' }}
                        onClick={() => (isSelected ? setSelectedPhaseId(null) : setSelectedPhaseId(phase.id))}
                        elevation={isSelected ? 3 : 0}
                    >
                        <h4 className="m-0 m-b-10">{phase.title}</h4>
                        <ConfirmPopover
                            contentTitle="Are you sure?"
                            confirmButtonIntent="danger"
                            contentBody={
                                <p>
                                    Are you sure you want to remove this phase? All items associated with <b>{phase.title}</b> will also be removed.
                                </p>
                            }
                            confirmButtonText="Delete Phase"
                            onConfirmClick={() => remove(phase.id)}
                        >
                            <Button small intent="danger">
                                Delete
                            </Button>
                        </ConfirmPopover>
                    </Card>
                </Col>
            );
        });
    };

    return (
        <>
            <Header
                title="Checklists"
                breadcrumbs={['Settings', 'Checklists']}
                actionComponent={
                    <>
                        <Button className="m-r-5" intent="primary" icon="updated" text="Add Item" onClick={() => setItemDialogOpen(true)} />
                        <Button intent="primary" icon="add" text="Add Phase" onClick={() => setDialogOpen(true)} />
                    </>
                }
            />
            <PhaseDialog isOpen={dialogOpen} onClose={() => setDialogOpen(false)} onCreateComplete={() => loadPhases(false)} />
            {error && <p>{error}</p>}
            <Row>{renderPhases()}</Row>
            <ChecklistItemTable data={items} onRowClick={(item) => setSelectedItem(item)} loading={loading} hideStatus />
            {selectedItem && (
                <ChecklistItemDialog
                    //selectedJobId={selectedJob.id}
                    data={selectedItem}
                    isOpen={selectedItem !== null}
                    onClose={() => setSelectedItem(null)}
                    onCreateComplete={() => loadPhases(false)}
                    onUpdateComplete={() => loadPhases(false)}
                />
            )}
            <ChecklistItemDialog
                //selectedJobId={selectedJob.id}
                isOpen={itemDialogOpen}
                onClose={() => setItemDialogOpen(false)}
                onCreateComplete={() => loadPhases(false)}
                onUpdateComplete={() => loadPhases(false)}
            />
        </>
    );
};
