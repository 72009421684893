import React from 'react';
import { Spinner } from '@blueprintjs/core';
import { useParams } from 'react-router-dom';
import { loadJobById } from 'src/api/jobs';
import { RoleBasedRoute } from '../../components/RoleBasedRoute';
import { Materials } from '../Materials';
import { Checklists } from './Checklists';
import { Team } from './Team';
import { Timesheets } from './Timesheets';
import { CreateTimesheet } from './Timesheets/Create';
import { Dailys } from './Dailys';
import { SubmitDaily } from './Dailys/SubmitDaily';
import { ViewDaily } from './Dailys/ViewDaily';
import { WBS } from './WBS';
import { ActivityFeed } from './ActivityFeed';
import { Plans } from './Plans';
import { Analysis } from './Analysis';
import { Installs } from './Installs';
import { Pieces } from './Pieces';
import { Summary } from './Summary';
import { Finance } from './Finance';
import { useJobs } from 'src/hooks/useJobs';
import { RFIs } from './RFIs';
import { ViewRFI } from './RFIs/ViewRFI';
import { SubmitRFI } from './RFIs/SubmitRFI';
import { Workflows } from './Workflows';
import { EditWorkflow } from './Workflows/EditWorkflow';
import { Submittals } from './Submittals';
import { SubmitSubmittal } from './Submittals/Submit';
import { ViewSubmittal } from './Submittals/View';
import { Weather } from './Weather';
import { RateSheet } from './RateSheet';
import { Tickets } from './Tickets';
import { CreateTicket } from './Tickets/CreateTicket';
import { EditTicket } from './Tickets/EditTicket';
import { Questions } from './Questions';
import { Changes } from './Changes';
import { CreateChange } from './Changes/CreateChange';
import { ViewChange } from './Changes/ViewChange';
import { JobTasksProvider } from 'src/hooks/useJobTasks';
import { ViewDrawing } from './Drawings/View';
import { SubmitDrawing } from './Drawings/Submit';
import { Drawings } from './Drawings';
import { EditTimesheetDraft } from './Timesheets/EditTimesheetDraft';
import { ViewTimesheet } from './Timesheets/View';
import { Documents } from './Documents';
import { MeetingMinutes } from './MeetingMinutes';
import { CreateMeetingMinutes } from './MeetingMinutes/Create';
import { ViewMeetingMinutes } from './MeetingMinutes/View';
import { ExportMeeting } from './MeetingMinutes/ExportMeeting';

interface ISettingsRoutes {
    requiredScopes: string[];
}

export const JobRouter: React.FC<ISettingsRoutes> = () => {
    const params: any = useParams();
    const { selectedJob, setSelectedJob, loading } = useJobs();
    const [error, setError] = React.useState<string | null>();
    React.useEffect(() => {
        loadJobData();
        return () => {
            setSelectedJob(null);
        };
    }, [params.id]);

    const loadJobData = async () => {
        setError(null);
        try {
            const jobData = await loadJobById(params.id);
            setSelectedJob(jobData);
        } catch (err) {
            setError(err.message);
        }
    };

    if (!selectedJob || loading) {
        return <Spinner />;
    }

    if (error) {
        return <p>{error}</p>;
    }

    return (
        <>
            <JobTasksProvider>
                <RoleBasedRoute
                    requiredScopes={['Admin:write:global', 'Admin:read:global']}
                    //requiredScopes={['Admin:write:global', 'Admin:read:global', 'admin:readOnly', 'Jobs.admin', 'Jobs:summary.read']}
                    exact
                    hide
                    path="/jobs/:id/workflows"
                    render={() => <Workflows />}
                />
                <RoleBasedRoute requiredScopes={['Admin:write:global', 'Admin:read:global']} exact path="/jobs/:id/timesheets" render={() => <Timesheets />} />
                <RoleBasedRoute requiredScopes={['Admin:write:global', 'Admin:read:global']} exact path="/jobs/:id/timesheets/create" render={() => <CreateTimesheet />} />
                <RoleBasedRoute requiredScopes={['Admin:write:global', 'Admin:read:global']} exact path="/jobs/:id/timesheets/drafts/:draftId" render={() => <EditTimesheetDraft />} />
                <RoleBasedRoute requiredScopes={['Admin:write:global', 'Admin:read:global']} exact path="/jobs/:id/timesheets/view/:timesheetId" render={() => <ViewTimesheet />} />
                <RoleBasedRoute allowAll requiredScopes={['All Users']} exact path="/jobs/:id/documents" render={() => <Documents />} />
                <RoleBasedRoute
                    requiredScopes={['Admin:write:global', 'Admin:read:global']}
                    //requiredScopes={['Admin:write:global', 'Admin:read:global', 'admin:readOnly', 'Jobs.admin', 'Jobs:summary.read']}
                    exact
                    hide
                    path="/jobs/:id/workflows/:workflowId"
                    render={() => <EditWorkflow />}
                />
                <RoleBasedRoute
                    allowAll
                    requiredScopes={['All Users']}
                    //requiredScopes={['Admin:write:global', 'Admin:read:global', 'admin:readOnly', 'Jobs.admin', 'Jobs:summary.read']}
                    exact
                    path="/jobs/:id/summary"
                    render={() => <Summary />}
                />
                <RoleBasedRoute allowAll requiredScopes={['All Users']} exact path="/jobs/:id/meetings/export/:meetingId" render={() => <ExportMeeting />} />
                <RoleBasedRoute allowAll requiredScopes={['All Users']} exact path="/jobs/:id/meetings/create" render={() => <CreateMeetingMinutes />} />
                <RoleBasedRoute allowAll requiredScopes={['All Users']} exact path="/jobs/:id/meetings/edit/:meetingId" render={() => <ViewMeetingMinutes />} />
                <RoleBasedRoute allowAll requiredScopes={['All Users']} exact path="/jobs/:id/meetings" render={() => <MeetingMinutes />} />
                <RoleBasedRoute
                    requiredScopes={['Admin:write:global', 'Admin:read:global', 'RFIs:read:global', 'RFIs:write:global', 'RFIs:read:jobs', 'RFIs:write:jobs']}
                    //requiredScopes={['Admin:write:global', 'Admin:read:global', 'admin:readOnly', 'Jobs.admin', 'Jobs:summary.read']}
                    exact
                    path="/jobs/:id/rfis"
                    render={() => <RFIs />}
                />

                <RoleBasedRoute
                    requiredScopes={['Admin:write:global', 'Admin:read:global', 'Drawings:read:global', 'Drawings:write:global', 'Drawings:read:jobs', 'Drawings:write:jobs']}
                    exact
                    path="/jobs/:id/drawings"
                    render={() => <Drawings />}
                />
                <RoleBasedRoute
                    requiredScopes={['Admin:write:global', 'Admin:read:global', 'Drawings:read:global', 'Drawings:write:global', 'Drawings:read:jobs', 'Drawings:write:jobs']}
                    exact
                    path="/jobs/:id/drawings/:drawingId"
                    render={() => <ViewDrawing />}
                />
                <RoleBasedRoute allowAll requiredScopes={['All Users']} exact hide path="/jobs/:id/drawings/submit/drawing" render={() => <SubmitDrawing />} />

                <RoleBasedRoute
                    requiredScopes={['Admin:write:global', 'Admin:read:global', 'Submittals:read:global', 'Submittals:write:global', 'Submittals:read:jobs', 'Submittals:write:jobs']}
                    //requiredScopes={['Admin:write:global', 'Admin:read:global', 'admin:readOnly', 'Jobs.admin', 'Jobs:summary.read']}
                    exact
                    path="/jobs/:id/submittals"
                    render={() => <Submittals />}
                />
                <RoleBasedRoute
                    requiredScopes={['Admin:write:global', 'Admin:read:global', 'Submittals:read:global', 'Submittals:write:global', 'Submittals:read:jobs', 'Submittals:write:jobs']}
                    //requiredScopes={['Admin:write:global', 'Admin:read:global', 'admin:readOnly', 'Jobs.admin', 'Jobs:summary.read']}
                    exact
                    path="/jobs/:id/submittals/:submittalId"
                    render={() => <ViewSubmittal />}
                />
                <RoleBasedRoute
                    allowAll
                    requiredScopes={['All Users']}
                    //requiredScopes={['Admin:write:global', 'Admin:read:global', 'admin:readOnly', 'Jobs.admin', 'Jobs:summary.read']}
                    exact
                    hide
                    path="/jobs/:id/submittals/submit/submittal"
                    render={() => <SubmitSubmittal />}
                />
                <RoleBasedRoute
                    allowAll
                    requiredScopes={['All Users']}
                    //requiredScopes={['Admin:write:global', 'Admin:read:global', 'admin:readOnly', 'Jobs.admin', 'Jobs:summary.read']}
                    exact
                    hide
                    path="/jobs/:id/changes/submit/change"
                    render={() => <CreateChange />}
                />
                <RoleBasedRoute
                    allowAll
                    requiredScopes={['All Users']}
                    //requiredScopes={['Admin:write:global', 'Admin:read:global', 'admin:readOnly', 'Jobs.admin', 'Jobs:summary.read']}
                    exact
                    hide
                    path="/jobs/:id/changes/view/:changeId"
                    render={() => <ViewChange />}
                />
                <RoleBasedRoute
                    allowAll
                    requiredScopes={['All Users']}
                    //requiredScopes={['Admin:write:global', 'Admin:read:global', 'admin:readOnly', 'Jobs.admin', 'Jobs:summary.read']}
                    exact
                    hide
                    path="/jobs/:id/rfis/submit/rfi"
                    render={() => <SubmitRFI />}
                />
                <RoleBasedRoute
                    requiredScopes={['Admin:write:global', 'Admin:read:global', 'RFIs:read:global', 'RFIs:write:global', 'RFIs:read:jobs', 'RFIs:write:jobs']}
                    //requiredScopes={['Admin:write:global', 'Admin:read:global', 'admin:readOnly', 'Jobs.admin', 'Jobs:summary.read']}
                    exact
                    path="/jobs/:id/rfis/:rfiId"
                    render={() => <ViewRFI />}
                />
                <RoleBasedRoute
                    requiredScopes={['Admin:write:global', 'Admin:read:global', 'Materials:read:global', 'Materials:write:global', 'Materials:read:jobs', 'Materials:write:jobs']}
                    exact
                    path="/jobs/:id/materials"
                    render={() => <Materials />}
                />
                <RoleBasedRoute
                    requiredScopes={['Admin:write:global', 'Admin:read:global', 'Tasks:read:global', 'Tasks:write:global', 'Tasks:read:jobs', 'Tasks:write:jobs']}
                    exact
                    path="/jobs/:id/checklists"
                    render={() => <Checklists />}
                />
                <RoleBasedRoute
                    requiredScopes={['Admin:write:global', 'Admin:read:global', 'Teams:read:global', 'Teams:write:global', 'Teams:read:jobs', 'Teams:write:jobs']}
                    exact
                    path="/jobs/:id/team"
                    render={() => <Team />}
                />
                <RoleBasedRoute
                    requiredScopes={['Admin:write:global', 'Admin:read:global', 'WBS:read:global', 'WBS:write:global', 'WBS:read:jobs', 'WBS:write:jobs']}
                    exact
                    path="/jobs/:id/wbs"
                    render={() => <WBS />}
                />
                <RoleBasedRoute
                    requiredScopes={['Admin:write:global', 'Admin:read:global', 'Notes:read:global', 'Notes:write:global', 'Notes:read:jobs', 'Notes:write:jobs']}
                    exact
                    path="/jobs/:id/activity"
                    render={() => <ActivityFeed />}
                />
                <RoleBasedRoute
                    requiredScopes={['Admin:write:global', 'Admin:read:global', 'Plans:read:global', 'Plans:write:global', 'Plans:read:jobs', 'Plans:write:jobs']}
                    exact
                    path="/jobs/:id/plans"
                    render={() => <Plans />}
                />
                <RoleBasedRoute
                    requiredScopes={['Admin:write:global', 'Admin:read:global', 'Analysis:read:global', 'Analysis:write:global', 'Analysis:read:jobs', 'Analysis:write:jobs']}
                    exact
                    path="/jobs/:id/analysis"
                    render={() => <Analysis />}
                />
                <RoleBasedRoute
                    requiredScopes={['Admin:write:global', 'Admin:read:global', 'Installs:read:global', 'Installs:write:global', 'Installs:read:jobs', 'Installs:write:jobs']}
                    exact
                    path="/jobs/:id/installs"
                    render={() => <Installs />}
                />
                <RoleBasedRoute
                    requiredScopes={['Admin:write:global', 'Admin:read:global', 'Pieces:read:global', 'Pieces:write:global', 'Pieces:read:jobs', 'Pieces:write:jobs']}
                    exact
                    path="/jobs/:id/pieces"
                    render={() => <Pieces />}
                />

                <RoleBasedRoute
                    requiredScopes={['Admin:write:global', 'Admin:read:global', 'Finance:read:global', 'Finance:write:global', 'Finance:read:jobs', 'Finance:write:jobs']}
                    exact
                    path="/jobs/:id/finance"
                    render={() => <Finance />}
                />
                <RoleBasedRoute
                    requiredScopes={['Admin:write:global', 'Admin:read:global']}
                    //requiredScopes={['Admin:write:global', 'Admin:read:global', 'admin:readOnly', 'Jobs.admin', 'Jobs:summary.read']}
                    exact
                    path="/jobs/:id/dailys"
                    render={() => <Dailys />}
                />
                <RoleBasedRoute
                    requiredScopes={['Admin:write:global', 'Admin:read:global']}
                    //requiredScopes={['Admin:write:global', 'Admin:read:global', 'admin:readOnly', 'Jobs.admin', 'Jobs:summary.read']}
                    exact
                    path="/jobs/:id/dailys/submit"
                    render={() => <SubmitDaily />}
                />
                <RoleBasedRoute
                    requiredScopes={['Admin:write:global', 'Admin:read:global']}
                    //requiredScopes={['Admin:write:global', 'Admin:read:global', 'admin:readOnly', 'Jobs.admin', 'Jobs:summary.read']}
                    exact
                    path="/jobs/:id/dailys/view/:dailyId"
                    render={() => <ViewDaily />}
                />
                <RoleBasedRoute
                    requiredScopes={['Admin:write:global', 'Admin:read:global']}
                    //requiredScopes={['Admin:write:global', 'Admin:read:global', 'admin:readOnly', 'Jobs.admin', 'Jobs:summary.read']}
                    exact
                    path="/jobs/:id/weather"
                    render={() => <Weather />}
                />
                <RoleBasedRoute
                    requiredScopes={['Admin:write:global', 'Admin:read:global']}
                    //requiredScopes={['Admin:write:global', 'Admin:read:global', 'admin:readOnly', 'Jobs.admin', 'Jobs:summary.read']}
                    exact
                    path="/jobs/:id/rates"
                    render={() => <RateSheet />}
                />
                <RoleBasedRoute
                    requiredScopes={['Admin:write:global', 'Admin:read:global']}
                    //requiredScopes={['Admin:write:global', 'Admin:read:global', 'admin:readOnly', 'Jobs.admin', 'Jobs:summary.read']}
                    exact
                    path="/jobs/:id/tickets"
                    render={() => <Tickets />}
                />
                <RoleBasedRoute
                    requiredScopes={['Admin:write:global', 'Admin:read:global']}
                    //requiredScopes={['Admin:write:global', 'Admin:read:global', 'admin:readOnly', 'Jobs.admin', 'Jobs:summary.read']}
                    exact
                    path="/jobs/:id/tickets/create"
                    render={() => <CreateTicket />}
                />
                <RoleBasedRoute
                    requiredScopes={['Admin:write:global', 'Admin:read:global']}
                    //requiredScopes={['Admin:write:global', 'Admin:read:global', 'admin:readOnly', 'Jobs.admin', 'Jobs:summary.read']}
                    exact
                    path="/jobs/:id/tickets/view/:ticketId"
                    render={() => <EditTicket />}
                />
                <RoleBasedRoute
                    requiredScopes={['Admin:write:global', 'Admin:read:global']}
                    //requiredScopes={['Admin:write:global', 'Admin:read:global', 'admin:readOnly', 'Jobs.admin', 'Jobs:summary.read']}
                    exact
                    path="/jobs/:id/questions"
                    render={() => <Questions />}
                />
                <RoleBasedRoute
                    requiredScopes={['Admin:write:global', 'Admin:read:global']}
                    //requiredScopes={['Admin:write:global', 'Admin:read:global', 'admin:readOnly', 'Jobs.admin', 'Jobs:summary.read']}
                    exact
                    path="/jobs/:id/changes"
                    render={() => <Changes />}
                />
            </JobTasksProvider>
        </>
    );
};
