import React from 'react';
import { Row, Col } from 'react-grid-system';
import { Button } from '@blueprintjs/core';
import { IUser } from 'src/api/models/User';
import { ITeamUser } from 'src/api/models/Job';
import { useJobs } from 'src/hooks/useJobs';
import { UserSelect } from 'src/components/Selectors/UserSelect/single';
import { addMemberToJobTeam } from 'src/api/jobs';

interface ITeamMemberSelector {
    onAddSuccess: (user: ITeamUser) => void;
    existingUsers: ITeamUser[];
}

export const TeamMemberSelector: React.FC<ITeamMemberSelector> = ({ onAddSuccess, existingUsers }) => {
    const { selectedJob } = useJobs();
    const [showSelector, setShowSelector] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState<string | null>(null);
    const [userToAdd, setUserToAdd] = React.useState<IUser | null>(null);

    const filterUserData = (options: IUser[]) => {
        return options.filter((o) => !existingUsers.find((user) => user.user_id === o.id));
    };

    const addUserToTeam = async () => {
        if (!selectedJob || !userToAdd) return;
        setLoading(true);
        setError(null);
        try {
            const result = await addMemberToJobTeam(selectedJob.id, userToAdd.id);
            onAddSuccess(result);
            setShowSelector(false);
            setUserToAdd(null);
        } catch (err) {
            setError(err.message);
        }
        setLoading(false);
    };
    const resetSelector = () => {
        setShowSelector(false);
        setUserToAdd(null);
    };
    return (
        <Row className="m-b-30">
            {showSelector ? (
                <>
                    <Col md={4}>
                        <UserSelect onChange={(user) => setUserToAdd(user)} value={userToAdd} hideLabel dataFilter={filterUserData} />
                    </Col>
                    <Col md={4}>
                        <Button intent="primary" loading={loading} onClick={addUserToTeam} className="medium-button" disabled={!userToAdd}>
                            Add To Team
                        </Button>
                        <Button minimal loading={loading} onClick={resetSelector} className="medium-button m-l-10">
                            Cancel
                        </Button>
                    </Col>
                    {error && <p>{error}</p>}
                </>
            ) : (
                <Col>
                    <Button onClick={() => setShowSelector(true)} className="medium-button">
                        Add Team Member
                    </Button>
                </Col>
            )}
        </Row>
    );
};
