import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { ISubmittal } from 'src/api/models/Submittal';
import { loadSubmittalById } from 'src/api/submittals';
import { Button, Spinner, Tab, TabId, Tabs } from '@blueprintjs/core';
import { IHistory } from 'src/api/models/History';
import { useJobs } from 'src/hooks/useJobs';
import { SubmittalForm } from '../components/SubmittalForm';
import { History } from '../../RFIs/components/History';
import { Header2 } from '../../../../components/Header2';

export const ViewSubmittal: React.FC = () => {
    const params: any = useParams();
    const history = useHistory();
    const [error, setError] = React.useState<string | null>(null);
    const [loading, setLoading] = React.useState(false);
    const [submittal, setSubmittal] = React.useState<ISubmittal | null>(null);
    const [tab, setTab] = React.useState<TabId>('submittal');
    const [submittalHistory, setSubmittalHistory] = React.useState<IHistory[]>([]);
    const { selectedJob } = useJobs();

    React.useEffect(() => {
        loadSubmittal();
    }, []);

    const loadSubmittal = async (load = true) => {
        if (!selectedJob) return;
        setLoading(load);
        setError(null);
        try {
            const data = await loadSubmittalById(selectedJob.id, params.submittalId);
            const d = {
                ...data,
                files_to_remove: [],
                files_to_add: [],
            };
            setSubmittal(d);
            setSubmittalHistory(data.history);
        } catch (err) {
            setError(err.toLocaleString());
        }
        setLoading(false);
    };
    const onSave = (data: ISubmittal) => {
        setSubmittalHistory(data.history);
    };

    if (!selectedJob) return null;
    if (loading) return <Spinner />;
    if (error) return <p>{error}</p>;
    if (!submittal) return <p>Error loading submittal</p>;
    return (
        <>
            <Header2
                breadcrumbs={[
                    { title: 'Jobs', link: '/jobs' },
                    { title: `${selectedJob?.name} - ${selectedJob?.job_number}`, link: `/jobs/${selectedJob?.id}/summary` },
                    { title: 'Submittals', link: `/jobs/${selectedJob.id}/submittals` },
                    { title: submittal?.submittal_number || 'No Submittal Number', link: '' },
                ]}
                actionComponent={
                    <Button intent="none" onClick={() => history.push(`/jobs/${selectedJob.id}/submittals`)}>
                        Cancel
                    </Button>
                }
            />
            <Tabs onChange={(tabId) => setTab(tabId)} selectedTabId={tab} large>
                <Tab id="submittal" title="Details" panel={<SubmittalForm initialValues={submittal} selectedJob={selectedJob} onSaved={onSave} onCreated={console.log} />} />
                <Tab id="history" title="History" panel={<History history={submittalHistory} />} />
                <Tabs.Expander />
            </Tabs>
        </>
    );
};
