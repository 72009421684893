import React from 'react';
import { Row, Col } from 'react-grid-system';
import { Button, Tabs, Tab, TabId, Checkbox, InputGroup } from '@blueprintjs/core';
import { RoleUserSelector } from '../RoleUserSelector';
import { FormGroupWithHelp } from 'src/components/FormGroupWithHelp';
import { IWorkflowStep } from 'src/api/models/Workflow';
import { ConfirmPopover } from 'src/components/ConfirmPopover';
import InlineEdit from '@atlaskit/inline-edit';
import { JobUserSelect } from 'src/components/Selectors/JobUserSelect';
import { ColorSelect } from '../../../Selectors/ColorSelect';

interface IEditDrawerProps {
    step: IWorkflowStep;
    onValueChange: any;
    onStepRemoved: (step: IWorkflowStep) => void;
    isTemplateEditor: boolean;
}

export const EditDrawer: React.FC<IEditDrawerProps> = ({ step, onValueChange, onStepRemoved, isTemplateEditor }) => {
    const [current, setCurrent] = React.useState(step);
    const [tab, setTab] = React.useState<TabId>('settings');
    const labelRef = React.useRef<any>(null);

    React.useEffect(() => {
        setCurrent(step);
    }, [step]);

    React.useLayoutEffect(() => {
        if (labelRef.current) {
            if (!step.is_first_step) {
                labelRef.current.select();
            }
        }
    }, [labelRef.current]);

    if (!step) return null;

    const removeStep = async () => {
        onStepRemoved(step);
    };
    const onFieldChange = (field: string, value: any) => {
        const updated = {
            ...current,
            [field]: value,
        };
        onValueChange(updated);
    };

    const onButtonTextUpdated = (val: any) => {
        onFieldChange('button_text', val);
    };
    return (
        <div className="workflow-drawer">
            <Tabs id="TabsExample" onChange={(tabId) => setTab(tabId)} selectedTabId={tab}>
                <Tab
                    id="settings"
                    title="General Settings"
                    panel={
                        <>
                            <h3 className="m-t-5 m-b-5">General Settings</h3>
                            <Row className="m-t-0 m-b-15">
                                <Col>
                                    <Row>
                                        <Col>
                                            <div className="m-b-15 inline-edit-with-label">
                                                <FormGroupWithHelp
                                                    className="m-b-0 m-t-10"
                                                    labelBottomMargin={10}
                                                    label="Button Text"
                                                    helpText="Text the user will see when transitioning to this status"
                                                />
                                                <InlineEdit
                                                    defaultValue={step.button_text}
                                                    editView={({ errorMessage, ...fieldProps }, ref) => <InputGroup inputRef={ref} {...fieldProps} />}
                                                    readView={() => <div className="read-only-inline-edit sub">{step.button_text || 'Click to enter a value'}</div>}
                                                    onConfirm={onButtonTextUpdated}
                                                    keepEditViewOpenOnBlur={false}
                                                    readViewFitContainerWidth
                                                />
                                            </div>
                                        </Col>
                                        <Col>
                                            <FormGroupWithHelp className="m-b-0 m-t-10" labelBottomMargin={10} label="Button Color" helpText="Color of the create button" />
                                            <ColorSelect onChange={(value) => onFieldChange('button_color', value)} value={step.button_color} hideLabel />
                                        </Col>
                                    </Row>

                                    {!step.is_external_step && (
                                        <Checkbox checked={current.allow_void} label="Allow void from status" onChange={(e: any) => onFieldChange('allow_void', e.target.checked)} />
                                    )}
                                    {!step.is_last_step && (
                                        <Checkbox checked={current.allow_reject} label="Allow reject to previous status" onChange={(e: any) => onFieldChange('allow_reject', e.target.checked)} />
                                    )}
                                </Col>
                            </Row>
                            {!current.is_external_step && !current.is_last_step && (
                                <>
                                    {' '}
                                    <h3 className="m-t-5">Assignments</h3>
                                    {!step.is_last_step && (
                                        <Checkbox
                                            className="m-b-15"
                                            checked={current.is_dynamic_user_assignment}
                                            label="Set assignment within form"
                                            onChange={(e: any) => onFieldChange('is_dynamic_user_assignment', e.target.checked)}
                                        />
                                    )}
                                    {!current.is_dynamic_user_assignment && (
                                        <Row>
                                            <Col>
                                                <FormGroupWithHelp label="Assign to Roles" helpText="Users with selected roles will be assigned items with this status. Leave blank if final step.">
                                                    <RoleUserSelector
                                                        field={'assigned_roles'}
                                                        initialUsers={current.excluded_assigned_users}
                                                        onUserSelect={(val) => onFieldChange('excluded_assigned_users', val)}
                                                        placeholder="Select assigned roles..."
                                                        initialSelectedRoles={current.assigned_roles}
                                                        selectedRoles={current.assigned_roles}
                                                        selectDescription="Select users to EXCLUDE from assigned roles"
                                                        onFieldChange={onFieldChange}
                                                    />
                                                </FormGroupWithHelp>
                                                <FormGroupWithHelp
                                                    hidden={isTemplateEditor}
                                                    label="Assign to Users"
                                                    helpText="Selected users will also be assigned items with this status regardless of user roles"
                                                >
                                                    <JobUserSelect onChange={(value) => onFieldChange('assigned_users', value)} value={current.assigned_users} hideLabel />
                                                </FormGroupWithHelp>
                                            </Col>
                                        </Row>
                                    )}
                                </>
                            )}
                            {current.is_external_step && <p>Items assigned to external client</p>}
                            {current.is_last_step && <p>Items with this status are considered complete</p>}
                        </>
                    }
                />
                <Tab
                    id="notifications"
                    title="Notifications"
                    panel={
                        <>
                            <h3 className="m-t-5">Notification Settings</h3>
                            <Checkbox checked={current.notify_assignees} label="Notify Assigned Users" onChange={(e: any) => onFieldChange('notify_assignees', e.target.checked)} />
                            <h3 className="m-t-25">Additional Notifications</h3>
                            <Row>
                                <Col>
                                    <FormGroupWithHelp label="Additional Roles to Notify" helpText={'Users with these roles will be notified when transitioned to this status'}>
                                        <RoleUserSelector
                                            onUserSelect={(val) => onFieldChange('excluded_notify_users', val)}
                                            initialUsers={current.excluded_notify_users}
                                            field={'notify_roles'}
                                            placeholder="Select roles to notify..."
                                            initialSelectedRoles={current.notify_roles}
                                            selectedRoles={current.notify_roles}
                                            selectDescription="Select users to EXCLUDE from notify roles"
                                            onFieldChange={onFieldChange}
                                        />
                                    </FormGroupWithHelp>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FormGroupWithHelp
                                        hidden={isTemplateEditor}
                                        label="Additional Users to Notify"
                                        helpText={'Selected users will be notified when transitioned to this status regardless of roles'}
                                    >
                                        <JobUserSelect onChange={(value) => onFieldChange('notify_users', value)} value={current.notify_users} hideLabel />
                                    </FormGroupWithHelp>
                                </Col>
                            </Row>
                        </>
                    }
                />
                <Tab
                    id="approval"
                    title="Approval"
                    panel={
                        <>
                            <h3 className="m-t-5">Approval Settings</h3>
                            <Checkbox
                                checked={current.approval_required}
                                label="Require Approval"
                                onChange={(e: any) => {
                                    onFieldChange('approval_required', e.target.checked);
                                }}
                            />
                            {current.approval_required && (
                                <>
                                    <Checkbox
                                        className="m-b-20"
                                        checked={current.signature_required}
                                        label="Require Electronic Signature"
                                        onChange={(e: any) => onFieldChange('signature_required', e.target.checked)}
                                    />
                                    <Row>
                                        <Col>
                                            <FormGroupWithHelp label="Approval" helpText={'Users with these roles can perform approval'}>
                                                <RoleUserSelector
                                                    onUserSelect={(val) => onFieldChange('excluded_approval_users', val)}
                                                    initialUsers={current.excluded_approval_users}
                                                    field={'approval_roles'}
                                                    placeholder="Select approval roles..."
                                                    initialSelectedRoles={current.approval_roles}
                                                    selectedRoles={current.approval_roles}
                                                    selectDescription="Select users to EXCLUDE from approval roles"
                                                    onFieldChange={onFieldChange}
                                                />
                                            </FormGroupWithHelp>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FormGroupWithHelp hidden={isTemplateEditor} label="Approval Users" helpText={'Selected users will be able to approve regardless of roles'}>
                                                <JobUserSelect onChange={(value) => onFieldChange('approval_users', value)} value={current.approval_users} hideLabel />
                                            </FormGroupWithHelp>
                                        </Col>
                                    </Row>
                                </>
                            )}
                        </>
                    }
                />
            </Tabs>
            {!current.is_locked && (
                <div className="m-t-20 text-right">
                    <ConfirmPopover
                        contentTitle="Are you sure you want to remove this step?"
                        contentBody={null}
                        confirmButtonText="Remove Step"
                        confirmButtonIntent="danger"
                        onConfirmClick={removeStep}
                        interactionKind="click"
                        position="right"
                    >
                        <Button className="m-r-15" intent="danger">
                            Remove Step
                        </Button>
                    </ConfirmPopover>
                </div>
            )}
        </div>
    );
};
