import React from 'react';
import { Tab, Tabs } from '@blueprintjs/core';
import { Header } from 'src/components/Header';

export const Operations: React.FC = () => {
    const [activeTab, setActiveTab] = React.useState<any>('summary');
    return (
        <>
            <Header title="Operations" breadcrumbs={['Operations', 'Summary']} />
            <div className="m-t-10">
                <Tabs id="JobView" onChange={(t) => setActiveTab(t)} selectedTabId={activeTab}>
                    <Tab id="summary" title="Summary" panel={<p>Summary</p>} />
                    <Tab id="dailys" title="Dailys" panel={<p>Dailys</p>} />
                    <Tab id="installs" title="Installs" panel={<p>Installs</p>} />
                    <Tab id="timesheets" title="Timesheets" panel={<p>Timesheets</p>} />
                    <Tabs.Expander />
                    <input className="bp3-input" type="text" placeholder="Search..." />
                </Tabs>
            </div>
        </>
    );
};
