import React from 'react';
import { TimesheetCode } from '../../../../../api/models/timesheet';
import { ITeamUser } from '../../../../../api/models/Job';
import { UserPanel } from './UserPanel';
import { WeekSelector } from '../WeekSelector';
import { IDateOption } from '../../types';
interface ITimesheetCardProps {
    costCodes: TimesheetCode[];
    people: ITeamUser[];
    onChange: (userId: number, timesheet: any[]) => void;
    onStartChange: (value: IDateOption) => void;
    startDate: IDateOption;
}

export const TimesheetCard: React.FC<ITimesheetCardProps> = ({ costCodes, people, onStartChange, startDate, onChange }) => {
    const renderUsers = () => {
        return people.map((person) => {
            return <UserPanel person={person} costCodes={costCodes} onChange={onChange} startDate={startDate} />;
        });
    };

    return (
        <>
            <WeekSelector value={startDate} onChange={onStartChange} />
            {renderUsers()}
        </>
    );
};
