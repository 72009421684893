import React from 'react';
import { Row, Col } from 'react-grid-system';
import { Card, InputGroup, Button, Spinner } from '@blueprintjs/core';
import { listJobQuestions, createJobQuestion, removeJobQuestion } from 'src/api/dailys';
import { IDailyQuestionResponse } from '../../../api/models/Daily';

interface IQuestionCardProps {
    jobId: number;
}

export const QuestionCard: React.FC<IQuestionCardProps> = ({ jobId }) => {
    const [loading, setLoading] = React.useState(false);
    const [saving, setSaving] = React.useState(false);
    const [removing, setRemoving] = React.useState(false);
    const [error, setError] = React.useState<string | null>(null);
    const [questions, setQuestions] = React.useState<IDailyQuestionResponse[]>([]);
    const [value, setValue] = React.useState('');

    React.useEffect(() => {
        loadQuestions();
    }, []);

    const loadQuestions = async (load = true) => {
        setLoading(load);
        setError(null);
        try {
            const data = await listJobQuestions(jobId);
            setQuestions(data);
        } catch (err) {
            setError(err.toLocaleString());
        }
        setLoading(false);
    };

    const createQuestion = async () => {
        setSaving(true);
        const q = {
            question: value,
        };
        await createJobQuestion(jobId, q);
        await loadQuestions(false);
        setValue('');
        setSaving(false);
    };

    const removeQuestion = async (q: IDailyQuestionResponse) => {
        setRemoving(true);
        await removeJobQuestion(jobId, q.id);
        await loadQuestions(false);
        setValue('');
        setRemoving(false);
    };

    const renderQuestions = () => {
        if (questions.length === 0) {
            return <p>No questions to display</p>;
        }
        return questions.map((q) => {
            return (
                <Row>
                    <Col xs={10}>
                        <p key={q.id} className="p-t-5">
                            {q.question}
                        </p>
                    </Col>
                    <Col className="text-right">
                        <Button intent="danger" icon="trash" minimal onClick={() => removeQuestion(q)} loading={removing} />
                    </Col>
                </Row>
            );
        });
    };
    return (
        <Card>
            {error && <p>{error}</p>}
            {loading ? (
                <div className="text-center p-t-50">
                    <Spinner />
                </div>
            ) : (
                <>
                    <h4 className="m-t-0">Daily Questions</h4>
                    <div style={{ minHeight: 300 }}>{renderQuestions()}</div>
                    <InputGroup
                        placeholder="Enter question..."
                        value={value}
                        onChange={(e) => setValue(e.target.value)}
                        rightElement={
                            <Button intent="primary" minimal onClick={createQuestion} disabled={value.length === 0} loading={saving}>
                                ADD
                            </Button>
                        }
                    />
                </>
            )}
        </Card>
    );
};
