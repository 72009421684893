import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

export const getMeetingTableColumns = (useLink = true) => {
    return [
        {
            field: 'title',
            headerName: 'Title',
            renderCell: (params: any) => {
                if (params.row.title === undefined) return '';
                if (useLink) {
                    return <Link to={`/jobs/${params.row.job_id}/meetings/edit/${params.row.id}`}>{params.row.title.length > 0 ? params.row.title : 'Untitled'}</Link>;
                }
                return params.row.title.length > 0 ? params.row.title : 'Untitled';
            },
            width: 200,
            // renderCell: (params: GridValueGetterParams) => {
            //     return <Link to={`/jobs/${params.row.id}/summary`}>{params.row.name}</Link>;
            // },
        },
        {
            field: 'meeting_type_name',
            headerName: 'Meeting Type',
            width: 200,
            // renderCell: (params: GridValueGetterParams) => {
            //     return <Link to={`/jobs/${params.row.id}/summary`}>{params.row.name}</Link>;
            // },
        },
        {
            field: 'status',
            headerName: 'Status',
            // renderCell: (params: GridValueGetterParams) => {
            //     return <Link to={`/jobs/${params.row.id}/summary`}>{params.row.name}</Link>;
            // },
        },
        {
            field: 'created_by',
            headerName: 'Created By',
            width: 200,
            renderCell: (params: any) => {
                return <span>{params.row.created_by ? params.row.created_by.full_name : ''}</span>;
            },
        },
        {
            field: 'start_date',
            headerName: 'Date',
            valueGetter: (params: any) => {
                return `${params.row.start_date ? moment(params.row.start_date).format('MM/DD/YYYY') : ''}`;
            },
        },
        {
            field: 'start_date_time',
            headerName: 'Start',
            renderCell: (params: any) => {
                return `${params.row.start_date ? moment(params.row.start_date).format('h:mm a') : ''}`;
            },
        },
        {
            field: 'end_date',
            headerName: 'End',
            renderCell: (params: any) => {
                return `${params.row.end_date ? moment(params.row.end_date).format('h:mm a') : ''}`;
            },
        },
    ];
};
