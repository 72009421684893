import React from 'react';
import { CreateJobDialog } from './CreateJobDialog';
import { AddUserDialog } from './AddUserDialog';
import { ScopeInfoDialog } from './ScopeInfoDialog';
import { CreateRoleDialog } from './CreateRoleDialog';
import { CreateOfficeDialog } from './CreateOfficeDialog';
import { CreateClientDialog } from './CreateClientDialog';
import { useJobs } from 'src/hooks/useJobs';
export const Dialogs: React.FC = () => {
    const { fetchJobs } = useJobs();
    return (
        <>
            <CreateJobDialog onCreateComplete={fetchJobs} />
            <AddUserDialog />
            <ScopeInfoDialog />
            <CreateRoleDialog />
            <CreateOfficeDialog />
            <CreateClientDialog />
        </>
    );
};
