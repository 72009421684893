import React from 'react';
import { FormGroup, Tag } from '@blueprintjs/core';
import { Col, Row } from 'react-grid-system';
import { Classes as TTClasses, Popover2 } from '@blueprintjs/popover2';

interface IScopeListProps {
    scopes: string[];
    hideLabel?: boolean;
    count?: number;
    renderTarget?: () => any;
    title?: string;
    description?: string;
    forceView?: boolean;
}
const defaultDescription = 'Roles define what scopes are assigned to a user. Scopes determine what the user is able view, read, write, and manage and can only be derived from roles.';

export const ScopeList: React.FC<IScopeListProps> = ({ scopes, description = defaultDescription, forceView = false, hideLabel = false, title = 'Scopes', count = 4, renderTarget }) => {
    const showScopes = scopes.slice(0, count);
    const hiddenScopes = scopes.slice(count);
    const scopeDiff = scopes.length - count;
    const renderTags = (items: string[]) => {
        return items.map((scope) => {
            return (
                <Tag key={scope} minimal className="m-r-5 m-t-5">
                    {' '}
                    {scope}
                </Tag>
            );
        });
    };

    return (
        <Row>
            <Col>
                <FormGroup label={hideLabel ? '' : 'Scopes'} labelFor="role-input" labelInfo={hideLabel ? '' : '(Derived from roles)'}>
                    {renderTags(showScopes)}
                    {(scopeDiff > 0 || forceView) && (
                        <>
                            <Popover2
                                minimal
                                interactionKind="hover"
                                popoverClassName={TTClasses.POPOVER2_CONTENT_SIZING}
                                enforceFocus={false}
                                position="right"
                                content={
                                    <>
                                        <h4 className="m-0 m-b-5">{title}</h4>
                                        <p>{description}</p>
                                        {renderTags(hiddenScopes)}
                                    </>
                                }
                            >
                                {renderTarget ? renderTarget() : <span>+{scopeDiff} more</span>}
                            </Popover2>
                        </>
                    )}
                </FormGroup>
            </Col>
        </Row>
    );
};
