import React from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { IWorkTicketResponse } from '../../../../api/models/WorkTicket';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';

interface ITicketsTableProps {
    tickets: IWorkTicketResponse[];
    loading?: boolean;
}

const columns: GridColDef[] = [
    {
        field: 'ticket_number',
        headerName: 'Ticket',
        width: 250,
    },
    {
        field: 'requester',
        headerName: 'Requested By',
        width: 150,
    },
    {
        field: 'date',
        headerName: 'Date Requested',
        width: 150,
        renderCell: (params: GridValueGetterParams) => {
            return moment(params.row.date).format('MM/DD/YYYY');
        },
    },
    {
        field: 'created_by',
        headerName: 'Created By',
        width: 150,
        renderCell: (params: GridValueGetterParams) => {
            return params.row.created_by.full_name;
        },
    },
    {
        field: 'status',
        headerName: 'Status',
        width: 150,
        renderCell: (params: GridValueGetterParams) => {
            return params.row.status;
        },
    },
    {
        field: 'summary',
        headerName: 'Summary',
        // renderCell: (params: GridValueGetterParams) => {
        //     return moment(params.row.date).format('MM/DD/YYYY');
        // },
    },
];

export const TicketsTable: React.FC<ITicketsTableProps> = ({ tickets, loading = true }) => {
    const history = useHistory();
    return (
        <div style={{ height: 600, width: '100%', backgroundColor: '#fff' }}>
            <DataGrid
                sx={{
                    '& .MuiDataGrid-cell': {
                        cursor: 'pointer',
                    },
                }}
                disableSelectionOnClick
                disableColumnSelector
                onCellClick={async (params) => {
                    history.push(`/jobs/${params.row.job_id}/tickets/view/${params.row.id}`);
                }}
                loading={loading}
                rows={tickets}
                columns={columns}
                pageSize={20}
                rowsPerPageOptions={[20]}
            />
        </div>
    );
};
