import React from 'react';
import { Row, Col } from 'react-grid-system';
import { Card, CardContent, Avatar, Dialog } from '@mui/material';
import { PostForm } from './components/PostForm';
import './index.scss';
import { useAuth } from 'src/hooks/useAuth';

interface PostCardProps {
    onPostCreated: () => void;
}
export const PostCard: React.FC<PostCardProps> = ({ onPostCreated }) => {
    const [postOpen, setPostOpen] = React.useState(false);
    const { user } = useAuth();
    const handleClose = () => {
        setPostOpen(false);
    };
    const handleOpen = () => {
        setPostOpen(true);
    };
    if (!user) return null;
    return (
        <>
            <Row justify="center" className="m-b-15">
                <Col xs={12} xl={6}>
                    <Card style={{ overflow: 'visible' }}>
                        <CardContent>
                            <Row>
                                <Col xs={1} className="text-center">
                                    <Avatar style={{ marginTop: 0 }} sx={{ bgcolor: '#015794' }}>
                                        {user.first_name.slice(0, 1)}
                                        {user.last_name.slice(0, 1)}
                                    </Avatar>
                                </Col>

                                <Col>
                                    <div className="mention-input-placeholder" onClick={handleOpen}>
                                        <span>What would you like to say, {user.full_name}?</span>
                                    </div>
                                </Col>
                            </Row>
                            {/*<Divider style={{ marginTop: 20, marginBottom: 20 }} />*/}
                        </CardContent>
                    </Card>
                </Col>
            </Row>
            <Dialog open={postOpen} onClose={handleClose} fullWidth aria-labelledby="draggable-dialog-title">
                <PostForm handleClose={handleClose} onPostCreated={onPostCreated} />
            </Dialog>
        </>
    );
};
