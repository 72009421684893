import React from 'react';
import { Row, Col } from 'react-grid-system';
import { NonIdealState } from '@blueprintjs/core';
import { ScopeList } from 'src/components/ScopeList';

interface IAccessDeniedProps {
    requiredScopes: string[];
}

export const AccessDenied: React.FC<IAccessDeniedProps> = ({ requiredScopes }) => {
    return (
        <Row className="m-t-20">
            <Col>
                <NonIdealState icon="blocked-person" title="Access Denied" description="You do not have the correct permissions to view this page.  Please request access from your administrator">
                    <div>
                        <h4>This page requires at least one of the following scopes:</h4>
                        <ScopeList scopes={requiredScopes} hideLabel />
                    </div>
                </NonIdealState>
            </Col>
        </Row>
    );
};
