import React from 'react';
import moment from 'moment';
import { ITopicIssue } from 'src/api/models/Meeting';
import Textfield from '@atlaskit/textfield';
import { Icon, Popover, Position, Menu, MenuItem } from '@blueprintjs/core';
import { Avatar, Chip } from '@mui/material';
import { AssignToPicker } from '../AssignToPicker';
import { OptionMenu } from '../OptionMenu';
import { IUser } from 'src/api/models/User';
import { AppToast } from 'src/components/Toasts/AppToast';
import { DatePicker, TimePrecision } from '@blueprintjs/datetime';
import useDebounce from 'src/hooks/useDebounce';

const PRIORITY_COLORS: any = {
    Low: undefined,
    Medium: 'warning',
    High: 'error',
};

interface TopicIssueProps {
    issue: ITopicIssue;
    selected: boolean;
    onAddCommentClick: () => void;
    onDeleteIssue: () => void;
    onIssueChange: (issue: ITopicIssue) => void;
    onAddIssue: () => void;
}

export const TopicIssue: React.FC<TopicIssueProps> = ({ issue, onIssueChange, onAddIssue, onDeleteIssue, onAddCommentClick, selected }) => {
    const [title, setTitle] = React.useState(issue.title);
    const debouncedTitle = useDebounce(title, 700);

    React.useEffect(() => {
        onIssueChange({
            ...issue,
            title,
        });
    }, [debouncedTitle]);

    const onAssigneeChange = (user: IUser | null) => {
        onIssueChange({
            ...issue,
            assignee: user,
        });
    };

    const onPriorityChange = (priority: 'Low' | 'Medium' | 'High') => {
        onIssueChange({
            ...issue,
            priority,
        });
    };

    const toggleComplete = () => {
        const newVal = !issue.complete;
        onIssueChange({
            ...issue,
            complete: !issue.complete,
        });
        if (newVal) {
            AppToast.show({ message: `Issue Resolved!`, intent: 'success', icon: 'tick' });
        }
    };

    const handleKeyDown = (e: any) => {
        if (e.key === 'Enter') {
            onAddIssue();
        }
    };

    const onDueDateChange = (date: Date) => {
        onIssueChange({
            ...issue,
            due_date: date,
        });
    };

    const isOverdue = issue.due_date && moment(issue.due_date).isBefore(moment()) && !issue.complete;
    return (
        <>
            <div className={`m-b-5 p-5 p-b-10 ${selected && 'selected-issue'}`} style={{ borderBottom: '1px solid #eee' }}>
                {issue.complete ? (
                    <Icon icon="tick-circle" intent="success" size={22} className="inline-input-check m-r-5 pointer" onClick={toggleComplete} />
                ) : (
                    <Icon icon="circle" intent="success" size={22} className="inline-input-check m-r-5 pointer" onClick={toggleComplete} />
                )}
                <Textfield
                    autoFocus
                    className={issue.complete ? 'inline-input-field complete' : 'inline-input-field'}
                    value={title}
                    onKeyDown={handleKeyDown}
                    placeholder="Description..."
                    onChange={(e: any) => {
                        setTitle(e.target.value);
                    }}
                />
                <AssignToPicker initialValue={issue.assignee} onChange={onAssigneeChange} />
                <OptionMenu onAddComment={onAddCommentClick} onDeleteIssue={onDeleteIssue} />
                {issue.assignee ? (
                    <Chip className="m-t-5 m-l-25" avatar={<Avatar>{issue.assignee.first_name.slice(0, 1)}</Avatar>} label={`Assigned to ${issue.assignee.full_name}`} size="small" />
                ) : (
                    <Chip className="m-t-5 m-l-30" label="Unassigned" size="small" />
                )}
                {issue.comments.length > 0 ? (
                    <Chip
                        className="m-t-5 m-l-5"
                        onClick={onAddCommentClick}
                        avatar={<Avatar>{issue.comments[issue.comments.length - 1].author.first_name.slice(0, 1)}</Avatar>}
                        label={`${issue.comments.length} comment${issue.comments.length === 1 ? '' : 's'}`}
                        size="small"
                    />
                ) : (
                    <Chip
                        className="m-t-5 m-l-5"
                        onClick={onAddCommentClick}
                        // color="#000"
                        label="No Comments"
                        size="small"
                    />
                )}
                <Popover
                    content={
                        <DatePicker
                            timePickerProps={{ useAmPm: true, showArrowButtons: true }}
                            value={issue.due_date ? moment(issue.due_date).toDate() : null}
                            timePrecision={TimePrecision.MINUTE}
                            shortcuts
                            onChange={onDueDateChange}
                        />
                    }
                    position={Position.LEFT}
                >
                    <Chip
                        className="m-t-5 m-l-5"
                        color={isOverdue ? 'error' : undefined}
                        onClick={console.log}
                        label={issue.due_date ? `Due on ${moment(issue.due_date).format('MM/DD/YYYY [at] h:mm a')}` : 'No Due Date'}
                        size="small"
                    />
                </Popover>
                <Popover
                    content={
                        <Menu>
                            <Menu.Divider title="Issue Priority" />
                            <MenuItem text="Low" icon={issue.priority === 'Low' ? 'tick' : undefined} onClick={() => onPriorityChange('Low')} />
                            <MenuItem text="Medium" icon={issue.priority === 'Medium' ? 'tick' : undefined} onClick={() => onPriorityChange('Medium')} />
                            <MenuItem text="High" icon={issue.priority === 'High' ? 'tick' : undefined} onClick={() => onPriorityChange('High')} />
                        </Menu>
                    }
                    position={Position.RIGHT}
                >
                    <Chip className="m-t-5 m-l-5" color={PRIORITY_COLORS[issue.priority]} onClick={console.log} label={`${issue.priority} Priority`} size="small" />
                </Popover>
            </div>
        </>
    );
};
