import React from 'react';
import ListItem from '@mui/material/ListItem';

import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ListItemText from '@mui/material/ListItemText';
import { Button, Intent } from '@blueprintjs/core';
import { CostCodeSelector } from 'src/components/Selectors/CostCodeSelector';
import { CostCodeResponse } from 'src/api/models/WBS';
import { assignedLevel3 } from '../../../../../api/wbs';
import { useJobs } from '../../../../../hooks/useJobs';

interface AddCodeButtonProps {
    parentId: string;
    onAdd: (code: CostCodeResponse) => void;
}

export const AddCodeButton: React.FC<AddCodeButtonProps> = ({ parentId, onAdd }) => {
    const { selectedJob } = useJobs();
    const [showForm, setShowForm] = React.useState(false);
    const [code, setCode] = React.useState<CostCodeResponse | undefined>(undefined);
    const [loading, setLoading] = React.useState(false);

    const reset = () => {
        setCode(undefined);
        setShowForm(false);
    };

    const createLevel = async () => {
        if (!selectedJob || !code) return;
        setLoading(true);
        const result = await assignedLevel3(selectedJob.id, parentId, code);
        onAdd(result);
        reset();
        setLoading(false);
    };

    const sx = 19.5;

    if (showForm) {
        return (
            <ListItem sx={{ ml: sx }}>
                <CostCodeSelector onChange={(data) => setCode(data)} />
                <Button className="m-l-5 m-r-5" intent={Intent.PRIMARY} onClick={createLevel} loading={loading}>
                    Add
                </Button>
                <Button onClick={() => setShowForm(false)}>Cancel</Button>
            </ListItem>
        );
    }
    return (
        <ListItemButton onClick={() => setShowForm(true)} sx={{ ml: sx }}>
            <ListItemIcon>
                <AddCircleOutlineIcon />
            </ListItemIcon>
            <ListItemText primary="Add Cost Code" />
        </ListItemButton>
    );
};
