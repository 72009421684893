import React from 'react';
import { MenuItem, Button } from '@blueprintjs/core';
import { ItemRenderer, Select, ItemPredicate } from '@blueprintjs/select';
import { IOrganizationClient } from 'src/api/models/Organization';
import { useOrganization } from 'src/hooks/useOrganization';

const ClientSelect = Select.ofType<IOrganizationClient>();

interface IClientSelectorProps {
    onChange: (client: IOrganizationClient | undefined) => void;
    initialValue?: IOrganizationClient | undefined;
}

export const ClientSelector: React.FC<IClientSelectorProps> = ({ onChange, initialValue = undefined }) => {
    const { clients } = useOrganization();
    const [selected, setSelected] = React.useState<IOrganizationClient | undefined>(initialValue);

    React.useEffect(() => {
        onChange(selected);
    }, [selected]);

    const onValueChange = (value: IOrganizationClient) => {
        setSelected(value);
    };

    const filterData: ItemPredicate<IOrganizationClient> = (query, role, _index, exactMatch) => {
        const normalizedTitle = role.name.toLowerCase();
        const normalizedQuery = query.toLowerCase();
        if (exactMatch) {
            return normalizedTitle === normalizedQuery;
        } else {
            return `${role.name}. ${normalizedTitle}`.indexOf(normalizedQuery) >= 0;
        }
    };

    const renderItem: ItemRenderer<IOrganizationClient> = (client, { modifiers, handleClick }) => {
        if (!modifiers.matchesPredicate) {
            return null;
        }
        return <MenuItem key={client.id} onClick={handleClick} text={client.name} active={selected ? client.id === selected.id : false} />;
    };

    return (
        <ClientSelect itemPredicate={filterData} itemRenderer={renderItem} items={clients} noResults={<MenuItem disabled={true} text="No results." />} onItemSelect={onValueChange}>
            <Button text={selected ? selected.name : 'Select customer...'} rightIcon="caret-down" icon="inherited-group" />
        </ClientSelect>
    );
};
