import React from 'react';
import { Loader } from './components/Loader';
import { AuthRouter, AppRouter, ForceResetRouter } from './routes/Routers';
import './styles/index.scss';
import { BrowserRouter as Router } from 'react-router-dom';
import { useAuth } from './hooks/useAuth';
import { ProjectsProvider } from './hooks/useProjects';
import { OrganizationProvider } from './hooks/useOrganization';
import { DialogsProvider } from './hooks/useDialogs';
import { LicenseInfo } from '@mui/x-license-pro';

LicenseInfo.setLicenseKey('5c193a1473460dd51d144a2c33e58028Tz00NDE3OSxFPTE2ODQ3MDgxNTM2MTIsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y');

function App(): JSX.Element {
    const { user, loadingUser } = useAuth();
    React.useEffect(() => {
        const existingScript = document.getElementById('google-maps-script');
        if (!existingScript) {
            const script = document.createElement('script');
            script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyDhjU5Z_9njYWssw5Q0yD2dtrEou_LTE6k&libraries=places';
            script.id = 'google-maps-script';
            script.async = true;

            document.body.appendChild(script);
        }
    }, []);
    return (
        <Loader loading={loadingUser}>
            <Router>
                <React.Suspense fallback={<div />}>
                    {user && user.status === 'confirmed' ? (
                        <>
                            <ProjectsProvider>
                                <OrganizationProvider>
                                    <DialogsProvider>
                                        <AppRouter />
                                    </DialogsProvider>
                                </OrganizationProvider>
                            </ProjectsProvider>
                        </>
                    ) : (
                        <>{user && user.status === 'invited' ? <ForceResetRouter /> : <AuthRouter />}</>
                    )}
                </React.Suspense>
            </Router>
        </Loader>
    );
}

export default App;
