import { IDefaultFolderPayload, IDefaultFolder } from './models/DefaultFolder';
import { getAuthOptions } from './helpers';
import AppApi from './index';
const api = AppApi.getApi();

export const getDefaultFolders = async (): Promise<IDefaultFolder[]> => {
    const url = `organization/folders`;
    const { data } = await api.get(url, await getAuthOptions());
    return data;
};

export const createDefaultFolder = async (folder: IDefaultFolderPayload): Promise<IDefaultFolder> => {
    const url = `organization/folders`;
    const { data } = await api.post(url, folder, await getAuthOptions());
    return data;
};

export const updateDefaultFolder = async (folderId: string, folder: IDefaultFolderPayload): Promise<IDefaultFolder> => {
    const url = `organization/folders/${folderId}`;
    const { data } = await api.put(url, folder, await getAuthOptions());
    return data;
};

export const removeDefaultFolder = async (folderId: string): Promise<IDefaultFolder> => {
    const url = `organization/folders/${folderId}`;
    const { data } = await api.delete(url, await getAuthOptions());
    return data;
};
