import React from 'react';
import { useOrganization } from 'src/hooks/useOrganization';
import Select from 'react-select';

interface IDivisionSelectorProps {
    onChange: (divisions: string[]) => void;
    initialValue?: string[];
}

interface Option {
    value: string;
    label: string;
}

export const DivisionSelector: React.FC<IDivisionSelectorProps> = ({ onChange, initialValue = [] }) => {
    const { divisions } = useOrganization();
    const [selected, setSelected] = React.useState<Option[]>(
        initialValue.map((d) => {
            return {
                value: d,
                label: d,
            };
        }),
    );

    React.useEffect(() => {
        const values = selected.map((o) => o.value);
        onChange(values);
    }, [selected]);

    const onValueChange = (value: Option[]) => {
        setSelected(value);
    };

    const options: Option[] = divisions.map((d) => {
        return {
            value: d,
            label: d,
        };
    });

    return (
        <>
            <Select
                isClearable
                isMulti
                classNamePrefix="react-select single-small"
                getOptionLabel={(o) => o.label}
                getOptionValue={(o) => o.value}
                value={selected}
                isSearchable
                options={options}
                closeMenuOnSelect={false}
                onChange={(value: any) => {
                    onValueChange(value);
                }}
            />
        </>
    );
};
