import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Spinner, Tabs, Tab, TabId } from '@blueprintjs/core';
import { useJobs } from 'src/hooks/useJobs';
import { IMeeting } from '../../../api/models/Meeting';
import { loadMeetings } from '../../../api/meetings';
import { AppToast } from '../../../components/Toasts/AppToast';
import { MeetingTable } from './components/MeetingsTable';
import { IssuesTable } from './components/IssuesTable';
import { Header2 } from '../../../components/Header2';
import './index.scss';

export const MeetingMinutes: React.FC = () => {
    const { selectedJob } = useJobs();
    const history = useHistory();
    const [loading, setLoading] = React.useState(false);
    const [meetings, setMeetings] = React.useState<IMeeting[]>([]);
    const [tab, setTab] = React.useState<TabId>('meetings');
    React.useEffect(() => {
        loadMeetingData();
    }, []);

    const loadMeetingData = async () => {
        if (!selectedJob) return;
        setLoading(true);
        try {
            const data = await loadMeetings(selectedJob.id);
            setMeetings(data);
        } catch (err) {
            AppToast.show({ message: `Error: ${err.toLocaleString()}`, intent: 'danger', icon: 'tick' });
        }
        setLoading(false);
    };

    const onMeetingClicked = (meeting: IMeeting) => {
        history.push(`/jobs/${meeting.job_id}/meetings/edit/${meeting.id}`);
    };

    if (loading) {
        return (
            <div className="text-center p-t-50">
                <Spinner />
            </div>
        );
    }
    if (!selectedJob) return null;
    return (
        <>
            <Header2
                breadcrumbs={[
                    { title: 'Jobs', link: '/jobs' },
                    { title: `${selectedJob?.name} - ${selectedJob?.job_number}`, link: `/jobs/${selectedJob?.id}/summary` },
                    { title: 'Meeting Minutes', link: '' },
                ]}
                actionComponent={
                    <Button icon="add" intent="primary" onClick={() => history.push(`/jobs/${selectedJob.id}/meetings/create`)}>
                        New Meeting
                    </Button>
                }
            />
            <Tabs onChange={(t) => setTab(t)} selectedTabId={tab} large renderActiveTabPanelOnly>
                <Tab
                    id="meetings"
                    title="Meeting Minutes"
                    panel={
                        <>
                            <MeetingTable meetings={meetings} isLoading={loading} onMeetingClicked={onMeetingClicked} selectedMeetingId={null} />
                        </>
                    }
                />
                <Tab
                    id="issues"
                    title="Issues"
                    panel={
                        <>
                            <IssuesTable jobId={selectedJob.id} />
                        </>
                    }
                />
            </Tabs>
        </>
    );
};
