import React from 'react';
import { Icon, Button } from '@blueprintjs/core';
import { Row, Col } from 'react-grid-system';
import { RoleCard } from 'src/components/RoleCard';
import { Header } from 'src/components/Header';
import { useOrganization } from 'src/hooks/useOrganization';
import { useDialogs } from 'src/hooks/useDialogs';
import './index.scss';

export const Roles: React.FC = () => {
    const { roles, onRoleDeleted } = useOrganization();
    const { setScopeInfoOpen, setCreateRoleDialogOpen } = useDialogs();
    return (
        <>
            <Header title="Roles" breadcrumbs={['Settings', 'Roles']} actionComponent={<Button intent="primary" icon="add" text="Create Role" onClick={() => setCreateRoleDialogOpen(true)} />} />
            <Row>
                <Col>
                    <h2 className="m-t-0">
                        Available Roles{' '}
                        <div className="title-help-icon">
                            <Icon icon="help" color="#5c7080" size={15} className="m-l-10 m-b-3" onClick={() => setScopeInfoOpen(true)} />
                        </div>
                    </h2>

                    <p>
                        A role provides access to pre-defined pages, data, menus, and features through the use of scopes. Scopes control what resources a user with a role can read, write, and/or
                        manage. Click{' '}
                        <a onClick={() => setScopeInfoOpen(true)}>
                            <b>HERE</b>
                        </a>{' '}
                        for more information on scopes.
                    </p>
                </Col>
            </Row>
            <Row>
                {roles.map((role) => {
                    return (
                        <Col sm={6} md={6} lg={6} xl={3} key={role.id} className="m-b-15 m-t-15">
                            <RoleCard role={role} onClick={(role) => setCreateRoleDialogOpen(true, role)} onRoleDeleted={(role) => onRoleDeleted(role)} />
                        </Col>
                    );
                })}
            </Row>

            {/*<ScopePicker scopeList={scopes} />*/}
        </>
    );
};
