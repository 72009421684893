import AppApi from './index';
import { getAuthOptions } from './helpers';
import { IRFI } from './models/RFI';
import { IAttachment } from './models/Attachment';
import { IJobWithChanges, IJobWithRFIs, IJobWithTimesheets } from './models/Job';

const api = AppApi.getApi();

export const calculateNextRFINumber = async (id: number): Promise<string> => {
    const url = `/jobs/${id}/rfis/next`;
    await api.get(url, await getAuthOptions());
    return '00001';
};

export const uploadTmpFile = async (file: File, isExternalUpload: boolean): Promise<IAttachment> => {
    const formData = new FormData();
    formData.append('file', file);
    const url = isExternalUpload ? `/attachments/upload/tmp` : `/rfis/upload/tmp`;
    const authOptions = await getAuthOptions();
    const options = {
        ...authOptions,
        headers: {
            ...authOptions.headers,
            'Content-Type': 'multipart/form-data',
        },
    };
    const result = await api.post(url, formData, options);
    return result.data;
};

export const loadRFIById = async (jobId: number, rfiId: string): Promise<IRFI> => {
    const url = `/jobs/${jobId}/rfis/${rfiId}`;
    const result = await api.get(url, await getAuthOptions());
    return result.data;
};

export const loadAllRfis = async (): Promise<IJobWithRFIs[]> => {
    const url = `/rfis`;
    const result = await api.get(url, await getAuthOptions());
    return result.data;
};

export const loadAllTimesheets = async (): Promise<IJobWithTimesheets[]> => {
    const url = `/timesheets`;
    const result = await api.get(url, await getAuthOptions());
    return result.data;
};

export const loadAllChanges = async (): Promise<IJobWithChanges[]> => {
    const url = `/changes`;
    const result = await api.get(url, await getAuthOptions());
    return result.data;
};

export const loadJobRfis = async (jobId: number): Promise<IRFI[]> => {
    const url = `/jobs/${jobId}/rfis`;
    const result = await api.get(url, await getAuthOptions());
    return result.data;
};

export const saveRfi = async (jobId: number, data: IRFI): Promise<IRFI> => {
    const url = `/jobs/${jobId}/rfis`;
    const result = await api.post(url, data, await getAuthOptions());
    return result.data;
};
