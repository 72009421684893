import React from 'react';
import { MenuItem, TagProps } from '@blueprintjs/core';
import { ItemRenderer, MultiSelect, ItemPredicate } from '@blueprintjs/select';
import { useOrganization } from 'src/hooks/useOrganization';

const OfficeSelect = MultiSelect.ofType<string>();

interface IDivisionSelectorProps {
    onChange: (divisions: string[]) => void;
    initialValue?: string[];
}

export const DivisionSelector: React.FC<IDivisionSelectorProps> = ({ onChange, initialValue = [] }) => {
    const { divisions } = useOrganization();
    const [selected, setSelected] = React.useState<string[]>(initialValue);

    React.useEffect(() => {
        onChange(selected);
    }, [selected]);

    const onValueChange = (value: string) => {
        const isSelected = isValueSelected(value);
        if (!isSelected) {
            setSelected((prev) => [...prev, value]);
        } else {
            deselectValue(value);
        }
    };

    const deselectValue = (value: string) => {
        setSelected((prev) => prev.filter((p) => p !== value));
    };

    const getTagProps = (_value: React.ReactNode): TagProps => ({
        intent: 'none',
        minimal: true,
    });

    const isValueSelected = (value: string) => {
        return !!selected.find((s) => s === value);
    };

    const filterData: ItemPredicate<string> = (query, div, _index, exactMatch) => {
        const normalizedTitle = div.toLowerCase();
        const normalizedQuery = query.toLowerCase();
        if (exactMatch) {
            return normalizedTitle === normalizedQuery;
        } else {
            return `${div}. ${normalizedTitle}`.indexOf(normalizedQuery) >= 0;
        }
    };

    const renderTag = (div: string) => div;

    const renderItem: ItemRenderer<string> = (div, { modifiers, handleClick }) => {
        if (!modifiers.matchesPredicate) {
            return null;
        }
        return <MenuItem icon={isValueSelected(div) ? 'tick' : 'blank'} key={div} onClick={handleClick} text={div} />;
    };

    return (
        <OfficeSelect
            placeholder="Select divisions..."
            selectedItems={selected}
            itemPredicate={filterData}
            //itemListRenderer={itemListRenderer}
            fill
            tagRenderer={renderTag}
            tagInputProps={{
                tagProps: getTagProps,
            }}
            itemRenderer={renderItem}
            items={divisions}
            noResults={<MenuItem disabled={true} text="No results." />}
            onItemSelect={onValueChange}
            onRemove={deselectValue}
        />
    );
};
