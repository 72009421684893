import React from 'react';
import { useHistory } from 'react-router-dom';
import { useJobs } from 'src/hooks/useJobs';
import { Button } from '@blueprintjs/core';
import { TimesheetForm } from '../components/TimesheetForm';
import { Header2 } from '../../../../components/Header2';

export const CreateTimesheet: React.FC = () => {
    const { selectedJob } = useJobs();
    const history = useHistory();
    if (!selectedJob) return null;
    return (
        <>
            <Header2
                breadcrumbs={[
                    { title: 'Jobs', link: '/jobs' },
                    { title: `${selectedJob?.name} - ${selectedJob?.job_number}`, link: `/jobs/${selectedJob?.id}/summary` },
                    { title: 'Timesheets', link: `/jobs/${selectedJob.id}/timesheets` },
                    { title: 'Create Timesheet', link: '' },
                ]}
                actionComponent={<Button onClick={() => history.push(`/jobs/${selectedJob.id}/timesheets`)}>Cancel</Button>}
            />
            <TimesheetForm />
        </>
    );
};
