import React from 'react';
import { FormGroup, Tag } from '@blueprintjs/core';
import { Header } from 'src/components/Header';
import { useAuth } from 'src/hooks/useAuth';
import { ScopeList } from 'src/components/ScopeList';

export const Permissions: React.FC = () => {
    const { scopes, user } = useAuth();
    const renderRoles = () => {
        return user?.roles.map((r) => (
            <Tag key={r.id} minimal className="m-r-5" intent="primary">
                {r.name}
            </Tag>
        ));
    };

    const renderOffices = () => {
        return user?.offices.map((r) => (
            <Tag key={r.id} minimal className="m-r-5" intent="primary">
                {r.name}
            </Tag>
        ));
    };
    return (
        <>
            <Header title="Permissions" />
            <FormGroup label="Roles" labelFor="role-input">
                {renderRoles()}
            </FormGroup>
            <ScopeList scopes={scopes} />
            <FormGroup label="Offices" labelFor="role-input">
                {renderOffices()}
            </FormGroup>
        </>
    );
};
