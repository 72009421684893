import React from 'react';
import { Classes, Button, FormGroup, InputGroup, Intent, Colors, TextArea } from '@blueprintjs/core';
import { Row, Col } from 'react-grid-system';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { createOrganizationClient, updateOrganizationClient } from 'src/api/organization';
import { IOrganizationClient } from 'src/api/models/Organization';
import { useOrganization } from 'src/hooks/useOrganization';
import { BottomToast } from 'src/components/Toasts';

const validationSchema = Yup.object().shape({
    name: Yup.string().required().min(1, 'Name Required').label('Name'),
});

const initialValues: Pick<IOrganizationClient, 'id' | 'name' | 'description'> = {
    id: -1,
    name: '',
    description: '',
};

interface ICreateRoleForm {
    selectedClient?: IOrganizationClient;
    setCreateContactDialogOpen: (isOpen: boolean, client?: IOrganizationClient) => void;
}

export const CreateContactForm: React.FC<ICreateRoleForm> = ({ selectedClient, setCreateContactDialogOpen }) => {
    const { onClientCreated, onClientUpdated } = useOrganization();
    const [error, setError] = React.useState<string | null>(null);
    const closeDialog = () => {
        setCreateContactDialogOpen(false);
        formik.resetForm();
    };
    const formik = useFormik({
        initialValues: selectedClient ? selectedClient : initialValues,
        validationSchema,
        validateOnMount: true,
        onSubmit: async (values) => {
            setError(null);
            try {
                if (selectedClient) {
                    const result = await updateOrganizationClient(values);
                    onClientUpdated(result);
                    setCreateContactDialogOpen(false);
                    BottomToast.show({ message: 'Client successfully updated!', intent: 'success', icon: 'updated' });
                } else {
                    const result = await createOrganizationClient(values);
                    onClientCreated(result);
                    setCreateContactDialogOpen(false);
                    BottomToast.show({ message: 'Client successfully created!', intent: 'success', icon: 'tick-circle' });
                }
            } catch (err) {
                setError(err.message);
                BottomToast.show({ message: err.message, intent: 'danger', icon: 'warning-sign' });
            }
        },
    });

    const handleKeyDown = (e: any) => {
        if (e.key === 'Enter') {
            formik.handleSubmit();
        }
    };
    return (
        <>
            <div className={Classes.DIALOG_BODY}>
                <Row>
                    <Col>
                        <FormGroup label="Client Name" labelFor="client-name-input" labelInfo="*">
                            <InputGroup
                                autoFocus
                                autoComplete="off"
                                id="client-name-input"
                                placeholder="Client name..."
                                value={formik.values.name}
                                onKeyDown={handleKeyDown}
                                onChange={(e) => formik.setFieldValue('name', e.target.value)}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup label="Description">
                            <TextArea
                                placeholder="Enter a description for this client..."
                                fill
                                growVertically={true}
                                value={formik.values.description}
                                onChange={(e) => formik.setFieldValue('description', e.target.value)}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                {error && (
                    <p className="m-b-0" style={{ color: Colors.RED5 }}>
                        {error}
                    </p>
                )}
            </div>
            <div className="m-b-20">
                <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Button onClick={closeDialog}>Cancel</Button>
                        <Button onClick={() => formik.handleSubmit()} intent={Intent.PRIMARY} loading={formik.isSubmitting} disabled={!formik.isValid || !formik.dirty}>
                            {selectedClient ? 'Save Changes' : 'Create Client'}
                        </Button>
                    </div>
                </div>
            </div>
        </>
    );
};
