import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Icon, IconName } from '@blueprintjs/core';
import { SideNavigation, Section, NestingItem, NestableNavigationContent, ButtonItem, NavigationFooter } from '@atlaskit/side-navigation';
import { useAuth } from 'src/hooks/useAuth';
import { intersection } from 'lodash';
import { AppSelectorDialog } from './AppSelectorDialog';
import { UserAppOption } from '../../../api/models/User';

interface INavigationItem {
    id: string;
    title: string;
    path: string;
    icon: IconName;
    subs?: INavigationItem[];
    pathSuffix?: string;
}

const MENU_ITEMS: INavigationItem[] = [
    {
        id: 'ctc',
        title: 'CTC',
        path: '/ctc',
        icon: 'dollar',
    },
    {
        id: 'jobs',
        title: 'Jobs',
        path: '/jobs',
        icon: 'folder-close',
        pathSuffix: '/info',
    },
];

const adminScopes = ['Admin:write:global', 'Admin:read:global'];

export const Main: React.FC = () => {
    const history = useHistory();
    const location = useLocation();
    const { scopes, apps, setApps } = useAuth();
    const [appSelectorOpen, setAppSelectorOpen] = React.useState(false);
    const isAdmin = React.useMemo(() => intersection(adminScopes, scopes).length > 0, [scopes]);

    const buttonCssFn = (props: any) => {
        return {
            color: props.isSelected ? '#004677' : '#42536e',
            backgroundColor: props.isSelected ? '#ebecf0' : '#fff',
        };
    };

    const appsButtonCssFn = (props: any) => {
        return {
            color: props.isSelected ? '#004677' : '#42536e',
            backgroundColor: props.isSelected ? '#ebecf0' : '#f8f8f8',
        };
    };

    const openAppSelectorDialog = () => {
        console.log('opening: ');
        setAppSelectorOpen(true);
    };
    const closeAppSelectorDialog = () => {
        setAppSelectorOpen(false);
    };

    const onAppsSaved = (myApps: UserAppOption[]) => {
        setApps(myApps);
        setAppSelectorOpen(false);
    };
    const renderItems = (menuItems: INavigationItem[]) => {
        return menuItems.map((item) => {
            const isSelected = location.pathname === item.path;
            if (!item.subs) {
                return (
                    <Section key={item.id}>
                        <ButtonItem iconBefore={<Icon icon={item.icon} />} onClick={() => history.push(`${item.path}`)} isSelected={isSelected} cssFn={buttonCssFn}>
                            {item.title}
                        </ButtonItem>
                    </Section>
                );
            }
            return (
                <Section key={item.id}>
                    <NestingItem
                        id={item.id}
                        title={item.title}
                        iconBefore={<Icon icon={item.icon} />}
                        onClick={() => history.push(`${item.path}`)}
                        isSelected={location.pathname.includes(item.id)}
                        cssFn={buttonCssFn}
                    >
                        {item.subs && (
                            <Section title={item.title} key={`${item.id}-${item.title}`}>
                                {item.subs.map((s) => {
                                    return (
                                        <ButtonItem
                                            key={s.id}
                                            iconBefore={<Icon icon={s.icon} />}
                                            onClick={() => history.push(`${s.path}`)}
                                            isSelected={location.pathname.includes(s.id)}
                                            cssFn={buttonCssFn}
                                        >
                                            {s.title}
                                        </ButtonItem>
                                    );
                                })}
                            </Section>
                        )}
                    </NestingItem>
                </Section>
            );
        });
    };

    return (
        <SideNavigation label="project">
            <NestableNavigationContent initialStack={[]}>
                <Section hasSeparator title="Organization">
                    {renderItems(MENU_ITEMS)}
                </Section>
                <Section hasSeparator title="My Apps">
                    {renderItems(apps)}
                    <Section hasSeparator={apps.length > 0}>
                        <ButtonItem iconBefore={<Icon icon="grid-view" />} cssFn={appsButtonCssFn} onClick={openAppSelectorDialog}>
                            EDIT MY APPS
                        </ButtonItem>
                    </Section>
                    <AppSelectorDialog isOpen={appSelectorOpen} onClose={closeAppSelectorDialog} onSave={onAppsSaved} initialApps={apps} />
                </Section>
            </NestableNavigationContent>
            <NavigationFooter>
                {isAdmin && (
                    <ButtonItem isSelected={location.pathname === '/settings'} cssFn={buttonCssFn} iconBefore={<Icon icon="cog" />} onClick={() => history.push('/settings')}>
                        Admin Settings
                    </ButtonItem>
                )}

                {/*<Footer cssFn={buttonCssFn}>Firetrol Protection Systems</Footer>*/}
            </NavigationFooter>
        </SideNavigation>
    );
};
