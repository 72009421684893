import React from 'react';
import { Button, Callout, Tag, Spinner, Icon } from '@blueprintjs/core';
import { Tooltip2 } from '@blueprintjs/popover2';
import { Row, Col } from 'react-grid-system';
import { Header } from 'src/components/Header';
import { IUser } from 'src/api/models/User';
import { FiretrolTable } from 'src/components/FiretrolTable';
import { useDialogs } from 'src/hooks/useDialogs';
import { useOrganization } from 'src/hooks/useOrganization';

const iconLookup: any = {
    invited: 'offline',
    confirmed: 'endorsed',
    disabled: 'ban-circle',
};

const intentLookup: any = {
    invited: 'warning',
    confirmed: 'success',
    disabled: 'danger',
};

const getColumns = (setAddUserOpen: (isOpen: boolean, user?: IUser) => void) => {
    return [
        {
            name: 'Name',
            format: (user: IUser) => `${user.first_name} ${user.last_name}`,
            selector: (user: IUser) => `${user.first_name} ${user.last_name}`,
            sortable: true,
        },
        {
            name: 'Email',
            selector: (user: IUser) => user.email,
            sortable: true,
        },
        {
            name: 'Status',
            format: (user: any) => {
                return (
                    <Tooltip2 position="right" content={<span style={{ textTransform: 'capitalize' }}>{user.status}</span>}>
                        <Icon icon={iconLookup[user.status] || 'dot'} intent={intentLookup[user.status]} className="m-l-15" />
                    </Tooltip2>
                );
            },
            selector: (user: IUser) => user.status,
            sortable: true,
        },
        {
            name: 'Offices',
            format: (user: IUser) =>
                user.offices.map((r) => (
                    <Tag key={r.id} minimal intent="primary" className="m-r-5">
                        {r.name}
                    </Tag>
                )),
            selector: (user: IUser) => user.id,
        },
        {
            name: 'Roles',
            format: (user: IUser) =>
                user.roles.map((r) => (
                    <Tag key={r.id} minimal intent="primary" round className="m-r-5">
                        {r.name}
                    </Tag>
                )),
            selector: (user: IUser) => user.id,
        },
        {
            name: '',
            format: (user: IUser) => <Button text="Edit" small minimal intent="primary" onClick={() => setAddUserOpen(true, user)} />,
            selector: (user: IUser) => user.id,
        },
    ];
};

export const Users: React.FC = () => {
    const { setAddUserOpen } = useDialogs();
    const { users, loading, refreshUsers } = useOrganization();
    React.useEffect(() => {
        refreshUsers();
    }, []);
    //const [loading] = React.useState(false);
    const [error] = React.useState<string | null>(null);
    const columns = React.useMemo(() => getColumns(setAddUserOpen), [setAddUserOpen]);
    return (
        <>
            <Header title="Users" breadcrumbs={['Settings', 'Users']} actionComponent={<Button text="Invite User" onClick={() => setAddUserOpen(true)} intent="primary" icon="new-person" />} />
            {error && (
                <Row className="m-b-15">
                    <Col>
                        <Callout intent="danger" title="Error Loading Users">
                            Error doing something
                        </Callout>
                    </Col>
                </Row>
            )}
            <Row className="m-b-15">
                <Col>{loading ? <Spinner /> : <FiretrolTable columns={columns} data={users} />}</Col>
            </Row>
        </>
    );
};
