import React from 'react';
import { Button, Card, Spinner, Dialog } from '@blueprintjs/core';
import { Row, Col } from 'react-grid-system';
import { IDefaultFolder } from 'src/api/models/DefaultFolder';
import { getDefaultFolders, removeDefaultFolder } from 'src/api/folders';
import { Header } from '../../../components/Header';
import { CreateFolderDialog } from './CreateFolderDialog';
import { FolderTree } from './FolderTree';

export const Documents: React.FC = () => {
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState<string | null>(null);
    const [folders, setFolders] = React.useState<IDefaultFolder[]>([]);
    const [createFolderOpen, setCreateFolderOpen] = React.useState(false);
    const [editFolderOpen, setEditFolderOpen] = React.useState(false);
    const [selectedFolder, setSelectedFolder] = React.useState<IDefaultFolder | null>(null);
    const [breadcrumbs, setBreadcrumbs] = React.useState<IDefaultFolder[]>([]);

    React.useEffect(() => {
        loadFolders();
    }, []);

    const loadFolders = async (load = true) => {
        setLoading(load);
        setError(null);
        try {
            const data = await getDefaultFolders();
            setFolders(data);
        } catch (err) {
            setError(err.toString());
        }
        setLoading(false);
    };

    const closeCreateDialog = () => {
        setCreateFolderOpen(false);
    };

    const closeEditDialog = () => {
        setEditFolderOpen(false);
    };

    const onFolderSelected = (id: string, path: number[]) => {
        if (id === 'documents') {
            setSelectedFolder(null);
            return setBreadcrumbs([]);
        }
        if (!id) {
            setSelectedFolder(null);
            return setBreadcrumbs([]);
        }

        const crumbs: IDefaultFolder[] = [];
        path.forEach((location, idx) => {
            if (idx === 0) {
                crumbs.push(folders[location]);
            } else {
                crumbs.push(crumbs[idx - 1].children[location]);
            }
        });
        const parentIdx = crumbs.length - 1;
        const parent = crumbs[parentIdx] || null;
        setSelectedFolder(parent);
        setBreadcrumbs(crumbs);
    };

    const removeFolder = async () => {
        if (!selectedFolder) return;
        await removeDefaultFolder(selectedFolder.id);
        setSelectedFolder(null);
        setBreadcrumbs([]);
        loadFolders(false);
    };
    return (
        <>
            <Header title="Documents" breadcrumbs={['Settings', 'Documents']} />
            <Row>
                <Col xs={12} md={8} xl={8} xxl={6}>
                    <Card style={{ minHeight: 500 }}>
                        <Row>
                            <Col>
                                <h3 className="m-t-0">Default Folders</h3>
                            </Col>
                            <Col xs={8} className="text-right">
                                {selectedFolder ? (
                                    <>
                                        <Button small onClick={() => setCreateFolderOpen(true)}>
                                            Add Subfolder to <b>{selectedFolder.name}</b>
                                        </Button>{' '}
                                        <Button small onClick={() => setEditFolderOpen(true)}>
                                            Edit <b>{selectedFolder.name}</b>
                                        </Button>{' '}
                                        <Button intent="danger" minimal small icon="trash" onClick={removeFolder} />
                                    </>
                                ) : (
                                    <Button small onClick={() => setCreateFolderOpen(true)}>
                                        Create Folder
                                    </Button>
                                )}
                            </Col>
                        </Row>

                        {error && <p>{error}</p>}
                        {loading ? (
                            <div className="p-t-50 text-center">
                                <Spinner />
                            </div>
                        ) : (
                            <FolderTree selectedFolder={selectedFolder} onFolderSelected={onFolderSelected} folders={folders} />
                        )}
                    </Card>
                </Col>
            </Row>
            <Dialog isOpen={createFolderOpen} onClose={closeCreateDialog} title="Create Folder" icon="folder-new">
                {createFolderOpen && <CreateFolderDialog breadcrumbs={breadcrumbs} onClose={closeCreateDialog} selectedFolder={selectedFolder} onSaveComplete={() => loadFolders()} />}
            </Dialog>
            <Dialog isOpen={editFolderOpen} onClose={closeEditDialog} title="Edit Folder" icon="folder-close">
                {editFolderOpen && <CreateFolderDialog edit breadcrumbs={breadcrumbs} onClose={closeEditDialog} selectedFolder={selectedFolder} onSaveComplete={() => loadFolders()} />}
            </Dialog>
        </>
    );
};
