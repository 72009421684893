import React from 'react';
import Textfield from '@atlaskit/textfield';
import { Button } from '@blueprintjs/core';

import { useAuth } from 'src/hooks/useAuth';
import { Avatar } from '@mui/material';
import { IComment, ITopicIssue } from 'src/api/models/Meeting';
import { createComment } from 'src/api/meetings';
import { AppToast } from 'src/components/Toasts/AppToast';
import { useJobs } from 'src/hooks/useJobs';

interface NewCommentProps {
    issue: ITopicIssue | null;
    onCommentAdded: (comment: IComment) => void;
}

export const NewComment: React.FC<NewCommentProps> = ({ onCommentAdded, issue }) => {
    const { user } = useAuth();
    const { selectedJob } = useJobs();
    const [value, setValue] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const confirmComment = async () => {
        if (!user || !selectedJob || !issue) return;
        setLoading(true);
        try {
            const result = await createComment(selectedJob.id, issue.meeting_id, issue.topic_id, issue.id, value);
            onCommentAdded(result);
            setValue('');
        } catch (err) {
            AppToast.show({ message: `Error: ${err.toLocaleString()}`, intent: 'danger', icon: 'tick' });
        }
        setLoading(false);
    };

    const handleKeyDown = (e: any) => {
        if (e.key === 'Enter') {
            confirmComment();
        }
    };

    if (!user) return null;
    return (
        <div className="comment-box-wrapper">
            <div className="inline-comment-avatar m-t-8 m-l-5 m-r-10">
                <Avatar sx={{ width: 24, height: 24, bgcolor: '#2968a0', fontSize: 14 }}>{user.first_name.slice(0, 1)}</Avatar>
            </div>
            <Textfield
                className={value.length === 0 ? 'inline-comment-field-no-button' : 'inline-comment-field'}
                value={value}
                onKeyDown={handleKeyDown}
                placeholder="Add a comment..."
                onChange={(e: any) => {
                    setValue(e.target.value);
                }}
            />
            {value.length > 0 && (
                <div className="inline-comment-buttons m-t-8 m-l-5 m-r-5">
                    <Button icon="tick" minimal intent="primary" small loading={loading} onClick={confirmComment} /> <Button icon="cross" minimal intent="danger" small onClick={() => setValue('')} />
                </div>
            )}
        </div>
    );
};
