import React from 'react';
import moment from 'moment';
import { Link, useHistory } from 'react-router-dom';
import { Button, Spinner } from '@blueprintjs/core';
import { Header } from 'src/components/Header';
import { loadWorkflowTemplates } from 'src/api/admin';
import { IWorkflowResponse } from 'src/api/models/Workflow';
import { FiretrolTable } from 'src/components/FiretrolTable';

const WORKFLOW_COLUMNS = [
    {
        name: 'Name',
        selector: (workflow: IWorkflowResponse) => workflow.name,
        format: (workflow: IWorkflowResponse) => <Link to={`/settings/workflows/${workflow.id}`}>{workflow.name} </Link>,
        sortable: true,
        width: `200px`,
    },
    {
        name: 'Module',
        selector: (workflow: IWorkflowResponse) => workflow.resource_id.toUpperCase(),
        sortable: true,
        width: '100px',
    },
    {
        name: 'Last Modified By',
        selector: (workflow: IWorkflowResponse) => `${workflow.last_updated_by ? workflow.last_updated_by.full_name : ''} (${moment(workflow.updated_at).format('MM/DD/YYYY')})`,
        sortable: true,
        width: '200px',
    },
    {
        name: 'Created By',
        selector: (workflow: IWorkflowResponse) => `${workflow.created_by ? workflow.created_by.full_name : ''} (${moment(workflow.created_at).format('MM/DD/YYYY')})`,
        width: '200px',
        sortable: true,
    },
    {
        name: 'Offices',
        selector: () => 'N/A',
        sortable: true,
        //width: '100px',
    },
    {
        name: 'Ranking',
        selector: () => 'N/A',
        sortable: true,
        //width: '100px',
    },

    {
        name: 'Status',
        selector: (workflow: IWorkflowResponse) => (workflow.is_active ? 'Active' : 'Inactive'),
        sortable: true,
        // width: '100px',
    },
    {
        name: 'Default',
        selector: (workflow: IWorkflowResponse) => (workflow.is_default ? 'Yes' : 'No'),
        sortable: true,
        //width: '100px',
    },
];

export const Workflows: React.FC = () => {
    const history = useHistory();
    const [workflows, setWorkflows] = React.useState<IWorkflowResponse[]>([]);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState<string | null>();

    React.useEffect(() => {
        loadWorkflows();
    }, []);

    const loadWorkflows = async () => {
        setLoading(true);
        setError(null);
        try {
            const data = await loadWorkflowTemplates();
            setWorkflows(data);
        } catch (err) {
            setError(err.toLocaleString());
        }
        setLoading(false);
    };

    return (
        <>
            <Header
                title="Workflows"
                breadcrumbs={['Settings', 'Workflows']}
                actionComponent={<Button text="Create Workflow" onClick={() => history.push('/settings/workflows/create')} intent="primary" icon="add" />}
            />
            {error && <p className="text-center text-danger">{error}</p>}
            {loading ? <Spinner /> : <FiretrolTable columns={WORKFLOW_COLUMNS} data={workflows} />}
        </>
    );
};
