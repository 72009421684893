import AppApi from './index';
import {
    CompleteCTCJob,
    CostCodeBreakdown,
    CostCodeAdjustment,
    CTCJobResponse,
    CTCMaterial,
    CostCodeAdjustmentResponse,
    CostCodeAdjustmentLineItem,
    CostCodeAdjustmentHistoryResponse,
    CTCFile,
} from './models/CTC';
import { getAuthOptions } from './helpers';

const api = AppApi.getApi();

export const loadCTCJobs = async (): Promise<CTCJobResponse> => {
    const url = `/ctc/jobs`;
    const { data } = await api.get(url, await getAuthOptions());
    return data;
};

export const processCTCFiles = async (): Promise<CTCFile> => {
    const url = `/admin/ctc/processFiles`;
    const { data } = await api.get(url, await getAuthOptions());
    return data;
};
export const loadCTCFiles = async (): Promise<CTCFile[]> => {
    const url = `/admin/ctc/files`;
    const { data } = await api.get(url, await getAuthOptions());
    return data;
};

export const loadLinkedJobs = async (): Promise<string[]> => {
    const url = `/ctc/linkedJobs`;
    const { data } = await api.post(url, {}, await getAuthOptions());
    return data;
};
export const loadCTCJobsWithFilters = async (filters: any): Promise<CTCJobResponse> => {
    const url = `/ctc/jobs`;
    const { data } = await api.post(url, filters, await getAuthOptions());
    return data;
};

export const loadCTCDivisions = async (): Promise<string[]> => {
    const url = `/ctc/divisions`;
    const { data } = await api.get(url, await getAuthOptions());
    return data;
};

export const loadCTCJobById = async (id: string): Promise<CompleteCTCJob> => {
    const url = `/ctc/jobs/${id}`;
    const { data } = await api.get(url, await getAuthOptions());
    return data;
};

export const loadJobMaterials = async (jobNumber: string): Promise<Record<string, CTCMaterial[]>> => {
    const url = `/ctc/jobs/${jobNumber}/materials`;
    const { data } = await api.get(url, await getAuthOptions());
    return data;
};

export const loadJobCostCodeMaterials = async (jobNumber: string, costCode: string): Promise<Record<string, CTCMaterial[]>> => {
    const url = `/ctc/jobs/${jobNumber}/materials/${costCode}`;
    const { data } = await api.get(url, await getAuthOptions());
    return data;
};

export const loadJobMaterialsByCostCodes = async (jobNumber: string): Promise<Record<string, CTCMaterial[]>> => {
    const url = `/ctc/jobs/${jobNumber}/materialsByCostCode`;
    const { data } = await api.get(url, await getAuthOptions());
    return data;
};

export const loadJobMaterialsTotal = async (jobNumber: string): Promise<{ value: 0; remaining: 0 }> => {
    const url = `/ctc/jobs/${jobNumber}/materialsTotal`;
    const { data } = await api.get(url, await getAuthOptions());
    return data;
};

export const loadCostCodeBreakdown = async (jobNumber: string, costCode: string): Promise<CostCodeBreakdown> => {
    const url = `/ctc/jobs/${jobNumber}/breakdown/${costCode}`;
    const { data } = await api.get(url, await getAuthOptions());
    return data;
};

export const loadAdjustments = async (jobNumber: string): Promise<CostCodeAdjustmentResponse> => {
    const url = `/ctc/jobs/${jobNumber}/adjustments`;
    const { data } = await api.get(url, await getAuthOptions());
    return data;
};

export const loadAdjustmentHistory = async (jobNumber: string): Promise<CostCodeAdjustmentHistoryResponse[]> => {
    const url = `/ctc/jobs/${jobNumber}/adjustmentHistory`;
    const { data } = await api.get(url, await getAuthOptions());
    return data;
};

export const createCostCodeAdjustment = async (jobNumber: string, adjustment: CostCodeAdjustmentLineItem[], adjustedRev: number): Promise<CostCodeAdjustmentResponse> => {
    const url = `/ctc/jobs/${jobNumber}/adjustments`;
    const { data } = await api.post(url, { adjustments: adjustment, adjustedRev }, await getAuthOptions());
    return data;
};

export const updateCostCodeAdjustment = async (jobNumber: string, adjustment: CostCodeAdjustment): Promise<CostCodeAdjustment> => {
    const url = `/ctc/jobs/${jobNumber}/adjustments/${adjustment.id}`;
    const { data } = await api.put(url, adjustment, await getAuthOptions());
    return data;
};
