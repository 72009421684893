import React from 'react';
import { useParams } from 'react-router-dom';
import { Container, Row, Col } from 'react-grid-system';
import { IRFI } from 'src/api/models/RFI';
import { loadExternalRFI } from 'src/api/external';
import { Alignment, Navbar, Spinner } from '@blueprintjs/core';
import { RFIForm } from '../../Jobs/RFIs/components/RFIForm';
import logo from '../../../assets/images/firetrol.png';

export const ExternalRFI: React.FC = () => {
    const params: any = useParams();

    const [error, setError] = React.useState<string | null>(null);
    const [loading, setLoading] = React.useState(true);
    const [rfi, setRfi] = React.useState<IRFI | null>(null);

    React.useEffect(() => {
        loadRfi();
    }, []);

    const loadRfi = async (load = true) => {
        setLoading(load);
        setError(null);
        try {
            const data = await loadExternalRFI(params.id);
            setRfi(data);
        } catch (err) {
            setError(err.toLocaleString());
        }
        setLoading(false);
    };
    if (loading) {
        return (
            <div className="text-center p-100">
                <Spinner />
            </div>
        );
    }
    if (error || !rfi) {
        return (
            <div className="text-center p-100">
                <h4>{error}</h4>
            </div>
        );
    }
    return (
        <div style={{ backgroundColor: '#ebecf0' }}>
            <Navbar>
                <Navbar.Group align={Alignment.LEFT}>
                    <img className="sidebar-logo" src={logo} alt="" />
                </Navbar.Group>

                <Navbar.Group align={Alignment.RIGHT}>
                    <span className="bp3-navbar-divider" />
                    Guest Account
                </Navbar.Group>
            </Navbar>

            <Container style={{ maxWidth: 1000 }}>
                <Row>
                    <Col>
                        <h2>{rfi ? rfi.rfi_number : ''}</h2>
                        <RFIForm initialValues={rfi} onSaved={console.log} selectedJob={rfi.job} isExternal />
                    </Col>
                </Row>
            </Container>
        </div>
    );
};
