import React from 'react';
import { Header2 } from 'src/components/Header2';

export const PunchList: React.FC = () => {
    return (
        <>
            <Header2 breadcrumbs={[{ title: 'Punch List', link: '' }]} />
        </>
    );
};
