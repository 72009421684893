import React from 'react';
import { MenuItem, Avatar, ListItemIcon } from '@mui/material';
import { MentionsInput, Mention } from 'react-mentions';
import { IUser } from 'src/api/models/User';
import { useOrganization } from 'src/hooks/useOrganization';
import { IOrganizationRole } from 'src/api/models/Organization';

interface MentionInputProps {
    peopleOptions: IUser[];
    id?: string;
    value: string;
    disabled?: boolean;
    onChange: (value: string, plainTextValue: string, people: IUser[], roles: IOrganizationRole[]) => void;
}

function dedupUsers(users: any[]) {
    return users.filter((value: any, index: number, self: any[]) => index === self.findIndex((t) => t.id === value.id));
}

export const MentionInput: React.FC<MentionInputProps> = ({ peopleOptions, value, onChange, disabled = false, id = 'mention-input-1' }) => {
    const { roles } = useOrganization();

    React.useLayoutEffect(() => {
        const input: any = document.getElementById(id);
        if (input) {
            input.placeholder = 'What would you like to say?';
        }
    }, [id]);

    const roleOptions = React.useMemo(() => {
        return roles.map((role) => {
            return {
                ...role,
                id: `role-${role.id}`,
                display: role.name,
            };
        });
    }, [roles]);

    const renderSuggestion = (d: any) => {
        return (
            <MenuItem>
                {d.scopes ? (
                    <></>
                ) : (
                    <ListItemIcon>
                        <Avatar sx={{ width: 24, height: 24, fontSize: '12px' }}>CG</Avatar>
                    </ListItemIcon>
                )}
                {d.display}
            </MenuItem>
        );
    };

    const onMentionChange = (e: any, newValue: string, newPlainTextValue: string, mentions: any[]) => {
        const updatedMentions = dedupUsers(mentions)
            .map((m) => {
                const t = peopleOptions.find((user) => user.id === Number(m.id));
                return t || null;
            })
            .filter((n) => n !== null);

        const rolesOnly = mentions
            .filter((r) => r && r.id.includes('role'))
            .map((t) => {
                if (!t) return null;
                return {
                    ...t,
                    id: Number(t.id.replace('role-', '')),
                };
            });

        const updatedRoles = dedupUsers(rolesOnly)
            .map((m) => {
                const t = roles.find((r) => r.id === m.id);
                return t || null;
            })
            .filter((n) => n !== null);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        onChange(newValue, newPlainTextValue, updatedMentions, updatedRoles);
    };
    return (
        <>
            <MentionsInput autoFocus value={value} onChange={onMentionChange} className="mentions" id={id} allowSuggestionsAboveCursor disabled={disabled}>
                <Mention
                    trigger="@"
                    appendSpaceOnAdd
                    displayTransform={(id: number, display: string) => `@${display}`}
                    data={[...roleOptions, ...peopleOptions]}
                    renderSuggestion={renderSuggestion}
                    className="mentions__mention"
                />
            </MentionsInput>
        </>
    );
};
