import React from 'react';
import { Spinner } from '@blueprintjs/core';
import { useJobs } from 'src/hooks/useJobs';
import { Row, Col } from 'react-grid-system';
import { DailyForm } from '../DailyForm';
import { listJobQuestions } from '../../../../api/dailys';
import { IDailyFormValues } from '../../../../api/models/Daily';
import { Header2 } from '../../../../components/Header2';

const initialValues: IDailyFormValues = {
    job_id: -1,
    date: new Date(),
    status: 'Working',
    description: '',
    weather: null,
    questions: [],
    tickets: [],
};

export const SubmitDaily: React.FC = () => {
    const { selectedJob } = useJobs();
    const [values, setValues] = React.useState(initialValues);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState<string | null>(null);

    React.useEffect(() => {
        loadQuestions();
    }, []);

    const loadQuestions = async (load = true) => {
        if (!selectedJob) return;
        setLoading(load);
        setError(null);
        try {
            const data = await listJobQuestions(selectedJob.id);
            setValues((prevState) => {
                return {
                    ...prevState,
                    job_id: selectedJob.id,
                    questions: data.map((d) => {
                        return { ...d, note: '', value: 'no' };
                    }),
                };
            });
        } catch (err) {
            setError(err.toLocaleString());
        }
        setLoading(false);
    };
    return (
        <>
            <Header2
                breadcrumbs={[
                    { title: 'Jobs', link: '/jobs' },
                    { title: `${selectedJob?.name} - ${selectedJob?.job_number}`, link: `/jobs/${selectedJob?.id}/summary` },
                    { title: 'Dailys', link: `/jobs/${selectedJob?.id}/dailys` },
                    { title: 'Submit', link: '' },
                ]}
            />
            <Row>
                <Col>
                    {error && <p className="text-center">{error}</p>}
                    {loading ? (
                        <div className="text-center">
                            <Spinner />
                        </div>
                    ) : (
                        <DailyForm initialValues={values} />
                    )}
                </Col>
            </Row>
        </>
    );
};
