import React from 'react';
import { useHistory } from 'react-router-dom';
import { Navbar, Alignment, Button, Popover, Menu, Position, MenuItem, Classes } from '@blueprintjs/core';
import logo from 'src/assets/images/firetrol.png';
import { useAuth } from 'src/hooks/useAuth';
import { JobSelector } from '../Selectors/JobSelector';
import './index.scss';
import { useDialogs } from '../../hooks/useDialogs';
import { Notifications } from './components/Notifications';

export const Navigation: React.FC = () => {
    const { user, isAdmin } = useAuth();
    const history = useHistory();
    const { setCreateProjectOpen } = useDialogs();
    return (
        <>
            <Navbar>
                <Navbar.Group align={Alignment.LEFT}>
                    <img className="sidebar-logo" src={logo} alt="" />
                </Navbar.Group>
                <Navbar.Group align={Alignment.LEFT} className="p-l-60">
                    <JobSelector />
                </Navbar.Group>
                <Navbar.Group align={Alignment.RIGHT}>
                    <Notifications />
                    {isAdmin && (
                        <>
                            <Popover
                                content={
                                    <Menu>
                                        <Menu.Divider title="Admin Settings" />
                                        <MenuItem icon="people" text=" Users" onClick={() => history.push('/settings/users')} />
                                        <MenuItem icon="shield" text=" Roles" onClick={() => history.push('/settings/roles')} />
                                        <MenuItem icon="office" text=" Offices" onClick={() => history.push('/settings/offices')} />
                                        <MenuItem icon="inherited-group" text=" Clients" onClick={() => history.push('/settings/clients')} />
                                        <MenuItem icon="data-lineage" text=" Workflows" onClick={() => history.push('/settings/workflows')} />
                                        <MenuItem text=" More..." onClick={() => history.push('/settings')} />
                                        <Menu.Divider />
                                        {/*<MenuItem text="Create Office" onClick={() => setCreateOfficeDialogOpen(true)} />*/}
                                        {/*<MenuItem text="Create Client" onClick={() => setCreateClientDialogOpen(true)} />*/}
                                        <MenuItem text="Create Job" onClick={() => setCreateProjectOpen(true)} />
                                    </Menu>
                                }
                                position={Position.BOTTOM_RIGHT}
                            >
                                <Button className={Classes.MINIMAL} icon="cog" />
                            </Popover>
                            <Navbar.Divider />
                        </>
                    )}
                    <Popover
                        content={
                            <Menu>
                                <Menu.Divider title={`${user?.first_name} ${user?.last_name}`} />
                                <MenuItem text="Account" onClick={() => history.push('/account/profile')} />
                                <MenuItem text="Logout" onClick={() => history.push('/logout')} />
                            </Menu>
                        }
                        position={Position.BOTTOM_RIGHT}
                    >
                        <Button className="bp3-minimal" text={user?.full_name} icon="user" />
                    </Popover>
                </Navbar.Group>
            </Navbar>
        </>
    );
};
