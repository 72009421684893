export const scopes = [
    {
        title: 'CTC',
        scopes: ['CTC:read:global', 'CTC:restricted:offices'],
    },
    {
        title: 'Elevated',
        scopes: ['Admin:read:global', 'Admin:write:global', 'Admin:read:jobs', 'Admin:write:jobs'],
    },
    {
        title: 'Settings',
        scopes: ['Settings:read:global', 'Settings:write:global', 'Settings:read:jobs', 'Settings:write:jobs', 'Settings:restricted:jobs'],
    },
    // {
    //     title: 'Users',
    //     scopes: ['Users:read:global', 'Users:write:global', 'Users:read:jobs', 'Users:write:jobs'],
    // },
    {
        title: 'Teams',
        scopes: ['Teams:read:global', 'Teams:write:global', 'Teams:read:jobs', 'Teams:write:jobs', 'Teams:restricted:jobs'],
    },
    {
        title: 'WBS',
        scopes: ['WBS:read:global', 'WBS:write:global', 'WBS:read:jobs', 'WBS:write:jobs', 'WBS:restricted:jobs'],
    },
    {
        title: 'Timesheets',
        scopes: ['Timesheets:read:global', 'Timesheets:write:global', 'Timesheets:read:jobs', 'Timesheets:write:jobs', 'Timesheets:restricted:jobs'],
    },
    {
        title: 'Tasks',
        scopes: ['Tasks:read:global', 'Tasks:write:global', 'Tasks:read:jobs', 'Tasks:write:jobs', 'Tasks:restricted:jobs'],
    },
    {
        title: 'Documents',
        scopes: ['Documents:read:global', 'Documents:write:global', 'Documents:read:jobs', 'Documents:write:jobs', 'Documents:restricted:jobs'],
    },
    {
        title: 'Materials',
        scopes: ['Materials:read:global', 'Materials:write:global', 'Materials:read:jobs', 'Materials:write:jobs', 'Materials:restricted:jobs'],
    },
    {
        title: 'Dailys',
        scopes: ['Dailys:read:global', 'Dailys:write:global', 'Dailys:read:jobs', 'Dailys:write:jobs', 'Dailys:restricted:jobs'],
    },
    {
        title: 'RFIs',
        scopes: ['RFIs:read:global', 'RFIs:write:global', 'RFIs:read:jobs', 'RFIs:write:jobs', 'RFIs:restricted:jobs'],
    },
    {
        title: 'Analysis',
        scopes: ['Analysis:read:global', 'Analysis:write:global', 'Analysis:read:jobs', 'Analysis:write:jobs', 'Analysis:restricted:jobs'],
    },
    {
        title: 'Plans',
        scopes: ['Plans:read:global', 'Plans:write:global', 'Plans:read:jobs', 'Plans:write:jobs', 'Plans:restricted:jobs'],
    },
    {
        title: 'Notes',
        scopes: ['Notes:read:global', 'Notes:write:global', 'Notes:read:jobs', 'Notes:write:jobs', 'Notes:restricted:jobs'],
    },
    {
        title: 'Finance',
        scopes: ['Finance:read:global', 'Finance:write:global', 'Finance:read:jobs', 'Finance:write:jobs', 'Finance:restricted:jobs'],
    },
];
