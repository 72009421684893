import React from 'react';
import { Tag, Checkbox } from '@blueprintjs/core';
import { Col, Row } from 'react-grid-system';
import { scopes as Scopes } from 'src/data/scopes';

interface IScopeListItem {
    title: string;
    scopes: string[];
}

interface IScopePickerProps {
    scopeList?: IScopeListItem[];
    hideLabel?: boolean;
    onChange: (scopes: string[]) => void;
    initialSelectedScopes?: string[];
}

export const ScopePicker: React.FC<IScopePickerProps> = ({ scopeList = Scopes, onChange, initialSelectedScopes = [], hideLabel = false }) => {
    const [selectedScopes, setSelectedScopes] = React.useState<string[]>(initialSelectedScopes);

    React.useEffect(() => {
        onChange(selectedScopes);
    }, [selectedScopes, onChange]);
    const onScopeChecked = (scope: string, checked: any) => {
        if (checked) {
            return setSelectedScopes((prevState) => [...prevState, scope]);
        }
        setSelectedScopes((prevState) => prevState.filter((v) => v !== scope));
    };
    const renderTags = (items: string[]) => {
        return items.map((scope) => {
            const isChecked = !!selectedScopes.find((v) => v === scope);
            return (
                <div key={scope}>
                    <Checkbox checked={isChecked} onChange={(e: any) => onScopeChecked(scope, e.target.checked)} inline className="m-0 p-r-0" />
                    <Tag minimal className="m-r-5 m-t-5">
                        {' '}
                        {scope}
                    </Tag>
                </div>
            );
        });
    };

    const renderCols = (scopes: IScopeListItem[]) => {
        return scopes.map((s) => {
            return (
                <Col md={4} key={s.title}>
                    <h5 className="m-b-0 m-t-10">{s.title}</h5>
                    {renderTags(s.scopes)}
                </Col>
            );
        });
    };
    return (
        <Row className="m-b-15">
            <Col>
                {!hideLabel && <h3 className="m-b-0">Scopes</h3>}
                <Row gutterWidth={0}>{renderCols(scopeList)}</Row>
            </Col>
        </Row>
    );
};
