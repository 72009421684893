import React from 'react';
import { Dialog, DialogTitle, Box, Stack, Paper, Button, CircularProgress } from '@mui/material';
import { loadAdjustmentHistory } from 'src/api/ctc';
import { CostCodeAdjustmentHistoryResponse } from 'src/api/models/CTC';
import { nanoid } from 'nanoid';
import moment from 'moment';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';

interface HistoryDialogProps {
    jobId: string;
    open: boolean;
    onClose: () => void;
}

const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0,
});

const headerBg = '#f3f3f3';
export const HistoryDialog: React.FC<HistoryDialogProps> = ({ jobId, open, onClose }) => {
    const [items, setItems] = React.useState<CostCodeAdjustmentHistoryResponse[]>([]);
    const [loading, setLoading] = React.useState(true);
    React.useEffect(() => {
        if (open) {
            loadHistory();
        }
    }, [jobId, open]);
    const loadHistory = async () => {
        setLoading(true);
        const history = await loadAdjustmentHistory(jobId);
        setItems(history);
        setLoading(false);
    };

    const renderItems = () => {
        if (items.length === 0) {
            return <p>No Adjustments have been created</p>;
        }
        return items.map((item) => {
            return (
                <div key={item.id}>
                    <p className="fs-16">
                        <b>{item.created_by.full_name}</b> updated the following on <b>{moment(item.created_at).format('MM/DD/YYYY h:mm a')}</b>:
                    </p>
                    <div className="p-l-20">
                        {item.rev_changed && (
                            <p className="fs-16">
                                <b>
                                    Adjusted Revenue from {formatter.format(item.previous_adjusted_rev)} to {formatter.format(item.adjusted_rev)}
                                </b>
                            </p>
                        )}
                        {item.adjustments.length > 0 && (
                            <TableContainer component={Paper} className="m-b-20">
                                <Table sx={{ minWidth: 650 }} aria-label="simple table" size="small">
                                    <TableHead>
                                        <TableRow style={{ backgroundColor: headerBg }}>
                                            <TableCell align="left">Cost Code</TableCell>
                                            <TableCell align="center">Previous CTC Hours</TableCell>
                                            <TableCell align="center">Updated CTC Hours</TableCell>
                                            <TableCell align="center">Previous JTD Rate</TableCell>
                                            <TableCell align="center">Updated JTD Rate</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {item.adjustments.map((row) => {
                                            const hoursChanged = Number(row.previous_ctc_hours) !== Number(row.ctc_hours);
                                            const rateChanged = Number(row.previous_jtd_rate) !== Number(row.jtd_rate);
                                            return (
                                                <TableRow key={nanoid()} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                    <TableCell component="th" scope="row" width={300}>
                                                        {row.description} - {row.cost_code_id}
                                                    </TableCell>
                                                    <TableCell align="center">{hoursChanged ? row.previous_ctc_hours : 'N/A'}</TableCell>
                                                    <TableCell align="center">{hoursChanged ? row.ctc_hours : 'N/A'}</TableCell>
                                                    <TableCell align="center">{rateChanged ? row.previous_jtd_rate : 'N/A'}</TableCell>
                                                    <TableCell align="center">{rateChanged ? row.jtd_rate : 'N/A'}</TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        )}
                    </div>
                </div>
            );
        });
    };
    return (
        <Dialog onClose={onClose} open={open} fullWidth maxWidth="lg">
            <DialogTitle>Adjustment History</DialogTitle>

            <Box sx={{ p: 2 }}>
                {loading && (
                    <div className="text-center p-t-50">
                        <CircularProgress />
                    </div>
                )}

                {!loading && <Box sx={{ p: 2, pt: 0 }}>{renderItems()}</Box>}

                <Stack direction="row" spacing={1} justifyContent="space-between" sx={{ mt: 3 }}>
                    <div />
                    <div>
                        <Button variant="outlined" onClick={onClose}>
                            Close
                        </Button>
                    </div>
                </Stack>
            </Box>
        </Dialog>
    );
};
