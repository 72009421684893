import React from 'react';
import { useJobs } from '../../../hooks/useJobs';
import { QuestionCard } from './QuestionCard';
import { Row, Col } from 'react-grid-system';
import { Header2 } from '../../../components/Header2';

export const Questions: React.FC = () => {
    const { selectedJob } = useJobs();
    return (
        <>
            <Header2
                breadcrumbs={[
                    { title: 'Jobs', link: '/jobs' },
                    { title: `${selectedJob?.name} - ${selectedJob?.job_number}`, link: `/jobs/${selectedJob?.id}/summary` },
                    { title: 'Daily Questions', link: '' },
                ]}
            />
            {selectedJob && (
                <Row>
                    <Col xs={6}>
                        <QuestionCard jobId={selectedJob.id} />
                    </Col>
                </Row>
            )}
        </>
    );
};
