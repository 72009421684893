import React, { useState, createContext } from 'react';

interface ITasksContext {
    tasks: any[];
    selectedTask: any | null;
    setSelectedTask: (task: any | null) => void;
    dialogOpen: boolean;
    loading: boolean;
}

export const TasksContext = createContext<ITasksContext>({
    tasks: [],
    selectedTask: null,
    dialogOpen: false,
    loading: true,
    setSelectedTask: () => null,
});

export const JobTasksProvider: React.FC = ({ children }) => {
    const [tasks] = useState<any[]>([]);
    const [selectedTask, setSelectedTask] = useState<any | null>(null);
    const [loading] = useState(true);
    return <TasksContext.Provider value={{ tasks, loading, selectedTask, setSelectedTask, dialogOpen: selectedTask !== null }}>{children}</TasksContext.Provider>;
};

export const useJobTasks: () => ITasksContext = () => {
    const context = React.useContext(TasksContext);
    return { ...context };
};
