import React from 'react';
import { Row, Col } from 'react-grid-system';
import { useHistory } from 'react-router-dom';
import { useJobs } from 'src/hooks/useJobs';
import { SelectMeetingTable } from './components/SelectMeetingTable';
import { createMeeting, cloneMeeting } from '../../../../api/meetings';
import { AppToast } from '../../../../components/Toasts/AppToast';
import { Card, Button } from '@blueprintjs/core';
import { IMeeting } from '../../../../api/models/Meeting';

export const CreateMeetingMinutes: React.FC = () => {
    const { selectedJob } = useJobs();
    const history = useHistory();
    const [cloning, setCloning] = React.useState(false);
    const [creating, setCreating] = React.useState(false);
    const [selectedOption, setSelectedOption] = React.useState<string | null>(null);
    const [selectedMeeting, setSelectedMeeting] = React.useState<IMeeting | null>(null);

    const createClonedMeeting = async () => {
        if (!selectedJob || !selectedMeeting) return;
        setCloning(true);
        try {
            const result = await cloneMeeting(selectedJob.id, selectedMeeting.id);
            history.push(`/jobs/${result.job_id}/meetings/edit/${result.id}`);
        } catch (err) {
            AppToast.show({ message: `Error: ${err.toLocaleString()}`, intent: 'danger', icon: 'tick' });
            setCloning(false);
        }
    };

    const createBlankMeeting = async () => {
        if (!selectedJob) return;
        setCreating(true);
        try {
            const result = await createMeeting(selectedJob.id);
            history.push(`/jobs/${result.job_id}/meetings/edit/${result.id}`);
        } catch (err) {
            AppToast.show({ message: `Error: ${err.toLocaleString()}`, intent: 'danger', icon: 'tick' });
            setCreating(false);
        }
    };

    if (!selectedJob) return null;

    return (
        <>
            <Row className="m-t-25 m-b-25">
                <Col xs={10}>
                    <h3 className="m-b-10">Create Meeting Minutes</h3>
                    <p>Choose one of the following options to create a new meeting minute</p>
                </Col>
                <Col xs={2} className="text-right">
                    <Button className="m-t-15" onClick={() => history.push(`/jobs/${selectedJob.id}/meetings`)}>
                        Cancel
                    </Button>
                </Col>
            </Row>
            <Row>
                <Col xs={6}>
                    <Card
                        interactive
                        onClick={() => setSelectedOption('blank')}
                        elevation={selectedOption === 'blank' ? 3 : 0}
                        style={{ backgroundColor: selectedOption === 'blank' ? '#e9e9e9' : '#fff' }}
                    >
                        <h4 className="m-t-0">Create New Meeting</h4>
                        <p>Create new meeting from scratch </p>
                    </Card>
                </Col>
                <Col xs={6}>
                    <Card
                        interactive
                        onClick={() => setSelectedOption('clone')}
                        elevation={selectedOption === 'clone' ? 3 : 0}
                        style={{ backgroundColor: selectedOption === 'clone' ? '#e9e9e9' : '#fff' }}
                    >
                        <h4 className="m-t-0">Clone Existing Meeting</h4>
                        <p>Clone existing meeting's people, topics, and issues</p>
                    </Card>
                </Col>
            </Row>
            {selectedOption === 'blank' && (
                <Row className="m-t-50">
                    <Col xs={12} className="text-center">
                        <hr className="m-b-50" />
                        <Button intent="primary" onClick={createBlankMeeting} loading={creating}>
                            Create New Meeting
                        </Button>
                    </Col>
                </Row>
            )}
            {selectedOption === 'clone' && (
                <>
                    <Row className="m-t-50">
                        <Col xs={12} className="text-center">
                            <hr className="m-b-30" />
                            <h3 className="text-left">Select meeting to clone</h3>
                            <SelectMeetingTable jobId={selectedJob.id} selectedMeetingId={selectedMeeting ? selectedMeeting.id : null} onMeetingClicked={(meeting) => setSelectedMeeting(meeting)} />
                        </Col>
                    </Row>
                    {selectedMeeting && (
                        <Row className="m-t-25 m-b-25">
                            <Col xs={12} className="text-center">
                                <Button intent="primary" onClick={createClonedMeeting} loading={cloning}>
                                    Clone {selectedMeeting.title.length === 0 ? 'Meeting' : `"${selectedMeeting.title}"`}
                                </Button>
                            </Col>
                        </Row>
                    )}
                </>
            )}
        </>
    );
};
