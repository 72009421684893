import React from 'react';
import EmptyState from '@atlaskit/empty-state';
import DynamicTable from '@atlaskit/dynamic-table';
import type { RowType } from '@atlaskit/dynamic-table/types';
import { versionHead, versionHead2 } from '../../data';
import { IDocument } from 'src/api/models/Document';
import moment from 'moment';
import { IconLookup } from '../../../utils';
import { downloadVersionDocument, downloadDocument } from 'src/api/documents';
import { useAuth } from 'src/hooks/useAuth';

const formatRows = (docs: IDocument[], selectedDocumentId: string | null, currentUserId: number, hasLatest: boolean) => {
    return docs.map((document, index: number) => ({
        key: `row-${index}-${document.id}`,
        isHighlighted: selectedDocumentId === document.id,
        cells: [
            {
                key: index,
                shouldTruncate: true,
                content: (
                    <div className="p-l-10" style={{ cursor: 'pointer', width: '100%', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>
                        {IconLookup[document.type] || IconLookup['unknown']}
                        <span className="m-l-10">{`v${docs.length - index}.0`}</span>
                    </div>
                ),
            },
            {
                key: document.uploaded_by.full_name,
                shouldTruncate: true,

                content: (
                    <div style={{ cursor: 'pointer', width: '100%', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>
                        <span>{currentUserId === document.uploaded_by_id ? 'Me' : document.uploaded_by.full_name}</span>
                    </div>
                ),
            },
            {
                key: document.updated_at.toString(),
                content: moment(document.updated_at).format('MM/DD/YYYY h:mm a'),
            },
        ],
    }));
};

const extendRows = (rows: Array<RowType>, onClick: (e: React.MouseEvent, rowIndex: number) => void) => {
    return rows.map((row, index) => ({
        ...row,
        onClick: (e: React.MouseEvent) => onClick(e, index),
    }));
};

interface IFileTableProps {
    documents: IDocument[];
    jobId: number;
    selectedDocumentId: string | null;
    hasLatest?: boolean;
    emptyText?: string;
}

export const VersionTable: React.FC<IFileTableProps> = ({ documents, emptyText = 'No Versions', selectedDocumentId, jobId, hasLatest = false }) => {
    const { user } = useAuth();
    const downloadVersionFile = async (docId: string) => {
        await downloadVersionDocument(jobId, docId);
    };

    const downloadFile = async (docId: string) => {
        await downloadDocument(jobId, docId);
    };

    const onRowClick = (e: React.MouseEvent, rowIndex: number) => {
        if (hasLatest && rowIndex === 0) {
            downloadFile(documents[rowIndex].id);
        } else {
            downloadVersionFile(documents[rowIndex].id);
        }
    };

    const rows = React.useMemo(() => {
        return formatRows(documents, selectedDocumentId, user ? user.id : -1, hasLatest);
    }, [documents, selectedDocumentId, hasLatest]);

    const renderEmpty = () => {
        return <EmptyState header="" description={emptyText} />;
    };
    return <DynamicTable isFixedSize emptyView={renderEmpty()} head={hasLatest ? versionHead2 : versionHead} rows={extendRows(rows, onRowClick)} loadingSpinnerSize="large" />;
};
