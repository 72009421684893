import AppApi from './index';
import { IUser } from './models/User';
import { getAuthOptions } from './helpers';
import { IWorkflow, IWorkflowResponse } from './models/Workflow';

const api = AppApi.getApi();

export const loadOrganizationUsers = async (): Promise<IUser[]> => {
    const url = `/admin/users`;
    const { data } = await api.get(url, await getAuthOptions());
    return data;
};

export const inviteUser = async (user: Pick<IUser, 'roles' | 'first_name' | 'last_name' | 'email'>): Promise<IUser> => {
    const url = `/admin/users`;
    const { data } = await api.post(url, user, await getAuthOptions());
    if (data.success === false) {
        throw new Error(data.message);
    }
    return data;
};

export const updateUser = async (id: number, user: Pick<IUser, 'roles' | 'first_name' | 'last_name' | 'email'>): Promise<IUser> => {
    const url = `/admin/users/${id}`;
    const { data } = await api.put(url, user, await getAuthOptions());
    if (data.success === false) {
        throw new Error(data.message);
    }
    return data;
};

export const updateUserStatus = async (id: number, user: Pick<IUser, 'status'>): Promise<IUser> => {
    const url = `/admin/users/${id}/status`;
    const { data } = await api.put(url, user, await getAuthOptions());
    if (data.success === false) {
        throw new Error(data.message);
    }
    return data;
};

export const loadWorkflowTemplates = async (): Promise<IWorkflowResponse[]> => {
    const url = `/admin/workflows`;
    const { data } = await api.get(url, await getAuthOptions());
    return data;
};

export const loadWorkflowTemplateById = async (workflowId: string): Promise<IWorkflowResponse> => {
    const url = `/admin/workflows/${workflowId}`;
    const { data } = await api.get(url, await getAuthOptions());
    return data;
};

export const loadWorkflowTemplateByType = async (workflowId: string): Promise<IWorkflowResponse> => {
    const url = `/admin/workflows/${workflowId}/template`;
    const { data } = await api.get(url, await getAuthOptions());
    return data;
};
export const updateWorkflowTemplate = async (workflow: IWorkflow): Promise<IWorkflow> => {
    if (!workflow.id) {
        throw new Error('Error updating workflow template');
    }
    const url = `/admin/workflows/${workflow.id}`;
    const { data } = await api.post(url, workflow, await getAuthOptions());
    return data;
};
