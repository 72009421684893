import React from 'react';
import { Button, Menu, MenuItem, Popover, Position, Icon } from '@blueprintjs/core';

interface IOptionMenuProps {
    onAddComment: () => void;
    onDeleteIssue: () => void;
}

export const OptionMenu: React.FC<IOptionMenuProps> = ({ onAddComment, onDeleteIssue }) => {
    return (
        <Popover
            content={
                <Menu>
                    <MenuItem text="Add Comment" icon="comment" onClick={onAddComment} />
                    <MenuItem text="Delete Issue" icon="trash" onClick={onDeleteIssue} />
                </Menu>
            }
            position={Position.LEFT}
        >
            <Button className="bp3-minimal m-t--8" large>
                <Icon size={20} icon="more" className="rotate-90" />
            </Button>
        </Popover>
    );
};
