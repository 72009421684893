import React from 'react';
import { Row, Col } from 'react-grid-system';
import { useHistory, useParams } from 'react-router-dom';
import { WorkTicketForm } from '../WorkTicketForm';
import { Button, Spinner } from '@blueprintjs/core';
import { useJobs } from '../../../../hooks/useJobs';
import { IWorkTicketResponse } from 'src/api/models/WorkTicket';
import { getTicketById } from 'src/api/tickets';
import { Header2 } from '../../../../components/Header2';

export const EditTicket: React.FC = () => {
    const { selectedJob } = useJobs();
    const { ticketId }: any = useParams();
    const history = useHistory();
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState<string | null>(null);
    const [ticket, setTicket] = React.useState<IWorkTicketResponse | null>(null);

    React.useEffect(() => {
        loadTicket();
    }, [ticketId]);
    const loadTicket = async () => {
        if (!selectedJob) return;
        setLoading(true);
        setError(null);

        try {
            const data = await getTicketById(selectedJob.id, ticketId);
            setTicket(data);
        } catch (err) {
            setError(err.toLocaleString());
        }
        setLoading(false);
    };

    if (!selectedJob) return null;
    return (
        <>
            <Header2
                breadcrumbs={[
                    { title: 'Jobs', link: '/jobs' },
                    { title: `${selectedJob?.name} - ${selectedJob?.job_number}`, link: `/jobs/${selectedJob?.id}/summary` },
                    { title: 'Work Tickets', link: `/jobs/${selectedJob.id}/tickets` },
                    { title: 'Create', link: '' },
                ]}
            />
            <Row>
                <Col>
                    {error && <p>{error}</p>}
                    {loading || !ticket ? (
                        <div className="text-center">
                            <Spinner />
                        </div>
                    ) : (
                        <WorkTicketForm
                            jobId={selectedJob.id}
                            initialValues={ticket}
                            ticketId={ticketId}
                            sideCancelButton={
                                <Button fill className="m-b-15" onClick={() => history.push(`/jobs/${selectedJob.id}/tickets`)}>
                                    Cancel
                                </Button>
                            }
                            cancelButton={
                                <Button className="m-r-10" onClick={() => history.push(`/jobs/${selectedJob.id}/tickets`)}>
                                    Cancel
                                </Button>
                            }
                        />
                    )}
                </Col>
            </Row>
        </>
    );
};
