import React from 'react';
import { Tag } from '@blueprintjs/core';
import { Col, Row } from 'react-grid-system';
import { scopes as Scopes } from 'src/data/scopes';

interface IScopeListItem {
    title: string;
    scopes: string[];
}

interface IAllScopesProps {
    scopeList?: IScopeListItem[];
    hideLabel?: boolean;
}

export const AllScopes: React.FC<IAllScopesProps> = ({ scopeList = Scopes, hideLabel = false }) => {
    const renderTags = (items: string[]) => {
        return items.map((scope) => {
            return (
                <div key={scope}>
                    <Tag minimal className="m-r-5 m-t-5">
                        {' '}
                        {scope}
                    </Tag>
                </div>
            );
        });
    };

    const renderCols = () => {
        return scopeList.map((s) => {
            return (
                <Col sm={4} md={3} key={s.title}>
                    <h4 className="m-b-0">{s.title}</h4>
                    {renderTags(s.scopes)}
                </Col>
            );
        });
    };

    return (
        <Row className="m-b-15">
            <Col>
                {!hideLabel && <h3 className="m-b-0">Scopes</h3>}
                <Row gutterWidth={0}>{renderCols()}</Row>
            </Col>
        </Row>
    );
};
