import React from 'react';
import { IMeeting } from 'src/api/models/Meeting';
import { PDFViewer } from '@react-pdf/renderer';
import { PDFDocument } from '../PDFDocument';

interface MeetingPdfProps {
    values: IMeeting;
}

export const MeetingPdf: React.FC<MeetingPdfProps> = ({ values }) => {
    return (
        <>
            <PDFViewer style={{ minHeight: '500px', position: 'relative', width: '100%', height: '1000px' }}>
                <PDFDocument values={values} />
            </PDFViewer>
        </>
    );
};
