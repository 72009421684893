import moment from 'moment';
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu';
import Folder16Icon from '@atlaskit/icon-file-type/glyph/folder/16';
import ExcelSpreadsheet16Icon from '@atlaskit/icon-file-type/glyph/excel-spreadsheet/16';
import { Icon, Tag } from '@blueprintjs/core';
export const presidents = [
    {
        id: 1,
        name: 'Shop Drawings & Submittals',
        fileType: 'folder',
        updated_at: new Date(),
        size: 0,
        created_by: {
            full_name: 'Cary Gaskell',
        },
    },
    {
        id: 2,
        fileType: 'folder',
        name: 'RFIs',
        updated_at: new Date(),
        size: 0,
        created_by: {
            full_name: 'Mike Kenefski',
        },
    },
    {
        id: 2,
        fileType: 'excel',
        name: 'my_awesome_excel.xls',
        updated_at: new Date(),
        size: 64,
        created_by: {
            full_name: 'Mike Kenefski',
        },
    },
];

interface President {
    id: number;
    fileType: string;
    name: string;
    updated_at: Date;
    size: number;
    created_by: {
        full_name: string;
    };
}

function createKey(input: string) {
    return input ? input.replace(/^(the|a|an)/, '').replace(/\s/g, '') : input;
}

export const createHead = (withWidth: boolean) => {
    return {
        cells: [
            {
                key: 'name',
                content: 'Name',
                isSortable: true,
                shouldTruncate: true,
                width: 40,
            },
            {
                key: 'uploaded_by.full_name',
                content: 'Owner',
                isSortable: true,
                shouldTruncate: true,
            },
            // {
            //     key: 'fileType',
            //     content: 'Type',
            //     shouldTruncate: true,
            //     isSortable: true,
            //     width: withWidth ? 15 : undefined,
            // },
            {
                key: 'updated_at',
                content: 'Modified',
                shouldTruncate: true,
                isSortable: true,
                width: withWidth ? 15 : undefined,
            },
            {
                key: 'size',
                content: 'Size',
                shouldTruncate: true,
                isSortable: true,
            },
            // {
            //     key: 'more',
            //     shouldTruncate: true,
            // },
        ],
    };
};

export const head = createHead(false);
export const versionHead = {
    cells: [
        {
            key: 'version',
            content: 'Version',
            isSortable: true,
            shouldTruncate: true,
            width: 40,
        },
        {
            key: 'uploaded_by.full_name',
            content: 'Uploaded By',
            isSortable: true,
            shouldTruncate: true,
        },
        {
            key: 'updated_at',
            content: 'Date',
            shouldTruncate: true,
            isSortable: true,
        },
    ],
};
export const versionHead2 = {
    cells: [
        {
            key: 'version',
            content: 'Version',
            isSortable: true,
            shouldTruncate: true,
            width: 40,
        },
        {
            key: 'uploaded_by.full_name',
            content: 'Published By',
            isSortable: true,
            shouldTruncate: true,
        },
        {
            key: 'updated_at',
            content: 'Date',
            shouldTruncate: true,
            isSortable: true,
        },
    ],
};
export const rows = presidents.map((president: President, index: number) => ({
    key: `row-${index}-${president.name}`,
    isHighlighted: false,
    cells: [
        {
            key: createKey(president.name),
            content: (
                <div className="p-l-10">
                    {president.fileType === 'folder' ? <Folder16Icon label="folder" /> : <ExcelSpreadsheet16Icon label={president.fileType} />}
                    <span className="m-l-10">{president.name}</span>
                </div>
            ),
        },

        {
            key: createKey(president.fileType),
            content: (
                <Tag minimal style={{ textTransform: 'capitalize' }}>
                    {president.fileType}
                </Tag>
            ),
        },
        {
            key: president.id,
            content: moment(president.updated_at).format('MM/DD/YYYY'),
        },
        {
            key: 'size',
            content: president.fileType === 'folder' ? '--' : president.size + 'kb',
        },
        {
            key: 'MoreDropdown',
            content: (
                <DropdownMenu trigger="More">
                    <DropdownItemGroup>
                        <DropdownItem>
                            <Icon icon="download" color="grey" className="m-r-10" />
                            Download
                        </DropdownItem>
                        <DropdownItem>
                            <Icon icon="edit" color="grey" className="m-r-10" />
                            Rename
                        </DropdownItem>
                        <DropdownItem>
                            <Icon icon="trash" color="grey" className="m-r-10" />
                            Remove
                        </DropdownItem>
                    </DropdownItemGroup>
                </DropdownMenu>
            ),
        },
    ],
}));
