import React from 'react';
import moment from 'moment';
import Select from 'react-select';
import { IDateOption } from '../../types';

const start = 6;
// const end = 5;

const tempOptions = new Array(52).fill(0).map((v, idx) => {
    return {
        id: `${idx + 1}`,
        startDate: moment().week(idx).hour(12).day(start).toDate(),
        label: `${moment().week(idx).day(start).format('ddd (M/D/YY)')} - ${moment().week(idx).day(start).add(6, 'days').format('ddd (M/D/YY)')}`,
    };
});

export const currentWeekFilter = {
    id: `${moment().week()}`,
    prefix: 'Current Week - ',
    startDate: moment().day(start).hour(12).toDate(),
    label: `${moment().day(start).format('ddd (M/D/YY)')} - ${moment().day(start).add(6, 'days').format('ddd (M/D/YY)')}`,
};

const WeekOptions = [currentWeekFilter, ...tempOptions];

interface IWeekSelectorProps {
    value: IDateOption;
    onChange: (value: IDateOption) => void;
}

export const WeekSelector: React.FC<IWeekSelectorProps> = ({ onChange, value }) => {
    return (
        <>
            <label className="text-bold m-b-10">Work Week</label>
            <Select
                className="basic-single m-t-5 m-b-20"
                placeholder="Select weeks..."
                classNamePrefix="react-select"
                getOptionLabel={(value) => `${value.prefix ? value.prefix : ''} Week ${value.id}: ${' '} ${value.label}`}
                getOptionValue={(o) => o.id}
                value={value}
                isSearchable
                options={WeekOptions}
                onChange={(value: any) => {
                    onChange(value);
                }}
            />
        </>
    );
};
