import React from 'react';
import { Row, Col } from 'react-grid-system';
import { useHistory } from 'react-router-dom';
import { Breadcrumbs, BreadcrumbProps } from '@blueprintjs/core';

interface IHeaderProps {
    title: string;
    actionComponent?: React.ReactElement;
    breadcrumbs?: string[];
    goBackIndices?: number[];
    goBackPath?: string;
    className?: string;
}

export const Header: React.FC<IHeaderProps> = ({ breadcrumbs, className = 'm-t-20 m-b-20', goBackPath, title, goBackIndices = [], actionComponent }) => {
    const history = useHistory();
    return (
        <Row className={`${className}`}>
            <Col xs={8}>
                {breadcrumbs ? (
                    <Breadcrumbs
                        items={breadcrumbs.map((br, idx) => {
                            let item: BreadcrumbProps = {
                                text: br,
                            };
                            if (goBackIndices.indexOf(idx) >= 0) {
                                item = {
                                    ...item,
                                    onClick: goBackPath ? () => history.push(goBackPath) : () => history.goBack(),
                                };
                            }
                            return item;
                        })}
                    />
                ) : (
                    <Breadcrumbs items={[{ text: title }]} />
                )}
            </Col>
            <Col className="text-right">{actionComponent}</Col>
        </Row>
    );
};
