import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { MeetingPdf } from '../components/MeetingPdf';
import { IMeeting } from '../../../../api/models/Meeting';
import { getMeetingById } from '../../../../api/meetings';
import { AppToast } from '../../../../components/Toasts/AppToast';
import { useJobs } from '../../../../hooks/useJobs';
import { Button, Spinner } from '@blueprintjs/core';
import { Header } from '../../../../components/Header';

export const ExportMeeting: React.FC = () => {
    const { selectedJob } = useJobs();
    const history = useHistory();
    const params: any = useParams();
    const [loading, setLoading] = React.useState(true);
    const [meeting, setMeeting] = React.useState<IMeeting | null>(null);
    const [meetingTitle, setMeetingTitle] = React.useState('');

    React.useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        if (!selectedJob || !params.meetingId) return;
        setLoading(true);
        try {
            const result = await getMeetingById(selectedJob.id, params.meetingId);
            setMeetingTitle(result.title);
            setMeeting(result);
        } catch (err) {
            AppToast.show({ message: `Error: ${err.toLocaleString()}`, intent: 'danger', icon: 'tick' });
        }
        setLoading(false);
    };
    if (!selectedJob) return null;
    if (loading || !meeting) {
        return (
            <div className="text-center p-t-50">
                <Spinner />
            </div>
        );
    }
    return (
        <>
            <Header
                className="m-t-20 m-b-20"
                title="Meeting Minutes"
                breadcrumbs={[selectedJob.name, 'Meeting Minutes', meetingTitle.length > 0 ? meetingTitle : 'Untitled Meeting', 'Meeting Export']}
                goBackIndices={[2]}
                goBackPath={`/jobs/${selectedJob.id}/meetings/edit/${params.meetingId}`}
                actionComponent={
                    <>
                        <Button className="m-r-10" onClick={() => history.push(`/jobs/${selectedJob.id}/meetings/edit/${params.meetingId}`)}>
                            Back
                        </Button>
                    </>
                }
            />
            <MeetingPdf values={meeting} />
        </>
    );
};
