import React from 'react';
import Select from 'react-select';
import { Label, Tag } from '@blueprintjs/core';
import { IUser } from 'src/api/models/User';
import { useUsers } from 'src/hooks/useUsers';

interface IUserSelectProps {
    onChange?: (value: IUser | null) => void;
    dataFilter?: (options: IUser[]) => IUser[];
    value?: IUser | null;
    hideLabel?: boolean;
}

export const UserSelect: React.FC<IUserSelectProps> = ({ onChange, value, dataFilter, hideLabel = false }) => {
    const { users } = useUsers();
    const userOptions = dataFilter ? dataFilter(users) : users;
    return (
        <div>
            {!hideLabel && <Label className="text-bold">Users</Label>}
            <Select
                isClearable
                className="basic-single"
                placeholder="Select a user..."
                classNamePrefix="react-select"
                formatOptionLabel={(o) => {
                    return (
                        <div>
                            {o.first_name} {o.last_name}
                            <span className="pull-right">
                                {o.roles.map((r) => {
                                    return <Tag intent="primary">{r.name}</Tag>;
                                })}
                            </span>
                        </div>
                    );
                }}
                getOptionLabel={(o) => `${o.first_name} ${o.last_name}`}
                getOptionValue={(o) => String(o.id)}
                value={value}
                isSearchable
                options={userOptions}
                onChange={(value: any) => {
                    if (onChange) return onChange(value || null);
                }}
            />
        </div>
    );
};
