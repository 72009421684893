import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '@blueprintjs/core';
import { Header2 } from 'src/components/Header2';
import { useJobs } from 'src/hooks/useJobs';
import { IChangeOrderFormValues } from 'src/api/models/ChangeOrder';
import { ChangeForm } from '../components/ChangeForm';

const initialValues: IChangeOrderFormValues = {
    job_id: -1,
    date: new Date(),
    status: 'Working',
    description: '',
    assignee_user_id: null,
    cost: '',
    schedule: '',
    impact_notes: '',
    attachments: [],
    files_to_add: [],
    files_to_remove: [],
};

export const CreateChange: React.FC = () => {
    const { selectedJob } = useJobs();
    const history = useHistory();
    if (!selectedJob) return null;
    return (
        <div>
            <Header2
                //breadcrumbs={['Jobs', `${selectedJob.name} - ${selectedJob.job_number}` || '', 'Changes', 'Create Change Request']}
                breadcrumbs={[
                    { title: 'Jobs', link: '/jobs' },
                    { title: `${selectedJob.name} - ${selectedJob.job_number}`, link: `/jobs/${selectedJob.id}/summary` },
                    { title: 'Changes', link: `/jobs/${selectedJob.id}/changes` },
                    { title: 'Create Change Request', link: `` },
                ]}
                actionComponent={
                    <Button intent="none" onClick={() => history.push(`/jobs/${selectedJob.id}/changes`)}>
                        Cancel
                    </Button>
                }
            />
            <ChangeForm initialValues={initialValues} />
        </div>
    );
};
