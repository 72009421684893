import React from 'react';
import Divider from '@mui/material/Divider';
import Switch from '@mui/material/Switch';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Button from '@mui/material/Button';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import { UserAppOption } from 'src/api/models/User';
import { updateMyApps } from 'src/api/user';
import { DialogContent } from '@mui/material';

interface AppSelectorDialogProps {
    isOpen: boolean;
    onClose: () => void;
    onSave: (apps: UserAppOption[]) => void;
    initialApps: UserAppOption[];
}

const APP_OPTIONS: UserAppOption[] = [
    {
        id: 'rfis',
        title: 'RFIs',
        path: '/rfis',
        icon: 'exchange',
    },
    {
        id: 'change',
        title: 'Change Orders',
        path: '/changes',
        icon: 'changes',
    },
    {
        id: 'timesheets',
        title: 'Timesheets',
        path: '/timesheets',
        icon: 'time',
    },
    {
        id: 'installs',
        title: 'Installs',
        path: '/installs',
        icon: 'timeline-events',
    },
    {
        id: 'punchlists',
        title: 'Punch Lists',
        path: '/punchlists',
        icon: 'inheritance',
    },
    {
        id: 'summary',
        title: 'Summary',
        path: '/summary',
        icon: 'info-sign',
    },
];
export const AppSelectorDialog: React.FC<AppSelectorDialogProps> = ({ isOpen, onClose, onSave, initialApps }) => {
    const [userApps, setUserApps] = React.useState<UserAppOption[]>(initialApps);
    React.useEffect(() => {
        if (isOpen) {
            setUserApps(initialApps);
        }
    }, [isOpen, initialApps]);
    const toggleApp = (app: UserAppOption) => {
        setUserApps((prevState) => {
            const exists = prevState.find((a) => a.id === app.id) !== undefined;
            if (exists) {
                return prevState.filter((a) => {
                    return a.id !== app.id;
                });
            }
            return [...prevState, app];
        });
    };

    const saveSettings = async () => {
        console.log('saving: ', userApps);
        await updateMyApps(userApps);
        onSave(userApps);
    };

    return (
        <Dialog onClose={onClose} open={isOpen} maxWidth="xs" fullWidth>
            <DialogTitle>Select Apps</DialogTitle>
            <DialogContent>
                <List sx={{ pt: 0 }}>
                    {APP_OPTIONS.map((app) => (
                        <React.Fragment key={app.id}>
                            <ListItem>
                                <ListItemText primary={app.title} />
                                <Switch
                                    edge="end"
                                    onChange={() => toggleApp(app)}
                                    checked={userApps.find((a) => a.id === app.id) !== undefined}
                                    inputProps={{
                                        'aria-labelledby': 'switch-list-label-wifi',
                                    }}
                                />
                            </ListItem>
                            <Divider component="li" />
                        </React.Fragment>
                    ))}
                </List>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={saveSettings}>Save</Button>
            </DialogActions>
        </Dialog>
    );
};
