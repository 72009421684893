import React from 'react';
import { IMeeting, IMeetingTopic } from 'src/api/models/Meeting';
import moment from 'moment';
import { Page, Text, Document, View, StyleSheet, Image, Font } from '@react-pdf/renderer';
import { IUser } from '../../../../../api/models/User';
import logo from 'src/assets/images/firetrol.png';
import { useAuth } from '../../../../../hooks/useAuth';

Font.register({
    family: 'Open Sans',
    fonts: [
        { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf', fontWeight: 'normal' },
        { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf', fontWeight: 'bold' },
    ],
});
const styles = StyleSheet.create({
    page: {
        fontFamily: 'Open Sans',
        paddingBottom: 20,
        paddingLeft: 15,
        paddingRight: 15,
        paddingTop: 20,
        fontSize: 12,
        backgroundColor: '#fff',
        fontWeight: 'normal',
    },
    textCenter: {
        textAlign: 'center',
    },
    textRight: {
        textAlign: 'right',
    },
    imageRight: {
        flexBasis: '100%',
    },
    bold: {
        fontWeight: 'bold',
    },
    normal: {
        fontWeight: 'thin',
    },
    p: {
        fontSize: '11px',
    },
    ps: {
        fontSize: '10px',
    },
    h3: {
        fontSize: '18px',
        fontWeight: 'bold',
        marginBottom: 10,
    },
    h4: {
        fontSize: '15px',
        fontWeight: 'bold',
        marginBottom: 10,
    },
    h5: {
        fontSize: '14px',
        fontWeight: 'bold',
        marginBottom: 10,
    },
    pl5: {
        paddingLeft: 5,
    },
    pl10: {
        paddingLeft: 10,
    },
    pl15: {
        paddingLeft: 15,
    },
    pl20: {
        paddingLeft: 20,
    },
    pl40: {
        paddingLeft: 40,
    },
    inline: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
    },
    topicTitle: {
        backgroundColor: '#f1f1f1',
        fontSize: 11,
        margin: '0 -20px 2px -20px',
        padding: '2px 20px 2px 20px',
        fontWeight: 'bold',
    },
    row: {
        marginBottom: 10,
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        width: '100%',
    },
    logo: {
        marginLeft: -5,
        width: 210,
    },
    column: {
        display: 'flex',
        flexDirection: 'column',
        flexBasis: '100%',
        flex: 1,
    },
    body: {
        padding: 20,
    },
    header: {
        fontSize: 20,
        textAlign: 'center',
        padding: 5,
        color: '#fff',
        backgroundColor: '#000',
    },
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1,
    },
});

const pad = (num: number, size: number) => {
    const s = '000' + num;
    return s.substr(s.length - size);
};

interface PDFDocumentProps {
    values: IMeeting;
}

export const PDFDocument: React.FC<PDFDocumentProps> = ({ values }) => {
    const { user } = useAuth();
    const renderPeople = (label: string, people: IUser[], custom: IUser[]) => {
        return (
            <>
                <Text style={[styles.p]}>
                    <Text style={styles.bold}>{label}: </Text>
                    {people.map((u, idx) => {
                        return (
                            <Text key={u.id} style={[styles.normal]}>
                                {u.full_name} ({u.company_name}){idx === people.length - 1 ? '' : ', '}
                            </Text>
                        );
                    })}
                    {custom.length > 0 && people.length > 0 && <Text>{', '}</Text>}
                    {custom.map((u, idx) => {
                        return (
                            <Text style={[styles.normal]} key={u.id}>
                                {u.full_name}
                                {idx === custom.length - 1 ? '' : ','}{' '}
                            </Text>
                        );
                    })}
                </Text>
            </>
        );
    };
    const renderTopics = (topics: IMeetingTopic[]) => {
        return topics.map((topic, idx) => {
            return (
                <View id={topic.id}>
                    <Text style={[styles.topicTitle, styles.bold]}>{topic.title.length > 0 ? topic.title : 'Untitled Topic'}</Text>
                    <View>
                        {topic.issues
                            .filter((issue) => !issue.complete)
                            .map((issue, issueIdx) => {
                                return (
                                    <View key={issue.id} style={styles.ps}>
                                        <View style={[styles.row, { marginBottom: 2 }]}>
                                            <View style={styles.column}>
                                                <Text style={styles.bold}>
                                                    {pad(issueIdx + 1, 3)}: {issue.title.length > 0 ? issue.title : 'No Description'}
                                                </Text>
                                            </View>
                                            <View style={styles.column}>
                                                <Text style={styles.textRight}>
                                                    {issue.assignee ? issue.assignee.full_name : 'Unassigned'} / {issue.due_date ? moment(issue.due_date).format('MM/DD/YY') : 'No Due Date'} /{' '}
                                                    {issue.priority} Priority
                                                </Text>
                                            </View>
                                        </View>
                                        <View style={[styles.row, { marginBottom: 2 }]}>
                                            {issue.comments.map((comment) => {
                                                return (
                                                    <View style={[styles.row, { marginBottom: 2 }]} key={comment.id}>
                                                        <Text style={styles.ps}>
                                                            {moment(comment.created_at).format('MM/DD')}: {comment.comment}
                                                        </Text>
                                                    </View>
                                                );
                                            })}
                                        </View>
                                    </View>
                                );
                            })}
                    </View>
                </View>
            );
        });
    };
    return (
        <Document
            title="Meeting Minutes"
            subject={`${values.meeting_type ? values.meeting_type.name : 'Misc Meeting'}`}
            author={values.created_by.full_name}
            creator={values.created_by.full_name}
            producer={user ? user.full_name : values.created_by.full_name}
        >
            <Page size="LETTER" style={[styles.page, styles.ps]}>
                <View style={styles.body}>
                    <View style={[styles.row, { marginTop: -20 }]}>
                        <View style={[styles.column]}>
                            <Image src={logo} style={[styles.logo]} />
                        </View>
                        <View style={[styles.column]}>
                            <View style={[styles.textRight, { paddingTop: 5 }]}>
                                <Text style={styles.h3}>Meeting Minutes</Text>
                            </View>
                        </View>
                    </View>
                    <View style={styles.row}>
                        <View style={[styles.column]}>
                            <Text style={[styles.p, styles.bold]}>
                                {values.meeting_type ? values.meeting_type.name : 'Misc Meeting'} - {values.title.length > 0 ? values.title : 'Untitled'} ({values.status})
                            </Text>
                        </View>
                        <View style={[styles.column]}>
                            <Text style={[styles.p, styles.textRight]}>
                                <Text style={styles.bold}>Date:</Text> {moment(values.start_date).format('MM/DD/YYYY')}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <Text style={styles.bold}>Start:</Text> {moment(values.start_date).format('h:mm a')}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <Text style={styles.bold}>Close:</Text> {values.end_date ? moment(values.end_date).format('h:mm a') : 'N/A'}
                            </Text>
                        </View>
                    </View>

                    <View style={styles.row}>
                        <View style={styles.column}>{renderPeople('Lead', values.leads, values.custom_leads)}</View>
                        <View style={[styles.column, styles.textRight]}>{renderPeople('Administrator', values.admins, values.custom_admins)}</View>
                    </View>
                    <View style={styles.row}>
                        <View style={styles.column}>{renderPeople('Attendees', values.attendees, values.custom_attendees)}</View>
                    </View>
                    {renderTopics(values.topics)}
                </View>
                <View fixed style={{ position: 'absolute', width: 600, bottom: 10, fontSize: 10 }}>
                    <View style={[styles.textCenter]}>
                        <Text style={styles.textCenter} render={({ pageNumber, totalPages }) => `${pageNumber} of ${totalPages}`} />
                    </View>
                </View>
            </Page>
        </Document>
    );
};
