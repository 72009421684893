import React from 'react';

import Folder16Icon from '@atlaskit/icon-file-type/glyph/folder/16';
import PowerpointPresentation16Icon from '@atlaskit/icon-file-type/glyph/powerpoint-presentation/16';
import Spreadsheet16Icon from '@atlaskit/icon-file-type/glyph/spreadsheet/16';
import PdfDocument16Icon from '@atlaskit/icon-file-type/glyph/pdf-document/16';
import ExcelSpreadsheet16Icon from '@atlaskit/icon-file-type/glyph/excel-spreadsheet/16';
import Image16Icon from '@atlaskit/icon-file-type/glyph/image/16';
import Document16Icon from '@atlaskit/icon-file-type/glyph/document/16';
import Gif16Icon from '@atlaskit/icon-file-type/glyph/gif/16';
import Generic16Icon from '@atlaskit/icon-file-type/glyph/generic/16';

import Folder24Icon from '@atlaskit/icon-file-type/glyph/folder/24';
import PowerpointPresentation24Icon from '@atlaskit/icon-file-type/glyph/powerpoint-presentation/24';
import Spreadsheet24Icon from '@atlaskit/icon-file-type/glyph/spreadsheet/24';
import PdfDocument24Icon from '@atlaskit/icon-file-type/glyph/pdf-document/24';
import ExcelSpreadsheet24Icon from '@atlaskit/icon-file-type/glyph/excel-spreadsheet/24';
import Image24Icon from '@atlaskit/icon-file-type/glyph/image/24';
import Document24Icon from '@atlaskit/icon-file-type/glyph/document/24';
import Gif24Icon from '@atlaskit/icon-file-type/glyph/gif/24';
import Generic24Icon from '@atlaskit/icon-file-type/glyph/generic/24';

import Folder48Icon from '@atlaskit/icon-file-type/glyph/folder/48';
import PowerpointPresentation48Icon from '@atlaskit/icon-file-type/glyph/powerpoint-presentation/48';
import Spreadsheet48Icon from '@atlaskit/icon-file-type/glyph/spreadsheet/48';
import PdfDocument48Icon from '@atlaskit/icon-file-type/glyph/pdf-document/48';
import ExcelSpreadsheet48Icon from '@atlaskit/icon-file-type/glyph/excel-spreadsheet/48';
import Image48Icon from '@atlaskit/icon-file-type/glyph/image/48';
import Document48Icon from '@atlaskit/icon-file-type/glyph/document/48';
import Gif48Icon from '@atlaskit/icon-file-type/glyph/gif/48';
import Generic48Icon from '@atlaskit/icon-file-type/glyph/generic/48';
export const IconLookup: any = {
    folder: <Folder16Icon label="folder" />,
    ppt: <PowerpointPresentation16Icon label="ppt" />,
    pptx: <PowerpointPresentation16Icon label="pptx" />,
    pptm: <PowerpointPresentation16Icon label="pptm" />,
    csv: <Spreadsheet16Icon label="csv" />,
    pdf: <PdfDocument16Icon label="pdf" />,
    xlsx: <ExcelSpreadsheet16Icon label="xls" />,
    cfb: <ExcelSpreadsheet16Icon label="xls" />,
    xls: <ExcelSpreadsheet16Icon label="xls" />,
    png: <Image16Icon label="png" />,
    jpg: <Image16Icon label="jpg" />,
    jpeg: <Image16Icon label="jpeg" />,
    docx: <Document16Icon label="docx" />,
    gif: <Gif16Icon label="gif" />,
    unknown: <Generic16Icon label="unknown" />,
};

export const IconLookup24: any = {
    folder: <Folder24Icon label="folder" />,
    ppt: <PowerpointPresentation24Icon label="ppt" />,
    pptx: <PowerpointPresentation24Icon label="pptx" />,
    pptm: <PowerpointPresentation24Icon label="pptm" />,
    csv: <Spreadsheet24Icon label="csv" />,
    pdf: <PdfDocument24Icon label="pdf" />,
    xlsx: <ExcelSpreadsheet24Icon label="xls" />,
    cfb: <ExcelSpreadsheet24Icon label="xls" />,
    xls: <ExcelSpreadsheet24Icon label="xls" />,
    png: <Image24Icon label="png" />,
    jpg: <Image24Icon label="jpg" />,
    jpeg: <Image24Icon label="jpeg" />,
    docx: <Document24Icon label="docx" />,
    gif: <Gif24Icon label="gif" />,
    unknown: <Generic24Icon label="unknown" />,
};

export const IconLookup48: any = {
    folder: <Folder48Icon label="folder" />,
    ppt: <PowerpointPresentation48Icon label="ppt" />,
    pptx: <PowerpointPresentation48Icon label="pptx" />,
    pptm: <PowerpointPresentation48Icon label="pptm" />,
    csv: <Spreadsheet48Icon label="csv" />,
    pdf: <PdfDocument48Icon label="pdf" />,
    xlsx: <ExcelSpreadsheet48Icon label="xls" />,
    cfb: <ExcelSpreadsheet48Icon label="xls" />,
    xls: <ExcelSpreadsheet48Icon label="xls" />,
    png: <Image48Icon label="png" />,
    jpg: <Image48Icon label="jpg" />,
    jpeg: <Image48Icon label="jpeg" />,
    docx: <Document48Icon label="docx" />,
    gif: <Gif48Icon label="gif" />,
    unknown: <Generic48Icon label="unknown" />,
};

export function humanFileSize(size: number) {
    const i = Math.floor(Math.log(size) / Math.log(1024));
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return (size / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + ['B', 'KB', 'MB', 'GB', 'TB'][i];
}
