import React from 'react';
import Select from 'react-select';
import { Label, Tag, Intent } from '@blueprintjs/core';

interface IUserSelectProps {
    onChange: (value: Intent) => void;
    value: Intent;
    text?: string;
    hideLabel?: boolean;
}

interface IColorOption {
    value: Intent;
    label: string;
}
const OPTIONS: IColorOption[] = [
    {
        value: Intent.PRIMARY,
        label: 'primary',
    },
    {
        value: Intent.SUCCESS,
        label: '',
    },
    {
        value: Intent.WARNING,
        label: '',
    },
    {
        value: Intent.DANGER,
        label: '',
    },
    {
        value: Intent.NONE,
        label: '',
    },
];

// const OPTIONS: Intent[] = [Intent.PRIMARY];

export const ColorSelect: React.FC<IUserSelectProps> = ({ onChange, text = '', value, hideLabel = false }) => {
    const selected: IColorOption = {
        value: value,
        label: '',
    };
    return (
        <div>
            {!hideLabel && <Label className="text-bold">Button Color</Label>}
            <Select
                className="basic-single"
                placeholder="Select a color..."
                classNamePrefix="react-select"
                formatOptionLabel={(o) => {
                    return (
                        <div className="p-t-4">
                            <Tag intent={o.value} style={{ width: '100%' }}>
                                {text}
                            </Tag>
                        </div>
                    );
                }}
                getOptionLabel={(o) => o.label}
                getOptionValue={(o) => o.value}
                value={selected}
                options={OPTIONS}
                onChange={(value: any) => {
                    onChange(value.value);
                }}
            />
        </div>
    );
};
