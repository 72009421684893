import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Spinner } from '@blueprintjs/core';
import { Header2 } from 'src/components/Header2';
import { useJobs } from 'src/hooks/useJobs';
import { ChangeForm } from '../components/ChangeForm';
import { IChangeOrderResponse } from '../../../../api/models/ChangeOrder';
import { getChangeById } from '../../../../api/changes';

export const ViewChange: React.FC = () => {
    const { selectedJob } = useJobs();
    const history = useHistory();

    const params: any = useParams();
    const [values, setValues] = React.useState<IChangeOrderResponse | null>();
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState<string | null>(null);

    React.useEffect(() => {
        loadChange();
    }, []);

    const loadChange = async (load = true) => {
        if (!selectedJob) return;
        setLoading(load);
        setError(null);
        try {
            const change = await getChangeById(selectedJob.id, params.changeId);
            setValues({
                ...change,
                files_to_add: [],
                files_to_remove: [],
            });
        } catch (err) {
            setError(err.toLocaleString());
        }
        setLoading(false);
    };
    if (!selectedJob) return null;
    return (
        <>
            <Header2
                breadcrumbs={[
                    { title: 'Jobs', link: '/jobs' },
                    { title: `${selectedJob.name} - ${selectedJob.job_number}`, link: `/jobs/${selectedJob.id}/summary` },
                    { title: 'Changes', link: `/jobs/${selectedJob.id}/changes` },
                    { title: values?.change_number || '', link: `` },
                ]}
                actionComponent={
                    <Button intent="none" onClick={() => history.push(`/jobs/${selectedJob.id}/changes`)}>
                        Cancel
                    </Button>
                }
            />
            {error && <p className="text-center">{error}</p>}
            {loading || !values ? (
                <div className="text-center">
                    <Spinner />
                </div>
            ) : (
                <ChangeForm initialValues={values} changeId={params.changeId} />
            )}
        </>
    );
};
