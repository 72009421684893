import React from 'react';
import Select from 'react-select';
import { Label, Tag } from '@blueprintjs/core';
import { ITeamUser } from 'src/api/models/Job';
import { useJobs } from 'src/hooks/useJobs';

interface IJobUserSelectProps {
    onChange?: (value: ITeamUser | null) => void;
    hideLabel?: boolean;
    small?: boolean;
    selectedUserId?: number | null;
    hideRole?: boolean;
    disabled?: boolean;
    placeholder?: string;
}

export const JobUserSelect: React.FC<IJobUserSelectProps> = ({ onChange, placeholder = 'Select a user...', disabled = false, hideRole = false, selectedUserId, small = false, hideLabel = false }) => {
    const { team } = useJobs();
    const [value, setValue] = React.useState<ITeamUser | null>(null);
    React.useEffect(() => {
        if (selectedUserId) {
            const selected = team.find((teamUser) => teamUser.user_id === selectedUserId) || null;
            setValue(selected);
        } else {
            setValue(null);
        }
    }, [team, selectedUserId]);
    return (
        <div>
            {!hideLabel && <Label className="text-bold">Users</Label>}
            <Select
                isClearable
                isDisabled={disabled}
                className={`basic-single ${small ? 'single-small' : ''}`}
                placeholder={placeholder}
                classNamePrefix="react-select"
                formatOptionLabel={(o) => {
                    return (
                        <div>
                            {o.user.first_name} {o.user.last_name}
                            {!hideRole && (
                                <span className="pull-right">
                                    {o.user.roles.map((r) => {
                                        return <Tag intent="primary">{r.name}</Tag>;
                                    })}
                                </span>
                            )}
                        </div>
                    );
                }}
                getOptionLabel={(o) => `${o.user.first_name} ${o.user.last_name}`}
                getOptionValue={(o) => String(o.id)}
                value={value}
                isSearchable
                options={team}
                onChange={(value: any) => {
                    if (onChange) return onChange(value ? value : null);
                }}
            />
        </div>
    );
};
