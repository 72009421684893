import React from 'react';
import EmptyState from '@atlaskit/empty-state';
import DynamicTable from '@atlaskit/dynamic-table';
import type { RowType } from '@atlaskit/dynamic-table/types';
import { head } from '../data';
import { IDocument } from 'src/api/models/Document';
import moment from 'moment';
import { IconLookup, humanFileSize } from '../../utils';
import { downloadDocument } from '../../../../../api/documents';
import { useAuth } from '../../../../../hooks/useAuth';

const formatRows = (docs: IDocument[], onClick: (doc: IDocument) => void, onDownloadClick: (docId: string) => void, selectedDocumentId: string | null, currentUserId: number) => {
    return docs.map((document, index: number) => ({
        key: `row-${index}-${document.id}`,
        isHighlighted: selectedDocumentId === document.id,
        cells: [
            {
                key: document.name,
                shouldTruncate: true,
                onClick: () => onClick(document),
                content: (
                    <div className="p-l-10" onClick={() => onClick(document)} style={{ cursor: 'pointer', width: '100%', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>
                        {IconLookup[document.type] || IconLookup['unknown']}
                        <span className="m-l-10">{document.name}</span>
                    </div>
                ),
            },
            {
                key: document.uploaded_by.full_name,
                shouldTruncate: true,
                onClick: () => onClick(document),
                content: (
                    <div onClick={() => onClick(document)} style={{ cursor: 'pointer', width: '100%', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>
                        <span>{currentUserId === document.uploaded_by_id ? 'Me' : document.uploaded_by.full_name}</span>
                    </div>
                ),
            },
            {
                key: document.updated_at.toString(),
                content: moment(document.updated_at).format('MM/DD/YYYY'),
            },
            {
                key: 'size',
                content: document.is_folder ? '--' : humanFileSize(document.size),
            },
        ],
    }));
};

const extendRows = (rows: Array<RowType>, onClick: (e: React.MouseEvent, rowIndex: number) => void, onDoubleClick: (e: React.MouseEvent, rowIndex: number) => void) => {
    return rows.map((row, index) => ({
        ...row,
        onDoubleClick: (e: React.MouseEvent) => onDoubleClick(e, index),
        // onClick: (e: React.MouseEvent) => onClick(e, index),
    }));
};

interface IFileTableProps {
    documents: IDocument[];
    isLoading?: boolean;
    onDocumentDoubleClicked: (document: IDocument) => void;
    onDocumentClicked: (document: IDocument) => void;
    jobId: number;
    selectedDocumentId: string | null;
}
export const FileTable: React.FC<IFileTableProps> = ({ documents, selectedDocumentId, jobId, isLoading = false, onDocumentDoubleClicked, onDocumentClicked }) => {
    const { user } = useAuth();
    const onRowDoubleClick = (e: React.MouseEvent, rowIndex: number) => {
        onDocumentDoubleClicked(documents[rowIndex]);
    };
    const onRowClick = (e: React.MouseEvent, rowIndex: number) => {
        onDocumentClicked(documents[rowIndex]);
    };
    const downloadFile = async (docId: string) => {
        await downloadDocument(jobId, docId);
    };

    const rows = React.useMemo(() => {
        return formatRows(documents, onDocumentClicked, downloadFile, selectedDocumentId, user ? user.id : -1);
    }, [documents, selectedDocumentId]);

    const renderEmpty = () => {
        return <EmptyState header="This folder is empty" description="Upload files or create folders by using the button or drop files here" />;
    };
    return <DynamicTable isLoading={isLoading} isFixedSize emptyView={renderEmpty()} head={head} rows={extendRows(rows, onRowClick, onRowDoubleClick)} loadingSpinnerSize="large" />;
};
