import React from 'react';
import { Link } from 'react-router-dom';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { IJob } from 'src/api/models/Job';
import './JobTable.scss';

interface JobTableProps {
    jobs: IJob[];
    loading: boolean;
}

const columns: GridColDef[] = [
    {
        field: 'job_number',
        headerName: 'Job Number - Job Name',
        flex: 2,
        renderCell: (params: GridValueGetterParams) => {
            return (
                <Link to={`/jobs/${params.row.job_number}/summary`}>
                    {params.row.job_number} - {params.row.name}
                </Link>
            );
        },
    },
    {
        field: 'division',
        headerName: 'Division',
        flex: 1,
        renderCell: (params: GridValueGetterParams) => {
            return params.row.division.length > 0 ? params.row.division : '--';
        },
        valueGetter: (params: GridValueGetterParams) => {
            return params.row.division.length > 0 ? params.row.division : '--';
        },
    },
    {
        field: 'client',
        headerName: 'Customer',
        flex: 1,
        renderCell: (params: GridValueGetterParams) => {
            return params.row.client ? params.row.client.name : '--';
        },
        valueGetter: (params: GridValueGetterParams) => {
            return params.row.client ? params.row.client.name : '--';
        },
    },
    {
        field: 'description',
        headerName: 'Description',
        flex: 1,
        renderCell: (params: GridValueGetterParams) => {
            return params.row.description.length > 0 ? <span style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{params.row.description}</span> : '--';
        },
    },
    {
        field: 'location',
        headerName: 'Jobsite Location',
        flex: 1,
        valueGetter: (params: GridValueGetterParams) => {
            return params.row.location && params.row.location.address ? params.row.location.address : '';
        },
        renderCell: (params: GridValueGetterParams) => {
            return params.row.location.address ? params.row.location.address : '--';
        },
    },
];
export const JobTable: React.FC<JobTableProps> = ({ jobs, loading }) => {
    return (
        <>
            <div className="job-table-container">
                <DataGrid
                    // sx={{
                    //     '& .MuiDataGrid-cell': {
                    //         cursor: 'pointer',
                    //     },
                    // }}
                    disableSelectionOnClick
                    disableColumnSelector
                    // onCellClick={async (params) => {
                    //     if (params.field === 'name') {
                    //         history.push(`/jobs/${params.row.id}/summary`);
                    //     }
                    // }}
                    loading={loading}
                    rows={jobs}
                    columns={columns}
                    pageSize={20}
                    rowsPerPageOptions={[20]}
                />
            </div>
        </>
    );
};
