import React from 'react';
import { Row, Col } from 'react-grid-system';
import { Card, InputGroup, Button, Spinner } from '@blueprintjs/core';
import { Header } from 'src/components/Header';
import { listOrganizationQuestions, createOrganizationQuestion, removeOrganizationQuestion } from 'src/api/organization';
import { IDailyQuestionResponse } from '../../../api/models/Daily';
import { SettingsForm } from './SettingsForm';

export const Dailys: React.FC = () => {
    const [loading, setLoading] = React.useState(false);
    const [saving, setSaving] = React.useState(false);
    const [removing, setRemoving] = React.useState(false);
    const [error, setError] = React.useState<string | null>(null);
    const [questions, setQuestions] = React.useState<IDailyQuestionResponse[]>([]);
    const [value, setValue] = React.useState('');

    React.useEffect(() => {
        loadQuestions();
    }, []);

    const loadQuestions = async (load = true) => {
        setLoading(load);
        setError(null);
        try {
            const data = await listOrganizationQuestions();
            setQuestions(data);
        } catch (err) {
            setError(err.toLocaleString());
        }
        setLoading(false);
    };

    const createQuestion = async () => {
        setSaving(true);
        const q = {
            question: value,
        };
        await createOrganizationQuestion(q);
        await loadQuestions(false);
        setValue('');
        setSaving(false);
    };

    const removeQuestion = async (q: IDailyQuestionResponse) => {
        setRemoving(true);
        await removeOrganizationQuestion(q.id);
        await loadQuestions(false);
        setValue('');
        setRemoving(false);
    };

    const renderQuestions = () => {
        if (questions.length === 0) {
            return <p>No questions to display</p>;
        }
        return questions.map((q) => {
            return (
                <Row>
                    <Col xs={10}>
                        <p key={q.id} className="p-t-5">
                            {q.question}
                        </p>
                    </Col>
                    <Col className="text-right">
                        <Button intent="danger" icon="trash" minimal onClick={() => removeQuestion(q)} loading={removing} />
                    </Col>
                </Row>
            );
        });
    };
    return (
        <>
            <Header title="Dailys" breadcrumbs={['Settings', 'Dailys']} />
            <Row>
                <Col xs={6}>
                    <Card>
                        {error && <p>{error}</p>}
                        {loading ? (
                            <div className="text-center p-t-50">
                                <Spinner />
                            </div>
                        ) : (
                            <>
                                <h4 className="m-t-0">Default Questions</h4>
                                <div style={{ minHeight: 300 }}>{renderQuestions()}</div>
                                <InputGroup
                                    placeholder="Enter question..."
                                    value={value}
                                    onChange={(e) => setValue(e.target.value)}
                                    rightElement={
                                        <Button intent="primary" minimal onClick={createQuestion} disabled={value.length === 0} loading={saving}>
                                            ADD
                                        </Button>
                                    }
                                />
                            </>
                        )}
                    </Card>
                </Col>
                <Col xs={6}>
                    <SettingsForm />
                </Col>
            </Row>
        </>
    );
};
