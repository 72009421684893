import AppApi from './index';
import { getAuthOptions } from './helpers';
import { IRateResponse, IRateFormValues } from './models/Rate';

const api = AppApi.getApi();

export const listJobRates = async (jobId: number): Promise<IRateResponse[]> => {
    const url = `/jobs/${jobId}/rates`;
    const { data } = await api.get(url, await getAuthOptions());
    return data;
};

export const createJobRate = async (jobId: number, rate: IRateFormValues): Promise<IRateResponse> => {
    const url = `/jobs/${jobId}/rates`;
    const { data } = await api.post(url, rate, await getAuthOptions());
    return data;
};

export const removeJobRate = async (jobId: number, rateId: number): Promise<IRateResponse[]> => {
    const url = `/jobs/${jobId}/rates/${rateId}`;
    const { data } = await api.delete(url, await getAuthOptions());
    return data;
};
