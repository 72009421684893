import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { IDrawing } from 'src/api/models/Drawing';
import { loadDrawingById } from 'src/api/drawings';
import { Button, Spinner, Tab, TabId, Tabs } from '@blueprintjs/core';
import { IHistory } from 'src/api/models/History';
import { useJobs } from 'src/hooks/useJobs';
import { DrawingForm } from '../components/DrawingForm';
import { History } from '../../RFIs/components/History';
import { Header2 } from '../../../../components/Header2';

export const ViewDrawing: React.FC = () => {
    const params: any = useParams();
    const history = useHistory();
    const [error, setError] = React.useState<string | null>(null);
    const [loading, setLoading] = React.useState(false);
    const [drawing, setDrawing] = React.useState<IDrawing | null>(null);
    const [tab, setTab] = React.useState<TabId>('drawing');
    const [drawingHistory, setDrawingHistory] = React.useState<IHistory[]>([]);
    const { selectedJob } = useJobs();

    React.useEffect(() => {
        loadDrawing();
    }, []);

    const loadDrawing = async (load = true) => {
        if (!selectedJob) return;
        setLoading(load);
        setError(null);
        try {
            const data = await loadDrawingById(selectedJob.id, params.drawingId);
            const d = {
                ...data,
                files_to_remove: [],
                files_to_add: [],
            };
            setDrawing(d);
            setDrawingHistory(data.history);
        } catch (err) {
            setError(err.toLocaleString());
        }
        setLoading(false);
    };
    const onSave = (data: IDrawing) => {
        setDrawingHistory(data.history);
    };

    if (!selectedJob) return null;
    if (loading) return <Spinner />;
    if (error) return <p>{error}</p>;
    if (!drawing) return <p>Error loading drawing</p>;
    return (
        <>
            <Header2
                breadcrumbs={[
                    { title: 'Jobs', link: '/jobs' },
                    { title: `${selectedJob?.name} - ${selectedJob?.job_number}`, link: `/jobs/${selectedJob?.id}/summary` },
                    { title: 'Drawings', link: `/jobs/${selectedJob.id}/drawings` },
                    { title: drawing?.drawing_number || 'No Drawing Number', link: '' },
                ]}
                actionComponent={
                    <Button intent="none" onClick={() => history.push(`/jobs/${selectedJob.id}/drawings`)}>
                        Cancel
                    </Button>
                }
            />
            <Tabs onChange={(tabId) => setTab(tabId)} selectedTabId={tab} large>
                <Tab id="drawing" title="Details" panel={<DrawingForm initialValues={drawing} selectedJob={selectedJob} onSaved={onSave} onCreated={console.log} />} />
                <Tab id="history" title="History" panel={<History history={drawingHistory} />} />
                <Tabs.Expander />
            </Tabs>
        </>
    );
};
