import React from 'react';
import { Button } from '@blueprintjs/core';
import { useHistory } from 'react-router-dom';
import { useJobs } from 'src/hooks/useJobs';
import { DrawingForm } from '../components/DrawingForm';
import { useAuth } from 'src/hooks/useAuth';
import { IDrawing } from 'src/api/models/Drawing';
import { Header2 } from '../../../../components/Header2';

const emptyDrawing = {
    title: '',
    type: 'type_1',
    intent_of_review: 'review',
    assignee: null,
    assigned_to: null,
    company: '',
    drawing_number: '',
    external_drawing_number: '',
    status: '',
    workflow: null,
    assignee_user_id: null,
    attachments: [],
    revisions: [],
    files_to_add: [],
    files_to_remove: [],
    step_index: -1,
    job: null,
    id: '',
    created_at: new Date(),
    reporter: null,
    submitted_on: new Date(),
    updated_at: new Date(),
    history: [],
    checklist_item: null,
    checklist_item_id: null,
};

export const SubmitDrawing: React.FC = () => {
    const { selectedJob } = useJobs();
    const { user } = useAuth();
    const history = useHistory();
    const initialValues: IDrawing = React.useMemo(() => {
        return {
            ...emptyDrawing,
            job_id: selectedJob?.id || -1,
            workflow_id: selectedJob ? selectedJob.drawing_workflow_id : 0,
            workflow: selectedJob?.drawing_workflow || null,
            reporter_user_id: user?.id || -1,
            from_user_id: user?.id || -1,
            from_company: user?.company_name || '',
        };
    }, [selectedJob?.drawing_workflow, user?.id]);
    if (!selectedJob) return null;
    return (
        <>
            <Header2
                breadcrumbs={[
                    { title: 'Jobs', link: '/jobs' },
                    { title: `${selectedJob?.name} - ${selectedJob?.job_number}`, link: `/jobs/${selectedJob?.id}/summary` },
                    { title: 'Drawings', link: `/jobs/${selectedJob.id}/drawings` },
                    { title: 'Create Drawing', link: '' },
                ]}
                actionComponent={
                    <Button intent="none" onClick={() => history.push(`/jobs/${selectedJob.id}/drawings`)}>
                        Cancel
                    </Button>
                }
            />
            <DrawingForm initialValues={initialValues} selectedJob={selectedJob} onSaved={console.log} onCreated={(drawing) => history.push(`/jobs/${selectedJob.id}/drawings/${drawing.id}`)} />
        </>
    );
};
