import AppApi from './index';
import { getAuthOptions } from './helpers';
import { IPhaseFormValues, IPhase, IChecklistItemFormValues, IChecklistItem, IEnhancedPhase, ILinkItem } from './models/Checklist';

const api = AppApi.getApi();

export const listPhases = async (): Promise<IPhase[]> => {
    const url = `/checklists/phases`;
    const { data } = await api.get(url, await getAuthOptions());
    return data;
};

export const listJobPhases = async (jobId: number): Promise<IPhase[]> => {
    const url = `/checklists/${jobId}/phases`;
    const { data } = await api.get(url, await getAuthOptions());
    return data;
};

export const listEnhancedJobPhases = async (jobId: number): Promise<IEnhancedPhase[]> => {
    const url = `/checklists/${jobId}/enhancedPhases`;
    const { data } = await api.get(url, await getAuthOptions());
    return data;
};

export const createPhase = async (phase: IPhaseFormValues): Promise<IPhaseFormValues> => {
    const url = `/checklists/phases`;
    const { data } = await api.post(url, phase, await getAuthOptions());
    return data;
};

export const removePhase = async (phaseId: string): Promise<IPhaseFormValues> => {
    const url = `/checklists/phases/${phaseId}`;
    const { data } = await api.delete(url, await getAuthOptions());
    return data;
};

export const listItems = async (jobId: number | string, filters: any): Promise<IChecklistItem[]> => {
    const url = `/checklists/${jobId}/query`;
    const { data } = await api.post(url, filters, await getAuthOptions());
    return data;
};

export const loadItemLinks = async (jobId: number, itemId: string): Promise<ILinkItem[]> => {
    const url = `/checklists/${jobId}/links/${itemId}`;
    const { data } = await api.get(url, await getAuthOptions());
    return data;
};
export const createTemplateItem = async (item: IChecklistItemFormValues): Promise<IChecklistItemFormValues> => {
    const url = `/checklists/default/items`;
    const { data } = await api.post(url, item, await getAuthOptions());
    return data;
};

export const updateTemplateItem = async (item: IChecklistItemFormValues, itemId: string): Promise<IChecklistItemFormValues> => {
    const url = `/checklists/default/items/${itemId}`;
    const { data } = await api.put(url, item, await getAuthOptions());
    return data;
};

export const toggleItemComplete = async (itemId: string): Promise<IChecklistItemFormValues> => {
    const url = `/checklists/items/toggle/${itemId}`;
    const { data } = await api.post(url, null, await getAuthOptions());
    return data;
};

export const createItem = async (item: IChecklistItemFormValues, jobId: number): Promise<IChecklistItemFormValues> => {
    const url = `/checklists/${jobId}/items`;
    const { data } = await api.post(url, item, await getAuthOptions());
    return data;
};

export const removeItem = async (itemId: string): Promise<IChecklistItemFormValues> => {
    const url = `/checklists/items/${itemId}`;
    const { data } = await api.delete(url, await getAuthOptions());
    return data;
};

export const updateItem = async (item: IChecklistItemFormValues, jobId: number, itemId: string): Promise<IChecklistItemFormValues> => {
    const url = `/checklists/${jobId}/items/${itemId}`;
    const { data } = await api.put(url, item, await getAuthOptions());
    return data;
};
