import React from 'react';
import { Row, Col } from 'react-grid-system';
import { useHistory } from 'react-router-dom';
import { Breadcrumbs, BreadcrumbProps } from '@blueprintjs/core';

interface IBreadCrumb {
    title: string;
    link: string;
}
interface IHeader2Props {
    title?: string;
    actionComponent?: React.ReactElement;
    breadcrumbs?: IBreadCrumb[];
    className?: string;
}

export const Header2: React.FC<IHeader2Props> = ({ breadcrumbs, className = 'm-t-20 m-b-20', title, actionComponent }) => {
    const history = useHistory();
    return (
        <Row className={`${className}`}>
            <Col xs={7}>
                {breadcrumbs ? (
                    <Breadcrumbs
                        items={breadcrumbs.map((br, idx) => {
                            let item: BreadcrumbProps = {
                                text: br.title,
                            };
                            if (br.link) {
                                item = {
                                    ...item,
                                    onClick: br.link !== '' ? () => history.push(br.link) : () => history.goBack(),
                                };
                            }
                            return item;
                        })}
                    />
                ) : (
                    <Breadcrumbs items={[{ text: title }]} />
                )}
            </Col>
            <Col className="text-right">{actionComponent}</Col>
        </Row>
    );
};
