import React from 'react';
import { Row, Col } from 'react-grid-system';
import LinesEllipsis from 'react-lines-ellipsis';
import { Card, Elevation, H5, Button, Intent } from '@blueprintjs/core';
import { Classes as TTClasses, Popover2 } from '@blueprintjs/popover2';
import { IOrganizationOffice } from 'src/api/models/Organization';
import { deleteOrganizationOffice } from 'src/api/organization';
import { TopToast } from 'src/components/Toasts';

interface IOfficeCardProps {
    office: IOrganizationOffice;
    onClick: (role: IOrganizationOffice) => void;
    onRoleDeleted: (role: IOrganizationOffice) => void;
}

export const OfficeCard: React.FC<IOfficeCardProps> = ({ office, onClick, onRoleDeleted }) => {
    const [isDeleting, setIsDeleting] = React.useState(false);

    const deleteRole = async () => {
        setIsDeleting(true);
        try {
            await deleteOrganizationOffice(office);
            onRoleDeleted(office);
            TopToast.show({ message: 'Office Deleted!', intent: 'success', icon: 'tick-circle' });
        } catch (err) {
            TopToast.show({ message: err.message, intent: 'danger', icon: 'warning-sign', timeout: 5000000 });
        }
        setIsDeleting(false);
    };

    return (
        <Card elevation={Elevation.TWO} style={{ height: 125 }}>
            <Row>
                <Col xs={12} sm={9}>
                    <h3 className="m-t-0">
                        <a onClick={() => onClick(office)}>{office.name}</a>
                    </h3>
                </Col>
                <Col className="text-right">
                    <Popover2
                        minimal
                        interactionKind="click"
                        popoverClassName={TTClasses.POPOVER2_CONTENT_SIZING}
                        enforceFocus={false}
                        position="right"
                        content={
                            <div key="text">
                                <H5>Are you sure?</H5>
                                <p>Are you sure you want to delete role? This office will be unassigned from all assigned users.</p>
                                <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 15 }}>
                                    <Button className={TTClasses.POPOVER2_DISMISS} style={{ marginRight: 10 }}>
                                        Cancel
                                    </Button>
                                    <Button intent={Intent.DANGER} className={TTClasses.POPOVER2_DISMISS} onClick={deleteRole} loading={isDeleting}>
                                        Delete
                                    </Button>
                                </div>
                            </div>
                        }
                    >
                        <Button minimal icon="trash" />
                    </Popover2>
                </Col>
            </Row>
            <div style={{ height: 25 }}>
                <LinesEllipsis text={office.description} maxLine="2" ellipsis="..." trimRight basedOn="letters" />
            </div>
            <Row>
                {/*<Col xs={8}>{office.users.length > 0 ? <AvatarGroup data={avatarList} maxCount={5} size="small" /> : <p className="bp3-text-muted">No accounts with role</p>}</Col>*/}
                <Col>
                    <span className="bp3-text-muted">{office.divisions.length} Divisions</span>
                </Col>
                <Col className="text-right">
                    <span className="bp3-text-muted">{office.users.length} Users</span>
                </Col>
            </Row>
        </Card>
    );
};
