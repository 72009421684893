import React from 'react';
import { InputGroup, Label, Button, Colors } from '@blueprintjs/core';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { requestPasswordReset } from 'src/api/user';
import { useHistory } from 'react-router-dom';
import logo from '../../../assets/images/firetrol.png';

const validationSchema = Yup.object().shape({
    email: Yup.string().required().email('Email Required').label('Email'),
});

interface IForgotFormValues {
    email: string;
}

const initialValues: IForgotFormValues = {
    email: '',
};

export const ForgotPassword: React.FC = () => {
    const history = useHistory();
    const [complete, setComplete] = React.useState(false);
    const [loginError, setLoginError] = React.useState<string | null>(null);
    const formik = useFormik({
        initialValues,
        validationSchema,
        validateOnMount: true,
        onSubmit: async (values) => {
            setLoginError(null);
            try {
                await requestPasswordReset(values.email);
                setComplete(true);
            } catch (error) {
                setLoginError('Invalid email or password');
            }
        },
    });

    const handleKeyDown = (e: any) => {
        if (e.key === 'Enter') {
            formik.handleSubmit();
        }
    };

    return (
        <>
            <div className="bp3-dialog-container" style={{ marginTop: 200 }}>
                <div className="bp3-dialog bg-white">
                    <img className="login-logo" src={logo} alt="" style={{ maxWidth: 300, height: 'auto', margin: 'auto', marginTop: 25 }} />
                    <div className={`bp3-dialog-body ${loginError ? 'm-b-0' : ''}`}>
                        <h2 className="m-0 m-t-15 text-center">{complete ? 'Instructions Sent' : 'Forgot Password'}</h2>
                        {!complete && <p className="m-t-15 m-b-15 text-center">Enter your email to continue</p>}
                        {complete ? (
                            <p className="text-center m-t-15">
                                Instructions on how to reset your password have been sent to <b>{formik.values.email}</b>
                            </p>
                        ) : (
                            <Label>
                                Email
                                <InputGroup
                                    placeholder="Enter email..."
                                    autoFocus
                                    type="text"
                                    onChange={(e) => formik.setFieldValue('email', e.target.value)}
                                    value={formik.values.email}
                                    onKeyDown={handleKeyDown}
                                    autoComplete="off"
                                    className="m-b-15"
                                />
                            </Label>
                        )}
                    </div>
                    {!complete ? (
                        <div className="bp3-dialog-footer">
                            {loginError && <p style={{ color: Colors.RED2, textAlign: 'center' }}>{loginError}</p>}
                            <div className="bp3-dialog-footer-actions">
                                <Button
                                    text="Reset Password"
                                    type="submit"
                                    fill
                                    intent="primary"
                                    loading={formik.isSubmitting}
                                    disabled={!formik.isValid}
                                    className="m-0"
                                    onClick={() => formik.handleSubmit()}
                                />
                            </div>
                        </div>
                    ) : (
                        <div className="bp3-dialog-footer">
                            <div className="bp3-dialog-footer-actions">
                                <Button text="Back to Login" type="submit" fill intent="primary" className="m-0" onClick={() => history.push('/login')} />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};
