import React from 'react';
import { Row, Col } from 'react-grid-system';
import { Header } from '../../../components/Header';
import { Button } from '@blueprintjs/core';
import { CreateContactDialog } from './components/CreateContactDialog';
export const Contacts: React.FC = () => {
    const [createContactOpen, setCreateContactOpen] = React.useState(false);
    return (
        <>
            <Header
                title="Contacts"
                breadcrumbs={['Settings', 'Contacts']}
                actionComponent={<Button text="Create Contact" onClick={() => setCreateContactOpen(true)} intent="primary" icon="new-person" />}
            />
            <CreateContactDialog open={createContactOpen} onClose={() => setCreateContactOpen(false)} />
            <Row>
                <Col xs={12} md={12} lg={6}>
                    Contacts
                </Col>
            </Row>
        </>
    );
};
