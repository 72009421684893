import React from 'react';
import { Col, Row } from 'react-grid-system';
import { Spinner, Card, InputGroup, FormGroup, TextArea, Button, Radio, RadioGroup, Switch } from '@blueprintjs/core';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { RateSheetCard } from 'src/components/RateSheetCard';
import { IRateResponse } from 'src/api/models/Rate';
import { IWorkTicketFormValues, IWorkTicketResponse } from 'src/api/models/WorkTicket';
import { listJobRates } from 'src/api/rates';
import { createWorkTicket, updateWorkTicket } from 'src/api/tickets';
import { DatePicker } from 'src/components/DatePicker';
import { ItemsContainer } from './ItemsContainer';
import './index.scss';
import { useFormik } from 'formik';
import { useWindowSize } from 'src/hooks/useWindowSize';
import * as Yup from 'yup';
import SignatureCanvas from 'react-signature-canvas';
import { JobUserSelect } from 'src/components/Selectors/JobUserSelect/single';
import { AppToast } from '../../../../components/Toasts/AppToast';
import { useHistory } from 'react-router-dom';
import { ImageUpload } from '../../../../components/ImageUpload';
import { useAuth } from '../../../../hooks/useAuth';

interface IWorkTicketFormProps {
    jobId: number;
    ticketId?: string;
    cancelButton?: React.ReactElement;
    sideCancelButton?: React.ReactElement;
    initialValues: IWorkTicketFormValues;
    onTicketCreated?: (ticket: IWorkTicketResponse) => void;
    onTicketUpdated?: (ticket: IWorkTicketResponse) => void;
}

const validationSchema = Yup.object().shape({
    summary: Yup.string().required().min(1, 'Summary Required').label('Summary'),
});

export const WorkTicketForm: React.FC<IWorkTicketFormProps> = ({ jobId, initialValues, sideCancelButton, ticketId, onTicketCreated, cancelButton }) => {
    const history = useHistory();
    const signatureRef = React.useRef();
    const size = useWindowSize();
    const { isAdmin } = useAuth();
    const widthRef = React.useRef(null);
    const [sigWidth, setSigRef] = React.useState(600);
    const [showTotal, setShowTotal] = React.useState(false);
    const [rates, setRates] = React.useState<IRateResponse[]>([]);
    const [loading, setLoading] = React.useState(false);
    const [saving, setSaving] = React.useState(false);
    const [error, setError] = React.useState<string | null>(null);
    React.useEffect(() => {
        loadJobRates();
    }, []);

    React.useEffect(() => {
        if (signatureRef && signatureRef.current) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            signatureRef.current.fromData(initialValues.signature);
        }
    }, [signatureRef.current, initialValues]);

    const formik = useFormik({
        initialValues,
        validationSchema,
        validateOnMount: true,
        onSubmit: async () => {
            console.log('submit');
        },
    });
    React.useEffect(() => {
        if (widthRef && widthRef.current) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            setSigRef(widthRef.current.offsetWidth);
        }
    }, [widthRef.current, size, formik.values.use_signature_pad]);

    const loadJobRates = async (load = true) => {
        setLoading(load);
        const data = await listJobRates(jobId);
        setRates(data);
        setLoading(false);
    };

    const { values, setFieldValue } = formik;

    const onItemsChange = (items: IRateResponse[]) => {
        setFieldValue('items', items);
    };

    const submitTicket = async () => {
        setSaving(true);
        setError(null);
        try {
            const result = await createWorkTicket(jobId, values);
            AppToast.show({ message: `Work ticket created!`, intent: 'success', icon: 'tick' });
            if (onTicketCreated) {
                onTicketCreated(result);
            } else {
                history.push(`/jobs/${result.job_id}/tickets/view/${result.id}`);
            }
        } catch (err) {
            setError(err.toLocaleString());
        }
        setSaving(false);
    };

    const updateTicket = async () => {
        if (!ticketId) return;
        setSaving(true);
        setError(null);
        try {
            const result = await updateWorkTicket(jobId, ticketId, values);
            AppToast.show({ message: `Work ticket updated!`, intent: 'success', icon: 'tick' });
            history.push(`/jobs/${result.job_id}/tickets/view/${result.id}`);
        } catch (err) {
            setError(err.toLocaleString());
        }
        setSaving(false);
    };

    return (
        <DndProvider backend={HTML5Backend}>
            <Row>
                <Col>
                    <Card className="m-b-20">
                        <h3 className="m-t-0">Work Ticket</h3>
                        <Row>
                            <Col>
                                <FormGroup label="Created By">
                                    <InputGroup value={values.created_by?.full_name} disabled fill />
                                </FormGroup>
                            </Col>
                            <Col>
                                <DatePicker label="Date Requested" value={values.date} onChange={(val) => setFieldValue('date', val)} />
                            </Col>
                            <Col>
                                <FormGroup label="Status" labelFor="status">
                                    <div className="bp3-html-select bp3-fill">
                                        <select onChange={(e) => setFieldValue('status', e.target.value)} value={values.status}>
                                            <option value="Created">Created</option>
                                            <option value="Approved">Approved</option>
                                            <option value="Complete">Complete</option>
                                            <option value="Billed To Customer">Billed To Customer</option>
                                        </select>
                                        <span className="bp3-icon bp3-icon-double-caret-vertical" />
                                    </div>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <h4 className="m-b-10 m-t-10">Ticket Summary</h4>
                            </Col>
                        </Row>
                        <Row className="m-b-15">
                            <Col>
                                <TextArea
                                    id="desc"
                                    style={{ height: '100%', maxWidth: '100%' }}
                                    value={values.summary}
                                    onChange={(e) => setFieldValue('summary', e.target.value)}
                                    name="summary"
                                    growVertically={true}
                                    fill
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup label="Requested By">
                                    <InputGroup value={values.requester} onChange={(e) => setFieldValue('requester', e.target.value)} fill />
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup label="Authorized Date">
                                    <DatePicker value={values.work_performed_date || undefined} onChange={(val) => setFieldValue('work_performed_date', val)} />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup label="Recipient">
                                    <JobUserSelect
                                        hideLabel
                                        small
                                        hideRole
                                        selectedUserId={formik.values.recipient_id}
                                        onChange={(value) => {
                                            if (value) {
                                                formik.setFieldValue('recipient_id', value.user_id);
                                            } else {
                                                formik.setFieldValue('recipient_id', null);
                                            }
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                            <Col xs={6}>
                                <FormGroup label="Signature Type">
                                    <RadioGroup
                                        inline
                                        onChange={(e: any) => {
                                            const updated = e.target.value === 'true';
                                            if (!updated) {
                                                if (signatureRef && signatureRef.current) {
                                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                                    // @ts-ignore
                                                    signatureRef.current.fromData([]);
                                                }
                                                setFieldValue('signature', []);
                                            }
                                            setFieldValue('use_signature_pad', updated);
                                        }}
                                        selectedValue={values.use_signature_pad ? 'true' : 'false'}
                                    >
                                        <Radio label="Signature" value="true" />
                                        <Radio label="Print" value="false" />
                                    </RadioGroup>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Row>
                                    <Col xs={10}>
                                        <div ref={widthRef} />
                                        <FormGroup label="Signature">
                                            <SignatureCanvas
                                                ref={signatureRef}
                                                penColor="black"
                                                onEnd={() => {
                                                    if (signatureRef && signatureRef.current) {
                                                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                                        // @ts-ignore
                                                        setFieldValue('signature', signatureRef?.current?.toData());
                                                    }
                                                }}
                                                canvasProps={{
                                                    width: sigWidth,
                                                    height: 120,
                                                    className: 'sigCanvas',
                                                    style: { border: '1px solid #d8d8d9', display: values.use_signature_pad ? 'block' : 'none' },
                                                }}
                                            />
                                            {values.use_signature_pad ? <></> : <InputGroup value={values.signature_string} onChange={(e) => setFieldValue('signature_string', e.target.value)} fill />}
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Card>
                    <Row>
                        <Col>
                            <Card className="m-b-20">
                                <Row>
                                    <Col>
                                        <h4 className="m-b-10 m-t-0">Items</h4>
                                    </Col>
                                    <Col className="text-right">
                                        {isAdmin && <Switch checked={showTotal} alignIndicator="right" label="Show Rates" onChange={(e: any) => setShowTotal(e.target.checked)} />}
                                    </Col>
                                </Row>
                                <ItemsContainer onItemsChanged={onItemsChange} items={values.items} showTotal={showTotal} />
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Card className="m-b-20">
                                <h4 className="m-b-10 m-t-0">Images</h4>
                                <ImageUpload
                                    onFilesAdded={(files) => setFieldValue('files_to_add', files)}
                                    onFilesRemoved={(files) => setFieldValue('files_to_remove', files)}
                                    existingFiles={values.images}
                                />
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-right">
                            <Card className="m-b-20">
                                {error && <p>{error}</p>}
                                {cancelButton && <>{cancelButton}</>}
                                {ticketId ? (
                                    <Button intent="primary" onClick={updateTicket} loading={saving}>
                                        Save Changes
                                    </Button>
                                ) : (
                                    <Button intent="primary" onClick={submitTicket} loading={saving}>
                                        Save Work Ticket
                                    </Button>
                                )}
                            </Card>
                        </Col>
                    </Row>
                </Col>
                <Col xs={4} md={4} lg={4}>
                    {loading ? (
                        <div className="text-center p-t-50">
                            <Spinner />
                        </div>
                    ) : (
                        <>
                            {values.daily && values.daily_id && (
                                <Button intent="primary" minimal className="m-b-15" fill onClick={() => history.push(`/jobs/${values.job_id}/dailys/view/${values.daily_id}`)} loading={saving}>
                                    LINKED TO {values.daily.daily_number}
                                </Button>
                            )}
                            {ticketId ? (
                                <Button fill className="m-b-15" intent="primary" onClick={updateTicket} loading={saving}>
                                    Save Changes
                                </Button>
                            ) : (
                                <Button intent="primary" className="m-b-15" fill onClick={submitTicket} loading={saving}>
                                    Save Work Ticket
                                </Button>
                            )}
                            {sideCancelButton && <>{sideCancelButton}</>}
                            <RateSheetCard jobId={jobId} interactable rates={rates} onRatesChanged={() => loadJobRates(false)} />
                        </>
                    )}
                </Col>
            </Row>
        </DndProvider>
    );
};
