import React from 'react';
import { Classes, Button, FormGroup, InputGroup, Intent, Colors, TextArea } from '@blueprintjs/core';
import { Row, Col } from 'react-grid-system';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { createOrganizationOffice, updateOrganizationOffice } from 'src/api/organization';
import { IOrganizationOffice } from 'src/api/models/Organization';
import { useOrganization } from 'src/hooks/useOrganization';
import { BottomToast } from '../../Toasts';
import { DivisionSelector } from './DivisionSelector';
const validationSchema = Yup.object().shape({
    name: Yup.string().required().min(1, 'Name Required').label('Name'),
});

const initialValues: Pick<IOrganizationOffice, 'id' | 'name' | 'description' | 'divisions'> = {
    id: -1,
    name: '',
    description: '',
    divisions: [],
};

interface ICreateRoleForm {
    selectedOffice?: IOrganizationOffice;
    setCreateOfficeDialogOpen: (isOpen: boolean, role?: IOrganizationOffice) => void;
}

export const CreateOfficeForm: React.FC<ICreateRoleForm> = ({ selectedOffice, setCreateOfficeDialogOpen }) => {
    const { onOfficeCreated, onOfficeUpdated } = useOrganization();
    const [error, setError] = React.useState<string | null>(null);
    const closeDialog = () => {
        setCreateOfficeDialogOpen(false);
        formik.resetForm();
    };
    const formik = useFormik({
        initialValues: selectedOffice ? selectedOffice : initialValues,
        validationSchema,
        validateOnMount: true,
        onSubmit: async (values) => {
            setError(null);
            try {
                if (selectedOffice) {
                    const result = await updateOrganizationOffice(values);
                    onOfficeUpdated(result);
                    setCreateOfficeDialogOpen(false);
                    BottomToast.show({ message: 'Office successfully updated!', intent: 'success', icon: 'updated' });
                } else {
                    const result = await createOrganizationOffice(values);
                    onOfficeCreated(result);
                    setCreateOfficeDialogOpen(false);
                    BottomToast.show({ message: 'Office successfully created!', intent: 'success', icon: 'tick-circle' });
                }
            } catch (err) {
                setError(err.message);
                BottomToast.show({ message: err.message, intent: 'danger', icon: 'warning-sign' });
            }
        },
    });

    const onDivisionChange = (divisions: string[]) => {
        formik.setFieldValue('divisions', divisions);
    };

    const handleKeyDown = (e: any) => {
        if (e.key === 'Enter') {
            formik.handleSubmit();
        }
    };
    return (
        <>
            <div className={Classes.DIALOG_BODY}>
                <Row>
                    <Col>
                        <FormGroup label="Office Name" labelFor="role-name-input" labelInfo="*">
                            <InputGroup
                                autoFocus
                                autoComplete="off"
                                id="role-name-input"
                                placeholder="What do you want to call this office?"
                                value={formik.values.name}
                                onKeyDown={handleKeyDown}
                                onChange={(e) => formik.setFieldValue('name', e.target.value)}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup label="CTC Divisions" helperText="Divisions control view access to CTC">
                            <DivisionSelector initialValue={formik.values.divisions} onChange={onDivisionChange} />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup label="Description">
                            <TextArea
                                placeholder="Enter a description for this office..."
                                fill
                                growVertically={true}
                                value={formik.values.description}
                                onChange={(e) => formik.setFieldValue('description', e.target.value)}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                {error && (
                    <p className="m-b-0" style={{ color: Colors.RED5 }}>
                        {error}
                    </p>
                )}
            </div>
            <div className="m-b-20">
                <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Button onClick={closeDialog}>Cancel</Button>
                        <Button onClick={() => formik.handleSubmit()} intent={Intent.PRIMARY} loading={formik.isSubmitting} disabled={!formik.isValid || !formik.dirty}>
                            {selectedOffice ? 'Save Changes' : 'Create Office'}
                        </Button>
                    </div>
                </div>
            </div>
        </>
    );
};
