import React from 'react';
import { IconButton, Card, Box, ImageList } from '@mui/material';

import { download } from 'src/api/attachments';
import { downloadDocument } from 'src/api/documents';
import { IAttachment } from 'src/api/models/Attachment';
import { IconLookup24, humanFileSize } from 'src/routes/Jobs/Documents/utils';
import ClearIcon from '@mui/icons-material/Clear';
import { Image } from './Image';
import { IDocument } from 'src/api/models/Document';

interface PostFilesProps {
    onFileRemoved: (file: IAttachment) => void;
    onDocumentRemoved: (file: IDocument) => void;
    files: IAttachment[];
    documents: IDocument[];
}
const acceptedImageTypes = ['gif', 'jpeg', 'png', 'jpg'];
export const PostFiles: React.FC<PostFilesProps> = ({ files, documents, onFileRemoved, onDocumentRemoved }) => {
    const images = React.useMemo(() => {
        return files.filter((f) => acceptedImageTypes.indexOf(f.type) >= 0);
    }, [files]);

    const nonImages = React.useMemo(() => {
        return files.filter((f) => acceptedImageTypes.indexOf(f.type) < 0);
    }, [files]);

    const downloadFile = async (id: string) => {
        await download(id);
    };

    const downloadDoc = async (doc: IDocument) => {
        await downloadDocument(doc.job_id, doc.id);
    };
    const removeAttachment = (attachment: IAttachment) => {
        onFileRemoved(attachment);
    };

    const removeDocument = (doc: IDocument) => {
        onDocumentRemoved(doc);
    };

    const renderImages = () => {
        return images.map((att) => {
            return <Image key={att.id} id={att.id} title={att.name} onRemoveClick={() => removeAttachment(att)} onImageClick={() => downloadFile(att.id)} />;
        });
    };

    const renderFiles = () => {
        return nonImages.map((file) => {
            return (
                <Card variant="outlined" className="file-item m-b-10" key={file.id}>
                    <Box sx={{ display: 'flex', alignItems: 'center', p: 1, mr: 2 }}>
                        <div>{IconLookup24[file.type]}</div>
                        <div className="m-l-20" style={{ width: '65%' }}>
                            <a onClick={() => downloadFile(file.id)}>{file.name}</a>
                        </div>
                        <div className="m-l-20 text-right bp3-text-muted p-r-30" style={{ width: '35%' }}>
                            {humanFileSize(file.size)}
                        </div>
                    </Box>

                    <div className="file-item-delete">
                        <IconButton onClick={() => removeAttachment(file)} size="small">
                            <ClearIcon />
                        </IconButton>
                    </div>
                </Card>
            );
        });
    };

    const renderDocuments = () => {
        return documents.map((file) => {
            return (
                <Card variant="outlined" className="file-item m-b-10" key={file.id}>
                    <Box sx={{ display: 'flex', alignItems: 'center', p: 1, mr: 2 }}>
                        <div>{IconLookup24[file.type]}</div>
                        <div className="m-l-20" style={{ width: '65%' }}>
                            <a onClick={() => downloadDoc(file)}>
                                {file.name}.{file.type}
                            </a>
                        </div>
                        <div className="m-l-20 text-right bp3-text-muted p-r-30" style={{ width: '35%' }}>
                            {humanFileSize(file.size)}
                        </div>
                    </Box>

                    <div className="file-item-delete">
                        <IconButton onClick={() => removeDocument(file)} size="small">
                            <ClearIcon />
                        </IconButton>
                    </div>
                </Card>
            );
        });
    };

    return (
        <>
            {renderDocuments()}
            {renderFiles()}
            {images.length > 0 && (
                <ImageList sx={{ width: '100%', height: 200 }} cols={3}>
                    {renderImages()}
                </ImageList>
            )}
        </>
    );
};
