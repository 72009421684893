import { IChangeOrderFormValues, IChangeOrderResponse } from './models/ChangeOrder';
import { getAuthOptions } from './helpers';
import AppApi from './index';
const api = AppApi.getApi();

export const getChangeById = async (jobId: number, changeId: string): Promise<IChangeOrderResponse> => {
    const url = `/jobs/${jobId}/changes/${changeId}`;
    const { data } = await api.get(url, await getAuthOptions());
    return data;
};

export const listChanges = async (jobId: number): Promise<IChangeOrderResponse[]> => {
    const url = `/jobs/${jobId}/changes`;
    const { data } = await api.get(url, await getAuthOptions());
    return data;
};

export const createChangeOrder = async (jobId: number, changes: IChangeOrderFormValues): Promise<IChangeOrderResponse> => {
    const url = `/jobs/${jobId}/changes`;
    const { data } = await api.post(url, changes, await getAuthOptions());
    return data;
};

export const updateChangeOrder = async (jobId: number, changeId: string, changes: IChangeOrderFormValues): Promise<IChangeOrderResponse> => {
    const url = `/jobs/${jobId}/changes/${changeId}`;
    const { data } = await api.put(url, changes, await getAuthOptions());
    return data;
};

export const removeChangeOrder = async (jobId: number, changeId: string): Promise<IChangeOrderResponse> => {
    const url = `/jobs/${jobId}/changes/${changeId}`;
    const { data } = await api.delete(url, await getAuthOptions());
    return data;
};
