import React from 'react';
import { Row, Col } from 'react-grid-system';
import { Card, RadioGroup, Radio, TextArea } from '@blueprintjs/core';

interface QuestionBoxProps {
    question: any;
    onValueChange: (id: number, val: string) => void;
    onNoteChange: (id: number, val: string) => void;
}

export const QuestionBox: React.FC<QuestionBoxProps> = ({ question, onValueChange, onNoteChange }) => {
    return (
        <Col xs={6} className="m-b-15">
            <Card>
                <Row>
                    <Col xs={8} className="m-b-15">
                        <p className="p-t-5">{question.question}</p>
                    </Col>
                    <Col className="text-right p-t-5">
                        <RadioGroup inline onChange={(e: any) => onValueChange(question.id, e.target.value)} selectedValue={question.value}>
                            <Radio label="No" value="no" />
                            <Radio label="Yes" value="yes" />
                        </RadioGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <TextArea
                            placeholder={question.value === 'yes' ? 'Enter note...' : ''}
                            value={question.note}
                            onChange={(e) => onNoteChange(question.id, e.target.value)}
                            disabled={question.value === 'no'}
                            style={{ height: '100%', maxWidth: '100%' }}
                            name="description"
                            growVertically={true}
                            fill
                        />
                    </Col>
                </Row>
            </Card>
        </Col>
    );
};
