import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Spinner } from '@blueprintjs/core';
import { CreateWorkflow } from 'src/components/Workflow/CreateWorkflow';
import { loadWorkflowById } from 'src/api/workflows';
import { useJobs } from 'src/hooks/useJobs';
import { IWorkflowStep, IWorkflowSettings, IWorkflowResponse } from 'src/api/models/Workflow';
import { Header2 } from '../../../../components/Header2';

export const EditWorkflow: React.FC = () => {
    const params: any = useParams();
    const history = useHistory();
    const { selectedJob } = useJobs();
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState<string | null>(null);
    const [workflowSettings, setWorkflowSettings] = React.useState<IWorkflowSettings | null>(null);
    const [workflowSteps, setWorkflowSteps] = React.useState<IWorkflowStep[]>([]);
    React.useEffect(() => {
        fetchWorkflow();
    }, [params.workflowId]);

    const fetchWorkflow = async (load = true) => {
        if (!selectedJob) return;
        setLoading(load);
        setError(null);
        try {
            const result = await loadWorkflowById(params.workflowId);
            const { steps } = result;
            setWorkflowSteps(steps);
            if (result.is_template) {
                result.id = -1;
                result.is_template = false;
                result.name = `${result.name} (Copy)`;
                result.job_id = selectedJob.id;
            }
            setWorkflowSettings(result);
        } catch (err) {
            // console.log('error: ', err);
            setError(err.toLocaleString());
        }
        setLoading(false);
    };

    const onSaveComplete = async (workflow?: IWorkflowResponse) => {
        if (!selectedJob || !workflow) return;
        if (params.workflowId !== workflow.id) {
            history.push(`/jobs/${selectedJob.id}/workflows/${workflow.id}`);
        }
    };
    if (loading) return <Spinner />;
    if (error) {
        return <p>{error}</p>;
    }
    if (!workflowSettings && !loading) {
        return <p>No Workflow</p>;
    }
    if (!workflowSettings) {
        return <Spinner />;
    }
    return (
        <>
            <Header2
                breadcrumbs={[
                    { title: 'Jobs', link: '/jobs' },
                    { title: `${selectedJob?.name} - ${selectedJob?.job_number}`, link: `/jobs/${selectedJob?.id}/summary` },
                    { title: 'Workflows', link: `/jobs/${selectedJob?.id}/workflows` },
                    { title: workflowSettings.name, link: '' },
                ]}
            />
            {/*<CreateWorkflow initialSettings={settings} initialSteps={STEPS} />*/}
            <CreateWorkflow initialSettings={workflowSettings} initialSteps={workflowSteps} onSaveComplete={(workflow) => onSaveComplete(workflow)} />
        </>
    );
};
