import axios, { AxiosInstance } from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

/**
 * A wrapper for RGT API requests to be made through Axios, which provides a feature-rich
 * and convenient way to make API requests.
 */
export default class AppApi {
    private static instance: AppApi = new AppApi();

    api: AxiosInstance;

    static getInstance(): AppApi {
        return AppApi.instance;
    }

    static getApi(): AxiosInstance {
        return AppApi.instance.api;
    }

    private constructor() {
        this.api = axios.create({ baseURL: API_BASE_URL });
    }
}
