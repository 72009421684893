import React from 'react';
import { Button, Spinner, Tabs, Tab, TabId } from '@blueprintjs/core';
import { useParams, useHistory } from 'react-router-dom';
import { loadRFIById } from '../../../../api/rfis';
import { useJobs } from '../../../../hooks/useJobs';
import { IRFI } from 'src/api/models/RFI';
import { RFIForm } from '../components/RFIForm';
import { History } from '../components/History';
import { IHistory } from 'src/api/models/History';
import { Header2 } from '../../../../components/Header2';

export const ViewRFI: React.FC = () => {
    const params: any = useParams();
    const history = useHistory();
    const [error, setError] = React.useState<string | null>(null);
    const [loading, setLoading] = React.useState(false);
    const [rfi, setRfi] = React.useState<IRFI | null>(null);
    const [tab, setTab] = React.useState<TabId>('rfi');
    const [rfiHistory, setRfiHistory] = React.useState<IHistory[]>([]);
    const { selectedJob } = useJobs();

    React.useEffect(() => {
        loadRfi();
    }, []);

    const loadRfi = async (load = true) => {
        if (!selectedJob) return;
        setLoading(load);
        setError(null);
        try {
            const data = await loadRFIById(selectedJob.id, params.rfiId);
            setRfi(data);
            setRfiHistory(data.history);
        } catch (err) {
            setError(err.toLocaleString());
        }
        setLoading(false);
    };
    const onSave = (data: IRFI) => {
        setRfiHistory(data.history);
    };
    if (loading) return <Spinner />;
    if (!rfi || !selectedJob) return <p>Unable to find RFI</p>;
    if (error) return <p>{error}</p>;
    return (
        <>
            <Header2
                breadcrumbs={[
                    { title: 'Jobs', link: '/jobs' },
                    { title: `${selectedJob?.name} - ${selectedJob?.job_number}`, link: `/jobs/${selectedJob?.id}/summary` },
                    { title: 'RFIs', link: `/jobs/${selectedJob.id}/rfis` },
                    { title: rfi?.rfi_number || 'No RFI Number', link: '' },
                ]}
                actionComponent={
                    <Button intent="none" onClick={() => history.push(`/jobs/${selectedJob.id}/rfis`)}>
                        Cancel
                    </Button>
                }
            />
            <Tabs onChange={(tabId) => setTab(tabId)} selectedTabId={tab} large>
                <Tab id="rfi" title="Details" panel={<RFIForm initialValues={rfi} onSaved={onSave} selectedJob={selectedJob} />} />
                <Tab id="history" title="History" panel={<History history={rfiHistory} />} />
                <Tabs.Expander />
            </Tabs>
        </>
    );
};
