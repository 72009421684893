import React from 'react';
import { Button } from '@blueprintjs/core';
import { useHistory } from 'react-router-dom';
import { useJobs } from 'src/hooks/useJobs';
import { SubmittalForm } from '../components/SubmittalForm';
import { useAuth } from 'src/hooks/useAuth';
import { ISubmittal } from 'src/api/models/Submittal';
import { Header2 } from '../../../../components/Header2';

const emptySubmittal = {
    title: '',
    type: 'type_1',
    intent_of_review: 'review',
    assignee: null,
    assigned_to: null,
    company: '',
    submittal_number: '',
    external_submittal_number: '',
    status: '',
    workflow: null,
    assignee_user_id: null,
    attachments: [],
    revisions: [],
    files_to_add: [],
    files_to_remove: [],
    step_index: -1,
    job: null,
    id: '',
    created_at: new Date(),
    reporter: null,
    submitted_on: new Date(),
    updated_at: new Date(),
    history: [],
    checklist_item: null,
    checklist_item_id: null,
};

export const SubmitSubmittal: React.FC = () => {
    const { selectedJob } = useJobs();
    const { user } = useAuth();
    const history = useHistory();
    const initialValues: ISubmittal = React.useMemo(() => {
        return {
            ...emptySubmittal,
            job_id: selectedJob?.id || -1,
            workflow_id: selectedJob ? selectedJob.submittal_workflow_id : 0,
            workflow: selectedJob?.submittal_workflow || null,
            reporter_user_id: user?.id || -1,
            from_user_id: user?.id || -1,
            from_company: user?.company_name || '',
        };
    }, [selectedJob?.submittal_workflow, user?.id]);
    if (!selectedJob) return null;
    return (
        <>
            <Header2
                breadcrumbs={[
                    { title: 'Jobs', link: '/jobs' },
                    { title: `${selectedJob?.name} - ${selectedJob?.job_number}`, link: `/jobs/${selectedJob?.id}/summary` },
                    { title: 'Submittals', link: `/jobs/${selectedJob.id}/submittals` },
                    { title: 'Create Submittal', link: '' },
                ]}
                actionComponent={
                    <Button intent="none" onClick={() => history.push(`/jobs/${selectedJob.id}/submittals`)}>
                        Cancel
                    </Button>
                }
            />
            <SubmittalForm
                initialValues={initialValues}
                selectedJob={selectedJob}
                onSaved={console.log}
                onCreated={(submittal) => history.push(`/jobs/${selectedJob.id}/submittals/${submittal.id}`)}
            />
        </>
    );
};
