import React from 'react';
import { Row, Col } from 'react-grid-system';
import { Card, Button, Collapse } from '@blueprintjs/core';
import { IComment, IMeetingTopic, ITopicIssue } from 'src/api/models/Meeting';
import Textfield from '@atlaskit/textfield';
import InlineEdit from '@atlaskit/inline-edit';
import { css } from '@emotion/core';
import { gridSize as getGridSize } from '@atlaskit/theme/constants';
import { TopicIssue } from './components/TopicIssue';
import { CommentDrawer } from './components/CommentDrawer';
import useDebounce from 'src/hooks/useDebounce';
import ReactQuill from 'react-quill';
import { useJobs } from 'src/hooks/useJobs';
import { createIssue, updateIssue as apiUpdateIssue, updateTopic as apiUpdateTopic, deleteTopic as apiDeleteTopic } from 'src/api/meetings';
import { AppToast } from '../../../../../../components/Toasts/AppToast';
import { ConfirmPopover } from '../../../../../../components/ConfirmPopover';
interface ITopicProps {
    topic: IMeetingTopic;
    onTopicDeleted: (topic: IMeetingTopic) => void;
    onTopicChange: (topic: IMeetingTopic) => void;
}

const gridSize = getGridSize();
const minRows = 2;
const textAreaLineHeightFactor = 2.5;

const fs = 24;
const readViewTitleContainerStyles = css({
    minHeight: `${gridSize * textAreaLineHeightFactor * minRows}px`,
    color: '#fff!important',
    fontSize: fs,
    padding: `${gridSize - 2}px ${gridSize - 2}px`,
    lineHeight: `${(gridSize * textAreaLineHeightFactor) / fs}`,
    wordBreak: 'break-word',
});

export const Topic: React.FC<ITopicProps> = ({ topic, onTopicChange, onTopicDeleted }) => {
    const { selectedJob } = useJobs();
    const [deletingTopic, setDeletingTopic] = React.useState(false);
    const [addingIssue, setAddingIssue] = React.useState(false);
    const [selectedIssue, setSelectedIssue] = React.useState<ITopicIssue | null>(null);
    const [notepad, setNotepad] = React.useState(topic.notepad);
    const [notepadCollapsed, setNotepadCollapsed] = React.useState(true);
    const debouncedNotepad = useDebounce(notepad, 500);

    React.useEffect(() => {
        updateTopic(topic);
    }, [topic.title, topic.notepad]);

    React.useEffect(() => {
        const updatedTopic = {
            ...topic,
            notepad: debouncedNotepad,
        };
        onTopicChange(updatedTopic);
    }, [debouncedNotepad]);

    const addIssue = React.useCallback(async () => {
        if (!selectedJob) return;
        setAddingIssue(true);
        try {
            const result = await createIssue(selectedJob.id, topic.meeting_id, topic.id);
            const updatedTopic = {
                ...topic,
                issues: [...topic.issues, result],
            };
            onTopicChange(updatedTopic);
        } catch (err) {
            AppToast.show({ message: `Error: ${err.toLocaleString()}`, intent: 'danger', icon: 'tick' });
        }
        setAddingIssue(false);
    }, [topic]);

    const updateTopic = async (newTopic: IMeetingTopic) => {
        if (!selectedJob) return;
        try {
            await apiUpdateTopic(selectedJob.id, newTopic);
        } catch (err) {
            AppToast.show({ message: `Error: ${err.toLocaleString()}`, intent: 'danger', icon: 'tick' });
        }
    };

    const updateIssue = React.useCallback(
        async (issue: ITopicIssue) => {
            if (!selectedJob) return;
            try {
                const updatedTopic = {
                    ...topic,
                    issues: topic.issues.map((is) => {
                        if (is.id === issue.id)
                            return {
                                ...issue,
                                comments: is.comments,
                            };
                        return is;
                    }),
                };
                onTopicChange(updatedTopic);
                await apiUpdateIssue(selectedJob.id, issue.meeting_id, issue.topic_id, issue.id, issue);
            } catch (err) {
                AppToast.show({ message: `Error: ${err.toLocaleString()}`, intent: 'danger', icon: 'tick' });
            }
        },
        [topic],
    );

    const deleteIssue = (target: ITopicIssue) => {
        setSelectedIssue(null);
        const updatedTopic = {
            ...topic,
            issues: topic.issues.filter((issue) => issue.id !== target.id),
        };
        onTopicChange(updatedTopic);
    };

    const deleteTopic = async () => {
        setDeletingTopic(true);
        try {
            await apiDeleteTopic(topic.job_id, topic.meeting_id, topic.id);
            onTopicDeleted(topic);
        } catch (err) {
            AppToast.show({ message: `Error: ${err.toLocaleString()}`, intent: 'danger', icon: 'tick' });
        }
    };

    const onCommentAdded = (comment: IComment) => {
        if (!selectedIssue) return;
        const updatedTopic = {
            ...topic,
            issues: topic.issues.map((issue) => {
                if (issue.id !== selectedIssue.id) return issue;
                const updatedIssue = {
                    ...issue,
                    comments: [comment, ...issue.comments],
                };
                setSelectedIssue(updatedIssue);
                return updatedIssue;
            }),
        };
        onTopicChange(updatedTopic);
    };

    const onCommentUpdated = (comment: IComment) => {
        const updatedTopic = {
            ...topic,
            issues: topic.issues.map((issue) => {
                if (issue.id !== comment.issue_id) return issue;
                const updatedIssue = {
                    ...issue,
                    comments: issue.comments.map((c) => {
                        if (c.id === comment.id) return comment;
                        return c;
                    }),
                };
                setSelectedIssue(updatedIssue);
                return updatedIssue;
            }),
        };
        onTopicChange(updatedTopic);
    };

    const onCommentDeleted = (comment: IComment) => {
        const updatedTopic = {
            ...topic,
            issues: topic.issues.map((issue) => {
                if (issue.id !== comment.issue_id) return issue;
                const updatedIssue = {
                    ...issue,
                    comments: issue.comments.filter((c) => c.id !== comment.id),
                };
                setSelectedIssue(updatedIssue);
                return updatedIssue;
            }),
        };
        onTopicChange(updatedTopic);
    };

    const onIssueChange = (issue: ITopicIssue) => {
        updateIssue(issue);
    };

    return (
        <Card className="m-b-15 p-t-10 p-relative">
            <div className="m-l--10 m-r-30">
                <InlineEdit
                    defaultValue={topic.title}
                    editView={({ errorMessage, ...fieldProps }, ref) => (
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore - textarea does not pass through ref as a prop
                        <Textfield {...fieldProps} ref={ref} autoComplete="off" />
                    )}
                    readView={() => (
                        <div css={readViewTitleContainerStyles} style={{ padding: 5, fontSize: 24, color: '#000' }}>
                            {topic.title || 'Click to add topic title'}
                        </div>
                    )}
                    onConfirm={(val) => {
                        onTopicChange({
                            ...topic,
                            title: val,
                        });
                    }}
                    readViewFitContainerWidth
                />
                <div style={{ position: 'absolute', right: 10, top: 20 }}>
                    <ConfirmPopover
                        contentTitle="Are you sure you want to remove this topic?"
                        contentBody="This action cannot be reversed."
                        confirmButtonText="Delete Topic"
                        confirmButtonIntent="danger"
                        onConfirmClick={() => deleteTopic()}
                        interactionKind="click"
                        loading={deletingTopic}
                    >
                        <Button icon="trash" intent="danger" minimal />
                    </ConfirmPopover>
                </div>
            </div>
            <Row>
                <Col>
                    <h3>Issues</h3>
                </Col>
                {/*<Col className="text-right">*/}
                {/*    <Button onClick={addIssue} small className="m-t-15" minimal icon="add" intent="primary">*/}
                {/*        Add Issue*/}
                {/*    </Button>*/}
                {/*</Col>*/}
            </Row>
            {/*{topic.issues.length === 0 && <p className="text-center m-t-20 m-b-20">No issues</p>}*/}
            {topic.issues.map((issue) => {
                const isSelected = selectedIssue !== null && selectedIssue.id === issue.id;
                return (
                    <TopicIssue
                        key={issue.id}
                        issue={issue}
                        onAddIssue={addIssue}
                        onIssueChange={onIssueChange}
                        onAddCommentClick={() => setSelectedIssue(issue)}
                        onDeleteIssue={() => deleteIssue(issue)}
                        selected={isSelected}
                    />
                );
            })}
            <Button loading={addingIssue} onClick={addIssue} small fill className="m-t-15" minimal icon="add" intent="primary">
                Add Issue
            </Button>
            <CommentDrawer
                issue={selectedIssue}
                onCommentDeleted={onCommentDeleted}
                onCommentUpdated={onCommentUpdated}
                onCommentAdded={onCommentAdded}
                isOpen={selectedIssue !== null}
                onClose={() => setSelectedIssue(null)}
            />
            <Row>
                <Col>
                    <h3 style={{ display: 'inline-block' }}>Notepad</h3>
                    <Button minimal className="m-l-5 m-t--3" icon={notepadCollapsed ? 'chevron-up' : 'chevron-down'} onClick={() => setNotepadCollapsed((prevState) => !prevState)} />
                </Col>
            </Row>
            <Collapse isOpen={!notepadCollapsed}>
                <ReactQuill
                    // className={isDisabled ? 'quill-disabled' : ''}
                    theme="snow"
                    value={notepad}
                    //readOnly={isDisabled}
                    onChange={(value) => {
                        setNotepad(value);
                    }}
                />
            </Collapse>
        </Card>
    );
};
