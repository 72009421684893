import React from 'react';
import Select from 'react-select';
import { Label } from '@blueprintjs/core';
import { CTCJob } from '../../../../api/models/CTC';
import { loadCTCJobsWithFilters, loadLinkedJobs } from '../../../../api/ctc';

interface ICTCJobSelectProps {
    onChange: (value: CTCJob | null) => void;
    hideLabel?: boolean;
    small?: boolean;
    ctcJobId: string;
    disabled?: boolean;
    placeholder?: string;
}

export const CTCJobSelector: React.FC<ICTCJobSelectProps> = ({ onChange, placeholder = 'Select a CTC Job...', disabled = false, ctcJobId, small = false, hideLabel = false }) => {
    const [value, setValue] = React.useState<CTCJob | null>(null);
    const [jobs, setJobs] = React.useState<CTCJob[]>([]);
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {
        loadJobs();
    }, []);

    const loadJobs = async () => {
        setLoading(true);
        try {
            const codes = await loadCTCJobsWithFilters([]);
            const linkedJobs = await loadLinkedJobs();
            const filteredJobs = codes.jobs.filter((j) => linkedJobs.indexOf(j.ws_job_number) === -1);
            setJobs(filteredJobs);
        } catch (err) {
            console.log('error loadings codes: ', err.toLocaleString());
        }
        setLoading(false);
    };

    React.useEffect(() => {
        if (ctcJobId) {
            const selected = jobs.find((job) => job.ws_job_number === ctcJobId) || null;
            setValue(selected);
        } else {
            setValue(null);
        }
    }, [jobs]);

    const onSelect = (job: CTCJob | null) => {
        setValue(job);
        onChange(job);
    };
    return (
        <div>
            {!hideLabel && <Label className="text-bold">CTC Job</Label>}
            <Select
                isClearable
                isLoading={loading}
                isDisabled={disabled}
                className={`basic-single ${small ? 'single-small' : ''}`}
                placeholder={placeholder}
                classNamePrefix="react-select"
                formatOptionLabel={(o) => {
                    return (
                        <div>
                            {o.ws_job_number} - {o.ws_job_name}
                        </div>
                    );
                }}
                getOptionLabel={(o) => `${o.ws_job_number} - ${o.ws_job_name}`}
                getOptionValue={(o) => o.ws_job_number}
                value={value}
                isSearchable
                options={jobs}
                onChange={(value: any) => {
                    onSelect(value ? value : null);
                }}
            />
        </div>
    );
};
