import React from 'react';
import { Dialog, Classes, Button, FormGroup, InputGroup, TextArea, Intent, Colors } from '@blueprintjs/core';
import { createJob } from 'src/api/jobs';
import { ILocation } from 'src/api/models/Job';
import { useDialogs } from 'src/hooks/useDialogs';
import { useProjects } from 'src/hooks/useProjects';
import { ClientSelector } from 'src/components/Selectors/ClientSelector';
import { CTCJobSelector } from 'src/components/Selectors/CTCJobSelector/single';
import { PlaceSelector } from 'src/components/Selectors/PlaceSelector';
import { IOrganizationClient } from 'src/api/models/Organization';
import { CTCJob } from '../../../api/models/CTC';

interface ICreateJobDialogProps {
    onCreateComplete: () => void;
}

const defaultLocation = {
    address: '',
    geo: null,
};

export const CreateJobDialog: React.FC<ICreateJobDialogProps> = ({ onCreateComplete }) => {
    const { createProjectOpen, setCreateProjectOpen } = useDialogs();
    const { onNewProjectCreated } = useProjects();
    const [isSaving, setIsSaving] = React.useState(false);
    const [name, setName] = React.useState('');
    const [jobNumber, setJobNumber] = React.useState('');
    const [description, setDescription] = React.useState('');
    const [division, setDivision] = React.useState('');
    const [client, setClient] = React.useState<IOrganizationClient | undefined>(undefined);
    const [location, setLocation] = React.useState<ILocation>(defaultLocation);
    const [error, setError] = React.useState<string | null>(null);

    const onLocationChange = (location: ILocation) => {
        setLocation(location);
    };
    const saveProject = async () => {
        if (!client) return setError('Client Required...');
        try {
            setError(null);
            setIsSaving(true);
            const result = await createJob({ name, division, description, job_number: jobNumber, id: -1, client_id: client.id, organization_id: client.organization_id, location });
            setIsSaving(false);
            closeDialog();
            onNewProjectCreated(result);
        } catch (err) {
            console.log('error: ', err);
            setError(err.message);
            setIsSaving(false);
        }
    };
    const closeDialog = () => {
        setCreateProjectOpen(false);
        setName('');
        setDescription('');
        setDivision('');
        setJobNumber('');
        setClient(undefined);
        setError(null);
        setLocation(defaultLocation);
        onCreateComplete();
    };

    const onCTCJobChange = (job: CTCJob | null) => {
        if (job) {
            setName(job.ws_job_name);
            setJobNumber(job.ws_job_number);
            setDivision(job.division);
            setDescription(job.ws_job_name);
        } else {
            setName('');
            setJobNumber('');
            setDivision('');
        }
    };

    const getType = (v: string) => {
        const items = v.split('-');
        return items[1] ? items[1] : 'N/A';
    };
    return (
        <Dialog usePortal={false} isOpen={createProjectOpen} canOutsideClickClose={false} onClose={closeDialog} title="Create Job" icon="folder-new">
            <div className={Classes.DIALOG_BODY}>
                <FormGroup label="CTC Job" labelInfo="*">
                    <CTCJobSelector ctcJobId={jobNumber} onChange={onCTCJobChange} hideLabel small />
                </FormGroup>
                <FormGroup label="Customer" labelInfo="*">
                    <ClientSelector onChange={(client) => setClient(client)} />
                </FormGroup>
                <FormGroup label="Division">
                    <InputGroup placeholder="Derived from CTC Job" fill value={division} disabled />
                </FormGroup>
                <FormGroup label="Job Type">
                    <InputGroup placeholder="Derived from CTC Job" fill value={getType(division)} disabled />
                </FormGroup>
                <FormGroup label="Scope of Work">
                    <TextArea placeholder="Enter a scope of work for your new job..." fill growVertically={true} value={description} onChange={(e) => setDescription(e.target.value)} />
                </FormGroup>
                <FormGroup label="Jobsite Location">
                    <PlaceSelector initialValue={location} onChange={onLocationChange} />
                </FormGroup>
                {error && (
                    <p className="m-b-0" style={{ color: Colors.RED5 }}>
                        Job with job number {jobNumber} already exists
                    </p>
                )}
            </div>
            <div className={Classes.DIALOG_FOOTER}>
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                    <Button onClick={closeDialog}>Close</Button>
                    <Button
                        onClick={saveProject}
                        intent={Intent.PRIMARY}
                        loading={isSaving}
                        disabled={name.length < 3 || !client || jobNumber.length === 0 || division.length === 0 || description.length === 0 || location.address.length === 0}
                    >
                        Save
                    </Button>
                </div>
            </div>
        </Dialog>
    );
};
