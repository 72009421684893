import React from 'react';
import { Row, Col } from 'react-grid-system';
import { Button, Classes, Dialog, DialogProps, FormGroup, Label, TextArea, RadioGroup, Radio, InputGroup } from '@blueprintjs/core';
import { PhaseSelector } from './components/PhaseSelector';
import { RoleSelector2 } from 'src/components/Selectors/RoleSelector2';
import { Attachments } from './components/Attachments';
// import { Activity } from './components/Activity';
import { Links } from './components/Links';
import './index.scss';
import { useFormik } from 'formik';
import { IChecklistItem, IChecklistItemFormValues } from 'src/api/models/Checklist';
import { createItem, updateItem, removeItem, createTemplateItem, updateTemplateItem } from '../../../api/checklists';
import { BottomToast } from '../../Toasts';
import * as Yup from 'yup';
import { ConfirmPopover } from '../../ConfirmPopover';

const validationSchema = Yup.object().shape({
    title: Yup.string().required().min(1, 'Title Required').label('Title'),
});

const initialValues: IChecklistItemFormValues = {
    phase: null,
    title: '',
    description: '',
    complete: false,
    assigned_to: [],
    require_association: 'true',
    critical: 'true',
    files_to_add: [],
    files_to_remove: [],
    attachments: [],
};

interface IChecklistItemDialogProps extends DialogProps {
    onClose: () => void;
    onCreateComplete: () => void;
    onUpdateComplete: () => void;
    data?: IChecklistItem | null;
    selectedJobId?: number;
}

export const ChecklistItemDialog: React.FC<IChecklistItemDialogProps> = ({ isOpen, selectedJobId, onUpdateComplete, data, onClose, onCreateComplete }) => {
    const [error, setError] = React.useState<string | null>(null);

    const getRidOfItem = async () => {
        if (!data) return;
        await removeItem(data.id);
        BottomToast.show({ message: 'Checklist item removed!', intent: 'success', icon: 'tick-circle' });
        onClose();
        formik.resetForm();
        onUpdateComplete();
    };
    const formik = useFormik({
        initialValues: data
            ? {
                  phase: data.phase,
                  title: data.title,
                  description: data.description,
                  assigned_to: data.assigned_to,
                  require_association: data.require_association,
                  critical: data.critical,
                  attachments: data.attachments,
                  complete: data.complete,
                  files_to_add: [],
                  files_to_remove: [],
              }
            : initialValues,
        validationSchema,
        validateOnMount: false,
        onSubmit: async (values) => {
            setError(null);
            try {
                if (!selectedJobId) {
                    if (data?.id) {
                        await updateTemplateItem(values, data.id);
                        BottomToast.show({ message: 'Checklist item updated!', intent: 'success', icon: 'tick-circle' });
                        onUpdateComplete();
                    } else {
                        await createTemplateItem(values);
                        BottomToast.show({ message: 'Checklist item successfully created!', intent: 'success', icon: 'tick-circle' });
                        onCreateComplete();
                    }
                } else {
                    if (data?.id) {
                        await updateItem(values, selectedJobId, data.id);
                        BottomToast.show({ message: 'Checklist item updated!', intent: 'success', icon: 'tick-circle' });
                        onUpdateComplete();
                    } else {
                        await createItem(values, selectedJobId);
                        BottomToast.show({ message: 'Checklist item successfully created!', intent: 'success', icon: 'tick-circle' });
                        onCreateComplete();
                    }
                }

                onClose();
                formik.resetForm();
            } catch (err) {
                setError(err.message);
                BottomToast.show({ message: err.message, intent: 'danger', icon: 'warning-sign' });
            }
        },
    });
    const { values } = formik;
    return (
        <Dialog usePortal={false} isOpen={isOpen} onClose={onClose} title={data ? 'Edit Checklist Item' : 'New Checklist Item'} icon="updated" className="task-dialog">
            <div className={`${Classes.DIALOG_BODY}`}>
                <Row>
                    <Col xs={12}>
                        <Row className="m-b-15">
                            <Col>
                                <Label className="m-b-5 text-bold">Phase</Label>
                                <PhaseSelector value={values.phase} onChange={(value) => formik.setFieldValue('phase', value)} jobId={selectedJobId} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup label="Title" labelFor="title" className="text-bold">
                                    <InputGroup id="title" name="title" value={values.title} onChange={(e) => formik.setFieldValue('title', e.target.value)} fill />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup label="Description" labelFor="description" className="text-bold">
                                    <TextArea
                                        id="description"
                                        name="description"
                                        value={values.description}
                                        onChange={(e) => formik.setFieldValue('description', e.target.value)}
                                        growVertically={true}
                                        fill
                                    />
                                </FormGroup>
                            </Col>
                        </Row>

                        <Row className="m-b-20">
                            <Col>
                                <Label className="m-b-5 text-bold">Assign To</Label>
                                <RoleSelector2 value={values.assigned_to} initialValue={values.assigned_to} onChange={(roles) => formik.setFieldValue('assigned_to', roles)} />
                            </Col>
                        </Row>
                        <Row className="m-b-15">
                            <Col xs={7}>
                                <RadioGroup
                                    className="text-bold"
                                    inline
                                    label="Require Association to Complete"
                                    onChange={(e: any) => formik.setFieldValue('require_association', e.target.value)}
                                    selectedValue={values.require_association}
                                >
                                    <Radio label="Yes" value="true" />
                                    <Radio label="No" value="false" />
                                </RadioGroup>
                            </Col>
                            <Col>
                                <RadioGroup className="text-bold" inline label="Critical" onChange={(e: any) => formik.setFieldValue('critical', e.target.value)} selectedValue={values.critical}>
                                    <Radio label="Yes" value="true" />
                                    <Radio label="No" value="false" />
                                </RadioGroup>
                            </Col>
                        </Row>
                        {selectedJobId && (
                            <Row className="m-b-15">
                                <Col>
                                    <Attachments
                                        onFilesAdded={(files) => formik.setFieldValue('files_to_add', files)}
                                        onFilesRemoved={(files) => formik.setFieldValue('files_to_remove', files)}
                                        existingFiles={values.attachments}
                                        itemType="checklist_item"
                                    />
                                </Col>
                            </Row>
                        )}
                        {selectedJobId && data && <Links jobId={selectedJobId} itemId={data.id} />}
                    </Col>
                </Row>
            </div>
            <div className={Classes.DIALOG_FOOTER}>
                {error && <p>{error}</p>}
                <Row>
                    {selectedJobId && (
                        <Col xs={8}>
                            <RadioGroup
                                className="text-bold m-t-5"
                                inline
                                onChange={(e: any) => formik.setFieldValue('complete', e.target.value === 'true')}
                                selectedValue={values.complete ? 'true' : 'false'}
                            >
                                <Radio label="Complete" value="true" />
                                <Radio label="Incomplete" value="false" />
                            </RadioGroup>
                        </Col>
                    )}
                    <Col>
                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                            {data && (
                                <ConfirmPopover
                                    contentTitle="Are you sure?"
                                    confirmButtonIntent="danger"
                                    contentBody={<p>Are you sure you want to remove this item?</p>}
                                    confirmButtonText="Delete Item"
                                    onConfirmClick={() => getRidOfItem()}
                                >
                                    <Button icon="trash" intent="danger" />
                                </ConfirmPopover>
                            )}
                            <Button intent="primary" loading={formik.isSubmitting} onClick={() => formik.handleSubmit()} disabled={!formik.isValid}>
                                Save
                            </Button>
                        </div>
                    </Col>
                </Row>
            </div>
        </Dialog>
    );
};
