import React from 'react';
import { IOrganizationRole } from 'src/api/models/Organization';
import { useOrganization } from 'src/hooks/useOrganization';
import Select from 'react-select';

interface IRoleSelector2Props {
    onChange: (role: IOrganizationRole[]) => void;
    initialValue?: IOrganizationRole[];
    placeholder?: string;
    value: IOrganizationRole[];
}

export const RoleSelector2: React.FC<IRoleSelector2Props> = ({ onChange, initialValue = [], placeholder = 'Select user roles...' }) => {
    const { roles } = useOrganization();
    const [selected, setSelected] = React.useState<IOrganizationRole[]>(initialValue);
    React.useEffect(() => {
        onChange(selected);
    }, [selected]);

    return (
        <Select
            isClearable
            className="basic-single"
            placeholder={placeholder}
            classNamePrefix="react-select"
            getOptionLabel={(o) => o.name}
            getOptionValue={(o) => String(o.id)}
            value={selected}
            isMulti
            options={roles}
            onChange={(v: any) => {
                setSelected(v);
            }}
        />
    );
};
