import * as React from 'react';
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import { IWBSResponse } from 'src/api/models/WBS';
import { Level1Item } from './Level1Item';
import { AddLevelButton } from './AddLevelButton';

interface IWBSTreeProps {
    wbs: IWBSResponse[];
}

export const WBSTree: React.FC<IWBSTreeProps> = ({ wbs }) => {
    const [data, setData] = React.useState<IWBSResponse[]>(wbs);

    const onAdd = (data: IWBSResponse) => {
        setData((prevState) => {
            return [...prevState, data];
        });
    };

    const renderWBS = () => {
        return data.map((level1) => {
            return <Level1Item key={level1.id} label={level1.name} subs={level1.subs} id={level1.id} />;
        });
    };
    return (
        <List
            sx={{ width: '100%', bgcolor: 'background.paper' }}
            component="nav"
            aria-labelledby="nested-list-subheader"
            subheader={
                <ListSubheader component="div" id="nested-list-subheader">
                    WBS Structure
                </ListSubheader>
            }
        >
            {renderWBS()}
            <AddLevelButton level="1" onAdd={onAdd} />
        </List>
    );
};
