import React from 'react';
import { Col, Row } from 'react-grid-system';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import AddLinkIcon from '@mui/icons-material/AddLink';
import PostAddIcon from '@mui/icons-material/PostAdd';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton, Collapse, Tooltip } from '@mui/material';
import { PostFileUploader } from './components/PostFileUploader';
import { PostLinkPicker } from './components/PostLinkPicker';
import { PostDocumentPicker } from './components/PostDocumentPicker';
import './index.scss';
import { IAttachment } from 'src/api/models/Attachment';
import { IDocument } from 'src/api/models/Document';
import { PostItemOption } from 'src/api/models/Post';

interface PostActionsProps {
    onFileUploaded: (file: IAttachment) => void;
    onLinkSelected: (option: PostItemOption) => void;
    onDocumentSelected: (doc: IDocument) => void;
    jobId: number;
}

export const PostActions: React.FC<PostActionsProps> = ({ onFileUploaded, onLinkSelected, onDocumentSelected, jobId }) => {
    const [selectedAction, setSelectedAction] = React.useState<string | null>(null);
    const onUpload = (file: IAttachment) => {
        onFileUploaded(file);
        setSelectedAction(null);
    };
    const onLinkAdded = (option: PostItemOption) => {
        onLinkSelected(option);
        setSelectedAction(null);
    };
    const onDocumentAdded = (doc: IDocument) => {
        onDocumentSelected(doc);
        setSelectedAction(null);
    };

    return (
        <div className="post-actions">
            <Row className={selectedAction === null ? 'fade-visible' : 'fade-hidden'}>
                <Col>
                    <h3 className="m-6 p-l-10">Add to your post</h3>
                </Col>
                <Col>
                    <div className="text-right">
                        <Tooltip title="Upload File">
                            <span>
                                <IconButton aria-label="Attach File" size="small" onClick={() => setSelectedAction('file')}>
                                    <AttachFileIcon />
                                </IconButton>
                            </span>
                        </Tooltip>
                        <Tooltip title="Link Product">
                            <span>
                                <IconButton aria-label="Link Product" size="small" onClick={() => setSelectedAction('link')}>
                                    <AddLinkIcon />
                                </IconButton>
                            </span>
                        </Tooltip>

                        <Tooltip title="Link Document">
                            <span>
                                <IconButton aria-label="Link Document" size="small" onClick={() => setSelectedAction('document')}>
                                    <PostAddIcon />
                                </IconButton>
                            </span>
                        </Tooltip>
                    </div>
                </Col>
            </Row>
            <div className={selectedAction !== null ? 'fade-visible close-actions' : 'fade-hidden close-actions'}>
                <IconButton onClick={() => setSelectedAction(null)}>
                    <CloseIcon />
                </IconButton>
            </div>
            <Collapse in={selectedAction === 'file'} timeout="auto" unmountOnExit easing={{ exit: '1s' }}>
                <PostFileUploader onFileUploaded={onUpload} />
            </Collapse>
            <Collapse in={selectedAction === 'link'} timeout="auto" unmountOnExit easing={{ exit: '1s' }}>
                <PostLinkPicker jobId={jobId} onLinkSelected={onLinkAdded} />
            </Collapse>
            <Collapse in={selectedAction === 'document'} timeout="auto" unmountOnExit easing={{ exit: '1s' }}>
                <PostDocumentPicker jobId={jobId} onDocumentSelected={onDocumentAdded} />
            </Collapse>
        </div>
    );
};
