import React from 'react';
import { Classes as TTClasses, Popover2 } from '@blueprintjs/popover2';
import { Button, Intent, H5 } from '@blueprintjs/core';

interface IConfirmPopover {
    contentTitle: string;
    contentBody: any;
    confirmButtonText: string;
    confirmButtonIntent: Intent;
    loading?: boolean;
    onConfirmClick: () => Promise<void>;
    interactionKind?: 'click' | 'hover';
    position?: 'right' | 'left' | 'top' | 'bottom';
}

export const ConfirmPopover: React.FC<IConfirmPopover> = ({
    contentTitle,
    contentBody,
    loading = false,
    confirmButtonIntent,
    confirmButtonText,
    onConfirmClick,
    interactionKind = 'click',
    position = 'right',
    children,
}) => {
    return (
        <Popover2
            minimal
            interactionKind={interactionKind}
            popoverClassName={TTClasses.POPOVER2_CONTENT_SIZING}
            enforceFocus={false}
            position={position}
            content={
                <div>
                    <H5>{contentTitle}</H5>
                    {contentBody}
                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 15 }}>
                        <Button className={TTClasses.POPOVER2_DISMISS} style={{ marginRight: 10 }}>
                            Cancel
                        </Button>
                        <Button intent={confirmButtonIntent} className={TTClasses.POPOVER2_DISMISS} onClick={onConfirmClick} loading={loading}>
                            {confirmButtonText}
                        </Button>
                    </div>
                </div>
            }
        >
            {children}
        </Popover2>
    );
};
