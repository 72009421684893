import React from 'react';
import { getImageUrl } from 'src/api/attachments';
import { IconButton, ImageListItem, ImageListItemBar } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';

interface IImageProps {
    id: string;
    title: string;
    disabled?: boolean;
    onRemoveClick: () => void;
    onImageClick: () => void;
}

export const Image: React.FC<IImageProps> = ({ id, onRemoveClick, onImageClick, title, disabled }) => {
    const [src, setSrc] = React.useState<string | null>(null);

    React.useEffect(() => {
        const load = async () => {
            const url = await getImageUrl(id);
            setSrc(url);
        };
        load();
    }, []);
    if (!src) return null;
    return (
        <>
            <ImageListItem>
                <img src={`${src}`} srcSet={`${src}`} alt={title} loading="lazy" />
                <ImageListItemBar
                    title={
                        <a style={{ color: '#fff' }} onClick={onImageClick}>
                            {title}
                        </a>
                    }
                    // subtitle={item.author}
                    actionIcon={
                        !disabled ? (
                            <>
                                <IconButton sx={{ color: 'rgba(255, 255, 255, 0.54)' }} aria-label={`info about ${title}`} onClick={onRemoveClick}>
                                    <ClearIcon />
                                </IconButton>
                            </>
                        ) : undefined
                    }
                />
            </ImageListItem>
        </>
        // <div className="image-preview-wrapper">
        //     <img src={src} width={96} height={96} alt="" style={{ marginRight: 10, display: 'inline-block', objectFit: 'cover' }} onClick={onImageClick} />
        //     <div className="remove-image-button" onClick={onRemoveClick}>
        //         <Icon icon="cross" size={10} className="m-b-4" />
        //     </div>
        // </div>
    );
};
