import React from 'react';
import './index.scss';

interface ILoaderProps {
    loading: boolean;
}

export const Loader: React.FC<ILoaderProps> = ({ loading, children }) => {
    if (loading) return <h5 className="app-loader">Loading...</h5>;
    return <>{children}</>;
};
