import { IAttachment } from './models/Attachment';
import { IWorkTicketResponse, IWorkTicketFormValues } from './models/WorkTicket';
import { getAuthOptions } from './helpers';
import AppApi from './index';
const api = AppApi.getApi();

export const listWorkTickets = async (jobId: number): Promise<IWorkTicketResponse[]> => {
    const url = `/jobs/${jobId}/tickets`;
    const { data } = await api.get(url, await getAuthOptions());
    return data;
};

export const getTicketById = async (jobId: number, ticketId: string): Promise<IWorkTicketResponse> => {
    const url = `/jobs/${jobId}/tickets/${ticketId}`;
    const { data } = await api.get(url, await getAuthOptions());
    return data;
};

export const createWorkTicket = async (jobId: number, ticket: IWorkTicketFormValues): Promise<IWorkTicketResponse> => {
    const url = `/jobs/${jobId}/tickets`;
    const { data } = await api.post(url, ticket, await getAuthOptions());
    return data;
};

export const updateWorkTicket = async (jobId: number, ticketId: string, ticket: IWorkTicketFormValues): Promise<IWorkTicketResponse> => {
    const url = `/jobs/${jobId}/tickets/${ticketId}`;
    const { data } = await api.put(url, ticket, await getAuthOptions());
    return data;
};

export const removeWorkTicket = async (jobId: number, ticketId: string): Promise<IWorkTicketResponse> => {
    const url = `/jobs/${jobId}/tickets/${ticketId}`;
    const { data } = await api.delete(url, await getAuthOptions());
    return data;
};
export const uploadTmpImage = async (file: File): Promise<IAttachment> => {
    const formData = new FormData();
    formData.append('file', file);
    const url = `/attachments/upload/tmp/ticket`;
    const authOptions = await getAuthOptions();
    const options = {
        ...authOptions,
        headers: {
            ...authOptions.headers,
            'Content-Type': 'multipart/form-data',
        },
    };
    const result = await api.post(url, formData, options);
    return result.data;
};
