import React from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import Filter2Icon from '@mui/icons-material/Filter2';
import Divider from '@mui/material/Divider';
import { Level3Item } from './Level3Item';
import { AddCodeButton } from './AddCodeButton';
import { CostCodeResponse } from 'src/api/models/WBS';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

interface Level2Props {
    label: string;
    id: string;
    subs: CostCodeResponse[];
}

export const Level2Item: React.FC<Level2Props> = ({ label, subs, id }) => {
    const [open, setOpen] = React.useState(false);
    const [data, setData] = React.useState<CostCodeResponse[]>(subs);
    const onAdd = (data: CostCodeResponse) => {
        setData((prevState) => {
            return [...prevState, data];
        });
    };
    const handleClick = () => {
        setOpen(!open);
    };

    const renderLevel3 = () => {
        return data.map((level3) => {
            return <Level3Item key={level3.id} label={`${level3.code} - ${level3.description}`} />;
        });
    };

    return (
        <>
            <ListItem sx={{ ml: 10 }} onClick={handleClick}>
                <ListItemIcon>
                    <Filter2Icon />
                </ListItemIcon>
                <ListItemText primary={label} />
                <IconButton onClick={handleClick} edge="start" aria-label="delete" style={{ marginRight: 20 }}>
                    <DeleteIcon />
                </IconButton>
                <IconButton onClick={handleClick} edge="end" aria-label="delete" style={{ marginRight: 68 }}>
                    {open ? <ExpandLess /> : <ExpandMore />}
                </IconButton>
            </ListItem>
            <Divider sx={{ ml: 10 }} />
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {renderLevel3()}
                    <AddCodeButton parentId={id} onAdd={onAdd} />
                </List>
            </Collapse>
        </>
    );
};
