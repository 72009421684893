import React from 'react';
import { Label, Tag } from '@blueprintjs/core';
import Select, { components } from 'react-select';
import { ITeamUser } from 'src/api/models/Job';
import { useJobs } from '../../../hooks/useJobs';

interface IJobUserSelectProps {
    onChange: (value: ITeamUser[] | null) => void;
    value: ITeamUser[] | null;
    hideLabel?: boolean;
    placeholder?: string;
    hidden?: boolean;
    disabled?: boolean;
}

const MultiValueLabel = ({ data, ...props }: any) => {
    return (
        <components.MultiValueLabel {...props}>
            {data.user.first_name} {data.user.last_name}
        </components.MultiValueLabel>
    );
};

export const JobUserSelect: React.FC<IJobUserSelectProps> = ({ onChange, disabled = false, hidden = false, value, hideLabel = false, placeholder = 'Select users...' }) => {
    const { team } = useJobs();
    if (hidden) return <div className="m-b-30"></div>;
    return (
        <div className="m-b-30">
            {!hideLabel && <Label className="text-bold">User</Label>}
            <Select
                isClearable
                isDisabled={disabled}
                className="basic-single"
                placeholder={placeholder}
                classNamePrefix="react-select"
                components={{ MultiValueLabel }}
                formatOptionLabel={(o) => {
                    return (
                        <div>
                            {o.user.first_name} {o.user.last_name}
                            <span className="pull-right">
                                {o.user.roles.map((r) => {
                                    return (
                                        <Tag intent="primary" key={r.id}>
                                            {r.name}
                                        </Tag>
                                    );
                                })}
                            </span>
                        </div>
                    );
                }}
                getOptionLabel={(o) => `${o.user.first_name} ${o.user.last_name}`}
                getOptionValue={(o) => String(o.user.id)}
                value={value}
                isSearchable
                isMulti
                options={team}
                onChange={(value: any) => {
                    onChange(value.length > 0 ? value : []);
                }}
            />
        </div>
    );
};
