import React from 'react';
import { Classes, Button, FormGroup, InputGroup, Intent, Callout, Tag, Colors, H4 } from '@blueprintjs/core';
import { Popover2, Classes as TTClasses } from '@blueprintjs/popover2';
import { Row, Col } from 'react-grid-system';
import { uniq } from 'lodash';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { RoleSelector } from 'src/components/Selectors/RoleSelector';
import { OfficeSelector } from 'src/components/Selectors/OfficeSelector';
import { IUser } from 'src/api/models/User';
import { inviteUser, updateUser, updateUserStatus } from 'src/api/admin';
import { useOrganization } from 'src/hooks/useOrganization';
import { TopToast } from 'src/components/Toasts';
import { ConfirmPopover } from 'src/components/ConfirmPopover';

const validationSchema = Yup.object().shape({
    roles: Yup.array().min(1).required('Please enter at least one role').label('Email'),
    first_name: Yup.string().required().min(1, 'First Name Required').label('First Name'),
    last_name: Yup.string().required().min(1, 'Last Name Required').label('Last Name'),
    email: Yup.string().required('Please enter a registered email').email().label('Email'),
});

const initialValues: Pick<IUser, 'roles' | 'first_name' | 'last_name' | 'email' | 'offices'> = {
    roles: [],
    offices: [],
    first_name: '',
    last_name: '',
    email: '',
};

interface IAddUserFormProps {
    selectedUser?: IUser;
    setAddUserOpen: (isOpen: boolean, user?: IUser) => void;
}

export const AddUserForm: React.FC<IAddUserFormProps> = ({ selectedUser, setAddUserOpen }) => {
    const { onUserCreated, onUserUpdated } = useOrganization();
    const [error, setError] = React.useState<string | null>(null);
    const [scopes, setScopes] = React.useState<string[]>([]);
    const [isDisabling, setIsDisabling] = React.useState(false);
    const closeDialog = () => {
        setAddUserOpen(false);
        formik.resetForm();
    };

    const updateStatus = async (status: string) => {
        if (!selectedUser) return;
        setIsDisabling(true);
        setError(null);
        try {
            const updatedUser = await updateUserStatus(selectedUser.id, { status });
            onUserUpdated(updatedUser);
            setAddUserOpen(true, updatedUser);
            TopToast.show({ message: `${selectedUser.first_name} ${selectedUser.last_name}'s account status updated!`, intent: 'success', icon: 'tick-circle' });
        } catch (err) {
            setError(err.message);
        }
        setIsDisabling(false);
    };

    const formik = useFormik({
        initialValues: selectedUser
            ? {
                  roles: selectedUser.roles,
                  offices: selectedUser.offices,
                  first_name: selectedUser.first_name,
                  last_name: selectedUser.last_name,
                  email: selectedUser.email,
              }
            : initialValues,
        validationSchema,
        validateOnMount: true,
        onSubmit: async (values) => {
            setError(null);
            try {
                if (!selectedUser) {
                    const result = await inviteUser(values);
                    onUserCreated(result);
                    closeDialog();
                    formik.resetForm();
                    TopToast.show({ message: `Invite Sent to ${values.first_name} ${values.last_name}!`, intent: 'success', icon: 'tick-circle' });
                } else {
                    const result = await updateUser(selectedUser.id, values);
                    onUserUpdated(result);
                    closeDialog();
                    formik.resetForm();
                    TopToast.show({ message: `${values.first_name} ${values.last_name} Updated!`, intent: 'success', icon: 'tick-circle' });
                }
            } catch (err) {
                setError(err.message);
            }
        },
    });
    React.useEffect(() => {
        const items = formik.values.roles.map((r) => r.scopes).flat();
        setScopes(uniq(items));
    }, [formik.values.roles]);

    const showScopes = scopes.slice(0, 3);
    const hiddenScopes = scopes.slice(3);
    const scopeDiff = scopes.length - 4;

    const renderTags = (tags: string[]) => {
        return tags.map((scope) => {
            return (
                <Tag key={scope} minimal className="m-r-5 m-t-5">
                    {' '}
                    {scope}
                </Tag>
            );
        });
    };
    return (
        <>
            <div className={Classes.DIALOG_BODY}>
                {selectedUser && selectedUser.status === 'disabled' && <H4 className="text-danger m-b-15">This account is disabled</H4>}
                <Row>
                    <Col>
                        <FormGroup label="First Name" labelFor="first-name-input" labelInfo="*">
                            <InputGroup
                                autoFocus
                                autoComplete="off"
                                id="first-name-input"
                                placeholder="Enter first name..."
                                value={formik.values.first_name}
                                onChange={(e) => formik.setFieldValue('first_name', e.target.value)}
                            />
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup label="Last Name" labelFor="last-name-input" labelInfo="*">
                            <InputGroup
                                autoComplete="off"
                                id="last-name-input"
                                placeholder="Enter last name..."
                                value={formik.values.last_name}
                                onChange={(e) => formik.setFieldValue('last_name', e.target.value)}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup label="Email Address" labelFor="email-input" labelInfo="*">
                            <InputGroup autoComplete="off" id="email-input" placeholder="Enter email..." value={formik.values.email} onChange={(e) => formik.setFieldValue('email', e.target.value)} />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup label="Offices" labelFor="role-input">
                            <OfficeSelector onChange={(value) => formik.setFieldValue('offices', value)} initialValue={formik.values.offices} />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup label="Roles" labelFor="role-input">
                            <RoleSelector onChange={(value) => formik.setFieldValue('roles', value)} initialValue={formik.values.roles} />
                        </FormGroup>
                    </Col>
                </Row>

                {scopes.length > 0 && (
                    <Row>
                        <Col>
                            <FormGroup label="Scopes" labelFor="role-input" labelInfo="(Derived from roles)">
                                {renderTags(showScopes)}
                                {scopeDiff > 0 && (
                                    <>
                                        <Popover2
                                            minimal
                                            position="right"
                                            interactionKind="hover"
                                            popoverClassName={TTClasses.POPOVER2_CONTENT_SIZING}
                                            enforceFocus={false}
                                            content={
                                                <>
                                                    <h4 className="m-0 m-b-5">Scopes</h4>
                                                    <p>
                                                        Roles define what scopes are assigned to a user. Scopes determine what the user is able view, read, write, and manage and can only be derived
                                                        from roles.
                                                    </p>
                                                    {renderTags(hiddenScopes)}
                                                </>
                                            }
                                        >
                                            <span>+{scopeDiff} more</span>
                                        </Popover2>
                                    </>
                                )}
                            </FormGroup>
                        </Col>
                    </Row>
                )}

                {formik.isValid && !selectedUser && (
                    <Callout intent="primary" icon={null} className="m-t-0">
                        All set! Once created,{' '}
                        <b>
                            {formik.values.first_name} {formik.values.last_name}
                        </b>{' '}
                        will receive an email invite at <b>{formik.values.email}</b> with their login credentials.
                    </Callout>
                )}
                {error && (
                    <p className="m-b-0" style={{ color: Colors.RED5 }}>
                        {error}
                    </p>
                )}
            </div>
            <div className={Classes.DIALOG_FOOTER}>
                <div>
                    <Row>
                        <Col>
                            {selectedUser && selectedUser.status !== 'disabled' && (
                                <>
                                    <ConfirmPopover
                                        contentTitle="Are you sure?"
                                        confirmButtonIntent="danger"
                                        contentBody={
                                            <p>
                                                Are you sure you want to disable this account?{' '}
                                                <b>
                                                    {selectedUser.first_name} {selectedUser.last_name}
                                                </b>{' '}
                                                will no longer be able to login.
                                            </p>
                                        }
                                        confirmButtonText="Disable Account"
                                        onConfirmClick={() => updateStatus('disabled')}
                                    >
                                        <Button intent="danger">Disable Account</Button>
                                    </ConfirmPopover>
                                </>
                            )}
                            {selectedUser && selectedUser.status === 'disabled' && (
                                <Button intent={Intent.SUCCESS} className={TTClasses.POPOVER2_DISMISS} onClick={() => updateStatus('confirmed')} loading={isDisabling}>
                                    Enable Account
                                </Button>
                            )}
                        </Col>
                        <Col className="text-right">
                            <Button onClick={closeDialog} minimal className="m-r-15">
                                Cancel
                            </Button>{' '}
                            <Button onClick={() => formik.handleSubmit()} intent={Intent.PRIMARY} loading={formik.isSubmitting} disabled={!formik.isValid || !formik.dirty}>
                                {selectedUser ? 'Save' : 'Send Invite'}
                            </Button>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    );
};
