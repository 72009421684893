import React from 'react';
import { Row, Col } from 'react-grid-system';
import { useHistory } from 'react-router-dom';
import { useJobs } from 'src/hooks/useJobs';
import { Card, TextArea, FormGroup, InputGroup, Button } from '@blueprintjs/core';
import { Tab as MTab, Tabs as MTabs } from '@mui/material';
import { getDailySettings } from 'src/api/dailys';
import { IDailyFormValues, IDailySettings } from 'src/api/models/Daily';
import { TabPanel } from 'src/components/TabPanel';
import { DatePicker } from 'src/components/DatePicker';
import { WeatherCard } from 'src/components/WeatherCard';
import { Summary } from './Summary';
import { useAuth } from '../../../../hooks/useAuth';
import { createDaily, updateDaily } from 'src/api/dailys';
import { TicketsTab } from './TicketsTab';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { AppToast } from '../../../../components/Toasts/AppToast';

const validationSchema = Yup.object().shape({
    date: Yup.date().required().min(1, 'Date Required').label('Date'),
});

interface IDailyFormProps {
    initialValues: IDailyFormValues;
    dailyId?: string;
}

export const DailyForm: React.FC<IDailyFormProps> = ({ initialValues, dailyId }) => {
    const { selectedJob } = useJobs();
    const history = useHistory();
    const { user } = useAuth();
    const [loading, setLoading] = React.useState(true);
    const [saving, setSaving] = React.useState(false);
    const [error, setError] = React.useState<string | null>(null);
    const [settings, setSettings] = React.useState<IDailySettings | null>(null);
    React.useEffect(() => {
        loadSettings();
    }, []);
    const loadSettings = async () => {
        setLoading(true);
        const data = await getDailySettings();
        setSettings(data);
        setLoading(false);
    };
    const [tab, setTab] = React.useState<string>('summary');

    const formik = useFormik({
        initialValues,
        validationSchema,
        validateOnMount: true,
        onSubmit: async () => {
            console.log('submit');
        },
    });

    if (!selectedJob || !user) return null;
    if (loading || !settings) return null;

    const submitDaily = async () => {
        setSaving(true);
        setError(null);
        try {
            console.log('submitting: ', values);
            const result = await createDaily(values.job_id, values);
            AppToast.show({ message: `Daily created!`, intent: 'success', icon: 'tick' });
            history.push(`/jobs/${values.job_id}/dailys/view/${result.id}`);
        } catch (err) {
            setError(err.toLocaleString());
        }
        setSaving(false);
    };

    const submitUpdate = async () => {
        if (!dailyId) return;
        setSaving(true);
        setError(null);
        try {
            console.log('submitting: ', values);
            await updateDaily(values.job_id, dailyId, values);
            AppToast.show({ message: `Daily updated!`, intent: 'success', icon: 'tick' });
        } catch (err) {
            setError(err.toLocaleString());
        }
        setSaving(false);
    };

    const { values, setFieldValue } = formik;
    return (
        <>
            <Row className="m-b-5">
                <Col>
                    <Card>
                        <Row>
                            <Col>
                                <FormGroup label="Job" labelFor="job">
                                    <InputGroup id="job" name="job" disabled value={selectedJob?.name} />
                                </FormGroup>
                            </Col>
                            <Col>
                                <DatePicker label="Date" onChange={(val) => setFieldValue('date', val)} value={values.date} />
                            </Col>
                            <Col>
                                <FormGroup label="Status" labelFor="status">
                                    <div className="bp3-html-select bp3-fill">
                                        <select value={values.status} onChange={(e) => setFieldValue('status', e.target.value)}>
                                            <option value="Working">Working</option>
                                            <option value="Review">Review</option>
                                            <option value="Complete">Complete</option>
                                            <option value="Draft">Draft</option>
                                        </select>
                                        <span className="bp3-icon bp3-icon-double-caret-vertical" />
                                    </div>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={8}>
                                <FormGroup label="Description" labelFor="desc">
                                    <TextArea
                                        onChange={(e) => setFieldValue('description', e.target.value)}
                                        value={values.description}
                                        id="desc"
                                        style={{ height: '100%', maxWidth: '100%' }}
                                        name="description"
                                        growVertically={true}
                                        fill
                                    />
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup label="Weather" labelFor="status">
                                    <WeatherCard job={selectedJob} onWeatherLoaded={(w) => setFieldValue('weather', w)} initialWeather={values.weather} />
                                </FormGroup>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <MTabs value={tab} onChange={(e, value) => setTab(value)} variant="scrollable">
                                    <MTab value="summary" label="Summary" />
                                    {settings.tickets && <MTab value="tix" label="Work Tickets" />}
                                    {settings.timesheets && <MTab value="timesheets" label="Timesheets" />}
                                    {settings.progress && <MTab value="progress" label="Progress" />}
                                    {settings.equipment && <MTab value="equipment" label="Equipment" />}
                                    {settings.contractors && <MTab value="contractors" label="Contractors" />}
                                    {settings.notes && <MTab value="notes" label="Notes" />}
                                    {settings.concerns && <MTab value="concerns" label="Concerns" />}
                                    {settings.media && <MTab value="media" label="Media" />}
                                </MTabs>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col className="p-l-0 p-r-0">
                    <TabPanel value="summary" tab={tab}>
                        <Summary
                            jobId={selectedJob.id}
                            initialQuestions={values.questions}
                            onChange={(q) => setFieldValue('questions', q)}
                            buttons={
                                <Row>
                                    <Col className="text-right">
                                        <Card>
                                            {error && <p>{error}</p>}
                                            {dailyId ? (
                                                <Button onClick={submitUpdate} loading={saving} intent="primary">
                                                    Save Daily
                                                </Button>
                                            ) : (
                                                <Button onClick={submitDaily} loading={saving} intent="primary">
                                                    Create Daily
                                                </Button>
                                            )}
                                        </Card>
                                    </Col>
                                </Row>
                            }
                        />
                    </TabPanel>
                    <TabPanel value="timesheets" tab={tab}>
                        timesheets
                    </TabPanel>
                    <TabPanel value="progress" tab={tab}>
                        progress
                    </TabPanel>
                    <TabPanel value="tix" tab={tab}>
                        <TicketsTab initialTickets={values.tickets} onChange={(tickets) => setFieldValue('tickets', tickets)} dailyId={dailyId} />
                    </TabPanel>
                    <TabPanel value="equipment" tab={tab}>
                        equipment
                    </TabPanel>
                    <TabPanel value="contractors" tab={tab}>
                        contractors
                    </TabPanel>
                    <TabPanel value="notes" tab={tab}>
                        notes
                    </TabPanel>
                    <TabPanel value="concerns" tab={tab}>
                        concerns
                    </TabPanel>
                    <TabPanel value="media" tab={tab}>
                        media
                    </TabPanel>
                </Col>
            </Row>
        </>
    );
};
