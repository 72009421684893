import React from 'react';
import { useJobs } from '../../../hooks/useJobs';
import { Button } from '@blueprintjs/core';
import { useHistory, useLocation } from 'react-router-dom';
import { IChangeOrderResponse } from '../../../api/models/ChangeOrder';
import { listChanges } from '../../../api/changes';
import { Col, Row } from 'react-grid-system';
import { ChangesTable } from './components/ChangesTable';
import { Header2 } from '../../../components/Header2';

export const Changes: React.FC = () => {
    const { selectedJob } = useJobs();
    const history = useHistory();
    const location = useLocation();
    const [changes, setChanges] = React.useState<IChangeOrderResponse[]>([]);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState<string | null>(null);

    React.useEffect(() => {
        loadChanges();
    }, []);

    const loadChanges = async () => {
        if (!selectedJob) return;
        setLoading(true);
        setError(null);
        try {
            const data = await listChanges(selectedJob.id);
            setChanges(data);
        } catch (err) {
            setError(err.toLocaleString());
        }
        setLoading(false);
    };
    if (!selectedJob) return null;
    return (
        <>
            <Header2
                breadcrumbs={[
                    { title: 'Jobs', link: '/jobs' },
                    { title: `${selectedJob?.name} - ${selectedJob?.job_number}`, link: `/jobs/${selectedJob?.id}/summary` },
                    { title: 'Changes', link: '' },
                ]}
                actionComponent={
                    <Button intent="primary" icon="add" onClick={() => history.push(`${location.pathname}/submit/change`)}>
                        Create Change Request
                    </Button>
                }
            />
            {error && <p className="text-center">{error}</p>}
            <Row>
                <Col>
                    <ChangesTable changes={changes} loading={loading} />
                </Col>
            </Row>
        </>
    );
};
