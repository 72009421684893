import React from 'react';
import moment from 'moment';
import { TimesheetCode } from '../../../../../api/models/timesheet';
import { ITeamUser } from '../../../../../api/models/Job';
import DataGrid, { TextEditor } from 'react-data-grid';
import { Card } from '@blueprintjs/core';
import { IDateOption } from '../../types';
import { orderBy } from 'lodash';

const getRows = (startDate: IDateOption) => {
    const rows: any = {};
    new Array(7).fill({}).forEach((_, idx) => {
        rows[`day${idx}`] = '';
        rows.start = moment(startDate.startDate).day(0).toDate();
        rows.end = moment(startDate.startDate).day(6).toDate();
    });
    return rows;
};

const getColumns = (startDate: IDateOption) => {
    const start = 6;
    const days = new Array(7).fill({}).map((_, idx) => {
        let day = start + idx;
        if (start > 6) {
            day = -1 + idx;
        }
        return {
            key: `day${idx}`,
            name: moment(startDate.startDate).day(0).add(day, 'days').format('ddd (M/D/YY)'),
            editor: TextEditor,
            summaryFormatter({ row }: any) {
                return <strong>{row[`day${idx}`] === 0 ? '' : row[`day${idx}`]}</strong>;
            },
        };
    });
    return [
        {
            key: 'cost_code_label',
            name: 'Cost Code',
            width: 300,
            resizable: true,
            frozen: true,
        },
        {
            key: 'pay_type',
            name: 'Pay Type',
            width: 100,
            frozen: true,
            summaryFormatter({ row }: any) {
                return <strong>{row.label}</strong>;
            },
        },
        ...days,
        {
            key: 'total',
            name: 'Total',
            width: 100,
            summaryFormatter({ row }: any) {
                return <>{row.total}</>;
            },
        },
    ];
};

interface IUserPanelProps {
    costCodes: TimesheetCode[];
    startDate: IDateOption;
    person: ITeamUser;
    onChange: (userId: number, timesheet: any[]) => void;
}

export const UserPanel: React.FC<IUserPanelProps> = ({ costCodes, person, onChange, startDate }) => {
    const [rows, setRows] = React.useState<any[]>([]);
    const [columns, setColumns] = React.useState(getColumns(startDate));
    React.useEffect(() => {
        onChange(person.user_id, rows);
    }, [rows]);

    React.useEffect(() => {
        const options2: any[][] = costCodes.map((code) => {
            const c1 = {
                ...code,
                cost_code_label: code.label,
                pay_type: 'ST',
                total: '0',
                ...getRows(startDate),
            };
            const c2 = {
                ...code,
                cost_code_label: code.label,
                pay_type: 'OT',
                ...getRows(startDate),
                total: '0',
            };
            return [c1, c2];
        });
        setRows(orderBy(options2.flat(), 'pay_type', 'desc'));
    }, []);

    React.useEffect(() => {
        const col = getColumns(startDate);
        setColumns(col);
    }, [startDate]);

    const updateRows = (rows: any[]) => {
        const updatedRows = rows.map((row) => {
            const total = new Array(7).fill('').reduce((acc, _, idx) => {
                return acc + Number(row[`day${idx}`]);
            }, 0);
            return {
                ...row,
                total: String(total),
            };
        });
        setRows(updatedRows);
    };

    const regularItems = rows.filter((row) => {
        return row.pay_type === 'ST';
    });

    const overTimeItems = rows.filter((row) => {
        return row.pay_type === 'OT';
    });

    const getTotalValues = (items: any) => {
        const result: any = {};
        new Array(7).fill('').forEach((_, idx) => {
            result[`day${idx}`] = items.reduce((acc: number, val: any) => {
                return acc + Number(val[`day${idx}`]);
            }, 0);
        });
        return result;
    };

    const summaryRows = [
        {
            label: 'Regular',
            total: regularItems.reduce((acc, val) => {
                return acc + Number(val.total);
            }, 0),
            ...getTotalValues(regularItems),
        },
        {
            label: 'Overtime',
            total: overTimeItems.reduce((acc, val) => {
                return acc + Number(val.total);
            }, 0),
            ...getTotalValues(overTimeItems),
        },
        {
            label: 'Total',
            total: rows.reduce((acc, val) => {
                return acc + Number(val.total);
            }, 0),
            ...getTotalValues(rows),
        },
    ];
    return (
        <>
            <Card className="m-b-15">
                <h3 className="m-t-0">{person.user.full_name}</h3>
                <DataGrid className="rdg-light" style={{ height: 142 + rows.length * 35 }} columns={columns} rows={rows} onRowsChange={updateRows} summaryRows={summaryRows} />
            </Card>
        </>
    );
};
