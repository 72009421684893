import React from 'react';
import { Row, Col } from 'react-grid-system';
import { useJobs } from 'src/hooks/useJobs';
import { FormGroup, InputGroup } from '@blueprintjs/core';
import { WeatherCard } from '../Weather/WeatherCard';
import { EditDetails } from './EditDetails';
import { ActivityFeedCard } from './ActivityFeedCard';
import { ChecklistCard } from './ChecklistCard';
import CardHeader from '@mui/material/CardHeader';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import CardContent from '@mui/material/CardContent';
import { Header2 } from '../../../components/Header2';
export const Summary: React.FC = () => {
    const { selectedJob, rehydrateJob, jobSummary, loadSummary } = useJobs();
    const [editing, setEditing] = React.useState(false);
    const [error, setError] = React.useState<string | null>(null);
    React.useEffect(() => {
        try {
            loadSummary();
        } catch (err) {
            console.log('error: ', err);
            setError(err.toLocaleString());
        }
    }, []);
    const getType = (v: string) => {
        const items = v.split('-');
        return items[1] ? items[1] : 'N/A';
    };
    if (!selectedJob) return null;
    return (
        <div>
            <Header2
                breadcrumbs={[
                    { title: 'Jobs', link: '/jobs' },
                    { title: `${selectedJob?.name} - ${selectedJob?.job_number}`, link: `/jobs/${selectedJob?.id}/summary` },
                    { title: 'Summary', link: '' },
                ]}
            />
            {error && <p>{error}</p>}
            <Row>
                <Col sm={12} md={5}>
                    <Row className="m-b-25">
                        <Col>
                            <Card sx={{ pb: 0 }}>
                                <CardHeader
                                    className="p-b-5"
                                    titleTypographyProps={{ variant: 'h6' }}
                                    title="Job Details"
                                    action={
                                        <>
                                            {!editing && (
                                                <Button size="small" onClick={() => setEditing(true)}>
                                                    Edit
                                                </Button>
                                            )}
                                        </>
                                    }
                                />
                                <CardContent>
                                    {editing ? (
                                        <>
                                            <EditDetails job={selectedJob} onCancel={() => setEditing(false)} onSave={rehydrateJob} />
                                        </>
                                    ) : (
                                        <>
                                            <FormGroup label="Customer">
                                                <InputGroup disabled id="project-name-input" value={selectedJob?.client?.name} />
                                            </FormGroup>
                                            <FormGroup label="Job Number - Job Name" labelFor="project-name-input">
                                                <InputGroup disabled id="project-name-input" value={`${selectedJob?.job_number} - ${selectedJob?.name}`} />
                                            </FormGroup>
                                            <FormGroup label="Division" labelFor="job-division-input">
                                                <InputGroup disabled id="job-division-input" value={selectedJob?.division} />
                                            </FormGroup>
                                            <FormGroup label="Job Type">
                                                <InputGroup placeholder="Derived from CTC Job" fill value={getType(selectedJob?.division)} disabled />
                                            </FormGroup>
                                            <FormGroup label="Scope of Work">
                                                <InputGroup disabled fill value={selectedJob?.description} />
                                            </FormGroup>
                                            <FormGroup label="Jobsite Location">
                                                <InputGroup disabled fill value={selectedJob?.location.address} />
                                            </FormGroup>
                                        </>
                                    )}
                                </CardContent>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <ChecklistCard title="Checklists" items={jobSummary.checklists} jobId={selectedJob.id} />
                        </Col>
                    </Row>
                </Col>
                {selectedJob && (
                    <Col>
                        <Row>
                            <Col>
                                <ActivityFeedCard title="Constraints" items={jobSummary.constraints} />
                            </Col>
                            <Col>
                                <ActivityFeedCard title="Posts By Need Date" items={jobSummary.due} />
                            </Col>
                        </Row>
                        <Row className="m-b-20">
                            <Col>
                                <WeatherCard job={selectedJob} />
                            </Col>
                        </Row>
                    </Col>
                )}
            </Row>
        </div>
    );
};
