import React from 'react';

import { PostItemOption } from 'src/api/models/Post';
import { loadLinkItems } from 'src/api/posts';
import useDebounce from 'src/hooks/useDebounce';
import { Autocomplete, TextField } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

interface PostLinkPickerProps {
    onLinkSelected: (option: PostItemOption) => void;
    jobId: number;
}

export const PostLinkPicker: React.FC<PostLinkPickerProps> = ({ jobId, onLinkSelected }) => {
    const [loading, setLoading] = React.useState(false);
    const [query, setQuery] = React.useState('');
    const debouncedTitle = useDebounce(query, 300);
    const [options, setOptions] = React.useState<PostItemOption[]>([]);
    React.useEffect(() => {
        loadOptions(debouncedTitle);
    }, [debouncedTitle]);

    const loadOptions = async (q: string) => {
        setLoading(true);
        const options = await loadLinkItems(jobId, q);
        setOptions(options);
        setLoading(false);
    };

    return (
        <div style={{ height: 215, padding: 15 }}>
            <div className={`post-link-selector`}>
                <div className="link-selector-container">
                    <Autocomplete
                        size="small"
                        disablePortal={true}
                        //renderGroup={(group) => <div className="text-left">{group.group}</div>}
                        groupBy={(option) => option.resource}
                        // PopperComponent={(props) => <Popper {...props} placement="bottom" />}
                        noOptionsText="Type to find items to link..."
                        options={options}
                        getOptionLabel={(option) => `${option.label.length > 0 ? option.label : 'Untitled'}`}
                        renderOption={(props, option) => {
                            return (
                                <li {...props} key={option.id}>
                                    {option.label.length > 0 ? option.label : 'Untitled'}
                                </li>
                            );
                        }}
                        autoHighlight
                        value={null}
                        defaultValue={null}
                        onChange={(event, newValue: any) => onLinkSelected(newValue)}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                placeholder="Search for items to link..."
                                value={query}
                                onChange={(e) => setQuery(e.target.value)}
                                InputProps={{
                                    ...params.InputProps,
                                    onBlur: () => setQuery(''),
                                    endAdornment: (
                                        <React.Fragment>
                                            {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                            {params.InputProps.endAdornment}
                                        </React.Fragment>
                                    ),
                                }}
                            />
                        )}
                    />
                </div>
            </div>
        </div>
    );
};
