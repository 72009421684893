import React from 'react';
import { Row, Col } from 'react-grid-system';
import { Header } from '../../../components/Header';
import { CostCodes } from 'src/components/WBS/CostCodes';

export const WBS: React.FC = () => {
    return (
        <>
            <Header title="WBS" breadcrumbs={['Settings', 'WBS']} />
            <Row>
                <Col xs={12} md={12} lg={6}>
                    <CostCodes />
                </Col>
            </Row>
        </>
    );
};
