import AppApi from './index';
import { IUser, UserNotification, UserAppOption } from './models/User';
import { getAuthOptions } from './helpers';

const api = AppApi.getApi();

export const loadUsers = async (): Promise<IUser[]> => {
    const { data } = await api.get('/users', await getAuthOptions());
    return data;
};

export const loadUserNotifications = async (unread: boolean): Promise<UserNotification[]> => {
    const { data } = await api.get(`/user/notifications${unread ? '?unread=true' : ''}`, await getAuthOptions());
    return data;
};

export const markNotificationRead = async (notificationId: string): Promise<UserNotification> => {
    const { data } = await api.post(`/user/notifications/${notificationId}`, null, await getAuthOptions());
    return data;
};

export const markPostNotificationRead = async (postId: string): Promise<UserNotification[]> => {
    const { data } = await api.post(`/user/notifications/${postId}`, { postId }, await getAuthOptions());
    return data;
};
export const forceNotificationRead = async (postId: string): Promise<UserNotification> => {
    const { data } = await api.post(`/user/notifications/${postId}/read`, { postId }, await getAuthOptions());
    return data;
};

export const updateMyApps = async (apps: UserAppOption[]): Promise<IUser[]> => {
    const { data } = await api.put(`/user/me/updateApps`, apps, await getAuthOptions());
    return data;
};

export const loadJobUsers = async (jobId: number): Promise<IUser[]> => {
    const { data } = await api.get(`/jobs/${jobId}/users`, await getAuthOptions());
    return data;
};

export const login = async (email: string, password: string, rememberMe: boolean) => {
    const response = await api.post(`/login`, { email, password });
    const result = response.data;
    if (rememberMe) {
        await localStorage.setItem('@Firetrol:remember', String(rememberMe));
    }
    await localStorage.setItem('@Firetrol:jwt', result.token);
    const user = await getMe();
    return user;
};

export const register = async (userDetails: any) => {
    const response = await api.post(`/register`, userDetails);
    const result = response.data;
    if (result.success === false) {
        throw new Error(result.message);
    }
    return await login(userDetails.email, userDetails.password, false);
};

export const requestPasswordReset = async (email: string) => {
    const response = await api.post(`/requestPasswordReset`, { email });
    return response.data;
};

export const resetPassword = async (password: string, token: string) => {
    const response = await api.post(`/resetPassword`, { password, token });
    return response.data;
};

export const setPassword = async (password: string) => {
    const response = await api.post(`/user/me/setPassword`, { password }, await getAuthOptions());
    return response.data;
};

export const updatePassword = async (password: string, token: string) => {
    const response = await api.post(`/resetPassword`, { password, token });
    return response.data;
};

export const logout = async () => {
    const token = await localStorage.getItem('@Firetrol:jwt');

    const response = await api.post(`/logout`, null, {
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
    });
    const result = response.data;
    await localStorage.removeItem('@Firetrol:jwt');
    await localStorage.removeItem('@Firetrol:user');
    return result;
};

export const getMe = async (): Promise<IUser> => {
    const token = await localStorage.getItem('@Firetrol:jwt');
    const response = await api.get(`/user/me`, {
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
    });
    return {
        ...response.data,
        role: '',
    };
};
