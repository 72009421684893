import React from 'react';
import { Button, Spinner, Card } from '@blueprintjs/core';
import { useParams, useHistory } from 'react-router-dom';
import { CreateWorkflow } from 'src/components/Workflow/CreateWorkflow';
import { loadWorkflowTemplateByType, loadWorkflowTemplateById } from 'src/api/admin';
import { deleteWorkflow } from 'src/api/workflows';
import { IWorkflowStep, IWorkflowSettings } from '../../../../api/models/Workflow';
import { Header } from '../../../../components/Header';
import { ConfirmPopover } from 'src/components/ConfirmPopover';
import Select from 'react-select';
import { Container, Row, Col } from 'react-grid-system';

const WORKFLOW_OPTIONS = [
    { id: 'rfi', label: 'RFI Workflow' },
    { id: 'submittal', label: 'Submittal Workflow' },
    { id: 'drawing', label: 'Drawing Workflow' },
];

export const EditWorkflowTemplate: React.FC = () => {
    const params: any = useParams();
    const history = useHistory();
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState<string | null>(null);
    const [workflowSettings, setWorkflowSettings] = React.useState<IWorkflowSettings | null>(null);
    const [workflowSteps, setWorkflowSteps] = React.useState<IWorkflowStep[]>([]);
    const [removing, setRemoving] = React.useState(false);
    const [workflowType, setWorkflowType] = React.useState<any>(null);

    React.useEffect(() => {
        if (params.templateId === 'create') {
            fetchWorkflowByType();
        } else {
            fetchWorkflowById();
        }
    }, [workflowType, params.templateId]);

    const fetchWorkflowById = async (showLoader = true) => {
        if (!params.templateId) return;
        setLoading(showLoader);
        setError(null);
        try {
            const result = await loadWorkflowTemplateById(params.templateId);
            const { steps } = result;
            setWorkflowSteps(steps);
            setWorkflowSettings(result);
        } catch (err) {
            setError(err.toLocaleString());
        }
        setLoading(false);
    };

    const fetchWorkflowByType = async (showLoader = true) => {
        if (!workflowType) return;
        setLoading(showLoader);
        setError(null);
        try {
            const result = await loadWorkflowTemplateByType(workflowType.id);
            const { steps } = result;
            setWorkflowSteps(steps);
            setWorkflowSettings(result);
        } catch (err) {
            setError(err.toLocaleString());
        }
        setLoading(false);
    };

    const removeWorkflow = async () => {
        setRemoving(true);
        await deleteWorkflow(params.templateId);
        history.push('/settings/workflows');
        setRemoving(false);
    };

    if (loading) return <Spinner />;
    if (error) {
        return <p>{error}</p>;
    }
    const actionComponent =
        params.templateId === 'create' ? (
            <Button onClick={() => history.goBack()}>Cancel</Button>
        ) : (
            <ConfirmPopover
                contentTitle="Are you sure you want to remove this workflow template?"
                contentBody="This action cannot be reversed."
                confirmButtonText="Delete Workflow"
                confirmButtonIntent="danger"
                onConfirmClick={() => removeWorkflow()}
                interactionKind="click"
                loading={removing}
            >
                <Button intent="danger" minimal>
                    Delete Workflow Template
                </Button>
            </ConfirmPopover>
        );
    return (
        <>
            <Header
                title="Workflows"
                goBackIndices={[1]}
                goBackPath={'/settings/workflows'}
                breadcrumbs={['Settings', 'Workflows', workflowSettings?.name || 'New']}
                actionComponent={<>{actionComponent}</>}
            />
            {params.templateId === 'create' && (
                <Container fluid={false} className="workflow-container">
                    <Row>
                        <Col>
                            <Card className="m-b-15">
                                <Select
                                    isClearable={false}
                                    className="basic-single"
                                    placeholder="Select workflow type..."
                                    classNamePrefix="react-select"
                                    getOptionLabel={(o) => o.label}
                                    getOptionValue={(o) => o.id}
                                    value={workflowType}
                                    options={WORKFLOW_OPTIONS}
                                    onChange={(v: any) => {
                                        setWorkflowType(v);
                                    }}
                                />
                            </Card>
                        </Col>
                    </Row>
                </Container>
            )}

            {workflowSettings && workflowSteps && <CreateWorkflow initialSettings={workflowSettings} initialSteps={workflowSteps} isTemplateEditor onSaveComplete={() => fetchWorkflowByType(false)} />}
        </>
    );
};
