import React from 'react';
import { Button } from '@blueprintjs/core';
import { useHistory } from 'react-router-dom';
import { useJobs } from 'src/hooks/useJobs';
import { useAuth } from 'src/hooks/useAuth';
import { RFIForm } from '../components/RFIForm';
import { IRFI } from '../../../../api/models/RFI';
import { Header2 } from '../../../../components/Header2';

const emptyRFI: IRFI = {
    id: '',
    need_date: new Date(),
    external_email: '',
    rfi_number: '',
    status: '',
    step_index: -1,
    external_rfi_number: '',
    workflow_id: 0,
    to: '',
    from: '',
    from_user_id: 1,
    attention: '',
    from_company: '',
    reporter_user_id: -1,
    submitted_on: new Date(),
    question: '',
    slug: '',
    recommendation: '',
    impact: 'tbd',
    cost_dollars: '',
    cost_days: '',
    impact_notes: '',
    attachments: [],
    files_to_remove: [],
    files_to_add: [],
    items: [],
    history: [],
    workflow: null,
    response_to_impacts: '',
    response_to_questions: '',
    response_from: '',
    response_date: new Date(),
};
export const SubmitRFI: React.FC = () => {
    const { selectedJob } = useJobs();
    const { user } = useAuth();
    const history = useHistory();
    const initialValues = React.useMemo(() => {
        return {
            ...emptyRFI,
            workflow_id: selectedJob ? selectedJob.rfi_workflow_id : 0,
            workflow: selectedJob?.rfi_workflow || null,
            reporter_user_id: user?.id || -1,
            from_user_id: user?.id || -1,
            from_company: user?.company_name || '',
        };
    }, [selectedJob?.rfi_workflow, user?.id]);
    if (!selectedJob) return null;
    return (
        <>
            <Header2
                breadcrumbs={[
                    { title: 'Jobs', link: '/jobs' },
                    { title: `${selectedJob?.name} - ${selectedJob?.job_number}`, link: `/jobs/${selectedJob?.id}/summary` },
                    { title: 'RFIs', link: `/jobs/${selectedJob.id}/rfis` },
                    { title: 'Submit RFI', link: '' },
                ]}
                actionComponent={
                    <Button intent="none" onClick={() => history.push(`/jobs/${selectedJob.id}/rfis`)}>
                        Cancel
                    </Button>
                }
            />
            <RFIForm selectedJob={selectedJob} onSaved={(rfi) => history.push(`/jobs/${selectedJob.id}/rfis/${rfi.id}`)} initialValues={initialValues} />
        </>
    );
};
