import AppApi from './index';
import { IWorkflow, IWorkflowResponse } from './models/Workflow';
import { getAuthOptions } from './helpers';

const api = AppApi.getApi();

export const loadWorkflow = async (jobId: number, workflowId: string): Promise<IWorkflow> => {
    const url = `/jobs/${jobId}/workflows/${workflowId}`;
    const { data } = await api.get(url, await getAuthOptions());
    return data;
};

export const saveWorkflow = async (jobId: number, workflow: any): Promise<IWorkflowResponse> => {
    const url = `/jobs/${jobId}/workflows/${workflow.id}`;
    const { data } = await api.post(url, workflow, await getAuthOptions());
    return data;
};

export const loadWorkflowOptions = async (resourceId: string, jobId: number): Promise<IWorkflowResponse[]> => {
    const url = `/jobs/${jobId}/workflowOptions/${resourceId}`;
    const { data } = await api.get(url, await getAuthOptions());
    return data;
};

export const deleteWorkflow = async (workflowId: number): Promise<IWorkflowResponse> => {
    const url = `/workflows/${workflowId}`;
    const { data } = await api.delete(url, await getAuthOptions());
    return data;
};

export const loadWorkflowById = async (workflowId: number): Promise<IWorkflowResponse> => {
    const url = `/workflows/${workflowId}`;
    const { data } = await api.get(url, await getAuthOptions());
    return data;
};
