import React from 'react';
import { Button } from '@blueprintjs/core';

interface IWorkflowDividerProps {
    showButton: boolean;
    onButtonClick?: () => void;
}
export const WorkflowDivider: React.FC<IWorkflowDividerProps> = ({ showButton, onButtonClick }) => {
    const buttonClicked = () => {
        if (onButtonClick) {
            onButtonClick();
        }
    };
    return (
        <>
            <div className="workflow-adder-top" />
            {showButton ? (
                <div style={{ height: 25 }}>
                    <div className="workflow-adder-button-container">
                        <Button intent="primary" className="wkfl-add" icon="add" minimal onClick={buttonClicked} />
                    </div>
                </div>
            ) : (
                <div style={{ height: 25 }} className="btn-gap" />
            )}

            <div className="workflow-adder-bottom" />
            <div className="workflow-adder-arrow">▼</div>
        </>
    );
};
