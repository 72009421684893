import React from 'react';
import { Card } from '@blueprintjs/core';
import { Col, Row } from 'react-grid-system';
import { Button as MDButton, ButtonGroup, CircularProgress } from '@mui/material';
import { PostCard } from '../../../ActivityFeed/components/PostCard';
import { loadChangePosts } from '../../../../../api/posts';
import { PostResponse, PostResponseMeta } from '../../../../../api/models/Post';
import { PostItem } from '../../../ActivityFeed/components/PostItem';
import { EditPost } from '../../../ActivityFeed/components/EditPost';

interface IFeedFooterProps {
    changeId?: string;
    jobId: number;
}

const initialPage = {
    current_page: 1,
    first_page: 0,
    last_page: 2,
    per_page: 25,
    total: 0,
};

export const FeedFooter: React.FC<IFeedFooterProps> = ({ changeId, jobId }) => {
    const [feedTab, setFeedTab] = React.useState('activity');
    const [loading, setLoading] = React.useState(false);
    const [openPost, setOpenPost] = React.useState<PostResponse | null>(null);
    const [sort] = React.useState('updated_at');
    const [postType] = React.useState('all');
    const [query] = React.useState('');
    const [page] = React.useState<PostResponseMeta>(initialPage);
    const [filters] = React.useState({
        visibility: 'all',
        resolved: 'unresolved',
        assignedToMe: false,
        createdByMe: false,
        constraint: false,
        critical: false,
        cost: false,
        assigned: false,
        changeId,
    });
    const [posts, setPosts] = React.useState<PostResponse[]>([]);

    React.useEffect(() => {
        loadRelatedFeed();
    }, []);

    const loadRelatedFeed = async () => {
        if (!changeId) return;
        setLoading(true);
        try {
            const data = await loadChangePosts(jobId, sort, filters, postType, query, page.current_page);
            setPosts(data.data);
        } catch (err) {
            console.log('error fetching feed', err);
        }
        setLoading(false);
    };

    const openEditDialog = (item: PostResponse) => {
        setOpenPost(item);
    };
    const closeEditDialog = () => {
        setOpenPost(null);
    };
    const renderPosts = () => {
        if (posts.length === 0) {
            return <h3 className="text-center">No posts</h3>;
        }
        return posts.map((post) => {
            return <PostItem key={post.id} item={post} refreshPosts={loadRelatedFeed} openEditDialog={openEditDialog} />;
        });
    };
    if (!changeId) return null;
    return (
        <Card className="m-t-20">
            <EditPost post={openPost} handleClose={closeEditDialog} onPostSaved={() => loadRelatedFeed()} />
            <Row className="m-b-15">
                <Col>
                    <ButtonGroup variant="outlined" aria-label="outlined button group" size="small">
                        <MDButton variant={feedTab === 'activity' ? 'contained' : 'outlined'} onClick={() => setFeedTab('activity')}>
                            Activity
                        </MDButton>
                        <MDButton variant={feedTab === 'feed' ? 'contained' : 'outlined'} onClick={() => setFeedTab('feed')}>
                            Feed
                        </MDButton>
                    </ButtonGroup>
                </Col>
            </Row>
            {feedTab === 'activity' && (
                <Row>
                    <Col>
                        <PostCard onPostCreated={() => loadRelatedFeed()} />
                    </Col>
                </Row>
            )}
            {feedTab === 'feed' && (
                <Row justify="center">
                    <Col xs={6}>
                        {loading && <CircularProgress />}
                        {renderPosts()}
                    </Col>
                </Row>
            )}
        </Card>
    );
};
