import React from 'react';
import { Classes, Popover2 } from '@blueprintjs/popover2';
import './index.scss';

interface IFormGroupWithHelp {
    helpText: string;
    label: string;
    className?: string;
    labelBottomMargin?: number;
    hidden?: boolean;
}

export const FormGroupWithHelp: React.FC<IFormGroupWithHelp> = ({ label, hidden = false, className = '', labelBottomMargin = 7, helpText, children }) => {
    if (hidden) return <></>;
    return (
        <div className={`help-popover ${className}`}>
            <div className={`m-b-${labelBottomMargin}`}>
                <Popover2 interactionKind="hover" popoverClassName={Classes.POPOVER2_CONTENT_SIZING} enforceFocus={false} position="top" content={<p className="m-0">{helpText}</p>}>
                    <span className={`${Classes.TOOLTIP2_INDICATOR}`}>{label}</span>
                </Popover2>
            </div>
            {children}
        </div>
    );
};
