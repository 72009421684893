import React from 'react';
import { getImageUrl } from '../../api/attachments';
import { Icon } from '@blueprintjs/core';

interface IImageProps {
    id: string;
    onRemoveClick: () => void;
    onImageClick: () => void;
}
export const Image: React.FC<IImageProps> = ({ id, onRemoveClick, onImageClick }) => {
    const [src, setSrc] = React.useState<string | null>(null);

    React.useEffect(() => {
        const load = async () => {
            const url = await getImageUrl(id);
            setSrc(url);
        };
        load();
    }, []);
    if (!src) return null;
    return (
        <div className="image-preview-wrapper">
            <img src={src} width={96} height={96} alt="" style={{ marginRight: 10, display: 'inline-block', objectFit: 'cover' }} onClick={onImageClick} />
            <div className="remove-image-button" onClick={onRemoveClick}>
                <Icon icon="cross" size={10} className="m-b-4" />
            </div>
        </div>
    );
};
