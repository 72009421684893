import React from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { IDailyResponse } from '../../../../api/models/Daily';

interface ITicketsTableProps {
    dailys: IDailyResponse[];
    loading?: boolean;
}

const columns: GridColDef[] = [
    {
        field: 'daily_number',
        headerName: 'Daily',
        width: 250,
    },
    {
        field: 'date',
        headerName: 'Date',
        width: 150,
        renderCell: (params: GridValueGetterParams) => {
            return moment(params.row.date).format('MM/DD/YYYY');
        },
    },
    {
        field: 'created_by',
        headerName: 'Created By',
        width: 150,
        renderCell: (params: GridValueGetterParams) => {
            return params.row.created_by.full_name;
        },
    },
    {
        field: 'status',
        headerName: 'Status',
        width: 150,
        renderCell: (params: GridValueGetterParams) => {
            return params.row.status;
        },
    },
    {
        field: 'description',
        headerName: 'Description',
        width: 150,
    },
];

export const DailyTable: React.FC<ITicketsTableProps> = ({ dailys, loading = true }) => {
    const history = useHistory();
    return (
        <div style={{ height: 600, width: '100%', backgroundColor: '#fff' }}>
            <DataGrid
                sx={{
                    '& .MuiDataGrid-cell': {
                        cursor: 'pointer',
                    },
                }}
                disableSelectionOnClick
                disableColumnSelector
                onCellClick={async (params) => {
                    history.push(`/jobs/${params.row.job_id}/dailys/view/${params.row.id}`);
                }}
                loading={loading}
                rows={dailys}
                columns={columns}
                pageSize={20}
                rowsPerPageOptions={[20]}
            />
        </div>
    );
};
