import React from 'react';
import moment from 'moment';
import { groupBy, sumBy, get, sortBy, uniqBy } from 'lodash';
import { useParams, useHistory, Link } from 'react-router-dom';
import { Row, Col } from 'react-grid-system';
import { CircularProgress, Button, Card, Stack, CardContent, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { CTCJob, CTCJobDetails, CTCJobSummary, CTCMaterial } from 'src/api/models/CTC';
import { JobData, JobDetails } from './components/JobDetails';
import { loadCTCJobById, loadJobMaterialsTotal, loadJobMaterialsByCostCodes } from 'src/api/ctc';
import { Header } from 'src/components/Header';
import { BreakdownDialog } from './components/BreakdownDialog';
import { MaterialsDialog } from './components/MaterialsDialog';
import { Estimator } from './components/Estimator';
import { NonIdealState } from '@blueprintjs/core';
// import { loadJobById } from '../../../api/jobs';
// import { IJob } from '../../../api/models/Job';

interface Column {
    id: string;
    label: string;
    format?: (value: number) => string;
}

const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0,
});

const decimalFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 2,
});

const columns: Column[] = [
    { id: 'description', label: 'Description' },
    { id: 'original', label: 'Original' },
    {
        id: 'revised',
        label: 'Revised',
        format: (value: number) => value.toLocaleString('en-US'),
    },
    {
        id: 'committed',
        label: 'Committed',
        format: (value: number) => value.toLocaleString('en-US'),
    },
    {
        id: 'jtd',
        label: 'JTD',
        format: (value: number) => value.toLocaleString('en-US'),
    },
    {
        id: 'month_to_date',
        label: 'MTD',
        format: (value: number) => value.toLocaleString('en-US'),
    },
    {
        id: 'est',
        label: 'EST',
        format: (value: number) => value.toLocaleString('en-US'),
    },
    {
        id: 'jtd_month',
        label: 'JTD',
        format: (value: number) => value.toLocaleString('en-US'),
    },
    {
        id: 'mtd',
        label: 'MTD',
        format: (value: number) => value.toLocaleString('en-US'),
    },
    {
        id: 'remain',
        label: 'Remain',
        format: (value: number) => value.toLocaleString('en-US'),
    },
    {
        id: 'rate',
        label: 'Rate',
        format: (value: number) => value.toLocaleString('en-US'),
    },
    {
        id: 'ctc_hours',
        label: 'CTC Hours',
        format: (value: number) => value.toLocaleString('en-US'),
    },
    {
        id: 'jtd_rate',
        label: 'JTD Rate',
        format: (value: number) => value.toLocaleString('en-US'),
    },
    {
        id: 'CTC',
        label: 'CTC',
        format: (value: number) => value.toLocaleString('en-US'),
    },
    {
        id: 'cost_at_completion',
        label: 'Cost at Comp',
        format: (value: number) => value.toLocaleString('en-US'),
    },
    {
        id: 'currentDiff',
        label: 'Over / Under',
        format: (value: number) => value.toLocaleString('en-US'),
    },
    // {
    //     id: 'history',
    //     label: '',
    //     format: (value: number) => value.toLocaleString('en-US'),
    // },
];

interface GroupedDetails {
    [category: string]: CTCJobDetails[];
}
interface GroupedSummary {
    [category: string]: CTCJobSummary[];
}

const KEYS = [
    {
        category: 'Labor',
        code: '10',
        codeList: [],
    },
    {
        category: 'Materials',
        code: '20',
        codeList: [],
    },
    {
        category: 'Equip Rental',
        code: '30',
        codeList: [],
    },
    {
        category: 'Permits Rental',
        code: '70',
        codeList: [],
    },
    {
        category: 'Subcontractors',
        code: '40',
        codeList: [],
    },
    {
        category: 'Other',
        code: '50',
        codeList: [],
    },
];

const CODE_LOOKUP: any = {
    '10': 'Labor',
    '20': 'Materials',
    '30': 'Equip Rental',
    '70': 'Permits Rental',
    '40': 'Subcontractors',
    '50': 'Other',
};
const YELLOW = '#f6f233';
const TOTAL_BG = '#ffb5b5';

function createCostCodeDetail(costCodeSummary: CTCJobSummary) {
    return {
        Cost_Code: costCodeSummary.Cost_Code,
        Cost_Code_Description: costCodeSummary.Cost_Code_Description,
        DistDesc: '',
        DOCNUMBR: '',
        EST_Hours: 0,
        CTC_Hours: 0,
        EstimatedRate: 0,
        JTD_Rate: 0,
        JTD_Cost: 0,
        CTC: 0,
        JTD_hours: 0,
        MTD_hours: 0,
        OriginalEstimateCost: 0,
        RevisedEstimateCost: 0,
        WS_Job_Number: '',
        created_at: null,
        id: '',
        updated_at: null,
    };
}
export const ViewCTCJob: React.FC = () => {
    const params: any = useParams();
    const history = useHistory();
    const [selectedLine, setSelectedLine] = React.useState<{ costCode: string; description: string; isLabor: boolean; isMtd?: boolean } | null>(null);
    const [selectedJDTHourLine, setSelectedJDTHourLine] = React.useState<{ costCode: string; description: string } | null>(null);
    const [materialOpen, setMaterialOpen] = React.useState<{ costCode: string; description: string; poNumber: string; isMtd?: boolean } | null>(null);
    const [materialsTotal, setMaterialsTotal] = React.useState({ value: 0, remaining: 0 });
    const [loading, setLoading] = React.useState(true);
    const [error, setError] = React.useState<string | null>(null);
    const [job, setJob] = React.useState<CTCJob | null>(null);
    //const [_, setJobDetails] = React.useState<IJob | null>(null);
    const [details, setDetails] = React.useState<GroupedDetails>({});
    const [summary, setSummary] = React.useState<GroupedSummary>({});
    const [summaryList, setSummaryList] = React.useState<CTCJobSummary[]>([]);
    const [materials, setMaterials] = React.useState<Record<string, CTCMaterial[]>>({});
    const [jobData, setJobData] = React.useState<JobData>({
        original: 0,
        percentComplete: 0,
        contractEarned: 0,
        overUnder: 0,
        postedCOTotal: 0,
        contractToDate: 0,
        costToDate: 0,
        adjustedRevenue: 0,
        expectedContract: 0,
        costAtCompletion: 0,
        grossMarginDollar: 0,
        grossMarginPercent: 0,
        grossBilled: 0,
        retentionHeld: 0,
        retentionBilled: 0,
        netBilled: 0,
        jtdCosts: 0,
    });
    const [ctc, setCtc] = React.useState(0);
    const [adjustedRev, setAdjustedRev] = React.useState(0);

    React.useEffect(() => {
        loadJob(params.id);
    }, [params.id]);

    const onCtcChange = (val: number) => {
        setCtc(val);
    };
    const loadJob = async (id: string) => {
        if (!id) return;
        setLoading(true);
        setError(null);
        try {
            const result = await loadCTCJobById(id);
            const materialsData = await loadJobMaterialsByCostCodes(id);
            const mTotal = await loadJobMaterialsTotal(id);

            // try {
            //     const otherJob = await loadJobById(id);
            //     setJobDetails(otherJob);
            // } catch (err) {
            //     console.log('no linked Job');
            // }

            setMaterialsTotal(mTotal);
            setMaterials(materialsData);
            setJob(result.job);
            setDetails(groupBy(result.details, 'CostCode'));
            setSummary(groupBy(result.summary, 'Cost_Code'));
            setSummaryList(result.summary);
            const j = result.job;
            const top = j.contract_amount - j.cost_at_completion;
            const gmPercent = top === 0 || j.contract_amount === 0 ? 0 : (top / j.contract_amount) * 100;

            const original = result.details[0] ? Number(get(result.details[0], 'OriginalContract', 0)) : 0;
            const postedCOTotal = result.details[0] ? Number(result.details[0].PostedCOTotal) : 0;
            const contractToDate = original + postedCOTotal;
            const adjustedRevenue = result.details[0] ? Number(result.details[0].RevenueAdj) : 0;
            const expectedContract = contractToDate + adjustedRevenue;

            let costAtCompletion = 0;
            let costToDate = 0;
            result.summary.forEach((s) => {
                costAtCompletion += Number(s.CTC) + Number(s.JTD_Cost);
                costToDate += Number(s.JTD_Cost);
            });

            //const costAtCompletion = Number(result.job.cost_at_completion) + Number(result.job.adjustments_total);

            const grossMarginDollar = expectedContract - costAtCompletion;
            const grossMarginPercent = Math.round(gmPercent);
            const grossBilled = result.details[0] ? Number(result.details[0].GrossBilled) : 0;
            const retentionHeld = result.details[0] ? Number(result.details[0].RetentionHeld) : 0;
            const retentionBilled = result.details[0] ? Number(result.details[0].RetentionBilled) : 0;
            const netBilled = grossBilled + retentionBilled - retentionHeld;
            const jtdCosts = Number(result.job.jtd_costs);

            const contractEarned = (costToDate / costAtCompletion) * expectedContract;
            const overUnder = grossBilled - contractEarned;
            const percentComplete = Math.round((costToDate / costAtCompletion) * 100);
            const data: JobData = {
                original,
                overUnder,
                contractEarned,
                postedCOTotal,
                contractToDate,
                adjustedRevenue,
                expectedContract,
                costAtCompletion,
                grossMarginDollar,
                grossMarginPercent,
                grossBilled,
                percentComplete,
                costToDate,
                retentionHeld,
                retentionBilled,
                netBilled,
                jtdCosts,
            };
            setJobData(data);
        } catch (err) {
            setError(err.toLocaleString());
        }

        setLoading(false);
    };
    const renderRows = () => {
        const updatedKeys = KEYS.map((key) => {
            return {
                ...key,
                codeList: summaryList
                    .filter((summaryItem) => {
                        return summaryItem.Cost_Code.slice(0, 2) === key.code;
                    })
                    .map((s) => {
                        return { cc: s.Cost_Code, description: s.Cost_Code_Description };
                    }),
            };
        });
        const today = moment();
        return updatedKeys.map((settings) => {
            const { category, codeList } = settings;
            const isLabor = category === 'Labor';
            const totals = {
                OriginalEstimateCost: 0,
                RevisedEstimateCost: 0,
                RemainingPoBalanceTotal: 0,
                MTD_Cost: 0,
                JTD_Cost: 0,
                EST_Hours: 0,
                JTD_Hours: 0,
                MTD_Hours: 0,
                Remain: 0,
                CTC: 0,
                Cost: 0,
                Diff: 0,
            };
            return (
                <>
                    {sortBy(codeList, [
                        function (o) {
                            return o.cc;
                        },
                    ]).map((obj) => {
                        const costCodeDetailItems = (details[obj.cc] || []).map((r) => {
                            return {
                                ...r,
                                NumberAmount: Number(r.Amount),
                            };
                        });
                        const costCodeSummaryItems = summary[obj.cc] || [];
                        const costCodeSummary = costCodeSummaryItems[0];
                        if (!costCodeSummary) return null;
                        const costCodeDetails = costCodeDetailItems[0] || createCostCodeDetail(costCodeSummary);
                        const allMaterials = materials[costCodeDetails.CostCode] || [];
                        const materialNumbers = uniqBy(allMaterials, 'PONUMBER').map((m) => {
                            return {
                                ...m,
                                PO_Amount: Number(m.PO_Amount),
                                Remaining_PO_Balance: Number(m.Remaining_PO_Balance),
                            };
                        });
                        const currentMonthDetails = costCodeDetailItems.filter((d) => moment(new Date(d.DOCDATE)).isSame(today, 'month'));
                        const currentMonthAmountTotal = sumBy(currentMonthDetails, 'NumberAmount') | 0;
                        const remainingPoBalanceTotal = sumBy(materialNumbers, 'Remaining_PO_Balance');
                        const cost = Number(costCodeSummary.CTC) + Number(costCodeSummary.JTD_Cost);
                        const diff = costCodeSummary.RevisedEstimateCost - cost;

                        totals.OriginalEstimateCost = totals.OriginalEstimateCost + Number(costCodeSummary.OriginalEstimateCost);
                        totals.RevisedEstimateCost = totals.RevisedEstimateCost + Number(costCodeSummary.RevisedEstimateCost);
                        totals.RemainingPoBalanceTotal = totals.RemainingPoBalanceTotal + remainingPoBalanceTotal;
                        totals.MTD_Cost = totals.MTD_Cost + currentMonthAmountTotal;
                        totals.JTD_Cost = totals.JTD_Cost + Number(costCodeSummary.JTD_Cost);
                        totals.EST_Hours += Number(costCodeSummary.EST_Hours);
                        totals.JTD_Hours += Number(costCodeSummary.JTD_hours);
                        totals.MTD_Hours += Number(costCodeSummary.MTD_hours);
                        totals.Remain += Math.round(Number(costCodeSummary.EST_Hours) - Number(costCodeSummary.JTD_hours));
                        totals.CTC += Number(costCodeSummary.CTC);
                        totals.Cost += cost;
                        totals.Diff += diff;
                        return (
                            <TableBody key={obj.cc}>
                                <TableRow tabIndex={-1}>
                                    <TableCell align="left" colSpan={1}>
                                        {obj.description}
                                    </TableCell>
                                    <TableCell align="right" colSpan={1}>
                                        {formatter.format(costCodeSummary.OriginalEstimateCost)}
                                    </TableCell>
                                    <TableCell align="right" colSpan={1}>
                                        {formatter.format(costCodeSummary.RevisedEstimateCost)}
                                    </TableCell>
                                    <TableCell align="right" colSpan={1}>
                                        {!isLabor ? (
                                            <a
                                                onClick={() => {
                                                    setMaterialOpen({ costCode: costCodeDetails.CostCode, description: '', poNumber: '123' });
                                                }}
                                            >
                                                {formatter.format(remainingPoBalanceTotal)}
                                            </a>
                                        ) : (
                                            <>-</>
                                        )}
                                    </TableCell>
                                    <TableCell align="right" colSpan={1}>
                                        <a
                                            onClick={() => {
                                                setSelectedLine({ costCode: costCodeDetails.CostCode, description: costCodeDetails.Cost_Code_Description, isLabor });
                                            }}
                                        >
                                            {formatter.format(costCodeSummary.JTD_Cost)}
                                        </a>
                                    </TableCell>
                                    <TableCell align="right" colSpan={1}>
                                        <a
                                            onClick={() => {
                                                setSelectedLine({ costCode: costCodeDetails.CostCode, description: costCodeDetails.Cost_Code_Description, isLabor: category === 'Labor', isMtd: true });
                                            }}
                                        >
                                            {formatter.format(currentMonthAmountTotal)}
                                        </a>
                                    </TableCell>
                                    <TableCell align="right" colSpan={1}>
                                        {isLabor ? <>{Math.round(costCodeSummary.EST_Hours).toLocaleString()}</> : '-'}
                                    </TableCell>
                                    <TableCell align="right" colSpan={1}>
                                        {isLabor ? (
                                            <a
                                                onClick={() => {
                                                    setSelectedLine({ costCode: costCodeDetails.CostCode, description: costCodeDetails.Cost_Code_Description, isLabor });
                                                }}
                                            >
                                                {Math.round(costCodeSummary.JTD_hours).toLocaleString()}
                                            </a>
                                        ) : (
                                            '-'
                                        )}
                                    </TableCell>
                                    <TableCell align="right" colSpan={1}>
                                        {isLabor ? (
                                            <a
                                                onClick={() => {
                                                    setSelectedLine({ costCode: costCodeDetails.CostCode, description: costCodeDetails.Cost_Code_Description, isLabor });
                                                }}
                                            >
                                                {Math.round(costCodeSummary.MTD_hours).toLocaleString()}
                                            </a>
                                        ) : (
                                            '-'
                                        )}
                                    </TableCell>
                                    <TableCell align="right" colSpan={1}>
                                        {isLabor ? <>{Math.round(costCodeSummary.EST_Hours - costCodeSummary.JTD_hours).toLocaleString()}</> : '-'}
                                    </TableCell>
                                    <TableCell align="right" colSpan={1}>
                                        {isLabor ? <>{formatter.format(costCodeSummary.EstimatedRate)}</> : <>-</>}
                                    </TableCell>

                                    <TableCell align="right" colSpan={1} style={{ backgroundColor: YELLOW }}>
                                        {isLabor ? <>{Number(costCodeSummary.CTC_Hours).toLocaleString()}</> : <>-</>}
                                    </TableCell>
                                    <TableCell align="right" colSpan={1} style={{ backgroundColor: YELLOW }}>
                                        {isLabor ? <>{decimalFormatter.format(costCodeSummary.JTD_Rate)}</> : <>-</>}
                                    </TableCell>
                                    <TableCell align="right" colSpan={1} style={{ backgroundColor: YELLOW }}>
                                        {formatter.format(costCodeSummary.CTC)}
                                    </TableCell>
                                    <TableCell align="right" colSpan={1} style={{ backgroundColor: YELLOW }}>
                                        {cost < 0 ? <span className="text-danger">({formatter.format(cost * -1)})</span> : formatter.format(cost)}
                                    </TableCell>
                                    <TableCell align="right" colSpan={1}>
                                        {diff < 0 ? <span className="text-danger">({formatter.format(diff * -1)})</span> : formatter.format(diff)}
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        );
                    })}
                    {codeList.length > 0 && (
                        <TableBody>
                            <TableRow>
                                <TableCell align="left" style={{ borderBottom: '1px solid #000', backgroundColor: '#f3f3f3' }} colSpan={1}>
                                    <b>Total {category}:</b>
                                </TableCell>
                                <TableCell style={{ borderBottom: '1px solid #000', backgroundColor: '#f3f3f3' }} align="right" colSpan={1}>
                                    <b>{formatter.format(totals.OriginalEstimateCost)}</b>
                                </TableCell>
                                <TableCell style={{ borderBottom: '1px solid #000', backgroundColor: '#f3f3f3' }} align="right" colSpan={1}>
                                    <b>{formatter.format(totals.RevisedEstimateCost)}</b>
                                </TableCell>
                                <TableCell style={{ borderBottom: '1px solid #000', backgroundColor: '#f3f3f3' }} align="right" colSpan={1}>
                                    {!isLabor ? <b>{formatter.format(totals.RemainingPoBalanceTotal)}</b> : <>-</>}
                                </TableCell>
                                <TableCell style={{ borderBottom: '1px solid #000', backgroundColor: '#f3f3f3' }} align="right" colSpan={1}>
                                    <b>{formatter.format(totals.JTD_Cost)}</b>
                                </TableCell>
                                <TableCell style={{ borderBottom: '1px solid #000', backgroundColor: '#f3f3f3' }} align="right" colSpan={1}>
                                    <b>{formatter.format(totals.MTD_Cost)}</b>
                                </TableCell>
                                <TableCell style={{ borderBottom: '1px solid #000', backgroundColor: '#f3f3f3' }} align="right" colSpan={1}>
                                    {isLabor ? <b>{Math.round(totals.EST_Hours).toLocaleString()}</b> : '-'}
                                </TableCell>
                                <TableCell style={{ borderBottom: '1px solid #000', backgroundColor: '#f3f3f3' }} align="right" colSpan={1}>
                                    {isLabor ? <b>{Math.round(totals.JTD_Hours).toLocaleString()}</b> : '-'}
                                </TableCell>
                                <TableCell style={{ borderBottom: '1px solid #000', backgroundColor: '#f3f3f3' }} align="right" colSpan={1}>
                                    {isLabor ? <b>{Math.round(totals.MTD_Hours).toLocaleString()}</b> : '-'}
                                </TableCell>
                                <TableCell style={{ borderBottom: '1px solid #000', backgroundColor: '#f3f3f3' }} align="right" colSpan={1}>
                                    {isLabor ? <b>{totals.Remain.toLocaleString()}</b> : '-'}
                                </TableCell>
                                <TableCell style={{ borderBottom: '1px solid #000', backgroundColor: '#f3f3f3' }} align="right" colSpan={1}>
                                    -
                                </TableCell>
                                <TableCell style={{ borderBottom: '1px solid #000', backgroundColor: '#f3f3f3' }} align="right" colSpan={1}>
                                    -
                                </TableCell>
                                <TableCell style={{ borderBottom: '1px solid #000', backgroundColor: '#f3f3f3' }} align="right" colSpan={1}>
                                    -
                                </TableCell>
                                <TableCell style={{ borderBottom: '1px solid #000', backgroundColor: '#f3f3f3' }} align="right" colSpan={1}>
                                    <b>{formatter.format(totals.CTC)}</b>
                                </TableCell>
                                <TableCell style={{ borderBottom: '1px solid #000', backgroundColor: '#f3f3f3' }} align="right" colSpan={1}>
                                    <b>{totals.Cost < 0 ? <span className="text-danger">({formatter.format(totals.Cost * -1)})</span> : formatter.format(totals.Cost)}</b>
                                </TableCell>
                                <TableCell style={{ borderBottom: '1px solid #000', backgroundColor: '#f3f3f3' }} align="right" colSpan={1}>
                                    <b>{totals.Diff < 0 ? <span className="text-danger">({formatter.format(totals.Diff * -1)})</span> : formatter.format(totals.Diff)}</b>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    )}
                </>
            );
        });
    };

    const renderFooter = () => {
        const isLabor = true;
        const today = moment();
        const totals = {
            OriginalEstimateCost: 0,
            RevisedEstimateCost: 0,
            RemainingPoBalanceTotal: 0,
            MTD_Cost: 0,
            JTD_Cost: 0,
            EST_Hours: 0,
            JTD_Hours: 0,
            MTD_Hours: 0,
            Remain: 0,
            CTC: 0,
            Cost: 0,
            Diff: 0,
        };

        summaryList.forEach((costCodeSummary) => {
            const allMaterials = materials[costCodeSummary.Cost_Code] || [];
            const materialNumbers = uniqBy(allMaterials, 'PONUMBER').map((m) => {
                return {
                    ...m,
                    PO_Amount: Number(m.PO_Amount),
                    Remaining_PO_Balance: Number(m.Remaining_PO_Balance),
                };
            });

            const costCodeDetailItems = (details[costCodeSummary.Cost_Code] || []).map((r) => {
                return {
                    ...r,
                    NumberAmount: Number(r.Amount),
                };
            });

            const currentMonthDetails = costCodeDetailItems.filter((d) => moment(new Date(d.DOCDATE)).isSame(today, 'month'));
            const currentMonthAmountTotal = sumBy(currentMonthDetails, 'NumberAmount') | 0;
            const remainingPoBalanceTotal = sumBy(materialNumbers, 'Remaining_PO_Balance');
            const cost = Number(costCodeSummary.CTC) + Number(costCodeSummary.JTD_Cost);
            const diff = costCodeSummary.RevisedEstimateCost - cost;

            const category = CODE_LOOKUP[costCodeSummary.Cost_Code.slice(0, 2)];
            if (!category) return;
            const isLabor = category === 'Labor';
            totals.OriginalEstimateCost += Number(costCodeSummary.OriginalEstimateCost);
            totals.RevisedEstimateCost += Number(costCodeSummary.RevisedEstimateCost);
            totals.JTD_Cost += Number(costCodeSummary.JTD_Cost);
            totals.CTC += Number(costCodeSummary.CTC);
            totals.MTD_Cost += currentMonthAmountTotal;
            totals.Cost += cost;
            totals.Diff += diff;

            if (!isLabor) {
                totals.RemainingPoBalanceTotal += remainingPoBalanceTotal;
            }

            if (isLabor) {
                totals.EST_Hours += Number(costCodeSummary.EST_Hours);
                totals.JTD_Hours += Number(costCodeSummary.JTD_hours);
                totals.MTD_Hours += Number(costCodeSummary.MTD_hours);
                totals.Remain += Math.round(Number(costCodeSummary.EST_Hours) - Number(costCodeSummary.JTD_hours));
            }
        });
        return (
            <TableBody>
                <TableRow>
                    <TableCell align="left" style={{ borderBottom: '1px solid #000', backgroundColor: TOTAL_BG }} colSpan={1}>
                        <b>Total Job:</b>
                    </TableCell>
                    <TableCell style={{ borderBottom: '1px solid #000', backgroundColor: TOTAL_BG }} align="right" colSpan={1}>
                        <b>{formatter.format(totals.OriginalEstimateCost)}</b>
                    </TableCell>
                    <TableCell style={{ borderBottom: '1px solid #000', backgroundColor: TOTAL_BG }} align="right" colSpan={1}>
                        <b>{formatter.format(totals.RevisedEstimateCost)}</b>
                    </TableCell>
                    <TableCell style={{ borderBottom: '1px solid #000', backgroundColor: TOTAL_BG }} align="right" colSpan={1}>
                        <b>{formatter.format(totals.RemainingPoBalanceTotal)}</b>
                    </TableCell>
                    <TableCell style={{ borderBottom: '1px solid #000', backgroundColor: TOTAL_BG }} align="right" colSpan={1}>
                        <b>{formatter.format(totals.JTD_Cost)}</b>
                    </TableCell>
                    <TableCell style={{ borderBottom: '1px solid #000', backgroundColor: TOTAL_BG }} align="right" colSpan={1}>
                        <b>{formatter.format(totals.MTD_Cost)}</b>
                    </TableCell>
                    <TableCell style={{ borderBottom: '1px solid #000', backgroundColor: TOTAL_BG }} align="right" colSpan={1}>
                        {isLabor ? <b>{Math.round(totals.EST_Hours).toLocaleString()}</b> : '-'}
                    </TableCell>
                    <TableCell style={{ borderBottom: '1px solid #000', backgroundColor: TOTAL_BG }} align="right" colSpan={1}>
                        {isLabor ? <b>{Math.round(totals.JTD_Hours).toLocaleString()}</b> : '-'}
                    </TableCell>
                    <TableCell style={{ borderBottom: '1px solid #000', backgroundColor: TOTAL_BG }} align="right" colSpan={1}>
                        {isLabor ? <b>{Math.round(totals.MTD_Hours).toLocaleString()}</b> : '-'}
                    </TableCell>
                    <TableCell style={{ borderBottom: '1px solid #000', backgroundColor: TOTAL_BG }} align="right" colSpan={1}>
                        {isLabor ? <b>{totals.Remain.toLocaleString()}</b> : '-'}
                    </TableCell>
                    <TableCell style={{ borderBottom: '1px solid #000', backgroundColor: TOTAL_BG }} align="right" colSpan={1}>
                        -
                    </TableCell>
                    <TableCell style={{ borderBottom: '1px solid #000', backgroundColor: TOTAL_BG }} align="right" colSpan={1}>
                        -
                    </TableCell>
                    <TableCell style={{ borderBottom: '1px solid #000', backgroundColor: TOTAL_BG }} align="right" colSpan={1}>
                        -
                    </TableCell>
                    <TableCell style={{ borderBottom: '1px solid #000', backgroundColor: TOTAL_BG }} align="right" colSpan={1}>
                        <b>{formatter.format(totals.CTC)}</b>
                    </TableCell>
                    <TableCell style={{ borderBottom: '1px solid #000', backgroundColor: TOTAL_BG }} align="right" colSpan={1}>
                        <b>{totals.Cost < 0 ? <span className="text-danger">({formatter.format(totals.Cost * -1)})</span> : formatter.format(totals.Cost)}</b>
                    </TableCell>
                    <TableCell style={{ borderBottom: '1px solid #000', backgroundColor: TOTAL_BG }} align="right" colSpan={1}>
                        <b>{totals.Diff < 0 ? <span className="text-danger">({formatter.format(totals.Diff * -1)})</span> : formatter.format(totals.Diff)}</b>
                    </TableCell>
                </TableRow>
            </TableBody>
        );
    };
    const getType = (v: string) => {
        const items = v.split('-');
        return items[1] ? items[1] : 'N/A';
    };
    const unauth = 'Error: Request failed with status code 401';
    return (
        <>
            <Header
                title="CTC"
                breadcrumbs={['CTC', job ? `${job.ws_job_number} - ${job.ws_job_name}` : '']}
                goBackIndices={[0]}
                goBackPath={`/ctc`}
                actionComponent={<Button onClick={() => history.push(`/ctc`)}>Back</Button>}
            />

            {error && error !== unauth && <p>{error}</p>}
            {error && error === unauth && (
                <Row className="m-t-20">
                    <Col>
                        <NonIdealState
                            action={<Button onClick={() => history.push('/ctc')}>Go Back</Button>}
                            icon="blocked-person"
                            title="Access Denied"
                            description="You do not have access to view this job.  Please request access from your administrator"
                        />
                    </Col>
                </Row>
            )}
            {loading ? (
                <div className="text-center p-t-100">
                    <CircularProgress />
                </div>
            ) : (
                <>
                    {job && (
                        <>
                            <BreakdownDialog
                                costCode={selectedLine?.costCode || null}
                                description={selectedLine?.description || null}
                                isLabor={selectedLine?.isLabor || false}
                                handleClose={() => setSelectedLine(null)}
                                jobNumber={job.ws_job_number}
                                isMtd={selectedLine?.isMtd}
                            />
                            <BreakdownDialog
                                costCode={selectedJDTHourLine?.costCode || null}
                                description={selectedJDTHourLine?.description || null}
                                isLabor={selectedLine?.isLabor || false}
                                handleClose={() => setSelectedJDTHourLine(null)}
                                isHours
                                jobNumber={job.ws_job_number}
                            />
                            <MaterialsDialog
                                open={materialOpen !== null}
                                costCode={materialOpen?.costCode || null}
                                poNumber={materialOpen?.poNumber || null}
                                description={materialOpen?.description || null}
                                handleClose={() => setMaterialOpen(null)}
                                jobNumber={job.ws_job_number}
                                totalValue={materialsTotal.value}
                                totalRemaining={materialsTotal.remaining}
                            />

                            <Row>
                                <Col>
                                    <Card sx={{ mb: 2 }}>
                                        <CardContent style={{ paddingBottom: 10 }}>
                                            <Stack direction="row" justifyContent="space-between" alignItems="start" spacing={2}>
                                                <div>
                                                    <p className="fs-16">
                                                        <b>Job Name:</b>{' '}
                                                        <Link to={`/jobs/${job.ws_job_number}/summary`}>
                                                            {job.ws_job_number} - {job.ws_job_name}
                                                        </Link>
                                                    </p>
                                                    <p className="fs-16">
                                                        <b>Customer:</b> {job.customer}
                                                    </p>
                                                    <p className="fs-16">
                                                        <b>Job Type:</b> {getType(job.division)}
                                                    </p>
                                                </div>
                                                <div>
                                                    <p>
                                                        <b>PM:</b> N/A
                                                    </p>

                                                    <p>
                                                        <b>Division:</b> {job.division}
                                                    </p>
                                                    <p>
                                                        <b>Salesperson:</b> {job.salesperson}
                                                    </p>
                                                </div>
                                                <div>
                                                    <p>
                                                        <b>Certified Payroll:</b> {job.certified_payroll}
                                                    </p>
                                                    <p>
                                                        <b>Bonded:</b> {job.bonded}
                                                    </p>
                                                    <p>
                                                        <b>OCIP:</b> {job.ocip}
                                                    </p>
                                                </div>
                                                <div>
                                                    <p>
                                                        <b>LDS:</b> {job.lds}
                                                    </p>
                                                    <p>
                                                        <b>Tax Exempt:</b> {job.taxExempt}
                                                    </p>
                                                    <p>
                                                        <b>Vendor Discounts:</b> {job.vendorDiscounts}
                                                    </p>
                                                </div>
                                                <div></div>
                                            </Stack>
                                        </CardContent>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="m-b-15">
                                    <Card>
                                        <CardContent>
                                            <TableContainer sx={{ maxHeight: 600, overflow: 'auto' }}>
                                                <Table size="small" stickyHeader>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell align="center" colSpan={1} style={{ backgroundColor: '#d6ffd6', borderLeft: '1px solid #e0e0e0' }}>
                                                                Cost Codes
                                                            </TableCell>
                                                            <TableCell align="center" colSpan={2} style={{ backgroundColor: '#d6ffd6', borderLeft: '1px solid #e0e0e0' }}>
                                                                Estimates - System
                                                            </TableCell>
                                                            <TableCell align="center" colSpan={3} style={{ backgroundColor: '#d6ffd6', borderLeft: '1px solid #e0e0e0' }}>
                                                                Actuals
                                                            </TableCell>
                                                            <TableCell align="center" colSpan={3} style={{ backgroundColor: '#d6ffd6', borderLeft: '1px solid #e0e0e0' }}>
                                                                Hours
                                                            </TableCell>
                                                            <TableCell align="center" colSpan={2} style={{ backgroundColor: '#d6ffd6', borderLeft: '1px solid #e0e0e0' }}>
                                                                Current Est
                                                            </TableCell>
                                                            <TableCell align="center" colSpan={4} style={{ backgroundColor: '#9ed6fc', borderLeft: '1px solid #e0e0e0' }}>
                                                                Current Month Adjustments
                                                            </TableCell>
                                                            <TableCell align="center" colSpan={4} style={{ backgroundColor: '#f6f6f6', borderLeft: '1px solid #e0e0e0' }}>
                                                                Current Change from Estimate
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow className="sticky-header-group">
                                                            {columns.map((column) => (
                                                                <TableCell key={column.id} align="center" style={{ top: 57, backgroundColor: '#e9e9e9' }} colSpan={column.id === 'currentDiff' ? 1 : 1}>
                                                                    {column.label}
                                                                </TableCell>
                                                            ))}
                                                        </TableRow>
                                                    </TableHead>
                                                    {renderRows()}
                                                    {renderFooter()}
                                                </Table>
                                            </TableContainer>
                                        </CardContent>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} sm={6} md={3} className="m-b-15">
                                    <JobDetails title="Job To Date" jobData={jobData} />
                                </Col>
                                <Col xs={12} sm={12} md={6} className="m-b-15 p-l-0">
                                    <Estimator
                                        summaryList={summaryList}
                                        adjustedRevenue={jobData.adjustedRevenue}
                                        details={details}
                                        summary={summary}
                                        materials={materials}
                                        jobId={job.ws_job_number}
                                        onCtcChange={onCtcChange}
                                        onAdjustedRevChange={setAdjustedRev}
                                    />
                                </Col>
                                <Col xs={12} sm={6} md={3} className="m-b-15 p-l-0">
                                    <JobDetails title="Operations to Date" jobData={jobData} adjusted={adjustedRev} ctc={ctc} isCalc={true} />
                                </Col>
                            </Row>
                        </>
                    )}
                </>
            )}
        </>
    );
};
