import React from 'react';
import { Row, Col } from 'react-grid-system';
import { Card, Button, FormGroup, InputGroup, Checkbox, Dialog, Classes, NonIdealState, Spinner, Menu, MenuItem } from '@blueprintjs/core';
import { OfficeSelector } from '../Selectors/OfficeSelector';
import { createCostCode, listCostCodes, updateCostCode } from 'src/api/wbs';
import { CostCodeResponse } from 'src/api/models/WBS';

interface CostCodeFormProps {
    onCancelClick: () => void;
    onSave: () => void;
    data?: CostCodeResponse;
}

const initialValues = {
    code: '',
    description: '',
    offices: [],
    active: true,
};

const CostCodeForm: React.FC<CostCodeFormProps> = ({ onCancelClick, onSave, data }) => {
    const [values, setValues] = React.useState(
        data
            ? {
                  code: data.code,
                  description: data.description,
                  offices: data.offices || [],
                  active: data.active,
              }
            : initialValues,
    );

    const [saving, setSaving] = React.useState(false);
    const [error, setError] = React.useState<string | null>(null);

    const updateField = (field: string, value: any) => {
        setValues((prevState) => {
            return {
                ...prevState,
                [field]: value,
            };
        });
    };

    const saveCostCode = async () => {
        setSaving(true);
        setError(null);
        try {
            if (data) {
                await updateCostCode(data.id, values);
            } else {
                await createCostCode(values);
            }
            onSave();
            setValues(initialValues);
        } catch (err) {
            setError(err.toLocaleString());
        }
        setSaving(false);
    };
    return (
        <>
            <Row>
                <Col>
                    <FormGroup label="Cost Code" labelFor="code-field" labelInfo="*">
                        <InputGroup autoFocus autoComplete="off" id="code-field" placeholder="Enter cost code..." value={values.code} onChange={(e) => updateField('code', e.target.value)} />
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup label="Description" labelFor="description-field" labelInfo="*">
                        <InputGroup
                            autoFocus
                            autoComplete="off"
                            id="description-field"
                            placeholder="Enter description..."
                            value={values.description}
                            onChange={(e) => updateField('description', e.target.value)}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormGroup label="Offices" labelFor="office-field" labelInfo="*">
                        <OfficeSelector onChange={(value) => updateField('offices', value)} initialValue={[]} />
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup label="Status" labelFor="office-field">
                        <Checkbox checked={values.active} label="Active" onChange={(e: any) => updateField('active', e.target.checked)} />
                    </FormGroup>
                </Col>
            </Row>
            {error && <p className="text-center">{error}</p>}
            <Row>
                <Col className="text-right">
                    <Button onClick={onCancelClick}>Cancel</Button>{' '}
                    <Button intent="primary" loading={saving} onClick={saveCostCode}>
                        Save
                    </Button>
                </Col>
            </Row>
        </>
    );
};

export const CostCodes: React.FC = () => {
    const [showForm, setShowForm] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [codes, setCodes] = React.useState<CostCodeResponse[]>([]);
    const [selectedCode, setSelectedCode] = React.useState<CostCodeResponse | null>(null);
    React.useEffect(() => {
        loadCostCodes();
    }, []);

    const loadCostCodes = async (load = true) => {
        setLoading(load);
        const data = await listCostCodes();
        setCodes(data);
        setLoading(false);
    };

    const openCode = (code: CostCodeResponse) => {
        setSelectedCode(code);
        setShowForm(true);
    };
    const closeCode = () => {
        setSelectedCode(null);
        setShowForm(false);
    };

    const renderCodes = () => {
        if (codes.length === 0) {
            return <NonIdealState description="No Cost Codes" className="m-t-20" />;
        }
        return codes.map((code) => {
            return <MenuItem key={code.id} onClick={() => openCode(code)} text={`${code.code} - ${code.description}`} label={code.active ? 'Active' : 'Inactive'} />;
        });
    };

    const onCostCodeCreated = () => {
        loadCostCodes(false);
        setShowForm(false);
        setSelectedCode(null);
    };

    return (
        <Card>
            <Row>
                <Col>
                    <h3 className="m-t-0">Cost Codes</h3>
                </Col>
                <Col className="text-right">
                    <Button small intent="primary" minimal icon="add" onClick={() => setShowForm(true)}>
                        Add Cost Code
                    </Button>
                </Col>
            </Row>
            <div style={{ minHeight: 300 }}>
                <Row>
                    {loading ? (
                        <Col className="text-center p-t-50">
                            <Spinner />
                        </Col>
                    ) : (
                        <Col className="p-0">
                            <Menu>{renderCodes()}</Menu>
                        </Col>
                    )}
                </Row>
            </div>
            <Dialog usePortal={true} isOpen={showForm} onClose={closeCode} title="Create Cost Code">
                <div className={`${Classes.DIALOG_BODY} m-b-0`}>{showForm && <CostCodeForm onSave={onCostCodeCreated} data={selectedCode ? selectedCode : undefined} onCancelClick={closeCode} />}</div>
            </Dialog>
        </Card>
    );
};
