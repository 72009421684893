import React from 'react';
import moment from 'moment';
import { Icon, Navbar, Button } from '@blueprintjs/core';
import { useHistory } from 'react-router-dom';
import { Badge, Drawer, Button as MButton, Box, List, CircularProgress, Tooltip, ListItemText, ListItemButton, Typography, Divider } from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import FiberManualRecordOutlinedIcon from '@mui/icons-material/FiberManualRecordOutlined';
import { loadUserNotifications, markNotificationRead } from 'src/api/user';
import { UserNotification } from 'src/api/models/User';

const DrawerWidth = 350;
export const Notifications: React.FC = () => {
    const history = useHistory();
    const [tab, setTab] = React.useState('all');
    const [open, setOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [notifications, setNotifications] = React.useState<UserNotification[]>([]);
    const [unreadCount, setUnreadCount] = React.useState(0);
    React.useEffect(() => {
        loadNotifications(false, tab);
        const interval = setInterval(() => {
            loadNotifications(false, tab);
        }, 15000);

        return () => clearInterval(interval);
    }, [tab]);

    React.useEffect(() => {
        loadNotifications(true, tab);
    }, [tab]);

    const loadNotifications = async (load = true, t = 'unread') => {
        setLoading(load);
        const data = await loadUserNotifications(t === 'unread');
        setNotifications(data);
        const count = data.filter((d) => !d.read).length;
        setUnreadCount(count);
        setLoading(false);
    };

    const markRead = async (notificationId: string) => {
        const updated = await markNotificationRead(notificationId);
        const updateNotifications = notifications.map((n) => {
            if (n.id === notificationId) {
                return updated;
            }
            return n;
        });
        setNotifications(updateNotifications);
    };

    const renderNotifications = () => {
        if (notifications.length === 0) {
            return <p className="m-l-20 m-t-10">No Notifications</p>;
        }
        return notifications.map((notification) => {
            const createdDate = moment(notification.created_at);
            const timeago = moment().diff(createdDate, 'days') <= 1;
            return (
                <span key={notification.id}>
                    <ListItemButton key={notification.id} alignItems="flex-start">
                        <ListItemText
                            onClick={() => {
                                setOpen(false);
                                history.push(`/jobs/${notification.job_id}/activity?postId=${notification.post_id}`);
                            }}
                            secondary={
                                <React.Fragment>
                                    <Typography sx={{ display: 'inline' }} component="span" variant="body2" color="text.primary">
                                        {notification.person.full_name}{' '}
                                    </Typography>
                                    {notification.message}{' '}
                                    <Typography sx={{ display: 'inline' }} component="span" variant="body2" color="text.primary">
                                        in {notification.job.name}
                                    </Typography>
                                    <div>
                                        <Typography sx={{ display: 'inline', color: notification.read ? '#626e7f' : '#0072e4' }} component="span" variant="body2">
                                            {timeago ? createdDate.fromNow() : createdDate.format('MM/DD/YYYY [at] h:mm a')}
                                        </Typography>
                                    </div>
                                </React.Fragment>
                            }
                        />
                        {notification.read ? (
                            <Tooltip title="Read">
                                <FiberManualRecordOutlinedIcon color="primary" className="m-t-20 pointer" onClick={() => markRead(notification.id)} />
                            </Tooltip>
                        ) : (
                            <Tooltip title="Unread">
                                <FiberManualRecordIcon color="primary" className="m-t-20 pointer" onClick={() => markRead(notification.id)} />
                            </Tooltip>
                        )}
                    </ListItemButton>
                    <Divider variant="inset" component="li" />
                </span>
            );
        });
    };
    return (
        <>
            <Button className="bp3-minimal" onClick={() => setOpen((prevState) => !prevState)}>
                <Badge badgeContent={unreadCount} color="primary" variant="dot">
                    <Icon icon="notifications" />
                </Badge>
            </Button>

            <Navbar.Divider />
            <Drawer
                elevation={1}
                anchor="right"
                sx={{
                    width: DrawerWidth,

                    flexShrink: 0,
                    [`& .MuiDrawer-paper`]: { width: DrawerWidth, boxSizing: 'border-box' },
                }}
                open={open}
                onClose={() => setOpen(false)}
                //hideBackdrop
                variant="temporary"
            >
                <Box sx={{ width: DrawerWidth, p: 1 }} role="presentation">
                    <h2 className="m-0 p-l-15">Notifications</h2>
                    <div className="m-15">
                        <MButton variant={tab === 'all' ? 'contained' : 'text'} className="m-r-5" onClick={() => setTab('all')} size="small">
                            All
                        </MButton>
                        <MButton variant={tab === 'unread' ? 'contained' : 'text'} onClick={() => setTab('unread')} size="small">
                            Unread
                        </MButton>
                    </div>
                    {loading ? (
                        <div className="text-center p-t-50">
                            <CircularProgress />
                        </div>
                    ) : (
                        <List sx={{ width: '100%', maxWidth: DrawerWidth, bgcolor: 'background.paper' }}>{renderNotifications()}</List>
                    )}
                </Box>
            </Drawer>
        </>
    );
};
