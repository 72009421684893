import React from 'react';
import { Button, ButtonProps } from '@blueprintjs/core';
import { useAuth } from '../../hooks/useAuth';
import { intersection } from 'lodash';

interface IAuthorizedButtonProps extends ButtonProps {
    allowedRoles: number[];
    allowedUsers: number[];
    blockedUsers: number[];
    allowAll?: boolean;
}
export const AuthorizedButton: React.FC<IAuthorizedButtonProps> = ({ allowedRoles, allowAll, allowedUsers, blockedUsers, ...restProps }) => {
    const { isAdmin, user, roleIds } = useAuth();
    if (allowAll) {
        return <Button {...restProps} />;
    }
    const isExcluded = blockedUsers.indexOf(user?.id || -1) >= 0 && !isAdmin;
    const hasRole = intersection(allowedRoles, roleIds).length > 0 || isAdmin;
    const isUser = allowedUsers.indexOf(user?.id || -1) >= 0;
    if (isExcluded && !allowAll) return null;
    if (!hasRole && !isUser && !allowAll) return null;
    return <Button {...restProps} />;
};
