import React from 'react';

import { IDocument } from 'src/api/models/Document';
import { loadDocumentItems } from 'src/api/posts';
import useDebounce from 'src/hooks/useDebounce';
import { Autocomplete, TextField } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

interface PostDocumentPickerPickerProps {
    onDocumentSelected: (option: IDocument) => void;
    jobId: number;
}

export const PostDocumentPicker: React.FC<PostDocumentPickerPickerProps> = ({ jobId, onDocumentSelected }) => {
    const [loading, setLoading] = React.useState(false);
    const [query, setQuery] = React.useState('');
    const debouncedTitle = useDebounce(query, 300);
    const [options, setOptions] = React.useState<IDocument[]>([]);
    React.useEffect(() => {
        loadOptions(debouncedTitle);
    }, [debouncedTitle]);

    const loadOptions = async (q: string) => {
        setLoading(true);
        const options = await loadDocumentItems(jobId, q);
        setOptions(options);
        setLoading(false);
    };

    return (
        <div style={{ height: 215, padding: 15 }}>
            <div className="post-link-selector">
                <div className="document-selector-container">
                    <Autocomplete
                        size="small"
                        disablePortal={true}
                        //renderGroup={(group) => <div className="text-left">{group.group}</div>}
                        // groupBy={(option) => option.resource}
                        // PopperComponent={(props) => <Popper {...props} placement="bottom" />}
                        noOptionsText="Type to find documents to link..."
                        options={options}
                        getOptionLabel={(option) => option.name}
                        renderOption={(props, option) => {
                            return (
                                <li {...props} key={option.id}>
                                    <div style={{ width: '385px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', textAlign: 'left' }}>{option.name}</div>
                                </li>
                            );
                        }}
                        autoHighlight
                        value={null}
                        defaultValue={null}
                        onChange={(event, newValue: any) => onDocumentSelected(newValue)}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                placeholder="Search for documents to link..."
                                value={query}
                                onChange={(e) => setQuery(e.target.value)}
                                InputProps={{
                                    ...params.InputProps,
                                    onBlur: () => setQuery(''),
                                    endAdornment: (
                                        <React.Fragment>
                                            {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                            {params.InputProps.endAdornment}
                                        </React.Fragment>
                                    ),
                                }}
                            />
                        )}
                    />
                </div>
            </div>
        </div>
    );
};
