import React from 'react';
import moment from 'moment';
import { Card, Label, Button } from '@blueprintjs/core';
import { Row, Col } from 'react-grid-system';
import { useLocation, Link } from 'react-router-dom';
import { WorkflowSelector } from 'src/components/Selectors/WorkflowSelector';
import { IWorkflowResponse } from 'src/api/models/Workflow';
import { useJobs } from 'src/hooks/useJobs';
import { updateJobWorkflow } from 'src/api/jobs';
import { FiretrolTable } from '../../../components/FiretrolTable';
import { loadWorkflowOptions } from '../../../api/workflows';
import { AppToast } from '../../../components/Toasts/AppToast';
import { Header2 } from '../../../components/Header2';

const getWorkflowColumns = (pathName: string, jobId?: number, selectedId?: number) => {
    return [
        {
            name: 'Name',
            selector: (workflow: IWorkflowResponse) => workflow.name,
            format: (workflow: IWorkflowResponse) => <Link to={`${pathName}/${workflow.id}`}>{workflow.name} </Link>,
            sortable: true,
            width: `200px`,
        },
        {
            name: 'Module',
            selector: (workflow: IWorkflowResponse) => workflow.resource_id.toUpperCase(),
            sortable: true,
            // width: '100px',
        },
        {
            name: 'Last Modified By',
            selector: (workflow: IWorkflowResponse) => `${workflow.last_updated_by ? workflow.last_updated_by.full_name : ''} (${moment(workflow.updated_at).format('MM/DD/YYYY')})`,
            sortable: true,
            width: '200px',
        },
        {
            name: 'Created By',
            selector: (workflow: IWorkflowResponse) => `${workflow.created_by ? workflow.created_by.full_name : ''} (${moment(workflow.created_at).format('MM/DD/YYYY')})`,
            // width: '200px',
            sortable: true,
        },
        {
            name: 'Approvals',
            selector: (workflow: IWorkflowResponse) => (workflow.steps.find((step) => step.approval_required) ? 'Yes' : 'No'),
            sortable: true,
        },
        {
            name: 'Template',
            selector: (workflow: IWorkflowResponse) => (workflow.job_id === jobId ? 'No' : 'Yes'),
            sortable: true,
        },
        {
            name: 'Default',
            selector: (workflow: IWorkflowResponse) => (workflow.id === selectedId ? 'Yes' : 'No'),
            sortable: true,
        },
        {
            name: 'Finish',
            selector: (workflow: IWorkflowResponse) => {
                const last = workflow.steps.find((step) => step.is_last_step);
                return last ? last.label : '';
            },
            sortable: true,
        },
    ];
};

export const Workflows: React.FC = () => {
    const location = useLocation();
    const { selectedJob, setSelectedJob, rehydrateJob } = useJobs();
    const [rfiWorkflow, setRFIWorkflow] = React.useState<IWorkflowResponse | null>(null);
    const [submittalWorkflow, setSubmittalWorkflow] = React.useState<IWorkflowResponse | null>(null);
    const [drawingWorkflow, setDrawingWorkflow] = React.useState<IWorkflowResponse | null>(null);
    const [saving, setSaving] = React.useState(false);
    const [canSaveRFI, setCanSaveRFI] = React.useState(false);
    const [canSaveSubmittal, setCanSaveSubmittal] = React.useState(false);
    const [canSaveDrawing, setCanSaveDrawing] = React.useState(false);
    const [workflows, setWorkflows] = React.useState<IWorkflowResponse[]>([]);
    const workflowColumns = getWorkflowColumns(location.pathname, selectedJob?.id, selectedJob?.rfi_workflow_id);
    React.useEffect(() => {
        loadWorkflows();
        rehydrateJob();
    }, []);

    const loadWorkflows = async () => {
        if (!selectedJob) return;
        const workflows = await loadWorkflowOptions('all', selectedJob.id);
        setWorkflows(workflows);
    };

    React.useEffect(() => {
        if (!selectedJob) return;
        setRFIWorkflow(selectedJob.rfi_workflow);
        setSubmittalWorkflow(selectedJob.submittal_workflow);
        setDrawingWorkflow(selectedJob.drawing_workflow);
    }, [selectedJob]);

    const updateRFIWorkflow = (value: IWorkflowResponse) => {
        if (!selectedJob) return;
        setRFIWorkflow(value);
        setCanSaveRFI(value.id !== selectedJob.rfi_workflow_id);
    };

    const updateSubmittalWorkflow = (value: IWorkflowResponse) => {
        if (!selectedJob) return;
        setSubmittalWorkflow(value);
        setCanSaveSubmittal(value.id !== selectedJob.submittal_workflow_id);
    };

    const updateDrawingWorkflow = (value: IWorkflowResponse) => {
        if (!selectedJob) return;
        setDrawingWorkflow(value);
        setCanSaveDrawing(value.id !== selectedJob.drawing_workflow_id);
    };

    const saveWorkflowSettings = async () => {
        if (!selectedJob || (!rfiWorkflow && !submittalWorkflow && !drawingWorkflow)) return;
        setSaving(true);
        const updatedJob = await updateJobWorkflow(selectedJob.id, {
            rfi_workflow_id: rfiWorkflow?.id || null,
            submittal_workflow_id: submittalWorkflow?.id || null,
            drawing_workflow_id: drawingWorkflow?.id || null,
        });
        AppToast.show({ message: `Workflow Settings Updated!`, intent: 'success', icon: 'tick' });
        setSelectedJob(updatedJob);
        setCanSaveRFI(false);
        setCanSaveSubmittal(false);
        setCanSaveDrawing(false);
        await loadWorkflows();
        setSaving(false);
    };

    return (
        <>
            <Header2
                breadcrumbs={[
                    { title: 'Jobs', link: '/jobs' },
                    { title: `${selectedJob?.name} - ${selectedJob?.job_number}`, link: `/jobs/${selectedJob?.id}/summary` },
                    { title: 'Workflows', link: '' },
                ]}
            />
            <Row className="m-b-20">
                <Col xs={12} sm={12} md={12}>
                    <Card>
                        <h3 className="m-t-0">Workflow Settings</h3>
                        <Row>
                            <Col xs={12} sm={6} md={4}>
                                <Label>RFI Workflow</Label>
                                <WorkflowSelector options={workflows} resourceId="rfi" value={rfiWorkflow} onChange={(value) => updateRFIWorkflow(value)} />
                            </Col>
                            <Col xs={12} sm={6} md={4}>
                                <Label>Submittal Workflow</Label>
                                <WorkflowSelector options={workflows} resourceId="submittal" value={submittalWorkflow} onChange={(value) => updateSubmittalWorkflow(value)} />
                            </Col>
                            <Col xs={12} sm={6} md={4}>
                                <Label>Drawing Workflow</Label>
                                <WorkflowSelector options={workflows} resourceId="drawing" value={drawingWorkflow} onChange={(value) => updateDrawingWorkflow(value)} />
                            </Col>
                        </Row>
                        <Row className="m-t-20 text-right">
                            <Col>
                                <Button disabled={!canSaveRFI && !canSaveSubmittal && !canSaveDrawing} intent="primary" loading={saving} onClick={saveWorkflowSettings}>
                                    Save Settings
                                </Button>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>

            <FiretrolTable columns={workflowColumns} data={workflows} />
        </>
    );
};
