import React from 'react';
import moment from 'moment';
import { Tag, Card } from '@blueprintjs/core';
import { IHistory, IHistoryField } from 'src/api/models/History';
interface IHistoryProps {
    history: IHistory[];
}

export const History: React.FC<IHistoryProps> = ({ history }) => {
    const renderFields = (fields: IHistoryField[]) => {
        return fields.map((field) => {
            return (
                <Tag className="m-l-5" minimal key={field.field}>
                    {field.label}
                </Tag>
            );
        });
    };
    const renderItems = () => {
        return history.map((hs) => {
            return (
                <Card className="m-b-10" key={hs.id}>
                    <p className="m-0">
                        {moment(hs.created_at).format('MMM Do YYYY')} at {moment(hs.created_at).format('h:mma')}: {hs.person} {hs.status_change ? 'changed status to ' : ' '}
                        <b>{hs.action.toUpperCase()}</b> {hs.resource_title}.
                    </p>
                    {hs.fields.length > 0 && (
                        <div className="m-t-10">
                            <b>Updated Fields: </b>
                            {renderFields(hs.fields)}
                        </div>
                    )}
                </Card>
            );
        });
    };
    return <div className="m-t-20">{renderItems()}</div>;
};
