import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Icon, IconName } from '@blueprintjs/core';
import { SideNavigation, GoBackItem, Section, NestableNavigationContent, ButtonItem, Footer, NavigationFooter } from '@atlaskit/side-navigation';

interface INavigationItem {
    id: string;
    title: string;
    path: string;
    icon: IconName;
    subs?: INavigationItem[];
}

interface ISubMenuProps {
    items: INavigationItem[];
    actions?: any;
    buttonCssFn: (props: any) => any;
    computePath: (navItem: INavigationItem) => string;
    goBackText: string;
    goBackPath: string;
    goBackPop?: boolean;
}

export const SubMenu: React.FC<ISubMenuProps> = ({ items, buttonCssFn, computePath, goBackText, goBackPath, goBackPop = false, actions = <></> }) => {
    const history = useHistory();
    const location = useLocation();

    const renderItems = () => {
        return items.map((item) => {
            if (!item.subs) {
                const isSelected = location.pathname.includes(item.path);
                const ttPath = computePath(item);
                return (
                    <Section hasSeparator key={item.id}>
                        <ButtonItem iconBefore={<Icon icon={item.icon} />} onClick={() => history.push(`${ttPath}`)} isSelected={isSelected} cssFn={buttonCssFn}>
                            {item.title}
                        </ButtonItem>
                    </Section>
                );
            }

            return (
                <Section key={item.id} hasSeparator title={item.title}>
                    {item.subs.map((sub) => {
                        const ttPath = computePath(sub); //sub.path;
                        const isSelected = location.pathname.includes(sub.path);
                        return (
                            <ButtonItem key={sub.id} iconBefore={<Icon icon={sub.icon} />} onClick={() => history.push(`${ttPath}`)} isSelected={isSelected} cssFn={buttonCssFn}>
                                {sub.title}
                            </ButtonItem>
                        );
                    })}
                </Section>
            );
        });
    };

    return (
        <SideNavigation label="project">
            <NestableNavigationContent initialStack={[]}>
                <Section hasSeparator>
                    <GoBackItem onClick={() => (goBackPop ? history.goBack() : history.push(goBackPath))}>{goBackText}</GoBackItem>
                </Section>
                <Section>{renderItems()}</Section>
                {actions}
            </NestableNavigationContent>
            <NavigationFooter>
                <Footer cssFn={buttonCssFn}>Firetrol Protection Systems</Footer>
            </NavigationFooter>
        </SideNavigation>
    );
};
