import React from 'react';
import { MenuItem, TagProps } from '@blueprintjs/core';
import { ItemRenderer, MultiSelect, ItemPredicate } from '@blueprintjs/select';
import { IOrganizationOffice } from 'src/api/models/Organization';
import { useOrganization } from 'src/hooks/useOrganization';

const OfficeSelect = MultiSelect.ofType<IOrganizationOffice>();

interface IOfficeSelectorProps {
    onChange: (offices: IOrganizationOffice[]) => void;
    initialValue?: IOrganizationOffice[];
}

export const OfficeSelector: React.FC<IOfficeSelectorProps> = ({ onChange, initialValue = [] }) => {
    const { offices } = useOrganization();
    const [selected, setSelected] = React.useState<IOrganizationOffice[]>(initialValue);

    React.useEffect(() => {
        onChange(selected);
    }, [selected]);

    const onValueChange = (value: IOrganizationOffice) => {
        const isSelected = isValueSelected(value);
        if (!isSelected) {
            setSelected((prev) => [...prev, value]);
        } else {
            deselectValue(value);
        }
    };

    const deselectValue = (value: IOrganizationOffice) => {
        setSelected((prev) => prev.filter((p) => p.id !== value.id));
    };

    const getTagProps = (_value: React.ReactNode): TagProps => ({
        intent: 'none',
        minimal: true,
    });

    const isValueSelected = (value: IOrganizationOffice) => {
        return !!selected.find((s) => s.id === value.id);
    };

    const filterData: ItemPredicate<IOrganizationOffice> = (query, role, _index, exactMatch) => {
        const normalizedTitle = role.name.toLowerCase();
        const normalizedQuery = query.toLowerCase();
        if (exactMatch) {
            return normalizedTitle === normalizedQuery;
        } else {
            return `${role.name}. ${normalizedTitle}`.indexOf(normalizedQuery) >= 0;
        }
    };

    const renderTag = (role: IOrganizationOffice) => role.name;

    const renderItem: ItemRenderer<IOrganizationOffice> = (role, { modifiers, handleClick }) => {
        if (!modifiers.matchesPredicate) {
            return null;
        }
        return <MenuItem icon={isValueSelected(role) ? 'tick' : 'blank'} key={role.id} onClick={handleClick} text={role.name} />;
    };

    return (
        <OfficeSelect
            placeholder="Select offices..."
            selectedItems={selected}
            itemPredicate={filterData}
            //itemListRenderer={itemListRenderer}
            fill
            tagRenderer={renderTag}
            tagInputProps={{
                tagProps: getTagProps,
            }}
            itemRenderer={renderItem}
            items={offices}
            noResults={<MenuItem disabled={true} text="No results." />}
            onItemSelect={onValueChange}
            onRemove={deselectValue}
        />
    );
};
