import React from 'react';
import { RoleBasedRoute } from 'src/components/RoleBasedRoute';
import { Redirect } from 'react-router-dom';
import { Drawings } from './Drawings';
import { Submittals } from './Submittals';
import { Misc } from './Misc';

interface IDocumentRoutes {
    requiredScopes: string[];
}
export const DocumentRoutes: React.FC<IDocumentRoutes> = ({ requiredScopes }) => {
    return (
        <>
            <RoleBasedRoute requiredScopes={requiredScopes} exact path="/documents" render={() => <Redirect to="/documents/drawings" />} />
            <RoleBasedRoute requiredScopes={requiredScopes} exact path="/documents/drawings" render={() => <Drawings />} />
            <RoleBasedRoute requiredScopes={requiredScopes} exact path="/documents/submittals" render={() => <Submittals />} />
            <RoleBasedRoute requiredScopes={requiredScopes} exact path="/documents/misc" render={() => <Misc />} />
        </>
    );
};
