import React from 'react';
import { Row, Col } from 'react-grid-system';
import Stepper from '@mui/material/Stepper';
import { useHistory } from 'react-router-dom';
import Step from '@mui/material/Step';
import { Button, Card, NonIdealState } from '@blueprintjs/core';
import StepButton from '@mui/material/StepButton';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useJobs } from 'src/hooks/useJobs';
import { AppToast } from '../../../../../components/Toasts/AppToast';
import { JobUserSelect } from 'src/components/Selectors/JobUserSelect';
import { ITeamUser } from 'src/api/models/Job';
import { TimesheetCode } from 'src/api/models/timesheet';
import { ChargeCodeSelector } from '../ChangeCodeSelector';
import { TimesheetCard } from '../TimesheetCard';
import { createTimesheetDraft } from '../../../../../api/timesheets';
import { IDateOption } from '../../types';
import { currentWeekFilter } from '../WeekSelector';

interface TimesheetFormValues {
    users: ITeamUser[];
    codes: TimesheetCode[];
    timesheets: any[];
    start_date: IDateOption;
}

const validationSchema = Yup.object().shape({
    users: Yup.array().required().min(1, 'Users Required').label('Users'),
});

const initialValues: TimesheetFormValues = {
    users: [],
    codes: [],
    timesheets: [],
    start_date: currentWeekFilter,
};

export const TimesheetForm: React.FC = () => {
    const { selectedJob } = useJobs();
    const history = useHistory();
    const [activeStep, setActiveStep] = React.useState(0);
    const formik = useFormik({
        initialValues,
        validationSchema,
        validateOnMount: true,
        onSubmit: async (values, { setSubmitting }) => {
            if (!selectedJob) return;
            setSubmitting(true);
            try {
                await createTimesheetDraft(selectedJob.id, values);
                AppToast.show({ message: `Timesheets Draft Created!`, intent: 'success', icon: 'tick' });
                history.push(`/jobs/${selectedJob.id}/timesheets`);
            } catch (err) {
                AppToast.show({ message: `Error: ${err.toLocaleString()}`, intent: 'danger', icon: 'tick' });
            }
            // if (!changeId) {
            //     const result = await createChangeOrder(selectedJob.id, values);
            //     AppToast.show({ message: `Change Request created!`, intent: 'success', icon: 'tick' });
            //     history.push(`/jobs/${selectedJob.id}/changes/view/${result.id}`);
            // } else {
            //     await updateChangeOrder(selectedJob.id, changeId, values);
            //     AppToast.show({ message: `Change Request updated!`, intent: 'success', icon: 'tick' });
            // }
            setSubmitting(false);
        },
    });

    const { values, setFieldValue } = formik;

    const handleStep = (step: number) => {
        setActiveStep(step);
    };

    const onTimesheetChange = (userId: number, rows: any[]) => {
        const exists = values.timesheets.find((item) => item.userId === userId);
        const ts = { userId, rows };
        if (exists) {
            const items = values.timesheets.map((item) => {
                if (item.userId === userId) {
                    return ts;
                }
                return item;
            });
            setFieldValue('timesheets', items);
        } else {
            setFieldValue('timesheets', [...values.timesheets, ts]);
        }
    };
    const peopleValid = values.users.length > 0;
    const codesValid = values.codes.length > 0;

    const disableNext = (activeStep === 0 && !peopleValid) || (activeStep === 1 && (!codesValid || !peopleValid));
    if (!selectedJob) return null;
    return (
        <>
            <Stepper nonLinear activeStep={activeStep}>
                <Step completed={peopleValid}>
                    <StepButton onClick={() => handleStep(0)}>Select People</StepButton>
                </Step>
                <Step completed={codesValid}>
                    <StepButton disabled={!peopleValid} onClick={() => handleStep(1)}>
                        Select Cost Codes
                    </StepButton>
                </Step>
                <Step completed={activeStep === 3}>
                    <StepButton disabled={!codesValid || !peopleValid} onClick={() => handleStep(2)}>
                        Complete Timesheets
                    </StepButton>
                </Step>
                <Step>
                    <StepButton disabled={!codesValid || !peopleValid} onClick={() => handleStep(3)}>
                        Review & Save
                    </StepButton>
                </Step>
            </Stepper>

            {activeStep !== 2 && (
                <Card className="m-t-30">
                    {activeStep === 0 && (
                        <Row>
                            <Col>
                                <h3 className="m-t-0">Select People</h3>
                                <JobUserSelect
                                    hideLabel
                                    value={values.users}
                                    onChange={(users) => {
                                        if (users) {
                                            setFieldValue('users', users);
                                        } else {
                                            setFieldValue('users', []);
                                        }
                                    }}
                                />
                            </Col>
                        </Row>
                    )}
                    {activeStep === 1 && (
                        <Row>
                            <Col>
                                <ChargeCodeSelector onChange={(codes) => setFieldValue('codes', codes)} initialValues={values.codes} />
                            </Col>
                        </Row>
                    )}
                    {activeStep === 3 && (
                        <Row>
                            <Col>
                                <NonIdealState title="Review and save timesheet drafts" icon="search-template">
                                    <p>Start Date: {values.start_date.label}</p>
                                    <p className="m-b-5">{values.users.length} Timesheets for:</p>
                                    {values.users.map((user) => {
                                        return (
                                            <p key={user.user_id} className="m-b-5">
                                                {user.user.full_name}
                                            </p>
                                        );
                                    })}
                                    <Button className="m-r-10 m-t-10" onClick={() => setActiveStep((prevActiveStep) => prevActiveStep - 1)}>
                                        Back
                                    </Button>
                                    <Button intent="primary" onClick={() => formik.handleSubmit()} loading={formik.isSubmitting}>
                                        Save Timesheets
                                    </Button>
                                </NonIdealState>
                            </Col>
                        </Row>
                    )}
                </Card>
            )}

            {activeStep === 2 && (
                <Row className="m-t-30">
                    <Col>
                        <TimesheetCard
                            startDate={values.start_date}
                            onStartChange={(startDate) => setFieldValue('start_date', startDate)}
                            costCodes={values.codes}
                            people={values.users}
                            onChange={onTimesheetChange}
                        />
                    </Col>
                </Row>
            )}
            {activeStep !== 3 && (
                <Row>
                    <Col className="text-center m-t-20">
                        <Card>
                            {activeStep !== 0 && (
                                <Button className="m-r-10" onClick={() => setActiveStep((prevActiveStep) => prevActiveStep - 1)}>
                                    Back
                                </Button>
                            )}
                            <Button disabled={disableNext} intent="primary" onClick={() => setActiveStep((prevActiveStep) => prevActiveStep + 1)}>
                                Next
                            </Button>
                        </Card>
                    </Col>
                </Row>
            )}
        </>
    );
};
