import { getAuthOptions } from './helpers';
import AppApi from './index';
import { Timesheet, TimesheetDraft } from './models/timesheet';

const api = AppApi.getApi();

export const listTimesheets = async (jobId: number): Promise<Timesheet[]> => {
    const url = `/jobs/${jobId}/timesheets`;
    const { data } = await api.get(url, await getAuthOptions());
    return data;
};

export const listTimesheetDrafts = async (jobId: number): Promise<TimesheetDraft[]> => {
    const url = `/jobs/${jobId}/timesheets/drafts`;
    const { data } = await api.get(url, await getAuthOptions());
    return data;
};

export const createTimesheetDraft = async (jobId: number, data: any): Promise<TimesheetDraft> => {
    const url = `/jobs/${jobId}/timesheets/drafts`;
    const result = await api.post(url, data, await getAuthOptions());
    return result.data;
};

export const updateTimesheetDraft = async (jobId: number, draftId: string, data: any): Promise<TimesheetDraft> => {
    const url = `/jobs/${jobId}/timesheets/drafts/${draftId}`;
    const result = await api.put(url, data, await getAuthOptions());
    return result.data;
};

export const updateTimesheet = async (jobId: number, timesheetId: string, data: any): Promise<Timesheet> => {
    const url = `/jobs/${jobId}/timesheets/timesheet/${timesheetId}`;
    const result = await api.put(url, data, await getAuthOptions());
    return result.data;
};

export const submitDraftForReview = async (jobId: number, draftId: string): Promise<TimesheetDraft> => {
    const url = `/jobs/${jobId}/timesheets/drafts/${draftId}/submit`;
    const result = await api.post(url, {}, await getAuthOptions());
    return result.data;
};

export const getTimesheetDraftById = async (jobId: number, draftId: string): Promise<TimesheetDraft> => {
    const url = `/jobs/${jobId}/timesheets/drafts/${draftId}`;
    const { data } = await api.get(url, await getAuthOptions());
    return data;
};

export const getTimesheetById = async (jobId: number, timesheetId: string): Promise<Timesheet> => {
    const url = `/jobs/${jobId}/timesheets/timesheet/${timesheetId}`;
    const { data } = await api.get(url, await getAuthOptions());
    return data;
};
