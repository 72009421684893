import React, { Suspense } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { Login } from './Auth/Login';
import { Navigation } from '../components/Navigation';
import { Sidebar } from '../components/Sidebar';
import { CTC } from './CTC';
import { ViewCTCJob } from './CTC/ViewCTCJob';
import { Jobs } from './Jobs';
import { RFIs } from './RFIs';
import { Changes } from './Changes';
import { Timesheets } from './Timesheets';
import { PunchList } from './PunchList';
import { Installs } from './Installs';
import { Operations } from './Operations';
import { Materials } from './Materials';
import { Tasks } from './Tasks';
import { Dialogs } from 'src/components/Dialogs';
import { JobRouter } from './Jobs/JobRouter';
import { Logout } from './Auth/Logout';
import { AccountProfile } from './Auth/AccountProfile';
import { Permissions } from './Auth/AccountProfile/Permissions';
import { RoleBasedRoute } from 'src/components/RoleBasedRoute';
import { ResetPassword } from './Auth/ResetPassword';
import { DocumentRoutes } from './Documents';
import { SettingsRoutes } from './Settings';
import { Analysis as MainAnalysis } from './Analysis';
import { JobsProvider } from 'src/hooks/useJobs';
import { UsersProvider } from 'src/hooks/useUsers';
import { ExternalRFI } from 'src/routes/External/ExternalRFI';
import { Summary } from './Summary';
import { ForgotPassword } from 'src/routes/Auth/ForgotPassword';
import { UpdatePassword } from 'src/routes/Auth/ForgotPassword/UpdatePassword';
export const AuthRouter: React.FC = () => {
    return (
        <Switch>
            <Route exact path="/login">
                <Login />
            </Route>
            <Route exact path="/forgotPassword">
                <ForgotPassword />
            </Route>
            <Route exact path="/passwordReset/:token">
                <UpdatePassword />
            </Route>
            <Route exact path="/external/rfi/:id" render={() => <ExternalRFI />} />
            <Route render={() => <Redirect to="/login" />} />
        </Switch>
    );
};
export const ForceResetRouter: React.FC = () => {
    return (
        <Switch>
            <Route exact path="/resetPassword">
                <ResetPassword />
            </Route>
            <Route render={() => <Redirect to="/resetPassword" />} />
        </Switch>
    );
};

const ExternalRouter: React.FC = () => {
    return (
        <Switch>
            <Route exact path="/external/rfi/:id" render={() => <ExternalRFI />} />
        </Switch>
    );
};

export const AppRouter: React.FC = () => {
    const location = useLocation();
    if (location.pathname.includes('/external/')) {
        return <ExternalRouter />;
    }
    const isDocuments = location.pathname.includes('/documents') && location.pathname.includes('/jobs/');
    const isCTCView = location.pathname.includes('/ctc/view/');
    return (
        <>
            <Switch>
                <>
                    <JobsProvider>
                        <Navigation />
                        <div className="parent">
                            {!isCTCView && (
                                <div className="sidebar">
                                    <Sidebar />
                                </div>
                            )}

                            <UsersProvider>
                                <div className={`not-sidebar ${isDocuments ? 'isDocuments' : ''}`}>
                                    <Suspense fallback={<div />}>
                                        <Route exact path="/" render={() => <Redirect to="/ctc" />} />
                                        <Route exact path="/login" render={() => <Redirect to="/ctc" />} />
                                        <Route exact path="/dashboard" render={() => <Redirect to="/ctc" />} />
                                        <Route exact path="/passwordReset/:token" render={() => <Redirect to="/ctc" />} />
                                        <Route exact path="/resetPassword" render={() => <Redirect to="/ctc" />} />
                                        <RoleBasedRoute
                                            requiredScopes={['Admin:write:global', 'Admin:read:global', 'CTC:read:global', 'CTC:restricted:offices']}
                                            exact
                                            path="/ctc"
                                            render={() => <CTC />}
                                        />
                                        <RoleBasedRoute
                                            requiredScopes={['Admin:write:global', 'Admin:read:global', 'CTC:read:global', 'CTC:restricted:offices']}
                                            exact
                                            path="/ctc/view/:id"
                                            render={() => <ViewCTCJob />}
                                        />
                                        <RoleBasedRoute requiredScopes={['Admin:write:global', 'Admin:read:global']} exact path="/analysis" render={() => <MainAnalysis />} />
                                        <SettingsRoutes requiredScopes={['Admin:write:global', 'Admin:read:global', 'Settings:read:global', 'Settings:write:global']} />
                                        <DocumentRoutes
                                            requiredScopes={[
                                                'Admin:write:global',
                                                'Admin:read:global',
                                                'Documents:read:global',
                                                'Documents:write:global',
                                                'Documents:read:jobs',
                                                'Documents:write:jobs',
                                            ]}
                                        />
                                        <Route path="/jobs/:id" render={() => <JobRouter requiredScopes={[]} />} />
                                        <RoleBasedRoute
                                            requiredScopes={[
                                                'Admin:write:global',
                                                'Admin:read:global',
                                                'Materials:read:global',
                                                'Materials:write:global',
                                                'Dailys:read:global',
                                                'Dailys:write:global',
                                                'Installs:read:global',
                                                'Installs:write:global',
                                                'Timesheets:read:global',
                                                'Timesheets:write:global',
                                                'Plans:read:global',
                                                'Plans:write:global',
                                                'Analysis:read:global',
                                                'Analysis:write:global',
                                                'Pieces:read:global',
                                                'Pieces:write:global',
                                            ]}
                                            exact
                                            path="/operations/summary"
                                            render={() => <Operations />}
                                        />

                                        <RoleBasedRoute
                                            requiredScopes={[
                                                'Admin:write:global',
                                                'Admin:read:global',
                                                'Materials:read:global',
                                                'Materials:write:global',
                                                'Materials:read:jobs',
                                                'Materials:write:jobs',
                                            ]}
                                            exact
                                            path="/materials"
                                            render={() => <Materials />}
                                        />
                                        <RoleBasedRoute
                                            requiredScopes={['Admin:write:global', 'Admin:read:global', 'Tasks:read:global', 'Tasks:write:global', 'Tasks:read:jobs', 'Tasks:write:jobs']}
                                            exact
                                            path="/tasks"
                                            render={() => <Tasks />}
                                        />
                                        <RoleBasedRoute allowAll requiredScopes={['All Users']} exact path="/jobs" render={() => <Jobs />} />
                                        <RoleBasedRoute allowAll requiredScopes={['All Users']} exact path="/rfis" render={() => <RFIs />} />
                                        <RoleBasedRoute allowAll requiredScopes={['All Users']} exact path="/installs" render={() => <Installs />} />
                                        <RoleBasedRoute allowAll requiredScopes={['All Users']} exact path="/punchlists" render={() => <PunchList />} />

                                        <RoleBasedRoute allowAll requiredScopes={['All Users']} exact path="/summary" render={() => <Summary />} />
                                        <RoleBasedRoute allowAll requiredScopes={['All Users']} exact path="/timesheets" render={() => <Timesheets />} />
                                        <RoleBasedRoute allowAll requiredScopes={['All Users']} exact path="/changes" render={() => <Changes />} />
                                        <RoleBasedRoute allowAll requiredScopes={['All Users']} exact path="/operations" render={() => <Redirect to="/operations/summary" />} />

                                        <Route exact path="/logout" render={() => <Logout />} />
                                        <Route exact path="/account/profile" render={() => <AccountProfile />} />
                                        <Route exact path="/account/permissions" render={() => <Permissions />} />
                                    </Suspense>
                                </div>
                            </UsersProvider>

                            <Dialogs />
                        </div>
                    </JobsProvider>
                </>
            </Switch>
        </>
    );
};
