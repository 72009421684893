import React from 'react';
import { Button, Callout, Spinner } from '@blueprintjs/core';
import { Row, Col } from 'react-grid-system';
import { Header } from 'src/components/Header';
import { IOrganizationClient } from 'src/api/models/Organization';
import { FiretrolTable } from 'src/components/FiretrolTable';
import { useDialogs } from 'src/hooks/useDialogs';
import { useOrganization } from 'src/hooks/useOrganization';

const getColumns = (setCreateClientDialogOpen: (isOpen: boolean, client?: IOrganizationClient) => void) => {
    return [
        {
            name: 'Name',
            selector: (client: IOrganizationClient) => client.name,
            sortable: true,
        },

        {
            name: '',
            format: (client: IOrganizationClient) => <Button text="Edit" small minimal intent="primary" onClick={() => setCreateClientDialogOpen(true, client)} />,
            selector: (client: IOrganizationClient) => client.id,
        },
    ];
};

export const Clients: React.FC = () => {
    const { setCreateClientDialogOpen } = useDialogs();
    const { clients, loading } = useOrganization();
    const [error] = React.useState<string | null>(null);
    const columns = React.useMemo(() => getColumns(setCreateClientDialogOpen), [setCreateClientDialogOpen]);
    return (
        <>
            <Header
                title="Clients"
                breadcrumbs={['Settings', 'Clients']}
                actionComponent={<Button text="Add Client" onClick={() => setCreateClientDialogOpen(true)} intent="primary" icon="new-person" />}
            />
            {error && (
                <Row className="m-b-15">
                    <Col>
                        <Callout intent="danger" title="Error Loading Users">
                            Error doing something
                        </Callout>
                    </Col>
                </Row>
            )}
            <Row className="m-b-15">
                <Col>{loading ? <Spinner /> : <FiretrolTable columns={columns} data={clients} />}</Col>
            </Row>
        </>
    );
};
