import React from 'react';
import { Row, Col } from 'react-grid-system';
import { IDailyQuestionResponse } from '../../../../../api/models/Daily';

import { QuestionBox } from './QuestionBox';
interface SummaryProps {
    jobId: number;
    initialQuestions: any;
    onChange: (q: any) => void;
    buttons: React.ReactElement;
}
export const Summary: React.FC<SummaryProps> = ({ initialQuestions, onChange, buttons }) => {
    const [answers, setAnswers] = React.useState<IDailyQuestionResponse[]>(initialQuestions);

    React.useEffect(() => {
        onChange(answers);
    }, [answers]);
    const updateNote = (id: number, note: string) => {
        setAnswers((prevState) => {
            return prevState.map((v) => {
                if (v.id === id)
                    return {
                        ...v,
                        note,
                    };
                return v;
            });
        });
    };
    const updateValue = (id: number, value: string) => {
        setAnswers((prevState) => {
            return prevState.map((v) => {
                if (v.id === id)
                    return {
                        ...v,
                        value,
                    };
                return v;
            });
        });
    };
    const renderQuestions = () => {
        if (answers.length === 0) {
            return <Col className="text-center">No questions to display</Col>;
        }
        return answers.map((q) => {
            return <QuestionBox key={q.id} question={q} onValueChange={updateValue} onNoteChange={updateNote} />;
        });
    };

    return (
        <>
            <Row>
                <Col>
                    <Row>{renderQuestions()}</Row>
                </Col>
            </Row>
            <>{buttons}</>
        </>
    );
};
