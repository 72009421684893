import React from 'react';
import { Row, Col, Container } from 'react-grid-system';
import { useHistory } from 'react-router-dom';
import { Button, Intent } from '@blueprintjs/core';
import { nanoid } from 'nanoid';
import { WorkflowStep } from './WorkflowStep';
import { IWorkflowStep, IWorkflowSettings, IWorkflowResponse } from 'src/api/models/Workflow';
import { saveWorkflow } from 'src/api/workflows';
import { updateWorkflowTemplate } from 'src/api/admin';
import { WorkflowDivider } from './WorkflowDivider';
import { WorkflowSettings } from './WorkflowSettings';
import { WorkflowPreview } from './WorkflowPreview';
import { useJobs } from 'src/hooks/useJobs';
import './index.scss';
import { AppToast } from '../../Toasts/AppToast';

const initialData = {
    excluded_assigned_users: [],
    assigned_roles: [],
    assigned_users: [],
    notify_roles: [],
    notify_users: [],
    excluded_notify_users: [],
    approval_users: [],
    approval_roles: [],
    excluded_approval_users: [],
    require_signature: false,
    notify_assignees: true,
    allow_draft: true,
    allow_reject: false,
    allow_void: false,
    button_color: Intent.PRIMARY,
    status_color: Intent.PRIMARY,
    approval_required: false,
    signature_required: false,
};

interface ICreateWorkflowProps {
    initialSettings: IWorkflowSettings;
    initialSteps: IWorkflowStep[];
    isTemplateEditor?: boolean;
    onSaveComplete?: (workflow?: IWorkflowResponse) => void;
}

export const CreateWorkflow: React.FC<ICreateWorkflowProps> = ({ initialSettings, onSaveComplete, initialSteps, isTemplateEditor = false }) => {
    const history = useHistory();
    const { selectedJob } = useJobs();
    const [settings, setSettings] = React.useState<IWorkflowSettings>(initialSettings);
    const [steps, setSteps] = React.useState<IWorkflowStep[]>(initialSteps);
    const [saving, setSaving] = React.useState(false);
    const [saveError, setSaveError] = React.useState<string | null>(null);
    const [previewOpen, setPreviewOpen] = React.useState(false);
    const onSettingsChanged = (key: string, value: any) => {
        //console.log('value: ', value);
        setSettings((prevState) => {
            return {
                ...prevState,
                [key]: value,
            };
        });
    };
    const onAddClick = (idx: number) => {
        const stepToAdd = {
            step_id: nanoid(),
            step_number: idx,
            editable: true,
            is_first_step: false,
            is_configurable: true,
            is_external_step: false,
            is_last_step: false,
            is_locked: false,
            is_dynamic_user_assignment: false,
            label: '<Status Name>',
            button_text: '<Button Text>',
            ...initialData,
        };
        const insert = [...steps.slice(0, idx), stepToAdd, ...steps.slice(idx)];
        setSteps(insert);
    };

    const onRemove = (step: IWorkflowStep) => {
        setSteps((prevState) => prevState.filter((s) => s.step_id !== step.step_id));
    };

    const onValueChange = (updated: IWorkflowStep) => {
        setSteps((els) => {
            return els.map((el) => {
                if (el.step_id === updated.step_id) {
                    return updated;
                }
                return el;
            });
        });
    };
    const saveTemplate = async () => {
        setSaving(true);
        setSaveError(null);
        try {
            const workflow = {
                ...settings,
                steps,
                statuses: steps.map((step) => step.label.toLowerCase()),
            };
            const result = await updateWorkflowTemplate(workflow);
            if (onSaveComplete) {
                onSaveComplete();
            }
            AppToast.show({ message: `Workflow Template ${initialSettings.id === -1 ? 'Created' : 'Updated'}!`, intent: 'success', icon: 'tick' });
            if (initialSettings.id === -1) {
                history.push(`/settings/workflows/${result.id}`);
            }
        } catch (err) {
            console.log('error: ', err);
            setSaveError(err.toLocaleString());
        }
        setSaving(false);
    };

    const onSaveClicked = async () => {
        if (isTemplateEditor) {
            return saveTemplate();
        }
        if (!selectedJob) return;
        setSaving(true);
        setSaveError(null);
        try {
            const workflow = {
                ...settings,
                steps,
                statuses: steps.map((step) => step.label.toLowerCase()),
            };
            const result = await saveWorkflow(selectedJob.id, workflow);
            AppToast.show({ message: `Workflow Updated!`, intent: 'success', icon: 'tick' });
            if (onSaveComplete) {
                onSaveComplete(result);
            }
            //console.log('result: ', saved);
        } catch (err) {
            console.log('error: ', err);
            setSaveError(err.toLocaleString());
        }
        setSaving(false);
    };

    const renderWorkflowSteps = () => {
        return steps.map((step, idx) => {
            return (
                <React.Fragment key={step.step_id}>
                    <WorkflowStep step={step} onValueChange={onValueChange} onStepRemoved={onRemove} isTemplateEditor={isTemplateEditor} />
                    {!step.is_last_step && <WorkflowDivider showButton={step.editable || step.is_first_step} onButtonClick={() => onAddClick(idx + 1)} />}
                </React.Fragment>
            );
        });
    };

    return (
        <>
            <hr className="m-b-0 m-l--20 m-r--20" />
            <div className="workflow-scroll-container">
                <Container fluid={false} className="workflow-container">
                    <Row>
                        <Col>
                            <WorkflowSettings settings={settings} onChange={onSettingsChanged} isTemplateEditor={isTemplateEditor} />
                            <hr className="m-b-25 m-t-25" />
                            {/*<WorkflowDivider showButton onButtonClick={() => onAddClick(0)} />*/}
                            {renderWorkflowSteps()}
                            <WorkflowPreview isOpen={previewOpen} setOpen={setPreviewOpen} steps={steps} settings={settings} />
                            {saveError && <p className="text-center text-danger">{saveError}</p>}
                            <div className="workflow-footer">
                                <Button onClick={() => setPreviewOpen(true)} minimal disabled={saving}>
                                    Preview
                                </Button>{' '}
                                <Button onClick={() => history.goBack()} minimal disabled={saving}>
                                    Cancel
                                </Button>{' '}
                                <Button onClick={onSaveClicked} intent="primary" loading={saving}>
                                    Save Workflow
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
};
