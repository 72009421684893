import React from 'react';
import { Row, Col } from 'react-grid-system';
import { InputGroup, Label } from '@blueprintjs/core';
import { Header } from 'src/components/Header';
import { useAuth } from 'src/hooks/useAuth';

export const AccountProfile: React.FC = () => {
    const { user } = useAuth();
    return (
        <>
            <Header title="Profile" />
            <Row>
                <Col>
                    <Label>
                        First Name
                        <InputGroup
                            disabled
                            value={user?.first_name}
                            autoComplete="off"
                            //className="m-b-15"
                        />
                    </Label>
                </Col>
                <Col>
                    <Label>
                        Last Name
                        <InputGroup
                            disabled
                            value={user?.last_name}
                            autoComplete="off"
                            //className="m-b-15"
                        />
                    </Label>
                </Col>
                <Col>
                    <Label>
                        Email
                        <InputGroup
                            disabled
                            value={user?.email}
                            autoComplete="off"
                            //className="m-b-15"
                        />
                    </Label>
                </Col>
            </Row>
        </>
    );
};
