import * as React from 'react';
import { Row, Col } from 'react-grid-system';
import { IconButton, MenuItem, Menu, Button, ButtonGroup, Chip, OutlinedInput, InputAdornment, Stack } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { FilterState } from 'src/api/posts';
import useDebounce from '../../../../../hooks/useDebounce';

interface FiltersProps {
    onFiltersChange: (field: string, value: string | boolean) => void;
    filters: FilterState;
    onSortChange: (sort: string) => void;
    onFilterChange: (filter: string) => void;
    onPostTypeChange: (type: string) => void;
    onQueryChange: (query: string) => void;
    postType: string;
    sort: string;
    filter: string;
    query: string;
}

export const Filters: React.FC<FiltersProps> = ({ onSortChange, sort, onFilterChange, onFiltersChange, filters, filter, postType, onPostTypeChange, query, onQueryChange }) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [q, setQ] = React.useState(query);
    const [searchOpen, setSearchOpen] = React.useState(false);
    const open = Boolean(anchorEl);
    const debouncedQuery = useDebounce(q, 300);
    const [filterAnchorEl, setFilterAnchorEl] = React.useState<null | HTMLElement>(null);
    const filterOpen = Boolean(filterAnchorEl);

    React.useEffect(() => {
        onQueryChange(debouncedQuery);
    }, [debouncedQuery]);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleFilterClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setFilterAnchorEl(event.currentTarget);
    };
    const handleFilterClose = () => {
        setFilterAnchorEl(null);
    };

    const closeSearch = () => {
        setSearchOpen(false);
        onQueryChange('');
        setQ('');
    };

    return (
        <>
            <Row justify="center" className="m-b-15">
                <Col xs={12} xl={6}>
                    <Row>
                        <Col xs={5}>
                            <ButtonGroup variant="outlined" aria-label="outlined button group" size="small">
                                <Button variant={postType === 'all' ? 'contained' : 'outlined'} onClick={() => onPostTypeChange('all')}>
                                    All Posts
                                </Button>
                                <Button variant={postType === 'createdByMe' ? 'contained' : 'outlined'} onClick={() => onPostTypeChange('createdByMe')}>
                                    My Posts
                                </Button>
                            </ButtonGroup>
                        </Col>
                        <Col className="text-right">
                            {!searchOpen && (
                                <IconButton size="small" className="m-l-5" onClick={() => setSearchOpen(true)}>
                                    <SearchIcon />
                                </IconButton>
                            )}

                            <IconButton
                                size="small"
                                className="m-l-5"
                                id="filter-button"
                                aria-controls={filterOpen ? 'filter-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={filterOpen ? 'true' : undefined}
                                onClick={handleFilterClick}
                            >
                                <FilterAltIcon />
                            </IconButton>
                            <Menu
                                id="filter-menu"
                                anchorEl={filterAnchorEl}
                                open={filterOpen}
                                onClose={handleFilterClose}
                                MenuListProps={{
                                    'aria-labelledby': 'filter-button',
                                }}
                            >
                                <MenuItem disabled>Filter</MenuItem>
                                {/*<MenuItem*/}
                                {/*    selected={filters.visibility === 'all'}*/}
                                {/*    onClick={() => {*/}
                                {/*        onFiltersChange('visibility', 'all');*/}
                                {/*        handleFilterClose();*/}
                                {/*    }}*/}
                                {/*>*/}
                                {/*    All Posts*/}
                                {/*</MenuItem>*/}
                                <MenuItem
                                    selected={filters.visibility === 'private'}
                                    onClick={() => {
                                        onFiltersChange('visibility', 'private');
                                        handleFilterClose();
                                    }}
                                >
                                    Private Posts
                                </MenuItem>
                                <MenuItem
                                    selected={filters.visibility === 'public'}
                                    onClick={() => {
                                        onFiltersChange('visibility', 'public');
                                        handleFilterClose();
                                    }}
                                >
                                    Public Posts
                                </MenuItem>
                                <MenuItem
                                    selected={filters.resolved === 'resolved'}
                                    onClick={() => {
                                        onFiltersChange('resolved', 'resolved');
                                        handleFilterClose();
                                    }}
                                >
                                    Resolved
                                </MenuItem>
                                <MenuItem
                                    selected={filters.resolved === 'unresolved'}
                                    onClick={() => {
                                        onFiltersChange('resolved', 'unresolved');
                                        handleFilterClose();
                                    }}
                                >
                                    Unresolved
                                </MenuItem>
                                <MenuItem
                                    selected={filters.critical}
                                    onClick={() => {
                                        onFiltersChange('critical', !filters.critical);
                                        handleFilterClose();
                                    }}
                                >
                                    Critical
                                </MenuItem>
                                <MenuItem
                                    selected={filters.constraint}
                                    onClick={() => {
                                        onFiltersChange('constraint', !filters.constraint);
                                        handleFilterClose();
                                    }}
                                >
                                    Constraint
                                </MenuItem>
                                <MenuItem
                                    selected={filters.cost}
                                    onClick={() => {
                                        onFiltersChange('cost', !filters.cost);
                                        handleFilterClose();
                                    }}
                                >
                                    Cost and Schedule
                                </MenuItem>
                                <MenuItem
                                    selected={filters.assigned}
                                    onClick={() => {
                                        onFiltersChange('assigned', !filters.assigned);
                                        handleFilterClose();
                                    }}
                                >
                                    Assigned
                                </MenuItem>
                                <MenuItem
                                    selected={filters.assignedToMe}
                                    onClick={() => {
                                        onFiltersChange('assignedToMe', !filters.assignedToMe);
                                        handleFilterClose();
                                    }}
                                >
                                    Assigned To Me
                                </MenuItem>
                                <MenuItem
                                    selected={filters.createdByMe}
                                    onClick={() => {
                                        onFiltersChange('createdByMe', !filters.createdByMe);
                                        handleFilterClose();
                                    }}
                                >
                                    Created By Me
                                </MenuItem>
                            </Menu>

                            <IconButton
                                className="m-l-5"
                                size="small"
                                id="basic-button"
                                aria-controls={open ? 'basic-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                onClick={handleClick}
                            >
                                <FilterListIcon />
                            </IconButton>
                            <Menu
                                id="basic-menu"
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                MenuListProps={{
                                    'aria-labelledby': 'basic-button',
                                }}
                            >
                                <MenuItem disabled>Sort By</MenuItem>
                                <MenuItem
                                    selected={sort === 'updated_at'}
                                    onClick={() => {
                                        onSortChange('updated_at');
                                        handleClose();
                                    }}
                                >
                                    Last Active
                                </MenuItem>
                                <MenuItem
                                    selected={sort === 'created_at'}
                                    onClick={() => {
                                        onSortChange('created_at');
                                        handleClose();
                                    }}
                                >
                                    Created Date
                                </MenuItem>
                            </Menu>
                        </Col>
                    </Row>
                    {(filters.visibility !== 'all' ||
                        filters.resolved !== 'all' ||
                        filters.constraint ||
                        filters.createdByMe ||
                        filters.assignedToMe ||
                        filters.cost ||
                        filters.assigned ||
                        filters.critical) && (
                        <Row className="m-t-15">
                            <Col>
                                <Stack direction="row" spacing={1}>
                                    {filters.resolved !== 'all' && <Chip label={filters.resolved} style={{ textTransform: 'capitalize' }} onDelete={() => onFiltersChange('resolved', 'all')} />}
                                    {filters.visibility !== 'all' && <Chip label={filters.visibility} style={{ textTransform: 'capitalize' }} onDelete={() => onFiltersChange('visibility', 'all')} />}
                                    {filters.assignedToMe && <Chip label="Assigned To Me" style={{ textTransform: 'capitalize' }} onDelete={() => onFiltersChange('assignedToMe', false)} />}
                                    {filters.createdByMe && <Chip label="Created By Me" style={{ textTransform: 'capitalize' }} onDelete={() => onFiltersChange('createdByMe', false)} />}
                                    {filters.constraint && <Chip label="Constraint" style={{ textTransform: 'capitalize' }} onDelete={() => onFiltersChange('constraint', false)} />}
                                    {filters.cost && <Chip label="Cost and Schedule" style={{ textTransform: 'capitalize' }} onDelete={() => onFiltersChange('cost', false)} />}
                                    {filters.assigned && <Chip label="Assigned" style={{ textTransform: 'capitalize' }} onDelete={() => onFiltersChange('assigned', false)} />}
                                    {filters.critical && <Chip label="Critical" style={{ textTransform: 'capitalize' }} onDelete={() => onFiltersChange('critical', false)} />}
                                </Stack>
                            </Col>
                        </Row>
                    )}

                    {searchOpen && (
                        <Row className="m-t-15">
                            <Col>
                                <OutlinedInput
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton onClick={closeSearch} edge="end">
                                                <CloseIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    fullWidth
                                    placeholder="Search posts..."
                                    size="small"
                                    value={q}
                                    onChange={(e) => setQ(e.target.value)}
                                />
                            </Col>
                        </Row>
                    )}
                </Col>
            </Row>
        </>
    );
};
