import React from 'react';
import { Redirect } from 'react-router-dom';
import { Users } from './Users';
import { Roles } from './Roles';
import { Offices } from './Offices';
import { Clients } from './Clients';
import { Workflows } from './Workflows';
import { Checklists } from './Checklists';
import { Dailys } from './Dailys';
import { EditWorkflowTemplate } from './Workflows/EditWorkflowTemplate';
import { RoleBasedRoute } from 'src/components/RoleBasedRoute';
import { WBS } from './WBS';
import { Documents } from './Documents';
import { CTC } from './CTC';
import { Contacts } from './Contacts';
interface ISettingsRoutes {
    requiredScopes: string[];
}

export const SettingsRoutes: React.FC<ISettingsRoutes> = ({ requiredScopes }) => {
    return (
        <>
            <RoleBasedRoute requiredScopes={requiredScopes} exact path="/settings" render={() => <Redirect to="/settings/users" />} />
            <RoleBasedRoute requiredScopes={requiredScopes} exact path="/settings/users" render={() => <Users />} />
            <RoleBasedRoute requiredScopes={requiredScopes} exact path="/settings/contacts" render={() => <Contacts />} />
            <RoleBasedRoute requiredScopes={requiredScopes} exact path="/settings/ctc" render={() => <CTC />} />
            <RoleBasedRoute requiredScopes={requiredScopes} exact path="/settings/roles" render={() => <Roles />} />
            <RoleBasedRoute requiredScopes={requiredScopes} exact path="/settings/offices" render={() => <Offices />} />
            <RoleBasedRoute requiredScopes={requiredScopes} exact path="/settings/clients" render={() => <Clients />} />
            <RoleBasedRoute requiredScopes={requiredScopes} exact path="/settings/workflows" render={() => <Workflows />} />
            <RoleBasedRoute requiredScopes={requiredScopes} exact path="/settings/checklists" render={() => <Checklists />} />
            <RoleBasedRoute requiredScopes={requiredScopes} exact path="/settings/workflows/:templateId" render={() => <EditWorkflowTemplate />} />
            <RoleBasedRoute requiredScopes={requiredScopes} exact path="/settings/wbs" render={() => <WBS />} />
            <RoleBasedRoute requiredScopes={requiredScopes} exact path="/settings/dailys" render={() => <Dailys />} />
            <RoleBasedRoute requiredScopes={requiredScopes} exact path="/settings/documents" render={() => <Documents />} />
        </>
    );
};
