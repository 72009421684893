import React from 'react';
import { useDropzone } from 'react-dropzone';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Button, Icon, Navbar, Alignment, IToastProps, ProgressBar, Classes, Intent, Toaster, Position } from '@blueprintjs/core';
import { useJobs } from '../../../hooks/useJobs';
import { FileTable } from './components/FileTable';
import { FolderDialog } from './components/FolderDialog';
import { Allotment } from 'allotment';
import classNames from 'classnames';
import { IDocument } from 'src/api/models/Document';
import { listDocuments, listFolders, removeFolder, uploadDocument, getDocumentById } from 'src/api/documents';
import 'allotment/dist/style.css';
import { DocumentDetails } from './components/DocumentDetails';
import { DocumentTree } from './components/DocumentTree';
import './index.scss';
import { ConfirmPopover } from '../../../components/ConfirmPopover';
import { AppToast } from '../../../components/Toasts/AppToast';
import { Header2 } from '../../../components/Header2';

const TopToast = Toaster.create({
    position: Position.BOTTOM_RIGHT,
    usePortal: true,
});
const drawerWidth = 350;

export const Documents: React.FC = () => {
    const { selectedJob } = useJobs();
    const [folders, setFolders] = React.useState<IDocument[]>([]);
    const [isSelectedLocked, setIsSelectedLocked] = React.useState(false);
    const [parentFolder, setParentFolder] = React.useState<IDocument | null>(null);
    const [isFolderOpen, setIsFolderOpen] = React.useState(false);
    const [isEditOpen, setIsEditOpen] = React.useState(false);
    const [documents, setDocuments] = React.useState<IDocument[]>([]);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState<string | null>(null);
    const [currentFolderId, setCurrentFolderId] = React.useState<string>('documents');
    const [showProgress, setShowProgress] = React.useState(false);
    const [targetCount, setTargetCount] = React.useState(0);
    const [completeCount, setCompleteCount] = React.useState(0);
    const [panelOpen, setPanelOpen] = React.useState(false);
    const [selectedDocument, setSelectedDocument] = React.useState<IDocument | null>(null);
    const [masterId, setMasterId] = React.useState('documents');

    const [breadcrumbs, setBreadcrumbs] = React.useState<IDocument[]>([]);
    React.useEffect(() => {
        loadFolders(false);
    }, []);

    React.useEffect(() => {
        loadDocuments();
    }, [currentFolderId]);

    const loadDocuments = async (load = true) => {
        if (!selectedJob) return;
        setLoading(load);
        setError(null);
        try {
            const docs = await listDocuments(selectedJob.id, currentFolderId === 'documents' ? null : currentFolderId);
            setDocuments(docs);
        } catch (err) {
            setError(err.toLocaleString());
        }
        setLoading(false);
    };

    const loadFolders = async (load = true) => {
        if (!selectedJob) return;
        setLoading(load);
        setError(null);
        try {
            const docs = await listFolders(selectedJob.id, null);
            setFolders(docs);
        } catch (err) {
            setError(err.toLocaleString());
        }
        setLoading(false);
    };

    const renderProgress = (count: number): IToastProps => {
        return {
            icon: <Icon icon={'tick-circle'} color={'green'} />,
            message: (
                <>
                    <p>{count} files successfully uploaded!</p>
                    <ProgressBar
                        className={classNames('m-t-5', {
                            [Classes.PROGRESS_NO_STRIPES]: true,
                        })}
                        intent={Intent.SUCCESS}
                        value={10}
                    />
                </>
            ),
            timeout: 2000,
        };
    };

    const uploadSingleFile = async (file: File) => {
        if (!selectedJob) return;
        await uploadDocument(selectedJob.id, currentFolderId === 'documents' ? null : currentFolderId, file);
        setCompleteCount((prevState) => prevState + 1);
    };

    const onDrop = React.useCallback(
        async (acceptedFiles) => {
            if (!selectedJob) return;
            try {
                setTargetCount(acceptedFiles.length);
                setShowProgress(true);
                const promises = acceptedFiles.map((file: File) => uploadSingleFile(file));
                await Promise.all(promises);
                loadDocuments(false);
                loadFolders(false);
                TopToast.show(renderProgress(acceptedFiles.length));
                setShowProgress(false);
                setTargetCount(0);
                setCompleteCount(0);
            } catch (err) {
                console.log(err.toString());
            }
        },
        [currentFolderId],
    );

    const { getRootProps, getInputProps, isDragActive, open } = useDropzone({ onDrop, noClick: true, noKeyboard: true });

    const fileUploadClick = () => {
        open();
    };

    const onDocumentDoubleClicked = (document: IDocument) => {
        if (document.is_folder) {
            setCurrentFolderId(document.id);
            // setPaths((prevState) => [...prevState, document]);
        }
    };

    const onDocumentClicked = (document: IDocument) => {
        if (!document.is_folder) {
            setSelectedDocument(document);
            setPanelOpen(true);
        }
    };

    const refreshSelectedDocument = async () => {
        if (!selectedJob || !selectedDocument) return;
        const doc = await getDocumentById(selectedJob.id, selectedDocument.id);
        setSelectedDocument(doc);
    };

    const onFolderSelected = (id: string, path: number[]) => {
        if (!id) {
            if (path.length === 0) {
                setIsSelectedLocked(false);
                setCurrentFolderId('documents');
                setMasterId('documents');
                setBreadcrumbs([]);
            }
            return;
        }
        closePanel();
        const crumbs: IDocument[] = [];
        path.forEach((location, idx) => {
            if (idx === 0) {
                crumbs.push(folders[location]);
            } else {
                crumbs.push(crumbs[idx - 1].children[location]);
            }
        });
        setBreadcrumbs(crumbs);
        setMasterId(crumbs[0] ? crumbs[0].id : 'documents');
        setIsSelectedLocked(crumbs[0] ? crumbs[0].is_locked : false);
        const parentIdx = crumbs.length - 1;
        const parent = crumbs[parentIdx] || null;
        setParentFolder(parent);
        setCurrentFolderId(id);
    };

    const closePanel = () => {
        setPanelOpen(false);
        setSelectedDocument(null);
    };

    const onFolderCreated = (folder: IDocument) => {
        loadFolders(false);
        //setCurrentFolderId(folder.id);
    };
    const onFolderUpdated = (folder: IDocument) => {
        loadFolders(false);
        loadDocuments(false);
        setParentFolder(folder);
        //setCurrentFolderId(folder.id);
    };
    const renderBreadcrumbs = () => {
        return breadcrumbs.map((crumb) => {
            return (
                <span key={crumb.id} className="bp3-button-text bp3-minimal fs-16 " style={{ color: '#182026' }}>
                    {crumb.name}
                </span>
            );
        });
    };
    const deleteFolder = async () => {
        if (!selectedJob || currentFolderId === 'documents') return;
        await removeFolder(selectedJob.id, currentFolderId);
        AppToast.show({ message: `Folder removed!`, intent: 'success', icon: 'tick-circle' });
        setCurrentFolderId('documents');
        setMasterId('documents');
        setParentFolder(null);
        closePanel();
        loadFolders(false);
    };
    const uploadPercent = completeCount / targetCount;
    if (!selectedJob) return null;
    return (
        <>
            <div className="p-l-15 p-r-20 ">
                <Header2
                    className="p-b-10 m-t-20 bottom-border-bar"
                    breadcrumbs={[
                        { title: 'Jobs', link: '/jobs' },
                        { title: `${selectedJob?.name} - ${selectedJob?.job_number}`, link: `/jobs/${selectedJob?.id}/summary` },
                        { title: 'Documents', link: '' },
                    ]}
                />
            </div>
            {error && <p className="text-center">{error}</p>}
            <Allotment>
                <Allotment.Pane preferredSize={275} minSize={isFolderOpen || isEditOpen ? 275 : 0} maxSize={isFolderOpen || isEditOpen ? 275 : 600}>
                    <Navbar>
                        <Navbar.Group align={Alignment.LEFT}>
                            <Navbar.Heading>
                                <Breadcrumbs className={showProgress ? 'm-b-0' : 'm-b-0'}>
                                    <span className="bp3-button-text bp3-minimal fs-16" style={{ color: '#182026' }}>
                                        Folders
                                    </span>
                                </Breadcrumbs>
                            </Navbar.Heading>
                        </Navbar.Group>
                    </Navbar>
                    <div className="left-pane">
                        <DocumentTree selectedFolderId={currentFolderId} folders={folders} onFolderSelected={onFolderSelected} />
                    </div>
                </Allotment.Pane>
                <Allotment.Pane>
                    <Navbar className="p-l-0">
                        <Navbar.Group align={Alignment.LEFT}>
                            <Navbar.Divider className="m-l-0" />
                            <Navbar.Heading>
                                <Breadcrumbs className={showProgress ? 'm-b-0' : 'm-b-0'} maxItems={4}>
                                    <span className="bp3-button-text bp3-minimal fs-16" style={{ color: '#182026' }}>
                                        Documents
                                    </span>
                                    {renderBreadcrumbs()}
                                </Breadcrumbs>
                            </Navbar.Heading>
                        </Navbar.Group>
                    </Navbar>
                    <div className="m-b-5">
                        <Button
                            small
                            icon="folder-new"
                            text="New Folder"
                            className="m-t-5 m-l-10"
                            minimal
                            disabled={isSelectedLocked}
                            onClick={() => {
                                closePanel();
                                setIsFolderOpen(true);
                            }}
                        />
                        <Button small icon="upload" text="File Upload" onClick={fileUploadClick} className="m-t-5 m-l-5" minimal />
                        {currentFolderId !== 'documents' && (
                            <>
                                <Button
                                    small
                                    icon="cog"
                                    text="Edit Folder"
                                    className="m-t-5 m-l-5"
                                    minimal
                                    onClick={() => {
                                        closePanel();
                                        setIsEditOpen(true);
                                    }}
                                />
                                {!isSelectedLocked ? (
                                    <ConfirmPopover
                                        contentTitle="Are you sure?"
                                        confirmButtonIntent="danger"
                                        contentBody={<p>Are you sure you want to remove this folder? All sub folders and documents will also be removed.</p>}
                                        confirmButtonText="Delete"
                                        onConfirmClick={deleteFolder}
                                    >
                                        <Button small text="Delete Folder" className="m-t-5 m-l-5" minimal intent="danger" />
                                    </ConfirmPopover>
                                ) : (
                                    <Button small text="Delete" className="m-t-5 m-l-5" minimal disabled intent="danger" />
                                )}
                            </>
                        )}
                    </div>

                    {showProgress && (
                        <div className="p-20">
                            <p>
                                Uploaded {completeCount} of {targetCount} files
                            </p>
                            <ProgressBar
                                className={classNames('m-t-5 m-b-00', {
                                    [Classes.PROGRESS_NO_STRIPES]: uploadPercent >= 1,
                                })}
                                intent={uploadPercent < 1 ? Intent.PRIMARY : Intent.SUCCESS}
                                value={uploadPercent + 0.2}
                            />
                        </div>
                    )}
                    <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <div className="right-pane">
                            <div className={isDragActive ? 'upload-overlay' : 'display-none'}>
                                <div className="upload-icon bounce">
                                    <CloudUploadIcon
                                        sx={{
                                            color: '#42536e',
                                            fontSize: 50,
                                        }}
                                    />
                                </div>
                                <Alert severity="info" className="drop-alert">
                                    <AlertTitle className="p-l-50">
                                        {' '}
                                        Drop files to upload to <b>{breadcrumbs[0]?.name || 'Documents'}</b>
                                    </AlertTitle>
                                </Alert>
                            </div>
                            <FileTable
                                jobId={selectedJob.id}
                                documents={documents}
                                selectedDocumentId={selectedDocument ? selectedDocument.id : null}
                                isLoading={loading}
                                onDocumentClicked={onDocumentClicked}
                                onDocumentDoubleClicked={onDocumentDoubleClicked}
                            />
                        </div>
                    </div>
                </Allotment.Pane>
                <Allotment.Pane minSize={350} maxSize={350} visible={panelOpen}>
                    <DocumentDetails
                        onRemoveComplete={() => {
                            closePanel();
                            loadFolders(false);
                            loadDocuments(false);
                        }}
                        refreshSelectedDocument={refreshSelectedDocument}
                        onUploadComplete={(doc) => {
                            loadDocuments(false);
                            setSelectedDocument(doc);
                        }}
                        onUpdateComplete={() => {
                            loadDocuments(false);
                        }}
                        jobId={selectedJob.id}
                        isOpen={panelOpen}
                        selectedDocument={selectedDocument}
                        onClose={closePanel}
                        drawerWidth={drawerWidth}
                    />
                </Allotment.Pane>
            </Allotment>
            <FolderDialog
                edit
                isLocked={isSelectedLocked}
                masterId={masterId}
                parentFolder={parentFolder}
                onFolderUpdated={onFolderUpdated}
                isOpen={isEditOpen}
                currentFolderId={currentFolderId}
                onFolderCreated={onFolderCreated}
                onClose={() => setIsEditOpen(false)}
            />
            <FolderDialog
                masterId={masterId}
                parentFolder={parentFolder}
                isLocked={isSelectedLocked}
                isOpen={isFolderOpen}
                currentFolderId={currentFolderId}
                onFolderCreated={onFolderCreated}
                onClose={() => setIsFolderOpen(false)}
            />
        </>
    );
};
