import React from 'react';
import { Drawer } from '@blueprintjs/core';
import { NewComment } from './NewComment';
import { CommentValue } from './CommentValue';
import { IComment, ITopicIssue } from 'src/api/models/Meeting';

interface ICommentDrawerProps {
    isOpen: boolean;
    onClose: () => void;
    onCommentAdded: (comment: IComment) => void;
    onCommentUpdated: (comment: IComment) => void;
    onCommentDeleted: (comment: IComment) => void;
    issue: ITopicIssue | null;
}

export const CommentDrawer: React.FC<ICommentDrawerProps> = ({ isOpen, onClose, onCommentDeleted, issue, onCommentAdded, onCommentUpdated }) => {
    const addComment = (comment: IComment) => {
        onCommentAdded(comment);
    };

    const renderComments = () => {
        if (!issue) return;
        return issue.comments.map((comment) => {
            return <CommentValue key={comment.id} comment={comment} onCommentDeleted={onCommentDeleted} onCommentUpdated={onCommentUpdated} />;
        });
    };
    return (
        <Drawer title="Comments" icon="comment" hasBackdrop={false} isOpen={isOpen} size={500} onClose={onClose}>
            <div className="comment-drawer-wrapper">
                <div className="m-t-15">{renderComments()}</div>
                <NewComment onCommentAdded={addComment} issue={issue} />
            </div>
        </Drawer>
    );
};
