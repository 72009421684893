import React from 'react';
import { InputGroup, Label, Button, Colors } from '@blueprintjs/core';
import { Row, Col } from 'react-grid-system';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useAuth } from 'src/hooks/useAuth';
import { login } from 'src/api/user';
import logo from 'src/assets/images/firetrol.png';
import { useHistory } from 'react-router-dom';

const validationSchema = Yup.object().shape({
    email: Yup.string().required('Please enter a registered email').email().label('Email'),
    password: Yup.string().required().min(1, 'Password must have at least 6 characters').label('Password'),
});

interface ILoginFormValues {
    email: string;
    password: string;
}

const initialValues: ILoginFormValues = {
    email: '',
    password: '',
};

export const Login: React.FC = () => {
    const { setUser } = useAuth();
    const history = useHistory();
    const [rememberMe, setRememberMe] = React.useState(false);
    const [loginError, setLoginError] = React.useState<string | null>(null);
    const formik = useFormik({
        initialValues,
        validationSchema,
        validateOnMount: true,
        onSubmit: async (values) => {
            setLoginError(null);
            try {
                const user = await login(values.email, values.password, rememberMe);
                if (user.status === 'disabled') {
                    return setLoginError('Account is Disabled');
                }
                setUser(user);
            } catch (error) {
                setLoginError('Invalid email or password');
            }
        },
    });

    const handleKeyDown = (e: any) => {
        if (e.key === 'Enter') {
            formik.handleSubmit();
        }
    };

    return (
        <>
            <div className="bp3-dialog-container" style={{ marginTop: 200 }}>
                <div className="bp3-dialog bg-white">
                    <img className="login-logo" src={logo} alt="" style={{ maxWidth: 300, height: 'auto', margin: 'auto', marginTop: 25 }} />
                    <div className={`bp3-dialog-body ${loginError ? 'm-b-0' : ''}`}>
                        <h2 className="m-0">Sign In</h2>
                        <p className="m-t-15 m-b-30">Enter your email & password to sign in</p>
                        <Label>
                            Email Address
                            <InputGroup
                                placeholder="Email..."
                                autoFocus
                                leftIcon="person"
                                onChange={(e) => formik.setFieldValue('email', e.target.value)}
                                value={formik.values.email}
                                autoComplete="off"
                                className="m-b-15"
                            />
                        </Label>
                        <Label>
                            Password
                            <InputGroup
                                type="password"
                                onChange={(e) => formik.setFieldValue('password', e.target.value)}
                                value={formik.values.password}
                                placeholder="Password..."
                                autoComplete="off"
                                leftIcon="lock"
                                onKeyDown={handleKeyDown}
                            />
                        </Label>
                        <Row className="m-b-15">
                            <Col>
                                <label className="bp3-control bp3-checkbox">
                                    <input type="checkbox" checked={rememberMe} onChange={(event) => setRememberMe(event.target.checked)} />
                                    <span className="bp3-control-indicator" />
                                    Remember Me
                                </label>
                            </Col>
                            <Col className="text-right">
                                <Button text="Forgot Password?" small minimal intent="primary" onClick={() => history.push('/forgotPassword')} />
                            </Col>
                        </Row>
                        {/*<Checkbox checked={rememberMe} label="Remember me" onChange={(event) => setRememberMe(event.target.checked)} />*/}
                    </div>
                    <div className="bp3-dialog-footer">
                        {loginError && <p style={{ color: Colors.RED2, textAlign: 'center' }}>{loginError}</p>}
                        <div className="bp3-dialog-footer-actions">
                            <Button text="Sign In" type="submit" fill intent="primary" loading={formik.isSubmitting} disabled={!formik.isValid} className="m-0" onClick={() => formik.handleSubmit()} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
