import React from 'react';
import moment, { Moment } from 'moment';
import { IconButton, TextField, Chip, Tooltip } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';

interface DatePickerProps {
    value: Moment | Date | null;
    onChange: (value: Moment | null) => void;
}
export const DatePicker: React.FC<DatePickerProps> = ({ value, onChange }) => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <>
            {value ? (
                <Tooltip title="Need Date">
                    <Chip color="primary" label={moment(value).format('MM/DD/YYYY')} onDelete={() => onChange(null)} className="m-r-5" />
                </Tooltip>
            ) : (
                <Tooltip title="Need Date">
                    <IconButton aria-describedby={id} onClick={handleClick} className="m-r-5 p-t-4">
                        <CalendarMonthIcon />
                    </IconButton>
                </Tooltip>
            )}

            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Typography sx={{ pl: 1, pt: 1 }} className="text-center">
                    Select Due Date
                </Typography>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <StaticDatePicker
                        displayStaticWrapperAs="desktop"
                        value={value}
                        onChange={(newValue) => {
                            handleClose();
                            onChange(newValue);
                        }}
                        renderInput={(params) => <TextField {...params} />}
                    />
                </LocalizationProvider>
            </Popover>
        </>
    );
};
