import { getAuthOptions } from './helpers';
import AppApi from './index';
import { IDocument } from './models/Document';
import { ITopicIssue, IComment, IMeeting, IMeetingTopic, IMeetingType, IGroupedMeeting, ITopicIssueResponseWithStats } from './models/Meeting';
const api = AppApi.getApi();

export const createMeetingType = async (jobId: number, body: IMeetingType): Promise<IMeetingType> => {
    const url = `/jobs/${jobId}/meeting_types`;
    const { data } = await api.post(url, body, await getAuthOptions());
    return data;
};

export const loadMeetingTypes = async (jobId: number): Promise<IMeetingType[]> => {
    const url = `/jobs/${jobId}/meeting_types`;
    const { data } = await api.get(url, await getAuthOptions());
    return data;
};

export const getMeetingById = async (jobId: number, meetingId: string): Promise<IMeeting> => {
    const url = `/jobs/${jobId}/meetings/${meetingId}`;
    const { data } = await api.get(url, await getAuthOptions());
    return data;
};

export const loadGroupedMeetings = async (jobId: number): Promise<IGroupedMeeting[]> => {
    const url = `/jobs/${jobId}/grouped_meetings`;
    const { data } = await api.get(url, await getAuthOptions());
    return data;
};
export const loadMeetings = async (jobId: number): Promise<IMeeting[]> => {
    const url = `/jobs/${jobId}/meetings`;
    const { data } = await api.get(url, await getAuthOptions());
    return data;
};

export const createMeeting = async (jobId: number): Promise<IMeeting> => {
    const url = `/jobs/${jobId}/meetings`;
    const { data } = await api.post(url, null, await getAuthOptions());
    return data;
};

export const cloneMeeting = async (jobId: number, meetingId: string): Promise<IMeeting> => {
    const url = `/jobs/${jobId}/meetings/${meetingId}/clone`;
    const { data } = await api.post(url, null, await getAuthOptions());
    return data;
};

export const updateMeeting = async (jobId: number, payload: IMeeting): Promise<IMeeting> => {
    const url = `/jobs/${jobId}/meetings/${payload.id}`;
    const { data } = await api.put(url, payload, await getAuthOptions());
    return data;
};

export const publishMeeting = async (jobId: number, meetingId: string, blob: Blob): Promise<IDocument> => {
    const url = `/jobs/${jobId}/meetings/${meetingId}/publish`;
    const formData = new FormData();
    formData.append('file', blob);
    const authOptions = await getAuthOptions();
    const options = {
        ...authOptions,
        headers: {
            ...authOptions.headers,
            'Content-Type': 'multipart/form-data',
        },
    };
    const { data } = await api.put(url, formData, options);
    return data;
};

export const deleteMeeting = async (jobId: number, meetingId: string): Promise<IMeeting> => {
    const url = `/jobs/${jobId}/meetings/${meetingId}`;
    const { data } = await api.delete(url, await getAuthOptions());
    return data;
};

export const createTopic = async (jobId: number, meetingId: string): Promise<IMeetingTopic> => {
    const url = `/jobs/${jobId}/meetings/${meetingId}/topics`;
    const { data } = await api.post(url, null, await getAuthOptions());
    return data;
};

export const deleteTopic = async (jobId: number, meetingId: string, topicId: string): Promise<IMeetingTopic> => {
    const url = `/jobs/${jobId}/meetings/${meetingId}/topics/${topicId}`;
    const { data } = await api.delete(url, await getAuthOptions());
    return data;
};

export const updateTopic = async (jobId: number, payload: IMeetingTopic): Promise<IMeetingTopic> => {
    const url = `/jobs/${jobId}/meetings/${payload.meeting_id}/topics/${payload.id}`;
    const { data } = await api.put(url, payload, await getAuthOptions());
    return data;
};

export const loadIssues = async (jobId: number): Promise<ITopicIssueResponseWithStats> => {
    const url = `/jobs/${jobId}/issues`;
    const { data } = await api.get(url, await getAuthOptions());
    return data;
};

export const createIssue = async (jobId: number, meetingId: string, topicId: string): Promise<ITopicIssue> => {
    const url = `/jobs/${jobId}/meetings/${meetingId}/topics/${topicId}/issues`;
    const { data } = await api.post(url, null, await getAuthOptions());
    return data;
};

export const updateIssue = async (jobId: number, meetingId: string, topicId: string, issueId: string, payload: ITopicIssue): Promise<ITopicIssue> => {
    const url = `/jobs/${jobId}/meetings/${meetingId}/topics/${topicId}/issues/${issueId}`;
    const { data } = await api.put(url, payload, await getAuthOptions());
    return data;
};

export const createComment = async (jobId: number, meetingId: string, topicId: string, issueId: string, comment: string): Promise<IComment> => {
    const url = `/jobs/${jobId}/meetings/${meetingId}/topics/${topicId}/issues/${issueId}/comments`;
    const { data } = await api.post(url, { comment }, await getAuthOptions());
    return data;
};

export const updateComment = async (jobId: number, meetingId: string, topicId: string, issueId: string, commentId: string, comment: string): Promise<IComment> => {
    const url = `/jobs/${jobId}/meetings/${meetingId}/topics/${topicId}/issues/${issueId}/comments/${commentId}`;
    const { data } = await api.put(url, { comment }, await getAuthOptions());
    return data;
};

export const deleteComment = async (jobId: number, meetingId: string, topicId: string, issueId: string, commentId: string): Promise<IComment> => {
    const url = `/jobs/${jobId}/meetings/${meetingId}/topics/${topicId}/issues/${issueId}/comments/${commentId}`;
    const { data } = await api.delete(url, await getAuthOptions());
    return data;
};
