import React from 'react';
import { AiOutlineFileExcel, AiOutlineFilePdf, AiOutlineFileUnknown, AiOutlineFileImage, AiOutlineFileWord } from 'react-icons/ai';
import { GrDocumentCsv } from 'react-icons/gr';
import moment from 'moment';
import { useDropzone } from 'react-dropzone';
import { Row, Col } from 'react-grid-system';
import { IAttachment } from 'src/api/models/Attachment';
import { Button, Label } from '@blueprintjs/core';
import { uploadTmpFile } from 'src/api/attachments';
import { download } from 'src/api/attachments';
import './index.scss';
import { useAuth } from 'src/hooks/useAuth';

interface IAttachmentsProps {
    onFilesAdded: (attachments: IAttachment[]) => void;
    onFilesRemoved: (attachment: IAttachment[]) => void;
    filesToRemove?: IAttachment[];
    existingFiles: IAttachment[];
    revisions?: IAttachment[];
    showRevisions?: boolean;
    disabled?: boolean;
    maxFiles?: number;
    itemType: string;
}

const IconLookup: any = {
    csv: <GrDocumentCsv size={16} />,
    pdf: <AiOutlineFilePdf size={18} />,
    xlsx: <AiOutlineFileExcel size={18} />,
    cfb: <AiOutlineFileExcel size={18} />,
    xls: <AiOutlineFileExcel size={18} />,
    png: <AiOutlineFileImage size={18} />,
    jpg: <AiOutlineFileImage size={18} />,
    jpeg: <AiOutlineFileImage size={18} />,
    docx: <AiOutlineFileWord size={18} />,
};

export const Attachments: React.FC<IAttachmentsProps> = ({
    onFilesAdded,
    itemType,
    filesToRemove = [],
    revisions = [],
    showRevisions = false,
    onFilesRemoved,
    maxFiles = 100,
    existingFiles,
    disabled,
}) => {
    const [removed, setRemoved] = React.useState<IAttachment[]>([]);
    const [attachments, setAttachments] = React.useState<IAttachment[]>(existingFiles);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState<string | null>(null);
    const { user } = useAuth();
    const onDrop = React.useCallback(async (acceptedFiles) => {
        setLoading(true);
        setError(null);
        try {
            const result = await uploadTmpFile(acceptedFiles[0], itemType);
            setAttachments((prevState) => [...prevState, result]);
        } catch (err) {
            setError(err.toString());
        }

        setLoading(false);
    }, []);

    React.useEffect(() => {
        if (attachments !== existingFiles) {
            onFilesAdded(attachments);
        }
    }, [attachments]);

    React.useEffect(() => {
        onFilesRemoved(removed);
    }, [removed]);

    const { getRootProps, getInputProps, open } = useDropzone({ onDrop, noClick: true, noKeyboard: true, maxFiles: 1 });

    const downloadFile = async (id: string) => {
        await download(id);
    };

    const removeAttachment = (attachment: IAttachment) => {
        setRemoved((prevState) => [...prevState, attachment]);
        setAttachments((prevState) => prevState.filter((a) => a.id !== attachment.id));
    };

    const renderFiles = () => {
        return attachments.map((file) => {
            return (
                <tr key={file.id}>
                    <td>{IconLookup[file.type] || <AiOutlineFileUnknown size={18} />}</td>
                    <td>
                        <a onClick={() => downloadFile(file.id)}>{file.name}</a>
                    </td>
                    <td>{Math.round(file.size / 1000)} KB</td>
                    <td>{moment(file.created_at).format('DD MMM YYYY')}</td>
                    <td className="p-t-8 p-b-0">{user && <Button icon="trash" minimal onClick={() => removeAttachment(file)} small />}</td>
                </tr>
            );
        });
    };

    const AttachmentTable = (
        <>
            {disabled || (attachments.length === 0 && <p className="m-t-5">No Attachments</p>)}
            {attachments.length > 0 && (
                <table className={`bp3-html-table bp3-html-table-striped`} style={{ width: '100%' }}>
                    <thead>
                        <tr>
                            <th style={{ width: 18 }} />
                            <th>Name</th>
                            <th>Size</th>
                            <th>Date Added</th>
                            <th />
                        </tr>
                    </thead>
                    <tbody>{renderFiles()}</tbody>
                </table>
            )}
        </>
    );
    return (
        <div>
            <Row>
                <Col>
                    <Label className="m-b-0 text-bold">Attachments ({attachments.length})</Label>
                </Col>
                <Col className="text-right">
                    <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <Button icon="plus" small minimal onClick={open} loading={loading} />
                    </div>
                </Col>
            </Row>
            <hr className="m-t-3" />
            <Row>
                <Col>{AttachmentTable}</Col>
            </Row>
            {error && <p>{error}</p>}
        </div>
    );
};
