import React from 'react';
import { Divider } from '@mui/material';
import { PostCommentResponse } from 'src/api/models/Post';
import { loadPostComments } from 'src/api/posts';
import { NewComment } from './components/NewComment';
import { CommentValue } from './components/CommentValue';

interface PostCommentsProps {
    comments: PostCommentResponse[];
    postId: string;
    shouldPoll?: boolean;
}

export const PostComments: React.FC<PostCommentsProps> = ({ comments, postId, shouldPoll = false }) => {
    const [postComments, setPostComments] = React.useState<PostCommentResponse[]>(comments);

    React.useEffect(() => {
        const interval = setInterval(() => {
            if (shouldPoll) {
                loadComments();
            }
        }, 8000);

        if (!shouldPoll) {
            clearInterval(interval);
        }
        return () => clearInterval(interval);
    }, [shouldPoll]);

    const loadComments = async () => {
        const data = await loadPostComments(-1, postId);
        setPostComments(data);
    };

    const addComment = (comment: PostCommentResponse) => {
        setPostComments((prevState) => [...prevState, comment]);
    };
    const updateComment = (comment: PostCommentResponse) => {
        setPostComments((prevState) => {
            return prevState.map((c) => {
                if (c.id === comment.id) return comment;
                return c;
            });
        });
    };

    const deleteComment = (comment: PostCommentResponse) => {
        setPostComments((prevState) => {
            return prevState.filter((c) => c.id !== comment.id);
        });
    };

    const renderComments = () => {
        return postComments.map((comment) => {
            return <CommentValue key={comment.id} comment={comment} onCommentUpdated={updateComment} onCommentDeleted={deleteComment} />;
        });
    };
    return (
        <div>
            <div className="comment-scroll-container">{renderComments()}</div>
            <Divider />
            <NewComment onCommentAdded={addComment} postId={postId} />
        </div>
    );
};
