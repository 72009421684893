import React from 'react';
import { Label } from '@blueprintjs/core';
import { Autocomplete, createFilterOptions } from '@mui/material';
import TextField from '@mui/material/TextField';
import { IMeetingType } from 'src/api/models/Meeting';
import { useJobs } from 'src/hooks/useJobs';
import { createMeetingType } from 'src/api/meetings';
import { AppToast } from '../../Toasts/AppToast';

interface IMeetingTypeSelectProps {
    jobId: number;
    onChange: (value: IMeetingType | null) => void;
    value: IMeetingType | null;
    hideLabel?: boolean;
    placeholder?: string;
    disabled?: boolean;
}

const filter = createFilterOptions<IMeetingType>();

export const MeetingTypeSelect: React.FC<IMeetingTypeSelectProps> = ({ onChange, jobId, disabled = false, value, hideLabel = false, placeholder = 'Select or create meeting type...' }) => {
    const { meetingTypes, setMeetingTypes } = useJobs();

    const createNewType = async (name: string) => {
        try {
            const newType = await createMeetingType(jobId, { id: '', name });
            setMeetingTypes([...meetingTypes, newType]);
            AppToast.show({ message: `"${name}" Created`, intent: 'success', icon: 'tick' });
            return newType ? newType : null;
        } catch (err) {
            console.log('error: ', err.toLocaleString());
            return null;
        }
    };

    const onValueChange = async (updatedValue: IMeetingType | null) => {
        if (updatedValue && updatedValue.id === 'new') {
            const newType = await createNewType(updatedValue.name);
            onChange(newType);
        } else {
            onChange(updatedValue);
        }
    };

    return (
        <div style={{ flexGrow: 1 }}>
            {!hideLabel && <Label className="text-bold">Meeting Type</Label>}
            <Autocomplete
                disabled={disabled}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                size="small"
                noOptionsText="No meeting types..."
                options={meetingTypes}
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(option, value) => {
                    return option.id === value.id;
                }}
                renderOption={(props, option) => {
                    return (
                        <li {...props} key={option.id}>
                            {option.id !== 'new' ? option.name : `Create "${option.name}"`}
                        </li>
                    );
                }}
                freeSolo
                autoHighlight
                value={value}
                defaultValue={value}
                onChange={(event, newValue: any) => {
                    onValueChange(newValue ? newValue : null);
                }}
                filterOptions={(options, params) => {
                    const filtered = filter(options, params);
                    if (params.inputValue !== '') {
                        filtered.push({ id: 'new', name: params.inputValue });
                    }
                    return filtered;
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="outlined"
                        placeholder={placeholder}
                        InputProps={{
                            ...params.InputProps,
                            size: 'small',
                            endAdornment: <React.Fragment>{params.InputProps.endAdornment}</React.Fragment>,
                        }}
                    />
                )}
            />
        </div>
    );
};
