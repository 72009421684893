import React from 'react';
import { Icon, Tag } from '@blueprintjs/core';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { IChecklistItem } from 'src/api/models/Checklist';
import { IOrganizationRole } from 'src/api/models/Organization';

const columns: GridColDef[] = [
    {
        field: '',
        headerName: '',
        width: 25,
        renderHeader: () => '',
        renderCell: (params: GridValueGetterParams) => {
            return params.row.complete ? <Icon icon="tick-circle" intent="success" className="m-l-5" /> : <Icon intent="none" icon="tick-circle" className="m-l-5" />;
        },
    },
    {
        field: 'title',
        headerName: 'Title',
        width: 200,
        editable: true,
        pinnable: true,
    },
    {
        field: 'critical',
        headerName: 'Critical',
        valueGetter: (params: GridValueGetterParams) => {
            return params.row.critical === 'true' ? 'Yes' : 'No';
        },
        editable: false,
    },
    {
        field: 'complete',
        headerName: 'Complete',
        editable: true,
        valueGetter: (params: GridValueGetterParams) => {
            return params.row.complete ? 'Yes' : 'No';
        },
    },
    {
        field: 'require_association',
        headerName: 'Driver',
        valueGetter: (params: GridValueGetterParams) => {
            return params.row.require_association === 'true' ? 'Yes' : 'No';
        },
        editable: false,
    },
    {
        field: 'phase',
        headerName: 'Phase',
        width: 150,
        description: 'This column has a value getter and is not sortable.',
        sortable: true,
        valueGetter: (params: GridValueGetterParams) => {
            return `${params.row.phase?.title || ''}`;
        },
    },
    {
        field: 'assigned_to',
        headerName: 'Assigned To',
        flex: 1,
        valueGetter: (params: GridValueGetterParams) => {
            return `${JSON.stringify(params.row.assigned_to)}`;
        },
        renderCell: (params: GridValueGetterParams) => {
            return params.row.assigned_to.map((role: IOrganizationRole) => (
                <Tag minimal className="m-r-5">
                    {role.name}
                </Tag>
            ));
        },
    },
];

const hideStatusColumns: GridColDef[] = [
    {
        field: 'title',
        headerName: 'Title',
        width: 200,
        editable: true,
        pinnable: true,
    },
    {
        field: 'critical',
        headerName: 'Critical',
        valueGetter: (params: GridValueGetterParams) => {
            return params.row.critical === 'true' ? 'Yes' : 'No';
        },
        editable: false,
    },
    {
        field: 'complete',
        headerName: 'Complete',
        editable: true,
        valueGetter: (params: GridValueGetterParams) => {
            return params.row.complete ? 'Yes' : 'No';
        },
    },
    {
        field: 'require_association',
        headerName: 'Driver',
        valueGetter: (params: GridValueGetterParams) => {
            return params.row.require_association === 'true' ? 'Yes' : 'No';
        },
        editable: false,
    },
    {
        field: 'phase',
        headerName: 'Phase',
        width: 150,
        description: 'This column has a value getter and is not sortable.',
        sortable: true,
        valueGetter: (params: GridValueGetterParams) => {
            return `${params.row.phase?.title || ''}`;
        },
    },
    {
        field: 'assigned_to',
        headerName: 'Assigned To',
        flex: 1,
        valueGetter: (params: GridValueGetterParams) => {
            return `${JSON.stringify(params.row.assigned_to)}`;
        },
        renderCell: (params: GridValueGetterParams) => {
            return params.row.assigned_to.map((role: IOrganizationRole) => (
                <Tag minimal className="m-r-5">
                    {role.name}
                </Tag>
            ));
        },
    },
];

// function CustomToolbar() {
//     return (
//         <GridToolbarContainer>
//             <GridToolbarColumnsButton />
//             <GridToolbarFilterButton />
//             <GridToolbarExport />
//         </GridToolbarContainer>
//     );
// }

interface IChecklistItemTableProps {
    data: IChecklistItem[];
    onRowClick: (item: IChecklistItem) => void;
    loading?: boolean;
    hideStatus?: boolean;
}
export const ChecklistItemTable: React.FC<IChecklistItemTableProps> = ({ data, onRowClick, hideStatus = false, loading = false }) => {
    return (
        <>
            <div style={{ height: 600, width: '100%', backgroundColor: '#fff' }}>
                <DataGrid
                    sx={{
                        '& .MuiDataGrid-cell': {
                            cursor: 'pointer',
                        },
                    }}
                    disableSelectionOnClick
                    disableColumnSelector
                    onRowClick={(params: any) => onRowClick(params.row)}
                    loading={loading}
                    rows={data}
                    columns={hideStatus ? hideStatusColumns : columns}
                    pageSize={20}
                    rowsPerPageOptions={[20]}
                />
            </div>
        </>
    );
};
