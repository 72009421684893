import React from 'react';
import { Dialog } from '@blueprintjs/core';
import { CreateContactForm } from './CreateContactForm';
interface CreateContactDialogProps {
    open: boolean;
    onClose: () => void;
}
export const CreateContactDialog: React.FC<CreateContactDialogProps> = ({ open, onClose }) => {
    return (
        <Dialog isOpen={open} onClose={onClose} title={'Create New Contact'} icon="id-number">
            <CreateContactForm setCreateContactDialogOpen={onClose} />
        </Dialog>
    );
};
