import React from 'react';
import { useHistory } from 'react-router-dom';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';
import { PostResponse } from 'src/api/models/Post';
import { Tooltip } from '@mui/material';
import { TbBarrierBlock } from 'react-icons/tb';
import NearbyErrorIcon from '@mui/icons-material/NearbyError';
import MoreTimeIcon from '@mui/icons-material/MoreTime';
import moment from 'moment';
import LinesEllipsis from 'react-lines-ellipsis';

interface ActivityFeedCardProps {
    title: string;
    items: PostResponse[];
}

export const ActivityFeedCard: React.FC<ActivityFeedCardProps> = ({ title, items }) => {
    const history = useHistory();
    return (
        <Card sx={{ mb: 3, pb: 2 }}>
            <CardHeader
                className="p-b-5"
                titleTypographyProps={{ variant: 'h6' }}
                title={title}
                action={items.length > 0 ? <Chip size="small" label={`${items.length} Open`} sx={{ mt: 1 }} /> : undefined}
            />
            <CardContent style={{ height: 365, overflow: 'auto', paddingBottom: 10 }}>
                {items.length === 0 && (
                    <Box sx={{ textAlign: 'center', mt: '50px' }}>
                        <Typography>No Open Items</Typography>
                    </Box>
                )}
                {items.map((item) => {
                    return (
                        <Box
                            key={item.id}
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                mb: 1,
                                pb: 1,
                                borderBottom: '1px solid #ced9e080',
                            }}
                        >
                            <Box
                                sx={{
                                    width: '100%',
                                    display: 'flex',
                                    flexWrap: 'nowrap',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <Box sx={{ mr: 2, display: 'flex', mb: 0.4, flexDirection: 'column' }}>
                                    <Typography variant="body1" sx={{ mb: 0.5, fontWeight: 500, color: 'text.primary' }}>
                                        <LinesEllipsis text={item.plain_text_content} maxLine="2" ellipsis="..." trimRight basedOn="letters" />
                                    </Typography>
                                    <Stack direction="row" spacing={1} className="m-l-5 m-t-10">
                                        {item.constraint && (
                                            <Tooltip title="Constraint">
                                                <Avatar sx={{ bgcolor: '#1776d1', width: 28, height: 28 }}>
                                                    <TbBarrierBlock />
                                                </Avatar>
                                            </Tooltip>
                                        )}
                                        {item.critical && (
                                            <Tooltip title="Critical">
                                                <Avatar sx={{ bgcolor: '#1776d1', width: 28, height: 28 }}>
                                                    <NearbyErrorIcon sx={{ fontSize: 18 }} />
                                                </Avatar>
                                            </Tooltip>
                                        )}
                                        {item.cost && (
                                            <Avatar sx={{ bgcolor: '#1776d1', width: 28, height: 28 }}>
                                                <Tooltip title="Cost and Schedule">
                                                    <MoreTimeIcon sx={{ fontSize: 18 }} />
                                                </Tooltip>
                                            </Avatar>
                                        )}
                                        {item.assigned_to && (
                                            <Tooltip title="Assigned To">
                                                <Chip size="small" label={item.assigned_to.full_name} color="primary" className="m-t-3" />
                                            </Tooltip>
                                        )}
                                        {item.date && (
                                            <Tooltip title="Need Date">
                                                <Chip size="small" label={moment(item.date).format('MM/DD/YYYY')} color="primary" className="m-t-3" />
                                            </Tooltip>
                                        )}
                                        <Button size="small" onClick={() => history.push(`/jobs/${item.job_id}/activity?postId=${item.id}`)}>
                                            View
                                        </Button>
                                    </Stack>
                                </Box>
                            </Box>
                        </Box>
                    );
                })}
            </CardContent>
        </Card>
    );
};
