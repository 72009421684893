import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Row, Col } from 'react-grid-system';
import ReactQuill from 'react-quill';
import { Card, FormGroup, InputGroup, RadioGroup, Radio, TextArea } from '@blueprintjs/core';
import { FileDrop } from 'src/components/FileDrop';
import { useAuth } from 'src/hooks/useAuth';
import { IRFI } from 'src/api/models/RFI';
import { JobUserSelect } from 'src/components/Selectors/JobUserSelect/single';
import { WorkflowButtons } from 'src/components/Workflow/WorkflowButtons';
import { saveRfi } from 'src/api/rfis';
import { saveExternalRfi } from 'src/api/external';
import './index.scss';
import { IWorkflowStep } from 'src/api/models/Workflow';
import { IJob } from '../../../../../api/models/Job';
import { DatePicker } from '../../../../../components/DatePicker';

const validationSchema = Yup.object().shape({
    rfi_number: Yup.string().required().min(1, 'RFI Number Required').label('RFI Number'),
    need_date: Yup.string().required().label('Need Date'),
});

interface IRFIFormProps {
    initialValues: IRFI;
    onSaved: (rfi: IRFI) => void;
    selectedJob?: IJob;
    isExternal?: boolean;
}

export const RFIForm: React.FC<IRFIFormProps> = ({ initialValues, onSaved, selectedJob, isExternal }) => {
    const { user, isAdmin } = useAuth();
    const [error, setError] = React.useState<string | null>(null);
    const [currentStep, setCurrentStep] = React.useState<IWorkflowStep | null>(null);

    const formik = useFormik({
        initialValues,
        validationSchema,
        validateOnMount: true,
        onSubmit: async () => {
            console.log('submit');
        },
    });
    const { values, setFieldValue } = formik;

    React.useEffect(() => {
        if (values.workflow) {
            // setNextStep(values.workflow.steps[values.step_index + 1]);
            if (values.step_index >= 0) {
                setCurrentStep(values.workflow.steps[values.step_index]);
            }
        }
    }, [values.workflow, values.step_index]);

    if (!selectedJob) return null;
    const setValue = ({ target }: any) => {
        setFieldValue(target.name, target.value);
    };

    const onSubmitClick = async (status: string, step_index: number) => {
        if (!selectedJob) return;

        setError(null);
        try {
            const updatedRFI = {
                ...values,
                step_index,
                status,
            };
            if (currentStep?.is_external_step && !user) {
                const result = await saveExternalRfi(updatedRFI);
                await formik.setValues(result);
                onSaved(result);
            } else {
                const result = await saveRfi(selectedJob.id, updatedRFI);
                await formik.setValues(result);
                onSaved(result);
            }
        } catch (err) {
            setError(err.toLocaleString());
        }
    };

    const onSavedOnlyClicked = async () => {
        if (!selectedJob) return;
        setError(null);
        try {
            const updatedRFI = {
                ...values,
                save_only: true,
            };
            const result = await saveRfi(selectedJob.id, updatedRFI);
            await formik.setValues(result);
            onSaved(result);
        } catch (err) {
            setError(err.toLocaleString());
        }
    };

    const onVoidClick = async () => {
        if (!selectedJob) return;
        setError(null);
        try {
            const updatedRFI = {
                ...values,
                status: 'Voided',
                //step_index: -1,
            };
            const result = await saveRfi(selectedJob.id, updatedRFI);
            await formik.setValues(result);
            onSaved(result);
        } catch (err) {
            setError(err.toLocaleString());
        }
    };

    const isDisabled = (currentStep?.is_external_step || values.id !== '' || values.status === 'Voided') && !isAdmin;
    const reporter = values.reporter ? values.reporter.full_name : `${user?.first_name} ${user?.last_name}`;
    const showResponse = currentStep?.is_external_step || currentStep?.is_last_step;
    return (
        <>
            <div className="rfi-form">
                <Card className="rfi-detail-section p-relative">
                    <h3>Details</h3>
                    <Row>
                        <Col>
                            <FormGroup label="Job" labelFor="job">
                                <InputGroup id="job" value={selectedJob.name} disabled fill />
                            </FormGroup>
                            <FormGroup label="Client" labelFor="client">
                                <InputGroup id="client" value={`${selectedJob.client?.name}`} disabled />
                            </FormGroup>
                            <FormGroup label="Reporter" labelFor="reporter">
                                <InputGroup id="reporter" value={reporter} disabled />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup label="Number" labelFor="number">
                                <InputGroup id="number" name="rfi_number" value={values.rfi_number} disabled placeholder="Auto Generated" />
                            </FormGroup>
                            <FormGroup label="RFI # (External)" labelFor="external_rfi_number">
                                <InputGroup
                                    id="external_rfi_number"
                                    name="external_rfi_number"
                                    disabled={isDisabled}
                                    value={isDisabled && values.external_rfi_number === '' ? 'N/A' : values.external_rfi_number}
                                    onChange={setValue}
                                    placeholder="ex: RFI-000149 (RFI #147)"
                                />
                            </FormGroup>
                            <FormGroup label="Status" labelFor="status">
                                <InputGroup id="status" name="status" value={values.status} disabled placeholder="N/A" />
                            </FormGroup>
                        </Col>
                    </Row>
                    {isAdmin && (
                        <FormGroup label="Assigned Workflow" labelFor="workflow" labelInfo="(Admin Only)">
                            <InputGroup id="workflow" name="workflow" value={values.workflow?.name} disabled />
                        </FormGroup>
                    )}
                </Card>
                <Card>
                    <h3>Summary </h3>
                    <Row>
                        <Col>
                            <FormGroup label="To" labelFor="to" labelInfo="*">
                                <InputGroup id="to" name="to" value={isDisabled && values.to === '' ? 'N/A' : values.to} onChange={setValue} disabled={isDisabled} placeholder="Who's the RFI to?" />
                            </FormGroup>
                            <FormGroup label="Attn" labelFor="att" labelInfo="*">
                                <InputGroup
                                    id="att"
                                    name="attention"
                                    value={isDisabled && values.attention === '' ? 'N/A' : values.attention}
                                    disabled={isDisabled}
                                    onChange={setValue}
                                    placeholder="Who's attention is required?"
                                />
                            </FormGroup>
                        </Col>
                        <Col>
                            {isExternal && values.from_user ? (
                                <FormGroup label="From" labelFor="from">
                                    <InputGroup id="from" name="from" value={values.from_user.full_name} disabled />
                                </FormGroup>
                            ) : (
                                <FormGroup label="From" labelFor="from" labelInfo="*">
                                    <JobUserSelect
                                        hideLabel
                                        small
                                        hideRole
                                        selectedUserId={formik.values.from_user_id}
                                        onChange={(value) => {
                                            if (value) {
                                                formik.setFieldValue('from_user_id', value.user_id);
                                                formik.setFieldValue('from_company', value.user.company_name);
                                            } else {
                                                formik.setFieldValue('from_user_id', -1);
                                                formik.setFieldValue('from_company', '');
                                            }
                                        }}
                                        disabled={isDisabled}
                                    />
                                </FormGroup>
                            )}

                            <FormGroup label="Company Name" labelFor="from_company" labelInfo="*">
                                <InputGroup
                                    id="from_company"
                                    disabled={isDisabled}
                                    name="from_company"
                                    value={isDisabled && values.from_company === '' ? 'N/A' : values.from_company}
                                    onChange={setValue}
                                    placeholder="What's the company name?"
                                />
                            </FormGroup>
                            {/*<FormGroup label="Date Submitted" labelFor="dsubmit">*/}
                            {/*    <InputGroup id="dsubmit" disabled value={moment().format('MM/DD/YYYY')} />*/}
                            {/*</FormGroup>*/}
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={6}>
                            <DatePicker label="Need Date" onChange={(val) => setFieldValue('need_date', val)} value={values.need_date} />
                        </Col>
                    </Row>
                </Card>
                <Card>
                    <h3>Questions </h3>
                    <Row>
                        <Col>
                            <h4>Question: </h4>
                            <ReactQuill
                                className={isDisabled ? 'quill-disabled' : ''}
                                theme="snow"
                                value={values.question}
                                readOnly={isDisabled}
                                onChange={(value) => {
                                    setFieldValue('question', value);
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h4>Recommendation: </h4>
                            <ReactQuill
                                className={isDisabled ? 'quill-disabled' : ''}
                                theme="snow"
                                value={values.recommendation}
                                readOnly={isDisabled}
                                onChange={(value) => {
                                    setFieldValue('recommendation', value);
                                }}
                            />
                        </Col>
                    </Row>
                    <Row className="m-t-20">
                        <Col>
                            <RadioGroup label="Potential Impact *" name="impact" disabled={isDisabled} inline onChange={setValue} selectedValue={values.impact}>
                                <Radio label="Yes" value="yes" />
                                <Radio label="No" value="no" />
                                <Radio label="TBD" value="tbd" />
                            </RadioGroup>
                        </Col>
                        <Col>
                            <FormGroup label="Cost" labelFor="cost" labelInfo="*">
                                <InputGroup
                                    id="cost"
                                    name="cost_dollars"
                                    disabled={isDisabled}
                                    onChange={setValue}
                                    placeholder="$0"
                                    value={isDisabled && values.cost_dollars === '' ? '$0' : values.cost_dollars}
                                />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup label="Schedule (days)" labelFor="days" labelInfo="*">
                                <InputGroup
                                    id="days"
                                    name="cost_days"
                                    disabled={isDisabled}
                                    value={isDisabled && values.cost_days === '' ? '0 days' : values.cost_days}
                                    onChange={setValue}
                                    placeholder="0 days"
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup label="Notes" labelFor="impact_notes">
                                <TextArea
                                    id="impact_notes"
                                    name="impact_notes"
                                    value={isDisabled && values.impact_notes === '' ? 'N/A' : values.impact_notes}
                                    disabled={isDisabled}
                                    onChange={setValue}
                                    growVertically={true}
                                    fill
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </Card>
                <Card>
                    <h3>Attachments </h3>
                    <FileDrop
                        disabled={currentStep?.is_last_step || values.status === 'Voided'}
                        onFilesAdded={(files) => setFieldValue('files_to_add', files)}
                        onFilesRemoved={(files) => setFieldValue('files_to_remove', files)}
                        existingFiles={values.attachments}
                    />
                </Card>
                {showResponse && (
                    <>
                        <hr className="m-t-20 m-b-20" />
                        <Card>
                            <h3>RFI Response </h3>
                            <Row>
                                <Col>
                                    <h4>Response to Question</h4>
                                    <ReactQuill
                                        className={!currentStep?.is_external_step ? 'quill-disabled' : ''}
                                        readOnly={!currentStep?.is_external_step}
                                        theme="snow"
                                        value={values.response_to_questions}
                                        onChange={(value) => {
                                            setFieldValue('response_to_questions', value);
                                        }}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <h4>Response to Impact </h4>
                                    <ReactQuill
                                        className={!currentStep?.is_external_step ? 'quill-disabled' : ''}
                                        readOnly={!currentStep?.is_external_step}
                                        theme="snow"
                                        value={values.response_to_impacts}
                                        onChange={(value) => {
                                            setFieldValue('response_to_impacts', value);
                                        }}
                                    />
                                </Col>
                            </Row>
                            <Row className="m-t-20">
                                <Col>
                                    <FormGroup label="Response From" labelFor="response_from" labelInfo="*">
                                        <InputGroup
                                            disabled={!currentStep?.is_external_step}
                                            id="response_from"
                                            name="response_from"
                                            onChange={setValue}
                                            placeholder="Enter your name..."
                                            value={values.response_from}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Card>
                    </>
                )}
                {values.status !== 'Voided' && (
                    <WorkflowButtons
                        error={error}
                        onExternalEmailChange={(email) => setFieldValue('external_email', email)}
                        onSubmitClick={onSubmitClick}
                        workflow={values.workflow}
                        onSaveOnlyClick={onSavedOnlyClicked}
                        onVoidClick={onVoidClick}
                        currentStatus={values.status}
                        stepIndex={values.step_index}
                    />
                )}
            </div>
        </>
    );
};
