import React from 'react';
import { Dialog } from '@blueprintjs/core';
import { useDialogs } from 'src/hooks/useDialogs';
import { AddUserForm } from './AddUserForm';

export const AddUserDialog: React.FC = () => {
    const { addUserOpen, setAddUserOpen, selectedUser } = useDialogs();
    return (
        <Dialog
            usePortal={true}
            isOpen={addUserOpen}
            onClose={() => setAddUserOpen(false)}
            title={selectedUser ? `${selectedUser.first_name} ${selectedUser.last_name} - ${selectedUser.status.slice(0, 1).toUpperCase() + selectedUser.status.slice(1)}` : 'Invite User'}
            icon={selectedUser ? 'person' : 'new-person'}
        >
            <AddUserForm selectedUser={selectedUser} setAddUserOpen={setAddUserOpen} />
        </Dialog>
    );
};
