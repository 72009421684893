import React from 'react';
import { Row, Col } from 'react-grid-system';
import LinesEllipsis from 'react-lines-ellipsis';
import { Card, Elevation, H5, Button, Intent } from '@blueprintjs/core';
import { Classes as TTClasses, Popover2 } from '@blueprintjs/popover2';
import AvatarGroup from '@atlaskit/avatar-group';
import { IOrganizationRole } from 'src/api/models/Organization';
import { deleteOrganizationRole } from 'src/api/organization';
import { ScopeList } from 'src/components/ScopeList';
import { TopToast } from 'src/components/Toasts';

interface IRoleCardProps {
    role: IOrganizationRole;
    onClick: (role: IOrganizationRole) => void;
    onRoleDeleted: (role: IOrganizationRole) => void;
}

export const RoleCard: React.FC<IRoleCardProps> = ({ role, onClick, onRoleDeleted }) => {
    const [isDeleting, setIsDeleting] = React.useState(false);

    const deleteRole = async () => {
        setIsDeleting(true);
        try {
            await deleteOrganizationRole(role);
            onRoleDeleted(role);
            TopToast.show({ message: 'Role Deleted!', intent: 'success', icon: 'tick-circle' });
        } catch (err) {
            TopToast.show({ message: err.message, intent: 'danger', icon: 'warning-sign', timeout: 5000000 });
        }
        setIsDeleting(false);
    };
    const avatarList = role.users.map((user) => {
        return {
            email: user.email,
            key: user.email,
            name: `${user.first_name} ${user.last_name}`,
            href: '#',
        };
    });
    return (
        <Card elevation={Elevation.TWO} style={{ height: 200 }}>
            <Row>
                <Col xs={7}>
                    <h3 className="m-t-0">
                        <a onClick={() => onClick(role)}>{role.name}</a>
                    </h3>
                </Col>
                <Col className="text-right">
                    <span className="bp3-text-muted">{role.users.length} Accounts</span>
                </Col>
            </Row>
            <div style={{ height: 50 }}>
                <LinesEllipsis text={role.description} maxLine="2" ellipsis="..." trimRight basedOn="letters" />
            </div>

            {/*<p className="bp3-text-overflow-ellipsis">{role.description}</p>*/}
            <div style={{ height: 50 }}>
                <ScopeList scopes={role.scopes} hideLabel count={2} />
            </div>
            <Row>
                <Col xs={8}>{role.users.length > 0 ? <AvatarGroup data={avatarList} maxCount={5} size="small" /> : <p className="bp3-text-muted">No accounts with role</p>}</Col>
                <Col className="text-right">
                    <Popover2
                        minimal
                        interactionKind="click"
                        popoverClassName={TTClasses.POPOVER2_CONTENT_SIZING}
                        enforceFocus={false}
                        position="right"
                        content={
                            <div key="text">
                                <H5>Are you sure?</H5>
                                <p>Are you sure you want to delete role? This role will be unassigned from all assigned users.</p>
                                <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 15 }}>
                                    <Button className={TTClasses.POPOVER2_DISMISS} style={{ marginRight: 10 }}>
                                        Cancel
                                    </Button>
                                    <Button intent={Intent.DANGER} className={TTClasses.POPOVER2_DISMISS} onClick={deleteRole} loading={isDeleting}>
                                        Delete
                                    </Button>
                                </div>
                            </div>
                        }
                    >
                        <Button minimal icon="trash" />
                    </Popover2>
                </Col>
            </Row>
        </Card>
    );
};
