import React from 'react';
import { Route, RouteProps } from 'react-router-dom';
import { useAuth } from 'src/hooks/useAuth';
import { intersection } from 'lodash';
import { AccessDenied } from '../AccessDenied';
import { RouteScopeInfo } from '../RouteScopeInfo';

interface IRoleBasedRouteProps extends RouteProps {
    render: () => any;
    requiredScopes: string[];
    allowAll?: boolean;
    hide?: boolean;
}

const adminScopes = ['Admin:write:global', 'Admin:read:global'];

export const RoleBasedRoute: React.FC<IRoleBasedRouteProps> = ({ requiredScopes, render, allowAll = false, hide = false, ...rest }) => {
    const { scopes } = useAuth();
    const hasAccess = intersection(requiredScopes, scopes).length > 0 || allowAll;
    if (!hasAccess) {
        return <Route {...rest} render={() => <AccessDenied requiredScopes={requiredScopes} />} />;
    }
    const isAdmin = intersection(adminScopes, scopes).length > 0;
    const show = isAdmin && !hide;
    return (
        <Route
            {...rest}
            render={() => {
                return (
                    <>
                        {show && <RouteScopeInfo scopes={requiredScopes} />}
                        {render()}
                    </>
                );
            }}
        ></Route>
    );
};
