import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useJobs } from 'src/hooks/useJobs';
import { MeetingForm } from '../MeetingForm';
import { IMeeting } from 'src/api/models/Meeting';
import { deleteMeeting, getMeetingById } from '../../../../api/meetings';
import { AppToast } from '../../../../components/Toasts/AppToast';
import { Button, Spinner } from '@blueprintjs/core';
import { ConfirmPopover } from 'src/components/ConfirmPopover';
import { Header2 } from '../../../../components/Header2';

export const ViewMeetingMinutes: React.FC = ({ children }) => {
    const { selectedJob } = useJobs();
    const params: any = useParams();
    const history = useHistory();
    const [loading, setLoading] = React.useState(true);
    const [deleting, setDeleting] = React.useState(false);
    const [meeting, setMeeting] = React.useState<IMeeting | null>(null);
    const [meetingType, setMeetingType] = React.useState('');
    React.useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        if (!selectedJob || !params.meetingId) return;
        setLoading(true);
        try {
            const result = await getMeetingById(selectedJob.id, params.meetingId);
            setMeetingType(result.title);
            setMeeting(result);
        } catch (err) {
            AppToast.show({ message: `Error: ${err.toLocaleString()}`, intent: 'danger', icon: 'tick' });
        }
        setLoading(false);
    };

    const deleteMeetingConfirmed = async () => {
        if (!selectedJob || !meeting) return;
        setDeleting(true);
        try {
            await deleteMeeting(selectedJob.id, meeting.id);
            history.push(`/jobs/${selectedJob.id}/meetings`);
        } catch (err) {
            AppToast.show({ message: `Error: ${err.toLocaleString()}`, intent: 'danger', icon: 'tick' });
        }
        setDeleting(false);
    };
    if (!selectedJob) return null;
    if (loading || !meeting) {
        return (
            <div className="text-center p-t-50">
                <Spinner />
            </div>
        );
    }
    return (
        <>
            <Header2
                className="m-t-20 m-b-40"
                breadcrumbs={[
                    { title: 'Jobs', link: '/jobs' },
                    { title: `${selectedJob?.name} - ${selectedJob?.job_number}`, link: `/jobs/${selectedJob?.id}/summary` },
                    { title: 'Meeting Minutes', link: `/jobs/${selectedJob.id}/meetings` },
                    { title: meetingType.length > 0 ? meetingType : 'Untitled Meeting', link: '' },
                ]}
                actionComponent={
                    <>
                        <Button className="m-r-10" onClick={() => history.push(`/jobs/${selectedJob.id}/meetings`)}>
                            Back
                        </Button>
                        <Button className="m-r-10" onClick={() => history.push(`/jobs/${selectedJob.id}/meetings/export/${params.meetingId}`)}>
                            Export
                        </Button>
                        <ConfirmPopover
                            contentTitle="Are you sure you want to remove this meeting?"
                            contentBody="This action cannot be reversed."
                            confirmButtonText="Delete Meeting"
                            confirmButtonIntent="danger"
                            onConfirmClick={() => deleteMeetingConfirmed()}
                            interactionKind="click"
                            loading={deleting}
                        >
                            <Button intent="danger">Delete</Button>
                        </ConfirmPopover>
                    </>
                }
            />
            {meeting && !loading && <MeetingForm initialValues={meeting} jobId={selectedJob.id} onMeetingTypeUpdated={(val) => setMeetingType(val)} />}
        </>
    );
};
