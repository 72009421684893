import React from 'react';
import { Col, Row } from 'react-grid-system';
import { Button } from '@blueprintjs/core';
import { IRateResponse } from 'src/api/models/Rate';

interface IDraggableItemProps {
    rate: IRateResponse;
    onRemove: () => void;
}

export const StaticItem: React.FC<IDraggableItemProps> = ({ rate, onRemove }) => {
    return (
        <Row>
            <Col xs={6}>
                <div className="p-t-10 p-b-10">{rate.label}</div>
            </Col>
            <Col xs={4}>
                <div className="p-t-10 p-b-10">
                    ${rate.rate} /{rate.rate_measurement}
                </div>
            </Col>
            <Col className="text-right">
                <Button style={{ position: 'absolute', right: 10, top: 4 }} onClick={onRemove} intent="danger" minimal icon="trash" />
            </Col>
        </Row>
    );
};
