import React from 'react';
import { Card } from '@blueprintjs/core';
import DataTable, { TableColumn } from 'react-data-table-component';

interface IDataTableProps {
    columns: TableColumn<any>[];
    data: any[];
    highlightOnHover?: boolean;
    onRowClicked?: (row: any) => void;
    paginationPerPage?: number;
}

const TABLE_STYLES = {
    headCells: {
        style: {
            fontSize: 14,
            overflow: 'visible',
            whiteSpace: 'pre-wrap',
            textOverflow: 'ellipsis',
            paddingTop: 10,
            paddingBottom: 10,
        },
    },
};

export const FiretrolTable: React.FC<IDataTableProps> = ({ columns, paginationPerPage = 15, data, highlightOnHover = false }) => {
    return (
        <Card>
            <DataTable
                customStyles={TABLE_STYLES}
                responsive
                data={data}
                paginationPerPage={paginationPerPage}
                //onRowClicked={(row) => history.push(`/plans/view/${row.id}`)}
                columns={columns}
                fixedHeader
                highlightOnHover={highlightOnHover}
                //pointerOnHover
                //expandableRows
                //expandableRowsComponent={(row: any) => <CreatePlan plan={row.data} partial />}
                //persistTableHead
                //dense
                pagination
            />
        </Card>
    );
};
