import { IDailyQuestionFormValues, IDailyQuestionResponse, IDailySettings, IDailyFormValues, IDailyResponse } from './models/Daily';
import { getAuthOptions } from './helpers';
import AppApi from './index';
const api = AppApi.getApi();

export const getDailySettings = async (): Promise<IDailySettings> => {
    const url = `organization/daily_settings`;
    const { data } = await api.get(url, await getAuthOptions());
    return data;
};

export const updateDailySettings = async (settings: IDailySettings): Promise<IDailySettings> => {
    const url = `organization/daily_settings`;
    const { data } = await api.post(url, settings, await getAuthOptions());
    return data;
};

export const listJobQuestions = async (jobId: number): Promise<IDailyQuestionResponse[]> => {
    const url = `/jobs/${jobId}/dailys/questions`;
    const { data } = await api.get(url, await getAuthOptions());
    return data;
};

export const createJobQuestion = async (jobId: number, question: IDailyQuestionFormValues): Promise<IDailyQuestionResponse> => {
    const url = `/jobs/${jobId}/dailys/questions`;
    const { data } = await api.post(url, question, await getAuthOptions());
    return data;
};

export const updateJobQuestion = async (jobId: number, questionId: number, question: IDailyQuestionFormValues): Promise<IDailyQuestionResponse> => {
    const url = `/jobs/${jobId}/dailys/questions/${questionId}`;
    const { data } = await api.put(url, question, await getAuthOptions());
    return data;
};

export const removeJobQuestion = async (jobId: number, questionId: number): Promise<IDailyQuestionResponse> => {
    const url = `/jobs/${jobId}/dailys/questions/${questionId}`;
    const { data } = await api.delete(url, await getAuthOptions());
    return data;
};

export const createDaily = async (jobId: number, daily: IDailyFormValues): Promise<IDailyResponse> => {
    const url = `/jobs/${jobId}/dailys`;
    const { data } = await api.post(url, daily, await getAuthOptions());
    return data;
};

export const updateDaily = async (jobId: number, dailyId: string, daily: IDailyFormValues): Promise<IDailyResponse> => {
    const url = `/jobs/${jobId}/dailys/${dailyId}`;
    const { data } = await api.put(url, daily, await getAuthOptions());
    return data;
};
export const listDailys = async (jobId: number): Promise<IDailyResponse[]> => {
    const url = `/jobs/${jobId}/dailys`;
    const { data } = await api.get(url, await getAuthOptions());
    return data;
};

export const getDailyById = async (jobId: number, dailyId: string): Promise<IDailyResponse> => {
    const url = `/jobs/${jobId}/dailys/${dailyId}`;
    const { data } = await api.get(url, await getAuthOptions());
    return data;
};
