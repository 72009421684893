import React from 'react';
import { IconName } from '@blueprintjs/core';
import { SubMenu } from '../SubMenu';

interface INavigationItem {
    id: string;
    title: string;
    path: string;
    icon: IconName;
    subs?: INavigationItem[];
}

const MENU_ITEMS: INavigationItem[] = [
    {
        id: 'documents',
        title: 'Documents',
        path: '/documents',
        icon: 'folder-close',
        subs: [
            {
                id: 'drawings',
                title: 'Drawings',
                path: '/documents/drawings',
                icon: 'draw',
            },
            {
                id: 'submittals',
                title: 'Submittals',
                path: '/documents/submittals',
                icon: 'projects',
            },
            {
                id: 'misc',
                title: 'Misc Files',
                path: '/documents/misc',
                icon: 'document',
            },
        ],
    },
];

const buttonCssFn = (props: any) => {
    return {
        color: props.isSelected ? '#004677' : '#42536e',
        backgroundColor: props.isSelected ? '#ebecf0' : '#fff',
    };
};

export const Documents: React.FC = () => {
    return <SubMenu items={MENU_ITEMS} buttonCssFn={buttonCssFn} goBackText="Back to Dashboard" goBackPath="/dashboard" computePath={(item) => item.path} />;
};
