import React from 'react';
import { Button, Menu, MenuItem, Popover, Position, Icon } from '@blueprintjs/core';
import { useJobs } from 'src/hooks/useJobs';
import { IUser } from 'src/api/models/User';
import { Avatar, Tooltip } from '@mui/material';

interface IAssignToPickerProps {
    initialValue: IUser | null;
    onChange: (user: IUser | null) => void;
}

export const AssignToPicker: React.FC<IAssignToPickerProps> = ({ initialValue, onChange }) => {
    const { users } = useJobs();
    const [selected, setSelected] = React.useState<IUser | null>(initialValue);

    React.useEffect(() => {
        onChange(selected);
    }, [selected]);

    const selectUser = (user: IUser | null) => {
        setSelected(user);
    };

    const renderOptions = () => {
        if (users.length === 0) {
            return <MenuItem text="No Users Assigned to Job" disabled />;
        }
        return users.map((user) => {
            const isSelected = selected && selected.id === user.id;
            return <MenuItem key={user.id} text={user.full_name} icon={isSelected ? 'tick' : undefined} onClick={isSelected ? () => selectUser(null) : () => selectUser(user)} />;
        });
    };
    return (
        <Popover
            content={
                <Menu>
                    <Menu.Divider title={'Assign to'} />
                    {renderOptions()}
                </Menu>
            }
            position={Position.LEFT}
        >
            <>
                {selected ? (
                    <Button className="bp3-minimal m-t--8" large>
                        <Tooltip title={selected.full_name}>
                            <Avatar sx={{ width: 24, height: 24, bgcolor: '#2968a0', fontSize: 14 }}>{selected.first_name.slice(0, 1)}</Avatar>
                        </Tooltip>
                    </Button>
                ) : (
                    <Button className="bp3-minimal m-t--8" large>
                        <Icon size={20} icon="user" className="m-l-3 m-r-3" />
                    </Button>
                )}
            </>
        </Popover>
    );
};
