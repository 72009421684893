import React from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ListItemText from '@mui/material/ListItemText';
import { Button, InputGroup, Intent } from '@blueprintjs/core';
import Filter1Icon from '@mui/icons-material/Filter1';
import Filter2Icon from '@mui/icons-material/Filter2';
import { IWBSResponse, WBSLevel1Children } from '../../../../../api/models/WBS';
import { createLevel1Wbs, createLevel2Wbs } from '../../../../../api/wbs';
import { useJobs } from '../../../../../hooks/useJobs';

interface AddLevelButtonProps {
    level: '1' | '2';
    parentId?: string;
    onAdd?: (data: IWBSResponse) => void;
    onLevel2Add?: (data: WBSLevel1Children) => void;
}

const initialValues: IWBSResponse = {
    id: '',
    name: '',
    description: '',
    subs: [],
};
export const AddLevelButton: React.FC<AddLevelButtonProps> = ({ level, onAdd, onLevel2Add, parentId }) => {
    const { selectedJob } = useJobs();
    const [showForm, setShowForm] = React.useState(false);
    const [values, setValues] = React.useState<IWBSResponse | WBSLevel1Children>(initialValues);
    const [loading, setLoading] = React.useState(false);

    const reset = () => {
        setValues(initialValues);
        setShowForm(false);
    };

    const createLevel = async () => {
        if (!selectedJob) return;
        setLoading(true);
        if (level === '1') {
            const result = await createLevel1Wbs(selectedJob.id, values);
            if (onAdd) onAdd(result);
        } else {
            if (!parentId) return;
            const result = await createLevel2Wbs(selectedJob.id, parentId, values);
            if (onLevel2Add) onLevel2Add(result);
        }
        reset();
        setLoading(false);
    };

    const updateValue = (field: string, value: string) => {
        setValues((prevState) => {
            return {
                ...prevState,
                [field]: value,
            };
        });
    };
    const sx = level === '1' ? 0 : 10;
    const filterIcon = level === '1' ? <Filter1Icon fontSize="small" /> : <Filter2Icon fontSize="small" />;
    if (showForm) {
        return (
            <ListItem sx={{ ml: sx }}>
                <InputGroup fill={level === '1'} value={values.name} onChange={(e) => updateValue('name', e.target.value)} leftElement={<div className="p-l-5 p-t-5 p-r-5">{filterIcon}</div>} />
                <Button className="m-l-5 m-r-5" intent={Intent.PRIMARY} onClick={createLevel} loading={loading}>
                    Add
                </Button>
                <Button onClick={reset}>Cancel</Button>
            </ListItem>
        );
    }
    return (
        <>
            <ListItemButton onClick={() => setShowForm(true)} sx={{ ml: sx }}>
                <ListItemIcon>
                    <AddCircleOutlineIcon />
                </ListItemIcon>
                <ListItemText primary={`Add Level ${level}`} />
            </ListItemButton>
        </>
    );
};
