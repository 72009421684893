import React from 'react';
import { Drawer } from '@blueprintjs/core';
import { useDialogs } from 'src/hooks/useDialogs';
import { CreateRoleForm } from './CreateRoleForm';

export const CreateRoleDialog: React.FC = () => {
    const { createRoleDialogOpen, setCreateRoleDialogOpen, selectedRole } = useDialogs();
    const closeDialog = () => {
        setCreateRoleDialogOpen(false);
    };
    return (
        <Drawer isOpen={createRoleDialogOpen} onClose={closeDialog} title={selectedRole ? 'Update Role' : 'Create New Role'} icon="shield">
            <CreateRoleForm selectedRole={selectedRole} setCreateRoleDialogOpen={setCreateRoleDialogOpen} />
        </Drawer>
    );
};
