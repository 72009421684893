import React, { useState, createContext } from 'react';
import { getMe } from 'src/api/user';
import { IUser, UserAppOption } from 'src/api/models/User';
import { intersection, uniq } from 'lodash';

interface IUserContext {
    user: IUser | null;
    apps: UserAppOption[];
    setApps: (apps: UserAppOption[]) => void;
    loadingUser: boolean;
    setUser: (user: IUser | null) => void;
    scopes: string[];
    isAdmin: boolean;
    roleIds: number[];
}
export const AuthUserContext = createContext<IUserContext>({
    user: null,
    setUser: () => null,
    setApps: () => null,
    loadingUser: true,
    scopes: [],
    apps: [],
    isAdmin: false,
    roleIds: [],
});

const adminScopes = ['Admin:write:global', 'Admin:read:global'];
export const AuthUserProvider: React.FC = ({ children }) => {
    const [user, setUserDetails] = useState<IUser | null>(null);
    const [roleIds, setRoleIds] = useState<number[]>([]);
    const [scopes, setScopes] = useState<string[]>([]);
    const [loadingUser, setLoadingUser] = useState(true);
    const [apps, setApps] = useState<UserAppOption[]>([]);
    React.useEffect(() => {
        if (!user) {
            setRoleIds([]);
            setApps([]);
            return setScopes([]);
        }
        const userRolesIds = user.roles.map((r) => r.id);
        const userScopes = user.roles.map((r) => r.scopes).flat();
        setScopes(uniq(userScopes));
        setRoleIds(userRolesIds);
        setApps(user.apps);
    }, [user]);

    React.useEffect(() => {
        const loadUserFromCache = async () => {
            setLoadingUser(true);
            try {
                const userDetails = await getMe();
                setUserDetails(userDetails || null);
            } catch (err) {
                setUserDetails(null);
            }
            setLoadingUser(false);
        };
        loadUserFromCache();
    }, [setUserDetails]);

    const isAdmin = React.useMemo(() => intersection(adminScopes, scopes).length > 0, [scopes]);

    const setUser = (userDetails: any) => {
        setUserDetails(userDetails);
    };
    return <AuthUserContext.Provider value={{ user, setUser, apps, loadingUser, scopes, isAdmin, roleIds, setApps }}>{children}</AuthUserContext.Provider>;
};

export const useAuth: () => IUserContext = () => {
    const context = React.useContext(AuthUserContext);
    return { ...context };
};
